import jeboApi from "api/api";
import { feedsContext } from "components/providers/FeedContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { currentLocationContext } from "components/providers/LocationContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import React, { useContext, useEffect, useState } from "react";
import closeImg from "../../assets/imgs/icons/exit_wh.svg";
import gpsImg from "../../assets/imgs/icons/gps.svg";
import LoadingComponent from "./LoadingComponent";
import QuickJeobPreviewFillText from "./QuickJeboPreviewFillText";

const QuickJeobPreview = ({ previewImg, setPreviewImg }) => {
  const { feeds, setFeeds } = useContext(feedsContext);
  const { handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const { currentLocation, getCurrentPosition } = useContext(
    currentLocationContext
  );
  const [loading, setLoading] = useState(false);

  // 위치 정보 check관리
  const [isLocationChecked, setisLocationChecked] = useState(true);
  // 내용넣기 클릭 state
  const [isFillTextClicked, setIsFillTextClicked] = useState(false);

  const [locationInfo, setLocationInfo] = useState(
    currentLocation ? currentLocation : null
  );

  useEffect(() => {
    if (currentLocation) {
      const { address, roadAddress, placeName, latitude, longitude } =
        currentLocation;
      let tags = [];
      if (placeName) {
        tags.push(`#${placeName.replaceAll(" ", "_")}`);
      } else {
        tags = makeLocationHashtag(address);
      }
      setLocationInfo({
        address,
        roadAddress,
        placeName,
        latitude,
        longitude,
        hashtags: tags,
      });
    }
  }, [currentLocation]);

  const registFeed = async (text) => {
    if (!locationInfo) return;
    setLoading(true);
    try {
      if (isLocationChecked && locationInfo.placeName)
        text = `${text} #${locationInfo.placeName.replace(/\s/gi, "_")}`;

      const regex = /#[^/!@#$%^&*(),.?":{}|<>=\s\n]+/g;

      const hashtags = [];
      let array1;
      while ((array1 = regex.exec(text)) !== null) hashtags.push(array1[0]);

      const {
        data: { jeboPost },
      } = await jeboApi.feedRegist(
        [previewImg],
        hashtags,
        text,
        // 진희 수정 jeboApi.feedRegist contents값 없애기
        // "#빠른제보",
        locationInfo.address,
        locationInfo.roadAddress,
        locationInfo.placeName,
        locationInfo.latitude,
        locationInfo.longitude,
        locationInfo.hashtags,
        "",
        0
      );
      const jeboPostObj = JSON.parse(jeboPost);
      jeboPostObj && setFeeds([jeboPostObj, ...feeds]);
      document.scrollingElement.scrollIntoView(true);
    } catch (error) {
      console.log("error : ", error);
      if (error.response) {
        console.log("error : ", error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요");
          handleSignOutApp();
          setSignLayer("signIn");
        }
      }
    } finally {
      URL.revokeObjectURL(previewImg.url);
      setPreviewImg(null);
      setLoading(false);
      setIsFillTextClicked(false);
    }
  };

  const makeLocationHashtag = (addressName) => {
    const addressNames = addressName.split(" ");
    let tags = [];
    if (addressNames && addressNames.length > 0) {
      const exceptAddressNumber = parseInt(
        addressNames[addressNames.length - 1].substring(0, 1)
      );
      if (!isNaN(exceptAddressNumber)) {
        addressNames.pop();
      }

      addressNames.reduce((acc, curr) => {
        tags.push(`#${curr}`);
        acc && tags.push(`#${acc}_${curr}`);
        return curr;
      }, "");
    }

    return tags;
  };

  return (
    <>
      <div className="previewContainer">
        {/* header 영역 */}
        <div className="previewHeader prevCt">
          <button
            className="previewCloseBtn"
            onClick={() => {
              URL.revokeObjectURL(previewImg.url);
              setPreviewImg(null);
            }}
          >
            <img src={closeImg} alt="closeImg" />
          </button>
          <button
            className="previewRegistBtn"
            onClick={() => {
              !loading && registFeed("");
            }}
          >
            바로등록
          </button>
        </div>

        {/* 이미지 영역 */}
        <div className="previewImgContainer prevCt">
          <img
            id="previewImg"
            className="preImgTag"
            src={previewImg.url}
            alt="이미지 미리보기"
          />
        </div>

        {/* text 영역 */}
        <div className="previewLocationTextArea prevCt">
          <button
            className={`addressCheckBtn ${isLocationChecked ? "check" : ""}`}
            onClick={() => {
              getCurrentPosition(false);  // 23.02.13 codelua. 원래 true 
              setisLocationChecked((prev) => !prev);
            }}
            id="addressCheck"
          >
            {isLocationChecked}
            {isLocationChecked
              ? locationInfo
                ? locationInfo.placeName
                  ? `${locationInfo.placeName} (${locationInfo.address})`
                  : locationInfo.address
                : "위치정보 불러오는 중.."
              : "현 위치를 태그로 추가"}
            <img
              className="addressCheckBtn__Img"
              src={`${isLocationChecked ? closeImg : gpsImg}`}
              alt=""
            />
          </button>
        </div>
        {/* 사용자 입력값 있을때만 보여주기 */}
        {/* {contents.length > 0 && (
          <div className="previewTextArea">
            <div className="previewText">{contents}</div>
          </div>
        )} */}

        {/* footer 영역*/}
        <div className="previewBtmNav prevCt">
          {/* 바로등록 */}
          <button
            className="quickRegiBtn"
            onClick={() => {
              !loading && registFeed("");
            }}
          >
            바로등록
          </button>

          {/* 내용작성 */}
          <button
            className="fillTextBtn"
            onClick={() => {
              setIsFillTextClicked(true);
            }}
          >
            내용작성
          </button>
        </div>

        {/* 내용작성 컴포넌트 */}
        {isFillTextClicked && (
          <QuickJeobPreviewFillText
            imgURL={previewImg.url}
            setIsFillTextClicked={setIsFillTextClicked}
            registFeed={registFeed}
            loading={loading}
          />
        )}
      </div>
      {loading && <LoadingComponent />}
    </>
  );
};

export default QuickJeobPreview;
