import jeboApi from "api/api";
import Layers from "components/layers/Layers";
import { signAppContext } from "components/providers/SignContextProvider";
import LoadingComponent from "components/templates/LoadingComponent";
import { useCallback, useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useInView } from "react-intersection-observer";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import MobileMyHistoryList from "./MobileMyHistoryList";

//더보기 활동
export default function MobileMyAttend() {
  const END_LIMIT = 9;

  const { handleSignOutApp, setSignLayer } = useContext(signAppContext);

  const [lastFeedRef, inView] = useInView({ threshold: 0 });
  const [cursor, setCursor] = useState(0);
  const [notLoadedData, setNotLoadedData] = useState(false);

  const [myAttends, setMyAttends] = useState([]);

  const [tabType, setTabType] = useState("CH"); //CP - 캠페인, TM - 테마, CH - 채널, CM - 커뮤니티

  const [reloadSwitch, setReloadSwitch] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const history = useHistory();

  const loadMyAttend = useCallback(async () => {
    try {
      // setLoading(true);
      const { status, data } = await jeboApi.loadMyActivity(
        tabType,
        END_LIMIT,
        cursor
      );
      if (status === 200) {
        const activities = JSON.parse(data.activities);

        setNotLoadedData(activities.length < END_LIMIT ? true : false);
        if (cursor === 0) setMyAttends(activities);
        else setMyAttends((prev) => prev.concat(activities));
      } else if (status === 204) {
        setNotLoadedData(true);
        console.log("no data to be loaded..");
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    } finally {
      setLoading(false);
    }
  }, [cursor, reloadSwitch, history, handleSignOutApp, setSignLayer]);

  useEffect(() => {
    loadMyAttend();
  }, [loadMyAttend]);

  useEffect(() => {
    if (inView && !error && !notLoadedData) {
      setCursor((prev) => prev + END_LIMIT);
    }
  }, [inView]);

  useEffect(() => {
    setMyAttends([]);
    setReloadSwitch((prev) => !prev);
    setCursor(0);
  }, [tabType]);

  return createPortal(
    <Wrap>
      <Modal>
        <ModalHeader>
          <Button
            onClick={() => {
              history.goBack();
            }}
          ></Button>
          <Title>제보큐브 활동</Title>
        </ModalHeader>
        <ModalBody>
          <Container>
            <MyAttendContainer>
              <MenuSection>
                <AttendTab>
                  <TabName>
                    <TabBtn
                      onClick={(e) => setTabType("CH")}
                      active={tabType === "CH" ? true : false}
                    >
                      채널
                    </TabBtn>
                  </TabName>
                  <TabName>
                    <TabBtn
                      onClick={(e) => setTabType("CM")}
                      active={tabType === "CM" ? true : false}
                    >
                      커뮤니티
                    </TabBtn>
                  </TabName>
                  <TabName>
                    <TabBtn
                      onClick={(e) => {
                        e.preventDefault();
                        setTabType("CP");
                      }}
                      active={tabType === "CP" ? true : false}
                    >
                      캠페인
                    </TabBtn>
                  </TabName>
                  <TabName>
                    <TabBtn
                      onClick={(e) => {
                        e.preventDefault();
                        setTabType("TM");
                      }}
                      active={tabType === "TM" ? true : false}
                    >
                      테마
                    </TabBtn>
                  </TabName>
                </AttendTab>
              </MenuSection>
              <ContentsSection>
                {tabType &&
                  (myAttends.length > 0 ? (
                    myAttends.map((attend, index) => {
                      return (
                        <MobileMyHistoryList
                          key={index}
                          actInfo={attend}
                          majorType={tabType}
                        />
                      );
                    })
                  ) : (
                    <NoData>
                      {tabType
                        ? (tabType === "CP"
                            ? "캠페인"
                            : tabType === "TM"
                            ? "테마"
                            : tabType === "CH"
                            ? "채널"
                            : tabType === "CM"
                            ? "커뮤니티"
                            : "") + " 참여 이력이 없습니다."
                        : "잘못된 접근 입니다."}
                    </NoData>
                  ))}
                {myAttends.length > 0 && <div ref={lastFeedRef}></div>}
              </ContentsSection>
            </MyAttendContainer>
          </Container>
          <Layers />
        </ModalBody>
      </Modal>
      {loading && (
        <div className="modal-wrap open">
          <LoadingComponent />
        </div>
      )}
    </Wrap>,
    document.getElementById("modal")
  );
}

//styled-component 정의
const openModal = keyframes`
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
`;

const Modal = styled.div`
  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;

  width: 100%;
  max-width: 100%;
  height: 100% !important;
  max-height: 100vh !important;

  position: relative;
  background: #fff;
  transform: translateY(0);
  opacity: 0;
`;

const ModalHeader = styled.div`
  display: flex !important;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 7px;
  padding-right: 16px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;
`;

const Title = styled.strong`
  display: inline-block;
  width: auto;
  font-size: 1rem;
  color: var(--text-defaultBlackColor);
  font-weight: 500;
  /* line-height: 50px; */
  line-height: normal !important;
  width: 70%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-self: flex-end;
`;

const Button = styled.button`
  display: block;
  width: 27px;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background: url(/static/media/img_pre.2990dcb3.svg) no-repeat;
  background-color: #fff;
  background-position: left center;
`;

const ModalBody = styled.div`
  height: calc(100% - 50px);
  max-height: none !important;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  max-width: 100%;
  margin: 0;
`;

const MyAttendContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 30px;
  width: 100%;
`;

const MenuSection = styled.div`
  height: 100%;
`;

const ContentsSection = styled.div`
  height: 100%;
`;

const AttendTab = styled.div`
  position: fixed;
  top: 48px;
  width: 100%;

  display: flex;
  margin-bottom: 15px;
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  height: 35px;
  /* border-bottom: 1px solid #dcdcdc; */
`;

const TabName = styled.div`
  display: grid;
  align-items: center;
  background-color: #fff;
  padding-left: 12px;
  padding-right: 12px;
  height: 100%;
  // width: 33.33%;
  width: 50%;
  margin-bottom: 0;
  text-align: center;

  font-weight: ${(props) => (props.active ? "bold" : "")};
  border-right: ${(props) => (props.right ? "1px solid #dcdcdc" : "")};
`;

const TabBtn = styled.button`
  font-size: 15px;
  background: ${(props) =>
    props.active
      ? `linear-gradient(
    316.2deg,
    #3773ff -3.34%,
    #22e6f3 131.85%
  )`
      : "#fff"};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  border-radius: 13px;
  border: 1px solid #dcdcdc;
  width: 100%;
  height: 80%;
  margin: auto;

  font-weight: ${(props) => (props.actve ? "700 !important" : "400")};
`;

const NoData = styled.div`
  width: 100vw;
  height: 100vh;
  text-align: center;
  padding-top: 50%;
`;
