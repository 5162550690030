import jeboApi from "api/api";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { AdminNoticeList } from "components/templates/AdminNoticeList";
import qs from "query-string";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useInView } from "react-intersection-observer";
import { useHistory, useLocation } from "react-router";
import styled, { keyframes } from "styled-components";

export default function MobileNoticeListContainer() {
  const END_LIMIT = 15;

  const { handleSignOutApp, setSignLayer } = useContext(signAppContext);

  const [noticeList, setNoticeList] = useState([]);

  const [lastFeedRef, inView] = useInView({ threshold: 0 });
  const [error, setError] = useState("");
  const [notLoadedData, setNotLoadedData] = useState(false);

  const [cursor, setCursor] = useState(0);

  const { mobileType } = useContext(isMobileContext);

  const history = useHistory();
  const location = useLocation();
  const { noticeId } = qs.parse(location.search);

  const getNoticeList = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.noticeList(END_LIMIT, cursor);

      if (status === 200) {
        const notice = JSON.parse(data.noticeList);
        setNotLoadedData(notice.length < END_LIMIT ? true : false);

        if (cursor === 0) setNoticeList(notice);
        else setNoticeList((prev) => prev.concat(notice));
      } else if (status === 204) {
        setNotLoadedData(true);
      }
    } catch (error) {
      setError(error);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인 해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  }, [cursor, handleSignOutApp, setSignLayer]);

  useEffect(() => {
    getNoticeList();
  }, [getNoticeList]);

  useEffect(() => {
    if (inView && !error && !notLoadedData)
      setCursor((prev) => prev + END_LIMIT);
  }, [inView, error, notLoadedData]);

  // const handleRefresh = useCallback(async () => {
  //   cursor !== 0 ? setCursor(0) : getNoticeList();
  // }, []);

  return createPortal(
    <Wrap>
      <Modal>
        <ModalHeader>
          <Button
            onClick={(e) => {
              history.goBack();
            }}
          ></Button>
          <Title>공지사항</Title>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Items>
              {console.log("!")}
              {noticeList && noticeList.length > 0 ? (
                noticeList.map((notice, index) => {
                  return (
                    <AdminNoticeList
                      key={index}
                      notice={notice}
                      mobileType={mobileType}
                      open={
                        noticeId && noticeId === notice.noticeId ? true : false
                      }
                      focus={noticeId ? true : false}
                    />
                  );
                })
              ) : (
                <NoData>공지사항이 없습니다.</NoData>
              )}
              {noticeList.length > 0 && <div ref={lastFeedRef}></div>}
            </Items>
          </Container>
        </ModalBody>
      </Modal>
    </Wrap>,
    document.getElementById("modal")
  );
}

const openModal = keyframes`
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
`;

const Modal = styled.div`
  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;

  width: 100%;
  max-width: 100%;
  height: 100% !important;
  max-height: 100vh !important;

  position: relative;
  background: #fff;
  transform: translateY(0);
  opacity: 0;
`;

const ModalHeader = styled.div`
  display: flex !important;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 7px;
  padding-right: 16px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;
`;

const Title = styled.strong`
  display: inline-block;
  width: auto;
  font-size: 1rem;
  color: var(--text-defaultBlackColor);
  font-weight: 500;
  /* line-height: 50px; */
  line-height: normal !important;
  width: 70%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-self: flex-end;
`;

const Button = styled.button`
  display: block;
  width: 27px;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background: url(/static/media/img_pre.2990dcb3.svg) no-repeat;
  background-color: #fff;
  background-position: left center;
`;

const ModalBody = styled.div`
  height: calc(100% - 50px);
  max-height: none !important;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  max-width: 100%;
  margin: 0;
`;

const Items = styled.div`
  display: flex;
  align-items: center;
  padding-top: 15px;
  flex-direction: column;

  overflow-y: scroll;
  padding-bottom: 100px;
`;

const NoData = styled.div`
  margin: 100% 0%;
`;
