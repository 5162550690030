import ProgressBar from "@ramonak/react-progress-bar";
import jeboApi from "api/api";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { Base64 } from "js-base64";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled, { keyframes } from "styled-components";

/* 아이콘 이미지 추후 변경 필요 */
import follow_icon from "assets/imgs/icons/m_my.svg"; //구독/관심구독
import profile_icon from "assets/imgs/icons/m_setting.svg"; //프로필수정
import feeds_icon from "assets/imgs/icons/speechbubble.svg"; //피드목록

export default function MobileSettingMenuContainer() {
  const { isLoggedIn, myInfo } = useContext(signAppContext);
  const { handleSignOutApp, setSignLayer } = useContext(signLayerContext);
  const { mobileType, isMobile } = useContext(isMobileContext);

  const [followerCnt, setFollowerCnt] = useState(null);
  const [followingCnt, setFollowingCnt] = useState(null);

  const [totFeedCnt, setTotFeedCnt] = useState(null);

  const [showReward, setShowReward] = useState(null);

  const [totalActPoint, setTotalActPoint] = useState(0);

  const history = useHistory();

  const now = new Date();
  const nowYear = now.getFullYear();
  const nowMonth = ("0" + (1 + now.getMonth())).slice(-2);

  const getFollowCnt = useCallback(
    async (response) => {
      try {
        const { status, data } = await jeboApi.getMyProfileInfo(
          myInfo.memberUid,
          nowYear,
          nowMonth
        );

        if (status === 200) {
          const followCnt = data.followCnt;
          const rewards = data.rewardList;
          const point = data.activitiesPoint;

          setFollowerCnt(followCnt.followerCnt);
          setFollowingCnt(followCnt.followingCnt);
          setTotFeedCnt(followCnt.totFeedCnt);

          let gainReward = [];
          let missReward = [];

          // MemberUid 존재 시 획득 리워드, 없을 시 미획득 리워드로 구분
          rewards.map((reward) =>
            reward.memberUid ? gainReward.push(reward) : missReward.push(reward)
          );

          //획득 리워드 수에 따라, 미획득 리워드를 채워 넣음
          gainReward.length < 2
            ? setShowReward(gainReward.concat(missReward.slice(0, 3)))
            : gainReward.length < 3
            ? setShowReward(gainReward.concat(missReward.slice(0, 2)))
            : gainReward.length < 4
            ? setShowReward(gainReward.concat(missReward[0]))
            : missReward.length < 1
            ? setShowReward(gainReward.slice(0, 4))
            : setShowReward(gainReward.slice(0, 3).concat(missReward[0]));

          setTotalActPoint(point);
        }
      } catch (error) {
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    },
    [myInfo]
  );

  useEffect(() => {
    getFollowCnt();
  }, [myInfo]);

  return (
    <div className="s-list-ct">
      <div className="s-header" style={{ zIndex: "1" }}>
        <button
          type="button"
          onClick={() => {
            isMobile && history.goBack();
          }}
          className="page-back mobile"
        ></button>
        <strong>마이페이지</strong>
      </div>

      <div className="s-body">
        <Profile>
          <ProfileContainer>
            <ProfileThumb>
              <Thumbnail>
                <ProfileImage src={myInfo.imageUrl} alt="프로필사진" />
              </Thumbnail>
            </ProfileThumb>
            <ProfileNickname>
              <ProfileWelcom>
                <strong>{myInfo.nickName}</strong>
                &nbsp;님
                <br /> 오늘도 반가워요
              </ProfileWelcom>
            </ProfileNickname>
            <ProfileSetting>
              <ProfileEdit>
                <ProfileEditIcon
                  src={profile_icon}
                  alt="프로필수정"
                  onClick={(e) => {
                    if (isLoggedIn) {
                      history.push({
                        pathname: "/myact/editprofile",
                      });
                    } else {
                      setSignLayer("signIn");
                    }
                  }}
                />
              </ProfileEdit>
            </ProfileSetting>
          </ProfileContainer>
          <Attendance>
            <AttendanceSpan
              onClick={(e) => {
                e.preventDefault();
                if (isLoggedIn) {
                  history.push({
                    pathname: "/myact/attendance",
                  });
                } else {
                  setSignLayer("signIn");
                }
              }}
            >
              출석체크 하러 가기
            </AttendanceSpan>
            <NextArrow type="arrowType" style={{ marginTop: "2.5px" }} />
          </Attendance>
          <MyJeboInfo>
            <MyJeboFeed
              onClick={(e) => {
                e.preventDefault();
                if (isLoggedIn) {
                  history.push({
                    pathname: `/mypage`,
                  });
                } else {
                  setSignLayer("signIn");
                }
              }}
            >
              <MyJeboFeedIcon src={feeds_icon} alt="게시물 수" />
              <MyJeboFeedCnt>{totFeedCnt}</MyJeboFeedCnt>
            </MyJeboFeed>
            <MyJeboFeed
              onClick={(e) => {
                e.preventDefault();
                if (isLoggedIn) {
                  history.push({
                    pathname: `/mobileSubscribe/${myInfo.memberUid}/FOLLOWER`,
                  });
                } else {
                  setSignLayer("signIn");
                }
              }}
            >
              <MyJeboFeedIcon src={follow_icon} alt="팔로워" />
              <MyJeboFeedCnt>{followerCnt}</MyJeboFeedCnt>
            </MyJeboFeed>
            <MyJeboFeed
              onClick={(e) => {
                e.preventDefault();
                if (isLoggedIn) {
                  history.push({
                    pathname: `/mobileSubscribe/${myInfo.memberUid}/FOLLOWING`,
                  });
                } else {
                  setSignLayer("signIn");
                }
              }}
            >
              <MyJeboFeedIcon src={follow_icon} alt="팔로워" />
              <MyJeboFeedCnt>{followingCnt}</MyJeboFeedCnt>
            </MyJeboFeed>
          </MyJeboInfo>
        </Profile>
        {/************************************************************************************마이페이지 상단 END     */}
        <Point>
          <PointProgressDiv>
            <PointProgressTitle>활동포인트</PointProgressTitle>
            <PointProgress>
              <ProgressBar
                className="my_progress_bar"
                labelAlignment="outside"
                labelColor="black"
                customLabelStyles={progressLayer}
                completed={Number((totalActPoint / 15).toFixed(1))}
                maxCompleted={100}
                bgColor="linear-gradient(316.2deg,#3773ff -3.34%,#22e6f3 131.85%)"
                height="30px"
              />
            </PointProgress>
          </PointProgressDiv>
          <PointProgressDetail
            onClick={(e) => {
              e.preventDefault();
              if (isLoggedIn) {
                history.push({
                  pathname: "/myact/activitypoint",
                  state: { total: totalActPoint },
                });
              } else {
                setSignLayer("signIn");
              }
            }}
          >
            <NextArrow />
          </PointProgressDetail>
        </Point>
        {/************************************************************************************활동포인트 END     */}
        <Badge>
          <BadgeInfo>
            <BadgeTitle>획득 배지</BadgeTitle>
          </BadgeInfo>
          <BadgeList>
            {showReward &&
              showReward.map((reward, index) => {
                return (
                  <BadgeItem key={index}>
                    <BadgeIcon
                      active={reward.memberUid ? true : false}
                      alt={`배지`}
                      className="icon active"
                      src={
                        process.env.REACT_APP_RESOURCE_HOST +
                        reward.rewardImagePath
                      }
                    />
                    <BadgeName>
                      {/* TODO :: 미획득 리워드에 보여 줄 짧은 한 줄 설명 필요 
                        {reward.memberUid ? reward.rewardName : reward.rewardDesc} 
                      */}
                      {reward.rewardName}
                    </BadgeName>
                  </BadgeItem>
                );
              })}
            <BadgeMore
              onClick={(e) => {
                if (isLoggedIn) {
                  history.push({
                    pathname: "/myact/reward",
                    search: `?memberUid=${myInfo.memberUid}`,
                  });
                } else {
                  setSignLayer("signIn");
                }
              }}
            >
              <NextArrow />
            </BadgeMore>
          </BadgeList>
        </Badge>
        {/************************************************************************************획득배지 END     */}
        <MenuList>
          <MenuBtn
            onClick={(e) => {
              if (isLoggedIn) {
                history.push({
                  pathname: "/myact/activity",
                });
              } else {
                setSignLayer("signIn");
              }
            }}
          >
            <MenuIcon src={profile_icon} alt="나의활동" />
            <MenuName>나의활동</MenuName>
            <NextArrow />
          </MenuBtn>
          <MenuBtn
            onClick={(e) => {
              if (isLoggedIn) {
                history.push({
                  pathname: "/myact/attend",
                });
              } else {
                setSignLayer("signIn");
              }
            }}
          >
            <MenuIcon src={profile_icon} alt="제보큐브 활동" />
            <MenuName>제보큐브 활동</MenuName>
            <NextArrow />
          </MenuBtn>
          <MenuBtn
            onClick={(e) => {
              if (isLoggedIn) {
                history.push({
                  pathname: "/myact/pushalarm",
                });
              } else {
                setSignLayer("signIn");
              }
            }}
          >
            <MenuIcon src={profile_icon} alt="푸시알림" />
            <MenuName>푸시알림</MenuName>
            <NextArrow />
          </MenuBtn>

          <MenuBtn
            onClick={(e) => {
              if (isLoggedIn) {
                history.push({
                  pathname: "/myact/noticelist",
                });
              } else {
                setSignLayer("signIn");
              }
            }}
          >
            <MenuIcon src={profile_icon} alt="공지사항" />
            <MenuName>공지사항</MenuName>
            <NextArrow />
          </MenuBtn>

          <MenuBtn
            onClick={(e) => {
              e.preventDefault();
              const url = "https://guide.jebo.io/faq/";
              if (mobileType === "ios") {
                const encodedUrl = Base64.encode(url);
                window.webkit.messageHandlers.openOuterLink.postMessage(
                  encodedUrl
                );
              } else {
                window.open(url);
              }
            }}
          >
            <MenuIcon src={profile_icon} alt="자주 묻는 질문" />
            <MenuName>자주 묻는 질문</MenuName>
            <NextArrow />
          </MenuBtn>

          <MenuBtn
            onClick={(e) => {
              if (isLoggedIn) {
                history.push({
                  pathname: "/myact/aboutservice",
                });
              } else {
                setSignLayer("signIn");
              }
            }}
          >
            <MenuIcon src={profile_icon} alt="약관 및 정책" />
            <MenuName>약관 및 정책</MenuName>
            <NextArrow />
          </MenuBtn>

          <MenuBtn
            onClick={(e) => {
              e.preventDefault();
              const url = "https://guide.jebo.io";
              if (mobileType === "ios") {
                const encodedUrl = Base64.encode(url);
                window.webkit.messageHandlers.openOuterLink.postMessage(
                  encodedUrl
                );
              } else {
                window.open(url);
              }
            }}
          >
            <MenuIcon src={profile_icon} alt="블로그 방문하기" />
            <MenuName>블로그 방문하기</MenuName>
            <NextArrow />
          </MenuBtn>
          <MenuBtn
            onClick={(e) => {
              if (isLoggedIn) {
                history.push({
                  pathname: "/myact/accountmanage",
                });
              } else {
                setSignLayer("signIn");
              }
            }}
          >
            <MenuIcon src={profile_icon} alt="계정관리" />
            <MenuName>계정관리</MenuName>
            <NextArrow />
          </MenuBtn>
        </MenuList>
      </div>
    </div>
  );
}

//Progressbar style
const progressLayer = {
  position: "absolute",
  padding: "0px 0px 0px 65vw",
  color: "black",
  fontWeight: "bold",
  fontSize: "15px",
};
//styled-component 정의
const Profile = styled.div`
  height: 23vh;
  background-color: #fff;
  @media screen and (max-height: 800px) {
    height: 26vh;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  height: 12vh;
  text-align: center;
  background: #fff;
  @media screen and (max-height: 800px) {
    margin-bottom: 10px;
  }
`;

const ProfileThumb = styled.div`
  height: 100%;
  width: 25%;
  margin-left: 5%;
  display: table;
  margin-bottom: 20px;
`;

const Thumbnail = styled.span`
  height: 70%;
  width: 80%;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
`;
const ProfileImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
`;

const ProfileNickname = styled.div`
  width: 55%;
  height: 100%;
  text-align: left;

  display: table;
`;
const ProfileSetting = styled.div`
  width: 20%;
  height: 100%;
  text-align: left;
  display: table;
`;

const ProfileWelcom = styled.span`
  display: table-cell;
  vertical-align: middle;
`;

const ProfileEdit = styled.div`
  background-color: inherit;
  display: flex;
  align-items: center;
  // padding-left: 5vw;
  width: 100%;
  height: 100%;
  text-align: center;
`;

const ProfileEditIcon = styled.img`
  width: 25px;
  height: 25px;
  margin: 35%;
`;

const Attendance = styled.div`
  height: 35px;
  background-color: #f5f5f5;
  border-radius: 30px;
  width: 90%;
  margin: auto;

  text-align: right;

  display: flex;
  padding-top: 10px;
`;

const AttendanceSpan = styled.span`
  display: table-cell;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 10px;

  width: 90%;
  margin-top: -2.5px;
`;

const MyJeboInfo = styled.div`
  display: flex;
  width: 90%;
  margin: 5px 5%;
  height: 25%;
`;

const MyJeboFeed = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  padding-left: 20px;
  font-weight: bold;
  font-size: 25px;
`;

const MyJeboFeedIcon = styled.img`
  width: 30px;
  height: 30px;
  margin: auto 0;
`;
const MyJeboFeedCnt = styled.div`
  margin: auto 20px;
`;

const DivisionBar = styled.div`
  height: 55%;
  border: 0.5px solid #0000006e;
  margin-top: 15px;
`;

/* 활동포인트 */

const Badge = styled.div`
  margin: 10px 0;
  background-color: #fff;
  height: 11rem;
  text-align: right;
`;

const BadgeInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100vw;
`;

const BadgeTitle = styled.span`
  width: 22%;
  display: table;
  text-align: left;
  margin-left: 5%;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 500;
`;

const BadgeList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const BadgeItem = styled.div`
  width: 23%;
  height: 100%;
  text-align: center;
`;

const BadgeIcon = styled.img`
  width: ${(props) => (props.active ? "80%" : "60%")};
  height: 110px;
  object-fit: ${(props) => (props.active ? "cover" : "contain")};
`;

const BadgeName = styled.div`
  font-size: 14px;
`;

const BadgeMore = styled.button`
  width: 8%;
  margin-bottom: 6vh;
  text-align: right;
  background-color: inherit;
  text-decoration: underline;
  color: black;
`;

const MenuList = styled.div`
  margin-top: 10px;
`;

const MenuBtn = styled.button`
  border-top: none;
  background: #fff;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px;
`;

const MenuIcon = styled.img`
  width: 20px;
`;

const MenuName = styled.span`
  width: 90%;
  text-align: left;
  font-weight: 400 !important;
  font-size: 15px;
  color: black;
  /* color: var(--text-defaultBlackColr); */
  letter-spacing: -0.03em;
`;

const NextArrow = styled.span`
  position: absolute;
  left: ${(props) => (props.type === "arrowType" ? "90%" : "93%")};
  content: "";
  width: 10px;
  height: 10px;
  border-top: 2px solid #999;
  border-right: 2px solid #999;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
`;

const Point = styled.div`
  margin: 10px 0;
  display: flex;
  background-color: #fff;
  height: 10vh;
`;
const PointProgressDiv = styled.div`
  width: 85%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
`;

const PointProgressTitle = styled.span`
  width: 90%;
  height: 25%;
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
  display: flex;
  align-items: center;
`;

const PointProgress = styled.span`
  height: 75%;
  width: 95%;
  display: flex;
  align-items: center;
`;

const PointProgressDetail = styled.div`
  width: 15%;
  height: 100%;
  margin-top: 5.5vh;
`;
