import logo from "assets/imgs/logo_new.svg";
import LocationPolicy from "components/layers/policy/LocationPolicy";
import MarketingPolicy from "components/layers/policy/MarketingPolicy";
import PersonalPolicy from "components/layers/policy/PersonalPolicy";
import TermOfUsePolicy from "components/layers/policy/TermOfUsePolicy";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AgreeToLocationInfo from "./AgreeToLocationInfo";
import AgreeToOverAge from "./AgreeToOverAge";
import AgreeToPersonalData from "./AgreeToPersonalData";
import AgreeToTermsOfUse from "./AgreeToTermsOfUse";

import { isMobileContext } from "components/providers/BrowserEnvContextProvider";

export default function SignUpAgreeLayer({
  socialSignIn,
  infoStruct,
  setInfoStruct,
  setOpenTermsOfJebo,
}) {
  const { isMobile } = useContext(isMobileContext);

  const history = useHistory();

  const [requiredAgreeMessage, setRequiredAgreeMessage] = useState("");

  const [checkOverAge, setCheckOverAge] = useState(false);
  const [checkTermsOfUse, setCheckTermsOfUse] = useState(false);
  const [checkPersonalData, setCheckPersonalData] = useState(false);
  const [checkLocationInfo, setCheckLocationInfo] = useState(false);
  const [checkMarketingInfo, setCheckMarketingInfo] = useState(false);

  const [allCheck, setAllCheck] = useState(false);

  const [termsOfUse, setTermsOfUse] = useState(false);
  const [personalData, setPersonalData] = useState(false);
  const [locationInfo, setLocationInfo] = useState(false);
  const [marketingInfo, setMarketingInfo] = useState(false);

  /* 이용동의 항목 값 */
  const agreeItems = {
    overAge: checkOverAge,
    termsOfUse: checkTermsOfUse,
    personalData: checkPersonalData,
    locationInfo: checkLocationInfo,
    marketingInfo: checkMarketingInfo,
  };

  // useEffect(() => {
  //   document.getElementsByTagName("html")[0].classList.add("hidden");
  //   window.document.body.classList.add("hidden");
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("hidden");
  //     window.document.body.classList.remove("hidden");
  //   };
  // }, []);

  useEffect(() => {
    setCheckOverAge(allCheck);
    setCheckTermsOfUse(allCheck);
    setCheckPersonalData(allCheck);
    setCheckLocationInfo(allCheck);
    setCheckMarketingInfo(allCheck);
  }, [allCheck]);

  return (
    <>
      <div className="modal-wrap agree open">
        <div className="modal modal-agree open" id="modal-agree">
          <div className="modal-header">
            {isMobile && (
              <button
                type="button"
                className="page-back mobile"
                onClick={() => {
                  if (
                    !termsOfUse &&
                    !personalData &&
                    !locationInfo &&
                    !marketingInfo
                  ) {
                    console.log("if");
                    setOpenTermsOfJebo(false);
                  } else {
                    console.log("else");
                    setTermsOfUse(false);
                    setPersonalData(false);
                    setLocationInfo(false);
                    setMarketingInfo(false);
                  }
                }}
              ></button>
            )}
            <strong>제보 회원가입</strong>

            {!isMobile && (
              <button
                className="modal-close pc"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    !termsOfUse &&
                    !personalData &&
                    !locationInfo &&
                    !marketingInfo
                  ) {
                    setInfoStruct(null);
                    history.push({ pathname: "/" });
                  } else {
                    setTermsOfUse(false);
                    setPersonalData(false);
                    setLocationInfo(false);
                    setMarketingInfo(false);
                  }
                }}
              >
                <i className="icon-cross1"></i>
              </button>
            )}
          </div>
          {(termsOfUse || personalData || locationInfo || marketingInfo) && (
            <div className="serviceArgreeContainer full-content">
              <div className="policy-container sign email-sign">
                {termsOfUse && (
                  <TermOfUsePolicy
                    setTermsOfUse={setTermsOfUse}
                    callFrom={"signUp"}
                  />
                )}
                {personalData && (
                  <PersonalPolicy
                    setPersonalData={setPersonalData}
                    callFrom={"signUp"}
                  />
                )}
                {locationInfo && (
                  <LocationPolicy
                    setLocationInfo={setLocationInfo}
                    callFrom={"signUp"}
                  />
                )}
                {marketingInfo && (
                  <MarketingPolicy
                    setMarketingInfo={setMarketingInfo}
                    callFrom={"signUp"}
                  />
                )}
              </div>
            </div>
          )}
          {!termsOfUse && !personalData && !locationInfo && !marketingInfo && (
            <>
              <div className="resign-header">
                <div className="logo size">
                  <img src={logo} alt="제보" />
                </div>
                <br />
                <div className="resign-notice">
                  제보에 오신걸 환영합니다.
                  <br /> 제보 서비스 시작을 위해 먼저 약관을 동의해주세요.
                </div>
              </div>
              <div className="serviceArgreeContainer">
                <div className="serviceArgreeAll">
                  <label htmlFor="serviceAllChk">모든 약관 동의</label>
                  <input
                    onChange={(e) => setAllCheck((current) => !current)}
                    id="serviceAllChk"
                    type="checkbox"
                    checked={allCheck}
                  ></input>
                </div>
                <div className="serviceAgreeContainer__wapper">
                  <AgreeToOverAge
                    checkOverAge={checkOverAge}
                    setCheckOverAge={setCheckOverAge}
                  />
                  <AgreeToTermsOfUse
                    checkTermsOfUse={checkTermsOfUse}
                    setCheckTermsOfUse={setCheckTermsOfUse}
                    setTermsOfUse={setTermsOfUse}
                  />
                  <AgreeToPersonalData
                    checkPersonalData={checkPersonalData}
                    setCheckPersonalData={setCheckPersonalData}
                    setPersonalData={setPersonalData}
                  />
                  <AgreeToLocationInfo
                    checkLocationInfo={checkLocationInfo}
                    setCheckLocationInfo={setCheckLocationInfo}
                    setLocationInfo={setLocationInfo}
                  />
                </div>
              </div>
              <div className="input-group">
                {requiredAgreeMessage && (
                  <span className="invalid">{requiredAgreeMessage}</span>
                )}
              </div>
              <div className="resign-footer">
                <button
                  className="input-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      !checkOverAge ||
                      !checkTermsOfUse ||
                      !checkPersonalData ||
                      !checkLocationInfo
                    ) {
                      setRequiredAgreeMessage("필수 동의 항목을 체크해주세요.");
                    } else {
                      console.log("Callback Function : ", infoStruct);
                      socialSignIn(
                        infoStruct.id,
                        infoStruct.email,
                        infoStruct.name,
                        infoStruct.socialType,
                        infoStruct.deviceId,
                        infoStruct.fcmToken,
                        agreeItems
                      );
                      setInfoStruct(null);
                    }
                  }}
                >
                  회원가입
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
