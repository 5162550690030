import jeboApi from "api/api";
import Layers from "components/layers/Layers";
import Header from "components/pages/app/layouts/Header";
import FeedSection from "components/pages/member/FeedSection";
import Sections from "components/pages/member/layouts/Sections";
import ProfileSection from "components/pages/member/ProfileSection";
import MemberContextProvider from "components/pages/member/provider/MemberContextProvider";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import LoadingComponent from "components/templates/LoadingComponent";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

export default function Member() {
  const { isMobile } = useContext(isMobileContext);
  const { handleSignOutApp } = useContext(signAppContext);

  const [feeds, setFeeds] = useState([]);

  const [memberInfo, setMemberInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const [notFoundMember, setNotFoundMember] = useState(false);
  const [blockMember, setBlockMember] = useState(false);

  const [layer, setLayer] = useState(null);

  const { nickName } = useParams();
  const history = useHistory();
  const match = useRouteMatch();

  const getMemberInfo = useCallback(async () => {
    setLoading(true);

    if (!nickName) {
      setNotFoundMember(true);
      return;
    }

    try {
      const { status, data } = await jeboApi.getMemberInfoNickName(nickName);

      if (status === 200) {
        setMemberInfo(JSON.parse(data.memberInfo));
        setNotFoundMember(false);
        setBlockMember(false);
      } else if (status === 203) {
        setBlockMember(true);
      } else if (status === 204) setNotFoundMember(true);
    } catch (error) {
      console.log(error.response);
      if (error.response?.status === 403) {
        alert("세션이 만료되었습니다. 다시 로그인해주세요.");
        handleSignOutApp();
      }
    } finally {
      setLoading(false);
    }
  }, [nickName, handleSignOutApp]);

  useEffect(() => {
    getMemberInfo();
  }, [getMemberInfo]);

  const onExpiredSession = () => {
    alert("로그인 세션이 만료됐습니다. 다시 로그인해주세요.");
    handleSignOutApp();
    history.replace({ pathname: `${match.url}/sign` });
  };
  return (
    <>
      <MemberContextProvider
        feeds={{ feeds, setFeeds }}
        layer={{ layer, setLayer }}
        session={{ onExpiredSession }}
      >
        {!isMobile && <Header />}
        {loading && <LoadingComponent />}
        <Sections>
          {memberInfo && !notFoundMember && (
            <>
              <ProfileSection memberInfo={memberInfo} />
              <FeedSection memberUid={memberInfo.memberUid} />
            </>
          )}
          {blockMember && (
            <div className="wrongTag_contianer">
              <figure className="wrongTag_imgCt">
                <div className="wrongTag_img"></div>
              </figure>
              <h2 className="title">차단한 사용자입니다.</h2>
              <a
                href="#!"
                className="text back_btn"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.goBack();
                }}
              >
                돌아가기
              </a>
            </div>
          )}
          {notFoundMember && (
            <div className="wrongTag_contianer">
              <figure className="wrongTag_imgCt">
                <div className="wrongTag_img"></div>
              </figure>
              <h2 className="title">사용자를 찾을 수 없습니다.</h2>
              <a
                href="#!"
                className="text back_btn"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.goBack();
                }}
              >
                돌아가기
              </a>
            </div>
          )}
        </Sections>
        <Layers />
      </MemberContextProvider>
    </>
  );
}
