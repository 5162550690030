import styled from "styled-components";

//앱 유도 Pop Up Layer
export default function IntroduceEventPopLayer({ setOpenPop, expiredKey }) {
  const setExpired = () => {
    localStorage.setItem(expiredKey, false);
    setOpenPop(false);
  };

  return (
    <EventModal>
      <Container>
        <ModalHeader>
          <img
            src={
              process.env.REACT_APP_RESOURCE_HOST +
              `/media/conmap/event/pet_event.png`
            }
            alt="event_image"
          />
        </ModalHeader>
        <ModalBody>
          <Button
            type="button"
            className="cancel"
            onClick={(e) => setExpired()}
          >
            다시보지 않기
          </Button>
          <Button
            type="button"
            className="confirm"
            onClick={(e) => {
              setOpenPop(false);
            }}
          >
            닫기
          </Button>
        </ModalBody>
      </Container>
    </EventModal>
  );
}

const EventModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
`;

const Container = styled.div`
  border-radius: 25px;
  overflow: hidden;

  width: 312px;
  /* height: 312px !important; */

  text-align: center;
  transform: translateX(-50%);
  position: absolute;
  top: 30%;
  left: 50%;

  z-index: 99;
  box-shadow: 1px 1px 20px 0px rgb(0, 0, 0, 0.2);

  display: block !important;
  animation: fadeIn 0.15s ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const ModalHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  img {
    width: 100%;
    height: 100%;
  }
`;

const ModalBody = styled.div`
  width: 312px;
  height: 46px !important;
  margin-top: -1px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.button`
  width: 50%;
  height: 45px;
  font-size: 13px;
  font-weight: 300;
  border: none;
  margin: 0;
  padding: 0;
  background: #fff;
  border-top: solid 1px #dcdcdc;
  border-right: solid 1px #dcdcdc;
  display: block;
`;
