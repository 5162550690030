import jeboApi from "api/api";
import AuthService from "api/AuthService";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import {
  activeLocationContext,
  currentLocationContext,
} from "components/providers/LocationContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import uuid from "react-uuid";
import ToastMessageBox from "components/templates/ToastMessageBox";

export default function LocationSearchLayer({ setShowLocationSearchLayer }) {
  const [toastMessage, setToastMessage] = useState("");

  const handleInterLocList = () => {
    setToastMessage("관심지역은 최대 20개까지 등록 가능합니다.");
  };

  const { setActiveLocation } = useContext(activeLocationContext);
  const { getCurrentPosition, currentLocation } = useContext(
    currentLocationContext
  );
  const { isLoggedIn, myInfo, setMyInfo, handleSignOutApp } =
    useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);

  const [resultList, setResultList] = useState([]);
  const [keyword, setKeyword] = useState("");

  const [historySearchLocations, setHistorySearchLocations] = useState(
    AuthService.getHistorySearchLocations()
      ? JSON.parse(AuthService.getHistorySearchLocations())
      : []
  );
  const [current, setCurrent] = useState(false);
  const kakao = window.kakao;
  const history = useHistory();

  // useEffect(() => {
  //   document.getElementsByTagName("html")[0].classList.add("hidden");
  //   window.document.body.classList.add("hidden");
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("hidden");
  //     window.document.body.classList.remove("hidden");
  //   };
  // }, []);

  useEffect(() => {
    if (currentLocation && current) {
      setShowLocationSearchLayer(false);
    }
  }, [currentLocation, setActiveLocation, current, setShowLocationSearchLayer]);

  const searchAddrListFromRegionAddr = useCallback(
    (resultData) => {
      const addressSearchObj = new kakao.maps.services.Geocoder();

      addressSearchObj.addressSearch(
        keyword,
        (result, status, pagination) => {
          if (status === kakao.maps.services.Status.OK) {
            const regionList =
              result && result.length > 0
                ? result.map((data) => {
                    return {
                      address_name: data.address_name ? data.address_name : "",
                      road_address_name: data.road_address
                        ? data.road_address.address_name
                        : "",
                      place_name: "",
                      x: data.x,
                      y: data.y,
                    };
                  })
                : null;
            setResultList(
              resultData && resultData.length > 0
                ? resultData.concat(regionList)
                : regionList
            );
          } else {
            if (resultData && resultData.length > 0) {
              setResultList(resultData);
            } else {
              setResultList([]);
            }
          }
        },
        { size: 10 }
      );
    },
    [kakao, keyword]
  );

  const searchAddrListFromKeyword = useCallback(() => {
    const placeSearchObj = new kakao.maps.services.Places();
    placeSearchObj.keywordSearch(
      keyword,
      (result, status, pagination) => {
        if (status === kakao.maps.services.Status.OK) {
          const placeList =
            result && result.length > 0
              ? result.map((data) => {
                  return {
                    address_name: data.address_name ? data.address_name : "",
                    road_address_name: data.road_address_name
                      ? data.road_address_name
                      : "",
                    place_name: data.place_name,
                    x: data.x,
                    y: data.y,
                  };
                })
              : null;
          searchAddrListFromRegionAddr(placeList);
        } else {
          status === kakao.maps.services.Status.ZERO_RESULT &&
            searchAddrListFromRegionAddr(null);
        }
      },
      { size: 10, page: 1 }
    );
  }, [kakao, keyword, searchAddrListFromRegionAddr]);

  useEffect(() => {
    keyword ? searchAddrListFromKeyword() : setResultList([]);
  }, [keyword, searchAddrListFromKeyword]);

  const handleAddLocation = async ({
    addressName,
    placeName,
    longitude,
    latitude,
  }) => {
    if (!isLoggedIn) {
      return;
    }

    if (myInfo.interLocations.length >= 20) {
      return handleInterLocList();
    }
    //이미 관심지역리스트에 등록된 위치일 경우 거르는 로직 추가
    if (
      myInfo.interLocations.some(
        (interLocation) =>
          interLocation.latitude === Number.parseFloat(latitude) &&
          interLocation.longitude === Number.parseFloat(longitude)
      )
    ) {
      setMyInfo(
        Object.assign({}, myInfo, {
          interLocations: myInfo.interLocations.map((interLocation, index) => {
            if (
              interLocation.latitude === Number.parseFloat(latitude) &&
              interLocation.longitude === Number.parseFloat(longitude)
            )
              return Object.assign({}, interLocation, { selectYn: "Y" });
            else return Object.assign({}, interLocation, { selectYn: "N" });
          }),
        })
      );
    } else {
      let locationInfo = {
        addressName,
        roadAddressName: "",
        placeName,
        latitude,
        longitude,
        selectYn: "Y",
      };

      try {
        const response = await jeboApi.regInterLocation(locationInfo);
        if (response.status === 200) {
          const { interLocations } = JSON.parse(response.data.myInfo);
          setMyInfo(Object.assign({}, myInfo, { interLocations }));
        }
      } catch (error) {
        if (error.response) {
          console.log(error.response);
          if (error.response.status === 403) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            handleSignOutApp();
            setSignLayer("signIn");
            history.replace({ pathname: "/" });
          }
        }
      }
    }
  };

  const handleRemoveLocation = async (locationSeq) => {
    try {
      const response = await jeboApi.removeInterLocation(locationSeq);
      if (response.status === 200) {
        const { interLocations } = JSON.parse(response.data.myInfo);
        setMyInfo(Object.assign({}, myInfo, { interLocations }));
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  };

  const handleAddHistoryLocation = (location) => {
    let locationHistory = historySearchLocations;

    const locationInfo = {
      id: uuid(),
      latitude: location.y,
      longitude: location.x,
      addressName: location.address_name,
      placeName: location.place_name,
    };
    //중복된 검색어가 있다면 삭제 후 마지막 값으로 insert
    if (
      locationHistory.some(
        (searchHistory) =>
          Number.parseFloat(searchHistory.latitude) ===
            Number.parseFloat(locationInfo.latitude) &&
          Number.parseFloat(searchHistory.longitude) ===
            Number.parseFloat(locationInfo.longitude)
      )
    ) {
      const locationId = locationHistory.find(
        (searchHistory) =>
          Number.parseFloat(searchHistory.latitude) ===
            Number.parseFloat(locationInfo.latitude) &&
          Number.parseFloat(searchHistory.longitude) ===
            Number.parseFloat(locationInfo.longitude)
      ).id;
      locationHistory = historySearchLocations.filter(
        (arr) => arr.id !== locationId
      );
    }

    AuthService.setHistorySearchLocation(locationInfo);
    //5개 이상일 때 shift로 첫번째 값 삭제
    // 30개 이상일 때로 변경
    if (locationHistory.length >= 30) locationHistory.pop(); //마지막 값 제거
    locationHistory.unshift(locationInfo); // 첫번째 값으로 add
    setHistorySearchLocations(locationHistory);

    setResultList([]);
    setKeyword("");
  };

  return (
    <div
      className="modal-wrap open"
      style={{ display: "block", overscrollBehavior: "contain" }}
    >
      {toastMessage && (
        <ToastMessageBox
          toastMessage={toastMessage}
          setToastMessage={setToastMessage}
        />
      )}

      <div className="modal modal-search open">
        <div className="modal-header">
          <button
            type="button"
            className="page-back mobile"
            onClick={(e) => {
              e.preventDefault();
              setShowLocationSearchLayer(false);
            }}
          ></button>
          <strong>관심 지역 설정</strong>
          <button className="m-btn mobile"></button>
          <button
            type="button"
            className="modal-close pc"
            onClick={(e) => {
              e.preventDefault();
              setShowLocationSearchLayer(false);
            }}
          ></button>
        </div>
        <div className="modal-body">
          <div className="write-item">
            <div className="input-group search">
              <input
                type="text"
                className="input-control mb-10"
                placeholder="위치를 검색하세요."
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
              />
              {/* <button className="btn-search"></button> */}
              {keyword && (
                <button
                  className="clear-btn"
                  onClick={() => {
                    setKeyword("");
                  }}
                ></button>
              )}
              {resultList && resultList.length > 0 && (
                <div className="search-dropdown open">
                  <ul>
                    {resultList.map((location, index) => {
                      return (
                        <li key={index}>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              handleAddHistoryLocation(location);
                              //handleAddLocation(location);
                            }}
                          >
                            {location.place_name
                              ? `${location.place_name} (${location.address_name})`
                              : location.address_name}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
            <button
              className="btn-location"
              onClick={(e) => {
                getCurrentPosition(true);
                setCurrent(true);
              }}
            >
              현 위치
            </button>
          </div>
          {isLoggedIn && (
            <div className="write-item">
              <div className="input-title location">
                <strong className="ttl">관심 지역</strong>
                <span className="sub-desc">
                  *관심지역은 프로필에서도 수정이 가능합니다.
                </span>
              </div>
              <ul className="k-list locSearch-list">
                {myInfo &&
                  myInfo.interLocations &&
                  myInfo.interLocations.length > 0 &&
                  myInfo.interLocations.map((location) => (
                    <li key={location.locationSeq}>
                      <a
                        href="#!"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          setActiveLocation({
                            latitude: location.latitude,
                            longitude: location.longitude,
                            addressName: location.addressName,
                            placeName: location.placeName,
                          });
                          setShowLocationSearchLayer(false);
                        }}
                      >
                        {location.placeName
                          ? `${location.placeName}(${location.addressName})`
                          : `${location.addressName}`}
                      </a>
                      <button
                        className="delete interloc"
                        onClick={(e) => {
                          e.preventDefault();
                          handleRemoveLocation(location.locationSeq);
                        }}
                      ></button>
                    </li>
                  ))}
              </ul>
            </div>
          )}

          <div className="recentLocation-container">
            <div className="recentLocation-header">
              <h2 className="title">최근 검색 내역</h2>
              <button
                className="delAll-btn"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  AuthService.clearHistorySearchLocations();
                  setHistorySearchLocations([]);
                }}
              >
                전체 삭제
              </button>
            </div>
            <ul className="recentLocation-list">
              {historySearchLocations &&
                historySearchLocations.map((location) => {
                  return (
                    <li
                      className="recentLocation-list__item active"
                      key={location.id}
                    >
                      <button
                        className="recentToFavLocationBtn "
                        type="button"
                        onClick={(e) => {
                          e.target.classList.toggle("active");
                          // e.preventDefault();
                          // e.stopPropagation();
                          handleAddLocation(location);
                        }}
                      ></button>
                      <span
                        onClick={(e) => {
                          setActiveLocation({
                            latitude: location.latitude,
                            longitude: location.longitude,
                            addressName: location.addressName,
                            placeName: location.placeName,
                          });
                          setShowLocationSearchLayer(false);
                        }}
                      >
                        {location.placeName
                          ? `${location.placeName}(${location.addressName})`
                          : `${location.addressName}`}
                      </span>
                      <button
                        className="delete"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          AuthService.removeHistorySearchLocation(location.id);
                          setHistorySearchLocations((prev) =>
                            prev.filter((target) => target.id !== location.id)
                          );
                        }}
                      ></button>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
