import jeboApi from "api/api";
import user_icon from "assets/imgs/icons/user_icon.svg";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Link, useHistory, useLocation } from "react-router-dom";

export default function LikeHistoryLayer() {
  const history = useHistory();

  const { isLoggedIn, myInfo, handleSignOutApp } = useContext(signAppContext);

  const [members, setMembers] = useState([]);

  const { setSignLayer } = useContext(signLayerContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // useEffect(() => {
  //   document.getElementsByTagName("html")[0].classList.add("hidden");
  //   window.document.body.classList.add("hidden");
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("hidden");
  //     window.document.body.classList.remove("hidden");
  //   };
  // }, []);

  const location = useLocation();
  const jeboId = location.state?.jeboId;

  useEffect(() => {
    loadLikeMember();
  }, []);

  const regFollow = async (memberUid) => {
    try {
      const { status, data } = await jeboApi.regFollow(memberUid);

      if (status === 200) {
        console.log(data.followId);
        setMembers((prev) =>
          prev.map((member) =>
            member.memberUid === memberUid
              ? Object.assign({}, member, { followId: data.followId })
              : member
          )
        );
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  };

  const removeFollow = async (followId) => {
    try {
      const { status } = await jeboApi.removeFollow(followId);

      console.log(status);
      if (status === 200) {
        setMembers((prev) =>
          prev.map((member) =>
            member.followId === followId
              ? Object.assign({}, member, { followId: null })
              : member
          )
        );
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  };

  const loadLikeMember = async () => {
    try {
      setLoading(true);

      const { status, data } = await jeboApi.loadLikeMember(jeboId);

      if (status === 200) {
        const members = JSON.parse(data.likeMembers);
        setMembers(members);
      } else if (status === 204) {
        console.log("no data to be loaded..");
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요");
          handleSignOutApp();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return createPortal(
    <div className="modal-wrap open likeHistory-section web">
      <div className="modal modal-detail open">
        <button
          className="close pc"
          onClick={(e) => {
            history.goBack();
          }}
        ></button>
        <div className="modal-header p-header">
          {/* <strong>{memberInfo.nickName} </strong> */}
          <strong>공감내역</strong>
          <button
            type="button"
            className="page-back "
            onClick={(e) => {
              history.goBack();
            }}
          ></button>
        </div>

        <div className="modal-body active">
          {/* 구독자 tab */}
          <div className={"tab-subs-ct tab-subscribed active likeHistory__ct"}>
            <ul className="tab-subs-list likeHistory__list">
              {members &&
                members.length > 0 &&
                members.map((member, idx) => (
                  <li
                    className="tab-subs-list-item likeHistory__user"
                    key={idx}
                  >
                    <span
                      className="tab-subs-list-item__pic"
                      onClick={(e) => {
                        e.preventDefault();
                        if (myInfo && myInfo.memberUid === member.memberUid) {
                          history.push({ pathname: "/mypage" });
                        } else {
                          history.push({
                            pathname: `/member/${encodeURIComponent(
                              member.nickName
                            )}`,
                          });
                        }
                      }}
                    >
                      <img
                        src={
                          member.memberImageUrl
                            ? process.env.REACT_APP_RESOURCE_HOST +
                              member.memberImageUrl
                            : user_icon
                        }
                        alt=""
                      />
                    </span>

                    <span className="tab-subs-list-item__name">
                      <Link
                        to={
                          myInfo && myInfo.memberUid === member.memberUid
                            ? { pathname: "/mypage" }
                            : {
                                pathname: `/member/${encodeURIComponent(
                                  member.nickName
                                )}`,
                              }
                        }
                      >
                        {member.nickName}
                      </Link>
                    </span>
                    {myInfo &&
                      myInfo.memberUid !== member.memberUid &&
                      (member.followId ? (
                        <button
                          className="btn-edit subscribing-btn"
                          onClick={(e) => {
                            removeFollow(member.followId);
                          }}
                        >
                          구독 해제
                        </button>
                      ) : (
                        <button
                          className="btn-edit subscribe-btn"
                          onClick={(e) => {
                            isLoggedIn
                              ? regFollow(member.memberUid)
                              : setSignLayer("signIn");
                          }}
                        >
                          구독 하기
                        </button>
                      ))}
                  </li>
                ))}
            </ul>
          </div>
        </div>
        {/* 구독자 목록 mobile popup */}
      </div>
    </div>,
    document.getElementById("modal")
  );
}
