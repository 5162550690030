import { useCallback, useEffect, useState } from "react";

const useRecentKeywords = (keyword, storageKey) => {
  const [recentKeywords, setRecentKeywords] = useState([]);

  const saveKeyword = useCallback(() => {
    if (keyword) {
      const STORAGE_KEYWORDS = localStorage.getItem(storageKey);
      let keywords = [];

      if (STORAGE_KEYWORDS) {
        const recentKeywords = JSON.parse(STORAGE_KEYWORDS);
        if (recentKeywords && recentKeywords.length > 0) {
          if (recentKeywords.some((item) => item === keyword))
            keywords = [
              keyword,
              ...recentKeywords.filter((item) => item !== keyword),
            ];
          else if (recentKeywords.length >= 5) {
            keywords = [keyword, ...recentKeywords];
            keywords.pop();
          } else keywords = [keyword, ...recentKeywords];

          localStorage.setItem(storageKey, JSON.stringify(keywords));
        } else {
          localStorage.setItem(storageKey, JSON.stringify([keyword]));
        }
      } else {
        localStorage.setItem(storageKey, JSON.stringify([keyword]));
      }
    }

    const STORAGE_KEYWORDS = localStorage.getItem(storageKey);
    const recentKeywords = JSON.parse(STORAGE_KEYWORDS);
    setRecentKeywords(recentKeywords ? recentKeywords : []);
  }, [storageKey, keyword]);

  useEffect(() => {
    saveKeyword();
  }, [saveKeyword]);

  const removeKeyword = (keyword) => {
    const recentKeywords = JSON.parse(localStorage.getItem(storageKey));

    if (keyword) {
      localStorage.setItem(
        storageKey,
        JSON.stringify(
          recentKeywords.filter((recentKeyword) => recentKeyword !== keyword)
        )
      );
      setRecentKeywords((prev) =>
        prev.filter((recentKeword) => recentKeword !== keyword)
      );
    } else {
      localStorage.setItem(storageKey, JSON.stringify([]));
      setRecentKeywords([]);
    }
  };

  return { recentKeywords, removeKeyword };
};

export default useRecentKeywords;
