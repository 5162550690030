import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import Comment from "./Comment";
import CommentWriteForm from "./CommentWriteForm";
import qs from "query-string";
import { feedsContext } from "components/providers/FeedContextProvider";
import { useContext } from "react";

const commentReducer = (state, action) => {
  switch (action.type) {
    case "MENU_OPEN":
      return { ...state, id: action.id, name: action.name };
    case "MODE":
      return { ...state, id: action.id, name: action.name };
    case "INIT":
      return initialState;
    default:
      return;
  }
};

const initialState = {
  id: "",
  name: "COMMENT",
};

export default function CommentContainer({ feed, setFeed }) {
  const { jeboCommentList: comments } = feed;

  const [showCommentCnt, setShowCommentCnt] = useState(0);

  const reCommentTextAreaRef = useRef();

  const location = useLocation();
  const { focusType, focusId } = qs.parse(location.search);

  const [state, dispatch] = useReducer(commentReducer, initialState);
  const { setFeeds } = useContext(feedsContext);

  useEffect(() => {
    if (comments.length > 2) setShowCommentCnt(2);
    else if (comments.length > 0) setShowCommentCnt(comments.length);
  }, [comments]);

  const prevCommentsLength = useRef(comments.length);
  useEffect(() => {
    if (prevCommentsLength.current < comments.length) {
      let updatedCommentCnt = comments.length - prevCommentsLength.current;
      setShowCommentCnt(showCommentCnt + updatedCommentCnt);
    }
    prevCommentsLength.current = comments.length;
  }, [comments, showCommentCnt]);

  const onClose = () => {
    dispatch({
      type: "INIT",
    });
  };

  const onBlock = (memberUid) => {
    setFeeds((prev) => prev.filter((item) => item.memberUid !== memberUid));
  };

  return (
    <div className="comments">
      {comments && comments.length > 0 && (
        <div className="comments-list">
          {comments &&
            comments.map((comment, index) => {
              return (
                <Comment
                  key={index}
                  comment={comment}
                  feed={feed}
                  setFeed={setFeed}
                  state={state}
                  dispatch={dispatch}
                  reCommentTextAreaRef={reCommentTextAreaRef}
                  onClose={onClose}
                  onBlock={onBlock}
                  focus={
                    focusType &&
                    focusType === "comment" &&
                    focusId &&
                    focusId === comment.commentId
                      ? true
                      : false
                  }
                />
              );
            })}
        </div>
      )}

      {state.name === "COMMENT" && (
        <CommentWriteForm feed={feed} setFeed={setFeed} />
      )}
    </div>
  );
}
