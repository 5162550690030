import React from "react";

export default function Sections({ children }) {
  return (
    <div className="contents-wrap setting">
      <div className="section-setting">
        <div className="container">
          <div className="setting-wrap">{children}</div>
        </div>
      </div>
    </div>
  );
}
