import FeedContextProvider from "components/providers/FeedContextProvider";
import LayerContextProvider from "components/providers/LayerContextProvider";
import SessionContextProvider from "components/providers/SessionContextProvider";

export default function SearchContextProvider({
  feeds,
  layer,
  session,
  children: pageComponent,
}) {
  return (
    <FeedContextProvider feeds={feeds}>
      <LayerContextProvider layer={layer}>
        <SessionContextProvider session={session}>
          {pageComponent}
        </SessionContextProvider>
      </LayerContextProvider>
    </FeedContextProvider>
  );
}
