import { AdsMapDataContext } from "components/providers/AdsMapContextProvider";
import { convenienceDataContext } from "components/providers/ConvenienceDataContextProvider";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

export default function Sections({ children, eventInfo, conMapData }) {
  const { keyword } = useParams();

  return (
    <div className="contents-wrap search">
      <main className="content">
        <div className="search-word pc">
          {eventInfo && <span>{`[${eventInfo.eventName}]`}</span>}

          {!conMapData && keyword && (
            <NormarContainer>
              <span>{decodeURIComponent(keyword)}</span>
            </NormarContainer>
          )}
        </div>
        {children}
      </main>
    </div>
  );
}

const NormarContainer = styled.div`
  padding-top: 60px;
`;

const Container = styled.div`
  height: 120px;
  background: linear-gradient(90deg, #3484fd 0%, #29bef7 100%);
`;

const Section = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const Banner = styled.div`
  text-align: center;
  flex-direction: column;
  overflow-x: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Strong = styled.div`
  display: inline-block;
  font-size: 1.313rem;
  line-height: 1.3em;
  font-weight: 400;
  word-break: keep-all;
  color: #fff;
  margin-right: 20px;
`;

const AddButton = styled.div`
  margin-top: 13px;

  color: #fff;
  background: transparent;
  border: solid 2px #fff;

  display: inline-block;
  width: auto;
  min-width: 145px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 36px;
  font-size: 0.875rem;
  cursor: pointer;
`;
