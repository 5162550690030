import jeboApi from "api/api";
import InduceAppPopLayer from "components/layers/popMenu/InduceAppPopLayer";
import { AdsMapDataContext } from "components/providers/AdsMapContextProvider";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { feedsContext } from "components/providers/FeedContextProvider";
import { activeLocationContext } from "components/providers/LocationContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import FeedContainer from "components/templates/FeedContainer";
import FeedInsertedCampaign from "components/templates/FeedInsertedCampaign";
import FeedSortTab from "components/templates/FeedSortTab";
import TrafficInfoBox from "components/templates/TrafficInfoBox";
import WeatherInfoBox from "components/templates/WeatherInfoBox";
import qs from "query-string";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import AdsInfoSection from "./custom/AdsInfoSection";
import CategoryTabSection from "./CategoryTabSection";
import TopBandBannerSection from "./TopBandBannerSection";

const throttler = (() => {
  let throttleCheck;

  return {
    throttle(callback, milliseconds) {
      if (!throttleCheck) {
        throttleCheck = setTimeout(() => {
          callback(...arguments);
          throttleCheck = false;
        }, milliseconds);
      }
    },
  };
})();

const debouncer = (() => {
  let debounceCheck;

  return {
    debounce(callback, milliseconds) {
      clearTimeout(debounceCheck);
      debounceCheck = setTimeout(() => {
        callback(...arguments);
      }, milliseconds);
    },
  };
})();

const popMenuReducer = (state, action) => {
  switch (action.type) {
    case "DISPLAY_MENU":
      return {
        ...state,
        id: action.id,
        name: action.name,
      };
    case "CLEAR":
      return initialPopMenuState;

    default:
      return state;
  }
};

const initialPopMenuState = {
  id: "",
  name: "",
};

const END_LIMIT = 15;
export default function FeedSection() {
  /* 피드 로딩 할지 말지 여부 처리 시작 23.05.16 codelua. ss session약어 */
  const ssFeeds = sessionStorage.getItem("homeFeedData")
    ? sessionStorage.getItem("homeFeedData")
    : null;
  const ssScrollY = sessionStorage.getItem("homeFeedScrollY")
    ? sessionStorage.getItem("homeFeedScrollY")
    : 0;
  const ssHomeCursor = sessionStorage.getItem("homeFeedCursor")
    ? sessionStorage.getItem("homeFeedCursor")
    : null;
  const ssHomeSort = sessionStorage.getItem("homeFeedSort")
    ? sessionStorage.getItem("homeFeedSort")
    : null;
  const ssHomeCategory = sessionStorage.getItem("homeFeedCategory")
    ? sessionStorage.getItem("homeFeedCategory")
    : null;

  /* 피드 로딩 할지 말지 여부 처리 끝  */

  // 광고 링크 클릭 시 분기 처리 시작
  const { adsMapData } = useContext(AdsMapDataContext);
  //실제 광고용 카드색션? 피드안의카드색션? 이거 정해져야 함 2023.04.20 codelua
  //1.  QueryString 판단
  const location = useLocation();
  const { gubun, classNum } = qs.parse(location.search);
  const [adsAction, setAdsAction] = useState(null);
  const [adLink, setAdLink] = useState(null);
  useEffect(() => {
    adsMapData &&
      adsMapData.forEach((info) => {
        if (info.gubun === gubun && info.classNum === classNum) {
          // info.actionCode로 어찌 할지는 이후 진행방향을 확인 후 !!
          setAdsAction(info.actionCode);
          setAdLink(info.adLink);
        }
      });
  }, [gubun, classNum, adsMapData]);
  // 광고 링크 클릭 시 분기 처리 끝

  const { feeds, setFeeds } = useContext(feedsContext);
  const { myInfo } = useContext(signAppContext);
  const { activeLocation } = useContext(activeLocationContext);
  const { isMobile, mobileType } = useContext(isMobileContext);

  const [lastFeedRef, inView] = useInView({ threshold: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [weatherFeed, setWeatherFeed] = useState(null);
  const [trafficFeed, setTrafficFeed] = useState(null);

  //앱 유도 팝업 6시간에 한번 씩
  const EXPIRED_KEY = "induce_app_expired_home";
  const EXPIRED_TIME = localStorage.getItem(EXPIRED_KEY);

  const [openInducePop, setOpenInduceApp] = useState(
    EXPIRED_TIME && EXPIRED_TIME > new Date() ? false : true
  );

  const [popMenu, dispatchPopMenu] = useReducer(
    popMenuReducer,
    initialPopMenuState
  );

  //피드 조회 조건
  const [cursor, setCursor] = useState({
    cursor: 0,
    baseTime:
      new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0] +
      " " +
      new Date().toTimeString().split(" ")[0],
    intervalDay: 10,
  });
  const [sort, setSort] = useState(ssHomeSort ? ssHomeSort : "");
  const [category, setCategory] = useState(
    ssHomeCategory ? ssHomeCategory : ""
  );
  const [isLastLoad, setIsLastLoad] = useState(false);

  useEffect(() => {
    // Feeds가 로딩 되어있고 세션에 저장된 스크롤y 포지션이 0 초과라면 스크롤 이동해준다.
    if (feeds.length > 0 && ssScrollY > 0) {
      window.scrollTo(0, ssScrollY);
      // 23.05.16 codelua 세션에 저장 된 값을 사용 되었으니 이제 세션에서 지워준다. (이후 사이드이펙트가 일어나지 않기 위해)
      sessionStorage.removeItem("homeFeedData");
      sessionStorage.removeItem("homeFeedScrollY");
      sessionStorage.removeItem("homeFeedCursor");
    }
  }, [feeds, ssScrollY]);

  // 값 초기화.
  useEffect(() => {
    setWeatherFeed(null);
    setTrafficFeed(null);
    setFeeds([]);
    if (ssFeeds && ssScrollY > 0 && ssHomeCursor) {
      setCursor(JSON.parse(ssHomeCursor));
    } else {
      setCursor({
        cursor: ssScrollY > 0 ? ssHomeCursor : 0,
        baseTime:
          new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0] +
          " " +
          new Date().toTimeString().split(" ")[0],
        intervalDay: 10,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFeeds, activeLocation, category, sort, myInfo.interTag]);

  const loadFeeds = useCallback(async () => {
    // codeluafix
    try {
      setLoading(true);
      /*
        FeedSortTab 컴포넌트는 Search에도 공유하여 사용중이므로,
        (Home)FeedSection에서 sort가 변경될 때 sessionStorage에 Set 해준다.
        CategoryTabSection 은 불리되어 있지만 Sort와 동일하게 처리
      */
      sessionStorage.setItem("homeFeedSort", sort);
      sessionStorage.setItem("homeFeedCategory", category);
      if (ssFeeds && ssScrollY > 0) {
        setFeeds(JSON.parse(ssFeeds));
      } else {
        const { status, data } = await jeboApi.feedList(
          category,
          myInfo.interTag,
          activeLocation ? activeLocation.latitude : null,
          activeLocation ? activeLocation.longitude : null,
          END_LIMIT,
          cursor.cursor,
          sort,
          cursor.baseTime,
          cursor.intervalDay,
          adsAction
        );

        if (status === 200) {
          const jeboPostArr = JSON.parse(data.jeboPost);

          // setIsLastLoad(false);
          // if (cursor.cursor === 0) setFeeds(jeboPostArr);
          // else setFeeds((prev) => prev.concat(jeboPostArr));
          setFeeds((prev) => prev.concat(jeboPostArr));

          setWeatherFeed(
            jeboPostArr &&
              jeboPostArr.length > 0 &&
              jeboPostArr[0].weatherInfoVo
              ? jeboPostArr[0].weatherInfoVo
              : null
          );
          setTrafficFeed(
            jeboPostArr &&
              jeboPostArr.length > 0 &&
              jeboPostArr[0].trafficInfoList
              ? jeboPostArr[0].trafficInfoList
              : null
          );
          setIsLastLoad(jeboPostArr.length < END_LIMIT ? true : false);
        } else if (status === 204) {
          console.log("no data to be loaded..");
          setIsLastLoad(true);
        }
      }
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    category,
    myInfo.interTag,
    activeLocation,
    cursor.cursor,
    cursor.baseTime,
    cursor.intervalDay,
    sort,
    adsAction,
    setFeeds,
  ]);

  useEffect(() => {
    activeLocation && debouncer.debounce(loadFeeds, 500);
  }, [activeLocation, loadFeeds]);

  useEffect(() => {
    if (inView && !error) {
      setCursor((prev) =>
        Object.assign({}, prev, {
          cursor: isLastLoad ? 0 : prev.cursor + END_LIMIT,
          intervalDay: isLastLoad ? prev.intervalDay + 10 : prev.intervalDay,
        })
      );
    }
  }, [inView, error, isLastLoad]);

  useEffect(() => {
    cursor && sessionStorage.setItem("homeFeedCursor", JSON.stringify(cursor));
  }, [cursor]);

  const handleRefresh = useCallback(async () => {
    setCursor({
      cursor: 0,
      baseTime:
        new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0] +
        " " +
        new Date().toTimeString().split(" ")[0],
      intervalDay: 10,
    });
  }, []);

  const onUpdateFeed = (updatedFeed) => {
    setFeeds((prev) =>
      prev.map((item) => {
        if (item.jeboId === updatedFeed.jeboId) {
          return Object.assign({}, item, updatedFeed);
        }
        return item;
      })
    );
  };

  const onBlockFeed = (memberUid) => {
    setFeeds((prev) => prev.filter((item) => item.memberUid !== memberUid));
  };

  const onDeleteFeed = (feed) => {
    setFeeds((prev) => prev.filter((item) => item.jeboId !== feed.jeboId));
    loadFeeds();
  };

  return (
    <>
      <section className="section-feed">
        <CategoryTabSection category={category} setCategory={setCategory} />
        <div className="container home">
          <div className="cont-sort">
            <FeedSortTab sort={sort} setSort={setSort} />
          </div>
          <PullToRefresh
            onRefresh={handleRefresh}
            pullingContent=""
            refreshingContent={
              <div className="feed-spinner">
                <span></span>
                <span></span>
                <span></span>
              </div>
            }
            className="pulling-contents"
          >
            {/* 23.02.16 codelua 신규 배너 고정 처리 부분.  */}
            {isMobile && feeds && feeds.length > 0 && (
              <>
                <TopBandBannerSection cursor={cursor} classNum={classNum} />
              </>
            )}

            <div className={`cont-feed ${isMobile && "nopadding"}`}>
              {/* 23.02.16 codelua 기존 버전 테스트 끝나기전에는 아래 유지.  */}
              {/* <div className="cont-feed"> */}
              <div className="list">
                {/* 23.05.09 codelua 광고소재 번호 1005인 경우 슬라이드 용 화면이 나오도록 하드코딩.  */}
                {feeds &&
                  feeds.length > 0 &&
                  classNum &&
                  classNum === "1005" && <AdsInfoSection feed={feeds} />}
                {weatherFeed && <WeatherInfoBox weatherInfo={weatherFeed} />}
                {trafficFeed && (
                  <TrafficInfoBox trafficInfoList={trafficFeed} />
                )}
                {feeds &&
                  feeds.length > 0 &&
                  feeds.map((feed, index, arr) =>
                    feed.eventId && feed.eventTypeCode ? (
                      <FeedInsertedCampaign key={index} feed={feed} />
                    ) : (
                      <FeedContainer
                        key={index}
                        feed={feed}
                        popMenu={popMenu}
                        dispatchPopMenu={dispatchPopMenu}
                        onUpdateFeed={onUpdateFeed}
                        onDeleteFeed={onDeleteFeed}
                        onBlockFeed={onBlockFeed}
                        page="home"
                      />
                    )
                  )}
                {feeds && feeds.length > 0 && !loading && (
                  <div ref={lastFeedRef}></div>
                )}
                {!error && loading && (
                  <div className="feed-spinner">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                )}
              </div>
            </div>
          </PullToRefresh>
          {isMobile && mobileType === "browser" && openInducePop && (
            <InduceAppPopLayer
              setOpenInduceApp={setOpenInduceApp}
              expiredKey={EXPIRED_KEY}
              targetUrl={adLink}
            />
          )}
        </div>
      </section>
    </>
  );
}
