import { feedCategoriesContext } from "components/providers/FeedContextProvider";
import { useContext, useRef } from "react";
import SwiperCore, {
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Pagination, Navigation, Keyboard, Mousewheel]);
export default function CategoryTabSection({ category, setCategory }) {
  const { feedCategories } = useContext(feedCategoriesContext);

  const refSectionTab = useRef();

  return (
    <section className="section-tab" ref={refSectionTab}>
      <div className="tab-btns">
        <Swiper
          className="tab-swiper swiper-container"
          freeMode={true}
          slidesPerView="auto"
          spaceBetween={10}
        >
          <SwiperSlide>
            <button
              className={category ? `btn type0` : `btn type0 active`}
              name="report_type"
              onClick={(e) => {
                setCategory("");
              }}
            >
              전체
            </button>
          </SwiperSlide>
          {feedCategories.map((feedCategory, index) => (
            <SwiperSlide key={index}>
              <button
                key={feedCategory.categoryCode}
                type="checkbox"
                className={
                  category === feedCategory.categoryName
                    ? `btn type${feedCategory.categoryCode} active`
                    : `btn type${feedCategory.categoryCode}`
                }
                name="report_type"
                onClick={(e) => {
                  setCategory(feedCategory.categoryName);
                }}
              >
                {feedCategory.categoryName}
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
