import jeboApi from "api/api";
import MobileLikeHistory from "components/pages/MobileLikeHistory/MobileLikeHistory";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { feedsContext } from "components/providers/FeedContextProvider";
import { activeLocationContext } from "components/providers/LocationContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import FeedDetailContainer from "components/templates/FeedDetailContainer";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import {
  Route,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import LikeHistoryLayer from "../likeHistory/LikeHistoryLayer";
import SignLayer from "../sign/SignLayer";
import FeedEditLayer from "../write/FeedEditLayer";

const popMenuReducer = (state, action) => {
  switch (action.type) {
    case "DISPLAY_MENU":
      return {
        ...state,
        id: action.id,
        name: action.name,
      };
    case "CLEAR":
      return initialPopMenuState;

    default:
      return state;
  }
};

const initialPopMenuState = {
  id: "",
  name: "",
};

export default function FeedDetailLayer() {
  const { feeds, setFeeds } = useContext(feedsContext);
  const { myInfo, isLoggedIn } = useContext(signAppContext);
  const { isMobile } = useContext(isMobileContext);
  const { activeLocation } = useContext(activeLocationContext);

  const [feed, setFeed] = useState(null);
  const [loading, setLoading] = useState(true);
  //const [error, setError] = useState(null);

  const [popMenu, dispatchPopMenu] = useReducer(
    popMenuReducer,
    initialPopMenuState
  );

  const [nextJeboId, setNextJeboId] = useState("");
  const [prevJeboId, setPrevJeboId] = useState("");

  const history = useHistory();
  const match = useRouteMatch();

  const { jeboId } = useParams();

  const modalRef = useRef();

  useEffect(() => {
    feed && setLoading(false);
  }, [feed]);

  useEffect(() => {
    if (feeds && feeds.length > 0 && feed && feed.jeboId) {
      const index = feeds.findIndex((item) => item.jeboId === feed.jeboId);
      console.log(">>>>> item::", feed);

      const prevFeeds = feeds
        .slice(0, index > 0 ? index : 0)
        .filter((item) => item.memberUid);

      if (prevFeeds && prevFeeds.length > 0) {
        setPrevJeboId(prevFeeds[prevFeeds.length - 1].jeboId);
      }

      const nextFeeds = feeds
        .slice(
          0 <= index && index < feeds.length ? index + 1 : feeds.length,
          feeds.length
        )
        .filter((item) => item.memberUid);

      if (nextFeeds && nextFeeds.length > 0) {
        setNextJeboId(nextFeeds[0].jeboId);
      }
    }
  }, [feeds, feed]);

  const getFeedDetail = useCallback(async () => {
    try {
      setLoading(true);

      const { status, data } = await jeboApi.feedDetail(
        jeboId,
        myInfo.memberUid,
        activeLocation ? activeLocation.latitude : null,
        activeLocation ? activeLocation.longitude : null
      );

      if (status === 200) {
        const jeboPost = JSON.parse(data.jeboPost);
        setFeed(jeboPost);
      } else if (status === 204) {
        alert("삭제된 게시물 입니다.");
        const url = match.url?.slice(0, match.url?.lastIndexOf("/detail"));
        history.replace({
          pathname: url ? url : "/",
        });
        console.log("no data to be loaded..");
      }
    } catch (error) {
      console.log(error);
      const url = match.url?.slice(0, match.url?.lastIndexOf("/detail"));
      history.replace({
        pathname: url ? url : "/",
      });
      //setError(error);
    }
  }, [jeboId, myInfo.memberUid, activeLocation, history, match.url]);

  useEffect(() => {
    getFeedDetail();
  }, [getFeedDetail]);

  const onUpdateFeed = (updatedFeed) => {
    setFeed((prev) => Object.assign({}, prev, updatedFeed));
    setFeeds((prev) =>
      prev.map((item) => {
        if (item.jeboId === updatedFeed.jeboId) {
          return Object.assign({}, item, updatedFeed);
        }
        return item;
      })
    );
  };

  const onBlockFeed = (memberUid) => {
    setFeeds((prev) => prev.filter((item) => item.memberUid !== memberUid));
    history.goBack();
  };

  const onDeleteFeed = (feed) => {
    setFeeds((prev) => prev.filter((item) => item.jeboId !== feed.jeboId));
    history.goBack();
  };

  //부모창 스크롤 방지 추가
  useEffect(() => {
    //Component Mount 시 동작
    document.scrollingElement.style.overflow = "hidden";

    //Component UnMount 시 동작
    return () => {
      document.scrollingElement.style.overflow = "scroll";
    };
  }, []);

  return (
    <>
      {createPortal(
        <>
          <div
            className="modal-wrap open"
            ref={modalRef}
            onMouseDown={(e) => {
              if (e.target === modalRef.current) {
                const url = match.url?.slice(
                  0,
                  match.url?.lastIndexOf("/detail")
                );

                history.length <= 1
                  ? history.replace({
                      pathname: url ? url : "/",
                    })
                  : history.goBack();
              }
            }}
          >
            <div className="modal modal-detail open">
              {!loading && (
                <>
                  <button
                    className="close pc"
                    onClick={() => {
                      if (history.length <= 1) {
                        const url = match.url?.slice(
                          0,
                          match.url?.lastIndexOf("/detail")
                        );

                        history.replace({
                          pathname: url ? url : "/",
                        });
                      } else {
                        history.goBack();
                      }
                    }}
                  ></button>
                  {prevJeboId && (
                    <button
                      className="btn-prev"
                      onClick={() => {
                        const url = match.url?.slice(
                          0,
                          match.url?.lastIndexOf("/")
                        );
                        history.replace({
                          pathname: `${url}/${prevJeboId}`,
                        });
                      }}
                    >
                      prev
                    </button>
                  )}
                  {nextJeboId && (
                    <button
                      className="btn-next"
                      onClick={() => {
                        const url = match.url?.slice(
                          0,
                          match.url?.lastIndexOf("/")
                        );
                        history.replace({
                          pathname: `${url}/${nextJeboId}`,
                        });
                      }}
                    >
                      next
                    </button>
                  )}
                  <div className="modal-header mobile">
                    <button
                      type="button"
                      className="page-back mobile"
                      onClick={() => {
                        if (history.length <= 1) {
                          const url = match.url?.slice(
                            0,
                            match.url?.lastIndexOf("/detail")
                          );

                          history.replace({
                            pathname: url ? url : "/",
                          });
                        } else {
                          history.goBack();
                        }
                      }}
                    ></button>
                    <strong>{`${feed.nickName}님의 제보`}</strong>
                  </div>
                  <div className="modal-body">
                    <FeedDetailContainer
                      feed={feed}
                      setFeed={setFeed}
                      popMenu={popMenu}
                      dispatchPopMenu={dispatchPopMenu}
                      onUpdateFeed={onUpdateFeed}
                      onDeleteFeed={onDeleteFeed}
                      onBlockFeed={onBlockFeed}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </>,
        document.getElementById("modal")
      )}
      <Route
        path={`${match.path}/edit`}
        render={(props) =>
          isLoggedIn ? <FeedEditLayer setDetailFeed={setFeed} /> : <SignLayer />
        }
      />
      <Route
        path={`${match.path}/likeHistory`}
        render={() => (isMobile ? <MobileLikeHistory /> : <LikeHistoryLayer />)}
      />
    </>
  );
}
