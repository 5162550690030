import jeboApi from "api/api";
import AuthService from "api/AuthService";
import SignUpComplete from "components/layers/sign/SignUpComplete";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { Base64 } from "js-base64";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
//import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import SignUpAgreeLayer from "./SignUpAgreeLayer";

export default function SignInLayerForm({ setShowFindPasswordLayer }) {
  const { handleSignInApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const { mobileType } = useContext(isMobileContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [keepId, setKeepId] = useState(false);
  const [isAutoLogin, setIsAutoLogin] = useState(false);
  const [emailInputStateMessage, setEmailInputStateMessage] = useState("");
  const [passwordInputStateMessage, setPasswordInputStateMessage] =
    useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [pwdError, setPwdError] = useState("");

  const [openTermsOfJebo, setOpenTermsOfJebo] = useState(false);
  const [infoStruct, setInfoStruct] = useState(null);

  const [openComplete, setOpenComplete] = useState(false);
  const [memberYn, setMemberYn] = useState("");

  const naver = window.naver;
  const kakao = window.Kakao;
  const emailRegExp = useMemo(
    () =>
      /^(([^<>()\\[\].,;:\s@"]+(\.[^<>()\\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    []
  );

  // useEffect(() => {
  //   document.getElementsByTagName("html")[0].classList.add("hidden");
  //   window.document.body.classList.add("hidden");
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("hidden");
  //     window.document.body.classList.remove("hidden");
  //   };
  // }, []);

  //아이디저장, 자동로그인 상태 확인
  useEffect(() => {
    const savedKeepId = AuthService.getKeepId();

    if (savedKeepId !== null) {
      setKeepId(true);
      setEmail(savedKeepId);
    }
  }, []);

  useEffect(() => {
    setOpenTermsOfJebo(infoStruct ? true : false);
  }, [infoStruct]);

  useEffect(() => {
    if (emailError) {
      setPwdError("");
      setPasswordInputStateMessage("");
    }
  }, [emailError, pwdError]);

  useEffect(() => {
    if (!email) {
      setEmailError("");
      setEmailInputStateMessage("");
    } else if (email && !emailRegExp.test(email)) {
      setEmailError("");
      setEmailInputStateMessage("이메일 형식이 잘못되었습니다.");
    } else {
      setEmailError("");
      setEmailInputStateMessage("");
    }

    !password ? setPwdError("") : setPasswordInputStateMessage("");
  }, [email, password, emailRegExp]);

  const inputSignInData = useCallback(() => {
    if (!email) {
      setEmailInputStateMessage("이메일을 입력하세요.");
      return false;
    } else if (email && !emailRegExp.test(email)) {
      setEmailInputStateMessage("이메일 형식이 잘못되었습니다.");
      return false;
    } else {
      setEmailInputStateMessage("");
      setEmailError("");
    }

    if (!password) {
      setPasswordInputStateMessage("비밀번호를 입력하세요.");
      return false;
    } else {
      setPasswordInputStateMessage("");
    }
    return true;
  }, [email, password, emailRegExp]);

  const signIn = useCallback(
    async (deviceId, fcmToken) => {
      if (!inputSignInData()) return;
      try {
        const { headers } = await jeboApi.signIn(
          email,
          password,
          deviceId,
          fcmToken,
          mobileType
        );

        if (mobileType === "ios" || mobileType === "android") {
          headers.refresh_token &&
            AuthService.setAutoLoginToken(headers.refresh_token);
          headers.member_email && AuthService.setKeepId(headers.member_email);
        } else {
          if (isAutoLogin) {
            headers.refresh_token &&
              AuthService.setAutoLoginToken(headers.refresh_token);
            headers.member_email && AuthService.setKeepId(headers.member_email);
          } else if (keepId)
            headers.member_email && AuthService.setKeepId(headers.member_email);
          else {
            AuthService.removeAutoLoginToken();
            AuthService.removeKeepId();
          }
        }

        handleSignInApp(
          headers.authorization,
          headers.refresh_token,
          headers.member_email,
          Base64.decode(headers.member_nickname),
          headers.grant_type,
          headers.member_uid,
          headers.social_yn,
          "email"
        );

        setSignLayer("");
      } catch (error) {
        if (error.response) {
          const {
            response: { data, status },
          } = error;
          if (status === 401) {
            data.errorMessage === "NotFoundMember"
              ? setEmailError(data.errorDescription)
              : setPwdError(data.errorDescription);
          } else setError("알 수 없는 에러 발생. 관리자에게 문의 해주세요.");
        }
      }
    },
    [
      email,
      handleSignInApp,
      inputSignInData,
      isAutoLogin,
      keepId,
      mobileType,
      password,
      setSignLayer,
    ]
  );

  const socialSignIn = useCallback(
    async (id, email, name, socialType, deviceId, fcmToken, agreeItems) => {
      try {
        const { headers } = await jeboApi.socialSignIn(
          id,
          email,
          name,
          socialType,
          deviceId,
          fcmToken,
          mobileType,
          agreeItems
        );
        if (mobileType === "ios" || mobileType === "android") {
          headers.refresh_token
            ? AuthService.setAutoLoginToken(headers.refresh_token)
            : AuthService.removeAutoLoginToken();
        } else {
          if (isAutoLogin) {
            headers.refresh_token &&
              AuthService.setAutoLoginToken(headers.refresh_token);
            headers.member_email && AuthService.setKeepId(headers.member_email);
          }
        }

        handleSignInApp(
          headers.authorization,
          headers.refresh_token,
          headers.member_email,
          Base64.decode(headers.member_nickname),
          headers.grant_type,
          headers.member_uid,
          headers.social_yn,
          socialType
        );
      } catch (error) {
        console.error("error : ", error);
      } finally {
        memberYn === "N" ? setOpenComplete(true) : setSignLayer("");
      }
    },
    [handleSignInApp, isAutoLogin, memberYn, mobileType, setSignLayer]
  );

  const signInAppIOS = useCallback(
    async (encodedUserInfo) => {
      let jsonUserInfo = Base64.decode(encodedUserInfo);
      let userInfo = JSON.parse(jsonUserInfo);

      window.webkit.messageHandlers.print.postMessage(jsonUserInfo);

      if (userInfo.loginType === "email") {
        signIn(userInfo.deviceId, userInfo.fcmToken);
      } else {
        const { data } = await jeboApi.existMemberYn(
          userInfo.id,
          userInfo.loginType
        );
        setMemberYn(data.existMemberYn);
        if (data.existMemberYn === "N") {
          setInfoStruct({
            id: userInfo.id,
            email: userInfo.email,
            name: userInfo.nickName.replace(/[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]/g, ""),
            socialType: userInfo.loginType,
            deviceType: userInfo.deviceType,
            fcmToken: userInfo.fcmToken,
          });
        } else if (data.existMemberYn === "G") {
          alert("재가입은 탈퇴일로 부터 7일 후에 가능합니다");
        } else if (data.existMemberYn === "F") {
          alert("영구정지 상태로 재가입이 불가합니다.");
        } else {
          socialSignIn(
            userInfo.id,
            userInfo.email,
            userInfo.nickName,
            userInfo.loginType,
            userInfo.deviceId,
            userInfo.fcmToken
          );
        }
      }
    },
    [signIn, socialSignIn]
  );

  useEffect(() => {
    // 이거 테스트코드이니 언제든 이펙트 자체 삭제해도 됨
    console.log(">>>>> mobileType: ", mobileType);
  }, [mobileType]);

  useEffect(() => {
    window.signInAppIOS = signInAppIOS;

    return () => {
      window.signInAppIOS = () => {};
    };
  }, [signInAppIOS]);

  const naverInit = useCallback(() => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: process.env.REACT_APP_NAVER_CLIENT_KEY,
      callbackUrl: `${process.env.REACT_APP_HOST}/naverLoginSuccess`,
      isPopup: false,
      loginButton: { color: "green", type: 1, height: 30 },
    });

    naverLogin.init();
    naver.successCallback = socialSignIn;
  }, [naver, socialSignIn]);

  const kakaoInit = useCallback(() => {
    !kakao.isInitialized() && kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
  }, [kakao]);

  useEffect(() => {
    naverInit();
    kakaoInit();
  }, [naverInit, kakaoInit]);

  const handleNaverLogin = () => {
    const naverLoginButton = document.querySelector("#naverIdLogin a");
    naverLoginButton.click();
  };

  //WEB - kakao login
  const getKakaoUserInfo = (userInfo) => {
    const { id, kakao_account } = userInfo;

    let deviceId = "";
    let fcmToken = "";

    if (mobileType === "android") {
      let returnString = window.androidApp.getDeviceTokenInfo();

      deviceId = returnString.split("@@")[0];
      fcmToken = returnString.split("@@")[1];
    }

    socialSignIn(
      id,
      kakao_account.email,
      "profile" in kakao_account ? kakao_account.profile.nickname : "",
      "kakao",
      deviceId,
      fcmToken
    );
  };

  const handleKakaoLogin = () => {
    kakao.Auth.login({
      success: (response) => {
        kakao.API.request({
          url: "/v2/user/me",
          success: getKakaoUserInfo,
          fail: (error) => {
            console.log("kakao get userInfo error : ", error);
          },
        });
      },
      fail: (error) => {
        console.log("kakao login error : ", error);
      },
    });
  };

  //WEB - facebook login
  /*
  const getFacebookUserInfo = async (userInfo) => {
    if (userInfo.id &&  userInfo.name) {
      const { data } = await jeboApi.existMemberYn(userInfo.id, "facebook");
      setMemberYn(data.existMemberYn);
      if (data.existMemberYn === "N") {
        setInfoStruct({
          id: userInfo.id,
          email: "email" in userInfo ? userInfo.email : null,
          name: userInfo.name.replace(/[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]/g, ""),
          socialType: "facebook",
          deviceId: "",
          fcmToken: "",
        });
      } else if (data.existMemberYn === "G") {
        alert("재가입은 탈퇴일로 부터 7일 후에 가능합니다");
      } else if (data.existMemberYn === "F") {
        alert("영구정지 상태로 재가입이 불가합니다.");
      } else {
        socialSignIn(
          userInfo.id,
          "email" in userInfo ? userInfo.email : null,
          userInfo.name,
          "facebook",
          "",
          ""
        );
      }
    }
  };
  */

  //WEB - google login
  const getGoogleUserInfo = async (userInfo) => {
    const id = userInfo.getId();
    const email = userInfo.getBasicProfile().getEmail();
    const name = userInfo.getBasicProfile().getName();

    const { data } = await jeboApi.existMemberYn(id, "google");
    setMemberYn(data.existMemberYn);
    if (data.existMemberYn === "N") {
      setInfoStruct({
        id: id,
        email: email,
        name: name.replace(/[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]/g, ""),
        socialType: "google",
        deviceId: "",
        fcmToken: "",
      });
    } else if (data.existMemberYn === "G") {
      alert("재가입은 탈퇴일로 부터 7일 후에 가능합니다");
    } else if (data.existMemberYn === "F") {
      alert("영구정지 상태로 재가입이 불가합니다.");
    } else {
      socialSignIn(id, email, name, "google", "", "");
    }
  };

  const signInAppAndroid = () => {
    let returnString = window.androidApp.getDeviceTokenInfo();

    let deviceId = returnString.split("@@")[0];
    let fcmToken = returnString.split("@@")[1];

    signIn(deviceId, fcmToken);
  };

  const loginCallbackAndroid = useCallback(
    (account) => {
      let userInfo = JSON.parse(account);
      let returnString = window.androidApp.getDeviceTokenInfo();

      let deviceId = returnString.split("@@")[0];
      let fcmToken = returnString.split("@@")[1];

      socialSignIn(
        userInfo.id,
        userInfo.email,
        userInfo.nickName,
        userInfo.socialType,
        deviceId,
        fcmToken
      );
    },
    [socialSignIn]
  );

  useEffect(() => {
    window.loginCallbackAndroid = loginCallbackAndroid;
    return () => {
      window.loginCallbackAndroid = () => {};
    };
  }, [loginCallbackAndroid]);

  return (
    <div className="panel signin" style={{ display: "block" }}>
      <div className="modal-body">
        <div className="input-group flex mb-10">
          <input
            type="text"
            name="email"
            className="input-control"
            placeholder="ex) jebo@naver.com"
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter")
                if (mobileType === "ios") {
                  let params = JSON.stringify({
                    loginType: "email",
                  });
                  window.webkit.messageHandlers.login.postMessage(
                    Base64.encode(params)
                  );
                } else signIn();
            }}
            value={email}
          />
        </div>
        {emailInputStateMessage && (
          <span className="invalid">{emailInputStateMessage}</span>
        )}
        <div className="input-group flex mb-20">
          <input
            type="password"
            name="password"
            className="input-control"
            placeholder="비밀번호(영문, 숫자, 특수문자 조합 6~20자 이내)"
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter")
                if (mobileType === "ios") {
                  let params = JSON.stringify({
                    loginType: "email",
                  });
                  window.webkit.messageHandlers.login.postMessage(
                    Base64.encode(params)
                  );
                } else signIn();
            }}
            value={password}
          />
        </div>
        {passwordInputStateMessage && (
          <span className="invalid">{passwordInputStateMessage}</span>
        )}
        {error && <span className="invalid">{error}</span>}
        {emailError && <span className="invalid">{emailError}</span>}
        {pwdError && <span className="invalid">{pwdError}</span>}
        {["others", "mobile", "browser"].includes(mobileType) && (
          <div className="input-group flex mb-30">
            <input
              type="checkbox"
              className="chk-control"
              id="keep_id"
              name="keepId"
              checked={keepId}
              onChange={(e) => setKeepId(e.target.checked)}
            />
            <label htmlFor="keep_id">아이디 저장</label>
            <input
              type="checkbox"
              className="chk-control"
              id="auto_login"
              name="autoLogin"
              checked={isAutoLogin}
              onChange={(e) => setIsAutoLogin(e.target.checked)}
            />
            <label htmlFor="auto_login">자동 로그인</label>
          </div>
        )}
        <div className="input-group">
          <button
            className="modal-btn"
            onClick={(e) => {
              e.preventDefault();
              if (mobileType === "ios") {
                let params = JSON.stringify({
                  loginType: "email",
                });
                window.webkit.messageHandlers.login.postMessage(
                  Base64.encode(params)
                );
              } else if (mobileType === "android") {
                signInAppAndroid();
              } else {
                signIn();
              }
            }}
          >
            로그인
          </button>
        </div>
        <div className="input-group flex">
          <span className="join-txt">
            아직 회원이 아니신가요?
            <button
              type="button"
              className="join underline"
              onClick={(e) => {
                e.preventDefault();
                setSignLayer("signUp");
              }}
            >
              회원가입
            </button>
          </span>
          <button
            className="find-pswd"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setShowFindPasswordLayer(true);
            }}
          >
            비밀번호를 잊으셨나요?
          </button>
        </div>
        <div className="sns-group">
          <strong className="sns-ttl">SNS 계정으로 로그인</strong>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            render={(props) => (
              <a
                href="#!"
                className="sign-ggl"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (mobileType === "ios") {
                    let params = JSON.stringify({
                      loginType: "google",
                    });
                    window.webkit.messageHandlers.login.postMessage(
                      Base64.encode(params)
                    );
                  } else if (mobileType === "android") {
                    window.androidApp.googleSignIn();
                  } else {
                    props.onClick(e);
                  }
                }}
              >
                <></>
              </a>
            )}
            onSuccess={(result) => getGoogleUserInfo(result)}
            onFailure={(result) => console.log(result)}
            cookiePolicy="single_host_origin"
          />

          <a
            href="#!"
            className="sign-kko"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if (mobileType === "ios") {
                let params = JSON.stringify({
                  loginType: "kakao",
                });
                window.webkit.messageHandlers.login.postMessage(
                  Base64.encode(params)
                );
              } else if (mobileType === "android") {
                handleKakaoLogin();
              } else {
                kakao.Auth.authorize({
                  redirectUri: `${process.env.REACT_APP_HOST}/kakaoLoginSuccess`,
                  throughTalk: true,
                });
              }
            }}
          >
            <></>
          </a>
          <a
            href="#!"
            className="sign-nv"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (mobileType === "ios") {
                let params = JSON.stringify({
                  loginType: "naver",
                });
                window.webkit.messageHandlers.login.postMessage(
                  Base64.encode(params)
                );
              } else {
                handleNaverLogin();
              }
            }}
          >
            <></>
          </a>

          {/* <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            autoLoad={false}
            fields="id,name,email"
            callback={getFacebookUserInfo}
            onFailure={(e) => {
              console.log("facebook login failed : ", e);
            }}
            render={(renderProps) => (
              <a
                href="#!"
                className="sign-fb"
                onClick={(e) => {
                  if (mobileType === "ios") {
                    let params = JSON.stringify({
                      loginType: "facebook",
                    });
                    window.webkit.messageHandlers.login.postMessage(
                      Base64.encode(params)
                    );
                  } else {
                    renderProps.onClick(e);
                  }
                }}
              >
                <></>
              </a>
            )}
          /> */}
          {mobileType === "ios" && (
            <a
              href="#!"
              className="sign-appl"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (mobileType === "ios") {
                  let params = JSON.stringify({
                    loginType: "apple",
                  });
                  window.webkit.messageHandlers.login.postMessage(
                    Base64.encode(params)
                  );
                }
              }}
            >
              <></>
            </a>
          )}
        </div>
      </div>
      {openTermsOfJebo ? (
        <SignUpAgreeLayer
          socialSignIn={socialSignIn}
          infoStruct={infoStruct}
          setInfoStruct={setInfoStruct}
          setOpenTermsOfJebo={setOpenTermsOfJebo}
        />
      ) : openComplete ? (
        <SignUpComplete
          setSignLayer={setSignLayer}
          setOpenComplete={setOpenComplete}
        />
      ) : null}
    </div>
  );
}
