import user_icon from "assets/imgs/icons/user_icon.svg";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

export default function MemberSection({ titles, isFirstDm }) {
  const history = useHistory();
  const { communicationId } = useParams();
  const { isMobile } = useContext(isMobileContext);

  const regDateCalculate = (date) => {
    const year = date.split(" ")[0].split("-")[0];
    const month = date.split(" ")[0].split("-")[1] - 1;
    const day = date.split(" ")[0].split("-")[2];
    const hours = date.split(" ")[1].split(":")[0];
    const seconds = date.split(" ")[1].split(":")[1];

    const now = new Date();
    const timeValue = new Date(year, month, day, hours, seconds);

    let betweenTime = Math.floor(
      (now.getTime() - timeValue.getTime()) / 1000 / 60
    );
    let betweenTimeHour = Math.floor(betweenTime / 60);
    let betweenTimeDay = Math.floor(betweenTime / 60 / 24);

    if (betweenTime < 1) {
      return "방금전";
    } else if (betweenTime < 60) {
      return `${betweenTime}분전`;
    } else if (betweenTimeHour < 24) {
      return `${betweenTimeHour}시간전`;
    } else {
      return `${betweenTimeDay}일전`;
    }
  };

  return (
    <div className="dm-list-ct">
      <div className="dm-header">
        <button
          type="button"
          className="page-back mobile"
          onClick={() => {
            isMobile && history.goBack();
          }}
        ></button>
        <strong>1:1대화</strong>
      </div>
      {isFirstDm && (
        <div className="dm-body empty">
          <span>받은 메시지가 없습니다. </span>
        </div>
      )}
      {!isFirstDm && (
        <div className="dm-body">
          <div className="list">
            {titles.map((header, index) => {
              return (
                <Link
                  to={`/directMessage/${header.communicationId}`}
                  replace
                  key={index}
                >
                  <div
                    className={`dm-item${
                      communicationId &&
                      communicationId === header.communicationId
                        ? " active"
                        : ""
                    }`}
                  >
                    {header.notReadCnt && (
                      <span className="badge">{header.notReadCnt}</span>
                    )}
                    <div className="thumb">
                      <img
                        src={
                          header.toMemberImageUrl
                            ? process.env.REACT_APP_RESOURCE_HOST +
                              header.toMemberImageUrl
                            : user_icon
                        }
                        alt={header.toMemberNickName}
                      />
                    </div>
                    <div className="info-ct">
                      <div className="info">
                        <span className="user-name">
                          {header.toMemberNickName === "@RESIGN@"
                            ? "탈퇴한회원입니다"
                            : header.toMemberNickName}
                        </span>
                        <span className="text">{header.message}</span>
                      </div>
                    </div>
                    <span className="time">
                      {regDateCalculate(header.createAt)}
                    </span>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
