/* eslint-disable array-callback-return */
import jeboApi from "api/api";
import MobileFeedListLayer from "components/layers/detail/MobileFeedListLayer";
import { currentLocationContext } from "components/providers/LocationContextProvider";
import FeedContextProvider from "components/providers/FeedContextProvider";
import { convenienceDataContext } from "components/providers/ConvenienceDataContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { Route, useRouteMatch, useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player";
import redMarkerImg from "../../../assets/imgs/icons/redmarker.svg";
import "assets/styles/mapInfoStyle.css";
import redMarker from "../../../assets/imgs/icons/icon_mapMarker_red.svg";
import blueMarker from "../../../assets/imgs/icons/icon_mapMarker_blue.svg";
// import gpsMarker from "../../../assets/imgs/icons/iconGps.gray.svg";
import gpsMarker from "../../../assets/imgs/icons/iconGps.blue.svg";
import moreBtn from "../../../assets/imgs/icons/more.svg";

import SearchNavButton from "./SearchNavButton";
import SearchNavEtcButton from "./SearchNavEtcButton";

// border가 있는 아이콘
import markerPet from "../../../assets/imgs/icons/mapMarker_pet.svg";
import markerMedi from "../../../assets/imgs/icons/mapMarker_medi.svg";
import markerCloth from "../../../assets/imgs/icons/mapMarker_cloth.svg";
import markerRedPet from "../../../assets/imgs/icons/mapRedMarker_pet.svg";
import markerRedMedi from "../../../assets/imgs/icons/mapRedMarker_medi.svg";
import markerRedCloth from "../../../assets/imgs/icons/mapRedMarker_cloth.svg";

// border가 없는 아이콘
import markerPet_Bn from "../../../assets/imgs/icons/marker_pet_blue.svg";
import markerPet_RBn from "../../../assets/imgs/icons/marker_pet_red.svg";
import markerMedi_Bn from "../../../assets/imgs/icons/marker_medi_blue.svg";
import markerMedi_RBn from "../../../assets/imgs/icons/marker_medi_red.svg";
import markerCloth_Bn from "../../../assets/imgs/icons/marker_cloth_blue.svg";
import markerCloth_RBn from "../../../assets/imgs/icons/marker_cloth_red.svg";

// 정보 없음 기본 아이콘
import iconNone from "../../../assets/imgs/icons/api_none.svg";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { Base64 } from "js-base64";
import { useInView } from "react-intersection-observer";
const debouncer = (() => {
  let debounceCheck;

  return {
    debounce(callback, milliseconds) {
      clearTimeout(debounceCheck);
      debounceCheck = setTimeout(() => {
        callback(...arguments);
      }, milliseconds);
    },
  };
})();

const kakao = window.kakao;
const roundControll = 0.0000000000001;
const END_LIMIT = 15;
export default function MapSection({
  keyword,
  searchPosition,
  conMapData,
  loadingAdsMapData,
  isAd,
}) {
  const match = useRouteMatch();
  const history = useHistory();

  const { mobileType } = useContext(isMobileContext);

  const { currentLocation, getCurrentPosition } = useContext(
    currentLocationContext
  );
  const { convenienceData } = useContext(convenienceDataContext);
  const { handleSignOutApp } = useContext(signAppContext);
  // MAP용
  const kakaoMap = useRef();
  const kakaoMapContainer = useRef();
  const infowindow = useRef();

  const [mapPositionRect, setMapPositionRect] = useState(null);
  const [mapRegion, setMapRegion] = useState("");
  //
  const [jeboMarkerPostions, setJeboMarkerPositions] = useState([]);
  const [jeboMarkers, setJeboMarkers] = useState([]);
  //
  const [pubMarkerPostions, setPubMarkerPositions] = useState([]);
  const [pubMarkers, setPubMarkers] = useState([]);

  // GRID FEED용
  const [mapFeedRect, setMapFeedRect] = useState(null);
  const [cursor, setCursor] = useState(0);
  const [feeds, setFeeds] = useState([]);
  const [notLoadedData, setNotLoadedData] = useState(false);
  const [lastFeedRef, inView] = useInView();

  //편의지도 수정
  const [showMoreItemBox, setShowMoreItemBox] = useState(false);

  useEffect(() => {
    const openExternalLink = (url) => {
      if (mobileType === "ios") {
        const encodedUrl = Base64.encode(url);
        window.webkit.messageHandlers.openOuterLink.postMessage(encodedUrl);
      } else {
        window.open(url);
      }
    };

    window.openExternalLink = openExternalLink;
  }, []);

  const funcSettingMapPositionRect = () => {
    setShowMoreItemBox(false);
    setMapPositionRect({
      northEastLatitude: kakaoMap.current.getBounds().getNorthEast().getLat(),
      northEastLongitude: kakaoMap.current.getBounds().getNorthEast().getLng(),
      southWestLatitude: kakaoMap.current.getBounds().getSouthWest().getLat(),
      southWestLongitude: kakaoMap.current.getBounds().getSouthWest().getLng(),
      scale: kakaoMap.current.getLevel(),
    });
  };

  // 편의지도 아이템 변경 시 Map 변경 처리 부.
  useEffect(() => {
    if (!kakaoMap.current) return;
    // 편의지도별 최대 확대 가능 값
    const maxLevel = conMapData.level ? conMapData.level : 7;
    kakaoMap.current.setMaxLevel(maxLevel);
  }, [conMapData]);

  // 최초 1회의 카카오맵 initialize 처리.
  useEffect(() => {
    const { latitude, longitude } = searchPosition;
    const coords = new kakao.maps.LatLng(latitude, longitude);

    kakaoMap.current = new kakao.maps.Map(kakaoMapContainer.current, {
      center: coords,
      level: 4,
    });

    // 확대/축소 컨트롤 추가.
    const zoomControl = new kakao.maps.ZoomControl();
    kakaoMap.current.addControl(
      zoomControl,
      kakao.maps.ControlPosition.BOTTOMRIGHT
    );
    // 맵이동 이벤트
    kakao.maps.event.addListener(
      kakaoMap.current,
      "center_changed",
      function () {
        debouncer.debounce(() => {
          funcSettingMapPositionRect();
        }, 300);
      }
    );

    // 확대/축소 시 이벤트.
    kakao.maps.event.addListener(kakaoMap.current, "zoom_changed", function () {
      debouncer.debounce(() => {
        funcSettingMapPositionRect();
      }, 300);
    });

    // infoWindow 등록
    infowindow.current = new kakao.maps.InfoWindow({ zIndex: 1 });

    // 최초1회는 rect를 세팅해준다. 로딩 시 바로 값을 가져올 수 있도록
    funcSettingMapPositionRect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 센터 주소 처리.
  useEffect(() => {
    /*
      광고(isAd)인 경우 현 위치 설정 안해줌
    */
    if (!currentLocation || isAd) return;

    kakaoMap.current.setLevel(6); // 시작 레벨.
    kakaoMap.current.setCenter(
      new kakao.maps.LatLng(currentLocation.latitude, currentLocation.longitude)
    );
  }, [currentLocation]);

  useEffect(() => {
    /*
      광고여부 판단과 페이지 render 시의 타이밍 차이가 있기 때문에 
      위의 currentLocation의 Effect에서 return 을 통해 setCenter를 방어하고,
      현재 Effect 에서 setCenter를 해준다
      loadingAdsMapData가 false인 경우 searchPosition에 저장된 위/경도로 카카오맵 세팅 
    */
    if (!isAd || loadingAdsMapData) return;

    if (isAd) {
      kakaoMap.current.setLevel(6); // 시작 레벨.
      kakaoMap.current.setCenter(
        new kakao.maps.LatLng(searchPosition.latitude, searchPosition.longitude)
      );
    }
  }, [loadingAdsMapData, isAd]);

  useEffect(() => {
    const latlng = kakaoMap.current.getCenter();
    const geocoder = new kakao.maps.services.Geocoder();
    geocoder.coord2RegionCode(
      latlng.getLng(),
      latlng.getLat(),
      (result, status) => {
        if (status === kakao.maps.services.Status.OK) {
          setMapRegion(
            result[0].region_4depth_name
              ? `${result[0].region_3depth_name} ${result[0].region_4depth_name}`
              : `${result[1].region_2depth_name} ${result[1].region_3depth_name}`
          );
        }
      }
    );
  }, [mapPositionRect]);

  /*
    2023.02.23 codelua 
    제보정보, 공공정보의 마커는 mapPositionRect state가 변경되면 각기 비동기로 동작한다. 
    2개는 API서버로 부터 각 각에 맞는 Position List를 먼저 불러오고 
    각 정보에 맞는 Markers 를 세팅해준다. 
    이후 제보정보는 jeboMarkers의 state값에 의해 추가로 FEED 목록 호출이 이어 진다. 
  */

  // 제보정보의 포지션 정보와 공공정보의 통을 받아온다.
  useEffect(() => {
    if (!mapPositionRect) return;
    const neLat = mapPositionRect.northEastLatitude;
    const neLng = mapPositionRect.northEastLongitude;
    const swLat = mapPositionRect.southWestLatitude;
    const swLng = mapPositionRect.southWestLongitude;
    // 제보정보 호출  async 호출이니 해당이 끝나고 아래 function을 부를거라고 생각하면 안됨.
    async function loadJeboPositionList() {
      try {
        const { status, data } = await jeboApi.jeboMapPositionList(
          neLat,
          neLng,
          swLat,
          swLng,
          keyword
        );
        if (status === 200) {
          const resultData = JSON.parse(data.jeboMapPositionList);
          setJeboMarkerPositions(
            resultData && resultData.length > 0 ? resultData : []
          );
        } else {
          console.log("jeboMapPositionList -- Reuslt : Data Nothing..");
          setJeboMarkerPositions([]);
        }
      } catch (error) {
        setJeboMarkerPositions([]);
        console.log(error.response);
        if (error.response?.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
        }
        console.log(error);
      } finally {
      }
    }
    async function loadPublicMapPostionList() {
      try {
        const { status, data } = await jeboApi.getJeboPublicMapPositionList(
          neLat,
          neLng,
          swLat,
          swLng,
          keyword
        );
        if (status === 200) {
          const resultData = JSON.parse(data.jeboPublicPostionListData);
          setPubMarkerPositions(
            resultData && resultData.length > 0 ? resultData : []
          );
        } else {
          console.log("getJeboPublicMapPositionList - Result: Data Nothing..");
          setPubMarkerPositions([]);
        }
      } catch (error) {
        setPubMarkerPositions([]);
        console.log(error.response);
        if (error.response?.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
        }
        console.log(error);
      } finally {
      }
    }

    // 공공정보 호출 async 호출이니 윗 펑션이 끝나고 아래 탈거라고 생각하면 안됨.
    loadJeboPositionList();
    loadPublicMapPostionList();
  }, [handleSignOutApp, keyword, mapPositionRect]);

  // 제보정보 마커 세팅
  useEffect(() => {
    let imageSrc;
    let imageSize = new kakao.maps.Size(30, 30);
    let imageOption = { offset: new kakao.maps.Point(15, 45) };

    if (keyword === "#주말약국") {
      imageSrc = markerRedMedi;
    } else if (keyword === "#의류수거함") {
      imageSrc = markerRedCloth;
    } else if (keyword === "#반려동물") {
      imageSrc = markerRedPet;
    } else {
      imageSrc = redMarkerImg;
      imageSize = new kakao.maps.Size(45, 45);
      imageOption = { offset: new kakao.maps.Point(27, 69) };
    }

    const jeboMarkerImage = new kakao.maps.MarkerImage(
      imageSrc,
      imageSize,
      imageOption
    );

    jeboMarkers.forEach((marker) => {
      marker.setMap(null);
    });

    setJeboMarkers(
      jeboMarkerPostions.map((feed, index) => {
        const marker = new kakao.maps.Marker({
          map: kakaoMap.current, // 맵을 속성으로 부여 하면 바로 지도에 표기 된다.
          position: new kakao.maps.LatLng(feed.latitude, feed.longitude),
          image: jeboMarkerImage,
        });
        kakao.maps.event.addListener(marker, "click", function () {
          const mapRect = {
            northEastLatitude: marker.getPosition().getLat() + roundControll,
            northEastLongitude: marker.getPosition().getLng() + roundControll,
            southWestLatitude: marker.getPosition().getLat() - roundControll,
            southWestLongitude: marker.getPosition().getLng() - roundControll,
          };
          setShowMoreItemBox(false);
          setMapFeedRect(mapRect);
        });
        return marker;
      })
    );
    // 포지션 정보가 있었다면 Feed List도 호출 되도록 Rect state 변경
    if (jeboMarkerPostions && jeboMarkerPostions.length > 0) {
      setMapFeedRect(mapPositionRect);
    } else {
      setFeeds([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jeboMarkerPostions]);

  // keyword가 변경될 때마다 정보 제공 마커 변경하기
  let iconInfoImgJebo = redMarker;
  let iconInfoImgPublic = blueMarker;
  if (keyword === "#주말약국") {
    iconInfoImgJebo = markerMedi_RBn;
    iconInfoImgPublic = markerMedi_Bn;
  } else if (keyword === "#의류수거함") {
    iconInfoImgJebo = markerCloth_RBn;
    iconInfoImgPublic = markerCloth_Bn;
  } else if (keyword === "#반려동물") {
    iconInfoImgJebo = markerPet_RBn;
    iconInfoImgPublic = markerPet_Bn;
  }

  // 공공정보 마커 세팅
  useEffect(() => {
    infowindow.current && infowindow.current.close();
    pubMarkers.forEach((marker) => {
      marker.setMap(null);
    });

    let imageSrc;
    let imageSize = new kakao.maps.Size(30, 30);
    let imageOption = { offset: new kakao.maps.Point(15, 45) };

    if (keyword === "#주말약국") {
      imageSrc = markerMedi;
    } else if (keyword === "#의류수거함") {
      imageSrc = markerCloth;
    } else if (keyword === "#반려동물") {
      imageSrc = markerPet;
    } else {
      imageSrc = blueMarker;
      imageSize = new kakao.maps.Size(45, 45);
    }

    const publicMarkerImage = new kakao.maps.MarkerImage(
      imageSrc,
      imageSize,
      imageOption
    );

    setPubMarkers(
      pubMarkerPostions.map((info, index) => {
        var marker = new kakao.maps.Marker({
          map: kakaoMap.current, // 맵을 속성으로 부여 하면 바로 지도에 표기 된다.
          position: new kakao.maps.LatLng(info.lat, info.lng),
          image: publicMarkerImage,
        });
        marker.id = info.mapId;
        // 23.02.06 codelua 마커를 누를 때 API서버로 부터 컨텐츠 정보를 즉시 받아온다. (멀티 처리를 위해 html 로드) 동기화 함수임.
        kakao.maps.event.addListener(marker, "click", async function () {
          const mapId = this.id;
          var contents;
          try {
            const { status, data } = await jeboApi.getJeboPublicMapInfoData(
              mapId
            );
            if (status === 200) {
              const infoResult = JSON.parse(data.infoData);
              // html형태로 저장 되어있는 값인지 판단 하는 근거로 </div>사용되었는지 판단. 해당 사항 주의 필요 (이상하다 느낀다면 div가 없는것.)
              if (!String(infoResult.content).includes("</div>")) {
                contents =
                  '<div class="info-search-map"><p>' +
                  infoResult.content +
                  "</p></div>";
              } else {
                contents =
                  '<div class="info-search-map">' +
                  infoResult.content +
                  "</div>";
              }
            } else {
              contents =
                '<div class="info-search-map"><p>' +
                data.resultMessage +
                "</p></div>";
            }
          } catch (error) {
            contents = '<div class="info-search-map">일시적오류입니다.</div>';
          } finally {
          }
          infowindow.current.setContent(contents);
          infowindow.current.open(kakaoMap.current, marker);
          // open의 경우 marker포지션 정보로 마커의 이미지기준 상단에 붙게 된다.
          setShowMoreItemBox(false);
        });
        return marker;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pubMarkerPostions]);

  // 제보정보 FEED 호출
  useEffect(() => {
    async function loadJeboMapPostList() {
      if (!mapFeedRect) return;
      try {
        const neLat = mapFeedRect.northEastLatitude;
        const neLng = mapFeedRect.northEastLongitude;
        const swLat = mapFeedRect.southWestLatitude;
        const swLng = mapFeedRect.southWestLongitude;
        const { status, data } = await jeboApi.jeboMapPostList(
          neLat,
          neLng,
          swLat,
          swLng,
          keyword,
          0,
          END_LIMIT,
          cursor
        );
        if (status === 200) {
          const jeboPostArr = JSON.parse(data.jeboMapPostList);
          setNotLoadedData(jeboPostArr.length < END_LIMIT ? true : false);
          if (cursor === 0) {
            setFeeds(jeboPostArr);
          } else setFeeds((prev) => prev.concat(jeboPostArr));
        } else if (status === 204) {
          console.log("no data loadFeeds to be loaded..");
          setNotLoadedData(true);
        }
      } catch (error) {
        console.log(error.response);
        if (error.response?.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
        }
        console.log(error);
      } finally {
      }
    }
    loadJeboMapPostList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, mapFeedRect]);

  useEffect(() => {
    if (inView && !notLoadedData) setCursor((prev) => prev + END_LIMIT);
  }, [inView, notLoadedData]);

  return (
    <>
      <Route
        path={`${match.path}/list`}
        render={(props) => (
          <FeedContextProvider
            feeds={{
              feeds: feeds,
              setFeeds: setFeeds,
            }}
          >
            <MobileFeedListLayer title={`${keyword} 제보`} />
          </FeedContextProvider>
        )}
      />
      <SectionMap>
        <MapContainer ref={kakaoMapContainer} />
        <MapInfoCt>
          <MapInfoHeader>
            <MapList>
              <MapLocation
                gps={gpsMarker}
                className="curLocation"
                onClick={(e) => {
                  getCurrentPosition(false);
                }}
              >
                현위치
              </MapLocation>
              <MapListContent>
                {conMapData &&
                  convenienceData &&
                  convenienceData.map((item, index) => {
                    if (index <= 2)
                      // 대표 아이템은 3개를 초과하지 않도록 처리.
                      return (
                        <SearchNavButton
                          item={item}
                          key={index}
                          keyword={keyword}
                          onClick={(e) => {
                            const url =
                              "/search/" + item.codeName.replace("#", "%23");
                            history.replace({
                              pathname: url,
                            });
                          }}
                        ></SearchNavButton>
                      );
                  })}
              </MapListContent>

              <MapMoreBtn
                moreBtn={moreBtn}
                className="moreBtn"
                onClick={(e) => {
                  setShowMoreItemBox((prev) => !prev);
                }}
              ></MapMoreBtn>
            </MapList>

            {/* 편의지도 리스트 */}
            <MoreItemBoxWrapper showMoreBox={showMoreItemBox}>
              {convenienceData.map((item, index) => {
                if (index > 2)
                  return (
                    <SearchNavEtcButton
                      keyword={keyword}
                      key={index}
                      item={item}
                      className="itemListP"
                    ></SearchNavEtcButton>
                  );
              })}
            </MoreItemBoxWrapper>

            <MapIconWrapper>
              <PublicInfo>
                <PublicInfoIcon
                  redMarker={iconInfoImgJebo}
                  className={keyword ? " roundIcon jeboInfoCt" : "jeboInfoCt"}
                ></PublicInfoIcon>{" "}
                <PublicInfoText>제보</PublicInfoText>
              </PublicInfo>
              <PublicInfo>
                <PublicInfoIcon
                  blueMarker={iconInfoImgPublic}
                  className={
                    keyword ? `roundIcon publicInfoCt` : `publicInfoCt`
                  }
                ></PublicInfoIcon>{" "}
                <PublicInfoText>공공</PublicInfoText>
              </PublicInfo>
            </MapIconWrapper>
          </MapInfoHeader>
        </MapInfoCt>
        <MapInfoDescCt>
          {convenienceData &&
            convenienceData.map((item, index) => {
              if (keyword === item.codeName) {
                return (
                  <AddConmapFeed
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `${match.url}/add`,
                        search: `?conName=${item.codeName.replace(
                          "#",
                          "%23"
                        )}&isEvent=${item.isEvent}`,
                      });
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_API_HOST +
                        `/media/conmap/${item.hashCode}.png`
                      }
                      alt="test_image"
                    />
                  </AddConmapFeed>
                );
              }
            })}
        </MapInfoDescCt>

        {feeds && feeds.length > 0 ? (
          <GridContainer>
            {feeds.map((content) => (
              <GridContent
                key={content.jeboId}
                content={content}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push({
                    pathname: `${match.url}/list`,
                    state: { jeboId: content.jeboId },
                  });
                }}
              >
                {content.jeboMediaList && content.jeboMediaList.length > 0 ? (
                  content.jeboMediaList[0].mediaType === "image" ? (
                    <LazyLoadImage
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", //"contain",
                        margin: "auto",
                        backgroundColor: "#f8f8f8",
                      }}
                      src={
                        process.env.REACT_APP_RESOURCE_HOST +
                        content.jeboMediaList[0].mediaUrl
                      }
                      alt=""
                    />
                  ) : (
                    <ReactPlayer
                      className="react-player"
                      controls={false}
                      controlsList="nodownload"
                      loop={false}
                      muted={true}
                      autoPlay={false}
                      playsInline={true}
                      type="video/*"
                      url={`${
                        process.env.REACT_APP_RESOURCE_HOST +
                        content.jeboMediaList[0].mediaUrl
                      }#t=0.5`}
                      width="100%"
                      height="100%"
                      style={{
                        objectFit: "cover", //"contain",
                        margin: "auto",
                        backgroundColor: "#f8f8f8",
                      }}
                    />
                  )
                ) : (
                  <GridContentText>{content.contents}</GridContentText>
                )}
              </GridContent>
            ))}
            <div ref={lastFeedRef}></div>
          </GridContainer>
        ) : (
          <EmptyGridContainer>
            <IconText keyword={keyword} />
            지도를 움직여 다른 지역의
            <br />
            <HighLightText>{keyword}</HighLightText>
            <HighLightText className="red"> 제보 정보</HighLightText>를 확인해
            보세요
          </EmptyGridContainer>
        )}
      </SectionMap>
    </>
  );
}

const AddConmapFeed = styled.div`
  width: 100%;
  height: 47px;
  padding: 0px;
  img {
    width: 100%;
    height: 100%;
  }
`;

const SectionMap = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

// Map container
const MapContainer = styled.div`
  width: 100%;
  /* 편의지도 수정(지도 높이 변경) */
  height: 120vw;
  overflow: hidden;
  z-index: 0;
`;

const MapInfoCt = styled.div``;

const MapInfoHeader = styled.div`
  width: 100%;

  position: absolute;
  /* 편의지도 수정(여백 변경) */
  padding: 0 10px;
  top: 0;
  left: 0;
  height: 115px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const PublicInfo = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 6px;
  height: 24px;

  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #232323;

  margin-bottom: 1px;
`;

const PublicInfoIcon = styled.span`
  width: 25px;
  height: 25px;

  margin-right: 3px;
  &.jeboInfoCt {
    background: url(${({ redMarker }) => redMarker}) no-repeat;
    &.roundIcon {
      background-size: contain;
      background-position: center;
    }
  }
  &.publicInfoCt {
    background: url(${({ blueMarker }) => blueMarker}) no-repeat;
    &.roundIcon {
      background-size: contain;
      background-position: center;
    }
  }
`;

const PublicInfoText = styled.p`
  margin-left: auto;
  width: 70%;
`;

// Grid container
const GridContainer = styled.div`
  height: 100%;
  display: grid;

  gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(32%, 105px));
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;

const EmptyGridContainer = styled.div`
  font-weight: 500;
  font-size: 14px;
  text-align: center;

  padding: 25px 0;
  height: 100%;
`;

const IconText = styled.span`
  text-align: center;
  width: 100%;
  height: 55px;
  display: flex;

  margin-bottom: 20px;

  ${(keyword) => {
    if (keyword.keyword === "#반려동물") {
      return css`
        background: url(${markerPet_RBn}) no-repeat;
        background-size: contain;
        background-position: center;
      `;
    } else if (keyword.keyword === "#주말약국") {
      return css`
        background: url(${markerMedi_RBn}) no-repeat;
        background-size: contain;
        background-position: center;
      `;
    } else if (keyword.keyword === "#의류수거함") {
      return css`
        background: url(${markerCloth_RBn}) no-repeat;
        background-size: contain;
        background-position: center;
      `;
    } else {
      return css`
        background: url(${iconNone}) no-repeat;
        background-size: contain;
        background-position: center;
      `;
    }
  }}
`;
const HighLightText = styled.span`
  /* color: #238cfa; */
  /* &.red {
    color: #cf514a;
  } */
`;

// Grid Item
const GridContent = styled.div`
  background-color: #83a7fb;
  height: 110px;
  word-break: break-all;

  padding: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  line-height: 1.4em;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 14px;

  ${({ content }) =>
    content.jeboMediaList && content.jeboMediaList.length > 0
      ? css`
          padding: 0;
        `
      : ""}
`;

const GridContentText = styled.p`
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const MapInfoDescCt = styled.div`
  background-color: #f1f1f1;
  color: #232323;
  text-align: center;

  height: 47px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MapInfoDesc = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  span.text__hashTagCt {
    color: #238cfa;
    font-weight: bold;
    font-size: 13px;
    span.text__hashTag {
    }
  }
`;

// 편의지도 수정
const MapIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 8px;
  top: calc(120vw - 80px);
`;
const MapList = styled.div`
  width: 350px;
  height: 34px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  background-color: #fff;
  border: 1px solid #e2e5e7;
  flex-shrink: 0;
  border-radius: 4px;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
`;

const MapLocation = styled.button`
  margin-left: 1px;
  padding: 0;
  font-size: 0;
  background-color: transparent;
  width: 24px;

  border-right: 1px solid #dcdcdc;
  &.curLocation::before {
    content: "";
    display: block;
    width: 100%;
    height: 21.83px;

    background: url(${({ gps }) => gps}) no-repeat;
    background-position: center;
    background-size: 16px;
  }
`;

const MapListContent = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 280px;
`;

const MapMoreBtn = styled.button`
  position: absolute;
  right: -2px;
  top: 0;
  height: 32px;

  background-color: #fff;
  border-left: 1px solid #e2e5e7;
  border-radius: 0 4px 4px 0;
  padding: 8px;
  &.moreBtn::before {
    content: "";
    display: block;
    width: 16px;
    height: 12px;
    background: url(${({ moreBtn }) => moreBtn}) no-repeat;
    background-position: center;
  }
`;

const MoreItemBoxWrapper = styled.div`
  margin-top: 5px;
  position: relative;
  z-index: 1;
  width: 350px;
  display: ${({ showMoreBox }) => (showMoreBox ? "block" : "none")};

  background-color: #fff;
  border: 1px solid #e2e5e7;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
`;
