import LayerContextProvider from "components/providers/LayerContextProvider";
import SocialContentContextProvider from "components/providers/SocialContentContextProvider";
export default function SocialContextProvider({
  socials,
  layer,
  children: pageComponent,
}) {
  return (
    <LayerContextProvider layer={layer}>
      <SocialContentContextProvider socials={socials}>
        {pageComponent}
      </SocialContentContextProvider>
    </LayerContextProvider>
  );
}
