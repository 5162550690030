import heart from "assets/imgs/icons/icon_favRed.svg";
import emptyHeart from "assets/imgs/icons/icon_favRedBorder.svg";

import iconMember from "assets/imgs/icons/icon_joinUser.svg";
import iconMemberActive from "assets/imgs/icons/icon_joinUserActive.svg";

import iconPostCount from "assets/imgs/icons/icon_feedcount.svg";
import iconPostCountActive from "assets/imgs/icons/icon_feedcountActive.svg";

import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import styled, { css } from "styled-components";
import SocialConfirmPop from "./SocialConfirmPop";

export default function SocialItemList({
  item,
  category,
  toggleLike,
  handleJoin,
  handleQuit,
  sortType,
  dispatchContent,
  index,
  itemType,
  filter,
}) {
  const history = useHistory();
  const location = useLocation();
  const { isLoggedIn } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const [joinConfirm, setJoinConfirm] = useState(false);
  const [quitConfirm, setQuitConfirm] = useState(false);
  const [social, setSocial] = useState(null);

  return (
    <>
      <ContentSortContainer>
        {isLoggedIn && (
          <>
            <ContentSortInput
              id="joinedChk"
              type="checkbox"
              onChange={(e) => {
                console.log(e.target.checked);

                dispatchContent({
                  type: "CHANGE_FILTER",
                  filter: { joined: e.target.checked },
                });
              }}
              checked={filter && filter.joined}
            ></ContentSortInput>
            <ContentSortlabel htmlFor="joinedChk">
              {category.title === "테마" || category.title === "캠페인"
                ? `참여한 ${category.title}`
                : `구독한 ${category.title}`}
            </ContentSortlabel>
          </>
        )}
        <SortBtn
          className={sortType === 0 ? "active first" : "first"}
          onClick={() => {
            dispatchContent({
              type: "CHANGE_SORT",
              sortType: 0,
            });
          }}
        >
          인기순
        </SortBtn>
        <SortBtn
          className={sortType === 1 ? "active" : ""}
          onClick={() => {
            dispatchContent({
              type: "CHANGE_SORT",
              sortType: 1,
            });
          }}
        >
          최신순
        </SortBtn>
      </ContentSortContainer>

      <PullToRefresh
        onRefresh={async (e) => {
          dispatchContent({ type: "REFRESH_DATA" });
        }}
        pullingContent=""
        refreshingContent={
          <div className="feed-spinner">
            <span></span>
            <span></span>
            <span></span>
          </div>
        }
      >
        {item.length < 1 ? (
          <EmptyDataCt>
            {category.title === "채널"
              ? `구독한 채널이 없습니다.`
              : category.title === "테마"
              ? `참여한 테마가 없습니다.`
              : `참여한 캠페인이 없습니다.`}
          </EmptyDataCt>
        ) : (
          item.map((data, index) => (
            <ItemWrapper
              index={index}
              onClick={(e) => {
                history.push({
                  ...location,
                  pathname: `/social/${category.name}/${data.scId}`,
                });
              }}
              itemType={itemType}
            >
              <ContentWrapper itemType={itemType}>
                <InfoWrap itemType={itemType}>
                  <ImageWrapper>
                    <Image
                      imageUrl={
                        data.scId &&
                        process.env.REACT_APP_API_HOST +
                          "/media/social/" +
                          data.imageName
                      }
                    />
                  </ImageWrapper>

                  <TextWrapper
                    className={category.name === "channel" ? "" : "others"}
                  >
                    <HashtagWrapper itemType={itemType}>
                      {data.scTag &&
                        data.scTag
                          .split(" ")
                          .filter((_, index) => index < 3)
                          .map((hashtag, index) => (
                            <Hashtag itemType={itemType} key={index}>
                              {hashtag}
                            </Hashtag>
                          ))}
                    </HashtagWrapper>
                    <Title itemType={itemType}>{data.scName}</Title>
                    <JoinInfoWrap>
                      <HeartBox className={data.scType === 3 ? "channel" : ""}>
                        {data.scType !== 3 && (
                          <a
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              toggleLike(data);
                            }}
                          >
                            <img src={data.like ? heart : emptyHeart} alt="" />
                            <h3>{data.likeCount}</h3>
                          </a>
                        )}
                      </HeartBox>
                      <MemberCountBox>
                        {/* <AiOutlineUser className="AiOutlineUser" /> */}

                        {category.name === "channel" ? (
                          <h3>구독자 {data.memberCount}명</h3>
                        ) : (
                          <>
                            <img
                              src={
                                data.roleName !== "ANONYMOUS"
                                  ? iconMemberActive
                                  : iconMember
                              }
                              alt=""
                            />
                            <h3>{data.memberCount}</h3>
                          </>
                        )}
                      </MemberCountBox>
                      {/* codeluafix */}
                      <PostCountBox>
                        {category.name !== "channel" && (
                          <>
                            <img
                              src={
                                data.postCnt > 0
                                  ? iconPostCountActive
                                  : iconPostCount
                              }
                              alt=""
                            />
                            <h3>{data.postCnt}</h3>
                          </>
                        )}
                      </PostCountBox>
                    </JoinInfoWrap>
                  </TextWrapper>
                  {data &&
                    data.roleName !== "ADMIN" &&
                    category.name === "channel" && (
                      <SubscribeBtn
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSocial(data);
                          isLoggedIn && data.roleName === "ANONYMOUS"
                            ? setJoinConfirm(true)
                            : isLoggedIn
                            ? setQuitConfirm(true)
                            : setSignLayer("signIn");
                        }}
                        className={
                          data &&
                          (data.roleName !== "ANONYMOUS" ? "" : "active")
                        }
                        itemType={itemType}
                      >
                        구독
                      </SubscribeBtn>
                    )}
                </InfoWrap>
              </ContentWrapper>
            </ItemWrapper>
          ))
        )}
      </PullToRefresh>

      {joinConfirm && (
        <SocialConfirmPop
          title={social.scName}
          message={
            category.name === "channel"
              ? "구독하시겠습니까?"
              : "가입하시겠습니까?"
          }
          onClick={() => {
            setJoinConfirm(false);
            handleJoin(social);
            setSocial(null);
          }}
          onCancel={() => {
            setJoinConfirm(false);
            setSocial(null);
          }}
          category={category.name}
        />
      )}

      {quitConfirm && (
        <SocialConfirmPop
          title={social.scName}
          message={
            category.name === "channel"
              ? "구독을 취소하시겠습니까?"
              : "탈퇴하시겠습니까?"
          }
          onClick={() => {
            setQuitConfirm(false);
            handleQuit(social);
            setSocial(null);
          }}
          onCancel={() => {
            setQuitConfirm(false);
            setSocial(null);
          }}
          category={category.name}
        />
      )}
    </>
  );
}

const ContentSortContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
`;

const ContentSortInput = styled.input`
  /* margin-left: auto; */
`;
const ContentSortlabel = styled.label`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #2d2d2d;
  margin-right: auto;

  @media screen and (min-width: 481px) {
    font-size: 13px;
    margin-left: 5px;
  }
`;

const SortBtn = styled.button`
  background-color: transparent;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #c0b9b9;
  font-weight: 500;

  &.active {
    color: #2f9bfa;
    font-weight: 600;
  }
  &.first {
    margin-left: auto;
  }
  @media screen and (min-width: 481px) {
    font-size: 13px;
  }
`;

const ItemWrapper = styled.div`
  overflow: hidden;
  ${({ index }) => {
    if (index === 4) {
      return css`
        border-bottom: none;
      `;
    }
  }};

  padding: 18px 0;
  width: 100%;
`;

const InfoWrap = styled.div`
  display: flex;
  align-items: center;
`;

const JoinInfoWrap = styled.div`
  display: flex;
  margin-top: 4px;
`;

const HeartBox = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;

  margin-right: 11px;
  &.channel {
    margin-right: 0px;
  }

  & a {
    display: flex;
  }
  & > :first-child {
    margin-right: 5px;
  }
  img {
    margin-right: 5px;
    width: 14px;
    height: 12.84px;
  }
`;

const MemberCountBox = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  margin-right: 11px;
  & > :first-child {
    margin-right: 5px;
  }
  img {
    width: 14px;
    height: 12.84px;
  }
`;

const PostCountBox = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  margin-right: 11px;
  & > :first-child {
    margin-right: 5px;
  }
  img {
    width: 14px;
    height: 12.84px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  &:hover {
    cursor: pointer;
  }
`;

const HashtagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  flex: 1 0 inherit;
  width: 100%;
  max-height: 30px;
  margin-bottom: 4px;
`;

const Hashtag = styled.p`
  display: block;
  height: 12px;
  max-width: 85px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 10px;
  line-height: 14px;

  color: #3773ff;

  margin-right: 10px;

  font-weight: 400;

  @media screen and (min-width: 481px) {
    font-size: 11px;
    height: 13px;
    font-weight: 500;
  }
`;

const TextWrapper = styled.div`
  height: 100%;

  width: 195px;
  &.others {
    width: 100%;
  }
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #292929;

  @media screen and (min-width: 481px) {
    width: 60%;
  }
`;
const Title = styled.p`
  overflow: hidden;
  white-space: normal;

  width: 100%;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #292929;
  margin-right: 10px;

  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ImageWrapper = styled.div`
  width: 64px;
  height: 64px;
  margin-right: 12px;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid #dcdcdc;

  border-radius: 100%;
  overflow: hidden;
  position: relative;
`;

const Image = styled.div`
  background: url(${({ imageUrl }) => (imageUrl ? imageUrl : "")}) no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const SubscribeBtn = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;

  color: #dcdcdc;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  /* width: 44px; */
  height: 27px;
  &.active {
    border: none;
    color: #ffffff;
    background: linear-gradient(
      134.74deg,
      #22e6f3 -66.53%,
      #29bdf7 -23.83%,
      #2f9dfa 16.93%,
      #3385fd 57.69%,
      #3677fe 94.56%,
      #3773ff 127.56%
    );
  }

  border-radius: 64px;

  padding: 4px 9px;

  margin-left: auto;
`;
const EmptyDataCt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 55vh;

  font-size: 12px;
  color: #a3a3a3;
  text-align: center;

  background: #fff;
`;
