import styled, { css } from "styled-components";
import { useHistory } from "react-router";

export default function SearchNavEctButton({ item, keyword }) {
  const history = useHistory();
  return (
    <>
      <MoreItemBtn
        isActive={item.codeName === keyword ? true : false}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const url = "/search/" + item.codeName.replace("#", "%23");
          history.replace({
            pathname: url,
          });
        }}
      >
        {item.codeName}
      </MoreItemBtn>
    </>
  );
}

const MoreItemBtn = styled.button`
  display: inline-block;
  font-size: 14px;
  margin: 6px;
  line-height: 19px;
  color: #828282;

  @media screen and( max-width : 481px ) {
    flex: 0 0 auto;
  }

  background-color: transparent;

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 600;
      color: #238cfa;

      display: inline-flex;
      padding: 2px 10px;
      gap: 10px;

      background: #e7f2fe;
      border-radius: 46px;
    `};
`;
