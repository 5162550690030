import jeboApi from "api/api";
import LoadingComponent from "components/templates/LoadingComponent";
import React, { useEffect, useState } from "react";

export default function FindPasswordLayer({ setShowFindPasswordLayer }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const [inputStateMessage, setInputStateMessage] = useState("");
  const [sendEmailStateMessage, setSendEmailStateMessage] = useState("");

  // useEffect(() => {
  //   document.getElementsByTagName("html")[0].classList.add("hidden");
  //   window.document.body.classList.add("hidden");
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("hidden");
  //     window.document.body.classList.remove("hidden");
  //   };
  // }, []);

  useEffect(() => {
    email && setInputStateMessage("");
  }, [email]);

  const handleSendFindPasswordMail = async (e) => {
    e.preventDefault();

    let emailRegExp =
      /^(([^<>()\\[\].,;:\s@"]+(\.[^<>()\\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    // /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if (!email) {
      setSendEmailStateMessage("");
      setInputStateMessage("이메일을 입력하세요.");
      return;
    }
    if (!emailRegExp.test(email)) {
      setSendEmailStateMessage("");
      setInputStateMessage("이메일 형식이 잘못되었습니다.");
      return;
    }

    setLoading(true);
    try {
      const response = await jeboApi.sendFindPasswordMail(email);

      if (response.status === 200) {
        setInputStateMessage("");
        setSendEmailStateMessage("전송 완료");
      } else if (response.status === 204) {
        setSendEmailStateMessage("");
        setInputStateMessage("가입된 이메일이 아닙니다.");
      }
    } catch (error) {
      if (error.response) {
        const {
          response: { data, status },
        } = error;
        if (status === 400) console.log(data.errorDescription);
        if (status === 401) console.log(data.errorDescription);
        else console.log("알 수 없는 에러 발생. 관리자에게 문의 해주세요.");

        setSendEmailStateMessage("");
        setInputStateMessage("");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="modal modal-sign open" id="modal-pswd">
        <div className="modal-header">
          <strong className="passwordText">비밀번호를 잊으셨나요?</strong>
          <button
            className="modal-close"
            onClick={(e) => setShowFindPasswordLayer(false)}
          >
            <i className="icon-cross"></i>
          </button>
        </div>
        <div className="modal-body">
          <div className="input-group">
            <input
              type="text"
              className="input-control"
              placeholder="가입한 이메일 주소를 입력하세요."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") handleSendFindPasswordMail(e);
              }}
            />
            <button
              type="button"
              className="input-group-btn"
              style={{
                position: "absolute",
                right: "0",
                zIndex: "2",
                width: "100px",
                height: "45px",
                fontSize: ".813rem",
                color: "#1a78c9",
                textAlign: "center",
                background: "transparent",
                border: "none",
              }}
              onClick={(e) => e.preventDefault()}
            >
              {sendEmailStateMessage}
            </button>
            {inputStateMessage && (
              <span className="invalid">{inputStateMessage}</span>
            )}
          </div>
          <div className="input-group">
            <button className="modal-btn" onClick={handleSendFindPasswordMail}>
              비밀번호 재설정 이메일 받기
            </button>
          </div>
        </div>
      </div>
      {loading && (
        <div className="modal-wrap open">
          <LoadingComponent />
        </div>
      )}
    </>
  );
}
