import React, { createContext } from "react";

export const AdsMapDataContext = createContext({
  adsMapData: {},
  setAdsMapData: () => {},
  loadingAdsMapData: false,
  setLoadingAdsMapData: () => {},
});

export default function AdsMapDataContextProvider({
  adsmapData,
  children: Components,
}) {
  return (
    <AdsMapDataContext.Provider value={adsmapData}>
      {Components}
    </AdsMapDataContext.Provider>
  );
}
