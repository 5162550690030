import jeboApi from "api/api";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import "assets/styles/Calendar.css";
import moment from "moment";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Calendar } from "react-calendar";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import AlertLayer from "./AlertLayer";

/* 테스트이미지 */
import testImg from "assets/imgs/calendar_test_img.png";
import { createPortal } from "react-dom";

export default function AttendancePopLayer({ setViewAttendance }) {
  const now = new Date();
  const [focusDate, setFocusDate] = useState(new Date());

  const history = useHistory();
  const { myInfo, handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const [showAlertLayer, setShowAlertLayer] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const [checkComplete, setCheckComplete] = useState([]);
  const [attendanceType, setAttendanceType] = useState("W");
  const [eventImage, setEventImage] = useState();
  const [hasCheck, setHasCheck] = useState(false);

  // useEffect(() => {
  //   //부모창 스크롤 방지
  //   document.body.style.cssText = `
  //   position: fixed;
  //   top: -${window.scrollY}px;
  //   overflow-y: scroll;
  //   width: 100%;`;

  //   return () => {
  //     //부모창 스크롤 방지 해제
  //     const scrollY = document.body.style.top;
  //     document.body.style.cssText = "";
  //     window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
  //   };
  // }, []);

  const attendanceCheckList = (checkList) => {
    let markDate = [];
    checkList.map((check) => {
      markDate.push(check.checkDate);
      check.checkDate === moment(now).format("YYYY-MM-DD") && setHasCheck(true);
    });
    setCheckComplete(markDate);
  };

  const getAttendanceInfo = useCallback(
    async (response) => {
      try {
        const { data, status } = await jeboApi.getAttendanceInfo(
          myInfo.memberUid,
          now.getFullYear(),
          now.getMonth() + 1,
          moment(now).format("W"),
          attendanceType
        );

        if (status === 200) {
          const resultList = data.attendanceList;
          const imageInfo = data.eventImageInfo;

          //이벤트 이미지 정보
          setEventImage(imageInfo.eventImageFileName);
          //출석체크 정보
          resultList.length > 0 && attendanceCheckList(resultList);
        }
      } catch (error) {
        if (error.response) {
          console.log(error.response);
          if (error.response.status === 403) {
            alert("세션이 만료되었습니다. 다시 로그인 해주세요.");
            handleSignOutApp();
            setSignLayer("signIn");
            history.replace({ pathname: "/" });
          }
        }
      }
    },
    [myInfo]
  );

  const regAttendanceInfo = useCallback(async () => {
    if (checkComplete.includes(moment(now).format("YYYY-MM-DD"))) {
      setAlertMessage("출석체크는 하루 한 번 가능합니다.");
      setShowAlertLayer(true);
      return;
    }
    try {
      const { data, status } = await jeboApi.regAttendanceInfo(
        myInfo.memberUid,
        now.getFullYear(),
        now.getMonth() + 1,
        moment(now).format("W"),
        moment(now).format("YYYY-MM-DD"),
        attendanceType
      );
      if (status === 200) {
        const resultList = data.attendanceList;
        const checkCountForWeek = data.attendanceCheckCount;

        resultList.length > 0 && attendanceCheckList(resultList);
        setHasCheck(true);
        if (checkCountForWeek === 7) {
          setAlertMessage(
            "1주일 출석체크 완료! 이벤트는 자동으로 응모 됩니다."
          );
          setShowAlertLayer(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  });

  useEffect(() => {
    window.innerHeight >= 760 &&
      (window.document.body.style.overflowY = "hidden");
    getAttendanceInfo();
  }, [focusDate]);

  return createPortal(
    <Modal>
      <Wrap>
        <ModalHeader>
          출석체크
          <CloseButton onClick={(e) => setViewAttendance(false)} />
        </ModalHeader>
        <Container>
          <Section>
            <Title font={15}>
              매일매일 제보 출석 체크하고 상품 받아가세요! <br />
              100% 출석 완료 시, 추첨을 통해 선물을 드립니다.
            </Title>
          </Section>
          <Section marginTop={50}>
            <Title font={30}>이달의 출석 완료 선물</Title>
            <GiftImage
              alt="경품이미지"
              src={
                process.env.REACT_APP_RESOURCE_HOST +
                "/images/event/" +
                eventImage
              }
            />
          </Section>
          <Section>
            <RegButton
              onClick={(e) => {
                e.preventDefault(e);
                regAttendanceInfo();
              }}
            >
              {checkComplete && hasCheck ? "출석완료" : "출석인증하기"}
            </RegButton>
          </Section>
          <Section marginTop={20}>
            <BackgorundDiv>
              <Calendar
                calendarType="US"
                formatDay={(locale, date) => moment(date).format("DD")}
                navigationLabel={(label) =>
                  ("0" + (1 + now.getMonth())).slice(-2) + "월"
                }
                value={focusDate}
                tileClassName={({ date, view }) => {
                  if (
                    checkComplete.find(
                      (x) => x === moment(date).format("YYYY-MM-DD")
                    )
                  ) {
                    return moment(date).format("YYYY-MM-DD") ===
                      moment(now).format("YYYY-MM-DD")
                      ? "today_highlight"
                      : "highlight";
                  }
                }}
              />
            </BackgorundDiv>
          </Section>
          {showAlertLayer && (
            <AlertLayer
              setShowAlertLayer={setShowAlertLayer}
              title={alertMessage}
              description=""
              callback={() => {}}
            />
          )}
        </Container>
      </Wrap>
    </Modal>,
    document.getElementById("modal")
  );
}

const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 700px;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
`;

const Wrap = styled.div`
  position: fixed;
  top: 18%;
  margin-left: 15%;
  z-index: 4;
  width: 700px;
  height: 800px;
  border: 2px solid #dcdcdc;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 53%);
`;

const CloseButton = styled.button`
  position: absolute;
  height: 20px;
  width: 20px;
  margin-left: 260px;
  cursor: pointer;
  background: url(/static/media/exit.9fc98549.svg) no-repeat;
`;

const ModalHeader = styled.div`
  width: 100%;
  height: 70px;
  text-align: center;
  padding-top: 20px;
  font-size: 25px;
  font-weight: bold;
`;

const Container = styled.div`
  width: 100%;
  height: 90%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`;

const Section = styled.div`
  text-align: center;
  margin-top: ${(props) =>
    `${props.marginTop ? props.marginTop : 10}px !important`};
  margin: 10px;
`;

const Title = styled.div`
  font-size: ${(props) => `${props.font}px`};
  line-height: 20px;
`;

const GiftImage = styled.img`
  padding: 15px;
`;

const RegButton = styled.button`
  border-radius: 30px;
  height: 50px;
  width: 35%;
  font-size: 130%;
  font-weight: bold;
`;

const BackgorundDiv = styled.div`
  background: url(${testImg});
`;
