import jeboApi from "api/api";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { useContext, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

export default function LandingMessageSection() {
  const { isMobile } = useContext(isMobileContext);

  const history = useHistory();
  const match = useRouteMatch();
  const [landingMessageList, setLandingMessageList] = useState([]);

  useEffect(() => {
    //랜딩 메시지 불러오기
    !isMobile && getLandingMessage();
  }, [isMobile]);

  const getLandingMessage = async () => {
    try {
      const { status, data } = await jeboApi.getLandingMessage();

      if (status === 200) {
        setLandingMessageList(JSON.parse(data.landingMessageList));
      } else if (status === 204) {
        setLandingMessageList([]);
      }
    } catch (error) {
      setLandingMessageList([]);
      console.log(error);
    } finally {
    }
  };

  return (
    <div className="section-banner pc">
      <div className="container">
        <div className="banner">
          <Swiper
            autoplay={{ delay: 4000 }}
            autoHeight={true}
            className="swiper-container live-swiper"
            spaceBetween={30}
            slidesPerView={1}
            loop={
              landingMessageList && landingMessageList.length > 1 ? true : false
            }
            /*navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  pagination={{ clickable: true }}*/
            //scrollbar={{ draggable: true }}
            onSwiper={(swiper) => {}}
            onSlideChange={() => {}}
          >
            {landingMessageList && landingMessageList.length > 0 ? (
              landingMessageList.map((landingMessage, key) => (
                <SwiperSlide className="item swiper-slide" key={key}>
                  <strong>{landingMessage.text}</strong>
                </SwiperSlide>
              ))
            ) : (
              <strong>
                "당신의 일상을 제보하세요. 더 나은 세상이 될거에요."
              </strong>
            )}
          </Swiper>

          <button
            className="btn-md btn-border-wh"
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: `${match.url}/add`,
              });
            }}
          >
            제보하기
          </button>
        </div>
      </div>
    </div>
  );
}
