import React from "react";

export default function AgreeToOverAge({checkOverAge, setCheckOverAge}){

    return (
        <div className="serviceAgreeItem">
            <input
                onChange={(e) => {
                setCheckOverAge((current) => !current);
                }}
                id="serviceAgeChk"
                type="checkbox"
                checked={checkOverAge ? "checked" : ""}
            ></input>
            <label htmlFor="serviceAgeChk">
                14세 이상입니다.<span>(필수)</span>
            </label>
        </div>
    )
}