import React, { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
export default function ToastMessageBox({
  toastMessage,
  setToastMessage,
  cssStyle,
}) {
  const removeToastMessage = useCallback(() => {
    setToastMessage("");
  }, [setToastMessage]);

  useEffect(() => {
    window.setTimeout(removeToastMessage, 3000);

    return () => {
      window.clearTimeout(removeToastMessage);
    };
  }, [removeToastMessage]);

  return (
    <span className="item-toast on" style={cssStyle ? cssStyle : null}>
      {toastMessage}
    </span>
  );
}
