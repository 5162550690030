import iconMember from "assets/imgs/icons/icon_joinUser.svg";
import iconMemberActive from "assets/imgs/icons/icon_joinUserActive.svg";

import fullHeart from "assets/imgs/icons/icon_favRed.svg";
import emptyHeart from "assets/imgs/icons/icon_favRedBorder.svg";

import admin_icon from "assets/imgs/jebo_admin_profile.png";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SocialConfirmPop from "./SocialConfirmPop";
export default function SocialItemSlide({
  item,
  category,
  index,
  itemType,
  toggleLike,
  handleJoin,
  handleQuit,
  filter,
}) {
  const history = useHistory();
  const location = useLocation();
  const { isLoggedIn } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const [joinConfirm, setJoinConfirm] = useState(false);
  const [quitConfirm, setQuitConfirm] = useState(false);
  const [social, setSocial] = useState(null);

  const [classState, setClassState] = useState("summary");
  const { isMobile } = useContext(isMobileContext);

  useEffect(() => {
    setClassState(category.name);
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const swiperResponse = {
    spaceBetween: 1,
    slidesPerView: 1.75,
  };

  useEffect(() => {
    // 창 크기 변경 이벤트 리스너 등록
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // 컴포넌트 언마운트 시 이벤트 리스너 해제
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // setState로 값을 변경해주려고 하면 오류가 발생함
  // 너무 많은 re render를 했다
  if (windowWidth > 481 && windowWidth < 630) {
    // 481 spaceBetween slidesPerView
    swiperResponse["spaceBetween"] = 5;
    swiperResponse["slidesPerView"] = 1.75;
  } else if (windowWidth > 630 && windowWidth < 770) {
    // 630 spaceBetween slidesPerView
    // console.log("630");
    swiperResponse["spaceBetween"] = 5;
    swiperResponse["slidesPerView"] = 2.5;
  } else if (windowWidth > 770 && windowWidth < 940) {
    // 770 spaceBetween slidesPerView
    // console.log("770");
    swiperResponse["spaceBetween"] = 15;
    swiperResponse["slidesPerView"] = 3;
  } else if (windowWidth > 940) {
    // 940 spaceBetween slidesPerView
    // console.log("940");
    swiperResponse["spaceBetween"] = 25;
    swiperResponse["slidesPerView"] = 3;
  }

  return (
    <>
      <HotItemTitle>
        지금 가장 <span>인기</span> 있어요!
      </HotItemTitle>
      <Swiper
        autoplay={false}
        className="swiper-container live-swiper"
        spaceBetween={isMobile ? 1 : swiperResponse["spaceBetween"]}
        slidesPerView={isMobile ? 1.75 : swiperResponse["slidesPerView"]}
        loop={false}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        onSwiper={(swiper) => {}}
        onSlideChange={() => {}}
      >
        <HotItemContents>
          {item &&
            item.map((data, index) => (
              <SwiperSlide className="swiper-slide live-item">
                <ItemWrapper
                  index={index}
                  onClick={(e) => {
                    history.push({
                      ...location,
                      pathname: `/social/${category.name}/${data.scId}`,
                    });
                  }}
                >
                  <ContentWrapper>
                    <UpperInfoBox>
                      <UserPic
                        src={
                          data.scMemberUid === "ADMIN"
                            ? admin_icon
                            : data?.scMemberImageUrl
                            ? process.env.REACT_APP_API_HOST +
                              data.scMemberImageUrl
                            : "/static/media/user_icon.fcd9ee8a.svg"
                        }
                      ></UserPic>
                      <UserInfoCt>
                        <UserName>{data.scNickName}</UserName>
                        <JoinInfoWrap>
                          <HeartBox
                            className={data.scType === 3 ? "channel" : ""}
                          >
                            {data.scType !== 3 && (
                              <a
                                href="#!"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  toggleLike(data);
                                }}
                              >
                                <img
                                  src={data.like ? fullHeart : emptyHeart}
                                  alt=""
                                />
                                <h3>{data.likeCount}</h3>
                              </a>
                            )}
                          </HeartBox>
                          <MemberCountBox>
                            {data.scType === 3 ? (
                              `구독자 ${data.memberCount}명`
                            ) : (
                              <>
                                <img
                                  src={
                                    data.roleName !== "ANONYMOUS"
                                      ? iconMemberActive
                                      : iconMember
                                  }
                                  alt=""
                                />
                                <h3>{data.memberCount}</h3>
                              </>
                            )}
                          </MemberCountBox>
                        </JoinInfoWrap>
                      </UserInfoCt>

                      {data &&
                        data.roleName !== "ADMIN" &&
                        category.name === "channel" && (
                          <SubscribeBtn
                            className={
                              data && data.roleName !== "ANONYMOUS"
                                ? ""
                                : "active"
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setSocial(data);
                              isLoggedIn && data.roleName === "ANONYMOUS"
                                ? setJoinConfirm(true)
                                : isLoggedIn
                                ? setQuitConfirm(true)
                                : setSignLayer("signIn");
                            }}
                          >
                            구독
                          </SubscribeBtn>
                        )}
                    </UpperInfoBox>
                    <InfoWrap>
                      <TextWrapper>
                        <Title>{data.scName}</Title>
                      </TextWrapper>
                      <HashtagWrapper>
                        {data.scTag &&
                          data.scTag
                            .split(" ")
                            .filter((_, index) => index < 3)
                            .map((hashtag, index) => (
                              <Hashtag key={index}>{hashtag}</Hashtag>
                            ))}
                      </HashtagWrapper>
                    </InfoWrap>
                  </ContentWrapper>
                </ItemWrapper>
              </SwiperSlide>
            ))}
        </HotItemContents>
        <SwiperPaginationBtn className="swiper-pagination"></SwiperPaginationBtn>
      </Swiper>

      {joinConfirm && (
        <SocialConfirmPop
          title={social.scName}
          message={
            category.name === "channel"
              ? "구독하시겠습니까?"
              : "가입하시겠습니까?"
          }
          onClick={() => {
            setJoinConfirm(false);
            handleJoin(social);
            setSocial(null);
          }}
          onCancel={() => {
            setJoinConfirm(false);
            setSocial(null);
          }}
          category={category.name}
        />
      )}

      {quitConfirm && (
        <SocialConfirmPop
          title={social.scName}
          message={
            category.name === "channel"
              ? "구독을 취소하시겠습니까?"
              : "탈퇴하시겠습니까?"
          }
          onClick={() => {
            setQuitConfirm(false);
            handleQuit(social);
            setSocial(null);
          }}
          onCancel={() => {
            setQuitConfirm(false);
            setSocial(null);
          }}
          category={category.name}
        />
      )}
    </>
  );
}
const ItemWrapper = styled.div`
  overflow: hidden;
  ${({ index }) => {
    if (index === 4) {
      return css`
        border-bottom: none;
      `;
    }
  }};

  width: 190px;
  display: flex;
  padding: 14px 10px;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 20px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 481px) {
    width: 230px;
  }
  @media screen and (min-width: 800px) {
    width: 240px;
  }
`;

const InfoWrap = styled.div`
  display: flex;

  flex-direction: column;
`;

const JoinInfoWrap = styled.div`
  display: flex;
  margin-top: 4px;

  align-items: center;
  justify-content: start;
`;
const MemberCountBox = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  & > :first-child {
    margin-right: 5px;
  }
  img {
    width: 14px;
    height: 12px;
  }

  @media screen and (min-width: 481px) {
    font-size: 12px;
  }
`;

const HeartBox = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;

  margin-right: 11px;

  &.channel {
    margin-right: 0;
  }
  & a {
    display: flex;
  }
  & > :first-child {
    margin-right: 5px;
  }
  img {
    margin-right: 5px;
    width: 14px;
    height: 12px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
`;

const HashtagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  flex: 1 0 inherit;
  width: 100%;
  max-height: 30px;
  margin-bottom: 4px;
`;

const Hashtag = styled.p`
  display: block;
  height: 12px;
  max-width: 85px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 10px;
  line-height: 14px;

  color: #3773ff;

  margin-right: 10px;

  font-weight: 400;
  @media screen and (min-width: 481px) {
    margin-right: 7px;
    height: 15px;
    font-size: 11px;
  }
`;

const TextWrapper = styled.div`
  height: 100%;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  width: 100px;
  color: #292929;

  @media screen and (min-width: 481px) {
    width: 170px;
  }
`;
const Title = styled.p`
  overflow: hidden;
  white-space: normal;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #292929;
  width: inherit;

  white-space: nowrap;
  text-overflow: ellipsis;

  margin-bottom: 6px;

  @media screen and (min-width: 481px) {
    font-size: 13px;
    font-weight: 500;
  }
`;

const UpperInfoBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`;

const UserInfoCt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 65px;
  @media screen and (min-width: 481px) {
    width: 110px;
  }
`;

const UserPic = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid #dcdcdc;
  margin-right: 9px;
`;

const UserName = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  width: inherit;

  color: #292929;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width: 481px) {
    font-size: 14px;
  }
`;

const SubscribeBtn = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;

  color: #dcdcdc;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  /* width: 44px; */
  height: 27px;
  &.active {
    border: none;
    color: #ffffff;
    background: linear-gradient(
      134.74deg,
      #22e6f3 -66.53%,
      #29bdf7 -23.83%,
      #2f9dfa 16.93%,
      #3385fd 57.69%,
      #3677fe 94.56%,
      #3773ff 127.56%
    );
  }

  border-radius: 64px;

  padding: 4px 9px;

  @media screen and (min-width: 481px) {
    margin-left: auto;
  }
`;

const HotItemTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 18px;
  & span {
    color: #2f9bfa;
  }
`;
const HotItemContents = styled.div`
  display: flex;
  &.community {
    flex-direction: column;
    height: 220px;
    overflow: hidden;
  }
`;

const SwiperPaginationBtn = styled.div`
  &.swiper-pagination {
    text-align: center !important;
    & .swiper-pagination-bullet-active {
      color: #3289fc;
    }
    & .swiper-pagination-bullet {
      background: rgba(50, 137, 252, 0.4);
      width: 8px;
      height: 8px;
      margin: 0 3px;
    }
  }
`;
