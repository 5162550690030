import jeboApi from "api/api";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { Base64 } from "js-base64";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

/* 23.05.12 codelua 광고에 의해서 조절 될 수 있도록 처리.   */
export default function TopBandBannerSection({ cursor, classNum }) {
  const history = useHistory();
  const { mobileType } = useContext(isMobileContext);
  const [bandBannerInfo, setBandBannerInfo] = useState(null);
  const [defImg, setDefImg] = useState(null);

  // 배너 or 슬로건 가져오기.
  useEffect(() => {
    // if (classNum && classNum === "1006") {
    //   setBandBannerInfo({
    //     topMessage:
    //       '제보 APP <span style="color:#EE0000";>회원가입 이벤트 오픈!</span>',
    //     bottomMessage: "지금 이벤트 참여하고 선물 받아가세요.",
    //     fontColor: "#000000",
    //     gubunCode: "1",
    //     id: "18941607-ff81-456d-b98c-993882b6eadc",
    //     imagePath: "/media/topbanner/27ee5a43-adb5-11ed-8702-ac1f6bf629a4.png",
    //     linkUrl: "",
    //     pathUrl: "home/detail/60338207-1c93-44f2-a556-41f1d9091a4e",
    //   });
    // } else {
    //   getTopBandBannerList();
    // }
    getTopBandBannerList();
  }, [cursor, classNum]);

  useEffect(() => {
    //setDefImg((prev) => (bandBannerInfo ? bandBannerInfo.imagePath : prev));
    if (bandBannerInfo) setDefImg(bandBannerInfo.imagePath);
  }, [bandBannerInfo]);

  const getTopBandBannerList = async () => {
    try {
      console.log("call getTopBandBannerList");
      const { status, data } = await jeboApi.getTopBandBannerInfo();
      if (status === 200) {
        setBandBannerInfo(JSON.parse(data.topBandBannerInfo));
      } else if (status === 204) {
        setBandBannerInfo(null);
      }
    } catch (error) {
      setBandBannerInfo(null);
      console.log(error);
    } finally {
    }
  };

  return (
    <>
      {bandBannerInfo && <div className="section-mobile-banner-line" />}
      {bandBannerInfo && defImg ? (
        <BannerCt>
          <BannerLink
            img={process.env.REACT_APP_RESOURCE_HOST + defImg}
            href="_EXTERNALURL"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (bandBannerInfo.pathUrl) {
                history.push({
                  pathname: bandBannerInfo.pathUrl,
                });
                getTopBandBannerList();
              } else if (bandBannerInfo.linkUrl) {
                if (mobileType === "ios") {
                  const encodedUrl = Base64.encode(bandBannerInfo.linkUrl);
                  window.webkit.messageHandlers.openOuterLink.postMessage(
                    encodedUrl
                  );
                } else {
                  window.open(bandBannerInfo.linkUrl);
                }
              }
            }}
          >
            <div className="section-mobile-banner">
              <BannerTitle1
                color={bandBannerInfo && bandBannerInfo.fontColor}
                dangerouslySetInnerHTML={{
                  __html: bandBannerInfo && bandBannerInfo.topMessage,
                }}
              ></BannerTitle1>
              <BannerTitle2
                color={bandBannerInfo && bandBannerInfo.fontColor}
                dangerouslySetInnerHTML={{
                  __html: bandBannerInfo && bandBannerInfo.bottomMessage,
                }}
              ></BannerTitle2>
            </div>
          </BannerLink>
        </BannerCt>
      ) : (
        <BannerCt></BannerCt>
      )}
    </>
  );
}

const BannerCt = styled.div`
  padding: 0px 10px;
  background-color: #fff;
  width: 100%;
  height: 64px;
`;

const BannerLink = styled.a`
  width: 100%;
  height: 64px;
  background: url(${({ img }) => img}) no-repeat;
  background-position: center;
  background-size: cover;

  border-radius: 10px;

  display: inline-flex;
  padding: 7px 14px;
  align-items: center;
`;

// BannerTitle1은 기본 속성
const BannerTitle1 = styled.p`
  font-size: 14px;
  color: #232323;
  line-height: 20.27px;
  font-weight: 500;
  /* line-height: 6.5vw; */
  span {
    display: inline-block;
    font-weight: 700;
  }
`;

// BannerTitle1 상속 및 글자 간격 조정
const BannerTitle2 = styled(BannerTitle1)`
  /* letter-spacing: 0.3px; */
`;
