import SignLayer from "components/layers/sign/SignLayer";
import { signAppContext } from "components/providers/SignContextProvider";
import { cloneElement, useContext, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import CloseSocial from "./CloseSocial";
import ContentDetail from "./ContentDetail";
import CreatePost from "./CreatePost";
import CreateSocial from "./CreateSocial";
import EditSocial from "./EditSocial";
import ProposeSocial from "./ProposeSocial";
import FeedDetailLayer from "components/layers/detail/FeedDetailLayer";

export default function Content({
  category,
  content,
  dispatchContent,
  setActiveCategory,
  children,
}) {
  const { isLoggedIn } = useContext(signAppContext);

  const match = useRouteMatch();

  useEffect(() => {
    setActiveCategory(category);
  }, [setActiveCategory, category]);

  // useEffect(() => {
  //   dispatchContent({ type: "INIT_DATA" });
  // }, [dispatchContent]);

  // const loadContent = useCallback(async () => {
  //   try {
  //     const {
  //       data: { socialData: resultData },
  //       status,
  //     } = await jeboApi.getSocialList(
  //       category.type,
  //       sortType,
  //       cursor,
  //       queryKeyword,
  //       filter && filter.joined ? filter.joined : false
  //     );

  //     if (status === 200) {
  //       const socialData = JSON.parse(resultData);
  //       dispatchContent({
  //         type: "FETCH_DATA",
  //         datas: socialData,
  //       });
  //     } else if (status === 204) {
  //       dispatchContent({ type: "LAST_DATA" });
  //     }
  //   } catch (error) {
  //     const { status } = error;
  //     console.log(error, status);
  //   }
  // }, [cursor, sortType, queryKeyword, filter, category.type, dispatchContent]);
  // useEffect(() => {
  //   console.log("loadContent....");
  //   loadContent();
  // }, [loadContent]);

  const updateSocial = (social) => {
    dispatchContent({ type: "UPDATE_DATA", data: social });
  };

  const removeSocial = (socialId) => {
    dispatchContent({ type: "REMOVE_DATA", socialId });
  };

  const addSocial = (social) => {
    dispatchContent({ type: "ADD_DATA", data: social });
  };

  return (
    <>
      <Switch>
        <Route
          path={match.path}
          exact
          render={(props) =>
            cloneElement(children, {
              category,
              content,
              dispatchContent,
              updateSocial,
              removeSocial,
              addSocial,
            })
          }
        />

        <Route
          path={`${match.path}/create`}
          exact
          render={(props) =>
            isLoggedIn ? (
              <CreateSocial category={category} addSocial={addSocial} />
            ) : (
              <SignLayer />
            )
          }
        />
        <Route
          path={`${match.path}/propose`}
          exact
          render={(props) =>
            isLoggedIn ? <ProposeSocial category={category} /> : <SignLayer />
          }
        />

        <Route
          path={`${match.path}/:socialId/close`}
          exact
          render={(props) =>
            isLoggedIn ? (
              <CloseSocial category={category} removeSocial={removeSocial} />
            ) : (
              <SignLayer />
            )
          }
        />
        <Route
          path={`${match.path}/:socialId/modify`}
          exact
          render={(props) =>
            isLoggedIn ? (
              <EditSocial category={category} updateSocial={updateSocial} />
            ) : (
              <SignLayer />
            )
          }
        />

        <Route
          path={`${match.path}/:socialId/createPost`}
          exact
          render={(props) =>
            isLoggedIn ? (
              <CreatePost category={category} updateSocial={updateSocial} />
            ) : (
              <SignLayer />
            )
          }
        />
        <Route
          path={`${match.path}/detail/:jeboId`}
          render={(props) => <FeedDetailLayer />}
        />

        <Route
          path={`${match.path}/:socialId`}
          render={(props) => (
            <ContentDetail
              category={category}
              updateSocial={updateSocial}
              removeSocial={removeSocial}
              content={content}
            />
          )}
        />
      </Switch>
    </>
  );
}
