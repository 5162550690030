import jeboApi from "api/api";
import MobileFeedListLayer from "components/layers/detail/MobileFeedListLayer";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { feedsContext } from "components/providers/FeedContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import FeedContainer from "components/templates/FeedContainer";
import GridFeed from "components/templates/GridFeed";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import { Route, useRouteMatch } from "react-router-dom";

const popMenuReducer = (state, action) => {
  switch (action.type) {
    case "DISPLAY_MENU":
      return {
        ...state,
        id: action.id,
        name: action.name,
      };
    case "CLEAR":
      return initialPopMenuState;

    default:
      return state;
  }
};

const initialPopMenuState = {
  id: "",
  name: "",
};

const END_LIMIT = 36;
export default function FeedSection({ memberUid, activeMenu }) {
  const { feeds, setFeeds } = useContext(feedsContext);
  const { isMobile } = useContext(isMobileContext);
  const { handleSignOutApp, myInfo } = useContext(signAppContext);

  const [cursor, setCursor] = useState(0);
  const [lastFeedRef, inView] = useInView();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notLoadedData, setNotLoadedData] = useState(false);

  const [popMenu, dispatchPopMenu] = useReducer(
    popMenuReducer,
    initialPopMenuState
  );

  const match = useRouteMatch();

  useEffect(() => {
    setFeeds([]);
    setCursor(0);
  }, [activeMenu, setFeeds]);

  const loadFeeds = useCallback(async () => {
    try {
      setLoading(true);

      let response = null;

      if (activeMenu === "MY") {
        response = await jeboApi.myFeedList(memberUid, END_LIMIT, cursor);
      } else if (activeMenu === "HIDDEN") {
        response = await jeboApi.myHiddenFeedList(END_LIMIT, cursor);
      } else if (activeMenu === "CABINET") {
        response = await jeboApi.myCabinetFeedList(END_LIMIT, cursor);
      }

      const { status, data } = response;

      if (status === 200) {
        const jeboPostArr = JSON.parse(data.jeboPost);
        setNotLoadedData(jeboPostArr.length < END_LIMIT ? true : false);

        if (cursor === 0) setFeeds(jeboPostArr);
        else setFeeds((prev) => prev.concat(jeboPostArr));
      } else if (status === 204) {
        console.log("no data to be loaded..");
        setNotLoadedData(true);
      }
    } catch (error) {
      console.log(error.response);
      if (error.response?.status === 403) {
        alert("세션이 만료되었습니다. 다시 로그인해주세요.");
        handleSignOutApp();
      }
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [cursor, activeMenu, memberUid, setFeeds, handleSignOutApp]);

  useEffect(() => {
    loadFeeds();
  }, [loadFeeds]);

  useEffect(() => {
    if (inView && !error && !notLoadedData)
      setCursor((prev) => prev + END_LIMIT);
  }, [inView, error, notLoadedData]);

  const onUpdateFeed = (updatedFeed) => {
    setFeeds((prev) =>
      prev.map((item) => {
        if (item.jeboId === updatedFeed.jeboId) {
          return Object.assign({}, item, updatedFeed);
        }
        return item;
      })
    );
  };

  useEffect(() => {
    if (activeMenu === "CABINET") {
      const keepFeeds = feeds.filter((feed) => !feed.keeping);
      if (keepFeeds && keepFeeds.length > 0) {
        setFeeds((prev) => prev.filter((feed) => feed.keeping));
      }
    } else if (activeMenu === "HIDDEN") {
      const hiddenFeeds = feeds.filter((feed) => feed.hiddenYn === "N");
      if (hiddenFeeds && hiddenFeeds.length > 0) {
        setFeeds((prev) => prev.filter((feed) => feed.hiddenYn === "Y"));
      }
    }
  }, [activeMenu, feeds, setFeeds]);

  const onBlockFeed = (memberUid) => {
    setFeeds((prev) => prev.filter((item) => item.memberUid !== memberUid));
  };

  const onDeleteFeed = (feed) => {
    setFeeds((prev) => prev.filter((item) => item.jeboId !== feed.jeboId));
  };

  return (
    <>
      <Route
        path={`${match.path}/list`}
        render={(props) => (
          <MobileFeedListLayer title={`${myInfo.nickName}님의 제보`} />
        )}
      />
      {isMobile ? (
        <section className="section-grid mobile">
          <div className="container">
            <div className="grid">
              <ul>
                {feeds &&
                  feeds.map((feed, index, array) => (
                    <GridFeed
                      key={index}
                      feed={feed}
                      lastFeedRef={
                        array.length - 1 === index ? lastFeedRef : null
                      }
                      page="mypage"
                    />
                  ))}
              </ul>
            </div>
          </div>
        </section>
      ) : (
        <section className="section-feed pc">
          <div className="container">
            <div className="cont-feed">
              <div className="list">
                {feeds &&
                  feeds.map((feed, index, array) => (
                    <FeedContainer
                      key={index}
                      feed={feed}
                      popMenu={popMenu}
                      dispatchPopMenu={dispatchPopMenu}
                      onUpdateFeed={onUpdateFeed}
                      onDeleteFeed={onDeleteFeed}
                      onBlockFeed={onBlockFeed}
                      page="mypage"
                    />
                  ))}
                {feeds.length > 0 && <div ref={lastFeedRef}></div>}
                {!error && loading && (
                  <div className="feed-spinner">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
