import jeboApi from "api/api";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import CollectBadgeDetailMobile from "components/templates/CollectBadgeDetailMobile";
import CollectBadgeDetailWeb from "components/templates/CollectBadgeDetailWeb";
import { useCallback, useContext, useEffect, useState } from "react";

export default function CollectBadgeContainer({ memberUid }) {
  const [memberRewardList, setMemberRewardList] = useState(null);
  const [memberReward, setMemberReward] = useState(null);

  const handleBadgeClick = (memberReward) => {
    setMemberReward(memberReward);
    setModalIsOpen(!modalIsOpen);
  };

  const handleBadgeClickMobile = (isclickBackground) => {
    isclickBackground && setModalIsOpen(!modalIsOpen);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { isMobile } = useContext(isMobileContext);

  const getMemberRewardList = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.getMemberRewardList(memberUid);

      if (status === 200) {
        setMemberRewardList(JSON.parse(data.memberRewardList));
      }
    } catch (error) {
      console.log("error : ", error);
    }
  }, [memberUid]);

  useEffect(() => {
    getMemberRewardList();
  }, [getMemberRewardList]);

  return (
    <div className="badge-list">
      {/* 0628 진희 배지 수정중 */}
      <ul>
        {memberRewardList &&
          memberRewardList.map((memberReward, index, arr) => {
            return (
              <li
                className="badge-item"
                onClick={(e) => {
                  handleBadgeClick(memberReward);
                }}
              >
                <img
                  alt="배지"
                  className="icon active"
                  src={
                    process.env.REACT_APP_RESOURCE_HOST +
                    memberReward.rewardImagePath
                  }
                ></img>
                {/* <span className="icon active"></span> */}
                <strong className="badge-item_desc">
                  {memberReward.rewardName}
                </strong>
                <div className="icon-modal"></div>
              </li>
            );
          })}
      </ul>

      {modalIsOpen &&
        (isMobile ? (
          <CollectBadgeDetailMobile
            handleBadgeClickMobile={handleBadgeClickMobile}
            setModalIsOpen={setModalIsOpen}
            memberReward={memberReward}
          />
        ) : (
          <CollectBadgeDetailWeb
            handleBadgeClickMobile={handleBadgeClickMobile}
            memberReward={memberReward}
          />
        ))}
    </div>
  );
}
