import React, { useCallback, useContext, useEffect, useState } from "react";
import jeboApi from "api/api";
import AlertLayer from "components/layers/popMenu/AlertLayer";
import ConfirmLayer from "components/layers/popMenu/ConfirmLayer";
import { signAppContext } from "components/providers/SignContextProvider";

export default function MarketingPolicy({
  setMarketingInfo,
  callFrom,
  policyRef,
}) {
  const [showConfirmLayer, setShowConfirmLayer] = useState(false);
  const [showAlertLayer, setShowAlertLayer] = useState(false);
  const [marketingInfoCheck, setMarketingInfoCheck] = useState(false);
  const { myInfo } = useContext(signAppContext);

  const updateMarketingInfo = async () => {
    try {
      const response = await jeboApi.updateMemberStatus(
        myInfo.memberUid,
        marketingInfoCheck ? "T" : "F"
      );
      if (response.status === 200) {
        setShowAlertLayer(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMemberStatus = useCallback(async () => {
    try {
      const response = await jeboApi.getMemberStatus(myInfo.memberUid);
      if (response.status === 200) {
        const { data } = response;

        const memberStatus = JSON.parse(data.memberStatus);
        setMarketingInfoCheck(
          memberStatus.marketingInfo === "T" ? true : false
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [myInfo]);

  useEffect(() => {
    getMemberStatus();
  }, [getMemberStatus]);

  useEffect(() => {
    policyRef && policyRef.current.scrollTo(0, 0);
  }, [showConfirmLayer, policyRef]);

  const closePolicyLayer = () => {
    setMarketingInfo(false);
  };

  return (
    <>
      <div className="policy-container__textBox">
        <h2 className="policy__main__title">마케팅 정보 수집 및 이용동의</h2>
        <p className="policy__title">
          위치기반 정보공유 “제보(jebo)” 마케팅 관련 개인 정보 수집 및 이용
          동의(선택)
        </p>
        <p className="policy__desc">
          주식회사 리스트(이하 “회사”라고 합니다.)는 개인정보보호법 및
          정보통신망 이용 촉진 및 정보보호 및 정보통신망 이용 촉진 및 정보보호
          등에 관련 법률 등 관계 법령에 따라 마케팅 및 홍보 활동을 위해 이용자의
          개인 정보(이하 “마케팅 정보”라고 합니다)활용 동의를 받고 있습니다.
          아래의 내용을 충분히 숙지하신 후 해당 내용의 동의 여부를 결정하시기
          바랍니다.
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">제 1 조 마케팅 정보 수집 및 이용 목적</h2>
        <p className="policy__desc">
          제보(jebo) 서비스는 회원님에게 보다 다양한 정보를 제공하고, 서비스의
          질을 향상시키거나 신규 기능 및 서비스 안내 및 이벤트 안내 등
          영업목적으로 마케팅 관련 개인 정보를 수집 및 이용 할 수 있습니다.
          <br />
          1) 신규 기능 및 서비스 안내
          <br />
          2) 캠페인 안내 및 참여 기회 제공
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">제 2 조 수집하려는 마케팅 정보의 항목</h2>
        <p className="policy__desc no">
          1. 회원계정(또는 아이디), 비밀번호, 이메일주소, 단말정보(OS정보)
        </p>
        <p className="policy__desc no">2. 서비스 이용 기록 및 통계</p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">
          제 3 조 마케팅 정보의 보유 및 이용 기간
        </h2>
        <p className="policy__desc">
          동의 일로부터 회원 탈퇴 혹은 마케팅 이용 동의 철회 시까지 마케팅과
          관련된 정보 전달을 위해 보유 및 이용합니다.
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">
          제 4 조 동의거부권 및 마케팅 정보 수집 및 이용 동의 변경 및 철회
        </h2>
        <p className="policy__desc no">
          1. 회원은 마케팅 수집 및 활용에 대한 동의를 거부할 권리가 있으며, 활용
          동의 이후라도 언제든지 [내계정 &gt; 설정 &gt; 약관 및 정책 &gt; 마케팅
          정보 수집 및 이용 동의] 페이지를 통해 마케팅 활용 동의를
          변경(동의하거나 철회) 또는 철회할 수 있습니다.
        </p>
        <p className="policy__desc no">
          2. 동의 거부 시 회원가입을 통한 기본 서비스 이용은 가능하나, 광고 및
          이벤트 안내 등의 마케팅 관련 서비스 제공이 제한될 수 있습니다.
        </p>
        <p className="policy__desc no">
          3. 해당 요청의 근거자료를 남기기 위해 해당 문의 내역은 별도로 보관될
          수 있으며 회원은 이에 동의하는 것으로 간주합니다. 이미 제공된
          회원정보를 철회하는 데는 최대 10일의 기간이 소요됩니다. 활용 동의
          철회를 요청하시더라도 위와 같은 해당 철회 적용 이전까지 마케팅에 활용
          될 수 있음을 알려드립니다.
        </p>
        <p className="policy__desc no">
          4. 회사는 회원님의 소중한 정보를 보호하기 위하여 신속하게 처리되도록
          최선의 노력을 다하겠습니다.
        </p>
      </div>

      {callFrom !== "signUp" && (
        <>
          <div className="policy-check-div">
            <input
              type="checkbox"
              id="service04"
              checked={marketingInfoCheck}
              onChange={(e) => {
                setMarketingInfoCheck(e.target.checked);
              }}
            ></input>
            <label htmlFor="service04">
              마케팅 정보 수집 및 이용에 동의합니다.
            </label>
          </div>
          {showConfirmLayer && (
            <ConfirmLayer
              setShowConfirmLayer={setShowConfirmLayer}
              callback={updateMarketingInfo}
              title="마케팅 정보 수집 및 이용동의를\n수정 하시겠습니까?"
              description=""
              btnText="수정하기"
              isConfirm={true}
            />
          )}
          {showAlertLayer && (
            <>
              <AlertLayer
                setShowAlertLayer={setShowAlertLayer}
                title="마케팅 정보 수집 및 이용동의가\n수정되었습니다."
                description=""
                callback={closePolicyLayer}
              />
            </>
          )}
          <button
            className="service-complete-btn"
            onClick={(e) => {
              setShowConfirmLayer(true);
            }}
          >
            수정
          </button>
        </>
      )}
    </>
  );
}
