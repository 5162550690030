import AuthService from "api/AuthService";
import axios from "axios";

const baseURL = `${process.env.REACT_APP_API_HOST}/api/`;

const api = axios.create({ baseURL });
const authApi = axios.create({ baseURL });
const signApi = axios.create({ baseURL });
//// FEAT : checkToken 로직 추가
api.interceptors.response.use(
  (response) => {
    //const { status } = response;
    //console.log(status);
    return response;
  },
  async (error) => {
    const { config, response } = error;
    //console.log(config, error);
    if (response.status === 401) {
      const authorization = config.headers.authorization;
      const refresh_token = AuthService.getRefreshToken();

      try {
        const { status } = await authApi.post(
          "/common/checkToken",
          {},
          {
            headers: { authorization, refresh_token },
          }
        );
        if (status === 200) {
          config.headers.authorization = AuthService.getAuthorization();
          return api.request(config);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);
//// FEAT : checkToken 로직 추가

authApi.interceptors.response.use(
  (response) => {
    const { headers, config, status } = response;
    //response headers의 Authorization, refresh_token을 전달 받았다면
    //새로 갱신된 토큰 값이므로 기존의 sessionStorage에 Authorization,refresh_token 값을 업데이트한다.

    if (status === 205) {
      AuthService.setAuth(
        headers.authorization,
        headers.refresh_token,
        headers.member_email,
        "",
        //Base64.decode(headers.member_nickName),
        headers.grant_type,
        headers.member_uid
      );

      config.headers.authorization = headers.authorization;
      config.headers.refresh_token = headers.refresh_token;
      console.log("acessToken 갱신. 서비스 재 호출", response);
      return authApi.request(config);
    }

    return response;
  },
  (error) => {
    const { response, config } = error;
    if (response.status === 401) {
      console.log("accessToken 만료. refresh token으로 요청", response);
      config.headers.refresh_token = AuthService.getRefreshToken();
      return authApi.request(config);
    } else if (response.status === 403) {
      console.log("accessToken 만료, refreshToken 만료. 강제 로그아웃 ");
      return Promise.reject(error);
    } else {
      console.log("다른 이유로 에러 : ", response);
      return Promise.reject(error);
    }
  }
);

const jeboApi = {
  existMemberYn: (id, fromSocialLogin) => {
    return api.post("social/existMemberYn", {
      [fromSocialLogin + "SocialId"]: id,
      fromSocialLogin,
    });
  },
  signIn: (email, password, deviceId, fcmToken, deviceType) => {
    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    deviceId && formData.append("deviceId", deviceId);
    deviceType && formData.append("deviceType", deviceType);
    fcmToken && formData.append("fcmToken", fcmToken);

    return signApi.post("login", formData);
  },
  autoSignIn: (refreshToken, deviceId, fcmToken, deviceType) => {
    let formData = new FormData();
    formData.append("refreshToken", refreshToken);
    formData.append("isAutoLogin", true);

    formData.append("deviceId", deviceId);
    formData.append("deviceType", deviceType);
    formData.append("fcmToken", fcmToken);

    return signApi.post("common/autoSignIn", formData);
  },
  socialSignIn: (
    socialId,
    email,
    nickName,
    fromSocialLogin,
    deviceId,
    fcmToken,
    deviceType,
    agreeItems
  ) => {
    return api.post("social/login", {
      email,
      nickName,
      fromSocialLogin,
      [fromSocialLogin + "SocialId"]: socialId,
      deviceId: deviceId ? deviceId : "",
      fcmToken: fcmToken ? fcmToken : "",
      deviceType: deviceType ? deviceType : "",
      overAge: agreeItems && agreeItems.overAge ? "T" : "F",
      termsOfUse: agreeItems && agreeItems.termsOfUse ? "T" : "F",
      personalData: agreeItems && agreeItems.personalData ? "T" : "F",
      locationInfo: agreeItems && agreeItems.locationInfo ? "T" : "F",
      marketingInfo: agreeItems && agreeItems.marketingInfo ? "T" : "F",
    });
  },
  sendCertificationEmail: (email) => {
    return api.post("common/emailCertification", { email });
  },
  checkCertificationKey: (certificationKey, email) => {
    return api.post("common/checkCertificationKey", {
      certificationKey,
      email,
    });
  },
  checkDuplicationNickName: (nickName) => {
    return api.post("member/checkDuplicationNickName", { nickName });
  },
  signUp: (email, nickName, password, agreeItems) => {
    return api.post("member/signUp", {
      email,
      nickName,
      password,
      overAge: agreeItems && agreeItems.overAge ? "T" : "F",
      termsOfUse: agreeItems && agreeItems.termsOfUse ? "T" : "F",
      personalData: agreeItems && agreeItems.personalData ? "T" : "F",
      locationInfo: agreeItems && agreeItems.locationInfo ? "T" : "F",
      marketingInfo: agreeItems && agreeItems.marketingInfo ? "T" : "F",
    });
  },
  updateMemberPassword: (prePassword, password) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };
    return authApi.post(
      "member/updateMemberPassword",
      { prePassword, password },
      { headers }
    );
  },
  sendFindPasswordMail: (email) => {
    return api.post("common/sendFindPasswordMail", { email });
  },
  loadLikeMember: (jeboId) => {
    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;
    if (authorization) {
      let headers = { authorization };
      return api.post("jebo/post/like/memberList", { jeboId }, { headers });
    }
    return api.post("jebo/post/like/memberList", { jeboId });
  },
  toggleLike: (jeboId, type) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };
    if (type) {
      return authApi.post("jebo/post/like/reg", { jeboId, type }, { headers });
    } else {
      return authApi.post("jebo/post/like/remove", { jeboId }, { headers });
    }
  },
  toggleCommentLike: (jeboId, commentId, type) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };
    if (type) {
      let formData = new FormData();
      formData.append("commentId", commentId);
      formData.append("type", type);

      return authApi.post(
        "jebo/post/comment/like/reg",
        { jeboId, commentId, type },
        { headers }
      );
    } else {
      return authApi.post(
        "jebo/post/comment/like/remove",
        { jeboId, commentId },
        { headers }
      );
    }
  },
  feedRegist: (
    media,
    hashTags,
    contents,
    address,
    roadAddress,
    placeName,
    latitude,
    longitude,
    locationHashtags,
    eventId,
    feedType
  ) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
      "Content-Type": "multipart/form-data",
    };
    let formData = new FormData();
    media.forEach((item) => {
      formData.append("mediaFiles", item.file);
      formData.append("thumbnails", item.thumbnail);
    });

    contents && formData.append("contents", contents);
    address && formData.append("address", address);
    roadAddress && formData.append("roadAddress", roadAddress);
    placeName && formData.append("placeName", placeName);
    latitude && formData.append("latitude", latitude);
    longitude && formData.append("longitude", longitude);
    hashTags && hashTags.length > 0 && formData.append("hashTag", hashTags);
    eventId && formData.append("eventId", eventId);
    feedType && formData.append("feedType", feedType);
    locationHashtags &&
      locationHashtags.length > 0 &&
      formData.append("locationHashtag", locationHashtags.join(" "));
    // formData.append(
    //   "locationHashtag",
    //   placeName
    //     ? "#" + placeName.replaceAll(" ", "_")
    //     : "#" + address.replaceAll(" ", "_")
    // );

    return authApi.post("jebo/post/reg", formData, { headers });
  },
  shareFeedRegist: (
    media,
    hashTags,
    contents,
    address,
    roadAddress,
    placeName,
    latitude,
    longitude,
    locationHashtags,
    eventId,
    feedType
  ) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    let formData = new FormData();
    media.forEach((item) => {
      formData.append("mediaFiles", item.file);
      formData.append("thumbnails", item.thumbnail);
    });
    formData.append("contents", contents);
    if (latitude && longitude) {
      formData.append("address", address ? address : "");
      formData.append("roadAddress", roadAddress ? roadAddress : "");
      formData.append("placeName", placeName ? placeName : "");
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      locationHashtags &&
        locationHashtags.length > 0 &&
        formData.append("locationHashtag", locationHashtags.join(" "));
      // formData.append(
      //   "locationHashtag",
      //   placeName
      //     ? "#" + placeName.replaceAll(" ", "_")
      //     : "#" + address.replaceAll(" ", "_")
      // );
    }
    formData.append("hashTag", hashTags);
    formData.append("eventId", eventId ? eventId : "");
    formData.append("feedType", feedType);

    return authApi.post("jebo/post/reg", formData, { headers });
  },
  shareFeedModify: (
    media,
    hashTags,
    contents,
    address,
    roadAddress,
    placeName,
    latitude,
    longitude,
    locationHashtags,
    jeboId,
    feedType
  ) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    let formData = new FormData();
    let changeThumbnail = false;
    let mediaId = "";

    formData.append("jeboId", jeboId);
    media.forEach((item) => {
      if (item.upload) {
        //실제 파일 업로드 : 새롭게 올릴 이미지
        formData.append("mediaFiles", item.file);
        formData.append("thumbnails", item.thumbnail);
        if ("mediaId" in item && item.mediaId > 0) {
          formData.append("modMediaIds", item.mediaId);
        } else {
          formData.append("modMediaIds", "");
        }
      } else {
        //기존 업로드된 파일 : 기존 파일은 실제 파일 업로드를 하지않고,
        //image_index 값을 통해 처리한다.(해당 리스트에 없는 index를 DB에서 삭제)
        formData.append("mediaIds", item.id);
        if (item.changeThumbnail) {
          changeThumbnail = item.changeThumbnail;
          mediaId = item.id;
        }
      }
    });
    formData.append("changeThumbnail", changeThumbnail);
    formData.append("mediaId", mediaId);
    formData.append("contents", contents);

    if (latitude && longitude) {
      formData.append("address", address ? address : "");
      formData.append("roadAddress", roadAddress ? roadAddress : "");
      formData.append("placeName", placeName ? placeName : "");
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      locationHashtags &&
        locationHashtags.length > 0 &&
        formData.append("locationHashtag", locationHashtags.join(" "));
      // formData.append(
      //   "locationHashtag",
      //   placeName
      //     ? "#" + placeName.replaceAll(" ", "_")
      //     : "#" + address.replaceAll(" ", "_")
      // );
    }
    formData.append("hashTag", hashTags);
    formData.append("feedType", feedType);

    return authApi.post("jebo/post/mod", formData, { headers });
  },
  feedModify: (
    media,
    hashTags,
    contents,
    address,
    roadAddress,
    placeName,
    latitude,
    longitude,
    locationHashtags,
    jeboId,
    feedType
  ) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
      "Content-Type": "multipart/form-data",
    };

    let formData = new FormData();
    let changeThumbnail = false;
    let mediaId = "";

    formData.append("jeboId", jeboId);
    media.forEach((item) => {
      if (item.upload) {
        //실제 파일 업로드 : 새롭게 올릴 이미지
        formData.append("mediaFiles", item.file);
        formData.append("thumbnails", item.thumbnail);
        if ("mediaId" in item && item.mediaId > 0) {
          formData.append("modMediaIds", item.mediaId);
        } else {
          formData.append("modMediaIds", "");
        }
      } else {
        //기존 업로드된 파일 : 기존 파일은 실제 파일 업로드를 하지않고,
        //image_index 값을 통해 처리한다.(해당 리스트에 없는 index를 DB에서 삭제)
        formData.append("mediaIds", item.id);
        if (item.changeThumbnail) {
          changeThumbnail = item.changeThumbnail;
          mediaId = item.id;
        }
      }
    });
    formData.append("changeThumbnail", changeThumbnail);
    formData.append("mediaId", mediaId);
    formData.append("contents", contents);
    formData.append("address", address ? address : "");
    formData.append("roadAddress", roadAddress ? roadAddress : "");
    formData.append("placeName", placeName ? placeName : "");
    formData.append("latitude", latitude ? latitude : "");
    formData.append("longitude", longitude ? longitude : "");
    formData.append("hashTag", hashTags);
    formData.append("feedType", feedType);

    locationHashtags &&
      locationHashtags.length > 0 &&
      formData.append("locationHashtag", locationHashtags.join(" "));
    // formData.append(
    //   "locationHashtag",
    //   placeName
    //     ? "#" + placeName.replaceAll(" ", "_")
    //     : "#" + address.replaceAll(" ", "_")
    // );

    return authApi.post("jebo/post/mod", formData, { headers });
  },
  feedDetail: (jeboId, memberUid, interLat, interLong) => {
    let params = { jeboId, memberUid, interLat, interLong };
    return api.post("jebo/post/detail", params);
  },
  feedList: (
    searchStr,
    interTag,
    interLat,
    interLong,
    endLimit,
    cursor,
    sortType,
    baseTime,
    intervalDay,
    adsAction
  ) => {
    let params = {
      searchStr,
      interTag: interTag ? interTag : "",
      interLong: interLong ? interLong : "0",
      interLat: interLat ? interLat : "0",
      endLimit: endLimit ? endLimit : 0,
      lastCursor: cursor,
      jeboId: cursor && cursor.lastJeboId,
      sortType,
      baseTime: baseTime ? baseTime : "",
      eventId: "",
      intervalDay: intervalDay ? intervalDay : 10,
      eventTypeCode: adsAction ? adsAction : "",
    };

    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    console.log("feedList 피드조회호출");
    if (authorization) {
      let headers = { authorization };
      return api.post("jebo/post/list", params, { headers });
    }

    return api.post("jebo/post/list", params);
  },
  searchFeedList: (
    searchStr,
    interTag,
    interLat,
    interLong,
    endLimit,
    cursor,
    sortType,
    pathname,
    eventId
  ) => {
    console.log("searchFeedList 피드조회호출");
    let params = {
      searchStr: searchStr ? searchStr : "",
      interTag: interTag ? interTag : "",
      interLong: interLong ? interLong : "0",
      interLat: interLat ? interLat : "0",
      endLimit: endLimit ? endLimit : 0,
      lastCursor: cursor,
      jeboId: cursor && cursor.lastJeboId,
      sortType,
      pathname,
      eventId,
    };

    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    if (authorization) {
      let headers = { authorization };
      return api.post("jebo/post/list", params, { headers });
    }
    return api.post("jebo/post/list", params);
  },
  myCabinetFeedList: (endLimit, cursor) => {
    console.log("myCabinetFeedList 피드조회호출");

    let params = {
      endLimit: endLimit ? endLimit : 0,
      lastCursor: cursor,
      //lastCursor: cursor && cursor.lastCreateAt,
      //jeboId: cursor && cursor.lastJeboId,
    };

    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;
    if (authorization) {
      let headers = { authorization };
      return authApi.post("jebo/post/memberKeepList", params, { headers });
    }
  },
  myFeedList: (memberUid, endLimit, cursor) => {
    console.log("myFeedList 피드조회호출");

    let params = {
      targetMemberUid: memberUid,
      endLimit: endLimit ? endLimit : 0,
      lastCursor: cursor,
    };

    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;
    if (authorization) {
      let headers = { authorization };
      return api.post("jebo/post/myList", params, { headers });
    }

    return api.post("jebo/post/myList", params);
  },
  myHiddenFeedList: (endLimit, cursor) => {
    console.log("myHiddenFeedList 피드조회호출");

    let params = {
      endLimit: endLimit ? endLimit : 0,
      lastCursor: cursor,
    };

    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return authApi.post("jebo/hidden/myHiddenPosts", params, { headers });
  },
  commentReg: (
    media,
    contents,
    jeboId,
    mentions,
    parentCommentId,
    toMemberUid
  ) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
      "Content-Type": "multipart/form-data",
    };
    let formData = new FormData();
    formData.append("jeboId", jeboId);
    media && formData.append("mediaFile", media.file);
    formData.append("contents", contents);
    mentions && formData.append("mentions", mentions);
    parentCommentId && formData.append("parentCommentId", parentCommentId);
    toMemberUid && formData.append("toMemberUid", toMemberUid);

    return authApi.post("jebo/post/comment/reg", formData, { headers });
  },
  commentRemove: (jeboId, commentId, createAt) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return authApi.post(
      "jebo/post/comment/remove",
      { jeboId, commentId, createAt },
      { headers }
    );
  },
  commentModify: (jeboId, commentId, contents, media, isMedia) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
      "Content-Type": "multipart/form-data",
    };
    let formData = new FormData();
    formData.append("jeboId", jeboId);
    formData.append("commentId", commentId);
    formData.append("contents", contents);
    media && formData.append("mediaFile", media.file);
    formData.append("isMedia", isMedia);

    return authApi.post("jebo/post/comment/modify", formData, { headers });
  },
  regInterLocation: (interLocation) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };
    return authApi.post("member/regInterLocation", interLocation, { headers });
  },
  removeInterLocation: (locationSeq) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return authApi.post(
      "member/removeInterLocation",
      { locationSeq },
      { headers }
    );
  },
  updateInterLocationToSelect: (locationSeq) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return authApi.post(
      "member/updateInterLocationSelect",
      { locationSeq },
      { headers }
    );
  },
  cancelInterLocation: (locationSeq) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return authApi.post(
      "member/cancelInterLocation",
      { locationSeq },
      { headers }
    );
  },
  myInfoModify: (nickName, interTag, description, interLocations) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return authApi.post(
      "member/mod",
      { nickName, interTag, description, interLocations },
      { headers }
    );
  },
  getMyInfo: () => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return authApi.post("member/myInfo", {}, { headers });
  },
  getMemberInfo: (memberUid) => {
    return api.post("member/memberInfo", { memberUid });
  },
  getMemberInfoNickName: (nickName) => {
    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;
    if (authorization) {
      let headers = { authorization };
      return api.post("member/memberInfoNickName", { nickName }, { headers });
    }
    return api.post("member/memberInfoNickName", { nickName });
  },
  myProfileModify: (imageFile) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
      "Content-Type": "multipart/form-data",
    };

    let formData = new FormData();
    formData.append("imageFile", imageFile);

    return authApi.post("member/modPortrait", formData, { headers });
  },
  myProfileRemove: () => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };
    return authApi.post("member/removePortrait", {}, { headers });
  },
  getRecommendSearchKeyword: () => {
    return api.post("common/getRecommendSearchKeyword");
  },
  topJeboMemberList: () => {
    return api.post("member/topJeboMember");
  },
  getDirectMessages: (communicationId, authorization) => {
    let headers = {
      authorization: AuthService.getAuthorization()
        ? AuthService.getAuthorization()
        : authorization,
    };
    let formData = new FormData();
    formData.append("communicationId", communicationId);

    return authApi.post("directMessage/list", formData, { headers });
  },
  deleteDirectMessage: (communicationId) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    let formData = new FormData();
    formData.append("communicationId", communicationId);

    return authApi.post("directMessage/deleteDirectMessage", formData, {
      headers,
    });
  },
  checkAuth: () => {
    let isValid = false;
    let authorization = AuthService.getAuthorization();
    if (authorization) {
      isValid = true;
    }
    return isValid;
  },
  getAlarmList: () => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return authApi.post("common/getAlarmList", {}, { headers });
  },
  memberBlock: (toMemberUid, nickName, reason) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return authApi.post(
      "member/regBlockMember",
      { toMemberUid, nickName, reason, btype: "00" },
      { headers }
    );
  },
  reportDm: (communicationId, toMemberUid, reportCode, reportVal) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    let formData = new FormData();
    formData.append("communicationId", communicationId);
    formData.append("toMemberUid", toMemberUid);
    formData.append("reportCode", reportCode);
    formData.append("reportReason", reportVal);

    return authApi.post("report/reportDm", formData, { headers });
  },
  reportFeed: (jeboId, reportId, reportVal) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    let formData = new FormData();
    formData.append("jeboId", jeboId);
    formData.append("reportCode", reportId);
    formData.append("reportReason", reportVal);

    return authApi.post("report/reportFeed", formData, { headers });
  },
  addKeepFeed: (jeboId) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };
    return authApi.post("jebo/keep/reg", { jeboId }, { headers });
  },
  removeKeepFeed: (jeboId) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };
    return authApi.post("jebo/keep/remove", { jeboId }, { headers });
  },
  updateHiddenFeed: (jeboId, hiddenYn) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return authApi.post(
      "jebo/hidden/update",
      { jeboId, hiddenYn },
      { headers }
    );
  },
  reportComment: (commentId, reportId, reportVal) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    let formData = new FormData();
    formData.append("commentId", commentId);
    formData.append("reportCode", reportId);
    formData.append("reportReason", reportVal);

    return api.post("report/reportComment", formData, { headers });
  },
  getRealTimeJeboList: () => {
    return api.post("jebo/post/realTimeList", {}, {});
  },
  getJeboEventList: () => {
    return api.post("event/jeboEventList", {}, {});
  },
  getLandingMessage: () => {
    return api.post("event/landingMessage", {}, {});
  },
  getTopBandBannerInfo: () => {
    return api.post("event/topBandBannerInfo", {}, {});
  },
  getPushSettings: () => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return authApi.post("member/getPushSettings", {}, { headers });
  },
  updatePushSettings: (pushSettings) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return api.post("member/setPushSettings", pushSettings, { headers });
  },
  deleteFeed: (jeboId, createAt) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return authApi.post("jebo/post/remove", { jeboId, createAt }, { headers });
  },
  signOutApp: (deviceId, fcmToken) => {
    return api.post("common/signOutApp", { deviceId, fcmToken });
  },
  getFollowCnt: (myMemberUid, memberUid) => {
    return api.post("follow/get/followCnt", { myMemberUid, memberUid });
  },
  regFollow: (toMemberUid) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return authApi.post("follow/reg", { toMemberUid }, { headers });
  },
  removeFollow: (followId) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return authApi.post("follow/remove", { followId }, { headers });
  },
  getFollower: (memberUid, myMemberUid) => {
    return api.post("follow/get/follower", { memberUid, myMemberUid });
  },
  getFollowing: (memberUid, myMemberUid) => {
    return api.post("follow/get/following", { memberUid, myMemberUid });
  },
  jeboMapPositionList: (
    northEastLatitude,
    northEastLongitude,
    southWestLatitude,
    southWestLongitude,
    searchStr
  ) => {
    console.log("call jeboMapPositionList");

    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;
    let headers = null;
    if (authorization) {
      headers = { authorization };
    }
    return api.post(
      "jebo/post/jeboMapPositionList",
      {
        northEastLatitude,
        northEastLongitude,
        southWestLatitude,
        southWestLongitude,
        searchStr: searchStr,
      },
      { headers }
    );
  },
  jeboMapPostList: (
    northEastLatitude,
    northEastLongitude,
    southWestLatitude,
    southWestLongitude,
    searchStr,
    sortType,
    endLimit,
    lastCursor
  ) => {
    console.log("aroundMapFeedList 피드조회호출");

    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;
    let headers = null;
    if (authorization) {
      headers = { authorization };
    }
    return api.post(
      "jebo/post/jeboMapPostList",
      {
        northEastLatitude,
        northEastLongitude,
        southWestLatitude,
        southWestLongitude,
        searchStr: searchStr,
        sortType,
        endLimit,
        lastCursor,
      },
      { headers }
    );
  },
  socialMapPositionList: (
    northEastLatitude,
    northEastLongitude,
    southWestLatitude,
    southWestLongitude,
    eventId,
    aroundScale
  ) => {
    console.log("socialMapPositionList 호출");
    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;
    let headers = null;
    if (authorization) {
      headers = { authorization };
    }
    return api.post(
      "social/post/socialMapPositionList",
      {
        northEastLatitude,
        northEastLongitude,
        southWestLatitude,
        southWestLongitude,
        eventId,
        aroundScale,
      },
      { headers }
    );
  },
  socialMapPostList: (
    northEastLatitude,
    northEastLongitude,
    southWestLatitude,
    southWestLongitude,
    eventId,
    sortType,
    endLimit,
    lastCursor,
    aroundScale
  ) => {
    console.log("socialMap피드목록 호출");
    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;
    let headers = null;
    if (authorization) {
      headers = { authorization };
    }
    return api.post(
      "social/post/socialMapPostList",
      {
        northEastLatitude,
        northEastLongitude,
        southWestLatitude,
        southWestLongitude,
        eventId,
        sortType,
        endLimit,
        lastCursor,
        aroundScale,
      },
      { headers }
    );
  },
  aroundMapFeedList: (
    northEastLatitude,
    northEastLongitude,
    southWestLatitude,
    southWestLongitude,
    searchStr,
    eventId,
    sortType,
    endLimit,
    lastCursor,
    aroundScale
  ) => {
    console.log("aroundMapFeedList 피드조회호출");

    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;
    let headers = null;
    if (authorization) {
      headers = { authorization };
    }
    return api.post(
      "jebo/post/aroundMapPostList",
      {
        northEastLatitude,
        northEastLongitude,
        southWestLatitude,
        southWestLongitude,
        searchStr: searchStr,
        eventId,
        sortType,
        endLimit,
        lastCursor,
        aroundScale,
      },
      { headers }
    );
  },
  resign: (memberUid, resignCode, resignValue, resignType) => {
    return api.post("member/resign", {
      memberUid,
      resignCode,
      resignValue,
      resignType,
    });
  },
  updateMemberStatus: (memberUid, marketingInfo) => {
    return api.post("member/updateMemberStatus", { memberUid, marketingInfo });
  },
  getMemberStatus: (memberUid) => {
    return api.post("member/getMemberStatus", { memberUid });
  },
  getMemberRewardList: (memberUid) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };
    return authApi.post("reward/get/memberReward", { memberUid }, { headers });
  },
  getAttendanceInfo: (
    memberUid,
    attendanceYear,
    attendanceMonth,
    weekendNo,
    attendanceType
  ) => {
    return api.post("member/getAttendanceInfo", {
      memberUid,
      attendanceYear,
      attendanceMonth,
      weekendNo,
      attendanceType,
    });
  },
  regAttendanceInfo: (
    memberUid,
    attendanceYear,
    attendanceMonth,
    weekendNo,
    checkDate,
    attendanceType
  ) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };
    let param = {
      memberUid: memberUid,
      attendanceYear: attendanceYear,
      attendanceMonth: attendanceMonth,
      weekendNo: weekendNo,
      checkDate: checkDate,
      attendanceType: attendanceType,
    };
    return authApi.post("member/regAttendanceInfo", param, { headers });
  },
  getEventName: (eventId) => {
    return authApi.post("event/getEventName", { eventId });
  },

  blockMemberList: () => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };
    return authApi.post("member/blockMemberList", {}, { headers });
  },
  removeBlockMember: (toMemberUid) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };
    return authApi.post(
      "member/removeBlockMember",
      { toMemberUid },
      { headers }
    );
  },
  getMyProfileInfo: (memberUid, getYear, getMonth) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    return authApi.post(
      "member/getMyProfileInfo",
      { memberUid, getYear, getMonth },
      { headers }
    );
  },
  noticeList: (endLimit, cursor) => {
    let params = {
      endLimit: endLimit ? endLimit : 0,
      lastCursor: cursor,
    };

    return api.post("event/getNoticeList", params);
  },
  loadNotification: () => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };
    return authApi.post("activities/info", {}, { headers });
  },
  loadMyActivity: (activity, endLimit, cursor) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    let params = {
      activityType: activity,
      endLimit: endLimit ? endLimit : 0,
      lastCursor: cursor,
    };

    return authApi.post("activities/myInfo", params, { headers });
  },
  loadMyActivityPoint: (getYear, getMonth, endLimit, cursor) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };

    let params = {
      getYear: getYear,
      getMonth: getMonth,
      endLimit: endLimit ? endLimit : 0,
      lastCursor: cursor,
    };

    return authApi.post("activities/myPoints", params, { headers });
  },

  updateReadStateNotification: () => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };
    return authApi.post("activities/updateReadState", {}, { headers });
  },
  deleteNotification: (activitiesId) => {
    let headers = {
      authorization: AuthService.getAuthorization(),
    };
    return authApi.post(
      "activities/deleteNotification",
      { activitiesId },
      { headers }
    );
  },
  registMemberCurrentLocation: (latitude, longitude) => {
    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    let headers = {
      authorization,
    };
    return authApi.post(
      "common/registMemberCurrentLocation",
      { latitude, longitude },
      { headers }
    );
  },
  // 광고 클릭 link 처리 데이터 로딩
  getAdsMappingManagerInfo: () => {
    console.log("AdsMappingManagerInfo 광고LINK 처리 데이터 조회");
    return api.post("event/AdsMappingManagerInfo");
  },

  // 편의지도관리를 위한 코드데이터 1회로딩
  getJeboPublicMapCodeData: () => {
    console.log("initCodeData 편의지도용 코드데이터 조회");
    return api.post("jebo/post/getJeboPublicMapCodeData");
  },

  // infoView에 사용 될 단일 데이터
  getJeboPublicMapInfoData: (mapId) => {
    console.log("JeboPublicMapInfoData 조회, ID: ", mapId);
    return api.post("jebo/post/getJeboPublicMapInfoData", {
      mapId,
    });
  },

  getJeboPublicMapPositionList: (
    northEastLatitude,
    northEastLongitude,
    southWestLatitude,
    southWestLongitude,
    searchStr
  ) => {
    console.log("call getJeboPublicMapPositionList");
    return api.post("jebo/post/getJeboPublicMapPositionList", {
      northEastLatitude,
      northEastLongitude,
      southWestLatitude,
      southWestLongitude,
      searchStr: searchStr,
    });
  },
  registSocialPost: (locationInfo, media, contents, hashtag, socialId) => {
    console.log("registSocialPost");

    console.log("createSocial");
    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    let headers = {
      authorization,
    };

    const formData = new FormData();
    formData.append("locationInfo", locationInfo);

    formData.append("contents", contents);
    formData.append("hashtag", hashtag);
    formData.append("socialId", socialId);

    return authApi.post("social/post/create", formData, { headers });
  },

  getSocialJoinedList: (scType) => {
    console.log("getSummaryJoinedList");

    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    const params = {
      scType,
    };

    if (authorization) {
      let headers = { authorization };
      return api.post("social/summaryJoinedList", params, { headers });
    }

    return api.post("social/summaryJoinedList", params);
  },
  summaryRankedList: (sortType) => {
    console.log("getSummaryRankedList");

    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    const parmas = {
      sortType,
    };
    if (authorization) {
      let headers = { authorization };
      return api.post("social/summaryRankedList", parmas, { headers });
    }

    return api.post("social/summaryRankedList", parmas, {});
  },
  getSocialList: (scType, sortType, cursor, keyword, joined) => {
    console.log("getSocialList");

    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    const params = {
      scType,
      sortType,
      keyword,
      joined,
      cursor: cursor && cursor.start,
      endLimit: cursor && cursor.end,
    };

    if (authorization) {
      let headers = { authorization };
      return api.post("social/list", params, { headers });
    }

    return api.post("social/list", params);
  },
  checkDuplicationSocialTitle: (scName, scType) => {
    console.log("checkDuplicationSocialTitle");
    return api.post("social/checkDuplicationSocialTitle", { scName, scType });
  },
  createSocial: (images, socialType, title, description, tags) => {
    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    let headers = {
      authorization,
      "Content-Type": "multipart/form-data",
    };

    let formData = new FormData();
    images.forEach((item) => {
      formData.append("imageFiles", item.file);
      formData.append("thumbnails", item.thumbnail);
    });
    formData.append("scType", socialType);
    formData.append("scName", title);
    formData.append("scDesc", description);
    formData.append("scTag", tags);

    return authApi.post("social/create", formData, { headers });
  },
  proposeSocial: (images, socialType, title, description, tags) => {
    console.log("proposeSocial");
    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    let headers = {
      authorization,
      "Content-Type": "multipart/form-data",
    };

    const formData = new FormData();
    images.forEach((item) => {
      formData.append("imageFiles", item.file);
      formData.append("thumbnails", item.thumbnail);
    });
    formData.append("scType", socialType);
    formData.append("scName", title);
    formData.append("scDesc", description);
    formData.append("scTag", tags);

    return authApi.post("social/propose", formData, { headers });
  },
  editSocial: async (
    images,
    socialId,
    socialType,
    title,
    description,
    tags
  ) => {
    console.log("editSocial");
    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    let headers = {
      authorization,
      "Content-Type": "multipart/form-data",
    };

    let formData = new FormData();
    for (const item of images) {
      const res = await fetch(item.url);
      const data = res.blob();
      const ext = item.url.split(".").pop();
      const ffname = item.url.split("/").pop();
      const metadata = { type: `image/${ext}` };
      const ff = new File([data], ffname, metadata);
      formData.append("imageFiles", item.file ? item.file : ff);
      formData.append("thumbnails", item.thumbnail);
    }
    formData.append("scId", socialId);
    formData.append("scType", socialType);
    formData.append("scName", title);
    formData.append("scDesc", description);
    formData.append("scTag", tags);

    return authApi.post("social/edit", formData, { headers });
  },
  getSocialAbout: (socialId) => {
    console.log("getSocialAbout");

    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    console.log("feedList 피드조회호출");
    if (authorization) {
      let headers = { authorization };
      return api.get(`social/about?scId=${socialId}`, { headers });
    }

    return api.get(`social/about?scId=${socialId}`);
  },
  getSocialRspnImages: (socialId) => {
    console.log("getSocialRspnImages");
    return api.post("social/getSocialRspnImages", { scId: socialId });
  },

  getSocialAboutContents: (socialId, cursor) => {
    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    console.log("getSocialAboutContents");
    if (authorization) {
      let headers = { authorization };
      return api.get(
        `social/about/contents?scId=${socialId}&cursor=${cursor.start}&endLimit=${cursor.end}`,
        { headers }
      );
    }

    return api.get(
      `social/about/contents?scId=${socialId}&cursor=${cursor.start}&endLimit=${cursor.end}`
    );
  },
  createSocialPost: (
    media,
    hashTags,
    contents,
    address,
    roadAddress,
    placeName,
    latitude,
    longitude,
    locationHashtags,
    eventId,
    feedType
  ) => {
    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    let headers = {
      authorization,
      "Content-Type": "multipart/form-data",
    };

    let formData = new FormData();
    media.forEach((item) => {
      formData.append("mediaFiles", item.file);
      formData.append("thumbnails", item.thumbnail);
    });

    contents && formData.append("contents", contents);
    address && formData.append("address", address);
    roadAddress && formData.append("roadAddress", roadAddress);
    placeName && formData.append("placeName", placeName);
    latitude && formData.append("latitude", latitude);
    longitude && formData.append("longitude", longitude);
    hashTags && hashTags.length > 0 && formData.append("hashTag", hashTags);
    eventId && formData.append("eventId", eventId);
    feedType && formData.append("feedType", feedType);
    console.log(">>>>>> locationHashtags:", locationHashtags);
    console.log(">>>>>> locationHashtags.length: ", locationHashtags.length);
    if (locationHashtags) {
      if (typeof locationHashtags.length === "string") {
        formData.append("locationHashtag", locationHashtags);
      } else if (
        Array.isArray(locationHashtags) &&
        locationHashtags.length > 0
      ) {
        formData.append("locationHashtag", locationHashtags.join(" "));
      }
    }

    return authApi.post("social/post/reg", formData, { headers });
  },
  closeSocial: (scId) => {
    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    let headers = {
      authorization,
    };

    return authApi.post(`social/close`, { scId }, { headers });
  },
  joinSocial: (scId) => {
    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    let headers = {
      authorization,
    };
    return authApi.post(`social/join`, { scId }, { headers });
  },
  quitSocial: (scId) => {
    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    let headers = {
      authorization,
    };

    return authApi.post(`social/quit`, { scId }, { headers });
  },
  toggleSocialLike: (scId, like) => {
    let authorization = AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null;

    let headers = {
      authorization,
    };

    return authApi.post(`social/toggleLike`, { scId, like }, { headers });
  },
  getTotalCount: (scType) => {
    return api.get(`social/getTotalCount?scType=${scType}`);
  },

  getUrlMetaIfno: (url) => {
    return api.post(`common/UrlMetaInfo`, { url });
  },
};

export default jeboApi;
