import jeboApi from "api/api";
import AuthService from "api/AuthService";
import TagManager from "react-gtm-module";
import user_icon from "assets/imgs/icons/user_icon.svg";
import "assets/styles/style.css";
import AppContextProvider from "components/pages/app/provider/AppContextProvider";
import ToastMessageBox from "components/templates/ToastMessageBox";
import { Base64 } from "js-base64";
import Route from "pages/Route";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter, useParams } from "react-router-dom";
import DirectMessageService from "socket/DirectMessageService";
import { useRef } from "react";

// const tagManagerArgs = { gtmId: "GTM-KRVJZLK" };
// TagManager.initialize(tagManagerArgs);
const kakao = window.kakao;
export default function App() {
  const mobileType = navigator.userAgent.match(/JEBOAPP_IOS/i)
    ? "ios"
    : navigator.userAgent.match(/JEBOAPP_Android/i)
    ? "android"
    : navigator.userAgent.match(/Mobile/i) && navigator.maxTouchPoints > 1
    ? "browser"
    : "others";

  const isMobile = useMediaQuery({
    query: "screen and (min-width: 0px) and (max-width: 480px)",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(
    AuthService.getAuthorization()
      ? AuthService.getAuthorization()
      : AuthService.getAutoLoginToken()
      ? AuthService.getAutoLoginToken()
      : null
  );
  const [myInfo, setMyInfo] = useState(
    AuthService.getMyInfo()
      ? JSON.parse(AuthService.getMyInfo())
      : {
          memberUid: "",
          nickName: "",
          interLocations: [],
          interTag: "",
          imageUrl: user_icon,
          description: "",
        }
  );
  const [subscribeMessage, setSubscribeMessage] = useState(null);
  const [subscribeNotification, setSubscribeNotification] = useState(null);
  const [isConnectedSocket, setIsConnectedSocket] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState(false);
  const [socket, setSocket] = useState(null);
  const [stompClient, setStompClient] = useState(null);

  const [currentLocation, setCurrentLocation] = useState(null);
  const [isCurrentLocation, setIsCurrentLocation] = useState(true);
  const [convenienceData, setConvenienceData] = useState(null);
  const [adsMapData, setAdsMapData] = useState(null);
  const [loadingAdsMapData, setLoadingAdsMapData] = useState(true);
  const hashKeywords = useMemo(() => {
    let tmpInfos = [];
    convenienceData &&
      convenienceData.forEach((info) => {
        const arrDetail = info.codeDetail.split(",");
        arrDetail.forEach((tmp) => {
          tmpInfos.push(tmp);
        });
      });
    return tmpInfos;
  }, [convenienceData]);

  const [activeLocation, setActiveLocation] = useState(null);
  const [signLayer, setSignLayer] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const activeLocationRef = useRef();
  activeLocationRef.current = activeLocation;

  /* 화면이 rendering 되고, 5초 뒤에 activeLocation 없을 때 서울특별시청으로 Setting */
  useEffect(() => {
    const forActivePosition = setTimeout(() => {
      /* setTimeout은 실행될 때의 activelocation 값을 가지고 있기 때문에(클로저 발생) activeLocation을 ref.current에 담아서 사용 */
      !activeLocationRef.current &&
        setActiveLocation({
          latitude: 37.566818796700375,
          longitude: 126.97865226036578,
          addressName: "서울 중구 태평로1가 31",
          placeName: "서울특별시청",
        });
    }, 5000);

    return () => clearTimeout(forActivePosition);
  }, []);

  useEffect(() => {
    window.checkAppVersionIOS = checkAppVersionIOS;

    try {
      if (mobileType === "ios")
        window.webkit.messageHandlers.getAppVersion.postMessage("");
      else if (mobileType === "android") {
        let andVersion = window.androidApp.getAppVersion();
        const playStoreVersion = "1.2.0";
        if (andVersion !== playStoreVersion) {
          setToastMessage("업데이트 버전이 출시되었어요.");
        }
      }
    } catch (error) {
      console.log(error);
    }
    return () => {
      window.checkAppVersionIOS = () => {};
    };
  }, [mobileType]);

  const checkAppVersionIOS = (encodedVersion) => {
    const jsonVersion = window.atob(encodedVersion);
    const { version } = JSON.parse(jsonVersion);

    const appStoreVersion = "1.2.0";
    if (version !== appStoreVersion) {
      setToastMessage("업데이트 버전이 출시되었어요.");
    }
  };

  const onClose = () => {
    setStompClient(null);
    setSocket(null);
    setIsConnectedSocket(false);
  };

  const onOpen = () => {
    setIsConnectedSocket(false);
  };

  const initSocket = useCallback(() => {
    DirectMessageService.init(setSocket, setStompClient, onOpen, onClose);
  }, []);

  const disconnectSocket = useCallback(() => {
    DirectMessageService.disconnect(socket, stompClient);
  }, [socket, stompClient]);

  useEffect(() => {
    window.initSocket = initSocket;
    window.disconnectSocket = disconnectSocket;
    return () => {
      window.initSocket = () => {};
      window.disconnectSocket = () => {};
    };
  }, [initSocket, disconnectSocket]);

  const callbackSubscribeReply = (response) => {
    setSubscribeMessage(JSON.parse(response.body));
  };
  const callbackSubscribeNotification = (response) => {
    //console.log(JSON.parse(response.body));
    setSubscribeNotification(JSON.parse(response.body));
  };

  useEffect(() => {
    if (!stompClient) {
      initSocket();
    } else {
      if (stompClient.ws.readyState === 3) {
        initSocket();
      } else if (stompClient.connected !== isConnectedSocket) {
        setIsConnectedSocket(stompClient.connected);
      } else if (!stompClient.connected || !isConnectedSocket) {
        DirectMessageService.connect(stompClient, setIsConnectedSocket);
      }
    }
  }, [stompClient, isConnectedSocket, initSocket]);

  useEffect(() => {
    let subscribeReply = null;
    let subscribeNotification = null;
    if (
      stompClient &&
      stompClient.connected &&
      isConnectedSocket &&
      myInfo.memberUid
    ) {
      subscribeReply = DirectMessageService.subscribe(
        stompClient,
        `/user/${myInfo.memberUid}/reply`,
        callbackSubscribeReply
      );
      subscribeNotification = DirectMessageService.subscribe(
        stompClient,
        `/user/${myInfo.memberUid}/notification`,
        callbackSubscribeNotification
      );
    }

    return () => {
      subscribeReply && DirectMessageService.unsubscribe(subscribeReply);
      subscribeNotification &&
        DirectMessageService.unsubscribe(subscribeNotification);
    };
  }, [stompClient, isConnectedSocket, myInfo.memberUid]);

  useEffect(() => {
    AuthService.setMyInfo(JSON.stringify(myInfo));
  }, [myInfo]);

  useEffect(() => {
    isLoggedIn &&
      isConnectedSocket &&
      myInfo &&
      myInfo.memberUid &&
      DirectMessageService.sendNotification(stompClient, myInfo.memberUid);
  }, [isLoggedIn, myInfo, stompClient, isConnectedSocket]);

  //위도,경도 정보를 통해 카카오 맵 API로 주소 정보 얻기
  const searchCurrentLocation = useCallback((latitude, longitude, current) => {
    if (!kakao) {
      setCurrentLocation({
        latitude,
        longitude,
        address: "위치 정보를 가져올 수 없습니다.",
        roadAddress: "",
        placeName: "",
      });
      if (current) {
        setActiveLocation({
          latitude,
          longitude,
          addressName: "위치 정보를 가져올 수 없습니다.",
          placeName: "",
        });
      }

      return;
    }
    const geocoder = new kakao.maps.services.Geocoder();
    const coords = new kakao.maps.LatLng(latitude, longitude);
    //좌표로 주소 검색
    geocoder.coord2Address(
      coords.getLng(),
      coords.getLat(),
      (result, status) => {
        let address = "";
        let roadAddress = "";
        let placeName = "";

        if (status === kakao.maps.services.Status.OK && result) {
          address = result[0].address.address_name;
          roadAddress =
            result[0].road_address && result[0].road_address.address_name;
          placeName =
            result[0].road_address && result[0].road_address.building_name;
        } else {
          address = "찾을 수 없는 지역입니다.";
        }

        setCurrentLocation({
          latitude,
          longitude,
          address,
          roadAddress,
          placeName,
        });

        current &&
          setActiveLocation({
            latitude,
            longitude,
            addressName: address,
            placeName,
          });
      }
    );
  }, []);

  //웹뷰 또는 브라우저로부터 좌표 정보 얻기
  const getCurrentPosition = useCallback(
    (current) => {
      setIsCurrentLocation(current);
      if (mobileType === "ios") {
        window.webkit.messageHandlers.getCurrentPosition.postMessage("");
      } else if (mobileType === "android") {
        let currCoords = window.androidApp.getGpsAddress();
        let coords = JSON.parse(currCoords);
        if (coords && coords.latitude && coords.longitude) {
          searchCurrentLocation(coords.latitude, coords.longitude, current);
        } else if ("geolocation" in navigator) {
          /* 위치정보 사용 가능 */
          navigator.geolocation.getCurrentPosition(
            (p) => {
              searchCurrentLocation(
                p.coords.latitude,
                p.coords.longitude,
                current
              );
            },
            (error) => {
              console.log("getCurrentPosition error: ", error);
              searchCurrentLocation(
                37.5546788388674,
                126.970606917394,
                current
              );
            },
            { enableHighAccuracy: true, timeout: 1000 }
          );
        } else {
          /* 위치정보 사용 불가능 */
          console.log("웹 브라우저 위치정보 사용 불가");
          searchCurrentLocation(37.5546788388674, 126.970606917394, current);
        }
      } else if ("geolocation" in navigator) {
        /* 위치정보 사용 가능 */
        navigator.geolocation.getCurrentPosition(
          (p) => {
            //console.log(p.coords);
            searchCurrentLocation(
              p.coords.latitude,
              p.coords.longitude,
              current
            );
          },
          (error) => {
            console.log(
              "getCurrentPosition error: ",
              //error.code,
              error.message
            );
            searchCurrentLocation(37.5546788388674, 126.970606917394, current);
          },
          { enableHighAccuracy: true, timeout: 1000 }
        );
      } else {
        /* 위치정보 사용 불가능 */
        console.log("웹 브라우저 위치정보 사용 불가");
        searchCurrentLocation(37.5546788388674, 126.970606917394, current);
      }
    },
    [mobileType, searchCurrentLocation]
  );

  useEffect(() => {
    if (myInfo.interLocations !== null && myInfo.interLocations.length > 0) {
      if (myInfo.interLocations.length > 0) {
        const selectedInterLocation = myInfo.interLocations.find(
          (location) => location.selectYn === "Y"
        );
        if (selectedInterLocation) {
          setActiveLocation({
            latitude: selectedInterLocation.latitude,
            longitude: selectedInterLocation.longitude,
            addressName: selectedInterLocation.addressName,
            placeName: selectedInterLocation.placeName,
          });
        } else {
          getCurrentPosition(true);
        }
      }
    } else {
      getCurrentPosition(true);
    }
  }, [myInfo]);

  //로그아웃 처리
  const handleSignOutApp = useCallback(() => {
    if (mobileType === "android") {
      try {
        let returnString = window.androidApp.getDeviceTokenInfo();

        let deviceId = returnString.split("@@")[0];
        let fcmToken = returnString.split("@@")[1];

        jeboApi.signOutApp(deviceId, fcmToken);
      } catch (error) {
        console.log(error);
      }
    } else if (mobileType === "ios") {
      let params = JSON.stringify({
        loginType: AuthService.getLoginType() ? AuthService.getLoginType() : "",
      });
      window.webkit.messageHandlers.logout.postMessage(Base64.encode(params));
    }

    const autoLogintoken = AuthService.getAutoLoginToken();
    autoLogintoken && AuthService.removeKeepId();
    AuthService.removeAuth();
    AuthService.removeAutoLoginToken();
    AuthService.removeMyInfo();

    setMyInfo({
      memberUid: "",
      nickName: "",
      imageUrl: user_icon,
      interLocations: [],
      interTag: "",
      description: "",
      error: "",
    });
    setIsLoggedIn(null);
  }, [mobileType]);

  const registMemberCurrentLocation = useCallback(
    (latitude, longitude) => {
      try {
        jeboApi
          .registMemberCurrentLocation(latitude, longitude)
          .then(
            ({ status }) => {
              if (status === 200) console.log("성공");
            },
            (reason) => {
              console.log(reason);
            }
          )
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
              if (error.response.status === 403) {
                handleSignOutApp();
              }
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    [handleSignOutApp]
  );

  const registMemberCurrentLocationIOS = useCallback(
    (encodedCoord) => {
      const jsonCoord = window.atob(encodedCoord);
      const coord = JSON.parse(jsonCoord);

      const latitude = coord ? coord.latitude : null;
      const longitude = coord ? coord.longitude : null;

      if (latitude && longitude) {
        isLoggedIn && registMemberCurrentLocation(latitude, longitude);
      }
    },
    [isLoggedIn, registMemberCurrentLocation]
  );

  //ios 1.1.0 버전 이상
  const setCurrentCoordsIOS = useCallback(
    (encodedCoord) => {
      const jsonCoord = window.atob(encodedCoord);
      const coord = JSON.parse(jsonCoord);

      const latitude = coord ? coord.latitude : null;
      const longitude = coord ? coord.longitude : null;

      if (latitude && longitude) {
        searchCurrentLocation(latitude, longitude, isCurrentLocation);
        isLoggedIn && registMemberCurrentLocation(latitude, longitude);
      } else {
        searchCurrentLocation(
          37.5546788388674,
          126.970606917394,
          isCurrentLocation
        );
      }
    },
    [
      searchCurrentLocation,
      isCurrentLocation,
      isLoggedIn,
      registMemberCurrentLocation,
    ]
  );

  // android
  const setCurrentCoordinateIOS = useCallback(
    (lat, lng) => {
      let latitude;
      let longitude;
      if (lng) {
        latitude = lat;
        longitude = lng;
      } else {
        const encodedCoord = lat;
        const jsonCoord = window.atob(encodedCoord);
        const coord = JSON.parse(jsonCoord);

        latitude = coord ? coord.latitude : null;
        longitude = coord ? coord.longitude : null;
      }

      if (latitude && longitude) {
        //        searchCurrentLocation(latitude, longitude, isCurrentLocation);
        isLoggedIn && registMemberCurrentLocation(latitude, longitude);
      } else {
        // searchCurrentLocation(
        //   37.5546788388674,
        //   126.970606917394,
        //   isCurrentLocation
        // );
      }
    },
    [isLoggedIn, registMemberCurrentLocation]
  );

  useEffect(() => {
    window.setCurrentCoordinateIOS = setCurrentCoordinateIOS;
    window.setCurrentCoordsIOS = setCurrentCoordsIOS; //신규버전 ios.
    window.registMemberCurrentLocationIOS = registMemberCurrentLocationIOS;
    return () => {
      window.setCurrentCoordinateIOS = () => {};
      window.setCurrentCoordsIOS = () => {}; //신규버전 ios.
      window.registMemberCurrentLocationIOS = () => {};
    };
  }, [
    setCurrentCoordinateIOS,
    setCurrentCoordsIOS,
    registMemberCurrentLocationIOS,
  ]);

  useEffect(() => {
    !activeLocation &&
      currentLocation &&
      setActiveLocation({
        latitude: currentLocation.latitude,
        longitude: currentLocation.longitude,
        addressName: currentLocation.address,
        placeName: currentLocation.placeName,
      });
  }, [currentLocation, activeLocation]);

  const signOutAppIOS = useCallback((encodedDeviceInfo) => {
    let jsonDeviceInfo = Base64.decode(encodedDeviceInfo);
    let deviceInfo = JSON.parse(jsonDeviceInfo);

    try {
      jeboApi.signOutApp(
        deviceInfo && deviceInfo.deviceId ? deviceInfo.deviceId : "",
        deviceInfo && deviceInfo.fcmToken ? deviceInfo.fcmToken : ""
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    async function initMappingData() {
      const { status, data } = await jeboApi.getAdsMappingManagerInfo();

      try {
        if (status === 200) {
          const dataResult = JSON.parse(data.adsManagerInfoList);
          setAdsMapData(dataResult && dataResult.length > 0 ? dataResult : []);
        } else {
          setAdsMapData([]);
        }
      } catch (error) {
        setAdsMapData([]);
      } finally {
        setLoadingAdsMapData(false);
      }
    }
    initMappingData();
  }, []);

  useEffect(() => {
    async function initCodeData() {
      try {
        const { status, data } = await jeboApi.getJeboPublicMapCodeData();
        if (status === 200) {
          const dataResult = JSON.parse(data.infoData);
          setConvenienceData(
            dataResult && dataResult.length > 0 ? dataResult : []
          );
        } else {
          setConvenienceData([]);
        }
      } catch (error) {
        console.log(error);
      }
    }
    initCodeData();
  }, []);

  useEffect(() => {
    window.signOutAppIOS = signOutAppIOS;

    return () => {
      window.signOutAppIOS = () => {};
    };
  }, [signOutAppIOS]);

  const getAlarmList = useCallback(async () => {
    try {
      const {
        data: { alarmList },
      } = await jeboApi.getAlarmList();

      if (alarmList) {
        const resultAlarmList = JSON.parse(alarmList);
        setIsNewMessage(!resultAlarmList[0].noReadMessage);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          console.log("세션 만료");
          handleSignOutApp();
        }
      } else console.error(error);
    }
  }, []);

  useEffect(() => {
    isLoggedIn && getAlarmList();
  }, [subscribeMessage, isLoggedIn, getAlarmList]);

  //로그인 처리
  const handleSignInApp = useCallback(
    async (
      authorization,
      refreshToken,
      email,
      nickName,
      grantType,
      memberUid,
      socialYn,
      signInType
    ) => {
      AuthService.setAuth(
        authorization,
        refreshToken,
        email,
        nickName,
        grantType,
        memberUid,
        socialYn,
        signInType
      );
      try {
        const {
          status,
          data: { myInfo },
        } = await jeboApi.getMyInfo();

        if (status === 200) {
          const { nickName, imageUrl, interLocations, interTag, description } =
            JSON.parse(myInfo);
          setMyInfo({
            memberUid,
            nickName,
            imageUrl: imageUrl
              ? process.env.REACT_APP_RESOURCE_HOST + imageUrl
              : user_icon,
            interLocations,
            interTag,
            description,
          });
          setIsLoggedIn(authorization);
        }
      } catch (error) {
        console.log(error);
        handleSignOutApp();
      }
    },
    [handleSignOutApp]
  );

  //자동로그인 처리
  const autoLoginApp = useCallback(
    async (deviceId, fcmToken) => {
      try {
        const { headers } = await jeboApi.autoSignIn(
          AuthService.getAutoLoginToken(),
          deviceId,
          fcmToken,
          mobileType
        );

        handleSignInApp(
          headers.authorization,
          headers.refresh_token,
          headers.member_email,
          Base64.decode(headers.member_nickname),
          headers.grant_type,
          headers.member_uid,
          headers.social_yn,
          AuthService.getLoginType() ? AuthService.getLoginType() : ""
        );
      } catch (error) {
        if (error.response) {
          const {
            response: { data, status },
          } = error;
          console.log(status);
          if (status === 404) console.log(data.errorDescription);
          else if (status === 403) console.log(data.errorDescription);
          else if (status === 500) console.log(data.errorDescription);

          handleSignOutApp();
        }
      }
    },
    [handleSignInApp, handleSignOutApp, mobileType]
  );

  const handleAutoLoginIOS = useCallback(
    (encodedDeviceInfo) => {
      const jsonDeviceInfo = Base64.decode(encodedDeviceInfo);
      const deviceInfo = JSON.parse(jsonDeviceInfo);

      const deviceId = deviceInfo.deviceId ? deviceInfo.deviceId : "";
      const fcmToken = deviceInfo.fcmToken ? deviceInfo.fcmToken : "";

      autoLoginApp(deviceId, fcmToken);
    },
    [autoLoginApp]
  );

  useEffect(() => {
    window.autoLoginIOS = handleAutoLoginIOS;
    return () => {
      window.autoLoginIOS = () => {};
    };
  }, [handleAutoLoginIOS]);

  const handleAutoLoginAndroid = useCallback(() => {
    const returnString = window.androidApp.getDeviceTokenInfo();

    const deviceId = returnString.split("@@")[0];
    const fcmToken = returnString.split("@@")[1];

    autoLoginApp(deviceId, fcmToken);
  }, [autoLoginApp]);

  useEffect(() => {
    if (AuthService.getAutoLoginToken()) {
      if (mobileType === "ios") {
        window.webkit.messageHandlers.autoLogin.postMessage("");
      } else if (mobileType === "android") handleAutoLoginAndroid();
      else autoLoginApp();
    }
  }, [mobileType, autoLoginApp, handleAutoLoginAndroid]);

  return (
    <AppContextProvider
      isMobile={{ isMobile, mobileType }}
      signApp={{
        isLoggedIn,
        myInfo,
        setMyInfo,
        handleSignInApp,
        handleSignOutApp,
      }}
      signLayer={{ signLayer, setSignLayer }}
      subscribeMessage={{
        subscribeMessage,
        setSubscribeMessage,
        subscribeNotification,
        setSubscribeNotification,
        isNewMessage,
        isConnectedSocket,
        setIsConnectedSocket,
        stompClient,
      }}
      currentLocation={{ currentLocation, getCurrentPosition }}
      activeLocation={{ activeLocation, setActiveLocation }}
      convenienceData={{ convenienceData, setConvenienceData, hashKeywords }}
      adsmapData={{
        adsMapData,
        setAdsMapData,
        loadingAdsMapData,
        setLoadingAdsMapData,
      }}
    >
      <BrowserRouter>
        <Route />
      </BrowserRouter>

      {toastMessage && (
        <ToastMessageBox
          toastMessage={toastMessage}
          setToastMessage={setToastMessage}
        />
      )}
    </AppContextProvider>
  );
}
