import jeboApi from "api/api";
import Layers from "components/layers/Layers";
import { signAppContext } from "components/providers/SignContextProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import MobileMyHistoryList from "./mobile/MobileMyHistoryList";

export default function MyAttendContainer() {
  const END_LIMIT = 9;

  const { handleSignOutApp, setSignLayer } = useContext(signAppContext);

  const [lastFeedRef, inView] = useInView({ threshold: 0 });
  const [cursor, setCursor] = useState(0);
  const [notLoadedData, setNotLoadedData] = useState(false);

  const [myAttends, setMyAttends] = useState([]);

  const [tabType, setTabType] = useState("CH"); //CP - 캠페인, TM - 테마, CH - 채널, CM - 커뮤니티

  const [reloadSwitch, setReloadSwitch] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const history = useHistory();

  const loadMyAttend = useCallback(async () => {
    try {
      // setLoading(true);
      const { status, data } = await jeboApi.loadMyActivity(
        tabType,
        END_LIMIT,
        cursor
      );
      if (status === 200) {
        const activities = JSON.parse(data.activities);

        setNotLoadedData(activities.length < END_LIMIT ? true : false);
        if (cursor === 0) setMyAttends(activities);
        else setMyAttends((prev) => prev.concat(activities));
      } else if (status === 204) {
        setNotLoadedData(true);
        console.log("no data to be loaded..");
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    } finally {
      setLoading(false);
    }
  }, [cursor, reloadSwitch, history, handleSignOutApp, setSignLayer]);

  useEffect(() => {
    loadMyAttend();
  }, [loadMyAttend]);

  useEffect(() => {
    if (inView && !error && !notLoadedData) {
      setCursor((prev) => prev + END_LIMIT);
    }
  }, [inView]);

  useEffect(() => {
    setMyAttends([]);
    setReloadSwitch((prev) => !prev);
    setCursor(0);
  }, [tabType]);

  return (
    <Container>
      <Header>
        <Title>나의 활동</Title>
      </Header>
      <Body>
        <BodyContainer>
          <MenuSection>
            <TabName>
              <TabBtn
                onClick={(e) => setTabType("CH")}
                active={tabType === "CH" ? true : false}
              >
                채널
              </TabBtn>
            </TabName>
            <TabName>
              <TabBtn
                onClick={(e) => setTabType("CM")}
                active={tabType === "CM" ? true : false}
              >
                커뮤니티
              </TabBtn>
            </TabName>
            <TabName>
              <TabBtn
                onClick={(e) => {
                  e.preventDefault();
                  setTabType("CP");
                }}
                active={tabType === "CP" ? true : false}
              >
                캠페인
              </TabBtn>
            </TabName>
            <TabName>
              <TabBtn
                onClick={(e) => {
                  e.preventDefault();
                  setTabType("TM");
                }}
                active={tabType === "TM" ? true : false}
              >
                테마
              </TabBtn>
            </TabName>
          </MenuSection>
          <ContentsSection>
            {tabType &&
              (myAttends.length > 0 ? (
                myAttends.map((attend, index) => {
                  return (
                    <MobileMyHistoryList
                      key={index}
                      actInfo={attend}
                      majorType={tabType}
                      subType=""
                    />
                  );
                })
              ) : (
                <NoData>
                  {tabType
                    ? (tabType === "CP"
                        ? "캠페인"
                        : tabType === "TM"
                        ? "테마"
                        : tabType === "CH"
                        ? "채널"
                        : tabType === "CM"
                        ? "커뮤니티"
                        : "") + " 참여 이력이 없습니다."
                    : "잘못된 접근 입니다."}
                </NoData>
              ))}
            {myAttends.length > 0 && <div ref={lastFeedRef}></div>}
          </ContentsSection>
        </BodyContainer>
      </Body>
      <Layers />
    </Container>
  );
}

//styled-component 정의
const Container = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  padding: 40px;
  overflow-y: auto;

  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`;

const Header = styled.div`
  margin-bottom: 40px;
`;

const Title = styled.strong`
  color: #000;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.04em;
`;

const Body = styled.div`
  height: calc(100% - 50px);
`;

const BodyContainer = styled.div`
  padding-bottom: 50px;
  width: 100%;
  height: 100%;
`;

const MenuSection = styled.div`
  width: auto;
  display: flex;
  margin-bottom: 15px;
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  height: 35px;
  /* border-bottom: 1px solid #dcdcdc; */
`;

const ContentsSection = styled.div`
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`;

const TabName = styled.div`
  display: grid;
  align-items: center;
  background-color: #fff;
  padding-left: 12px;
  padding-right: 12px;
  height: 100%;
  width: 50%;
  margin-bottom: 0;
  text-align: center;
  /* border: 1px solid #dcdcdc; */
  font-weight: ${(props) => (props.active ? "bold" : "")};
  border-right: ${(props) => (props.right ? "1px solid #dcdcdc" : "")};
`;

const TabBtn = styled.button`
  font-size: 15px;
  background: ${(props) =>
    props.active
      ? `linear-gradient(
    316.2deg,
    #3773ff -3.34%,
    #22e6f3 131.85%
  )`
      : "#fff"};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  border-radius: 13px;
  border: 1px solid #dcdcdc;
  width: 100%;
  height: 80%;
  margin: auto;

  font-weight: ${(props) => (props.actve ? "700 !important" : "400")};
`;

const NoData = styled.div`
  width: 100vw;
  height: 100vh;
  text-align: center;
  padding-top: 50%;
`;
