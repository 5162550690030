import jeboApi from "api/api";
import { feedsContext } from "components/providers/FeedContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { useContext } from "react";

export default function CommentDeleteConfirmLayer({
  setFeed,
  comment,
  dispatch,
}) {
  const { isLoggedIn, handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const { feeds, setFeeds } = useContext(feedsContext);

  const deleteComment = async () => {
    if (!isLoggedIn) {
      setSignLayer("signIn");
      return;
    }

    try {
      const {
        status,
        data: { comments, commentsCnt },
      } = await jeboApi.commentRemove(
        comment.jeboId,
        comment.commentId,
        comment.createAt
      );
      if (status === 200) {
        const updateComments = JSON.parse(comments);
        setFeeds(
          feeds.map((feed) => {
            if (comment.jeboId === feed.jeboId) {
              return Object.assign({}, feed, { commentCnt: commentsCnt });
            }
            return feed;
          })
        );
        setFeed((prev) => {
          return Object.assign({}, prev, { jeboCommentList: updateComments });
        });
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
        }
      }
    } finally {
      dispatch({ type: "INIT" });
    }
  };

  return (
    <div className="pop-menu item-pop delete active">
      <div className="pop-header">
        <strong>해당 댓글을 삭제하시겠습니까?</strong>
      </div>
      <div className="pop-body">
        <span className="txt">삭제 시 복구할 수 없습니다.</span>
        <div className="btns">
          <button
            type="button"
            className="cancle"
            onClick={(e) => {
              dispatch({ type: "INIT" });
            }}
          >
            취소
          </button>
          <button
            type="button"
            className="delete"
            onClick={(e) => deleteComment()}
          >
            삭제
          </button>
        </div>
      </div>
    </div>
  );
}
