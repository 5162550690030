import jeboApi from "api/api";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { feedsContext } from "components/providers/FeedContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

export default function CommentAddFeatLayer({ setFeed, comment, dispatch }) {
  const { isLoggedIn, handleSignOutApp, myInfo } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const { feeds, setFeeds } = useContext(feedsContext);
  const { isMobile } = useContext(isMobileContext);

  const history = useHistory();

  return (
    <div className="pop-menu item-menu active comment feedCommentOption">
      {myInfo.memberUid === comment.memberUid ? (
        <ul>
          <li>
            <button
              className="comment-add"
              onClick={(e) => {
                dispatch({
                  type: "MODE",
                  id: comment.commentId,
                  name: "UPDATE",
                });
                e.target.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }}
            >
              수정
            </button>
          </li>
          <li>
            <button
              className="menu"
              onClick={(e) =>
                dispatch({
                  type: "MODE",
                  id: comment.commentId,
                  name: "DELETE",
                })
              }
            >
              삭제
            </button>
          </li>
          <li>
            <button
              className="menu mobile"
              onClick={(e) => dispatch({ type: "INIT" })}
            >
              취소
            </button>
          </li>
        </ul>
      ) : (
        <ul>
          <li>
            <button
              className="menu"
              onClick={(e) => {
                if (!isLoggedIn) {
                  setSignLayer("signIn");
                  return;
                }
                sessionStorage.setItem("dmTargetMemberUid", comment.memberUid);
                sessionStorage.setItem("dmTargetNickName", comment.nickName);

                isMobile
                  ? history.push({
                      pathname: "/directMessage",
                      search: `?memberUid=${comment.memberUid}&nickName=${comment.nickName}`,
                    })
                  : window.open(
                      `/directMessage?memberUid=${comment.memberUid}&nickName=${comment.nickName}`,
                      "_blank"
                    );
              }}
            >
              1:1대화
            </button>
          </li>
          <li>
            <button
              className="menu"
              onClick={(e) => {
                if (!isLoggedIn) {
                  setSignLayer("signIn");
                  return;
                }
                dispatch({
                  type: "MODE",
                  id: comment.commentId,
                  name: "BLOCK",
                });
              }}
            >
              차단
            </button>
          </li>
          <li>
            <button
              className="menu"
              onClick={(e) => {
                if (!isLoggedIn) {
                  setSignLayer("signIn");
                  return;
                }
                dispatch({
                  type: "MODE",
                  id: comment.commentId,
                  name: "REPORT",
                });
              }}
            >
              신고
            </button>
          </li>
          <li>
            <button
              className="menu mobile"
              onClick={(e) => dispatch({ type: "INIT" })}
            >
              취소
            </button>
          </li>
        </ul>
      )}
    </div>
  );
}
