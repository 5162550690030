import jeboApi from "api/api";
import heart from "assets/imgs/icons/icon_favRed.svg";
import emptyHeart from "assets/imgs/icons/icon_favRedBorder.svg";
import admin_icon from "assets/imgs/jebo_admin_profile.png";
import MobileFeedListLayer from "components/layers/detail/MobileFeedListLayer";
import FeedContextProvider from "components/providers/FeedContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player";
import { Route, useHistory, useParams, useRouteMatch } from "react-router-dom";
import styled, { css } from "styled-components";
import DetailNavButton from "./DetailNavButton";
import MapContent from "./MapContent";
import SocialCompletePop from "./SocialCompletePop";
import SocialConfirmPop from "./SocialConfirmPop";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import FeedDetailLayer from "components/layers/detail/FeedDetailLayer";

const contentReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_DATA":
      return {
        ...state,
        datas: [...state.datas, ...action.datas],
        loading: false,
      };
    case "UPDATE_DATA":
      return {
        ...state,
        datas: [...action.datas],
        loading: false,
      };
    case "CHANGE_CURSOR":
      return {
        ...state,
        cursor: { start: state.cursor.start + END_LIMIT, end: END_LIMIT },
      };
    case "STORE_SCROLL_POSITION":
      return {
        ...state,
        scrollPosition: action.scrollPosition,
      };
    default:
      return state;
  }
};

const END_LIMIT = 10;
export default function ContentDetail({
  category,
  updateSocial,
  removeSocial,
}) {
  const { isMobile } = useContext(isMobileContext);
  const { isLoggedIn } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);

  const history = useHistory();
  const { socialId } = useParams();
  const match = useRouteMatch();

  const [mapFeeds, setMapFeeds] = useState([]);

  const [joinConfirm, setJoinConfirm] = useState(false);
  const [quitConfirm, setQuitConfirm] = useState(false);
  const [contentType, setContentType] = useState("LOOKUP");
  const [social, setSocial] = useState(null);
  const [lastContentRef, inView] = useInView();

  const [confirmCompleteLayer, setConfirmCompleteLayer] = useState(false);
  const [confirmType, setConfirmType] = useState("0");
  const confirmMessage = {
    0: "폐쇄된 채널입니다.",
    1: "폐쇄된 커뮤니티입니다.",
    2: "폐쇄된 테마입니다.",
    3: "폐쇄된 캠페인입니다.",
    4: category.name === "channel" ? "구독했습니다." : "가입했습니다.",
    5: category.name === "channel" ? "구독을 취소했습니다." : "탈퇴했습니다",
  };

  const [contentState, dispatchContent] = useReducer(contentReducer, {
    datas: [],
    cursor: { start: 0, end: END_LIMIT },
    loading: true,
    totalCount: 0,
    scrollPosition: 0,
  });
  const {
    datas: feedContents,
    totalCount,
    cursor,
    loading,
    scrollPosition,
  } = contentState;

  const [feeds, setFeeds] = useState(feedContents);

  // 탭 클릭시 스크롤 이동을 위한 useRef 계산
  const [mapTabPosition, setMapTabPosition] = useState(0);
  const mapDetail = useRef();
  const mapDetail2 = useRef();

  useEffect(() => {
    setMapTabPosition(
      window.innerWidth <= 480
        ? mapDetail.current.scrollHeight + mapDetail2.current.clientHeight - 175
        : mapDetail.current.scrollHeight + mapDetail2.current.clientHeight - 15
    );
  }, [social]);

  useEffect(() => {
    dispatchContent({ type: "UPDATE_DATA", datas: feeds });
  }, [feeds]);

  useEffect(() => {
    window.scrollTo(0, scrollPosition);
  }, [category, scrollPosition]);

  useEffect(() => {
    return () => {
      dispatchContent({
        type: "STORE_SCROLL_POSITION",
        scrollPosition: window.scrollY,
      });
    };
  }, [dispatchContent]);

  const loadSocialDetail = useCallback(async () => {
    try {
      const {
        status,
        data: { socialData },
      } = await jeboApi.getSocialAbout(socialId);

      if (status === 200) {
        const {
          scId: socialId,
          scName: title,
          scDesc: description,
          scTag: hashtags,
          scType: socialType,
          scMemberUid,
          scStatus,
          imageName,
          likeCount,
          memberCount,
          participated,
          roleName,
          like,
          scNickName,
          scMemberImageUrl,
          endStatus,
        } = JSON.parse(socialData);

        setSocial({
          socialId,
          title,
          description,
          hashtags: hashtags.split(" "),
          socialType,
          scMemberUid,
          scStatus,
          likeCount,
          memberCount,
          participated,
          imageUrl: "/media/social/" + imageName,
          roleName,
          like,
          scNickName,
          scMemberImageUrl,
          endStatus,
        });
      }
    } catch (error) {
      const { status } = error;
      console.log(error, status);
      if (category.name === "channel") {
        setConfirmType("0");
        setConfirmCompleteLayer(true);
        // alert("폐쇄된 채널입니다.");
      }
      if (category.name === "community") {
        setConfirmType("1");
        setConfirmCompleteLayer(true);
        // alert("폐쇄된 커뮤니티입니다.");
      }
      if (category.name === "theme") {
        setConfirmType("2");
        setConfirmCompleteLayer(true);
        // alert("폐쇄된 테마입니다.");
      }
      if (category.name === "campaign") {
        setConfirmType("3");
        setConfirmCompleteLayer(true);
        // alert("폐쇄된 캠페인입니다.");
      }
      removeSocial(socialId);
      history.goBack();
    }
  }, [socialId]);

  useEffect(() => {
    loadSocialDetail();
  }, [isLoggedIn, loadSocialDetail]);

  const fetchSocialContents = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.getSocialAboutContents(
        socialId,
        cursor
      );
      if (status === 200) {
        const contents = JSON.parse(data.resultData);
        setFeeds((prev) => [...prev, ...contents]);
      } else if (status === 204) {
      }
    } catch (error) {
      const { status } = error;
      console.log(error, status);
    }
  }, [socialId, cursor]);
  useEffect(() => {
    fetchSocialContents();
  }, [fetchSocialContents]);

  useEffect(() => {
    inView && dispatchContent({ type: "CHANGE_CURSOR" });
  }, [inView]);

  const handleJoin = async () => {
    try {
      const { status } = await jeboApi.joinSocial(social.socialId);

      if (status === 200) {
        updateSocial({
          scId: social.socialId,
          memberCount: social.memberCount + 1,
          roleName: "USER",
        });
        setSocial((prev) => ({ ...prev, roleName: "USER" }));
        setConfirmType(4);
        setConfirmCompleteLayer(true);
        // alert(category.name === "channel" ? "구독했습니다." : "가입했습니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleQuit = async () => {
    try {
      const { status } = await jeboApi.quitSocial(social.socialId);
      if (status === 200) {
        social.roleName !== "ANONYMOUS" && removeSocial(social.socialId);
        updateSocial({
          scId: social.socialId,
          memberCount: social.memberCount - 1,
          roleName: "ANONYMOUS",
        });
        setSocial((prev) => ({ ...prev, roleName: "ANONYMOUS" }));
        setConfirmType(5);
        setConfirmCompleteLayer(true);
        // alert(
        //   category.name === "channel" ? "구독을 취소했습니다." : "탈퇴했습니다"
        // );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleLike = async () => {
    if (!isLoggedIn) {
      setSignLayer("signIn");
      return;
    }

    try {
      const { status } = await jeboApi.toggleSocialLike(
        social.socialId,
        !social.like
      );

      if (status === 200) {
        console.log(!social.like);
        updateSocial({
          scId: social.socialId,
          like: !social.like,
          likeCount: !social.like
            ? social.likeCount + 1
            : social.likeCount > 0
            ? social.likeCount - 1
            : social.likeCount,
        });
        setSocial((prev) => ({ ...prev, like: !prev.like }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {social && social.scStatus === "10" ? (
        <CloseSocial>폐쇄된 {category}입니다.</CloseSocial>
      ) : (
        <>
          <IntroSection ref={mapDetail}>
            <ImageWrapper>
              <img
                src={social && process.env.REACT_APP_API_HOST + social.imageUrl}
                alt="대표이미지"
              ></img>
              <IntroHeaderWrapper />
              <IntroContentSection />
              <TitleWrapper>
                <Title>{social && social.title}</Title>
              </TitleWrapper>
              <UserPic>
                <img
                  src={
                    social && social.scMemberUid === "ADMIN"
                      ? admin_icon
                      : social?.scMemberImageUrl
                      ? process.env.REACT_APP_API_HOST + social.scMemberImageUrl
                      : "/static/media/user_icon.fcd9ee8a.svg"
                  }
                  alt="유저이미지"
                ></img>
              </UserPic>
              <DetailNavButton
                text="목록"
                type="list"
                onClick={() => {
                  // history.goBack();
                  history.replace({ pathname: `/social/${category.name}` });
                  console.log(category.name);
                }}
              />
            </ImageWrapper>
            <DescriptionWrapper>
              <Description>{social && social.description}</Description>
            </DescriptionWrapper>
            <HashtagWrapper>
              {social &&
                social.hashtags &&
                social.hashtags.map((hashtag, index) => (
                  <Hashtag key={index}>{hashtag}</Hashtag>
                ))}
            </HashtagWrapper>
          </IntroSection>
          <DetailContainer>
            <NavSection ref={mapDetail2}>
              <LikeButton
                onClick={(e) => {
                  e.preventDefault();
                  toggleLike();
                }}
              >
                <HeartBox>
                  <img
                    src={social && social.like ? heart : emptyHeart}
                    alt=""
                  />
                </HeartBox>
              </LikeButton>

              {category && social && (
                <>
                  {/* 테마/캠페인인 경우 로그인한 사용자 모두 참여가능, 채널/커뮤니티인 경우, 로그인하고 해당 채널/커뮤니티에 가입된 사용자만 참여 가능
                   */}
                  {((category.name === "channel" &&
                    social.roleName === "ADMIN") ||
                    (category.name === "community" &&
                      social.roleName !== "ANONYMOUS") ||
                    (!category.joinable && social.endStatus === 0)) && (
                    <DetailNavButton
                      type="post"
                      text={category.name === "channel" ? "게시" : "참여"}
                      onClick={() => {
                        history.push({
                          pathname: `${match.url}/createPost`,
                          state: { social },
                        });
                      }}
                    />
                  )}

                  {/* 채널/커뮤니티인 경우 가입하지 않은 사용자에게는 가입 버튼 활성화, 이미 가입한 사용자에게는 탈퇴 버튼 활성화 */}
                  {category.joinable &&
                    (social.roleName === "ANONYMOUS" ? (
                      <DetailNavButton
                        type="join"
                        text={category.name === "channel" ? "구독" : "가입"}
                        onClick={() => {
                          isLoggedIn
                            ? setJoinConfirm(true)
                            : setSignLayer("signIn");
                        }}
                      />
                    ) : (
                      social.roleName === "USER" && (
                        <DetailNavButton
                          type="quit"
                          text={
                            category.name === "channel" ? "구독취소" : "탈퇴"
                          }
                          onClick={() => {
                            isLoggedIn
                              ? setQuitConfirm(true)
                              : setSignLayer("signIn");
                          }}
                        />
                      )
                    ))}

                  {/* 개설자인 경우 수정 및 폐쇄 버튼 활성화 */}
                  {social.roleName === "ADMIN" && (
                    <>
                      <DetailNavButton
                        type="modify"
                        text="수정"
                        onClick={() => {
                          history.push({
                            pathname: `${match.url}/modify`,
                            state: { social },
                          });
                        }}
                      />
                      <DetailNavButton
                        type="close"
                        text="폐쇄"
                        onClick={() => {
                          history.push({
                            pathname: `${match.url}/close`,
                            state: { social },
                          });
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </NavSection>

            {joinConfirm && (
              <SocialConfirmPop
                title={social.title}
                message={
                  category.name === "channel"
                    ? "구독하시겠습니까?"
                    : "가입하시겠습니까?"
                }
                onClick={() => {
                  setJoinConfirm(false);
                  handleJoin();
                }}
                onCancel={() => {
                  setJoinConfirm(false);
                }}
              />
            )}

            {quitConfirm && (
              <SocialConfirmPop
                title={social.title}
                message={
                  category.name === "channel"
                    ? "구독을 취소하시겠습니까?"
                    : "탈퇴하시겠습니까?"
                }
                onClick={() => {
                  setQuitConfirm(false);
                  handleQuit();
                }}
                onCancel={() => {
                  setQuitConfirm(false);
                }}
              />
            )}

            {confirmCompleteLayer && (
              <SocialCompletePop
                title={social.title}
                category={category}
                message={confirmMessage[confirmType]}
                onClick={() => {
                  setConfirmCompleteLayer(false);
                }}
              />
            )}

            <ContentSection>
              <DetailTabContainer>
                <DetailTabBtn
                  className={contentType === "LOOKUP" ? "active" : ""}
                  onClick={(e) => {
                    window.scrollTo(0, 0);
                    setContentType("LOOKUP");
                  }}
                >
                  현황
                </DetailTabBtn>
                <DetailTabBtn
                  className={contentType === "MAP" ? "active" : ""}
                  onClick={(e) => {
                    setContentType("MAP");
                  }}
                >
                  지도
                </DetailTabBtn>
              </DetailTabContainer>
              {contentType === "LOOKUP" && (
                <>
                  {(!feedContents || feedContents.length === 0) &&
                    (category.name === "channel" ? (
                      <EmptyContents>아직 게시글이 없어요.</EmptyContents>
                    ) : (
                      <EmptyContents>
                        아직 참여한 게시물이 없어요. <br />
                        {category.title}의 첫 게시글을 남겨 보세요.
                      </EmptyContents>
                    ))}
                  <GridContainer>
                    {feedContents &&
                      feedContents.length > 0 &&
                      feedContents.map((content) => (
                        <GridContent
                          key={content.jeboId}
                          content={content}
                          onClick={(e) => {
                            e.stopPropagation();
                            isMobile
                              ? history.push({
                                  pathname: `${match.url}/list`,
                                  state: { jeboId: content.jeboId },
                                })
                              : history.push({
                                  pathname: `${match.url}/detail/${content.jeboId}`,
                                });
                          }}
                        >
                          {content.jeboMediaList &&
                          content.jeboMediaList.length > 0 ? (
                            content.jeboMediaList[0].mediaType === "image" ? (
                              <LazyLoadImage
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover", //"contain",
                                  margin: "auto",
                                  backgroundColor: "#f8f8f8",
                                }}
                                src={
                                  process.env.REACT_APP_RESOURCE_HOST +
                                  content.jeboMediaList[0].mediaUrl
                                }
                                alt=""
                              />
                            ) : (
                              <ReactPlayer
                                className="react-player"
                                controls={false}
                                controlsList="nodownload"
                                loop={false}
                                muted={true}
                                autoPlay={false}
                                playsInline={true}
                                type="video/*"
                                url={`${
                                  process.env.REACT_APP_RESOURCE_HOST +
                                  content.jeboMediaList[0].mediaUrl
                                }#t=0.5`}
                                width="100%"
                                height="100%"
                                style={{
                                  objectFit: "cover", //"contain",
                                  margin: "auto",
                                  backgroundColor: "#f8f8f8",
                                }}
                              />
                            )
                          ) : (
                            <p>{content.contents}</p>
                          )}
                        </GridContent>
                      ))}
                    {feedContents && feedContents.length > 0 && !loading && (
                      <div ref={lastContentRef}></div>
                    )}
                  </GridContainer>
                </>
              )}
              {contentType === "MAP" && (
                <MapContent
                  socialId={socialId}
                  category={category}
                  feeds={mapFeeds}
                  setFeeds={setMapFeeds}
                  mapTabPosition={mapTabPosition}
                />
              )}
            </ContentSection>

            <Route
              path={`${match.path}/list`}
              render={(props) => (
                <FeedContextProvider
                  feeds={{
                    // contentType === 'MAP' 의 경우 MapConent Component에서 서버를 호출로 받은 Feeds를 새로운 변수에 받기 때문에
                    // 기존에 Provider로 전달해주던 feeds 와는 연동이 안되어 컨트롤 불가한 문제 존재
                    // 변수 컨트롤(?)을 위해 상위 Component( 현재 페이지 )에서 변수를 선언하여 Provider를 통해 전달
                    feeds: contentType === "MAP" ? mapFeeds : feeds,
                    setFeeds: contentType === "MAP" ? setMapFeeds : setFeeds,
                  }}
                >
                  <MobileFeedListLayer
                    title={`${social && social.title} 현황`}
                  />
                </FeedContextProvider>
              )}
            />
            <Route
              path={`${match.path}/detail/:jeboId`}
              render={(props) => <FeedDetailLayer />}
            />
          </DetailContainer>
        </>
      )}
    </>
  );
}

const EmptyContents = styled.div`
  text-align: center;
  margin-top: 100px;
  line-height: 20px;
`;

const DetailContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 100vh;

  background-color: #fff;

  padding: 0 12px;
`;

const IntroSection = styled.div`
  margin-top: 40px;

  @media screen and (min-width: 481px) {
    margin-top: 0;
  }
`;

const IntroHeaderWrapper = styled.div`
  display: flex;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 161px;
  position: relative;
  margin-bottom: 42px;
  border-bottom: 1px solid #dcdcdc;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const UserPic = styled.div`
  width: 54px;
  height: 54px;
  border: 1px solid #dcdcdc;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 60%);
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
`;

const IntroContentSection = styled.div`
  width: 100%;
  margin-left: 10px;
  border-radius: 10px;
  line-height: 16px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
`;

const HashtagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 20px;
  padding: 0 12px;
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 112px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.p`
  letter-spacing: -0.0025em;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  max-height: 100%;

  color: #ffffff;
  text-shadow: 0px 2px 4px #000000;

  width: 100%;
  min-height: 100px;
  display: flex;
  /* display: -webkit-flex-box; */
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (min-width: 481px) {
    font-size: 26px;
  }
`;

const DescriptionWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  margin: 10px 0 21px;
  padding: 0 12px;
`;

const DetailTabContainer = styled.div`
  display: flex;
  border-bottom: 0.8px solid #797979;
  margin-bottom: 17px;
`;

const DetailTabBtn = styled.button`
  /* width: 120px; */
  flex: 1 0 50%;
  height: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #292929;
  padding: 7.5px 0;
  background-color: transparent;

  &.active {
    border-bottom: 4px solid #000;
    font-weight: 600;
  }

  @media screen and (min-width: 481px) {
    font-size: 17px;
    height: 38px;
  }
`;

const ItemCountText = styled.p`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 10px;
  color: #2d2d2d;
`;

const Hashtag = styled.p`
  padding: 6px 8.5px;
  margin-right: 5px;
  margin-bottom: 4px;
  background: linear-gradient(
    316.2deg,
    rgba(55, 115, 255, 0.14) -3.34%,
    rgba(34, 230, 243, 0.14) 131.85%
  );
  border-radius: 18px;
  font-weight: 400;
  font-size: 10px;

  @media screen and (min-width: 481px) {
    font-size: 13px;
  }
`;

const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;

  overflow: hidden;
  color: #4e4e4e;

  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  @media screen and (min-width: 481px) {
    font-size: 15px;
    font-weight: 400;
  }
`;

const NavSection = styled.div`
  height: 40px;
  margin-top: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const ContentSection = styled.div`
  margin-top: 10px;
  height: 100%;
`;

// Grid container
const GridContainer = styled.div`
  height: 100%;

  display: grid;

  gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(32%, 105px));
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 481px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 770px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

// Grid Item
const GridContent = styled.div`
  height: 110px;
  padding: 15px;
  background-color: #83a7fb;

  display: flex;
  justify-content: center;
  align-items: center;

  line-height: 1.4em;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 14px;

  & p {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;

    @media screen and (min-width: 481px) {
      font-size: 16px;
    }
  }
  ${({ content }) =>
    content.jeboMediaList.length > 0
      ? css`
          padding: 0;
        `
      : ""}

  @media screen and (min-width: 481px) {
    min-height: 215px;
    /* height: 100%; */
    /* min-height: 155px; */
  }
`;

const LikeButton = styled.button`
  background-color: white;

  /* border: 1px solid #292929; */
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  margin: 0 3px;
  margin-right: auto;
  width: 34px;
  height: 34px;
`;

const HeartBox = styled.div`
  width: 50%;
  height: 20px;
  display: flex;
  & > :first-child {
    /* margin-right: 5px; */
  }
`;

const CloseSocial = styled.div`
  width: 50%;
  height: 50%;
`;
