import jeboApi from "api/api";
import user_icon from "assets/imgs/icons/user_icon.svg";
import ConfirmLayer from "components/layers/popMenu/ConfirmLayer";
import { signAppContext } from "components/providers/SignContextProvider";
import { useRef } from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";

export default function MobileBlockMemberContainer({ setActiveMenu }) {
  const { handleSignOutApp, setSignLayer } = useContext(signAppContext);
  const [members, setMembers] = useState([]);
  const [hasMember, setHasMember] = useState(true);
  const [loading, setLoading] = useState(false);
  //const [error, setError] = useState(null);
  const [showConfirmLayer, setShowConfirmLayer] = useState(false);

  const [targetMember, setTargetMember] = useState(null);

  const history = useHistory();

  const getBlockMembers = useCallback(async () => {
    try {
      setLoading(true);

      const { status, data } = await jeboApi.blockMemberList();

      if (status === 200) {
        const memberList = JSON.parse(data.blockMemberList);
        setHasMember(memberList.length > 0 ? true : false);
        setMembers(memberList);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    } finally {
      setLoading(false);
    }
  }, [history, handleSignOutApp, setSignLayer]);

  useEffect(() => {
    getBlockMembers();
  }, [getBlockMembers]);

  const removeBlockMember = async () => {
    try {
      setLoading(true);

      const { status } = await jeboApi.removeBlockMember(
        targetMember.toMemberUid
      );

      if (status === 200) {
        setMembers((prev) =>
          prev.filter(
            (member) => member.toMemberUid !== targetMember.toMemberUid
          )
        );
        setTargetMember(null);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrap>
      <Modal>
        <ModalHeader>
          <Button onClick={(e) => history.goBack()}></Button>
          <Title>차단목록</Title>
        </ModalHeader>
        <BlockMemberContainer>
          <BlockMemberWrap>
            {loading && <></>}
            <BlockMemberList>
              {hasMember ? (
                <BlockMemberItems>
                  {members.length > 0 &&
                    members.map((member, index) => (
                      <BlockMemberItem key={index}>
                        <BlockMemberProfile
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <BlockMemberImg
                            src={
                              member.imageUrl
                                ? process.env.REACT_APP_RESOURCE_HOST +
                                  member.imageUrl
                                : user_icon
                            }
                            alt={member.nickName}
                          />
                        </BlockMemberProfile>
                        <BlockMemberName>{member.nickName}</BlockMemberName>
                        <BlockMemberCancel
                          onClick={(e) => {
                            setTargetMember(member);
                            setShowConfirmLayer(true);
                          }}
                        >
                          차단 취소
                        </BlockMemberCancel>
                      </BlockMemberItem>
                    ))}
                </BlockMemberItems>
              ) : (
                <NoData>차단한 멤버가 없습니다.</NoData>
              )}
            </BlockMemberList>
          </BlockMemberWrap>
        </BlockMemberContainer>
        {showConfirmLayer && (
          <ConfirmLayer
            setShowConfirmLayer={setShowConfirmLayer}
            callback={removeBlockMember}
            title={`${targetMember.nickName}님을 차단 취소하시겠습니까?`}
            description=""
            btnText="확인"
            isConfirm={true}
          />
        )}
      </Modal>
    </Wrap>
  );
}

const openModal = keyframes`
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
`;

const Modal = styled.div`
  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;

  width: 100%;
  max-width: 100%;
  height: 100% !important;
  max-height: 100vh !important;

  position: relative;
  background: #fff;
  transform: translateY(0);
  opacity: 0;
`;

const ModalHeader = styled.div`
  display: flex !important;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 7px;
  padding-right: 16px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;
`;

const Title = styled.strong`
  display: inline-block;
  width: auto;
  font-size: 1rem;
  color: var(--text-defaultBlackColor);
  font-weight: 500;
  /* line-height: 50px; */
  line-height: normal !important;
  width: 70%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-self: flex-end;
`;

const Button = styled.button`
  display: block;
  width: 27px;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background: url(/static/media/img_pre.2990dcb3.svg) no-repeat;
  background-color: #fff;
  background-position: left center;
`;

const BlockMemberContainer = styled.div`
  padding: 1 0px 16px;
  display: block;
`;

const BlockMemberWrap = styled.div`
  display: block;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 50px;
  left: 0;
  background-color: #fff;
  /* padding-top: 60px; */
  z-index: 1;
  padding-bottom: 70px;
  overflow-y: scroll;
`;

const BlockMemberList = styled.div`
  display: block;
`;

const BlockMemberItems = styled.ul`
  padding: 0 1.75rem;
  list-style: none;
`;

const BlockMemberItem = styled.li`
  display: flex;
  margin: 1.25rem 0 0.625rem;
  align-items: center;
`;

const BlockMemberProfile = styled.span`
  margin-right: 24px;
`;

const BlockMemberImg = styled.img`
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
`;

const BlockMemberName = styled.span`
  color: var(--text-defaultBlackColor);
  font-size: 1rem;
`;

const BlockMemberCancel = styled.button`
  height: 21px;
  padding: 0 6px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  background: #fff;
  font-weight: 300;
  color: #666;
  margin-left: auto !important;
  width: 70px;
  min-height: 1.8rem !important;
  font-size: 11px !important;
  cursor: pointer;
`;

const NoData = styled.div`
  width: 100vw;
  height: 100vh;
  padding-top: 50%;
  text-align: center;
`;
