import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { feedCategoriesContext } from "components/providers/FeedContextProvider";
import { useContext } from "react";
import ZoomSlider from "react-instagram-zoom-slider";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player/lazy";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import SwiperCore, {
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/swiper-bundle.min.css";
import MediaBox from "./MediaBox";
import FeedMainText from "./FeedMainText";
import Lcampaign from "assets/imgs/icons/icon_socialCampaign.svg";
import Lchannel from "assets/imgs/icons/icon_socialChannel.svg";
import Lcommunity from "assets/imgs/icons/icon_socialCommu.svg";
import Ltheme from "assets/imgs/icons/icon_socialTheme.svg";

import styled from "styled-components";
import jeboApi from "api/api";
import { useEffect } from "react";

SwiperCore.use([Pagination, Navigation, Keyboard, Mousewheel]);
export default function FeedMainContent({ feed, page, scType }) {
  const {
    jeboId,
    address,
    placeName,
    hashTag,
    jeboMediaList: jeboMedia,
  } = feed;

  const { feedCategories } = useContext(feedCategoriesContext);
  const { isMobile } = useContext(isMobileContext);

  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const slides = jeboMedia.map((medium, index, arr) => {
    if (medium.mediaType === "image") {
      return (
        <LazyLoadImage
          alt=""
          style={{
            width: "100%",
            height: arr.length > 1 ? "300px" : "350px",
            objectFit: "cover", //"contain",
            margin: "auto",
          }}
          src={process.env.REACT_APP_RESOURCE_HOST + medium.mediaUrl}
        />
      );
    } else {
      return (
        <ReactPlayer
          controls={true}
          className="react-player"
          controlsList="nodownload"
          loop={false}
          muted={true}
          autoPlay={false}
          playsInline={true}
          type="video/*"
          url={`${process.env.REACT_APP_RESOURCE_HOST + medium.mediaUrl}#t=0.5`}
          width="100%"
          height={arr.length > 1 ? "300px" : "350px"}
          style={{
            objectFit: "cover", //"contain",
            margin: "auto",
          }}
        />
      );
    }
  });

  return (
    <>
      {jeboMedia && jeboMedia.length > 0 ? (
        //미디어 있으면

        <div
          className={`item-photo${jeboMedia.length > 1 ? " pl" : ""}${
            jeboMedia[0].mediaType === "video" ? " vdo" : ""
          }`}
        >
          {/* <SocialTypeMark scType={scType} /> */}
          <a
            href="#!"
            className="item-link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              ((jeboMedia &&
                jeboMedia.length > 0 &&
                jeboMedia[0].mediaType !== "video") ||
                jeboMedia.length === 0) &&
                history.push({
                  pathname: `${match.url}/detail/${jeboId}`,
                  search: location.search,
                });
            }}
          >
            {isMobile ? (
              <ZoomSlider slides={slides} />
            ) : (
              <MediaBox
                mediaType={jeboMedia[0].mediaType}
                mediaUrl={
                  process.env.REACT_APP_RESOURCE_HOST + jeboMedia[0].mediaUrl
                }
                enabledZoom={false}
                mediaFit={true}
              />
            )}
          </a>
        </div>
      ) : (
        //미디어 없으면
        <div className="item-photo share">
          <div
            className="share__textContainer"
            onClick={(e) => {
              e.stopPropagation();
              history.push({
                pathname: `${match.url}/detail/${feed.jeboId}`,
                search: location.search,
              });
            }}
          >
            <div className="share__textBox">
              <div className="share__desc">
                {/* {feed && feed.hashTag && <FeedMainTag feed={feed} />} */}
                <FeedMainText feed={feed} page={page} grid={false} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
