import useOuterClick from "components/hooks/useOuterClick";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import ToastMessageBox from "components/templates/ToastMessageBox";
import { Base64 } from "js-base64";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function FeedShareLayer({ feed, onClose }) {
  const kakao = window.Kakao;

  const shareUrl = encodeURI(
    `${process.env.REACT_APP_HOST}/home/detail/${feed.jeboId}`
  );

  const { isMobile, mobileType } = useContext(isMobileContext);

  const [toastMessage, setToastMessage] = useState("");

  const shareRef = useRef();
  useOuterClick(shareRef, onClose);

  const shareFacebook = (e) => {
    e.stopPropagation();

    if (mobileType === "ios") {
      const params = {
        url: shareUrl,
        scheme: "facebook",
      };
      const jsonParams = JSON.stringify(params);
      const encodedParams = Base64.encode(jsonParams);
      window.webkit.messageHandlers.openShare.postMessage(encodedParams);

      // const encodedUrl = Base64.encode(
      //   `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`
      // );
      // window.webkit.messageHandlers.openOuterLink.postMessage(encodedUrl);
    } else if (!isMobile) {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`);
    } else if (navigator.share) {
      navigator.share({ url: shareUrl });
    } else {
      console.log("Can't share URL to facebook ");
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`);
    }
  };
  const shareTwitter = (e) => {
    e.stopPropagation();

    if (mobileType === "ios") {
      const params = {
        url: `https://twitter.com/intent/tweet?url=${shareUrl}`,
        scheme: "twitter",
      };
      const jsonParams = JSON.stringify(params);
      const encodedParams = Base64.encode(jsonParams);
      window.webkit.messageHandlers.openShare.postMessage(encodedParams);
    } else if (!isMobile) {
      window.open(`https://twitter.com/intent/tweet?url=${shareUrl}`);
    } else if (navigator.share) {
      navigator.share({ url: shareUrl });
    } else {
      console.log("Can't share URL to twitter ");
      window.open(`https://twitter.com/intent/tweet?url=${shareUrl}`);
    }
  };

  const kakaoInit = useCallback(() => {
    !kakao.isInitialized() && kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
  }, [kakao]);

  useEffect(() => {
    kakaoInit();
  }, [kakaoInit]);
  const shareKakao = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const title = feed.nickName ? `${feed.nickName}의 제보` : "제보";
    const description = feed.contents ? feed.contents : "";
    let imageUrl = "";
    if (feed.jeboMediaList && feed.jeboMediaList.length > 0) {
      if (feed.jeboMediaList.some((medium) => medium.mediaType === "image")) {
        const medium = feed.jeboMediaList.find(
          (medium) => medium.mediaType === "image"
        );
        imageUrl = process.env.REACT_APP_HOST + medium.mediaUrl;
      }
    }
    console.log("imageUrl : ", imageUrl);
    const likeCount = feed.likeCnt ? feed.likeCnt : 0;
    const commentCount = feed.commentCnt > 0 ? feed.commentCnt : 0;

    const message = {
      objectType: "feed",
      content: {
        title,
        description,
        imageUrl,
        link: {
          webUrl: shareUrl,
          mobileWebUrl: shareUrl,
          androidExecutionParams: `page=${shareUrl}`,
          iosExecutionParams: `page=${shareUrl}`,
        },
      },
      social: {
        likeCount,
        commentCount,
      },
      buttons: [
        {
          title: "웹으로 이동",
          link: {
            mobileWebUrl: shareUrl,
            webUrl: shareUrl,
          },
        },
        {
          title: "앱으로 이동",
          link: {
            webUrl: shareUrl,
            mobileWebUrl: shareUrl,
            androidExecutionParams: `page=${shareUrl}`,
            iosExecutionParams: `page=${shareUrl}`,
          },
        },
      ],
    };
    //ios URL Scheme : kakaoc046bab26b5428fed40d119412a56bbc://kakaolink

    //window.webkit.messageHandlers.print.postMessage(JSON.stringify(message));
    kakao.Link.sendDefault(message);
  };

  return (
    <div className="pop-menu item-pop share active">
      <div className="pop-header">
        <strong>공유</strong>
        <button className="close" onClick={(e) => onClose()}>
          &times;
        </button>
      </div>
      <div className="pop-body">
        {toastMessage && (
          <ToastMessageBox
            toastMessage={toastMessage}
            setToastMessage={setToastMessage}
          />
        )}
        <ul>
          <li>
            <CopyToClipboard
              text={shareUrl}
              onCopy={() => {
                setToastMessage("링크가 복사되었습니다.");
              }}
            >
              <button className="btn copy"></button>
            </CopyToClipboard>
            <CopyToClipboard
              text={shareUrl}
              onCopy={() => {
                setToastMessage("링크가 복사되었습니다.");
              }}
            >
              <span>링크복사</span>
            </CopyToClipboard>
          </li>
          <li>
            <button className="btn kakao" onClick={shareKakao}></button>
            <span>카카오</span>
          </li>
          <li>
            <button className="btn facebook" onClick={shareFacebook}></button>
            <span>페이스북</span>
          </li>
          <li>
            <button className="btn twitter" onClick={shareTwitter}></button>
            <span>트위터</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
