import jeboApi from "api/api";
import useOuterClick from "components/hooks/useOuterClick";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { SessionContext } from "components/providers/SessionContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { useContext, useRef } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

export default function FeedAddFeatLayer({
  feed,
  onClose,
  onOpen,
  onUpdateFeed,
  onDeleteFeed,
  setToastMessage,
}) {
  const { isMobile } = useContext(isMobileContext);
  const { isLoggedIn, myInfo } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const { onExpiredSession } = useContext(SessionContext);

  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  //const [error, setError] = useState(null);

  const featRef = useRef();
  useOuterClick(featRef, onClose);

  const handleAddFeedCabinet = async () => {
    try {
      const { status } = await jeboApi.addKeepFeed(feed.jeboId);

      if (status === 200) {
        setToastMessage("보관 게시물에 추가되었습니다.");
        onUpdateFeed({ ...feed, keeping: true });
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          onExpiredSession();
        }
      }
    } finally {
      onClose();
    }
  };

  const handleRemoveFeedCabinet = async () => {
    try {
      const { status } = await jeboApi.removeKeepFeed(feed.jeboId);

      if (status === 200) {
        setToastMessage("보관이 취소되었습니다.");
        onUpdateFeed({ ...feed, keeping: false });
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          onExpiredSession();
        }
      }
    } finally {
      onClose();
    }
  };

  const handleUpdateFeedHidden = async (hiddenYn) => {
    try {
      const { status } = await jeboApi.updateHiddenFeed(feed.jeboId, hiddenYn);
      if (status === 200) {
        onDeleteFeed({ ...feed, hiddenYn });
        setToastMessage(
          hiddenYn === "Y"
            ? "게시물이 숨김 처리되었습니다."
            : "게시물이 공개되었습니다."
        );
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          onExpiredSession();
        }
      }
    } finally {
      onClose();
    }
  };

  return (
    <>
      <div className="pop-menu item-menu active" ref={featRef}>
        <ul>
          {myInfo && myInfo.memberUid === feed.memberUid && (
            <li>
              <button
                className="menu"
                onClick={(e) => {
                  history.replace({
                    pathname: `${match.url}/edit`,
                    state: {
                      ...location.state,
                      feed,
                    },
                    search: location.search ? location.search : null,
                  });
                  onClose();
                }}
              >
                수정
              </button>
            </li>
          )}
          {myInfo && myInfo.memberUid !== feed.memberUid && (
            <li>
              <button
                className="menu"
                onClick={(e) => {
                  if (!isLoggedIn) {
                    setSignLayer("signIn");
                    return;
                  }

                  sessionStorage.setItem("dmTargetMemberUid", feed.memberUid);
                  sessionStorage.setItem("dmTargetNickName", feed.nickName);
                  sessionStorage.setItem("dmFromJeboId", feed.jeboId);

                  isMobile
                    ? history.push({
                        pathname: "/directMessage",
                        search: `?memberUid=${feed.memberUid}&nickName=${feed.nickName}&jeboId=${feed.jeboId}`,
                      })
                    : window.open(
                        `/directMessage?memberUid=${feed.memberUid}&nickName=${feed.nickName}&jeboId=${feed.jeboId}`,
                        "_blank"
                      );
                }}
              >
                1:1대화
              </button>
            </li>
          )}
          <li>
            <button
              className="menu"
              onClick={(e) => {
                onOpen(feed.jeboId, "SHARE");
              }}
            >
              공유
            </button>
          </li>
          {myInfo && myInfo.memberUid !== feed.memberUid && (
            <li>
              {feed.keeping ? (
                <button
                  className="menu"
                  onClick={(e) => {
                    isLoggedIn
                      ? handleRemoveFeedCabinet()
                      : setSignLayer("signIn");
                  }}
                >
                  보관 취소
                </button>
              ) : (
                <button
                  className="menu"
                  onClick={(e) => {
                    isLoggedIn
                      ? handleAddFeedCabinet()
                      : setSignLayer("signIn");
                  }}
                >
                  보관
                </button>
              )}
            </li>
          )}

          {myInfo &&
            myInfo.memberUid === feed.memberUid &&
            (feed.hiddenYn === "N" ? (
              <li>
                <button
                  className="menu"
                  onClick={(e) => handleUpdateFeedHidden("Y")}
                >
                  숨김
                </button>
              </li>
            ) : (
              <li>
                <button
                  className="menu"
                  onClick={(e) => handleUpdateFeedHidden("N")}
                >
                  숨김 취소
                </button>
              </li>
            ))}

          {myInfo && myInfo.memberUid !== feed.memberUid && (
            <>
              <li>
                <button
                  className="menu"
                  onClick={(e) => {
                    if (!isLoggedIn) {
                      setSignLayer("signIn");
                      return;
                    }
                    onOpen(feed.jeboId, "MEMBER_BLOCK");
                  }}
                >
                  차단
                </button>
              </li>
              <li>
                <button
                  className="menu"
                  onClick={(e) => {
                    if (!isLoggedIn) {
                      setSignLayer("signIn");
                      return;
                    }

                    onOpen(feed.jeboId, "REPORT");
                  }}
                >
                  신고
                </button>
              </li>
            </>
          )}
          {myInfo && myInfo.memberUid === feed.memberUid && (
            <li>
              <button
                className="menu"
                onClick={(e) => {
                  onOpen(feed.jeboId, "DELETE");
                }}
              >
                삭제
              </button>
            </li>
          )}
          <li>
            <button
              className="menu mobile"
              onClick={() => {
                onClose();
              }}
            >
              취소
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}
