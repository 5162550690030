import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export default function SocialNavButtonNew({ text, onClick, isActive }) {
  return (
    <Button isActive={isActive} onClick={onClick}>
      {text}
    </Button>
  );
}

const Button = styled.button`
  flex: 0 0 auto;
  width: 70px;

  font-style: normal;
  font-weight: 400;
  line-height: 19px;

  /* background-color: white; */
  background-color: transparent;

  font-size: 14px;
  padding: 3px 0;
  color: rgba(255, 255, 255, 0.6);

  @media screen and (min-width: 481px) {
    font-size: 16px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      position: relative;
      font-weight: 700;
      color: #fff;

      ::after {
        position: absolute;
        content: "";
        bottom: -8px;
        left: 50%;
        width: 100%;
        height: 3px;
        transform: translateX(-50%);
        background-color: rgba(255, 255, 255, 0.62);
      }
    `};
`;
