import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import ToastMessageBox from "components/templates/ToastMessageBox";
import { useContext, useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import ReactPlayer from "react-player/lazy";
import uuid from "react-uuid";
import SwiperCore, {
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Pagination, Navigation, Keyboard, Mousewheel]);

//미디어 width,height 전역변수
const SIZE = "72px";
export default function MediaContainer({
  media,
  setMedia,
  openMedia,
  setShowEditor,
  setMediaUrl,
  isEdit,
  setIsEdit,
  tabState,
}) {
  const [toastMessage, setToastMessage] = useState("");
  const { isMobile } = useContext(isMobileContext);
  const [isImage, setIsImage] = useState(false);

  const removeMedium = (removeMedium) => {
    URL.revokeObjectURL(removeMedium.url);
    setMedia(media.filter((medium) => medium.id !== removeMedium.id));
  };

  useEffect(() => {
    let existImage = media.some((medium) => {
      return medium.type === "image";
    });
    existImage ? setIsImage(true) : setIsImage(false);
  }, [media]);

  const handleFileUpload = (e) => {
    let files = Array.from(e.target.files);

    if (files.length > 20) {
      files = files.filter((_, index) => index < 20);
      alert("최대 20개까지 첨부할 수 있습니다.");
    }

    const addMedia = files
      .filter((file) => handleFileValidate(file))
      .map((file, index) => {
        return {
          id: uuid(),
          file: file,
          url: URL.createObjectURL(file),
          type: file.type.split("/")[0],
          upload: true,
          thumbnail: media.length === 0 && index === 0 ? "Y" : "N",
          changeThumbnail: false,
        };
      });

    addMedia && addMedia.length > 0 && setMedia(media.concat(addMedia));
  };

  const handleFileValidate = (file) => {
    if (!file.type.includes("image") && !file.type.includes("video")) {
      setToastMessage("이미지 또는 동영상 파일만 업로드 가능합니다.");
      return false;
    }
    if (file.size > 1024 * 1024 * 50) {
      setToastMessage("50MB 이하의 파일만 업로드 가능합니다.");
      return false;
    }

    if (media && media.length > 20) {
      setToastMessage("최대 20개까지 첨부할 수 있습니다.");
      return false;
    }

    return true;
  };

  const onChangeRadioBtn = (e) => {
    const tempMedia = media.slice();

    tempMedia.map((medium, index) => {
      if (String(index) === String(e.target.value)) {
        medium.changeThumbnail = true;
        return (medium.thumbnail = "Y");
      } else return (medium.thumbnail = "N");
    });
    // let tmp = tempMedia[e.target.value];
    // tempMedia.splice(e.target.value, 1);
    // tempMedia.unshift(tmp);

    setMedia(tempMedia);
  };

  useEffect(() => {
    if (!openMedia) {
      setMedia([]);
    }
  }, [openMedia, setMedia]);

  return (
    <div className="write-item">
      {toastMessage && (
        <ToastMessageBox
          toastMessage={toastMessage}
          setToastMessage={setToastMessage}
        />
      )}
      <div className="input-title">
        <strong className="ttl">미디어</strong>
        <span className="desc">*동영상은 50MB 이하 파일만 업로드 가능</span>
        {tabState && isMobile && media.length > 0 && isImage && (
          <button
            className="modal-btn edit"
            onClick={(e) => {
              setIsEdit((prev) => !prev);
            }}
          >
            {isEdit ? "편집 완료" : "이미지편집"}
          </button>
        )}
      </div>
      {!tabState && (
        <div style={{ width: "100%", height: "30px" }}>
          {isMobile && media.length > 0 && isImage && (
            <button
              className="modal-btn edit"
              onClick={(e) => {
                setIsEdit((prev) => !prev);
              }}
            >
              {isEdit ? "편집 완료" : "이미지편집"}
            </button>
          )}
        </div>
      )}
      {
        <Swiper
          className="input-group image file-swiper swiper-container active"
          pagination={{ el: ".swiper-pagination", clickable: true }}
          freeMode={true}
          spaceBetween={10}
          slidesPerView="auto"
        >
          <SwiperSlide className="img-item">
            <input
              type="file"
              className="file-upload"
              id="fileupload"
              multiple
              onClick={(e) => {
                e.target.value = null;
              }}
              onInput={handleFileUpload}
              accept="image/* video/*;capture=camera"
            />
            <label htmlFor="fileupload"></label>
          </SwiperSlide>
          {media.map((medium, index) => (
            <SwiperSlide className="img-item remove-radio" key={medium.id}>
              {!isEdit && (
                <button
                  className="img-del"
                  type="button"
                  onClick={(e) => removeMedium(medium)}
                >
                  <i className="icon-cross3"></i>
                </button>
              )}

              {medium.type === "image" ? (
                isMobile && isEdit ? (
                  <>
                    <img
                      src={medium.url}
                      alt=""
                      width={SIZE}
                      height={SIZE}
                      style={
                        medium.thumbnail === "Y"
                          ? {
                              // border: "3px outset red",
                              border: "3px solid #3773ff",
                              borderRadius: "3px",
                            }
                          : null
                      }
                    />
                    <button
                      className="select-edit-btn"
                      onClick={(e) => {
                        setMediaUrl(medium.url);
                        setShowEditor(true);
                      }}
                    >
                      선택
                    </button>
                  </>
                ) : (
                  <Zoom>
                    <img
                      src={medium.url}
                      alt=""
                      width={SIZE}
                      height={SIZE}
                      style={
                        medium.thumbnail === "Y"
                          ? {
                              border: "3px solid #3773ff",
                              borderRadius: "3px",
                            }
                          : null
                      }
                    />
                  </Zoom>
                )
              ) : (
                <ReactPlayer
                  preload="metadata"
                  controlsList="nodownload"
                  controls={true}
                  loop={true}
                  muted={true}
                  autoPlay={true}
                  playsInline={false}
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen={true}
                  type="video/*"
                  url={medium.url}
                  width={SIZE}
                  height={SIZE}
                  style={
                    medium.thumbnail === "Y" && {
                      border: "3px solid #3773ff",
                      borderRadius: "3px",
                    }
                  }
                />
              )}
              {!isEdit && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 8,
                    width: "100%",
                    bottom: "-1em",
                    height: "3em",
                    left: "10px",
                  }}
                >
                  <input
                    type="radio"
                    name="fisrtImage"
                    id={medium.url}
                    value={index}
                    onChange={onChangeRadioBtn}
                    style={{
                      width: "100%",
                    }}
                    checked={medium.thumbnail === "Y" ? true : false}
                  />
                  <label
                    htmlFor={medium.url}
                    style={{
                      zIndex: 9,
                      width: "100%",
                      height: "45%",
                      bottom: "4px",
                    }}
                  ></label>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      }
    </div>
  );
}
