import { useEffect, useState } from "react";

export default function ShareTabLayer({
  setHashTags,
  targetCategory,
  setCategories,
  contents,
  setContents,
  eventInfo,
  editorRef,
}) {
  const [placeholderText, setPlaceHolderText] = useState("");

  useEffect(() => {
    const placeholderId = localStorage.getItem("feedReqPlaceholderText");
    if (!placeholderId || placeholderId === "0") {
      localStorage.setItem("feedReqPlaceholderText", "1");
      setPlaceHolderText(
        "궁금한건 여기에 제보요청 해보세요. 제보 사용자들이 도와줄 거예요"
      );
    } else if (placeholderId === "1") {
      localStorage.setItem("feedReqPlaceholderText", "2");
      setPlaceHolderText("지금 궁금한 그곳, 질문해 보세요!");
    } else if (placeholderId === "2") {
      localStorage.setItem("feedReqPlaceholderText", "3");
      setPlaceHolderText("알고 싶은 장소에 대해 궁금한 점을 작성해 보세요!");
    } else if (placeholderId === "3") {
      localStorage.setItem("feedReqPlaceholderText", "4");
      setPlaceHolderText("출근길 교통 정보가 궁금할 때 제보 요청해 보세요!");
    } else if (placeholderId === "4") {
      localStorage.setItem("feedReqPlaceholderText", "5");
      setPlaceHolderText("이슈가 궁금하신가요? 제보 요청해 보세요!");
    } else if (placeholderId === "5") {
      localStorage.setItem("feedReqPlaceholderText", "6");
      setPlaceHolderText("동네 맛집이 궁금하다면 제보 요청해 보세요!");
    } else if (placeholderId === "6") {
      localStorage.setItem("feedReqPlaceholderText", "7");
      setPlaceHolderText("동네 마트 세일 정보가 궁금할 때 제보 요청해 보세요!");
    } else if (placeholderId === "7") {
      localStorage.setItem("feedReqPlaceholderText", "8");
      setPlaceHolderText(
        "믿을 만한 동네 병원 정보가 필요하다면 제보 요청해 보세요!"
      );
    } else if (placeholderId === "8") {
      localStorage.setItem("feedReqPlaceholderText", "0");
      setPlaceHolderText(
        "궁금한건 여기에 제보요청 해보세요. 제보 사용자들이 도와줄 거예요"
      );
    } else {
      // 이도 저도 아니면 다시 1번부터 시작.
      localStorage.setItem("feedReqPlaceholderText", "1");
      setPlaceHolderText(
        "궁금한건 여기에 제보요청 해보세요. 제보 사용자들이 도와줄 거예요"
      );
    }
    //      localStorage.setItem("feedReqPlaceholderText", "12");
    //      setPlaceHolderText("단풍 구경 어디로 가야 좋을지 궁금하다면 제보 요청해 보세요!");
    // localStorage.setItem("feedReqPlaceholderText", "8");
    // setPlaceHolderText("SNS 운영 안 하는 로컬 맛집, 오늘 운영하는지 궁금하다면 제보 요청해 보세요!");
    // localStorage.setItem("feedReqPlaceholderText", "5");
    // setPlaceHolderText("오늘 날씨에 어떤 옷차림이 괜찮을지 궁금하다면 제보 요청해 보세요!");
    //      localStorage.setItem("feedReqPlaceholderText", "10");
    //      setPlaceHolderText("맛집 얼마나 줄 서서 기다려야 하는지 궁금하다면 제보 요청해 보세요!");
  }, []);

  useEffect(() => {
    //const regex = /#[^/!@#$%^&*(),.?":{}|<>=\s\n]+/g;
    //const regex = hashtagRegex();
    const regex = /#[^#\s\n]+/g;
    let array1;

    const foundHashtags = [];
    while ((array1 = regex.exec(contents)) !== null) {
      const tag = array1[0];
      //해시태그 세팅
      !foundHashtags.includes(tag) && foundHashtags.push(tag);
    }

    if (foundHashtags && foundHashtags.length > 0) {
      setHashTags(foundHashtags);
      //카테고리 체크 세팅
      setCategories((prev) =>
        prev.map((category) => {
          return Object.assign({}, category, {
            checked: foundHashtags.includes(category.categoryName),
          });
        })
      );
    } else {
      setHashTags([]);
      setCategories((prev) =>
        prev.map((category) => {
          return Object.assign({}, category, {
            checked: false,
          });
        })
      );
    }
  }, [contents, setHashTags, setCategories]);

  useEffect(() => {
    if (targetCategory) {
      if (targetCategory.checked) {
        setHashTags((prev) => [...prev, targetCategory.categoryName]);
        setContents((prev) => `${prev} ${targetCategory.categoryName} `);
      } else {
        //const regex = /#[^/!@#$%^&*(),.?":{}|<>=\s\n]+/g;
        //const regex = hashtagRegex();
        const regex = /#[^#\s\n]+/g;
        let array1;

        setHashTags((prev) =>
          prev.filter((tag) => tag !== targetCategory.categoryName)
        );
        setContents((prev) => {
          let current = prev;
          while ((array1 = regex.exec(current)) !== null) {
            if (array1[0] === targetCategory.categoryName) {
              current = current.replaceAll(
                ` ${targetCategory.categoryName} `,
                ""
              );
            }
          }
          return current;
        });
      }
    }
  }, [targetCategory, setContents, setHashTags]);

  return (
    <div className="write-item share">
      <div className="input-title">
        <strong className="ttl">내용</strong>
        {eventInfo && eventInfo.eventShortsDesc && (
          <div className="campaignHashTag-ct">
            {eventInfo.eventShortsDesc
              .split(" ")
              .map((eventShortsDesc, index) => {
                return (
                  <span key={index} className="campaignHashTag__text">
                    {eventShortsDesc}
                  </span>
                );
              })}
          </div>
        )}
      </div>
      <div className="input-group text">
        <textarea
          placeholder={placeholderText}
          ref={editorRef}
          autoCapitalize="none"
          aria-autocomplete="none"
          autoCorrect="off"
          autoComplete="off"
          spellCheck="false"
          className="text-area"
          style={{
            fontSize: "13px",
            lineHeight: "20px",
            height: "100%",
            padding: "10px 15px",
            minHeight: "275px",
          }}
          onChange={(e) => {
            if (e.target.value.length > 10000) return;
            //setShareContents(e.target.value);
            // 진희 수정
            setContents(e.target.value);
          }}
          value={contents}
        ></textarea>
      </div>
    </div>
  );
}
