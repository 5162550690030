import img_pre from "assets/imgs/icons/img_pre.svg";
import styled from "styled-components";
// import img_gps from "assets/imgs/icons/gps.svg";
// import img_clear from "assets/imgs/icons/clear.svg";
import jeboApi from "api/api";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SocialConfirmPop from "./SocialConfirmPop";
import LoadingComponent from "components/templates/LoadingComponent";
import SocialCompletePop from "./SocialCompletePop";

export function CloseChannel({ setKeep }) {
  const changeRadio = (e) => {
    setKeep(e.target.value);
  };

  return (
    <>
      <DeleteContentText>
        해당 채널의 게시물은 유지 할 수도 있으며 삭제 할 수도 있습니다.
      </DeleteContentText>
      <DeleteRadioDesc>* 데이터 유지 / 삭제를 선택해주세요.</DeleteRadioDesc>
      <DeleteChkContainer>
        <ButtonSection>
          <RadioButton
            id="deleteCheck1"
            type="radio"
            name="keepFeed"
            onChange={changeRadio}
            value={true}
          />
          <RadioTitle htmlFor="deleteCheck1">유지</RadioTitle>
        </ButtonSection>
        <ButtonSection>
          <RadioButton
            id="deleteCheck2"
            type="radio"
            name="keepFeed"
            onChange={changeRadio}
            value={false}
          />
          <RadioTitle htmlFor="deleteCheck2">삭제</RadioTitle>
        </ButtonSection>
      </DeleteChkContainer>
    </>
  );
}

export function CloseOthers({ name }) {
  return (
    <>
      <DeleteContentText>
        해당 {name}의 게시물은 개설자 권한의 게시물이 아니므로 유지됩니다.
      </DeleteContentText>
    </>
  );
}

export default function CloseSocial({ category, removeSocial }) {
  const history = useHistory();
  const location = useLocation();
  const { socialId } = useParams();
  const [social, setSocial] = useState(null);

  const [confirmLayer, setConfirmLayer] = useState(false);
  const [confirmCompleteLayer, setConfirmCompleteLayer] = useState(false);

  const [confirmType, setConfirmType] = useState(0);
  const comfirmMessage = {
    0: "데이터 유지/삭제 를 선택해주세요.",
    1: "유의사항 확인 체크 후, 폐쇄를 진행해주세요",
  };

  const [cautionCheck, setCautionCheck] = useState(false);
  const [keep, setKeep] = useState();

  const handleClose = async () => {
    try {
      const { status } = await jeboApi.closeSocial(social.socialId);
      if (status === 200) {
        removeSocial(social.socialId);
        history.replace({ pathname: `/social/${category.name}` });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadSocialDetail = useCallback(async () => {
    try {
      const {
        status,
        data: { socialData },
      } = await jeboApi.getSocialAbout(socialId);

      if (status === 200) {
        const {
          scId: socialId,
          scName: title,
          scDesc: description,
          scTag: hashtags,
          scType: socialType,
          imageName,
          likeCount,
          memberCount,
          roleName,
          like,
        } = JSON.parse(socialData);
        console.log(JSON.parse(socialData));

        setSocial({
          socialId,
          title,
          description,
          hashtags: hashtags.split(" "),
          socialType,
          likeCount,
          memberCount,
          imageUrl: "/media/social/" + imageName,
          roleName,
          like,
        });
      }
    } catch (error) {
      const { status } = error;
      console.log(error, status);
    }
  }, [socialId]);
  useEffect(() => {
    loadSocialDetail();
  }, [loadSocialDetail]);

  if (!social) return <LoadingComponent />;

  return (
    <Container>
      <Header>
        <BackButton onClick={history.goBack} />
        <HeaderTitle>
          [{category.title}]{social.title} 폐쇄 하시겠습니까?
        </HeaderTitle>
      </Header>
      <Main>
        <HeaderWeb>
          <BackButton onClick={history.goBack} />
          <HeaderWebTitle>{category.title} 폐쇄하기</HeaderWebTitle>
        </HeaderWeb>
        <InfoContainer>
          <DeleteInputTitle>
            <strong>※{category.title} 폐쇄 유의사항</strong>
          </DeleteInputTitle>
          <DeleteContentContainer>
            {category.name === "channel" ? (
              <CloseChannel setKeep={setKeep} />
            ) : (
              <CloseOthers name={category.title} setKeep={setKeep} />
            )}
          </DeleteContentContainer>
          <DeleteContentContainer className="chkCt">
            <DeleteChkContainer>
              <input
                id="deleteCheck"
                type="checkbox"
                onChange={(e) => {
                  if (category.name === "channel" && keep === undefined) {
                    setConfirmType(0);
                    setConfirmCompleteLayer(true);
                    return;
                  }
                  setCautionCheck(e.target.checked);
                }}
                checked={cautionCheck}
              />
              <label htmlFor="deleteCheck">
                {category.title} 을(를) 폐쇄합니다.
              </label>
            </DeleteChkContainer>
            <DeleteCommonText>
              {(category.name === "theme" || category.name === "campaign") &&
                `${category.title} 이(가) 폐쇄되면 개설자의 1:1대화로 폐쇄여부를
              보내 드립니다.`}
            </DeleteCommonText>
          </DeleteContentContainer>
        </InfoContainer>
        <DeleteSocialButton
          onClick={(e) => {
            e.preventDefault();
            if (cautionCheck) setConfirmLayer(true);
            else {
              setConfirmType(1);
              setConfirmCompleteLayer(true);
            }
          }}
          className="makeBtn"
        >
          {/* <span>
            [{category.title}] {social.title}
          </span> */}
          <p>폐쇄 신청</p>
        </DeleteSocialButton>
        {confirmLayer && (
          <SocialConfirmPop
            title={`[${category.title}]${social.title}`}
            message="폐쇄하시겠습니까?"
            onClick={() => {
              setConfirmLayer(false);
              handleClose();
            }}
            onCancel={() => {
              history.goBack();
            }}
          />
        )}
        {confirmCompleteLayer && (
          <SocialCompletePop
            title={`[${category.title}]${social.title}`}
            message={comfirmMessage[confirmType]}
            onClick={() => {
              setConfirmCompleteLayer(false);
            }}
          />
        )}
      </Main>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  padding-bottom: 50px;
  background: #ffffff;

  @media screen and (min-width: 481px) {
    background-color: rgba(0, 0, 0, 0.5);
    padding-bottom: 0;
  }
`;

const Main = styled.main`
  width: 100%;
  height: 100%;

  padding-top: 50px;

  /* 스크롤 넣었음 문제가 발생할까요? */
  overflow-y: scroll;

  display: flex;
  flex-direction: column;

  @media screen and (min-width: 481px) {
    /* border: 3px dashed blue; */
    background-color: #fff;
    width: 700px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;

    overflow-x: hidden;
  }
`;

const Header = styled.div`
  height: 48px;
  position: fixed;
  left: 0;
  right: 0;

  padding: 0 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-bottom: solid 1px #dcdcdc;
  background-color: #fff;

  @media screen and (min-width: 481px) {
    display: none;
  }
`;

const BackButton = styled.button`
  background: url(${img_pre}) no-repeat;
  border: none;

  width: 10%;
  height: 70%;

  @media screen and (min-width: 481px) {
    width: 25px;
    height: 25px;
  }
`;

const HeaderTitle = styled.h1`
  width: 80%;
  height: 70%;
  text-align: center;
  line-height: 30px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const HeaderWeb = styled.div`
  padding: 0 10px;

  display: flex;
  /* justify-content: center; */
  align-items: center;

  border-bottom: solid 1px #dcdcdc;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const HeaderWebTitle = styled.h1`
  height: 30px;
  line-height: 25px;
  justify-self: center;
  margin: 0 auto;
`;

const InfoContainer = styled.div`
  width: 100%;
  padding: 0 7px;
  margin-bottom: 14px;
  padding: 30px 4%;
  text-align: center;

  height: 100%;
  display: flex;
  flex-direction: column;
  .inputBox {
    display: flex;
    &.tags {
      padding-bottom: 5px;
    }
  }
  .inputTagBox {
    display: flex;
    flex-wrap: wrap;
    p {
      padding: 0px 11px;
      margin-right: 4px;
      margin-bottom: 6px;
      flex-grow: 0 0 auto;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      color: #5293c5;
      border: 1px solid #5293c5;
      border-radius: 100px;
    }
  }
`;

const DeleteSocialButton = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 7px;

  flex-direction: column;

  height: 36px;

  background: #9d9d9d;
  border: 1px solid #9d9d9d;
  border-radius: 4px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #ffffff;
  &.makeBtn {
    margin: 0 24px 15px 24px;
    /* width: 313px; */
    height: 50px;
    left: 24px;
    top: 609px;

    background: #014681;
    border-radius: 4px;

    text-align: center;
  }

  span {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  margin-top: auto !important;

  @media screen and (min-width: 481px) {
    width: 150px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
`;

const DeleteInputTitle = styled.p`
  strong {
    font-size: 18px;
    line-height: 26px;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  margin-bottom: 30px;
`;

const DeleteContentContainer = styled.div`
  text-align: left;
  /* margin-bottom: 50px; */
  &.chkCt {
    margin: auto 0 15px;
    text-align: center;
  }
`;

const DeleteChkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
  }
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    background: #ffffff;
    border: 1px solid #2d2d2d;
    border-radius: 1px;
    margin-right: 9px;
  }

  margin: 10px 0;
`;

const DeleteContentText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  padding-left: 10px;
  color: #797979;

  margin-bottom: 10px;
`;

const DeleteCommonText = styled.p`
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;

  padding-left: 10px;
  color: #797979;

  margin-bottom: 10px;
`;

const DeleteRadioDesc = styled.p`
  text-align: center;
  font-size: 12px;
  margin-left: 10px;
  color: #797979;
  margin-top: 55px;
`;

const ButtonSection = styled.label`
  /* width: 47%; */
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;

  margin: 0 5px;
`;

const RadioTitle = styled.span`
  font-size: 16px;
  padding: 8px 24px;
  background: #e6e6e6;
  border-radius: 30px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #2c2c2c;
  color: #fff;
`;

const RadioButton = styled.input.attrs({ type: "radio" })`
  &:checked {
    display: inline-block;
    background: none;
    padding: 0px 10px;
    text-align: center;
    height: 35px;
    line-height: 33px;
    font-weight: 500;
    display: none;
  }
  &:checked + ${RadioTitle} {
    background: linear-gradient(316.2deg, #3773ff -3.34%, #22e6f3 131.85%);
    color: #fff;
  }
  display: none;
`;
