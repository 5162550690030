/* 웹의 홈에서 상단 제보큐브에 들어갈 내용 제보큐브 전체와 분리 24.07.23.codelua */
import heart from "assets/imgs/icons/icon_favRed.svg";
import emptyHeart from "assets/imgs/icons/icon_favRedBorder.svg";
import { useHistory, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { useContext, useEffect, useState } from "react";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";

import iconMember from "assets/imgs/icons/icon_joinUser.svg";
import iconMemberActive from "assets/imgs/icons/icon_joinUserActive.svg";

import iconPostCount from "assets/imgs/icons/icon_feedcount.svg";
import iconPostCountActive from "assets/imgs/icons/icon_feedcountActive.svg";

export default function HomeContentItemDefault2({
  item,
  category,
  toggleLike,
  index,
  section,
}) {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useContext(isMobileContext);
  const [like, setLike] = useState(item.like);
  const [likeCount, setLikeCount] = useState(item.likeCount);
  const [postCnt, setPostCnt] = useState(item.postCnt);

  useEffect(() => {
    setLike(item.like);
    setLikeCount(item.likeCount);
    setPostCnt(item.postCnt);
  }, [item]);

  const {
    scId: socialId,
    scType: socialType,
    scTag: hashtags,
    scName: title,
    scDesc: scDesc,
    memberCount,
    imageName,
    participated,
  } = item;

  return (
    <ItemWrapper
      className={section}
      index={index}
      isMobile={isMobile.isMobile}
      onClick={(e) => {
        history.push({
          ...location,
          pathname: `/social/${category.name}/${socialId}`,
        });
      }}
    >
      <ImageWrapper className={section}>
        <SocialTypeText
          type={
            socialType === 1
              ? "테마"
              : socialType === 2
              ? "캠페인"
              : socialType === 3
              ? "채널"
              : socialType === 4
              ? `커뮤 니티`
              : ""
          }
        >
          {socialType === 1
            ? "테마"
            : socialType === 2
            ? "캠페인"
            : socialType === 3
            ? "채널"
            : socialType === 4
            ? `커뮤니티`
            : ""}
        </SocialTypeText>
        <Image
          alt="대표 이미지"
          src={
            socialId &&
            process.env.REACT_APP_RESOURCE_HOST + "/media/social/" + imageName
          }
        ></Image>
      </ImageWrapper>

      <TextWrapper>
        <Top>
          <p className="title">{title}</p>
        </Top>
        <DescInfoWrap>{scDesc}</DescInfoWrap>
        <JoinInfoWrap className={section}>
          {/* <HashtagWrapper>
            {hashtags &&
              hashtags
                .split(" ")
                .filter((_, index) => index < 3)
                .map((hashtag, index) => (
                  <Hashtag key={index}>{hashtag}</Hashtag>
                ))}
          </HashtagWrapper> */}
          <InfoWrap style={{ display: "flex" }}>
            <HeartBox>
              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <img src={like ? heart : emptyHeart} alt="" />
              </a>
              <h3>{likeCount}</h3>
            </HeartBox>
            <MemberCountBox>
              <img src={participated ? iconMemberActive : iconMember} alt="" />
              <h3>{memberCount}</h3>
            </MemberCountBox>
            {/* 피드의 개수 표기  */}
            <PostCountBox>
              <img
                src={postCnt > 0 ? iconPostCountActive : iconPostCount}
                alt=""
              />
              <h3>{postCnt}</h3>
            </PostCountBox>
          </InfoWrap>
        </JoinInfoWrap>
      </TextWrapper>
    </ItemWrapper>
  );
}

const ItemWrapper = styled.div`
  display: flex;

  /* padding-bottom: 10px; */

  /* border: 1px solid #dcdcdc;
  border-radius: 10px; */
  width: 100%;
  padding: 5px 0;
  gap: 10px;

  ${({ index }) => {
    if (index === 0) {
      return css`
        /* margin-top: 6px; */
      `;
    }
    if (index === 3) {
      return css`
        border-bottom: none;
      `;
    }
  }};

  cursor: pointer;
`;

const InfoWrap = styled.div`
  display: flex;
  align-items: flex-end;
`;

const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* min-height: 100%; */
`;

const Top = styled.div`
  display: flex;

  font-style: normal;
  font-weight: 500;
  /* font-size: 16px; */
  line-height: normal;
  color: #292929;
  .title {
    max-width: 400px;
    overflow: hidden;
    word-break: break-all;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 25px;
    line-height: 1.8;
    color: #141414;

    /* 반응형 */
    @media screen and (max-width: 1230px) {
      font-size: 14px;
    }
  }

  /* HomeContentitemDefault2 에 적용 */
  font-size: 15px;
`;

const DescInfoWrap = styled.div`
  /* font-size: 13px; */
  font-weight: 400;
  /* color: #666; */
  line-height: 18px;
  width: 95%;
  /* height: 30px; */
  /* height: 39px; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-top: 5px;
  margin-bottom: 8px;

  /* HomeContentitemDefault2 에 적용 */
  font-size: 12px;
  height: 35px;
  color: #999696;

  /* 반응형 */
  @media screen and (max-width: 1230px) {
    font-size: 12px;
  }
`;

const JoinInfoWrap = styled.div`
  display: flex;
`;

const PostCountBox = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;

  /* width: 60px; */
  width: 40px;

  margin-right: 2px;
  & > :first-child {
    margin-right: 2px;
  }
  img {
    width: 14px;
    height: 12.84px;
  }
  @media screen and (min-width: 481px) {
    font-size: 10px;

    h3 {
      font-size: 12px;
      font-weight: 400;
      color: #999696;
    }
  }
`;

const HeartBox = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* width: 50px; */
  /* width: 40px; */
  width: 32px;
  height: 14px;

  margin-right: 2px;
  & > :first-child {
    margin-right: 2px;
  }
  img {
    width: 14px;
    height: 12.84px;
  }

  @media screen and (min-width: 481px) {
    font-size: 10px;

    h3 {
      font-size: 12px;
      font-weight: 400;
      color: #999696;
    }
  }
`;

const MemberCountBox = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  margin-right: 2px;
  /* width: 50px; */
  /* width: 40px; */
  width: 32px;

  & > :first-child {
    margin-right: 2px;
  }
  img {
    width: 14px;
    height: 12.84px;
  }
  @media screen and (min-width: 481px) {
    font-size: 10px;

    h3 {
      font-size: 12px;
      font-weight: 400;
      color: #999696;
    }
  }
`;

const HashtagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  flex: 1 0 inherit;
  width: 100%;
  max-height: 30px;

  align-items: flex-end;

  @media screen and (min-width: 481px) {
    overflow: hidden;
  }
`;

const Hashtag = styled.p`
  display: block;
  height: 16px;
  max-width: 99px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-weight: 600;
  font-size: 10px;
  line-height: 14px;

  color: #3773ff;

  margin-right: 5px;

  @media screen and (min-width: 481px) {
    font-size: 10px;
  }
`;

const ImageWrapper = styled.div`
  margin-left: auto;
  flex: 0 0 auto;
  overflow: hidden;

  display: flex;

  position: relative;
`;

const SocialTypeText = styled.p`
  border-radius: 20px;

  padding: 3px 8px;
  display: inline-block;
  font-weight: 700;
  font-size: 12px;
  color: #fff;

  position: absolute;
  top: 5px;
  left: 5px;

  ${({ type }) => {
    if (type === "테마") {
      return css`
        background-color: #8e57a0 !important;
      `;
    } else if (type === "캠페인") {
      return css`
        background-color: #3498db !important;
      `;
    } else if (type === "커뮤 니티") {
      return css`
        background-color: #e9971e !important;
      `;
    } else if (type === "채널") {
      return css`
        background-color: #2fa55d !important;
      `;
    }
  }};
`;

const Image = styled.img`
  /* width: 100%;
  height: 100%; */
  /* width: 140px; */
  /* height: 101px; */
  border-radius: 5px;
  object-fit: cover;

  /* HomeContentitemDefault2 에 적용 */
  width: 130px;
  height: 90px;

  /* 반응형 */
  @media screen and (max-width: 1230px) {
    width: 120px;
  }
`;
