import React, { useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import ToastMessageBox from "./ToastMessageBox";

export default function QuickJeobButton({ setPreviewImg, previewImg }) {
  const [toastMessage, setToastMessage] = useState("");
  const fileRef = useRef();

  useEffect(() => {
    if (!previewImg) {
      if (fileRef && fileRef.current) fileRef.current.value = null;
    }
  }, [previewImg]);

  const handleFileUpload = (e) => {
    const file = Array.from(e.target.files)[0];
    if (handleFileValidate(file)) {
      setPreviewImg({
        id: uuid(),
        file: file,
        url: URL.createObjectURL(file),
        type: file.type.split("/")[0],
        upload: true,
        thumbnail: "Y",
        changeThumbnail: false,
      });
    }
  };

  const handleFileValidate = (file) => {
    if (!file) {
      alert(
        "이미지 또는 동영상 파일이 정상적으로 선택되지 않았습니다. 다시 업로드를 진행해주세요."
      );
      return false;
    }

    if (!file.type.includes("image") && !file.type.includes("video")) {
      alert("이미지 또는 동영상 파일만 업로드 가능합니다.");
      return false;
    }
    if (file.size > 1024 * 1024 * 50) {
      alert("50MB 이하의 파일만 업로드 가능합니다.");
      return false;
    }

    return true;
  };

  return (
    //   0315 퀵제보 button tag에서 input label 테그로 변경
    //   onClick 이벤트 추가함 (확인용)
    <>
      {toastMessage && (
        <ToastMessageBox
          toastMessage={toastMessage}
          setToastMessage={setToastMessage}
        />
      )}
      <div>
        <input
          className="quickJeboIconInput"
          id="quickJeobIcon"
          type="file"
          ref={fileRef}
          onClick={(e) => (e.target.value = null)}
          onChange={handleFileUpload}
          accept="image/* video/*;"
          capture={true}
        />
        <label htmlFor="quickJeobIcon" className="btn-camera"></label>
      </div>
    </>
  );
}
