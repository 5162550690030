import React, { createContext } from "react";

export const isMobileContext = createContext({
  isMobile: false,
  mobileType: "others",
});

export default function BrowserEnvContextProvider({
  isMobile,
  children: Components,
}) {
  return (
    <isMobileContext.Provider value={isMobile}>
      {Components}
    </isMobileContext.Provider>
  );
}
