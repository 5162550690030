import jeboApi from "api/api";
import AlertLayer from "components/layers/popMenu/AlertLayer";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import "assets/styles/Calendar.css";
import LoadingComponent from "components/templates/LoadingComponent";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useHistory } from "react-router-dom";

import { useRef } from "react";
import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";
/* 테스트이미지 */
import testImg from "assets/imgs/calendar_test_img.png";

export default function MobileAttendanceContainer() {
  const now = new Date();

  const { myInfo, handleSignOutApp } = useContext(signAppContext);

  const { setSignLayer } = useContext(signLayerContext);

  const [loading, setLoading] = useState(false);
  const [showAlertLayer, setShowAlertLayer] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [focusDate, setFocusDate] = useState(new Date());

  const [checkComplete, setCheckComplete] = useState([]);
  const [attendanceType, setAttendanceType] = useState("W");

  const [hasCheck, setHasCheck] = useState(false);
  const [eventImage, setEventImage] = useState();

  const history = useHistory();

  const attendanceCheckList = (checkList) => {
    let markDate = [];
    checkList.map((check) => {
      markDate.push(check.checkDate);
      check.checkDate === moment(now).format("YYYY-MM-DD") && setHasCheck(true);
    });
    setCheckComplete(markDate);
  };

  const getAttendanceInfo = useCallback(
    async (response) => {
      try {
        setLoading(true);
        const { data, status } = await jeboApi.getAttendanceInfo(
          myInfo.memberUid,
          now.getFullYear(),
          now.getMonth() + 1,
          moment(now).format("W"),
          attendanceType
        );

        if (status === 200) {
          const resultList = data.attendanceList;
          const imageInfo = data.eventImageInfo;

          //이벤트 이미지 정보
          setEventImage(imageInfo.eventImageFileName);
          //출석체크 정보
          resultList.length > 0 && attendanceCheckList(resultList);
        }
      } catch (error) {
        if (error.response) {
          console.log(error.response);
          if (error.response.status === 403) {
            alert("세션이 만료되었습니다. 다시 로그인 해주세요.");
            handleSignOutApp();
            setSignLayer("signIn");
            history.replace({ pathname: "/" });
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [myInfo]
  );

  const regAttendanceInfo = useCallback(async (response) => {
    if (checkComplete.includes(moment(now).format("YYYY-MM-DD"))) {
      setAlertMessage("출석체크는 하루 한 번 가능합니다.");
      setShowAlertLayer(true);
      return;
    }
    try {
      setLoading(true);

      const { data, status } = await jeboApi.regAttendanceInfo(
        myInfo.memberUid,
        now.getFullYear(),
        now.getMonth() + 1,
        moment(now).format("W"),
        moment(now).format("YYYY-MM-DD"),
        attendanceType
      );
      if (status === 200) {
        const resultList = data.attendanceList;
        const checkCountForWeek = data.attendanceCheckCount;

        resultList.length > 0 && attendanceCheckList(resultList);
        setHasCheck(true);
        if (checkCountForWeek === 7) {
          setAlertMessage(
            "1주일 출석체크 완료! 이벤트는 자동으로 응모 됩니다."
          );
          setShowAlertLayer(true);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    window.innerHeight >= 760 &&
      (window.document.body.style.overflowY = "hidden");
    getAttendanceInfo();
  }, [focusDate]);

  return createPortal(
    <Wrap>
      <Modal>
        <ModalHeader>
          <Button onClick={(e) => history.goBack()}></Button>
          <Title>출석체크</Title>
        </ModalHeader>
        <ModalBody>
          <Container>
            <AttendentDesc>
              매일매일 제보 출석 체크하고 상품 받아가세요! <br />
              100% 출석 완료 시, 추첨을 통해 선물을 드립니다.
            </AttendentDesc>
            <AttendentContent>
              <AttendentEvent>
                <span>이달의 출석 완료 선물!</span>
                <AttendentGift>
                  <img
                    alt="경품이미지"
                    src={
                      process.env.REACT_APP_RESOURCE_HOST +
                      "/images/event/" +
                      eventImage
                    }
                  />
                </AttendentGift>
              </AttendentEvent>
              <AttendentCheck>
                <AttendentCheckBtn
                  onClick={(e) => {
                    e.preventDefault(e);
                    regAttendanceInfo();
                  }}
                >
                  {checkComplete && hasCheck ? "출석완료" : "출석인증하기"}
                </AttendentCheckBtn>
              </AttendentCheck>
              <AttendentCalendar>
                <Calendar
                  calendarType="US"
                  formatDay={(locale, date) => moment(date).format("DD")}
                  navigationLabel={(label) =>
                    ("0" + (1 + now.getMonth())).slice(-2) + "월"
                  }
                  value={focusDate}
                  tileClassName={({ date, view }) => {
                    if (
                      checkComplete.find(
                        (x) => x === moment(date).format("YYYY-MM-DD")
                      )
                    ) {
                      return moment(date).format("YYYY-MM-DD") ===
                        moment(now).format("YYYY-MM-DD")
                        ? "today_highlight"
                        : "highlight";
                    }
                  }}
                />
              </AttendentCalendar>
            </AttendentContent>
          </Container>
        </ModalBody>
        {showAlertLayer && (
          <AlertLayer
            setShowAlertLayer={setShowAlertLayer}
            title={alertMessage}
            description=""
            callback={() => {}}
          />
        )}
        {loading && (
          <div className="modal-wrap open">
            <LoadingComponent />
          </div>
        )}
      </Modal>
    </Wrap>,
    document.getElementById("modal")
  );
}

const openModal = keyframes`
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
`;

const Modal = styled.div`
  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;

  width: 100%;
  max-width: 100%;
  height: 100% !important;
  max-height: 100vh !important;

  position: relative;
  background: #fff;
  transform: translateY(0);
  opacity: 0;
`;

const ModalHeader = styled.div`
  display: flex !important;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 7px;
  padding-right: 16px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;
`;

const Title = styled.strong`
  display: inline-block;
  width: auto;
  font-size: 1rem;
  color: var(--text-defaultBlackColor);
  font-weight: 500;
  /* line-height: 50px; */
  line-height: normal !important;
  width: 70%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-self: flex-end;
`;

const Button = styled.button`
  display: block;
  width: 27px;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background: url(/static/media/img_pre.2990dcb3.svg) no-repeat;
  background-color: #fff;
  background-position: left center;
`;

const ModalBody = styled.div`
  height: calc(100% - 120px);
  max-height: none !important;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  max-width: 100%;
  padding-top: 20px;
`;

const AttendentDesc = styled.div`
  width: 100%;
  text-align: center;
`;

const AttendentContent = styled.div`
  margin-top: 30px;
`;

const AttendentEvent = styled.div`
  text-align: center;
  font-size: 150%;
  font-weight: bold;
  text-decoration: underline 1px;
`;

const AttendentGift = styled.div`
  padding: 15px;
`;

const AttendentCheck = styled.div`
  text-align: center;
  padding-top: 20px;
  padding-bottom: 40px;
`;

const AttendentCheckBtn = styled.button`
  border-radius: 15px;
  height: 5vh;
  width: 45vw;
  font-size: 130%;
  font-weight: bold;
`;

const AttendentCalendar = styled.div`
  background: url(${testImg});
`;
