import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { currentLocationContext } from "components/providers/LocationContextProvider";

import redMarkerImg from "../../../assets/imgs/icons/redmarker.svg";
import { feedsContext } from "components/providers/FeedContextProvider";

const debouncer = (() => {
  let debounceCheck;

  return {
    debounce(callback, milliseconds) {
      clearTimeout(debounceCheck);
      debounceCheck = setTimeout(() => {
        callback(...arguments);
      }, milliseconds);
    },
  };
})();

export default function MapSection({
  markerPositions,
  setMapRect,
  setMapRegion,
  searchPosition,
  setCallFromClusterer,
}) {
  const kakaoMapContainer = useRef();
  const kakao = window.kakao;

  const { isMobile } = useContext(isMobileContext);

  const [map, setMap] = useState(null);
  const [customOverlay, setCustomOverlay] = useState(null);
  const { currentLocation } = useContext(currentLocationContext);
  const [originMarkers, setOriginMarkers] = useState([]);

  const coordToRegion = useCallback(
    (latitude, longitude) => {
      const geocoder = new kakao.maps.services.Geocoder();
      geocoder.coord2RegionCode(longitude, latitude, (result, status) => {
        if (status === kakao.maps.services.Status.OK) {
          setMapRegion(
            result[0].region_4depth_name
              ? `${result[0].region_3depth_name} ${result[0].region_4depth_name}`
              : `${result[1].region_2depth_name} ${result[1].region_3depth_name}`
          );
        }
      });
    },
    [kakao, setMapRegion]
  );

  const createCustomOverlayContent = useCallback(
    (count) => {
      const texts = isMobile
        ? `<div class="map-info" style="
    top:-75px;
    left: 5px;"><span class="count"><b>${count}</b>건</span><div>`
        : `<div class="map-info" style="
    top:-90px;
    left: 12px;"><span class="count"><b>${count}</b>건</span><div>`;

      return texts;
    },
    [isMobile]
  );

  const createCustomOveray = useCallback(
    (mapObj) => {
      // 마커 클러스터러를 생성합니다
      const clustererObj = new kakao.maps.MarkerClusterer({
        map: mapObj, // 마커들을 클러스터로 관리하고 표시할 지도 객체
        averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정
        minLevel: 5, // 클러스터 할 최소 지도 레벨
        minClusterSize: 100000,
        disableClickZoom: true,
        styles: [{}],
        texts: createCustomOverlayContent,
      });

      setCustomOverlay(clustererObj);
    },
    [kakao, createCustomOverlayContent]
  );

  window.eventFunction = (index) => {
    const mapRect = {
      northEastLatitude: markerPositions[index].maxLatitude,
      northEastLongitude: markerPositions[index].maxLongitude,
      southWestLatitude: markerPositions[index].minLatitude,
      southWestLongitude: markerPositions[index].minLongitude,
      scale: map.getLevel(),
    };

    /* MapRect가 set되면서 해당하는 좌표에 피드를 새로 호출해야 하는데 
       이때 클러스터가 지워지는 것을 방지하기 위한 Flag (/around/feedSection.js)*/
    setCallFromClusterer(true);
    setMapRect(mapRect);
  };

  window.zoomIn = (index, target) => {
    customOverlay._markers[index].setZIndex(3);

    target.style.width = "90px";
    target.style.height = "55px";
  };

  window.zoomOut = (index, target) => {
    customOverlay._markers[index].setZIndex(0);

    target.style.width = "max-content";
    target.style.height = "40px";
  };

  const clearMarker = () => {
    const onMarkers = originMarkers.map((marker, index) => {
      //지도에서 marker 삭제
      marker.setMap(null);
    });
    setOriginMarkers([]);
  };

  /* (map.getLevel > 2)인 경우 customOverlay로 clusterer  */
  const customMarker = useCallback(() => {
    /* 지도 위 customOverlay || marker 삭제 */
    customOverlay.clear();
    originMarkers && clearMarker();

    const markers = markerPositions.map((feed, index) => {
      var content = isMobile
        ? '<div class="map-info" onclick="eventFunction(' +
          index +
          ')" ontouchstart="zoomIn(' +
          index +
          ',this)" ontouchend="zoomOut(' +
          index +
          ',this)" style="top:-75px; left: 5px;"><span class="count"><b>' +
          feed.jeboId +
          "</b>건</span><div>"
        : '<div class="map-info" onclick="eventFunction(' +
          index +
          ')" onMouseover="zoomIn(' +
          index +
          ',this)" onMouseout="zoomOut(' +
          index +
          ',this)" style="top:-75px; left: 5px;"><span class="count"><b>' +
          feed.jeboId +
          "</b>건</span><div>";

      var marker = new kakao.maps.CustomOverlay({
        position: new kakao.maps.LatLng(feed.latitude, feed.longitude),
        content: content,
      });

      return marker;
    });

    // 클러스터러에 마커들을 추가합니다
    customOverlay.addMarkers(markers, false);

    customOverlay.setTexts(createCustomOverlayContent);
  }, [kakao, customOverlay, markerPositions, createCustomOverlayContent]);

  /* (map.getLevel < 3)인 경우 기본 marker로 그려줌 */
  const originMarker = useCallback(() => {
    /* 지도 위 customOverlay || marker 삭제 */
    customOverlay.clear();
    originMarkers && clearMarker();

    const markers = markerPositions.map((feed, index) => {
      var marker = new kakao.maps.Marker({
        position: new kakao.maps.LatLng(feed.latitude, feed.longitude),
      });

      kakao.maps.event.addListener(marker, "click", function () {
        const mapRect = {
          northEastLatitude: feed.maxLatitude,
          northEastLongitude: feed.maxLongitude,
          southWestLatitude: feed.minLatitude,
          southWestLongitude: feed.minLongitude,
          scale: map.getLevel(),
        };

        setCallFromClusterer(true); //다른 마커들 사라짐 방지
        setMapRect(mapRect);
      });

      marker.setMap(map); //지도에 마커 삽입
      return marker;
    });

    setOriginMarkers(markers);

    // 클러스터러에 마커들을 추가합니다
  }, [kakao, markerPositions]);

  //현재위치 마커 추가
  const currentMarker = (mapObj) => {
    if (mapObj && currentLocation) {
      const markerPositions = new kakao.maps.LatLng(
        currentLocation && currentLocation.latitude,
        currentLocation && currentLocation.longitude
      );

      // 마커 이미지 변경
      const imageSrc = redMarkerImg,
        // "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png"
        // 마커이미지의 주소입니다
        imageSize = new kakao.maps.Size(45, 45),
        // 마커이미지의 크기입니다
        imageOption = { offset: new kakao.maps.Point(27, 69) };
      // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

      // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
      const markerImage = new kakao.maps.MarkerImage(
        imageSrc,
        imageSize,
        imageOption
      );
      // 마커 이미지 변경 0804

      // 마커가 표시될 위치입니다
      const marker = new kakao.maps.Marker({
        position: markerPositions,
        image: markerImage,
        zIndex: 10,
      });
      marker.setMap(mapObj);
    }
  };

  useEffect(() => {
    if (customOverlay) {
      if (map && map.getLevel() < 3) originMarker();
      else customMarker();
    }
  }, [customOverlay, customMarker, originMarker]);

  useEffect(() => {
    //새로고침 혹은 다른 이유로 현재위치 마커가 사라졌을 때
    map && currentLocation && currentMarker(map);
  }, [currentLocation]);

  const initKakaoMap = useCallback(() => {
    const { latitude, longitude } = searchPosition;

    const coords = new kakao.maps.LatLng(latitude, longitude);

    //해당 좌표에 대한 맵 로드
    const mapObj = new kakao.maps.Map(kakaoMapContainer.current, {
      center: coords,
      level: 2,
    });

    /* 현재위치마커 함수로 뺌*/
    currentMarker(mapObj);

    const zoomControl = new kakao.maps.ZoomControl();
    mapObj.addControl(zoomControl, kakao.maps.ControlPosition.BOTTOMRIGHT);
    //

    setMap(mapObj);
    createCustomOveray(mapObj);

    const latlng = mapObj.getCenter();
    coordToRegion(latlng.getLat(), latlng.getLng());

    debouncer.debounce(() => {
      setMapRect({
        northEastLatitude: mapObj.getBounds().getNorthEast().getLat(),
        northEastLongitude: mapObj.getBounds().getNorthEast().getLng(),
        southWestLatitude: mapObj.getBounds().getSouthWest().getLat(),
        southWestLongitude: mapObj.getBounds().getSouthWest().getLng(),
      });
    }, 300);
  }, [
    kakao,
    searchPosition,
    createCustomOveray,
    coordToRegion,
    setMapRect,
    currentLocation,
  ]);

  const handleCenterChange = useCallback(() => {
    // 지도의 중심좌표를 얻어옵니다
    const latlng = map.getCenter();
    // 지도의 현재 레벨을 얻어옵니다
    //const level = map.getLevel();

    coordToRegion(latlng.getLat(), latlng.getLng());

    debouncer.debounce(() => {
      setMapRect({
        northEastLatitude: map.getBounds().getNorthEast().getLat(),
        northEastLongitude: map.getBounds().getNorthEast().getLng(),
        southWestLatitude: map.getBounds().getSouthWest().getLat(),
        southWestLongitude: map.getBounds().getSouthWest().getLng(),
        scale: map.getLevel(),
      });
    }, 500);
  }, [map, setMapRect, coordToRegion]);

  useEffect(() => {
    !map &&
      kakao.maps.load(() => {
        initKakaoMap();
      });
  }, [kakao, map, initKakaoMap]);

  useEffect(() => {
    map &&
      kakao.maps.event.addListener(map, "center_changed", handleCenterChange);

    return () => {
      map &&
        kakao.maps.event.removeListener(
          map,
          "center_changed",
          handleCenterChange
        );
    };
  }, [kakao, map, handleCenterChange]);

  useEffect(() => {
    if (map) {
      map.setLevel(4);
      map.setCenter(
        new kakao.maps.LatLng(searchPosition.latitude, searchPosition.longitude)
      );
    }
  }, [kakao, map, searchPosition]);

  return <div className="map-container" ref={kakaoMapContainer}></div>;
}
