import jeboApi from "api/api";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { useContext, useState } from "react";

export default function FeedReportLayer({ feed, onClose }) {
  const { isLoggedIn, handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const [radioVal, setRadioVal] = useState("");
  const [radioId, setRadioId] = useState("");
  const [etcOpinionField, setEtcOpinionField] = useState("hidden");
  const [opinionWarning, setOpinionWarning] = useState(false);

  const onChangeRadioBtn = (e) => {
    setRadioId(e.target.id);
    setOpinionWarning(false);

    if (e.target.id === "feed_report06") {
      setEtcOpinionField("text");
      setRadioVal("");
    } else {
      setEtcOpinionField("hidden");
      setRadioVal(e.target.value);
    }
  };

  const reportFeed = async () => {
    if (!feed.jeboId || !radioId) return;
    else if (radioId === "feed_report06" && !radioVal) {
      setOpinionWarning(true);
      return;
    }

    if (!isLoggedIn) {
      setSignLayer("signIn");
      return;
    }

    try {
      const { status } = await jeboApi.reportFeed(
        feed.jeboId,
        radioId,
        radioVal
      );

      if (status === 200) alert("해당 게시물이 신고되었습니다.");
    } catch (error) {
      if (error.response) {
        console.log("error : ", error.response);
        if (error.response.status === 403) {
          handleSignOutApp();
          setSignLayer("signIn");
        } else if (error.response.status === 409) {
          alert("이미 신고된 게시물입니다.");
        } else {
          alert("시스템 에러 입니다. 잠시후 다시 시도해 주세요.");
        }
      }
    } finally {
      onClose();
    }
  };

  return (
    <div className="pop-menu item-pop report active">
      <div className="pop-header">
        <strong>신고</strong>
        <button className="close" onClick={() => onClose()}>
          &times;
        </button>
      </div>
      <div className="pop-body">
        <form>
          <ul>
            <li>
              <input
                type="radio"
                name="report2"
                id="feed_report01"
                onChange={onChangeRadioBtn}
                value="음란·청소년 유해 게시물"
                checked={radioId === "feed_report01" ? true : false}
              />
              <label htmlFor="feed_report01">음란·청소년 유해 게시물</label>
            </li>
            <li>
              <input
                type="radio"
                name="report2"
                id="feed_report02"
                onChange={onChangeRadioBtn}
                value="스팸홍보·도배글 게시물"
                checked={radioId === "feed_report02" ? true : false}
              />
              <label htmlFor="feed_report02">스팸홍보·도배글 게시물</label>
            </li>
            <li>
              <input
                type="radio"
                name="report2"
                id="feed_report03"
                onChange={onChangeRadioBtn}
                value="불법내용(도박/사행성)을 담은 게시물"
                checked={radioId === "feed_report03" ? true : false}
              />
              <label htmlFor="feed_report03">
                불법내용(도박/사행성)을 담은 게시물
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="report2"
                id="feed_report04"
                onChange={onChangeRadioBtn}
                value="성적수치심·비방·비하·욕설 게시물"
                checked={radioId === "feed_report04" ? true : false}
              />
              <label htmlFor="feed_report04">
                성적수치심·비방·비하·욕설 게시물
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="report2"
                id="feed_report05"
                onChange={onChangeRadioBtn}
                value="개인정보노출 게시물"
                checked={radioId === "feed_report05" ? true : false}
              />
              <label htmlFor="feed_report05">개인정보노출 게시물</label>
            </li>
            <li>
              <input
                type="radio"
                name="report2"
                id="feed_report06"
                onChange={onChangeRadioBtn}
                value={radioVal}
                checked={radioId === "feed_report06" ? true : false}
              />
              <label htmlFor="feed_report06">기타</label>
            </li>
            <li>
              <input
                type={etcOpinionField}
                id="feed_report06_opinion"
                className={`report-etc-opinion ${
                  opinionWarning && "etc-opinion"
                }`}
                value={radioVal}
                placeholder="사유를 입력해주세요. (20자 내)"
                onChange={(e) => {
                  if (e.target.value.length > 20) return;
                  setRadioVal(e.target.value);
                }}
              />
            </li>
          </ul>
          <div className="btns">
            <button type="button" className="cancel" onClick={() => onClose()}>
              취소
            </button>
            <button
              type="button"
              className="confirm"
              onClick={(e) => {
                reportFeed();
              }}
            >
              확인
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
