import React, { useEffect, useState } from "react";
import jeboApi from "api/api";

export default function RecommendSearchKeywordBox({ handleSearchKeyword }) {
  const [recommendKeywords, setRecommendKeywords] = useState(null);

  useEffect(() => {
    // 추천키워드 조회
    getRecommendList();
  }, []);

  const getRecommendList = async () => {
    try {
      const {
        data: { searchHistoryList },
      } = await jeboApi.getRecommendSearchKeyword();
      setRecommendKeywords(JSON.parse(searchHistoryList));
    } catch (error) {
      if (error.response) {
        const {
          response: { data, status },
        } = error;
        console.log(status, data);
      }
    }
  };

  return (
    <div className="k-panel reco">
      <strong>제보 추천 키워드</strong>
      <ul className="k-list">
        {recommendKeywords &&
          recommendKeywords.length > 0 &&
          recommendKeywords.map((recommend, index) => (
            <li key={index}>
              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSearchKeyword(recommend.keyword);
                }}
              >
                <span>{index + 1}</span>
                {recommend.keyword}
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
}
