import React, { createContext } from "react";

export const feedsContext = createContext({
  feeds: null,
  setFeeds: () => {},
});
export const feedCategoriesContext = createContext({
  feedCategories: [],
});
const feedCategories = [
  { categoryCode: "01", categoryName: "#날씨" },
  { categoryCode: "02", categoryName: "#교통" },
  { categoryCode: "03", categoryName: "#뉴스" },
  { categoryCode: "04", categoryName: "#일상" },
];

export default function FeedContextProvider({ feeds, children: Components }) {
  return (
    <feedCategoriesContext.Provider value={{ feedCategories }}>
      <feedsContext.Provider value={feeds}>{Components}</feedsContext.Provider>
    </feedCategoriesContext.Provider>
  );
}
