import Layers from "components/layers/Layers";
import PasswordChangeLayer from "components/layers/passwordChange/PasswordChangeLayer";
import ResignLayer from "components/layers/resign/ResignLayer";
import SignLayer from "components/layers/sign/SignLayer";
import Header from "components/pages/app/layouts/Header";
import AboutServiceContainer from "components/pages/setting/AboutServiceContainer";
import AccountManageContainer from "components/pages/setting/AccountManageContainer";
import BlockMemberContainer from "components/pages/setting/BlockMemberContainer";
import CollectBadgeContainer from "components/pages/setting/CollectBadgeContainer";
import Sections from "components/pages/setting/layouts/Sections";
import SettingSections from "components/pages/setting/layouts/Sections";
import MobileAboutServiceContainer from "components/pages/setting/mobile/MobileAboutServiceContainer";
import MobileAccountManageContainer from "components/pages/setting/mobile/MobileAccountManageContainer";
import MobileAttendanceContainer from "components/pages/setting/mobile/MobileAttendanceContainer";
import MobileCollectBadgeContainer from "components/pages/setting/mobile/MobileCollectBadgeContainer";
import MobileMyActivity from "components/pages/setting/mobile/MobileMyActivity";
import MobileMyActivityPoint from "components/pages/setting/mobile/MobileMyActivityPoint";
import MobileMyAttend from "components/pages/setting/mobile/MobileMyAttend";
import MobileNoticeListContainer from "components/pages/setting/mobile/MobileNoticeListContainer";
import MobileProfileContainer from "components/pages/setting/mobile/MobileProfileContainer";
import MobilePushAlarmContainer from "components/pages/setting/mobile/MobilePushAlarmContainer";
import MobileSettingMenuContainer from "components/pages/setting/mobile/MobileSettingMenuContainer";
import MyActivityContainer from "components/pages/setting/MyActivityContainer";
import MyAttendContainer from "components/pages/setting/MyAttendContainer";
import MyInfoContainer from "components/pages/setting/MyInfoContainer";
import MyPointInfoContainer from "components/pages/setting/MyPointInfoContainer";
import NoticeListContainer from "components/pages/setting/NoticeListContainer";
import ProfileContainer from "components/pages/setting/ProfileContainer";
import PushAlarmContainer from "components/pages/setting/PushAlarmContainer";
import SettingMainSection from "components/pages/setting/SettingMainSection";
import SettingMenuSection, {
  LegacySettingMenuSection,
} from "components/pages/setting/SettingMenuSection";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import LayerContextProvider from "components/providers/LayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import qs from "query-string";
import { useContext, useEffect, useState } from "react";
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

//신규 모바일 설정 (= 마이페이지 or 활동내역 )
export function MobileSetting() {
  const { isMobile } = useContext(isMobileContext);
  const { myInfo, isLoggedIn } = useContext(signAppContext);
  const [activeMenu, setActiveMenu] = useState("");
  const match = useRouteMatch();
  const history = useHistory();

  const [layer, setLayer] = useState(null);

  return (
    <LayerContextProvider layer={{ layer, setLayer }}>
      <SettingSections>
        {isMobile && <MobileSettingMenuContainer />}
        <Route
          path={`${match.path}/editprofile`}
          render={(props) =>
            isLoggedIn ? <MobileProfileContainer /> : <SignLayer />
          }
        />
        <Route
          path={`${match.path}/reward`}
          render={(props) =>
            isLoggedIn ? <MobileCollectBadgeContainer /> : <SignLayer />
          }
        />
        <Route
          path={`${match.path}/attendance`}
          render={(props) =>
            isLoggedIn ? <MobileAttendanceContainer /> : <SignLayer />
          }
        />
        <Route
          path={`${match.path}/activity`}
          render={(props) =>
            isLoggedIn ? <MobileMyActivity /> : <SignLayer />
          }
        />
        <Route
          path={`${match.path}/pushalarm`}
          render={(props) =>
            isLoggedIn ? <MobilePushAlarmContainer /> : <SignLayer />
          }
        />
        <Route
          path={`${match.path}/activitypoint`}
          render={(props) =>
            isLoggedIn ? <MobileMyActivityPoint /> : <SignLayer />
          }
        />
        <Route
          path={`${match.path}/noticelist`}
          render={(props) =>
            isLoggedIn ? <MobileNoticeListContainer /> : <SignLayer />
          }
        />

        <Route
          path={`${match.path}/aboutservice`}
          render={(props) =>
            isLoggedIn ? <MobileAboutServiceContainer /> : <SignLayer />
          }
        />

        <Route
          path={`${match.path}/accountmanage`}
          render={(props) =>
            isLoggedIn ? <MobileAccountManageContainer /> : <SignLayer />
          }
        />

        <Route
          path={`${match.path}/attend`}
          render={(pops) => (isLoggedIn ? <MobileMyAttend /> : <SignLayer />)}
        />
      </SettingSections>
      <Layers />
    </LayerContextProvider>
  );
}

//신규 설정 페이지 (= 마이페이지 or 활동내역 )
export function Setting() {
  const { isMobile } = useContext(isMobileContext);
  const [activeMenu, setActiveMenu] = useState(isMobile ? "" : "myInfo");
  const match = useRouteMatch();
  const location = useLocation();

  const { noticeId } = qs.parse(location.search);

  useEffect(() => {
    noticeId && setActiveMenu("noticeList");
  }, [noticeId]);

  const [layer, setLayer] = useState(null);

  return (
    <LayerContextProvider layer={{ layer, setLayer }}>
      {!isMobile && <Header />}
      <SettingSections>
        {(!isMobile || (isMobile && !activeMenu)) && (
          <SettingMenuSection
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
          />
        )}

        {(!isMobile || (isMobile && activeMenu)) && (
          <SettingMainSection>
            {activeMenu === "myInfo" && (
              <MyInfoContainer setActiveMenu={setActiveMenu} />
            )}
            {activeMenu === "pointInfo" && (
              <MyPointInfoContainer setActiveMenu={setActiveMenu} />
            )}
            {activeMenu === "profile" && (
              <ProfileContainer setActiveMenu={setActiveMenu} />
            )}

            {activeMenu === "myact" && (
              <MyActivityContainer setActiveMenu={setActiveMenu} />
            )}

            {activeMenu === "myattend" && (
              <MyAttendContainer setActiveMenu={setActiveMenu} />
            )}

            {activeMenu === "pushAlarm" && (
              <PushAlarmContainer setActiveMenu={setActiveMenu} />
            )}
            {activeMenu === "blockMember" && (
              <BlockMemberContainer setActiveMenu={setActiveMenu} />
            )}

            {/* 이용약관 작성 0511 */}
            {activeMenu === "aboutService" && (
              <AboutServiceContainer setActiveMenu={setActiveMenu} />
            )}
            {activeMenu === "accountManage" && (
              <AccountManageContainer setActiveMenu={setActiveMenu} />
            )}
            {activeMenu === "noticeList" && (
              <NoticeListContainer
                setActiveMenu={setActiveMenu}
                noticeId={noticeId}
              />
            )}
          </SettingMainSection>
        )}

        <Route path={`${match.path}/resign`} component={ResignLayer} />
        <Route
          path={`${match.path}/passwdchange`}
          component={PasswordChangeLayer}
        />
      </SettingSections>
    </LayerContextProvider>
  );
}

//기존 설정 페이지 (마이페이지 -> 설정버튼 클릭)
export function LegacySetting() {
  const { isMobile } = useContext(isMobileContext);
  const { myInfo } = useContext(signAppContext);
  const [activeMenu, setActiveMenu] = useState(isMobile ? "" : "profile");
  const match = useRouteMatch();
  const location = useLocation();

  const { noticeId } = qs.parse(location.search);

  useEffect(() => {
    noticeId && setActiveMenu("noticeList");
  }, [noticeId]);

  const [layer, setLayer] = useState(null);

  return (
    <LayerContextProvider layer={{ layer, setLayer }}>
      {!isMobile && <Header />}
      <Sections>
        {(!isMobile || (isMobile && !activeMenu)) && (
          <LegacySettingMenuSection
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
          />
        )}

        {(!isMobile || (isMobile && activeMenu)) && (
          <SettingMainSection>
            {activeMenu === "profile" && (
              <ProfileContainer setActiveMenu={setActiveMenu} />
            )}
            {activeMenu === "collectBadge" && (
              <div className="s-body active" id="s-badge">
                <div className="s-title">
                  <button
                    type="button"
                    className="list-back mobile"
                    onClick={(e) => setActiveMenu("")}
                  ></button>
                  <strong>획득 배지</strong>
                </div>
                <div className="s-inner">
                  <CollectBadgeContainer memberUid={myInfo.memberUid} />
                </div>
              </div>
            )}
            {activeMenu === "pushAlarm" && (
              <PushAlarmContainer setActiveMenu={setActiveMenu} />
            )}
            {activeMenu === "blockMember" && (
              <BlockMemberContainer setActiveMenu={setActiveMenu} />
            )}

            {/* 이용약관 작성 0511 */}
            {activeMenu === "aboutService" && (
              <AboutServiceContainer setActiveMenu={setActiveMenu} />
            )}
            {activeMenu === "accountManage" && (
              <AccountManageContainer setActiveMenu={setActiveMenu} />
            )}
            {activeMenu === "noticeList" && (
              <NoticeListContainer
                setActiveMenu={setActiveMenu}
                noticeId={noticeId}
              />
            )}
          </SettingMainSection>
        )}

        <Route path={`${match.path}/resign`} component={ResignLayer} />
        <Route
          path={`${match.path}/passwdchange`}
          component={PasswordChangeLayer}
        />
      </Sections>
      <Layers />
    </LayerContextProvider>
  );
}
