import styled, { css } from "styled-components";

import iconPet from "../../../assets/imgs/icons/marker_pet_gray.svg";
import iconMedi from "../../../assets/imgs/icons/marker_medi_gray.svg";
import iconCloth from "../../../assets/imgs/icons/marker_cloth_gray.svg";

import iconPetActive from "../../../assets/imgs/icons/marker_pet_blue.svg";
import iconMediActive from "../../../assets/imgs/icons/marker_medi_blue.svg";
import iconClothActive from "../../../assets/imgs/icons/marker_cloth_blue.svg";

export default function SearchNavButton({ item, onClick, keyword }) {
  return (
    <MapListItem
      className={item.hashCode}
      isActive={item.codeName === keyword ? true : false}
      onClick={onClick}
    >
      <MapListItemIcon
        isActive={item.codeName === keyword ? true : false}
        className={item.hashCode}
      />
      {item.codeName.replace("#", "")}
    </MapListItem>
  );
}

const MapListItem = styled.li`
  padding: 0 5px;
  line-height: 30px;
  font-size: 14px;
  background-color: transparent;

  color: #b7c0c9;
  display: flex;
  align-items: center;

  cursor: pointer;

  @media screen and (min-width: 481px) {
    font-size: 16px;
    width: 300px;
    flex: 0 0 110px;
    justify-content: center;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      color: #238cfa;
      font-weight: 600;
    `};
`;

const MapListItemIcon = styled.span`
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-right: 4px;

  @media screen and (min-width: 481px) {
    margin-right: 0px;
  }

  &.B2 {
    background: url(${({ isActive }) => (isActive ? iconMediActive : iconMedi)})
      no-repeat;
    background-size: contain;
  }
  &.C1 {
    background: url(${({ isActive }) =>
        isActive ? iconClothActive : iconCloth})
      no-repeat;
    background-size: contain;
  }
  &.D1 {
    /* background: url(${iconPet}) no-repeat; */
    background: url(${({ isActive }) => (isActive ? iconPetActive : iconPet)})
      no-repeat;
    background-size: contain;
  }
`;
