import LayerContextProvider from "components/providers/LayerContextProvider";

export default function MobileSubscribeContextProvider({
  layer,
  children: pageComponent,
}) {
  return (
    <LayerContextProvider layer={layer}>{pageComponent}</LayerContextProvider>
  );
}
