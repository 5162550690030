import MobileLikeHistory from "components/pages/MobileLikeHistory/MobileLikeHistory";
import MobileUserNotice from "components/pages/mypage/MobileUserNotice";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { useContext } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import FeedDetailLayer from "./detail/FeedDetailLayer";
import LikeHistoryLayer from "./likeHistory/LikeHistoryLayer";
import SignLayer from "./sign/SignLayer";
import FeedAddLayer from "./write/FeedAddLayer";
import FeedEditLayer from "./write/FeedEditLayer";

export default function Layers() {
  const { isLoggedIn } = useContext(signAppContext);
  const { isMobile } = useContext(isMobileContext);
  const match = useRouteMatch();

  return (
    <>
      <Route
        path={`${match.path}/add`}
        render={(props) => (isLoggedIn ? <FeedAddLayer /> : <SignLayer />)}
      />
      <Route
        path={`${match.path}/edit`}
        render={(props) => (isLoggedIn ? <FeedEditLayer /> : <SignLayer />)}
      />
      <Route
        path={`${match.path}/detail/:jeboId`}
        render={(props) => <FeedDetailLayer />}
      />
      {isMobile && (
        <Route
          path={`${match.path}/notification`}
          render={(props) =>
            isLoggedIn ? <MobileUserNotice /> : <SignLayer />
          }
        />
      )}
      <Route
        path={`${match.path}/likeHistory`}
        render={() => (isMobile ? <MobileLikeHistory /> : <LikeHistoryLayer />)}
      />

      <Route path={`${match.path}/signIn`} render={() => <SignLayer />} />
    </>
  );
}
