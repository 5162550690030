import jeboApi from "api/api";
import AuthService from "api/AuthService";
import user_icon from "assets/imgs/icons/user_icon.svg";
import AlertLayer from "components/layers/popMenu/AlertLayer";
import ConfirmLayer from "components/layers/popMenu/ConfirmLayer";
import ProfilePopLayer from "components/layers/popMenu/ProfilePopLayer";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import {
  activeLocationContext,
  currentLocationContext,
} from "components/providers/LocationContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import LoadingComponent from "components/templates/LoadingComponent";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";

export default function MobileProfileContainer({ setActiveMenu }) {
  const { myInfo, setMyInfo, handleSignOutApp } = useContext(signAppContext);
  const { isMobile } = useContext(isMobileContext);
  const { setSignLayer } = useContext(signLayerContext);
  const { setActiveLocation } = useContext(activeLocationContext);
  const { currentLocation, getCurrentPosition } = useContext(
    currentLocationContext
  );
  const [current, setCurrent] = useState(false);

  const [showProfilePopLayer, setShowProfilePopLayer] = useState(false);
  const [nickName, setNickName] = useState(myInfo.nickName);

  const [description, setDescription] = useState(
    myInfo && myInfo.description ? myInfo.description : ""
  );
  const [interTag, setInterTag] = useState(
    myInfo && myInfo.interTag ? myInfo.interTag : ""
  );
  const [interLocations, setInterLocations] = useState(
    myInfo && myInfo.interLocations ? myInfo.interLocations : []
  );
  const [keyword, setKeyword] = useState("");
  const [resultList, setResultList] = useState([]);

  const [showConfirmLayer, setShowConfirmLayer] = useState(false);
  const [showAlertLayer, setShowAlertLayer] = useState(false);

  const kakao = window.kakao;
  const refDescriptionElement = useRef();

  const history = useHistory();

  const [removeToastText, setRemoveToastText] = useState(false);
  const [loading, setLoading] = useState(false);

  const activeRef = useRef(null);

  const ToastTextReturn = (bool) => {
    if (bool) {
      setTimeout((e) => {
        setRemoveToastText(false);
      }, 1500);
    }
  };
  // error 중복 닉네임 확인
  const [errorDesc, setErrorDesc] = useState();

  useEffect(() => {
    if (myInfo) {
      setNickName(myInfo.nickName);
      setDescription(myInfo.description);
      setInterTag(myInfo.interTag);
      setInterLocations(myInfo.interLocations);
    }
  }, [myInfo]);

  useEffect(() => {
    if (currentLocation && current) {
      setActiveLocation({
        latitude: currentLocation.latitude,
        longitude: currentLocation.longitude,
        addressName: currentLocation.address,
        placeName: currentLocation.placeName,
      });
    }
  }, [current, currentLocation, setActiveLocation]);

  const getMyInfo = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.getMyInfo();

      if (status === 200) {
        const resultData = JSON.parse(data.myInfo);
        const {
          memberUid,
          nickName,
          imageUrl,
          interLocations,
          interTag,
          description,
        } = resultData;
        resultData.imageUrl =
          process.env.REACT_APP_RESOURCE_HOST + resultData.imageUrl;
        AuthService.setNickName(nickName);
        // 23.02.14 codelua setMyInfo는 실제 값이 변경 되었다고 판단 될 때만 걸어준다.
        setMyInfo((prev) =>
          JSON.stringify(prev) !== JSON.stringify(resultData)
            ? resultData
            : prev
        );
        // 23.02.13 codelua 관심지역 설정한게 없으면 현위치 세팅.
        const isCurrentLocation =
          interLocations && interLocations.length > 0
            ? interLocations.find((location) => location.selectYn === "Y")
            : null;
        if (!isCurrentLocation) setCurrent(true);
      }
    } catch (error) {
      console.log(error.message);
      if (error.response) {
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  }, [history, handleSignOutApp, setSignLayer, setMyInfo]);

  useEffect(() => {
    getMyInfo();
  }, [getMyInfo]);

  const modifyMyInfo = async () => {
    let saveInterTag = "";
    try {
      //콤마를 공백으로 치환 후 반복되는 공백을 하나의 공백으로 치환

      const replaceInterTag = interTag
        ? interTag.replace(/,/g, " ").replace(/ +/g, " ").trim().split(" ")
        : null;

      replaceInterTag &&
        replaceInterTag.forEach((tagValue) => {
          let sTag = tagValue.charAt(0) !== "#" ? "#" + tagValue : tagValue;
          saveInterTag += saveInterTag !== "" ? " " + sTag : sTag;
        });

      const response = await jeboApi.myInfoModify(
        nickName,
        saveInterTag,
        description ? description.trim() : "",
        interLocations
      );
      if (response.status === 200) {
        const parsedMyInfo = JSON.parse(response.data.myInfo);
        setInterTag(saveInterTag);
        setMyInfo(
          Object.assign({}, myInfo, {
            nickName,
            interTag: saveInterTag,
            description: parsedMyInfo.description,
            interLocations: parsedMyInfo.interLocations,
          })
        );

        const selectedInterLocation =
          parsedMyInfo &&
          parsedMyInfo.interLocations &&
          parsedMyInfo.interLocations.length > 0
            ? parsedMyInfo.interLocations.find(
                (location) => location.selectYn === "Y"
              )
            : null;

        if (selectedInterLocation) {
          setActiveLocation({
            latitude: selectedInterLocation.latitude,
            longitude: selectedInterLocation.longitude,
            addressName: selectedInterLocation.addressName,
            placeName: selectedInterLocation.placeName,
          });
        } else {
          getCurrentPosition(true);
          setCurrent(true);
        }

        setShowAlertLayer(true);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // error 내용 500 === 중복 이름 체크
        if (error.response.status === 500) {
          setErrorDesc("수정 중 에러. 관리자에게 문의해주세요.");
        } else if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  };

  const searchAddrListFromRegionAddr = useCallback(
    (resultData) => {
      const addressSearchObj = new kakao.maps.services.Geocoder();

      addressSearchObj.addressSearch(
        keyword,
        (result, status, pagination) => {
          if (status === kakao.maps.services.Status.OK) {
            const regionList =
              result && result.length > 0
                ? result.map((data) => {
                    return {
                      address_name: data.address_name ? data.address_name : "",
                      road_address_name: data.road_address
                        ? data.road_address.address_name
                        : "",
                      place_name: "",
                      x: data.x,
                      y: data.y,
                    };
                  })
                : null;
            setResultList(
              resultData && resultData.length > 0
                ? resultData.concat(regionList)
                : regionList
            );
          } else {
            if (resultData && resultData.length > 0) {
              setResultList(resultData);
            } else {
              setResultList([]);
            }
          }
        },
        { size: 10 }
      );
    },
    [kakao, keyword]
  );

  const searchAddrListFromKeyword = useCallback(() => {
    const placeSearchObj = new kakao.maps.services.Places();
    placeSearchObj.keywordSearch(
      keyword,
      (result, status, pagination) => {
        //console.log("placeresult : ", result, keyword);
        if (status === kakao.maps.services.Status.OK) {
          const placeList =
            result && result.length > 0
              ? result.map((data) => {
                  return {
                    address_name: data.address_name ? data.address_name : "",
                    road_address_name: data.road_address_name
                      ? data.road_address_name
                      : "",
                    place_name: data.place_name,
                    x: data.x,
                    y: data.y,
                  };
                })
              : null;
          searchAddrListFromRegionAddr(placeList);
        } else {
          status === kakao.maps.services.Status.ZERO_RESULT &&
            searchAddrListFromRegionAddr(null);
        }
      },
      { size: 10, page: 1 }
    );
  }, [kakao, keyword, searchAddrListFromRegionAddr]);

  useEffect(() => {
    keyword ? searchAddrListFromKeyword() : setResultList([]);
  }, [keyword, searchAddrListFromKeyword]);

  useEffect(() => {
    nickName.length === 0 && setErrorDesc("");
  }, [nickName]);

  const handleAddLocation = async (location) => {
    //이미 관심지역리스트에 등록된 위치일 경우 거르는 로직 추가
    let locations = interLocations;
    if (
      locations.some(
        (item) =>
          item.latitude === Number.parseFloat(location.y) &&
          item.longitude === Number.parseFloat(location.x)
      )
    ) {
      locations = locations.map((item) => {
        if (
          item.latitude === Number.parseFloat(location.y) &&
          item.longitude === Number.parseFloat(location.x)
        )
          return Object.assign({}, item, { selectYn: "Y" });

        return Object.assign({}, item, { selectYn: "N" });
      });
      setInterLocations(locations);
    } else {
      locations = locations.map((item) =>
        Object.assign({}, item, { selectYn: "N" })
      );

      let locationInfo = {};
      const { address_name, road_address_name, place_name, x, y } = location;
      locationInfo = {
        addressName: address_name,
        roadAddressName: road_address_name,
        placeName: place_name,
        latitude: y,
        longitude: x,
        selectYn: "Y",
      };

      locations.length >= 20 && locations.shift();
      locations.push(locationInfo);
      setInterLocations(locations);
    }
    setKeyword("");
  };

  const handleRemoveLocation = (seq) => {
    let removeInterLocations = [];
    interLocations.forEach((interLocation) => {
      if (interLocation.locationSeq !== seq)
        removeInterLocations.push(interLocation);
    });
    setInterLocations(removeInterLocations);
  };

  const handleSelectLocation = (seq) => {
    let selectLocations = [];
    // 현위치를 누른 경우 나머지는 무조건 해제 시켜버린다.
    if (seq === 0) {
      setCurrent(true);
    } else {
      setCurrent(false);
    }
    interLocations.forEach((item) => {
      if (item.locationSeq === seq) item.selectYn = "Y";
      else item.selectYn = "N";
      selectLocations.push(item);
    });

    setInterLocations(selectLocations);
  };

  const modifyMyProfile = async (imageFile) => {
    try {
      setShowProfilePopLayer(false);
      setLoading(true);
      const response = await jeboApi.myProfileModify(imageFile);
      if (response.status === 200) {
        const { imageUrl } = JSON.parse(response.data.myInfo);
        setMyInfo(
          Object.assign({}, myInfo, {
            imageUrl: imageUrl
              ? process.env.REACT_APP_RESOURCE_HOST + imageUrl
              : user_icon,
          })
        );
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    } finally {
      // setShowProfilePopLayer(false);
      setLoading(false);
    }
  };

  const checkByte = (value) => {
    //const maxByte = 100; //최대 100바이트
    const text_val = value; //입력한 문자
    const text_len = text_val ? text_val.length : 0; //입력한 문자수

    let totalByte = 0;
    for (let i = 0; i < text_len; i++) {
      const each_char = text_val.charAt(i);
      const uni_char = escape(each_char); //유니코드 형식으로 변환

      //한글 :2byte, 영문,숫자,특수문자 : 1byte
      totalByte = uni_char.length > 4 ? totalByte + 2 : totalByte + 1;
    }

    return totalByte;
  };

  const validateNickname = () => {
    if (!nickName || nickName.length > 10) return false;
    if (checkByte(description) > 100) return false;
    // if (  nickName.match(/[`$~@#%^&|\\=?;:",<>\\{\\}\\[\]\\\\/ ]/gim) || nickName.match(/\s/g) ) 기존 validate
    if (/[^ㄱ-힣-\w().!*'\p{Emoji_Presentation}]/gimu.test(nickName)) {
      return false;
    }

    setShowConfirmLayer(true);
  };

  return createPortal(
    <Wrap>
      <Modal>
        <ModalHeader>
          <Button
            className="page-back mobile"
            onClick={() => {
              history.goBack();
            }}
          ></Button>
          <Title>프로필</Title>
        </ModalHeader>
        <ModalBody>
          <Container>
            <ProfileEdit>
              <ProfilePhoto>
                <ProfileThumb>
                  <Thumb>
                    <ThumbImg src={myInfo.imageUrl} alt="" />
                  </Thumb>
                  {showProfilePopLayer && (
                    <ProfilePopLayer
                      setShowProfilePopLayer={setShowProfilePopLayer}
                      setRemoveToastText={setRemoveToastText}
                      ToastTextReturn={ToastTextReturn}
                      modifyMyProfile={modifyMyProfile}
                    />
                  )}
                  <ThumbEdit
                    onClick={(e) =>
                      setShowProfilePopLayer(!showProfilePopLayer)
                    }
                  ></ThumbEdit>
                </ProfileThumb>
                <NickName>{myInfo.nickName}</NickName>
              </ProfilePhoto>

              <ContentsWrap>
                <WriteItem>
                  <InputTitle>
                    <StrongTtl>닉네임</StrongTtl>
                  </InputTitle>
                  <InputGroup>
                    <InputControl
                      type="text"
                      value={nickName}
                      onChange={(e) => {
                        setNickName(e.target.value);
                      }}
                    />

                    {((!nickName || nickName.length > 10) && (
                      <WarningSpan>
                        한글, 영문, 숫자를 포함하여 10자 이내로 작성하세요.
                      </WarningSpan>
                    )) ||
                      (/[^ㄱ-힣-\w().!*'\p{Emoji_Presentation}]/gimu.test(
                        nickName
                      ) && (
                        <WarningSpan>
                          특수문자 또는 공백은 사용하실 수 없습니다.
                        </WarningSpan>
                      ))}
                    {errorDesc && <WarningSpan>{errorDesc}</WarningSpan>}
                  </InputGroup>
                </WriteItem>
                <WriteItem>
                  <InputTitle>
                    <StrongTtl>
                      자기소개(
                      <span
                        style={{
                          color:
                            checkByte(description) <= 500 ? "green" : "red",
                        }}
                      >
                        {checkByte(description)}
                      </span>
                      /500자)
                    </StrongTtl>
                  </InputTitle>
                  <InputGroup>
                    <TextAreaControl
                      ref={refDescriptionElement}
                      placeholder="자기소개를 입력하세요."
                      style={{ height: "100px" }}
                      onChange={(e) =>
                        checkByte(e.target.value) <= 500 &&
                        setDescription(e.target.value)
                      }
                      value={description}
                    ></TextAreaControl>
                    {checkByte(description) > 500 && (
                      <WarningSpan>
                        자기소개를 500자 이내로 입력하세요.
                      </WarningSpan>
                    )}
                  </InputGroup>
                </WriteItem>
                <WriteItem>
                  <InputTitle>
                    <StrongTtl>관심 지역</StrongTtl>
                  </InputTitle>
                  <InputGroup>
                    <InputControl
                      type="text"
                      value={keyword}
                      placeholder="관심 지역은 최대 20개까지 등록할 수 있습니다."
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                    {keyword && (
                      <button
                        className="clear-btn"
                        onClick={() => {
                          setKeyword("");
                        }}
                      ></button>
                    )}
                    {resultList && resultList.length > 0 && (
                      <div className="search-dropdown open">
                        <ul>
                          {resultList.map((location, index) => {
                            return (
                              <li key={index}>
                                <a
                                  href="#!"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleAddLocation(location);
                                  }}
                                >
                                  {location.place_name
                                    ? `${location.place_name} (${location.address_name})`
                                    : location.address_name}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </InputGroup>
                </WriteItem>
                <WriteItemLoc>
                  <NowPositionItem active={current && "active"}>
                    <NowPositionTitle
                      onClick={(e) => {
                        e.preventDefault();
                        handleSelectLocation(0);
                      }}
                    >
                      현재 위치로 설정
                    </NowPositionTitle>
                  </NowPositionItem>
                  <br />
                  <i></i>
                  {interLocations &&
                    interLocations.length > 0 &&
                    interLocations.map((location, index) => {
                      return (
                        <LocItem
                          key={index}
                          ref={activeRef}
                          active={
                            location.selectYn === "Y" ? "active" : "false"
                          }
                        >
                          <LocSpan
                            onClick={(e) =>
                              handleSelectLocation(location.locationSeq)
                            }
                          >
                            {location.placeName
                              ? `${location.placeName}(${location.addressName})`
                              : `${location.addressName}`}
                          </LocSpan>
                          <LocCancel
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleRemoveLocation(location.locationSeq);
                            }}
                          >
                            <i></i>
                          </LocCancel>
                        </LocItem>
                      );
                    })}
                </WriteItemLoc>
                <WriteItem>
                  <InputTitle>
                    <StrongTtl>관심 태그</StrongTtl>
                  </InputTitle>
                  <InputGroup>
                    <InputControl
                      type="text"
                      placeholder="관심 키워드는 쉼표로 구분해주세요. ex) 함께하는_일상, 동네_맛집"
                      value={interTag}
                      onChange={(e) => {
                        checkByte(e.target.value) <= 100 &&
                          setInterTag(e.target.value);
                      }}
                    />
                    {checkByte(interTag) > 100 && (
                      <WarningSpan>
                        관심 키워드를 100자 이내로 입력하세요.
                      </WarningSpan>
                    )}
                  </InputGroup>
                </WriteItem>
                <WriteBtn>
                  <InputBtn
                    onClick={(e) => {
                      validateNickname();
                      // validateNickname && setShowConfirmLayer(true);
                    }}
                  >
                    수정
                  </InputBtn>
                </WriteBtn>
                {showConfirmLayer && (
                  <ConfirmLayer
                    setShowConfirmLayer={setShowConfirmLayer}
                    callback={modifyMyInfo}
                    title="회원정보를 수정 하시겠습니까?"
                    description=""
                    btnText="수정하기"
                    isConfirm={true}
                  />
                )}
                {showAlertLayer && (
                  <AlertLayer
                    setShowAlertLayer={setShowAlertLayer}
                    title="회원정보가 수정되었습니다."
                    description=""
                    callback={() => {
                      history.goBack();
                    }}
                  />
                )}
                {
                  <div
                    className={
                      removeToastText
                        ? "removeProfilePic-notice active"
                        : "removeProfilePic-notice"
                    }
                  >
                    <p className="removeProfilePic-text">
                      프로필 사진이 삭제되었습니다.
                    </p>
                  </div>
                }
              </ContentsWrap>
            </ProfileEdit>
          </Container>
        </ModalBody>
      </Modal>
      {loading && (
        <div className="modal-wrap open">
          <LoadingComponent />
        </div>
      )}
    </Wrap>,
    document.getElementById("modal")
  );
}

const openModal = keyframes`
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
`;

const Modal = styled.div`
  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;

  width: 100%;
  max-width: 100%;
  height: 100% !important;
  max-height: 100vh !important;

  position: relative;
  background: #fff;
  transform: translateY(0);
  opacity: 0;
`;

const ModalHeader = styled.div`
  display: flex !important;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 7px;
  padding-right: 16px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;
`;

const Title = styled.strong`
  display: inline-block;
  width: auto;
  font-size: 1rem;
  color: var(--text-defaultBlackColor);
  font-weight: 500;
  /* line-height: 50px; */
  line-height: normal !important;
  width: 70%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-self: flex-end;
`;

const Button = styled.button`
  display: block;
  width: 27px;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background: url(/static/media/img_pre.2990dcb3.svg) no-repeat;
  background-color: #fff;
  background-position: left center;
`;

const ModalBody = styled.div`
  height: calc(100% - 50px);
  max-height: none !important;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  max-width: 100%;
  margin: 0;
`;

const ProfileEdit = styled.div`
  padding: 40px 16px;
`;

const ProfilePhoto = styled.div`
  width: 180px !important;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 40px;
`;

const ProfileThumb = styled.div`
  display: inline-block;
  width: 123px;
  height: 123px;
  position: relative;
  margin-bottom: 20px;
`;

const Thumb = styled.span`
  display: inline-block;
  width: 123px;
  height: 123px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;

const ThumbImg = styled.img`
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const ThumbEdit = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 2px;
  right: 2px;
  z-index: 2;
  border-radius: 50%;
  border: none;
  background: url(/static/media/icon_camera.a2d8edc7.svg) no-repeat;
  background-size: 15px 12px;
  background-position: 50%;
  background-color: #e9e9e9;
`;

const NickName = styled.strong`
  color: var(--text-defaultBlackColor);
  font-size: 1rem;
  display: block;
`;

const ContentsWrap = styled.div``;

const WriteItem = styled.div`
  padding: 0;
  border-bottom: 0;
  margin-bottom: 18px;
  background: #fff;
`;

const WriteItemLoc = styled.div`
  margin-bottom: 10px;
  position: relative;
  top: -8px;
`;

const NowPositionItem = styled.div`
  display: inline-block;
  width: auto;
  margin-right: 5px;
  margin-bottom: 7px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  padding-left: 13px;
  padding-right: 30px;
  position: relative;
  cursor: pointer;
  background: ${(props) => props.active === "active" && "#f5f5f5"};
`;

const NowPositionTitle = styled.span`
  font-size: 12px;
  line-height: 33px;
  color: #232323;
  color: var(--text-defaultBlackColor);
  font-weight: 500;
`;

const LocItem = styled.div`
  display: inline-block;
  width: auto;
  margin-right: 5px;
  margin-bottom: 7px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  padding-left: 13px;
  padding-right: 30px;
  position: relative;
  cursor: pointer;
  background: ${(props) => props.active === "active" && "#f5f5f5"};
`;

const LocCancel = styled.a`
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 6px;
  right: 6px;
  background: url(/static/media/exit.9fc98549.svg) no-repeat;
  background-size: 9px 9px;
  background-position: 50%;
  cursor: pointer;
`;

const LocSpan = styled.span`
  font-size: 12px;
  line-height: 33px;
  color: #232323;
  color: var(--text-defaultBlackColor);
  font-weight: 500;
`;

const InputTitle = styled.div`
  margin-bottom: 10px;
`;

const InputGroup = styled.div`
  position: relative;
`;

const WriteBtn = styled.div`
  z-index: 2;
  position: relative !important;
  bottom: 48px;
  width: 100%;
  right: 0;
  flex-direction: column;
  padding: 15px 12px;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InputBtn = styled.button`
  width: 100%;
  border-radius: 3px;
  border: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  min-width: 178px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 1rem;
  background: var(--default-deepBlueColor);
  color: #fff;
  font-weight: 400;
  transition: 0.15s;
`;

const StrongTtl = styled.strong`
  font-size: 0.85rem;
  font-weight: 500;
  color: var(--text-defaultBlackColor);
  width: auto;
  margin-right: 10px;
`;

const InputControl = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 45px;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding-left: 13px;
  position: relative;
  box-sizing: border-box;
  font-size: 14px;

  &::placeholder {
    font-size: 80%;
  }
`;

const TextAreaControl = styled.textarea`
  -webkit-appearance: none;
  width: 100%;
  height: 45px;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding-left: 13px;
  position: relative;
  box-sizing: border-box;
  font-size: 14px;

  &::placeholder {
    font-size: 80%;
  }
`;

const WarningSpan = styled.span`
  display: block;
  color: #cf514a;
  font-size: 12px;
  margin-top: 8px;
`;
