import logo from "assets/imgs/jebo_logo_n.svg";
import { activeLocationContext } from "components/providers/LocationContextProvider";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import redMarkerImg from "../../../assets/imgs/icons/redmarker.svg";

export default function TrafficMapSection() {
  const kakao = window.kakao;
  const { activeLocation } = useContext(activeLocationContext);

  useEffect(() => {
    if (activeLocation) {
      kakao.maps.load(() => {
        const mapContainer = document.getElementById("map"), // 지도를 표시할 div
          mapOption = {
            center: new kakao.maps.LatLng(
              activeLocation.latitude,
              activeLocation.longitude
            ), // 지도의 중심좌표
            level: 4, // 지도의 확대 레벨
          };

        // 마커 이미지 변경
        const imageSrc = redMarkerImg,
          // "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png"
          // 마커이미지의 주소입니다
          imageSize = new kakao.maps.Size(45, 45),
          // 마커이미지의 크기입니다
          imageOption = { offset: new kakao.maps.Point(27, 69) };
        // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

        // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
        const markerImage = new kakao.maps.MarkerImage(
          imageSrc,
          imageSize,
          imageOption
        );
        // 마커 이미지 변경 0804

        const map = new kakao.maps.Map(mapContainer, mapOption); // 지도를 생성합니다
        const zoomControl = new kakao.maps.ZoomControl();
        const markerPositions = new kakao.maps.LatLng(
          activeLocation.latitude,
          activeLocation.longitude
        );
        const marker = new kakao.maps.Marker({
          position: markerPositions,
          image: markerImage,
        });
        map.setCenter(
          new kakao.maps.LatLng(
            activeLocation.latitude,
            activeLocation.longitude
          )
        );
        // 지도에 교통정보를 표시하도록 지도타입을 추가합니다
        map.addOverlayMapTypeId(kakao.maps.MapTypeId.TRAFFIC);
        map.addControl(zoomControl, kakao.maps.ControlPosition.BOTTOMRIGHT);
        marker.setMap(map);
      });
    }
  }, [kakao, activeLocation]);

  return (
    <>
      {" "}
      <Link className="TrafficMapSection_logo" to={{ pathname: "/" }}>
        <img src={logo} alt="제보"></img>
      </Link>{" "}
      <div className="map-container" id="map"></div>
    </>
  );
}
