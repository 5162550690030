import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { subscribeMessageContext } from "components/providers/SubscribeMessageContextProvider";
import { Base64 } from "js-base64";
import { useContext } from "react";
import { Link, Route, useHistory, useRouteMatch } from "react-router-dom";

import SignLayer from "components/layers/sign/SignLayer";
import UserNotice from "components/pages/mypage/UserNotice";
import { useCallback, useEffect, useState } from "react";
import alarm from "../../assets/imgs/icons/icon_notice.svg";

export default function HeaderMenuContainer() {
  const { isLoggedIn, myInfo } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const { isMobile, mobileType } = useContext(isMobileContext);
  const { isNewMessage, subscribeNotification } = useContext(
    subscribeMessageContext
  );

  const [isNoticeClicked, setIsNoticeClicked] = useState(false);

  const history = useHistory();
  const match = useRouteMatch();

  const clearModal = useCallback((e) => {
    !e.target.closest(".webUserNotice") && setIsNoticeClicked(false);
  }, []);

  useEffect(() => {
    // 다른 영역 클릭 시 닫기
    window.addEventListener("click", clearModal);
    return () => window.removeEventListener("click", clearModal);
  }, [clearModal]);

  return (
    <div className="top-menu">
      <div className="mobile">
        <Link className="btn-search" to={{ pathname: "/search" }}></Link>
      </div>
      {isLoggedIn ? (
        <>
          {!isMobile && (
            <>
              <a
                href="#!"
                className={isNewMessage ? "menu-dm active" : "menu-dm"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!isLoggedIn) {
                    setSignLayer("signIn");
                    return;
                  }
                  isMobile
                    ? history.push("/directMessage")
                    : window.open("/directMessage", "_blank");
                }}
              >
                <span></span>
              </a>
            </>
          )}
          <div className="my-box menu-open">
            <button
              className="my-account userNotice"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (isLoggedIn && isMobile) {
                  setSignLayer("");
                  history.push({
                    pathname: `${match.url}/notification`,
                  });
                } else if (isLoggedIn && !isMobile) {
                  history.push({
                    pathname: `${match.url}/notification`,
                  });
                } else {
                  setSignLayer("signIn");
                }
              }}
            >
              <span className="userNotice__contents">
                <img src={alarm} alt="알람"></img>
              </span>

              {subscribeNotification &&
                subscribeNotification.notReadCount > 0 && (
                  <span className="userNotice__count active">
                    {subscribeNotification.notReadCount > 9
                      ? `9+`
                      : subscribeNotification.notReadCount}
                  </span>
                )}
            </button>

            <button
              className="my-account"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (isLoggedIn) {
                  setSignLayer("");
                  history.push({
                    /* 1. /myact - 신규 마이페이지, /mypage - 기존 마이페이지 
                       2. 신/구 마이페이지 Path 변경 시, (mypage)ProfileSection.js 에 아래 내용 적용
                        2-1) 신규 마이페이지 - '설정'버튼 주석,  기존 마이페이지 - '설정'버튼 주석해제 */

                    // pathname: "/myact",
                    pathname: "/mypage",
                    state: {
                      memberUid: myInfo.memberUid,
                    },
                  });
                } else {
                  setSignLayer("signIn");
                }
              }}
            >
              <span className="thumb">
                {/* <img src={m_setting} alt="설정" /> */}
                <img src={myInfo.imageUrl} alt="마이페이지" />
              </span>
            </button>
            {!isMobile && (
              <Route
                path={`${match.url}/notification`}
                render={(props) =>
                  isLoggedIn ? <UserNotice /> : <SignLayer />
                }
              />
            )}
            {/* {isNoticeClicked && <UserNotice />} */}
          </div>
        </>
      ) : (
        <>
          <button
            onClick={(e) => {
              setSignLayer("signIn");
            }}
          >
            로그인
          </button>
          <button
            onClick={(e) => {
              setSignLayer("signUp");
            }}
          >
            회원가입
          </button>
          <button
            onClick={() => {
              const url = "https://guide.jebo.io/";
              if (mobileType === "ios") {
                const encodedUrl = Base64.encode(url);
                window.webkit.messageHandlers.openOuterLink.postMessage(
                  encodedUrl
                );
              } else {
                window.open(url);
              }
            }}
          >
            블로그
          </button>

          <button
            className="sign-pop mobile"
            onClick={(e) => {
              setSignLayer("signIn");
            }}
          >
            <span>로그인 후 다양한 서비스 이용이 가능합니다.</span>
            <i className="icon-arrow-right8"></i>
          </button>
        </>
      )}
    </div>
  );
}
