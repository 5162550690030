import SignLayer from "components/layers/sign/SignLayer";
import MobileNavigator from "components/pages/app/layouts/MobileNavigator";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import Around from "pages/Around";
import DirectMessage from "pages/DirectMessage";
import Home from "pages/Home";
import Member from "pages/Member";
import MobileSubscribe from "pages/MobileSubscribe";

import NaverLoginSuccess from "pages/NaverLogin/NaverLoginSuccess";
import Search from "pages/Search";
import { Setting, MobileSetting, LegacySetting } from "pages/Setting";
import TrafficMap from "pages/TrafficMap";
import qs from "query-string";
import { useCallback, useContext, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Campaign from "./Campaign";

import Detail from "./Detail";
import KakaoLoginSuccess from "./kakaoLogin/KakaoLoginSuccess";
import Mypage from "./Mypage";
import Social from "./Social";

export default function Router() {
  const { signLayer, setSignLayer } = useContext(signLayerContext);
  const { isLoggedIn } = useContext(signAppContext);
  const { isMobile } = useContext(isMobileContext);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setSignLayer("");
  }, [location, setSignLayer]);

  const movePageIOS = useCallback(
    (encodedUrl) => {
      const jsonUrl = window.atob(encodedUrl);
      const { url } = JSON.parse(jsonUrl);

      if (url) {
        const fullUrl = new URL(url.replace("#!", ""));
        const search = qs.parse(fullUrl.search);
        const prevSearch = qs.parse(location.search);
        const queryString = qs.stringify(Object.assign({}, search, prevSearch));

        //let pathname = originUrl.replaceAll(process.env.REACT_APP_HOST, "");
        let pathname = fullUrl.pathname;
        history.push({
          pathname,
          search: queryString ? `?${queryString}` : "",
        });
      }
    },
    [history, location]
  );

  useEffect(() => {
    window.movePageIOS = movePageIOS;
    return () => {
      window.movePageIOS = () => {};
    };
  }, [movePageIOS]);

  return (
    <>
      <Switch>
        <Route
          path="/naverLoginSuccess"
          render={(props) => <NaverLoginSuccess />}
        />
        <Route
          path="/kakaoLoginSuccess"
          render={(props) => <KakaoLoginSuccess />}
        />
        <Route path="/p/:jeboId" render={(props) => <Detail />} />
        <Route path="/social" render={(props) => <Social />} />
        <Route path="/campaign" render={(props) => <Campaign />} />

        <Route
          path="/directMessage/:communicationId?"
          render={(props) => (isLoggedIn ? <DirectMessage /> : <SignLayer />)}
        />
        <Route path="/trafficMap" render={(props) => <TrafficMap />} />
        <Route path="/around" render={(props) => <Around />} />

        <Route path="/search/:keyword?" render={(props) => <Search />} />
        <Route path="/member/:nickName?" render={(props) => <Member />} />

        {/* START 기존 마이페이지 START */}
        <Route
          path="/mypage"
          render={(props) => (isLoggedIn ? <Mypage /> : <SignLayer />)}
        />
        <Route
          path="/setting"
          render={(props) => (isLoggedIn ? <LegacySetting /> : <SignLayer />)}
          // render={(props) => (isLoggedIn ? <Setting /> : <SignLayer />)}
        />
        {/* END 기존 마이페이지 END */}

        {/* START 신규 마이페이지 START */}
        <Route
          path="/myact"
          render={(props) =>
            isLoggedIn ? (
              isMobile ? (
                <MobileSetting />
              ) : (
                <Setting />
              )
            ) : (
              <SignLayer />
            )
          }
        />
        {/* END 신규 마이페이지 END */}

        <Route
          path="/mobileSubscribe/:memberUid/:type"
          render={(props) => <MobileSubscribe />}
        />
        <Route path="/home" render={(props) => <Home />} />
        <Redirect from="*" to="/home" />
      </Switch>

      {signLayer && <SignLayer />}
      <MobileNavigator />
    </>
  );
}
