import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import QuickJeboButton from "./QuickJeboButton";

export default function SideNavButton({ setPreviewImg, previewImg }) {
  const { isLoggedIn } = useContext(signAppContext);
  const { isMobile } = useContext(isMobileContext);

  const history = useHistory();
  const match = useRouteMatch();

  const [showSideNavigator, setShowSideNavigator] = useState(false);

  const handleScroll = useCallback((e) => {
    const scrollTop = document.scrollingElement.scrollTop;
    if (scrollTop > window.innerHeight / 2) setShowSideNavigator(true);
    else setShowSideNavigator(false);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll, true);
  }, [handleScroll]);

  const pageScrollTop = (e) => {
    document.scrollingElement.scrollTo({ top: 0, behavior: "smooth" });

    /*
    const duration = 300;
    // cancel if already on top
    if (document.scrollingElement.scrollTop === 0) return;

    const totalScrollDistance = document.scrollingElement.scrollTop;
    let scrollY = totalScrollDistance,
      oldTimestamp = null;

    const step = (newTimestamp) => {
      if (oldTimestamp !== null) {
        // if duration is 0 scrollY will be -Infinity
        scrollY -=
          (totalScrollDistance * (newTimestamp - oldTimestamp)) / duration;
        if (scrollY <= 0) return (document.scrollingElement.scrollTop = 0);
        document.scrollingElement.scrollTop = scrollY;
      }
      oldTimestamp = newTimestamp;
      window.requestAnimationFrame(step);
    };
    window.requestAnimationFrame(step);
    */
  };

  return (
    <div className="btn-tools active">
      {showSideNavigator && (
        <button
          className="btn-addFeed pc"
          onClick={(e) => {
            e.preventDefault();
            history.push({
              pathname: `${match.url}/add`,
            });
          }}
        ></button>
      )}
      {isMobile && isLoggedIn && (
        <QuickJeboButton
          setPreviewImg={setPreviewImg}
          previewImg={previewImg}
        />
      )}
      {showSideNavigator && (
        <button className="btn-top" onClick={pageScrollTop}></button>
      )}
    </div>
  );
}
