import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import FindPasswordLayer from "../findPassword/FindPasswordLayer";
import SignInLayerForm from "./SignInLayerForm";
import SignUpMain from "./SignUpMain";

export default function SignLayer() {
  const { signLayer, setSignLayer } = useContext(signLayerContext);
  const [showFindPasswordLayer, setShowFindPasswordLayer] = useState(false);
  const history = useHistory();

  // useEffect(() => {
  //   document.getElementsByTagName("html")[0].classList.add("hidden");
  //   window.document.body.classList.add("hidden");
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("hidden");
  //     window.document.body.classList.remove("hidden");
  //   };
  // }, []);

  return createPortal(
    <div className="modal-wrap open">
      {showFindPasswordLayer ? (
        <FindPasswordLayer
          setShowFindPasswordLayer={setShowFindPasswordLayer}
        />
      ) : (
        <div className="modal modal-sign open">
          <div className="modal-header">
            <div className="sign-tab-btns">
              <button
                type="button"
                className={`sign-tab-btn signin ${
                  signLayer !== "signUp" && "active"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setSignLayer("signIn");
                }}
              >
                로그인
              </button>
              <button
                type="button"
                className={`sign-tab-btn signup ${
                  signLayer === "signUp" && "active"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setSignLayer("signUp");
                }}
              >
                회원가입
              </button>
            </div>
            <button
              className="modal-close"
              onClick={(e) => {
                e.preventDefault();
                setSignLayer("");
                !signLayer && history.goBack();
              }}
            >
              <i className="icon-cross"></i>
            </button>
          </div>
          {signLayer === "signUp" ? (
            <SignUpMain />
          ) : (
            <SignInLayerForm
              setShowFindPasswordLayer={setShowFindPasswordLayer}
            />
          )}
        </div>
      )}
    </div>,
    document.getElementById("modal")
  );
}
