import styled, { css } from "styled-components";
import jeboLogo from "assets/imgs/icons/icon_jebologo.svg";
export default function SocialConfirmPop({
  title,
  message,
  onClick,
  onCancel,
  category,
}) {
  return (
    <ConfirmContainer category={category}>
      <ConfirmPopup>
        <ConfirmPopupUpper>
          <img alt="제보로고" src={jeboLogo}></img>
          <ConfirmText>
            <span>
              <strong>{title}</strong>
            </span>
            {message}
          </ConfirmText>
        </ConfirmPopupUpper>
        <ConfirmPopupLower>
          <button onClick={onClick}>확인</button>
          <button onClick={onCancel}>취소</button>
        </ConfirmPopupLower>
      </ConfirmPopup>
    </ConfirmContainer>
  );
}

const ConfirmContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.55);
  /* ${({ category }) => {
    if (category === "channel") {
      return css`
        position: fixed;
      `;
    }
  }} */
`;

const ConfirmPopup = styled.div`
  width: 327px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.29));

  background: #ffffff;
  border-bottom: 1px solid #dcdcdc;
  border-radius: 20px;

  z-index: 2;
`;

const ConfirmPopupUpper = styled.div`
  height: 181px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: 33px;
  }
`;

const ConfirmPopupLower = styled.div`
  border-top: 1px solid #dcdcdc;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    background-color: transparent;
    color: #797979;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    width: 100%;
    height: 100%;
  }
`;

const ConfirmText = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  padding: 0 20px;
  span {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  strong {
    -webkit-line-clamp: 3;
    font-weight: 600;
    word-break: break-all;
  }
`;
