import user_icon from "assets/imgs/icons/user_icon.svg";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import React, { useContext, useEffect, useState } from "react";
import DirectMessageFeedCard from "./DirectMessageFeedCard";
import MetaInfo from "./MetaInfo";

export function DirectMessageCardTo({ message, scrollRef }) {
  const [convertTime, setConvertTime] = useState(null);
  const urlMessage = message.message;
  const { isMobile } = useContext(isMobileContext);
  let urlStr = [];

  useEffect(() => {
    let time = message.createAt.split(" ")[1];
    let hours = time.split(":")[0];
    let min = time.split(":")[1];
    let type = hours > 12 ? "오후" : "오전";

    setConvertTime(
      type + " " + (hours > 12 ? hours - 12 : hours) + "시" + min + "분"
    );
  }, [message]);

  const moveScroll = () => {
    isMobile
      ? window.scrollTo({ left: 0, top: scrollRef.current?.scrollHeight })
      : document
          .querySelector(".msg-list")
          .scrollTo(0, scrollRef.current?.scrollHeight);
  };

  urlMessage &&
    urlMessage.split(/([#@http(s)][^\s\n]+)/g).map((word) => {
      if (
        word.match(
          /((^http(s)?:\/\/)([\w]+[\.:]*)+[\w]{2})([\/\w-%#?&=])+(\.[\w]{2,4})*/g
        )
      ) {
        urlStr.push(
          word.match(
            // /((^http(s)?:\/\/)([\w]+[\.:]*)+[\w]{2})([\/\w-%#?&=])+(\.[\w]{2,4})*/g
            /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}([\/a-z0-9-%#?&=\w])+(\.[a-z0-9]{2,4}(\?[\/a-z0-9-%#?&=\w]+)*)*/g
          )
        );
      }
    });

  return (
    <div className="dm-box left">
      <div className="thumb">
        <img
          src={
            message.toMemberImageUrl
              ? process.env.REACT_APP_RESOURCE_HOST + message.toMemberImageUrl
              : user_icon
          }
          alt={message.toMemberNickName}
        />
      </div>
      <div className="msg">
        {message.jeboPostVo && (
          <DirectMessageFeedCard feed={message.jeboPostVo} />
        )}
        <div className="line">
          <span className="bubble">
            {message.message}
            {urlStr && urlStr.length > 0 && (
              <MetaInfo
                urlMessage={urlStr[0]}
                viewMode={"V"}
                callBack={moveScroll}
              />
            )}
          </span>
          <span className="time">{convertTime}</span>
        </div>
      </div>
    </div>
  );
}

export default React.memo(DirectMessageCardTo);
