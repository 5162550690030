import { convenienceDataContext } from "components/providers/ConvenienceDataContextProvider";
import { useContext } from "react";
import { useEffect } from "react";

export default function EditorContainer({
  setHashTags,
  targetCategory,
  setCategories,
  contents,
  setContents,
  eventInfo,
  editorRef,
  conName,
}) {
  useEffect(() => {
    //const regex = /#[^/!@#$%^&*(),.?":{}|<>=\s\n]+/g;
    //const regex = hashtagRegex();
    const regex = /#[^#\s\n]+/g;
    let array1;

    const foundHashtags = [];
    while ((array1 = regex.exec(contents)) !== null) {
      const tag = array1[0];
      //해시태그 세팅
      !foundHashtags.includes(tag) && foundHashtags.push(tag);
    }

    if (foundHashtags && foundHashtags.length > 0) {
      setHashTags(foundHashtags);
      //카테고리 체크 세팅
      setCategories((prev) =>
        prev.map((category) => {
          return Object.assign({}, category, {
            checked: foundHashtags.includes(category.categoryName),
          });
        })
      );
    } else {
      setHashTags([]);
      setCategories((prev) =>
        prev.map((category) => {
          return Object.assign({}, category, {
            checked: false,
          });
        })
      );
    }
  }, [contents, setHashTags, setCategories]);

  useEffect(() => {
    if (targetCategory) {
      if (targetCategory.checked) {
        setHashTags((prev) => [...prev, targetCategory.categoryName]);
        setContents((prev) => `${prev} ${targetCategory.categoryName} `);
      } else {
        //const regex = /#[^/!@#$%^&*(),.?":{}|<>=\s\n]+/g;
        //const regex = hashtagRegex();
        const regex = /#[^#\s\n]+/g;
        let array1;

        setHashTags((prev) =>
          prev.filter((tag) => tag !== targetCategory.categoryName)
        );
        setContents((prev) => {
          let current = prev;
          while ((array1 = regex.exec(current)) !== null) {
            if (array1[0] === targetCategory.categoryName) {
              current = current.replaceAll(
                ` ${targetCategory.categoryName} `,
                ""
              );
            }
          }
          return current;
        });
      }
    }
  }, [targetCategory, setContents, setHashTags]);
  return (
    <div className="write-item">
      <div className="input-title">
        <strong className="ttl">내용</strong>
        {/* <span className="desc">*#태그 입력 가능</span> */}
        {/* <div className="">
          <p children>자주쓰는테그</p>
          <ul className="">
            <li className="">1</li>
            <li className="">2</li>
            <li className="">3</li>
          </ul>
        </div> */}
        {eventInfo && eventInfo.eventShortsDesc && (
          <div className="campaignHashTag-ct">
            {eventInfo.eventShortsDesc
              .split(" ")
              .map((eventShortsDesc, index) => {
                return (
                  <span key={index} className="campaignHashTag__text">
                    {eventShortsDesc}
                  </span>
                );
              })}
          </div>
        )}
        {/*searchMap에서 전달받은 conName 있으면 노출*/}
        {conName && <span className="campaignHashTag__text">{conName}</span>}
      </div>
      <div className="input-group text">
        <textarea
          placeholder="내용을 입력해주세요."
          ref={editorRef}
          autoCapitalize="none"
          aria-autocomplete="none"
          autoCorrect="off"
          autoComplete="off"
          spellCheck="false"
          className="text-area"
          style={{
            fontSize: "13px",
            lineHeight: "20px",
            height: "100%",
            padding: "10px 15px",
            minHeight: "100px",
          }}
          onChange={(e) => {
            if (e.target.value.length > 10000) return;
            setContents(e.target.value);
          }}
          value={contents}
        ></textarea>
      </div>
    </div>
  );
}
