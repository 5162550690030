import jeboApi from "api/api";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory, useRouteMatch } from "react-router-dom";

import iconGrayX from "../../../assets/imgs/icons/icon_x_gray.svg";
import user_icon from "assets/imgs/icons/user_icon.svg";
import { subscribeMessageContext } from "components/providers/SubscribeMessageContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import SwipeToDelete from "react-swipe-to-delete-component";

const regDateCalculate = (date) => {
  const year = date.split(" ")[0].split("-")[0];
  const month = date.split(" ")[0].split("-")[1] - 1;
  const day = date.split(" ")[0].split("-")[2];
  const hours = date.split(" ")[1].split(":")[0];
  const seconds = date.split(" ")[1].split(":")[1];

  const now = new Date();
  const timeValue = new Date(year, month, day, hours, seconds);

  let betweenTime = Math.floor(
    (now.getTime() - timeValue.getTime()) / 1000 / 60
  );
  let betweenTimeHour = Math.floor(betweenTime / 60);
  let betweenTimeDay = Math.floor(betweenTime / 60 / 24);

  if (betweenTime < 1) {
    return "방금전";
  } else if (betweenTime < 60) {
    return `${betweenTime}분전`;
  } else if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간전`;
  } else {
    return `${betweenTimeDay}일전`;
  }
};

export default function MobileUserNotice() {
  const { isMobile, mobileType } = useContext(isMobileContext);

  const { handleSignOutApp } = useContext(signAppContext);

  const { subscribeNotification, setSubscribeNotification } = useContext(
    subscribeMessageContext
  );

  const [hasNotice, setHasNotice] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [readNotifications, setReadNotifications] = useState([]);
  const [notReadNotifications, setNotReadNotifications] = useState([]);

  const history = useHistory();

  const modalRef = useRef();

  // useEffect(() => {
  //   document.getElementsByTagName("html")[0].classList.add("hidden");
  //   window.document.body.classList.add("hidden");
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("hidden");
  //     window.document.body.classList.remove("hidden");
  //   };
  // }, []);

  useEffect(() => {
    try {
      if (mobileType === "android") {
        window.androidApp.clearBadge();
      } else if (mobileType === "ios") {
        window.webkit.messageHandlers.clearBadge.postMessage("");
      }
    } catch (error) {
      console.log(error);
    }
  }, [mobileType]);

  useEffect(() => {
    loadNotification();

    return () => {
      setSubscribeNotification({ notReadNotifications: [], notReadCount: 0 });
      updateReadState();
    };
  }, []);

  useEffect(() => {
    subscribeNotification &&
      subscribeNotification.notReadNotifications &&
      subscribeNotification.notReadNotifications.length > 0 &&
      setNotReadNotifications((prev) => [
        ...subscribeNotification.notReadNotifications,
      ]);
  }, [subscribeNotification]);

  useEffect(() => {
    setHasNotice(
      (readNotifications && readNotifications.length > 0) ||
        (notReadNotifications && notReadNotifications.length > 0)
        ? true
        : false
    );
  }, [readNotifications, notReadNotifications]);

  const updateReadState = () => {
    jeboApi
      .updateReadStateNotification()
      .then(({ status }) => {
        if (status === 200) {
          console.log("성공");
          setReadNotifications([...notReadNotifications, ...readNotifications]);
          setNotReadNotifications([]);
        } else if (status === 204) {
          console.log("no data to be loaded..");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          if (error.response.status === 403) {
            //alert("세션이 만료되었습니다. 다시 로그인해주세요");
            handleSignOutApp();
          }
        }
      });
  };

  const loadNotification = async () => {
    try {
      setLoading(true);

      const { status, data } = await jeboApi.loadNotification();

      if (status === 200) {
        const readNotifications = JSON.parse(data.readList);
        setReadNotifications(readNotifications);

        //const notReadNotifications = JSON.parse(data.notReadList);
        //setNotReadNotifications(notReadNotifications);
      } else if (status === 204) {
        console.log("no data to be loaded..");
        setHasNotice(false);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요");
          handleSignOutApp();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const clearNotification = async () => {
    try {
      setLoading(true);

      const { status } = await jeboApi.deleteNotification("");

      if (status === 200) {
        console.log("삭제 성공");

        setNotReadNotifications([]);
        setReadNotifications([]);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요");
          handleSignOutApp();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const removeNotification = async (activitiesId) => {
    try {
      setLoading(true);

      const { status } = await jeboApi.deleteNotification(activitiesId);

      if (status === 200) {
        console.log("삭제 성공");
        setNotReadNotifications((prev) =>
          prev.filter(
            (notification) => notification.activitiesId !== activitiesId
          )
        );
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요");
          handleSignOutApp();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return createPortal(
    <div
      className="modal-wrap open"
      ref={modalRef}
      onMouseDown={(e) => {
        !isMobile &&
          e.target === modalRef.current &&
          history.replace({ pathname: "/" });
      }}
    >
      <div className="modal modal-detail open">
        <div className="modal-header mobile">
          <button
            type="button"
            className="page-back mobile"
            onClick={() => {
              history.goBack();
            }}
          ></button>
          <strong>알림</strong>
        </div>
        <div className="modal-body">
          <section className="section-profile">
            <div className="container">
              <div
                className="profile-box mobileUserNoticeCt"
                style={hasNotice ? { paddingTop: "30px" } : null}
              >
                {!hasNotice ? (
                  <div className="noNoticeCt">
                    <p className="noNotice__desc">수신된 알림이 없습니다</p>
                  </div>
                ) : (
                  <div className="userNoticeContainer">
                    {notReadNotifications &&
                      notReadNotifications.length > 0 && (
                        <div className="currentNoticeCt noticeCt">
                          <div className="noticeBtnContainer current">
                            <p className="noNotice__desc">미확인 알림</p>
                            <button
                              className="clearNoticeBtn"
                              // onClick={clearNotification}
                              onClick={updateReadState}
                            >
                              모두 확인 처리
                            </button>
                          </div>
                          {notReadNotifications.map((notice) => (
                            <SwipeToDelete
                              key={notice.activitiesId}
                              deleteSwipe={0.3}
                              onDelete={() => {
                                removeNotification(notice.activitiesId);
                              }}
                            >
                              <Notification
                                key={notice.activitiesId}
                                notice={notice}
                                setNotification={setNotReadNotifications}
                              />
                            </SwipeToDelete>
                          ))}
                        </div>
                      )}

                    {readNotifications && readNotifications.length > 0 && (
                      <div
                        className="pastNoticeCt noticeCt"
                        style={
                          notReadNotifications.length === 0
                            ? { border: "none" }
                            : {}
                        }
                      >
                        <div className="noticeBtnContainer">
                          <p className="noNotice__desc">확인 알림</p>
                          {(readNotifications ||
                            notReadNotifications.length === 0) && (
                            <button
                              className="clearNoticeBtn"
                              onClick={clearNotification}
                            >
                              모두 삭제 처리
                            </button>
                          )}
                        </div>
                        {readNotifications.map((notice) => (
                          <SwipeToDelete
                            key={notice.activitiesId}
                            deleteSwipe={0.3}
                            onDelete={() => {
                              removeNotification(notice.activitiesId);
                            }}
                          >
                            <Notification
                              key={notice.activitiesId}
                              notice={notice}
                              setNotification={setReadNotifications}
                            />
                          </SwipeToDelete>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>,
    document.getElementById("modal")
  );
}

const Notification = ({ notice, setNotification }) => {
  if (notice.type === "FOLLOW")
    return (
      <FollowNotice
        key={notice.activitiesId}
        notice={notice}
        setNotification={setNotification}
      />
    );
  else if (notice.type === "FEED")
    return (
      <FeedNotice
        key={notice.activitiesId}
        notice={notice}
        setNotification={setNotification}
      />
    );
  else if (notice.type === "FEED_UP")
    return (
      <FeedUpNotice
        key={notice.activitiesId}
        notice={notice}
        setNotification={setNotification}
      />
    );
  else if (notice.type === "COMMENT")
    return (
      <CommentNotice
        key={notice.activitiesId}
        notice={notice}
        setNotification={setNotification}
      />
    );
  else if (notice.type === "COMMENT_UP")
    return (
      <CommentUpNotice
        key={notice.activitiesId}
        notice={notice}
        setNotification={setNotification}
      />
    );
  else if (notice.type === "RECOMMENT")
    return (
      <ReCommentNotice
        key={notice.activitiesId}
        notice={notice}
        setNotification={setNotification}
      />
    );
  else if (notice.type === "MENTION")
    return (
      <MentionNotice
        key={notice.activitiesId}
        notice={notice}
        setNotification={setNotification}
      />
    );
  // 공지사항 기능 잠금 23.03.06
  // else if (notice.type === "NOTICE")
  //   return (
  //     <AdminNotice
  //       key={notice.activitiesId}
  //       notice={notice}
  //       setNotification={setNotification}
  //     />
  //   );
  else return <></>;
};

const NoticeDeleteAction = async (notice, setNotification) => {
  const { handleSignOutApp } = useContext(signAppContext);
  // setLoading(true);
  try {
    const response = await jeboApi.deleteNotification(notice.activitiesId);
    if (response.status === 200) {
      console.log("삭제 성공");
      setNotification((prev) =>
        prev.filter(
          (notification) => notification.activitiesId !== notice.activitiesId
        )
      );
    }
  } catch (error) {
    if (error.response) {
      console.log(error.response);
      if (error.response.status === 403) {
        alert("세션이 만료되었습니다. 다시 로그인해주세요");
        handleSignOutApp();
      }
    }
  } finally {
    // setLoading(false);
  }
};

// function NoticeDeleteButton({ notice, setNotification }) {
//   const [loading, setLoading] = useState(false);
//   const { handleSignOutApp } = useContext(signAppContext);

//   const removeNotification = async (activitiesId) => {
//     try {
//       setLoading(true);

//       const { status } = await jeboApi.deleteNotification(activitiesId);

//       if (status === 200) {
//         console.log("삭제 성공");
//         setNotification((prev) =>
//           prev.filter(
//             (notification) => notification.activitiesId !== activitiesId
//           )
//         );
//       }
//     } catch (error) {
//       if (error.response) {
//         console.log(error.response);
//         if (error.response.status === 403) {
//           alert("세션이 만료되었습니다. 다시 로그인해주세요");
//           handleSignOutApp();
//         }
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <button
//       className="noticeDeleteBtn"
//       onClick={(e) => {
//         removeNotification(notice.activitiesId);
//       }}
//     >
//       <img alt="" src={iconGrayX}></img>
//     </button>
//   );
// }

function FollowNotice({ notice, setNotification }) {
  const { handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const match = useRouteMatch();

  const history = useHistory();

  const regFollow = async (memberUid) => {
    try {
      const { status, data } = await jeboApi.regFollow(memberUid);

      if (status === 200) {
        setNotification((prev) =>
          prev.map((notification) =>
            notification.fromMemberUid === memberUid
              ? Object.assign({}, notification, { followingId: data.followId })
              : notification
          )
        );
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  };

  const removeFollow = async (followId) => {
    try {
      const { status } = await jeboApi.removeFollow(followId);

      console.log(status);
      if (status === 200) {
        setNotification((prev) =>
          prev.map((notification) =>
            notification.followingId === followId
              ? Object.assign({}, notification, { followingId: null })
              : notification
          )
        );
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  };

  return (
    <div className="unitNoticeCt">
      <img
        className="memberImg"
        src={
          notice.memberImageUrl
            ? process.env.REACT_APP_RESOURCE_HOST + notice.memberImageUrl
            : user_icon
        }
        alt=""
      />
      <p
        className="notice__text"
        onClick={(e) => {
          history.push({
            pathname: `/member/${encodeURIComponent(notice.nickName)}`,
          });
        }}
      >
        <span className="notice__userNick">[{notice.nickName}]</span>
        님이
        <span className="notice__desc">회원님을 구독했습니다.</span>
        <span className="notice__time">
          {regDateCalculate(notice.createAt)}
        </span>
      </p>
      <div className="notice__content">
        {notice.followingId ? (
          <button
            className="notice__subBtn active"
            onClick={(e) => {
              e.preventDefault();
              removeFollow(notice.followingId);
            }}
          >
            구독 취소
          </button>
        ) : (
          <button
            className="notice__subBtn"
            onClick={(e) => {
              e.preventDefault();
              regFollow(notice.fromMemberUid);
            }}
          >
            구독 하기
          </button>
        )}
        {/* <NoticeDeleteButton notice={notice} setNotification={setNotification} /> */}
      </div>
    </div>
  );
}

function CommentNotice({ notice, setNotification }) {
  const history = useHistory();
  const match = useRouteMatch();

  return (
    <div className="unitNoticeCt">
      <img
        className="memberImg"
        src={
          notice.memberImageUrl
            ? process.env.REACT_APP_RESOURCE_HOST + notice.memberImageUrl
            : user_icon
        }
        alt=""
      />

      <p
        className="notice__text"
        onClick={(e) => {
          history.push({
            pathname: `${match.url.replace("notification", "")}detail/${
              notice.jeboId
            }`,
            search: `?focusType=comment&focusId=${notice.commentId}`,
          });
        }}
      >
        <span className="notice__userNick">[{notice.nickName}]</span>
        님이
        <span className="notice__desc">
          회원님의 게시물에 댓글을 남겼습니다.
        </span>
        <span className="notice__time">
          {regDateCalculate(notice.createAt)}
        </span>
      </p>

      <div className="notice__content">
        {notice.noticeImageUrl && (
          <img
            className="notice__pic"
            src={process.env.REACT_APP_RESOURCE_HOST + notice.noticeImageUrl}
            alt=""
          />
        )}

        {/* <NoticeDeleteButton notice={notice} setNotification={setNotification} /> */}
      </div>
    </div>
  );
}

function ReCommentNotice({ notice, setNotification }) {
  const history = useHistory();
  const match = useRouteMatch();
  return (
    <div className="unitNoticeCt">
      <img
        className="memberImg"
        src={
          notice.memberImageUrl
            ? process.env.REACT_APP_RESOURCE_HOST + notice.memberImageUrl
            : user_icon
        }
        alt=""
      />

      <p
        className="notice__text"
        onClick={(e) => {
          history.push({
            pathname: `${match.url.replace("notification", "")}detail/${
              notice.jeboId
            }`,
            search: `?focusType=comment&focusId=${notice.commentId}`,
          });
        }}
      >
        <span className="notice__userNick">[{notice.nickName}]</span>
        님이
        <span className="notice__desc">회원님의 댓글에 답글을 남겼습니다.</span>
        <span className="notice__time">
          {regDateCalculate(notice.createAt)}
        </span>
      </p>

      <div className="notice__content">
        {notice.noticeImageUrl && (
          <img
            className="notice__pic"
            src={process.env.REACT_APP_RESOURCE_HOST + notice.noticeImageUrl}
            alt=""
          />
        )}

        {/* <NoticeDeleteButton notice={notice} setNotification={setNotification} /> */}
      </div>
    </div>
  );
}

function CommentUpNotice({ notice, setNotification }) {
  const history = useHistory();
  const match = useRouteMatch();
  return (
    <div className="unitNoticeCt">
      <img
        className="memberImg"
        src={
          notice.memberImageUrl
            ? process.env.REACT_APP_RESOURCE_HOST + notice.memberImageUrl
            : user_icon
        }
        alt=""
      />

      <p
        className="notice__text"
        onClick={(e) => {
          history.push({
            pathname: `${match.url.replace("notification", "")}detail/${
              notice.jeboId
            }`,
            search: `?focusType=comment&focusId=${notice.commentId}`,
          });
        }}
      >
        <span className="notice__userNick">[{notice.nickName}]</span>
        님이
        <span className="notice__desc">회원님의 댓글을 공감합니다.</span>
        <span className="notice__time">
          {regDateCalculate(notice.createAt)}
        </span>
      </p>

      <div className="notice__content">
        {notice.noticeImageUrl && (
          <img
            className="notice__pic"
            src={process.env.REACT_APP_RESOURCE_HOST + notice.noticeImageUrl}
            alt=""
          />
        )}

        {/* <NoticeDeleteButton notice={notice} setNotification={setNotification} /> */}
      </div>
    </div>
  );
}

function FeedUpNotice({ notice, setNotification }) {
  const history = useHistory();
  const match = useRouteMatch();
  return (
    <div className="unitNoticeCt">
      <img
        className="memberImg"
        src={
          notice.memberImageUrl
            ? process.env.REACT_APP_RESOURCE_HOST + notice.memberImageUrl
            : user_icon
        }
        alt=""
      />

      <p
        className="notice__text"
        onClick={(e) => {
          history.push({
            pathname: `${match.url.replace("notification", "")}detail/${
              notice.jeboId
            }`,
          });
        }}
      >
        <span className="notice__userNick">[{notice.nickName}]</span>
        님이
        <span className="notice__desc">회원님의 게시물에 공감합니다.</span>
        <span className="notice__time">
          {regDateCalculate(notice.createAt)}
        </span>
      </p>

      <div className="notice__content">
        {notice.noticeImageUrl && (
          <img
            className="notice__pic"
            src={process.env.REACT_APP_RESOURCE_HOST + notice.noticeImageUrl}
            alt=""
          />
        )}
        {/* <NoticeDeleteButton notice={notice} setNotification={setNotification} /> */}
      </div>
    </div>
  );
}

function FeedNotice({ notice, setNotification }) {
  const history = useHistory();
  const match = useRouteMatch();
  return (
    <div className="unitNoticeCt">
      <img
        className="memberImg"
        src={
          notice.memberImageUrl
            ? process.env.REACT_APP_RESOURCE_HOST + notice.memberImageUrl
            : user_icon
        }
        alt=""
      />

      <p
        className="notice__text"
        onClick={(e) => {
          history.push({
            pathname: `${match.url.replace("notification", "")}detail/${
              notice.jeboId
            }`,
          });
        }}
      >
        <span className="notice__userNick">[{notice.nickName}]</span>
        님이
        <span className="notice__desc">새로운 게시물을 등록했습니다.</span>
        <span className="notice__time">
          {regDateCalculate(notice.createAt)}
        </span>
      </p>

      <div className="notice__content">
        {notice.noticeImageUrl && (
          <img
            className="notice__pic"
            src={process.env.REACT_APP_RESOURCE_HOST + notice.noticeImageUrl}
            alt=""
          />
        )}

        {/* <NoticeDeleteButton notice={notice} setNotification={setNotification} /> */}
      </div>
    </div>
  );
}

function MentionNotice({ notice, setNotification }) {
  const history = useHistory();
  const match = useRouteMatch();
  return (
    <div className="unitNoticeCt">
      <img
        className="memberImg"
        src={
          notice.memberImageUrl
            ? process.env.REACT_APP_RESOURCE_HOST + notice.memberImageUrl
            : user_icon
        }
        alt=""
      />

      <p
        className="notice__text"
        onClick={(e) => {
          history.push({
            pathname: `${match.url.replace("notification", "")}detail/${
              notice.jeboId
            }`,
            search: `?focusType=comment&focusId=${notice.commentId}`,
          });
        }}
      >
        <span className="notice__userNick">[{notice.nickName}]</span>
        님이
        <span className="notice__desc">회원님을 언급했습니다.</span>
        <span className="notice__time">
          {regDateCalculate(notice.createAt)}
        </span>
      </p>

      <div className="notice__content">
        {notice.noticeImageUrl && (
          <img
            className="notice__pic"
            src={process.env.REACT_APP_RESOURCE_HOST + notice.noticeImageUrl}
            alt=""
          />
        )}
        {/* <NoticeDeleteButton notice={notice} setNotification={setNotification} /> */}
      </div>
    </div>
  );
}

function AdminNotice({ notice, setNotification }) {
  const history = useHistory();

  return (
    <div className="unitNoticeCt">
      <img
        className="memberImg"
        src={
          notice.memberImageUrl
            ? process.env.REACT_APP_RESOURCE_HOST + notice.memberImageUrl
            : user_icon
        }
        alt=""
      />

      <p
        className="notice__text"
        onClick={(e) => {
          e.preventDefault();
          history.push({
            pathname: "/myact/noticelist",
            search: `?noticeId=${notice.noticeId}`,
          });
          // history.push({
          //   pathname: `/setting`,
          //   search: `?noticeId=${notice.noticeId}`,
          // });
        }}
      >
        <span className="notice__userNick">[{notice.nickName}]</span>
        님이
        <span className="notice__desc">새로운 공지사항을 올렸습니다.</span>
        <span className="notice__time">
          {regDateCalculate(notice.createAt)}
        </span>
      </p>

      <div className="notice__content">
        {notice.noticeImageUrl && (
          <img
            className="notice__pic"
            src={process.env.REACT_APP_RESOURCE_HOST + notice.noticeImageUrl}
          />
        )}
        {/* <NoticeDeleteButton notice={notice} setNotification={setNotification} /> */}
      </div>
    </div>
  );
}
