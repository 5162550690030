import styled from "styled-components";
import IconDelete from "assets/imgs/icons/icon_x_gray.svg";

export default function SearchList({
  searchListRef,
  recentKeywords,
  removeKeyword,
  handleSearchKeyword,
  setIsSearcing,
}) {
  console.log(setIsSearcing);
  return (
    <SearchResult ref={searchListRef}>
      <SearchResultHeader>
        <p>최근 검색어</p>
        <button
          onClick={() => {
            removeKeyword();
          }}
        >
          전체 삭제
        </button>
        {/* <button>닫기</button> */}
      </SearchResultHeader>
      <RecentResult>
        <RecentResultList>
          {recentKeywords.length >= 1 ? (
            recentKeywords &&
            recentKeywords.map((keyword, index) => (
              <li key={index}>
                <div className="recentResult-info">
                  <span>{index + 1}</span>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSearchKeyword(keyword);
                    }}
                  >
                    {keyword}
                  </a>
                  <DeleteButton
                    onClick={() => {
                      removeKeyword(keyword);
                    }}
                  ></DeleteButton>
                </div>
              </li>
            ))
          ) : (
            <EmptyRecentResult>최근 검색 기록이 없습니다.</EmptyRecentResult>
          )}
          {}
        </RecentResultList>
      </RecentResult>
    </SearchResult>
  );
}

const SearchResult = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fff;
  padding: 0 9px;
`;

const SearchResultHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #1e2530;
    @media screen and (min-width: 481px) {
      font-size: 16px;
    }
  }
  button {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #a3a3a3;
    background-color: transparent;
    @media screen and (min-width: 481px) {
      font-size: 14px;
    }
  }
  button:nth-child(2) {
    margin-left: auto;
  }
`;

const RecentResult = styled.div`
  min-height: 55%;
`;

const RecentResultList = styled.ul`
  padding: 0 10px;
  .recentResult-info {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 22px;
    span {
      margin-right: 9px;
      font-weight: 600;

      color: #1a78c9;
    }
    a {
      font-weight: 400;
      color: #1e2530;
    }
    @media screen and (min-width: 481px) {
      font-size: 16px;
    }
  }
`;

const DeleteButton = styled.button`
  width: 10px;
  height: 10px;
  margin-left: auto;

  background: url(${IconDelete}) no-repeat;
  background-size: contain;
`;

const EmptyRecentResult = styled.p`
  font-size: 12px;
  margin-top: 150px;

  color: #a3a3a3;
  text-align: center;

  @media screen and (min-width: 481px) {
    font-size: 16px;
  }
`;
