import jeboApi from "api/api";
import user_icon from "assets/imgs/icons/user_icon.svg";
import ConfirmLayer from "components/layers/popMenu/ConfirmLayer";
import { signAppContext } from "components/providers/SignContextProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function BlockMemberContainer({ setActiveMenu }) {
  const { handleSignOutApp, setSignLayer } = useContext(signAppContext);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [error, setError] = useState(null);
  const [showConfirmLayer, setShowConfirmLayer] = useState(false);

  const [targetMember, setTargetMember] = useState(null);

  const history = useHistory();

  const getBlockMembers = useCallback(async () => {
    try {
      setLoading(true);

      const { status, data } = await jeboApi.blockMemberList();

      if (status === 200) {
        const memberList = JSON.parse(data.blockMemberList);
        setMembers(memberList);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    } finally {
      setLoading(false);
    }
  }, [history, handleSignOutApp, setSignLayer]);

  useEffect(() => {
    getBlockMembers();
  }, [getBlockMembers]);

  const removeBlockMember = async () => {
    try {
      setLoading(true);

      const { status } = await jeboApi.removeBlockMember(
        targetMember.toMemberUid
      );

      if (status === 200) {
        setMembers((prev) =>
          prev.filter(
            (member) => member.toMemberUid !== targetMember.toMemberUid
          )
        );
        setShowConfirmLayer(false);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="s-body active">
        <div className="s-title">
          <button
            type="button"
            className="list-back mobile"
            onClick={(e) => setActiveMenu("")}
          ></button>
          <strong>차단목록</strong>
        </div>
        <div className="s-inner">
          <div className="info-wrap">
            {loading && <></>}
            <div className="subscribe-mobile-popup blockMemberPopup active">
              <div className="tab-subs-ct tab-subscribed blockMemberPopup-ct active">
                <ul className="tab-subs-list blockMember-list">
                  {members &&
                    members.length > 0 &&
                    members.map((member, index) => (
                      <li className="tab-subs-list-item" key={index}>
                        <span
                          className="tab-subs-list-item__pic"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <img
                            src={
                              member.imageUrl
                                ? process.env.REACT_APP_RESOURCE_HOST +
                                  member.imageUrl
                                : user_icon
                            }
                            alt={member.nickName}
                          />
                        </span>
                        <span className="tab-subs-list-item__name">
                          {member.nickName}
                        </span>
                        <button
                          className="btn-edit subscribe-btn"
                          onClick={(e) => {
                            setTargetMember(member);
                            setShowConfirmLayer(true);
                          }}
                        >
                          차단 취소
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmLayer && (
        <ConfirmLayer
          setShowConfirmLayer={setShowConfirmLayer}
          callback={removeBlockMember}
          title={`${targetMember.nickName}님을 차단 취소하시겠습니까?`}
          description=""
          btnText="확인"
          isConfirm={true}
        />
      )}
    </>
  );
}
