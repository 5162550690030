import jeboApi from "api/api";
import { signAppContext } from "components/providers/SignContextProvider";
import CollectBadgeDetailMobile from "components/templates/CollectBadgeDetailMobile";
import qs from "query-string";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory, useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";

export default function MobileCollectBadgeContainer() {
  const history = useHistory();
  const location = useLocation();
  const { memberUid } = qs.parse(location.search);

  const { handleSignOutApp, setSignLayer } = useContext(signAppContext);

  const [memberRewardList, setMemberRewardList] = useState(null);
  const [memberReward, setMemberReward] = useState(null);

  const handleBadgeClick = (memberReward) => {
    setMemberReward(memberReward);
    setModalIsOpen(!modalIsOpen);
  };

  const handleBadgeClickMobile = (isclickBackground) => {
    isclickBackground && setModalIsOpen(!modalIsOpen);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const getMemberRewardList = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.getMemberRewardList(memberUid);

      if (status === 200) {
        setMemberRewardList(JSON.parse(data.memberRewardList));
      }
    } catch (error) {
      console.log("error : ", error);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인 해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  }, [memberUid]);

  useEffect(() => {
    getMemberRewardList();
  }, [getMemberRewardList]);

  return createPortal(
    <Wrap>
      <Modal>
        <ModalHeader>
          <Button onClick={(e) => history.goBack()}></Button>
          <Title>획득 배지</Title>
        </ModalHeader>
        <ModalBody>
          <Container>
            <BadgeItems>
              {memberRewardList &&
                memberRewardList.map((memberReward, index, arr) => {
                  return (
                    <Item
                      key={index}
                      onClick={(e) => {
                        handleBadgeClick(memberReward);
                      }}
                    >
                      <Icon
                        alt="배지"
                        src={
                          process.env.REACT_APP_RESOURCE_HOST +
                          memberReward.rewardImagePath
                        }
                      />
                      <Name>{memberReward.rewardName}</Name>
                      <div className="icon-modal"></div>
                    </Item>
                  );
                })}
            </BadgeItems>

            {modalIsOpen && (
              <CollectBadgeDetailMobile
                handleBadgeClickMobile={handleBadgeClickMobile}
                setModalIsOpen={setModalIsOpen}
                memberReward={memberReward}
              />
            )}
          </Container>
        </ModalBody>
      </Modal>
    </Wrap>,
    document.getElementById("modal")
  );
}

const openModal = keyframes`
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
`;

const Modal = styled.div`
  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;

  width: 100%;
  max-width: 100%;
  height: 100% !important;
  max-height: 100vh !important;

  position: relative;
  background: #fff;
  transform: translateY(0);
  opacity: 0;
`;

const ModalHeader = styled.div`
  display: flex !important;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 7px;
  padding-right: 16px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;
`;

const Title = styled.strong`
  display: inline-block;
  width: auto;
  font-size: 1rem;
  color: var(--text-defaultBlackColor);
  font-weight: 500;
  /* line-height: 50px; */
  line-height: normal !important;
  width: 70%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-self: flex-end;
`;

const Button = styled.button`
  display: block;
  width: 27px;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background: url(/static/media/img_pre.2990dcb3.svg) no-repeat;
  background-color: #fff;
  background-position: left center;
`;

const ModalBody = styled.div`
  height: calc(100% - 50px);
  max-height: none !important;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  max-width: 100%;
  margin-top: 20px;
  width: 100%;
`;

const BadgeItems = styled.ul`
  list-style: none;
`;

const Item = styled.li`
  width: 33%;
  padding: 0 10px;
  margin-bottom: 15px;
  height: 180px;
  float: left;
  text-align: center;
  position: relative;
`;

const Icon = styled.img`
  display: inline-block;
  height: 100px;
  width: 100%;
`;

const Name = styled.strong`
  margin-bottom: 8px;
  display: block;
  color: #000;
  font-size: 14px;
  word-break: break-all;
`;
