import jeboApi from "api/api";
import user_icon from "assets/imgs/icons/user_icon.svg";
import MemberBlockLayer from "components/layers/memberBlock/MemberBlockLayer";
import CommentAddFeatLayer from "components/layers/popMenu/CommentAddFeatLayer";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { feedsContext } from "components/providers/FeedContextProvider";
import { Base64 } from "js-base64";
import { Fragment } from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import ReCommentWriteForm from "./ReCommentWriteForm";
import CommentUpdateForm from "./CommentUpdateForm";
import styled from "styled-components";
import CommentReportLayer from "components/layers/report/CommentReportLayer";
import MetaInfo from "./MetaInfo";
import Zoom from "react-medium-image-zoom";
import CommentDeleteConfirmLayer from "components/layers/confirm/CommentDeleteConfirmLayer";

const regDateCalculate = (date) => {
  const year = "20" + date.split(" ")[0].split(".")[0];
  const month = date.split(" ")[0].split(".")[1] - 1;
  const day = date.split(" ")[0].split(".")[2];
  const hours = date.split(" ")[1].split(":")[0];
  const seconds = date.split(" ")[1].split(":")[1];

  const now = new Date();
  const timeValue = new Date(year, month, day, hours, seconds);

  let betweenTime = Math.floor(
    (now.getTime() - timeValue.getTime()) / 1000 / 60
  );
  let betweenTimeHour = Math.floor(betweenTime / 60);
  let betweenTimeDay = Math.floor(betweenTime / 60 / 24);

  if (betweenTime < 1) {
    return "방금전";
  } else if (betweenTime < 60) {
    return `${betweenTime}분전`;
  } else if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간전`;
  } else {
    return `${betweenTimeDay}일전`;
  }
};

export default function ReComment({
  commentId,
  setFeed,
  feed,
  comment,
  state,
  dispatch,
  setTargetComment,
  onClose,
  onBlock,
  focus,
}) {
  const { setFeeds } = useContext(feedsContext);

  const { contents, nickName, createAt, memberImageUrl, commentMediaUrl } =
    comment;
  const [parseContents, setParseContents] = useState("");
  const { mobileType } = useContext(isMobileContext);

  const calculateDate = regDateCalculate(createAt);

  const history = useHistory();
  const match = useRouteMatch();

  const { setSignLayer } = useContext(signLayerContext);
  const { isLoggedIn, handleSignOutApp, myInfo } = useContext(signAppContext);

  const reCommentActiveButtonRef = useRef();
  const commentRef = useRef();

  const [upCommentCnt, setUpCommentCnt] = useState(comment.upCommentCnt);
  const [downCommentCnt, setDownCommentCnt] = useState(comment.downCommentCnt);
  const [likeType, setLikeType] = useState(comment.likeType);

  //const [needMetaInfo, setNeedMetaInfo] = useState(null);
  let urlStr = [];

  useEffect(() => {
    if (comment.toNickName !== null) {
      setParseContents("@" + comment.toNickName + " " + contents);
    } else {
      setParseContents(contents);
    }
  }, [contents, comment.toNickName]);

  const toggleCommentLike = async (jeboId, commentId, like) => {
    if (!isLoggedIn) {
      setSignLayer("signIn");
      return;
    }
    try {
      const {
        status,
        data: { jeboCommentLike },
      } = await jeboApi.toggleCommentLike(jeboId, commentId, like);
      if (status === 200) {
        const updatedJeboCommentLike = JSON.parse(jeboCommentLike);

        setUpCommentCnt(
          updatedJeboCommentLike ? updatedJeboCommentLike.upCnt : 0
        );
        setDownCommentCnt(
          updatedJeboCommentLike ? updatedJeboCommentLike.downCnt : 0
        );
        setLikeType(likeType === like ? "" : like);
      }
    } catch (error) {
      console.log("error : ", error);
      if (error.response) {
        console.log("error.response : ", error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
        }
      }
    }
  };

  useEffect(() => {
    focus &&
      commentRef.current &&
      commentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  }, [focus]);

  return (
    <>
      {state.name === "BLOCK" && state.id === commentId && (
        <MemberBlockLayer
          member={{ memberUid: comment.memberUid, nickName: comment.nickName }}
          onClose={onClose}
          onBlock={onBlock}
        />
      )}
      {state.name === "REPORT" && state.id === commentId && (
        <CommentReportLayer comment={comment} dispatch={dispatch} />
      )}
      {state.name === "DELETE" && state.id === commentId && (
        <CommentDeleteConfirmLayer
          setFeed={setFeed}
          comment={comment}
          dispatch={dispatch}
        />
      )}
      <div className="comment-item" ref={commentRef}>
        <div className="thumb">
          <img
            src={
              memberImageUrl
                ? process.env.REACT_APP_RESOURCE_HOST + memberImageUrl
                : user_icon
            }
            alt="사용자1"
          />
        </div>
        <div className="comment-text reComment">
          <div className="top">
            <a
              href="!#"
              className="user-name"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/member/${encodeURIComponent(nickName)}`,
                });
              }}
            >
              {nickName}
            </a>
            <span>
              {parseContents &&
                parseContents
                  .split(/([#@http(s)][^\s\n]+)/g)
                  .map((word, idx) => {
                    if (word.match(/(^#[^%\s\n]+)/g)) {
                      return (
                        <a
                          key={idx}
                          href="#!"
                          style={{ color: "#5293c5" }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            history.push({
                              pathname: `/search/${encodeURIComponent(word)}`,
                            });
                          }}
                        >
                          {`${word}`}
                        </a>
                      );
                    } else if (word.match(/(^@[^%\s\n]+)/g)) {
                      return (
                        <a
                          key={idx}
                          href="#!"
                          style={{ color: "#5293c5" }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            history.push({
                              pathname: `/member/${encodeURIComponent(
                                word.replace("@", "")
                              )}`,
                            });
                          }}
                        >
                          {`${word}`}
                        </a>
                      );
                    } else if (
                      word.match(
                        /((^http(s)?:\/\/)([\w]+[\.:]*)+[\w]{2})([\/\w-%#?&=])+(\.[\w]{2,4})*/g
                      )
                    ) {
                      urlStr.push(
                        word.match(
                          /((^http(s)?:\/\/)([\w]+[\.:]*)+[\w]{2})([\/\w-%#?&=])+(\.[\w]{2,4})*/g
                        )
                      );
                      urlStr.push(word.replace(urlStr[0], ""));
                      return urlStr.map((splWord, idx) => {
                        if (idx == 0) {
                          return (
                            <a
                              key={idx}
                              rel="noreferrer"
                              href="_EXTERNALURL"
                              style={{
                                color: "#191CEE",
                                wordWrap: "break-word",
                                wordBreak: "keep-all",
                                position: "relative",
                                width: "min-content",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                const url = splWord;
                                if (mobileType === "ios") {
                                  const encodedUrl = Base64.encode(url);
                                  window.webkit.messageHandlers.openOuterLink.postMessage(
                                    encodedUrl
                                  );
                                } else {
                                  window.open(url);
                                }
                              }}
                            >
                              {splWord[0]}
                            </a>
                          );
                        } else {
                          return <Fragment key={idx}>{`${splWord}`}</Fragment>;
                        }
                      });
                    }
                    return word;
                  })}
            </span>
          </div>
          {urlStr && urlStr.length > 0 && (
            <MetaInfo urlMessage={urlStr[0]} viewMode={"H"} />
          )}
          {commentMediaUrl && (
            <CommentMediaContainer>
              <Zoom>
                <CommentImage
                  src={process.env.REACT_APP_RESOURCE_HOST + commentMediaUrl}
                  alt=""
                />
              </Zoom>
            </CommentMediaContainer>
          )}
          <div className="bottom flex">
            <div className="count">
              <button
                className={likeType === "UP" ? "btn up active" : "btn up"}
                onClick={(e) => {
                  e.preventDefault();
                  toggleCommentLike(
                    feed.jeboId,
                    commentId,
                    likeType === "UP" ? "" : "UP"
                  );
                }}
              ></button>
              <span className={likeType === "UP" ? "text active" : "text"}>
                {/* {likeCnt < 0 ? 0 : likeCnt} */}
                {/* 22.08.01 이명삼 - 우선 기능 반영, 디자인 나오면 재반영 필요 */}
                {upCommentCnt ? upCommentCnt : 0}
              </span>
              <button
                className={likeType === "DOWN" ? "btn down active" : "btn down"}
                onClick={(e) => {
                  e.preventDefault();
                  toggleCommentLike(
                    feed.jeboId,
                    commentId,
                    likeType === "DOWN" ? "" : "DOWN"
                  );
                }}
              ></button>
              <span className={likeType === "DOWN" ? "text active" : "text"}>
                {/* 22.08.01 이명삼 - 우선 기능 반영, 디자인 나오면 재반영 필요 */}
                {downCommentCnt ? downCommentCnt : 0}
              </span>
            </div>

            <button
              className="comment-add"
              ref={reCommentActiveButtonRef}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch({
                  type: "MODE",
                  id: comment.commentId,
                  name: "RECOMMENT",
                });
                e.target.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }}
            >
              답글 달기
            </button>

            <span className="re-time">{calculateDate}</span>
          </div>
          {/* 대댓달기 */}
          {state.name === "RECOMMENT" && state.id === commentId && (
            <ReCommentWriteForm
              feed={feed}
              comment={comment}
              jeboId={feed.jeboId}
              setFeed={setFeed}
              state={state}
              dispatch={dispatch}
            />
          )}
          {/* 대댓달기 */}

          {/* 수정하기 */}
          {state.name === "UPDATE" && state.id === commentId && (
            <CommentUpdateForm
              comment={comment}
              jeboId={feed.jeboId}
              setFeed={setFeed}
              dispatch={dispatch}
            />
          )}
          {/* 수정하기 */}
        </div>
        <button
          className="menu-open"
          onClick={(e) =>
            dispatch({
              type: "MENU_OPEN",
              id: comment.commentId,
              name: "RE_MENU",
            })
          }
        ></button>
        {state.name === "RE_MENU" && state.id === comment.commentId && (
          <CommentAddFeatLayer
            setFeed={setFeed}
            comment={comment}
            dispatch={dispatch}
          />
        )}
      </div>
    </>
  );
}
const CommentMediaContainer = styled.div`
  margin: 10px 14px;
  padding: 5px 3vh;
  text-align: center;
  background-color: #ebeef5;
`;

const CommentImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 150px;
`;
