import { useHistory } from "react-router-dom";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { convenienceDataContext } from "components/providers/ConvenienceDataContextProvider";
import { Base64 } from "js-base64";
import React, { useCallback, useContext, useEffect } from "react";
import { Fragment } from "react";
import { feedsContext } from "components/providers/FeedContextProvider";

export default function FeedMainText({ feed, page, grid }) {
  const history = useHistory();
  const { feeds, setFeeds } = useContext(feedsContext);
  const { contents, feedType, latitude, longitude, locationHashtag } = feed;
  const { mobileType } = useContext(isMobileContext);
  const { hashKeywords } = useContext(convenienceDataContext);

  const isConMap = useCallback(
    function checkWord(word) {
      return hashKeywords ? [...hashKeywords].includes(word) : false;
    },
    [hashKeywords]
  );

  /* 라인줄수 끝  */
  var lineHeight = "50px";
  var fontSize = "10px";

  /*개행문자 스페이스 처리 */
  var relContents = contents ? contents.split("\n").join(" ") : "";
  relContents =
    relContents.replace(/ +(?= )/g, "") +
    " " +
    (locationHashtag ? locationHashtag : "");

  if (grid) {
    if (relContents.length > 35)
      relContents = relContents.substr(0, 35) + "...";
    fontSize = "10px";
    lineHeight = "20px";
  } else {
    if (relContents.length > 90) {
      // relContents = relContents.substr(0, 90) + "...";
      // if (mobileType === "ios") {
      //   relContents = relContents.substr(0, 10) + "...";
      // }
    }

    if (relContents.length <= 15) {
      fontSize = "40px";
      lineHeight = "50px";
    } else if (relContents.length <= 25) {
      fontSize = "40px";
      lineHeight = "50px";
    } else if (relContents.length <= 40) {
      fontSize = "35px";
      lineHeight = "45px";
    } else if (relContents.length <= 60) {
      fontSize = "30px";
      lineHeight = "40px";
    } else {
      fontSize = "25px";
      lineHeight = "45px";
    }
  }
  return (
    //<div className="text" style={feedType === 1 ? { fontSize,lineHeight,display,WebkitBoxOrient  } : null}  >
    <div
      className="text"
      style={feedType === 1 ? { fontSize, lineHeight } : null}
    >
      {/* 230607 text-clamp 수정 */}
      <p
        className={
          page === "member" || page === "mypage" ? "text-clamp" : "text-clamp"
        }
        style={{ whiteSpace: "pre-line" }}
      >
        {(relContents && feedType === 0) || feedType === 2
          ? relContents
              //.split(/(#[^/!@#$%^&*(),.?":{}|<>=\s\n]+)/g)
              .split(/([#@http][^\s\n]+)/g)
              .map((word, idx) => {
                if (
                  //word.match(/#[^/!@#$%^&*(),.?":{}|<>=\s\n]+/)
                  word.match(/(^#[^%\s\n]+)/g)
                ) {
                  return (
                    <a
                      key={idx}
                      href="#!"
                      className={isConMap(word) ? "text-normal" : "text-normal"}
                      style={{
                        color: isConMap(word) ? "tomato" : "#5293c5",
                        position: "relative",
                        width: "min-content",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // 23.05.16. codelua 태그를 눌러서 이동하고 home으로 돌아오는 경우에 한해 피드의 상태를 돌려주기 위함.
                        console.log(
                          ">>>> MainText sessionStorage Set Item..!!"
                        );
                        sessionStorage.setItem(
                          "homeFeedData",
                          JSON.stringify(feeds)
                        );
                        sessionStorage.setItem(
                          "homeFeedScrollY",
                          window.scrollY
                        );
                        history.push({
                          pathname: `/search/${encodeURIComponent(word)}`,
                          search:
                            isConMap(word) && latitude && longitude
                              ? `?latitude=${latitude}&longitude=${longitude}`
                              : "",
                        });
                      }}
                    >
                      {`${word} `}
                    </a>
                  );
                } else if (
                  word.match(
                    /((^http(s)?:\/\/)([\w]+[\.:]*)+[\w]{2})([\/\w-%#?&=])+(\.[\w]{2,4})*/g
                  )
                ) {
                  var urlStr = [];
                  urlStr.push(
                    word.match(
                      // /((^http(s)?:\/\/)([\w]+[\.:]*)+[\w]{2})([\/\w-%#?&=])+(\.[\w]{2,4})*/g
                      /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}([\/a-z0-9-%#?&=\w])+(\.[a-z0-9]{2,4}(\?[\/a-z0-9-%#?&=\w]+)*)*/g
                    )
                  );
                  urlStr.push(word.replace(urlStr[0], ""));
                  return urlStr.map((splWord, idx) => {
                    if (idx == 0) {
                      return (
                        <a
                          key={idx}
                          rel="noreferrer"
                          href="_EXTERNALURL"
                          style={{
                            color: "#191CEE",
                            wordWrap: "break-word",
                            wordBreak: "keep-all",
                            position: "relative",
                            width: "min-content",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const url = splWord;
                            if (mobileType === "ios") {
                              const encodedUrl = Base64.encode(url);
                              window.webkit.messageHandlers.openOuterLink.postMessage(
                                encodedUrl
                              );
                            } else {
                              window.open(url);
                            }
                          }}
                        >
                          {splWord}
                        </a>
                      );
                    } else {
                      return <Fragment key={idx}>{`${splWord}`}</Fragment>;
                    }
                  });
                }
                return word;
              })
          : relContents}
      </p>
    </div>
  );
}
