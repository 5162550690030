import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import jeboRoundLogo from "../../../../assets/imgs/custom/user_logo_simple.png";
import bokji_m from "../../../../assets/imgs/custom/bokji_m.png";
import bokji_web from "../../../../assets/imgs/custom/bokji_web.png";
import jeontong_m from "../../../../assets/imgs/custom/jeontong_m.png";
import jeontong_web from "../../../../assets/imgs/custom/jeontong_web.png";
import jumal_m from "../../../../assets/imgs/custom/jumal_m.png";
import jumal_web from "../../../../assets/imgs/custom/jumal_web.png";
import news_m from "../../../../assets/imgs/custom/news_m.png";
import news_web from "../../../../assets/imgs/custom/news_web.png";

import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function AdsInfoSection({ feed }) {
  const history = useHistory();
  const { isMobile } = useContext(isMobileContext);

  return (
    <>
      <AdsItem>
        {/* header */}
        <AdsItemHeader>
          <AdsItemHeadThum>
            <AdsItemHeadThumImg src={jeboRoundLogo} alt="jebo_logo" />
          </AdsItemHeadThum>
          <AdsItemHeadInfoWrap>
            <AdsItemHeadInfoTitle>제보</AdsItemHeadInfoTitle>
            <AdsItemHeadInfoDesc>정보</AdsItemHeadInfoDesc>
          </AdsItemHeadInfoWrap>
        </AdsItemHeader>

        {/* body */}
        <AdsItemBodyWrap>
          <>
            <Swiper
              autoplay={false}
              className="swiper-container live-swiper"
              spaceBetween={30}
              slidesPerView={1}
              loop={false}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              pagination={{ el: ".swiper-pagination", clickable: true }}
              onSwiper={(swiper) => {}}
              onSlideChange={() => {}}
            >
              {" "}
              <SwiperSlide className="swiper-slide live-item">
                <AdsItemBodyContents img={bokji_m} imgweb={bokji_web}>
                  <AdsItemContentTitle
                    img={bokji_m}
                    imgweb={bokji_web}
                  ></AdsItemContentTitle>
                  <ClickWrapper
                    onClick={(e) => {
                      history.push({
                        pathname: "/search/%23강서구_복지혜택",
                      });
                    }}
                  />
                </AdsItemBodyContents>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide live-item">
                <AdsItemBodyContents>
                  <AdsItemContentTitle
                    img={jeontong_m}
                    imgweb={jeontong_web}
                  ></AdsItemContentTitle>
                  <ClickWrapper
                    onClick={(e) => {
                      history.push({
                        pathname: "/search/%23강서구_전통시장",
                      });
                    }}
                  />
                </AdsItemBodyContents>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide live-item">
                <AdsItemBodyContents>
                  <AdsItemContentTitle
                    img={jumal_m}
                    imgweb={jumal_web}
                  ></AdsItemContentTitle>
                  <ClickWrapper
                    onClick={(e) => {
                      history.push({
                        pathname: "/search/%23주말약국",
                        search: `?gubun=meta&classNum=1005`,
                      });
                    }}
                  />
                </AdsItemBodyContents>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide live-item">
                <AdsItemBodyContents>
                  <AdsItemContentTitle
                    img={news_m}
                    imgweb={news_web}
                  ></AdsItemContentTitle>
                  <ClickWrapper
                    onClick={(e) => {
                      history.push({
                        pathname: "/search/%23강서구_뉴스",
                      });
                    }}
                  />
                </AdsItemBodyContents>
              </SwiperSlide>
              <SwiperPaginationBtn className="swiper-pagination"></SwiperPaginationBtn>
              {/* {isMobile ? (
                <SwiperPaginationBtn className="swiper-pagination"></SwiperPaginationBtn>
              ) : (
                <>
                  <SwiperBtn className="swiper-button-next"></SwiperBtn>
                  <SwiperBtn className="swiper-button-prev"></SwiperBtn>
                </>
              )} */}
            </Swiper>
          </>
        </AdsItemBodyWrap>
      </AdsItem>
    </>
  );
}

const AdsItem = styled.div`
  @media screen and (min-width: 481px) {
    width: 285px;
    height: 489px;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    margin: 0 10px 20px;
    text-align: left;
    vertical-align: top;
  }

  @media screen and (max-width: 480px) {
    width: 285px;
    height: 472px;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    margin: 0 10px 20px;
    text-align: left;
    vertical-align: top;
    width: 100%;
    margin: 0 0 16px 0;
  }
`;

const AdsItemHeader = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 10px 10px 0 0;
  background: #fff;
  padding: 8px 10px 8px 8px;
  position: relative;
  z-index: 1;

  display: flex;

  @media screen and (max-width: 480px) {
    border-radius: 0;
    border: none;
  }
`;

const AdsItemBodyWrap = styled.div`
  @media screen and (min-width: 481px) {
    position: relative;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    height: 425px;
  }

  @media screen and (max-width: 480px) {
    position: relative;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    height: 428px;
    border: none;
  }
`;

const AdsItemHeadThum = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin-right: 5px;
  flex-shrink: 0;
`;

const AdsItemHeadThumImg = styled.img`
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const AdsItemHeadInfoWrap = styled.div`
  max-width: calc(100% - 85px);
  padding-right: 3px;
  flex: auto;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-height: 31px;
  flex-direction: column;
`;

const AdsItemHeadInfoTitle = styled.p`
  width: 100%;
  display: block;
  font-size: 13px;
  line-height: 1em;
  font-weight: 500;
  color: #232323;
  color: var(--text-defaultBlackColor);
  display: flex;
  align-items: center;
`;

const AdsItemHeadInfoDesc = styled.p`
  width: 120px;
  display: block;
  font-size: 11px;
  line-height: 1.3em;
  font-weight: 300;
  color: #797979;
`;

const AdsItemBodyContents = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
  text-align: center;
  padding: 10px 10px;

  position: relative;
`;

const AdsItemContentTitle = styled.div`
  @media screen and (min-width: 481px) {
    width: 100%;
    height: 100%;
    background: url(${({ imgweb }) => imgweb}) no-repeat;
    background-size: 100%;
    border-radius: 15px;
    display: inline-flex;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
    background: url(${({ img }) => img}) no-repeat;
    background-size: 100%;
    border-radius: 15px;
    display: inline-flex;
  }
`;

const ClickWrapper = styled.div`
  @media screen and (min-width: 481px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 205px;
    top: 308px;
    width: 45px;
    height: 18px;
    border: 1px solid red;
    opacity: 0;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 85vw;
    right: 3vw;
    margin-right: 30px;
    margin-bottom: 40px;
    width: 75px;
    height: 25px;
    border: 1px solid red;
    opacity: 0;
  }
`;

const SwiperBtn = styled.div`
  border: 1px solid #15a3d3;
  bottom: 0;
  top: unset;
`;

const SwiperPaginationBtn = styled.div``;
