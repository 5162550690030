import React from "react";

export default function LocationPolicy({ setLocationInfo, callFrom }) {
  return (
    <>
      <div className="policy-container__textBox">
        <h2 className="policy__main__title">위치기반 서비스 이용약관(필수)</h2>
        <h2 className="policy__title">제 1 조 목적</h2>
        <p className="policy__desc">
          본 약관은 주식회사 리스트 (이하 “회사”)가 제공하는 위치기반서비스와
          관련하여 회사와 개인위치정보주체와의 권리, 의무 및 책임사항, 기타
          필요한 사항을 규정함을 목적으로 합니다.
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">제 2 조 약관 외 준칙</h2>
        <p className="policy__desc">
          본 약관에 명시되지 않은 사항은 위치정보의 보호 및 이용 등에 관한 법률,
          개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률,
          전기통신기본법, 전기통신사업법 등 관계법령과 회사의 이용약관 및
          개인정보처리방침, 회사가 별도로 정한 지침 등에 의합니다.
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">제 3 조 서비스 이용요금</h2>
        <p className="policy__desc no">
          1. 회사가 제공하는 서비스는 아래와 같습니다.
          <br /> &lt; 회원 단말기 등의 위치를 토대로 주변 정보(교통, 날씨, 사건,
          사고, 일상 등) 제공서비스 &gt;
        </p>
        <table className="policy__table">
          <tr className="table__firstRow">
            <td>
              <h2 className="table__header">서비스명</h2>
            </td>
            <td>
              <h2 className="table__header">서비스내용</h2>
            </td>
          </tr>
          <tr className="table__secRow">
            <td>
              <p>
                제보(jebo)
                <br />
                사용자 위치기반 정보 공유 서비스
              </p>
            </td>
            <td>
              <p>
                회원의 위치를 기반으로 내 주변에서 일어나는 다양한 교통, 날씨,
                사건, 사고, 일상 등의 정보 공유
              </p>
            </td>
          </tr>
        </table>
        <p className="policy__desc no">
          2. 제1항 위치기반서비스의 이용요금은 무료입니다.
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">제 4 조 개인위치정보주체의 권리</h2>
        <p className="policy__desc no">
          1. 개인위치정보주체는 개인위치정보 수집 범위 및 이용약관의 내용 중
          일부 또는 개인위치정보의 이용·제공 목적, 제공받는 자의 범위 및
          위치기반서비스의 일부에 대하여 동의를 유보할 수 있습니다.
        </p>
        <p className="policy__desc no">
          2. 회원은 언제든지 개인위치정보의 수집·이용·제공의 일시적인 중지를
          요구할 수 있습니다.
        </p>
        <p className="policy__desc no">
          3. 회원은 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를
          요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수
          있습니다. 이 경우 회사는 정당한 사유 없이 회원의 요구를 거절할 수
          없습니다.
        </p>
        <p className="policy__desc no">
          1. 개인위치정보주체는 회사에 대하여 아래 자료의 열람 또는 고지를
          요구할 수 있고, 당해 자료에 오류가 있는 경우에는그 정정을 요구할 수
          있습니다. 이 경우 회사는 정당한 이유없이 요구를 거절하기 아니합니다.
          <span className="policy__desc__detail">
            ① 개인위치정보주체에 대한 위치정보 수집·이용·제공사실 확인자료
          </span>
          <span className="policy__desc__detail">
            ② 개인위치정보주체의 개인위치정보가 위치정보의 보호 및 이용 등에
            관한 법률 또는 다른 법령의 규정에 의하여 제3자에게 제공된 이유 및
            내용
          </span>
        </p>
        <p className="policy__desc no">
          5. 회사는 개인위치정보주체가 동의의 전부 또는 일부를 철회한 경우에는
          지체없이 수집된 개인위치정보 및 위치정보 수집·이용·제공사실 확인자료를
          파기합니다. 단, 동의의 일부를 철회하는 경우에는 철회하는 부분의
          개인위치정보 및 위치정보 수집·이용·제공사실 확인자 료에 한합니다.
        </p>
        <p className="policy__desc no">
          6. 개인위치정보주체는 제1항 내지 제4항의 권리행사를 위하여 이 약관
          제12조의 연락처를 이용하여 회사에 요구할 수 있습니다.
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">제 5 조 법정대리인의 권리</h2>
        <p className="policy__desc no">
          1. 회사는 만14세 미만 아동으로부터 개인위치정보를 수집·이용 또는
          제공하고자 하는 경우에는 만14세 미만 아동과 그 법정대리인의 동의를
          받아야합니다. 단, 현재 제보(jebo) 서비스는 만14세 미만 아동은 이용할
          수 없습니다.
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">제 6 조 약관의 효력 및 변경</h2>
        <p className="policy__desc no">
          1. 본 약관은 제보 서비스 이용자 또는 개인위치정보주체자가 본 약관에
          동의하고 회사가 정한 소정의 절차에 따라 서비스의 이용자로 등록함으로써
          효력이 발생합니다.
        </p>
        <p className="policy__desc no">
          2. 회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 초기 화면에
          게시하거나 기타의 방법으로 공지합니다.
        </p>
        <p className="policy__desc no">
          3. 회사가 약관을 개정할 경우에는 다음 각 호와 같은 사항을 명시하여
          개정약관 적용 일자 30일 전부터 적용일 이후 상당한 기간 동안 각각 이를
          서비스 홈페이지에 게시하여 고지합니다.
        </p>
        <p className="policy__desc no">
          4. 회사는 제3항에 따른 게시 및 통지 후에도 회원이 제3항의 기간동안
          개정약관에 대해 동의 또는 거부의 의사표시를 하지 않을 때는 회원이 해당
          개정 약관에 동의한 것으로 봅니다. 회원이 개정 약관에 동의하지 않으면
          회원은 이용계약을 해지할 수 있습니다.
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">
          제 7 조 위치정보 이용·제공사실 확인자료 보유근거 및 보유기간
        </h2>
        <p className="policy__desc">
          회사는 위치정보의 보호 및 이용등에 관한 법률 제16조 제2항에 근거하여
          개인위치정보주체에 대한 위치정보 수집·이용·제공사실 확인자료를
          위치정보시스템에 자동으로 기록하며, 3년 이상 보관합니다
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">제 8 조 서비스 변경 및 중지</h2>
        <p className="policy__desc no">
          1. 회사는 위치기반서비스사업자의 정책변경 등과 같이 회사의 제반 사정
          또는 법률상의 장애 등으로 서비스를 유지할 수 없는 경우, 서비스의 전부
          또는 일부를 제한, 변경하거나 중지할 수 있습니다.
        </p>
        <p className="policy__desc no">
          2. 제1항에 의한 서비스 중단의 경우에는 회사는 사전에 인터넷 등에
          공지하거나 개인위치정보주체에게 통지합니다.
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">제 9 조 개인위치정보의 이용 또는 제공</h2>
        <p className="policy__desc no">
          1. 회사는 개인위치정보주체의 동의 없이 당해 개인위치정보주체의
          개인위치정보를 제3자에게 제공하지 아니하며, 제3자 제공 서비스를
          제공하는 경우에는 제공받는 자 및 제공목적을 사전에
          개인위치정보주체에게 고지하고 동의를 받습니다.
        </p>
        <p className="policy__desc no">
          2. 제보(jebo) 서비스를 통해 수집한 개인위치정보의 경우, 회사는 제보된
          사실관계의 위치를 특정할 수 있는 최소한의 위치정보만 언론사 등
          제3자에게 제공합니다. 이 경우 회원 개인은 식별되지 않으며 따라서
          개인위치정보의 제3자 제공으로 보지 않습니다.
        </p>
        <p className="policy__desc no">
          3. 회사는 개인위치정보를 개인위치정보주체가 지정하는 제3자에게
          제공하는 경우에는 개인위치정보를 수집한 당해 통신단말장치로 매회
          개인위치정보주체에 제공받는 자, 제공일시 및 제공목적을 즉시
          통보합니다. 다만, 아래에 해당하는 경우에는 개인위치정보주체가 미리
          특정하여 지정한 통신단말장치 또는 전자우편주소 등으로 통보합니다.
          <span className="policy__desc__detail">
            ① 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의
            수신기능을 갖추지 아니한 경우
          </span>
          <span className="policy__desc__detail">
            ② 개인위치정보주체가 개인위치정보를 수집한 당해 통신단말장치 외의
            통신단말장치 또는 전자우편주소 등으로 통보할 것을 미리 요청한 경우
          </span>
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">제 10 조 회사의 의무</h2>
        <p className="policy__desc">
          회사는 「위치정보의 보호 및 이용 등에 관한 법률」, 「정보통신망
          이용촉진 및 정보보호 등에 관한 법률」, 「통신비밀보호법」,
          「정보통신사업법」, 「개인정보 보호법」 등 서비스의 운영 및 유지와
          관련있는 법령을 준수합니다.
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">
          제 10 조 개인위치정보의 보유목적 및 이용기간
        </h2>
        <p className="policy__desc no">
          1. 회사는 위치가반서비스를 제공하기 위해 필요한 최소한의 기간 동안
          개인위치정보를 보유 및 이용합니다.
        </p>
        <p className="policy__desc no">
          2. 회사는 대부분의 위치기반 서비스에 개인위치정보를 일회성 또는 임시로
          이용 후 지체없이 파기합니다. 단, 제보(jebo) 서비스에서 회원이 게시물,
          콘텐츠와 함께 개인위치정보를 제보 서비스에 게시 또는 보관하는 경우,
          해당 게시물, 콘텐츠의 보관기간 동안 개인위치정보가 함께 보관됩니다.
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">제 12 조 손해배상</h2>
        <p className="policy__desc">
          개인위치정보주체는 회사의 위치정보의 보호 및 이용 등에 관한 법률
          제15조 내지 26조의 규정을 위반한 행위로 손해를 입은 경우에 회사에
          대하여 손해배상을 청구할 수 있습니다. 이 경우 회사는 고의 또는 과실이
          없음을 입증하지 아니하면 책임을 면할 수 없습니다.
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">제 13 조 분쟁의 조정</h2>
        <p className="policy__desc no">
          1. 회사는 위치정보와 관련된 분쟁에 대하여 개인위치정보주체와 협의가
          이루어지지 아니하거나 협의를 할 수 없는 경우에는 개인정보보호법에 따라
          개인정보분쟁조정위원회에 조정을 신청할 수 있습니다.
        </p>
        <p className="policy__desc no">
          2. 회사 또는 개인위치정보주체는 위치정보와 관련된 분쟁에 대해 당사자간
          협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는
          개인정보보호법에 따라 개인정보분쟁조정위원회에 조정을 신청할 수
          있습니다.
        </p>
      </div>

      <div className="policy-container__textBox">
        <h2 className="policy__title">
          제 14 조 사업자 정보 및 위치정보 관리책임자
        </h2>
        <p className="policy__desc no">
          1. 회사의 상호, 주소 및 연락처는 다음과 같습니다.
          <span className="policy__desc__detail">① 상호 : 주식회사 리스트</span>
          <span className="policy__desc__detail">
            ② 주소 : 서울특별시 강서구 화곡로 416, 720호(가양역 더스카이밸리5차)
          </span>
          <span className="policy__desc__detail"> ③ 연락처 : 02-2632-5133</span>
        </p>
        <p className="policy__desc no">
          2. 회사는 다음과 같이 위치정보 관리책임자를 지정하여 이용자들이 서비스
          이용과정에서 발 생한 민원사항 처리를 비롯하여 개인위치정보주체의 권리
          보호를 위해 힘쓰고 있습니다.
          <span className="policy__desc__detail">
            ① 직무 : 개인위치정보관리책임자
          </span>
          <span className="policy__desc__detail"> ② 성명 : 박영희</span>
          <span className="policy__desc__detail">
            ③ 담당자 번호 : 070-4140-7715
          </span>
          <span className="policy__desc__detail">
            ④ 이메일주소 : gps@jebo.io
          </span>
        </p>
      </div>
      <div className="policy-container__textBox">
        <h2 className="policy__title" style={{ textAlign: "center" }}>
          &lt; 부칙 &gt;
        </h2>
        <h2 className="policy__title">제 1 조 시행일</h2>
        <p className="policy__desc">
          본 약관은 2022년 08월 18일부터 적용됩니다.
        </p>
      </div>
      {callFrom !== "signUp" && (
        <button
          className="service-complete-btn"
          onClick={(e) => {
            setLocationInfo(false);
          }}
        >
          확인
        </button>
      )}
    </>
  );
}
