import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { feedCategoriesContext } from "components/providers/FeedContextProvider";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SwiperCore, {
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Pagination, Navigation, Keyboard, Mousewheel]);
export default function CategoryTabSection({ category, setCategory }) {
  const { feedCategories } = useContext(feedCategoriesContext);

  const refSectionTab = useRef();
  const [offsetTop, setOffsetTop] = useState(0);

  const { isMobile } = useContext(isMobileContext);

  useEffect(() => {
    setOffsetTop(refSectionTab.current.offsetTop);
  }, [isMobile]);

  const handleScroll = useCallback(
    (e) => {
      let scrollTop = e.srcElement.scrollingElement.scrollTop;
      if (scrollTop > offsetTop) {
        refSectionTab.current && refSectionTab.current.classList.add("fixed");
        document.body.classList.add("fixed");
      } else {
        refSectionTab.current &&
          refSectionTab.current.classList.remove("fixed");
        document.body.classList.remove("fixed");
      }
    },
    [offsetTop]
  );
  /* 20220608 김경민 모바일 카테고리 영역 수정 */
  useEffect(() => {
    if (isMobile) {
      refSectionTab.current && refSectionTab.current.classList.remove("fixed");
      document.body.classList.remove("fixed");
    } else {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      !isMobile && window.removeEventListener("scroll", handleScroll);
    };
  }, [offsetTop, isMobile, handleScroll]);

  return (
    <section className="section-tab" ref={refSectionTab}>
      <div className="tab-btns">
        <Swiper
          className="tab-swiper swiper-container"
          freeMode={true}
          slidesPerView="auto"
          spaceBetween={10}
        >
          <SwiperSlide>
            <button
              className={category ? `btn type0` : `btn type0 active`}
              name="report_type"
              onClick={(e) => {
                setCategory("");
              }}
            >
              전체
            </button>
          </SwiperSlide>
          {feedCategories.map((feedCategory, index) => (
            <SwiperSlide key={index}>
              <button
                key={feedCategory.categoryCode}
                type="checkbox"
                className={
                  category === feedCategory.categoryName
                    ? `btn type${feedCategory.categoryCode} active`
                    : `btn type${feedCategory.categoryCode}`
                }
                name="report_type"
                onClick={(e) => {
                  setCategory(feedCategory.categoryName);
                }}
              >
                {feedCategory.categoryName}
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
