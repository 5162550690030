import React, { useRef, useState } from "react";
import LocationPolicy from "../../layers/policy/LocationPolicy";
import MarketingPolicy from "../../layers/policy/MarketingPolicy";
import PersonalPolicy from "../../layers/policy/PersonalPolicy";
import TermOfUsePolicy from "../../layers/policy/TermOfUsePolicy";

export default function AboutServiceContainer({ setActiveMenu }) {
  const [personalData, setPersonalData] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [locationInfo, setLocationInfo] = useState(false);
  const [marketingInfo, setMarketingInfo] = useState(false);

  const policyRef = useRef();

  return (
    <>
      <div className="s-body about-us active" id="s-service">
        <div className="s-title">
          <button
            type="button"
            className="list-back mobile"
            onClick={(e) => {
              if (termsOfUse || personalData || locationInfo || marketingInfo) {
                setTermsOfUse(false);
                setPersonalData(false);
                setLocationInfo(false);
                setMarketingInfo(false);
              } else {
                setActiveMenu("");
              }
            }}
          ></button>
          <strong>약관 및 정책</strong>
        </div>
        <div className="s-inner">
          <div className="info-wrap">
            <div className="service-wrap">
              <div className="service-item">
                {/* <input type="checkbox" id="service01" checked disabled></input> */}
                <label htmlFor="service01">
                  서비스 이용약관 <span>[필수]</span>
                </label>
                <button
                  className="service-desc"
                  onClick={(e) => {
                    setTermsOfUse((prev) => !prev);
                  }}
                >
                  [전문 보기]
                </button>
              </div>
              <div className="service-item">
                {/* <input type="checkbox" id="service02" checked disabled></input> */}
                <label htmlFor="service02">
                   개인정보 수집 및 이용 동의 <span>[필수]</span>
                </label>
                <button
                  className="service-desc"
                  onClick={(e) => {
                    setPersonalData((prev) => !prev);
                  }}
                >
                  [전문 보기]
                </button>
              </div>
              <div className="service-item">
                {/* <input type="checkbox" id="service03" checked disabled></input> */}
                <label htmlFor="service03">
                  위치기반 서비스 이용약관 <span>[필수]</span>
                </label>
                <button
                  className="service-desc"
                  onClick={(e) => {
                    setLocationInfo((prev) => !prev);
                  }}
                >
                  [전문 보기]
                </button>
              </div>
              <div className="service-item">
                {/* <input
                  type="checkbox"
                  id="service04"
                  checked={marketingInfoCheck}
                  onChange={(e) => {
                    setMarketingInfoCheck(e.target.checked);
                  }}
                ></input> */}
                <label htmlFor="service04">
                  마케팅 정보 수집 및 이용 동의 <>[선택]</>
                </label>
                <button
                  className="service-desc"
                  onClick={(e) => {
                    setMarketingInfo((prev) => !prev);
                  }}
                >
                  [전문 보기]
                </button>
              </div>
              {/* <button
                className="service-complete-btn"
                onClick={(e) => {
                  setShowConfirmLayer((prev) => !prev);
                }}
              >
                완료
              </button> */}
            </div>
            {(termsOfUse || personalData || locationInfo || marketingInfo) && (
              <div className="policy-container settings" ref={policyRef}>
                {termsOfUse && (
                  <TermOfUsePolicy setTermsOfUse={setTermsOfUse} />
                )}
                {personalData && (
                  <PersonalPolicy setPersonalData={setPersonalData} />
                )}
                {locationInfo && (
                  <LocationPolicy setLocationInfo={setLocationInfo} />
                )}
                {marketingInfo && (
                  <MarketingPolicy
                    setMarketingInfo={setMarketingInfo}
                    policyRef={policyRef}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

// over_age terms_of_use personal_data location_info marketing_info
