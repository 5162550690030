import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { useContext, useRef, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Zoomable from "react-instagram-zoom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player/lazy";

export default function MediaBox({
  mediaType,
  mediaUrl,
  enabledZoom,
  mediaFit,
}) {
  const refImage = useRef();
  const refVideo = useRef();

  const { isMobile } = useContext(isMobileContext);
  const [imageModal, setImageModal] = useState(false);

  return (
    <>
      <Zoomable style={{ height: "100%" }}>
        {mediaType === "image" ? (
          <LazyLoadImage
            onClick={(e) => {
              !isMobile && setImageModal(true);
            }}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: mediaFit ? "cover" : "contain", //"contain",
              margin: "auto",
            }}
            itemRef={refImage}
            src={mediaUrl}
          />
        ) : (
          <ReactPlayer
            ref={refVideo}
            className={mediaFit ? "react-player" : ""}
            controls={true}
            controlsList="nodownload"
            loop={false}
            muted={true}
            autoPlay={false}
            playsInline={true}
            type="video/*"
            url={`${mediaUrl}#t=0.5`}
            width="100%"
            height="100%"
            style={{
              objectFit: mediaFit ? "cover" : "contain", //"contain",
              margin: "auto",
            }}
          />
        )}
      </Zoomable>

      {enabledZoom && mediaType === "image" && imageModal && (
        <Lightbox
          mainSrc={mediaUrl}
          onCloseRequest={(e) => {
            setImageModal(false);
          }}
        />
      )}
    </>
  );
}
