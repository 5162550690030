import jeboApi from "api/api";
import AuthService from "api/AuthService";
import axios from "axios";
import SignUpAgreeLayer from "components/layers/sign/SignUpAgreeLayer";
import SignUpComplete from "components/layers/sign/SignUpComplete";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import LoadingComponent from "components/templates/LoadingComponent";
import { Base64 } from "js-base64";
import qs from "query-string";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function KakaoLoginSuccess() {
  const kakao = window.Kakao;

  const location = useLocation();
  const history = useHistory();
  const { code } = qs.parse(location.search);

  const { handleSignInApp } = useContext(signAppContext);
  const { mobileType } = useContext(isMobileContext);

  const [openTermsOfJebo, setOpenTermsOfJebo] = useState(false);
  const [infoStruct, setInfoStruct] = useState(null);

  const [openComplete, setOpenComplete] = useState(false);
  const [memberYn, setMemberYn] = useState("");
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    setOpenTermsOfJebo(infoStruct ? true : false);
  }, [infoStruct]);

  const socialSignIn = useCallback(
    async (id, email, name, socialType, deviceId, fcmToken, agreeItems) => {
      try {
        const { headers } = await jeboApi.socialSignIn(
          id,
          email,
          name,
          socialType,
          deviceId,
          fcmToken,
          mobileType,
          agreeItems
        );

        headers.refresh_token &&
          AuthService.setAutoLoginToken(headers.refresh_token);
        headers.member_email && AuthService.setKeepId(headers.member_email);

        console.log("소셜 로그인 response header : ", headers);
        handleSignInApp(
          headers.authorization,
          headers.refresh_token,
          headers.member_email,
          Base64.decode(headers.member_nickname).replace(
            /[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]/g,
            ""
          ),
          headers.grant_type,
          headers.member_uid,
          headers.social_yn,
          socialType
        );
        memberYn === "N"
          ? setOpenComplete(true)
          : history.replace({ pathname: "/" });
      } catch (error) {
        console.error("error : ", error);
      }
    },
    [mobileType, memberYn, handleSignInApp, history]
  );

  const getKakaoUserInfo = useCallback(
    async (userInfo) => {
      const { id, kakao_account } = userInfo;

      let deviceId = "";
      let fcmToken = "";

      if (mobileType === "android") {
        let returnString = window.androidApp.getDeviceTokenInfo();

        deviceId = returnString.split("@@")[0];
        fcmToken = returnString.split("@@")[1];
      }

      // 회원여부 질의
      const { data } = await jeboApi.existMemberYn(id, "kakao");
      setMemberYn(data.existMemberYn);
      if (data.existMemberYn === "N") {
        setInfoStruct({
          id,
          email: kakao_account.email,
          name:
            "profile" in kakao_account ? kakao_account.profile.nickname : "",
          socialType: "kakao",
          deviceId,
          fcmToken,
        });
      } else if (data.existMemberYn === "G") {
        alert("재가입은 탈퇴일로 부터 7일 후에 가능합니다");
      } else if (data.existMemberYn === "F") {
        alert("영구정지 상태로 재가입이 불가합니다.");
      } else {
        socialSignIn(
          id,
          kakao_account.email,
          "profile" in kakao_account ? kakao_account.profile.nickname : "",
          "kakao",
          deviceId,
          fcmToken,
          ""
        );
      }
    },
    [mobileType, socialSignIn]
  );

  const kakaoInit = useCallback(() => {
    !kakao.isInitialized() && kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
  }, [kakao]);

  useEffect(() => {
    kakaoInit();
  }, [kakaoInit]);

  const success = useCallback(() => {
    const data = {
      grant_type: "authorization_code",
      client_id: process.env.REACT_APP_KAKAO_APP_KEY,
      redirect_uri: `${process.env.REACT_APP_HOST}/kakaoLoginSuccess`,
      code: code,
    };
    const queryString = Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");

    //토큰 발급 REST API
    axios
      .post("https://kauth.kakao.com/oauth/token", queryString, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
      .then((res) => {
        console.log("res : ", res);
        if (res.status === 200 && res.data && res.data.access_token) {
          setIsAuth(true);
          const {
            data: { access_token },
          } = res;
          kakao.Auth.setAccessToken(access_token);

          kakao.API.request({
            url: "/v2/user/me",
            success: getKakaoUserInfo,
            fail: (error) => {
              console.log("kakao get userInfo error : ", error);
            },
          });
        }
      });
  }, [kakao.API, kakao.Auth, code, getKakaoUserInfo]);

  useEffect(() => {
    !isAuth && success();
  }, [isAuth, success]);

  return openTermsOfJebo ? (
    <SignUpAgreeLayer
      socialSignIn={socialSignIn}
      infoStruct={infoStruct}
      setInfoStruct={setInfoStruct}
      setOpenTermsOfJebo={setOpenTermsOfJebo}
    />
  ) : openComplete ? (
    <SignUpComplete setOpenComplete={setOpenComplete} />
  ) : (
    <LoadingComponent />
  );
}
