import React, { useEffect, useState } from "react";
import logo from "assets/imgs/jebo_admin_profile.png";
import { Link } from "react-router-dom";

//앱 유도 Pop Up Layer
export default function InduceAppPopLayer({
  setOpenInduceApp,
  expiredKey,
  targetUrl,
}) {
  //localStorage Key값에 다라 유도 URL 다르게 적용
  // const url = expiredKey.includes("home")
  //   ? "https://jebo.page.link/home" //localStorage Key에 Home이 들어가면 제보 Home으로 이동하는 DynamicLink
  //   : "https://devjebo.page.link/event"; //임시 DynamicLink, dev.jebo.io/%23반려동물 로 이동

  const url = targetUrl ? targetUrl : "https://jebo.page.link/home";

  const setExpired = () => {
    const EXPIRED_TIME = localStorage.getItem(expiredKey);

    if (!EXPIRED_TIME || EXPIRED_TIME < new Date()) {
      let expiredTime = new Date();
      //6시간에 한 번 뜨는 설정
      expiredTime = expiredTime.setHours(expiredTime.getHours() + 6);

      //TEST용, 30초에 한 번 뜨는 설정
      // expiredTime = expiredTime.setSeconds(expiredTime.getSeconds() + 30);

      localStorage.setItem(expiredKey, expiredTime);
      console.log(
        ">>> Key : ",
        expiredKey,
        "  >  setExpiredTime : ",
        expiredTime
      );
    }

    setOpenInduceApp(false);
  };

  return (
    <>
      <div
        className="induceAppPopLayer_background"
        onClick={() => {
          return;
        }}
      >
        <div className="induceApp_Pop">
          <div className="induceApp_Pop_Layer">
            <div className="induceApp_Desc">
              <div className="induceApp_Logo">
                <img src={logo} alt="제보"></img>
              </div>
              <div className="induceApp_Text_Box">
                <p className="induceApp_title">
                  제보 앱에서 쉽고 편리하게! <br />더 많은 기능을 이용해 보세요.
                </p>
              </div>
            </div>
            <div className="induceApp_Link">
              {/* <button
                className="induceAppLink__Btn"
                onClick={(e) => {
                  
                }}
              >
                편하게 앱으로 보기
              </button> */}
              <a
                href={url}
                onClick={(e) => {
                  setExpired();
                }}
              >
                편하게 앱으로 보기 &gt;
              </a>
            </div>
          </div>
          <button
            className="not_induceApp__Btn"
            onClick={(e) => {
              setExpired();
            }}
          >
            괜찮아요. 모바일 웹에서 볼게요.
          </button>
        </div>
      </div>
    </>
  );
}
