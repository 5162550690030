import jeboApi from "api/api";
import MemberBlockLayer from "components/layers/memberBlock/MemberBlockLayer";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { subscribeMessageContext } from "components/providers/SubscribeMessageContextProvider";
import DirectMessageCardFrom from "components/templates/DirectMessageCardFrom";
import DirectMessageCardTo from "components/templates/DirectMessageCardTo";
import qs from "query-string";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DirectMessageService from "socket/DirectMessageService";
import DeleteConfirmLayer from "./DeleteConfirmLayer";
import PopMenu from "./PopMenu";
import ReportLayer from "./ReportLayer";

export default function MessageSection({
  setTitles,
  titles,
  messages,
  setMessages,
  getDirectMessages,
}) {
  const { myInfo } = useContext(signAppContext);

  const { isMobile } = useContext(isMobileContext);
  const { stompClient, subscribeMessage, isConnectedSocket } = useContext(
    subscribeMessageContext
  );

  const [inputMessage, setInputMessage] = useState("");
  //const [error, setError] = useState(null);

  const { communicationId } = useParams();
  const location = useLocation();
  const { memberUid, nickName, jeboId } = qs.parse(location.search);

  const [targetMemberUid, setTargetMemberUid] = useState(
    memberUid ? memberUid : ""
  );
  const [targetMemberNickName, setTargetMemberNickName] = useState(
    nickName ? nickName : ""
  );
  const [dmFromJeboId, setDmFromJeboId] = useState(jeboId ? jeboId : "");

  const [showPopMenu, setShowPopMenu] = useState(false);
  const [showBlockLayer, setShowBlockLayer] = useState(false);
  const [showReportLayer, setShowReportLayer] = useState(false);
  const [showDeleteConfirmLayer, setShowDeleteConfirmLayer] = useState(false);

  const [isAdmin, setIsAdmin] = useState(
    (communicationId && communicationId.includes("JEBO_REWARD_ROOM")) ||
      (targetMemberNickName && targetMemberNickName.includes("@RESIGN"))
      ? true
      : false
  );

  const history = useHistory();
  const lastMsgRef = useRef();
  const scrollRef = useRef();

  const dateGroupCalculate = (date) => {
    var week = [
      "일요일",
      "월요일",
      "화요일",
      "수요일",
      "목요일",
      "금요일",
      "토요일",
    ];

    let today = new Date(date.split(" ")[0]).getDay();
    var todayLabel = week[today];

    let year = date.split(" ")[0].split("-")[0];
    let month = date.split(" ")[0].split("-")[1];
    let day = date.split(" ")[0].split("-")[2];

    let dateGroup = year + "년" + month + "월" + day + "일 " + todayLabel;
    return dateGroup;
  };

  useEffect(() => {
    if (titles && titles.length > 0 && communicationId) {
      const title = titles.find(
        (title) => title.communicationId === communicationId
      );

      title && setTargetMemberNickName(title.toMemberNickName);
      title && setTargetMemberUid(title.toMemberUid);
    }
  }, [communicationId, titles]);

  // useEffect(() => {
  //   lastMsgRef.current?.scrollIntoView({
  //     behavior: "auto",
  //     block: "start",
  //     inline: "nearest",
  //   });
  // }, [messages]);

  useEffect(() => {
    if (
      subscribeMessage &&
      communicationId === subscribeMessage.communicationId
    ) {
      DirectMessageService.sendReadYn(stompClient, targetMemberUid);
      DirectMessageService.sendAlarm(stompClient);
    }
  }, [stompClient, targetMemberUid, subscribeMessage, communicationId]);

  useEffect(() => {
    if (
      (communicationId && communicationId.includes("JEBO_REWARD_ROOM")) ||
      (targetMemberNickName && targetMemberNickName.includes("@RESIGN"))
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [communicationId, targetMemberNickName]);

  const sendMessage = () => {
    if (!inputMessage.trim()) return;

    try {
      //auth 확인
      let isValid = jeboApi.checkAuth();
      if (!isValid) {
        alert("계정 만료. 다시 로그인해주세요.");
        return;
      }

      DirectMessageService.sendMessage(
        stompClient,
        targetMemberUid,
        inputMessage,
        communicationId,
        dmFromJeboId,
        myInfo.nickName
      );

      setInputMessage("");
      setDmFromJeboId("");

      //메시지를 보내고 DB에 저장하는 수행 시간보다,
      //웹소켓으로 메시지 목록 가져오는 시간이 더 빠르므로 타임아웃 적용.. 설정한 타임아웃보다 DB저장이 더 늦으면 안됨..
      //TODO 따라서, state로 관리하도록 수정해야함.

      setTimeout(() => {
        getDirectMessages();
      }, 500);
    } catch (error) {
      alert("메시지 발송도중 오류 입니다.");
      console.log(error);
      //setError(error);
    }
  };

  useEffect(() => {
    if (isConnectedSocket && targetMemberUid) {
      DirectMessageService.sendReadYn(stompClient, targetMemberUid);
      DirectMessageService.sendAlarm(stompClient);
    }
  }, [stompClient, isConnectedSocket, targetMemberUid]);

  return (
    <div className="dm-viewer-ct open">
      <div className="dm-viewer">
        <div className="dm-header">
          <button
            type="button"
            className="list-back mobile"
            onClick={(e) => {
              history.replace({ pathname: "/directMessage" });
            }}
          ></button>
          {targetMemberNickName !== "@RESIGN@" &&
          targetMemberNickName !== "제보알리미" ? (
            <a className="nickName" href="#!">
              <strong>
                To. {targetMemberNickName}
                <i className="icon-arrow-right32"></i>
              </strong>{" "}
            </a>
          ) : (
            <>
              {targetMemberNickName === "@RESIGN@" ? (
                <strong>탈퇴한회원입니다</strong>
              ) : (
                <strong className="nickName">
                  {" "}
                  To. {targetMemberNickName}{" "}
                </strong>
              )}
            </>
          )}
          <button
            className={`menu-open${isAdmin ? " hidden" : ""}`}
            onClick={(e) => {
              if (!targetMemberUid) return;
              setShowPopMenu(true);
            }}
          ></button>
          {showPopMenu && (
            <PopMenu
              setShowPopMenu={setShowPopMenu}
              setShowDeleteConfirmLayer={setShowDeleteConfirmLayer}
              setShowBlockLayer={setShowBlockLayer}
              setShowReportLayer={setShowReportLayer}
              targetMemberUid={targetMemberUid}
              targetMemberNickName={targetMemberNickName}
            />
          )}
        </div>
        <div className="dm-body">
          <div className="msg-list" ref={scrollRef}>
            {messages &&
              messages.map((message, index) => {
                let exdate = null;
                let date = null;

                if (index > 0)
                  exdate = dateGroupCalculate(messages[index - 1].createAt);
                date = dateGroupCalculate(message.createAt);

                if (exdate === date) date = null;

                return (
                  <div key={index} ref={lastMsgRef}>
                    {date && <div className="dm-time">{date}</div>}
                    {message.toMemberUid === myInfo.memberUid && (
                      <DirectMessageCardTo
                        message={message}
                        scrollRef={scrollRef}
                      />
                    )}
                    {message.fromMemberUid === myInfo.memberUid && (
                      <DirectMessageCardFrom
                        message={message}
                        scrollRef={scrollRef}
                      />
                    )}
                  </div>
                );
              })}
          </div>
          <div className="dm-write">
            <div className="msg-group">
              <textarea
                disabled={isAdmin}
                type="text"
                className="msg-text"
                placeholder={
                  isAdmin
                    ? "궁금하신 점은 help@jebo.io로 제보해 주세요."
                    : "메시지를 입력해주세요."
                }
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter" && e.shiftKey) {
                    sendMessage();
                  }
                }}
                onFocus={(e) => {
                  lastMsgRef.current?.scrollIntoView({
                    behavior: "auto",
                    block: "start",
                    inline: "nearest",
                  });
                }}
                value={inputMessage}
              ></textarea>
              <button
                className={`msg-send${isAdmin ? " hidden" : ""}`}
                onClick={(e) => {
                  sendMessage();
                }}
              ></button>
            </div>
          </div>
        </div>
      </div>
      {showBlockLayer && (
        <MemberBlockLayer
          member={{
            memberUid: targetMemberUid,
            nickName: targetMemberNickName,
          }}
          onClose={() => {
            setShowBlockLayer(false);
          }}
          onBlock={() => {
            alert(`${targetMemberNickName}님을 차단했습니다.`);
            setTitles((prev) =>
              prev.filter((title) => title.toMemberUid !== targetMemberUid)
            );
            history.replace({ pathname: "/directMessage" });
          }}
        />
      )}
      {showReportLayer && (
        <ReportLayer
          setShowReportLayer={setShowReportLayer}
          communicationId={communicationId}
          targetMemberUid={targetMemberUid}
        />
      )}
      {showDeleteConfirmLayer && (
        <DeleteConfirmLayer
          communicationId={communicationId}
          setShowDeleteConfirmLayer={setShowDeleteConfirmLayer}
          setTitles={setTitles}
        />
      )}
    </div>
  );
}
