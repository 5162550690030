import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import HistorySearchKeywordBox from "components/templates/HistorySearchKeywordBox";
import RecommendSearchKeywordBox from "components/templates/RecommendSearchKeywordBox";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import qs from "query-string";

export default function MobileSearchSection({
  setShowKeywordsBox,
  showKeywordsBox,
}) {
  const history = useHistory();
  const { keyword } = useParams();
  const location = useLocation();
  const { eventId } = qs.parse(location.search);

  const { isMobile } = useContext(isMobileContext);

  const [searchKeyword, setSearchKeyword] = useState(
    keyword ? decodeURIComponent(keyword) : ""
  );

  const boxRef = useRef();

  useEffect(() => {
    !searchKeyword && !eventId && setShowKeywordsBox(true);
  }, [searchKeyword, eventId, setShowKeywordsBox]);

  useEffect(() => {
    setSearchKeyword(keyword ? decodeURIComponent(keyword) : "");
  }, [keyword]);

  const handleSearchKeyword = (keyword) => {
    if (!keyword) return;

    let historyKeywords =
      localStorage.getItem("searchHistory") &&
      JSON.parse(localStorage.getItem("searchHistory"));

    let storeKeywords = [keyword];

    if (historyKeywords && historyKeywords.length > 0) {
      storeKeywords = historyKeywords.filter((item) => keyword !== item);
      storeKeywords.length >= 5 && storeKeywords.pop();
      storeKeywords = [keyword, ...storeKeywords];
    }

    keyword &&
      localStorage.setItem("searchHistory", JSON.stringify(storeKeywords));

    setShowKeywordsBox(false);

    history.replace({
      pathname: `/search/${encodeURIComponent(encodeURIComponent(keyword))}`,
    });
  };

  return (
    <div className="section-search mobile">
      <div className="container">
        <div className="search-wrap">
          <button
            className="btn-back"
            onClick={(e) => {
              isMobile && history.goBack();
            }}
          ></button>
          <div className="search-ct" ref={boxRef}>
            <div className="search-group">
              <input
                type="text"
                placeholder="검색어를 입력해주세요."
                onChange={(e) => {
                  setShowKeywordsBox(true);
                  setSearchKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  e.key === "Enter" && handleSearchKeyword(searchKeyword);
                }}
                autoComplete="off"
                value={searchKeyword}
              />

              {searchKeyword && (
                <button
                  className="clear-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setSearchKeyword("");
                  }}
                ></button>
              )}
              <button
                className="search-btn"
                onClick={(e) => {
                  e.preventDefault();
                  handleSearchKeyword(searchKeyword);
                }}
              ></button>
            </div>
          </div>
        </div>
      </div>
      {showKeywordsBox && (
        <div className="section-keyword">
          <div className="keyword-wrap sc">
            <div className="keywords-list">
              <RecommendSearchKeywordBox
                handleSearchKeyword={handleSearchKeyword}
              />
              <HistorySearchKeywordBox
                handleSearchKeyword={handleSearchKeyword}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
