import Sections from "components/pages/around/layouts/Sections";
import TrafficMapSection from "components/pages/trafficMap/TrafficMapSection";
import React from "react";

export default function TrafficMap() {
  return (
    <>
      <Sections>
        <TrafficMapSection />
      </Sections>
    </>
  );
}
