import Layers from "components/layers/Layers";
import Header from "components/pages/app/layouts/Header";
import FeedSection from "components/pages/mypage/FeedSection";
import MyapgeSections from "components/pages/mypage/layouts/Sections";
import ProfileSection from "components/pages/mypage/ProfileSection";
import MypageContextProvider from "components/pages/mypage/provider/MypageContextProvider";
import TabSection from "components/pages/mypage/TabSection";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { useContext, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

export default function Mypage() {
  const { isMobile } = useContext(isMobileContext);
  const { myInfo, handleSignOutApp } = useContext(signAppContext);
  const match = useRouteMatch();
  const history = useHistory();

  const [feeds, setFeeds] = useState([]);
  const [layer, setLayer] = useState(null);

  const [activeMenu, setActiveMenu] = useState("MY");

  const onExpiredSession = () => {
    alert("로그인 세션이 만료됐습니다. 다시 로그인해주세요.");
    handleSignOutApp();
    history.replace({ pathname: `${match.url}/sign` });
  };

  return (
    <>
      <MypageContextProvider
        feeds={{ feeds, setFeeds }}
        layer={{ layer, setLayer }}
        session={{ onExpiredSession }}
      >
        {!isMobile && <Header />}
        {myInfo && (
          <MyapgeSections>
            <ProfileSection memberInfo={myInfo} />
            <TabSection activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
            <FeedSection activeMenu={activeMenu} memberUid={myInfo.memberUid} />
          </MyapgeSections>
        )}
        <Layers />
      </MypageContextProvider>
    </>
  );
}
