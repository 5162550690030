import { useRef } from "react";

export default function CollectBadgeDetailWeb({
  handleBadgeClickMobile,
  memberReward,
}) {
  const pRef = useRef("");

  return (
    <>
      <div className="badgeDetail_modal web">
        <div className="header">
          <button
            className="close_btn"
            onClick={handleBadgeClickMobile}
          ></button>
        </div>
        <p ref={pRef} className="showPercentText">
          {memberReward.rewardName !== "스타터" && (
            <span className="percent">
              상위 {memberReward.rewardPercent} % 획득
            </span>
          )}
        </p>
        <div className="badgeDetail">
          <img
            src={
              process.env.REACT_APP_RESOURCE_HOST + memberReward.rewardImagePath
            }
            alt="배지"
          ></img>
          <div className="badgeDetailTextBox">
            <p className="badgeDetail__title">{memberReward.rewardName}</p>
            <p className="badgeDetail__desc">{memberReward.rewardDesc}</p>
          </div>
        </div>
      </div>
    </>
  );
}
