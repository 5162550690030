import jeboApi from "api/api";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { feedsContext } from "components/providers/FeedContextProvider";
import { useCallback, useContext, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

export default function FeedSection() {
  //const [error, setError] = useState("");
  const { feeds, setFeeds } = useContext(feedsContext);
  const history = useHistory();
  const match = useRouteMatch();

  const { isMobile } = useContext(isMobileContext);

  const loadFeeds = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.getJeboEventList();

      if (status === 200) {
        const jeboEventList = JSON.parse(data.jeboEventList);
        setFeeds(
          jeboEventList.sort((a, b) => (b.fixYn === "Y" ? true : false))
        );
      } else if (status === 204) {
        console.log("no data to be loaded..");
        //setError("no data");
      }
    } catch (error) {
      console.log(error);
      //setError(error);
    }
  }, [setFeeds]);

  useEffect(() => {
    loadFeeds();
  }, [loadFeeds]);

  return (
    <>
      <section className="section-feed campaign-feed">
        <div className="container">
          <div className="mobile-header p-header">
            <strong>{`캠페인 & 테마`}</strong>
            <button
              type="button"
              className="page-back mobile"
              onClick={() => {
                isMobile && history.goBack();
              }}
            ></button>
          </div>

          <div className="cont-feed campaignPageFeedContainer">
            <div className="list" style={{ marginTop: "10px" }}>
              <Swiper
                autoplay={true}
                className="swiper-container"
                spaceBetween={30}
                slidesPerView={1}
                // loop={
                //   jeboEventList && jeboEventList.length > 1 ? true : false
                // }
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                pagination={{ clickable: true }}
                //scrollbar={{ draggable: true }}
                onSwiper={(swiper) => {}}
                onSlideChange={() => {}}
              >
                {feeds &&
                  feeds.length > 0 &&
                  feeds.map((feed, index) => (
                    <SwiperSlide className="item swiper-slide" key={index}>
                      <div
                        className="item-header-wrap"
                        style={{ borderTop: "solid 1px #dcdcdc" }}
                      ></div>
                      <div className="item-body-wrap campaign-body-wrap">
                        <div className="item-body">
                          <a
                            href="#!"
                            className="item-link"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <div className="item-photo">
                              <div className="pht-slider">
                                <img
                                  src={
                                    process.env.REACT_APP_RESOURCE_HOST +
                                    "/images/event/" +
                                    feed.mobileImageName
                                  }
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    margin: "auto",
                                  }}
                                />
                              </div>
                            </div>
                          </a>
                        </div>

                        {/* item buttons */}
                        <div className="item-bar-wrap campaign-btn-container">
                          <div
                            className="item-bar"
                            style={{ justifyContent: "left" }}
                          >
                            <button
                              style={{
                                padding: "5px",
                                borderRadius: "3px",
                                margin: "4px",
                                backgroundColor: "#014681",
                                color: "#fff",
                              }}
                              onClick={(e) => {
                                e.preventDefault();

                                history.push({
                                  pathname: `${match.url}/add`,
                                  search: `?eventId=${feed.eventId}`,
                                });
                              }}
                            >
                              제보하기
                            </button>
                            {feed.eventViewCode === "CD03" ||
                            feed.eventViewCode === "CD05" ? (
                              <button
                                style={{
                                  padding: "5px",
                                  borderRadius: "3px",
                                  margin: "4px",
                                  backgroundColor: "#014681",
                                  color: "#fff",
                                }}
                                onClick={async (e) => {
                                  const { status, data } =
                                    await jeboApi.aroundMapFeedList(
                                      0,
                                      0,
                                      0,
                                      0,
                                      "",
                                      `${feed.eventId}`,
                                      "0",
                                      "5",
                                      "0"
                                    );
                                  if (status === 200) {
                                    const jeboPostArr = JSON.parse(
                                      data.jeboPost
                                    ).jeboPostList;
                                    const latitude =
                                      jeboPostArr.length > 0
                                        ? jeboPostArr[0].latitude
                                        : "";
                                    const longitude =
                                      jeboPostArr.length > 0
                                        ? jeboPostArr[0].longitude
                                        : "";

                                    history.push({
                                      pathname: `/around`,
                                      search: `?eventId=${
                                        feed.eventId
                                      }&latitude=${
                                        latitude ? latitude : ""
                                      }&longitude=${
                                        longitude ? longitude : ""
                                      }`,
                                    });
                                  } else {
                                    history.push({
                                      pathname: `/around`,
                                      search: `?eventId=${feed.eventId}`,
                                    });
                                  }
                                }}
                              >
                                지도보기
                              </button>
                            ) : (
                              ""
                            )}
                            {feed.eventViewCode === "CD04" ||
                            feed.eventViewCode === "CD05" ? (
                              <button
                                style={{
                                  padding: "5px",
                                  borderRadius: "3px",
                                  margin: "4px",
                                  backgroundColor: "#014681",
                                  color: "#fff",
                                }}
                                onClick={async (e) => {
                                  history.push({
                                    pathname: `/search`,
                                    search: `?eventId=${feed.eventId}`,
                                  });
                                }}
                              >
                                제보현황
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div
                          className="item-header-wrap"
                          style={{ borderTop: "solid 1px #dcdcdc" }}
                        ></div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>

              {feeds &&
                feeds.length > 0 &&
                feeds.map((feed, index) => (
                  <div
                    className="item-body-wrap campaign-body-wrap single"
                    key={index}
                  >
                    <div className="item-body">
                      <a href="#!" className="item-link">
                        <div className="item-photo">
                          <div className="pht-slider">
                            <img
                              src={
                                process.env.REACT_APP_RESOURCE_HOST +
                                "/images/event/" +
                                feed.mobileImageName
                              }
                              alt="테마/캠페인 단일 이미지"
                            />
                          </div>
                        </div>
                      </a>
                    </div>

                    {/* item button */}
                    <div className="item-bar-wrap singleCamp campaign-btn-container">
                      <div
                        className="item-bar"
                        style={{ justifyContent: "left" }}
                      >
                        <button
                          style={{
                            padding: "5px",
                            borderRadius: "3px",
                            margin: "4px",
                            backgroundColor: "#014681",
                            color: "#fff",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            history.push({
                              pathname: `${match.url}/add`,
                              search: `?eventId=${feed.eventId}`,
                            });
                          }}
                        >
                          제보하기
                        </button>
                        {feed.eventViewCode === "CD03" ||
                        feed.eventViewCode === "CD05" ? (
                          <button
                            style={{
                              padding: "5px",
                              borderRadius: "3px",
                              margin: "4px",
                              backgroundColor: "#014681",
                              color: "#fff",
                            }}
                            onClick={async (e) => {
                              const { status, data } =
                                await jeboApi.aroundMapFeedList(
                                  0,
                                  0,
                                  0,
                                  0,
                                  "",
                                  `${feed.eventId}`,
                                  "0",
                                  "5",
                                  "0"
                                );
                              if (status === 200) {
                                const jeboPostArr = JSON.parse(
                                  data.jeboPost
                                ).jeboPostList;
                                const latitude =
                                  jeboPostArr.length > 0
                                    ? jeboPostArr[0].latitude
                                    : "";
                                const longitude =
                                  jeboPostArr.length > 0
                                    ? jeboPostArr[0].longitude
                                    : "";

                                history.push({
                                  pathname: `/around`,
                                  search: `?eventId=${feed.eventId}&latitude=${
                                    latitude ? latitude : ""
                                  }&longitude=${longitude ? longitude : ""}`,
                                });
                              } else {
                                history.push({
                                  pathname: `/around`,
                                  search: `?eventId=${feed.eventId}`,
                                });
                              }
                            }}
                          >
                            지도보기
                          </button>
                        ) : (
                          ""
                        )}
                        {feed.eventViewCode === "CD04" ||
                        feed.eventViewCode === "CD05" ? (
                          <button
                            style={{
                              padding: "5px",
                              borderRadius: "3px",
                              margin: "4px",
                              backgroundColor: "#014681",
                              color: "#fff",
                            }}
                            onClick={(e) => {
                              history.push({
                                pathname: "/search",
                                search: `?eventId=${feed.eventId}`,
                              });
                            }}
                          >
                            제보현황
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
