import React, { createContext } from "react";

export const signLayerContext = createContext({
  signLayer: "",
  setSignLayer: () => {},
});

export default function SignLayerContextProvider({
  signLayer,
  children: Components,
}) {
  return (
    <signLayerContext.Provider value={signLayer}>
      {Components}
    </signLayerContext.Provider>
  );
}
