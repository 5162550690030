import { Base64 } from "js-base64";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import arrowDown from "../../assets/imgs/icons/keyboard_arrow_down.svg";
import arrowUp from "../../assets/imgs/icons/keyboard_arrow_up.svg";

export function AdminNoticeList({ notice, mobileType, open, focus }) {
  // const [clickId, setClickId] = useState("");
  const [isOpen, setIsOpen] = useState(open);
  const noticeDesc = notice.noticeDesc;

  const noticeRef = useRef();

  useEffect(() => {
    open &&
      noticeRef.current &&
      noticeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  }, [focus]);

  return (
    <>
      <List
        ref={noticeRef}
        onClick={(e) => {
          setIsOpen((prev) => !prev);
          // clickId === notice.id ? setClickId(-1) : setClickId(notice.id);
        }}
      >
        {/* 공지사항 날짜, 제목, 펼치기/닫기 Start */}
        <div>
          <Date>{notice.notiDate}</Date>
        </div>
        <div style={{ display: "flex" }}>
          <Title>{notice.noticeTitle}</Title>
          <Open>
            <img
              src={isOpen ? arrowUp : arrowDown}
              style={{ float: "right" }}
              alt="펼치기/접기"
            />
          </Open>
        </div>
        {/* 공지사항 날짜, 제목, 펼치기/닫기 End*/}
      </List>
      <div
        style={Object.assign(
          {},
          isOpen ? styles.notice__open : styles.notice__close,
          {
            height: isOpen ? "auto" : 0,
          }
        )}
      >
        <Desc>
          {noticeDesc &&
            noticeDesc.split(/([#@http(s)][^%\s\n]+)/g).map((word, idx) => {
              if (
                word.match(/((^http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4})/g)
              ) {
                return (
                  <a
                    key={idx}
                    rel="noreferrer"
                    href="_EXTERNALURL"
                    style={{
                      color: "#191CEE",
                      wordWrap: "break-word",
                      wordBreak: "keep-all",
                      position: "relative",
                      width: "min-content",
                      fontSize: "inherit",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const url = word;
                      if (mobileType === "ios") {
                        const encodedUrl = Base64.encode(url);
                        window.webkit.messageHandlers.openOuterLink.postMessage(
                          encodedUrl
                        );
                      } else {
                        window.open(url);
                      }
                    }}
                  >
                    {`${word} `}
                  </a>
                );
              }
              return word;
            })}
        </Desc>
      </div>
    </>
  );
}

const List = styled.button`
  background: #fff;
  font-weight: bold;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 90%;
  height: auto;
  border: none;
  border-radius: 4;
  border-bottom: 0.5px solid;
  padding-left: 2vw;
`;

const Title = styled.div`
  text-align: left;
  margin-bottom: 10px;
  width: 90%;
  line-height: 25px;
  word-break: keep-all;
`;

const Date = styled.p`
  text-align: left;
  font-size: 11px;
  margin-bottom: 5px;
  color: #797979;
`;

const Open = styled.div`
  display: flex;
  align-items: center;
`;

const Desc = styled.pre`
  width: 100%;
  padding: 10;
  margin: 0;
  line-height: 1.3;
  white-space: pre-line;
`;

const styles = {
  notice__open: {
    overflowY: "scroll",
    width: "90%",
    margin: 10,
    background: "aliceblue",
  },
  notice__close: {
    overflowY: "scroll",
    width: "90%",
    margin: 10,
    borderBottom: "none",
  },
};
