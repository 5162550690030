import logo from "assets/imgs/logo_new.svg";
import dev_logo from "assets/imgs/dev_logo.svg";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import HeaderMenuContainer from "components/templates/HeaderMenuContainer";
import HeaderSearchContainer from "components/templates/HeaderSearchContainer";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

const REACT_APP_MODE = process.env.REACT_APP_MODE;
export default function Header() {
  const { isMobile } = useContext(isMobileContext);

  return (
    <>
      <div className="header-wrap">
        <div className="container">
          <header>
            <Link
              className="logo"
              onClick={(e) => {
                /* Header 아이콘 클릭 시에도 SessionStorage를 비워주어 초기화 */
                sessionStorage.removeItem("homeFeedData");
                sessionStorage.removeItem("homeFeedScrollY");
                sessionStorage.removeItem("homeFeedCursor");
                sessionStorage.removeItem("homeFeedSort");
                sessionStorage.removeItem("homeFeedCategory");
              }}
              to={{ pathname: "/" }}
            >
              <img
                src={REACT_APP_MODE === "production" ? logo : dev_logo}
                alt="제보"
              />
            </Link>
            {!isMobile && <HeaderSearchContainer />}
            <HeaderMenuContainer />
          </header>
        </div>
      </div>
    </>
  );
}
