import jeboApi from "api/api";
import AlertLayer from "components/layers/popMenu/AlertLayer";
import ConfirmLayer from "components/layers/popMenu/ConfirmLayer";
import { signAppContext } from "components/providers/SignContextProvider";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function ResignLayer() {
  const history = useHistory();

  const { myInfo, handleSignOutApp } = useContext(signAppContext);

  const [showConfirmLayer, setShowConfirmLayer] = useState(false);
  const [showAlertLayer, setShowAlertLayer] = useState(false);

  const [reasonCodeToResign, setReasonCodeToResign] = useState("");
  const [reasonValueToResign, setReasonValueToResign] = useState("");
  const [etcOpinionField, setEtcOpinionField] = useState("hidden");
  const [etcOpinion, setEtcOpinion] = useState("");
  const [resignAgree, setResignAgree] = useState(false);

  const [reasonWarning, setReasonWarning] = useState(false);
  const [opinionWarning, setOpinionWarning] = useState(false);
  const [agreeWarning, setAgreeWarning] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  // useEffect(() => {
  //   document.getElementsByTagName("html")[0].classList.add("hidden");
  //   window.document.body.classList.add("hidden");
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("hidden");
  //     window.document.body.classList.remove("hidden");
  //   };
  // }, []);

  // useEffect(() => {
  //   setResignAgree(etcOpinion ? true : false);
  // }, [etcOpinion]);

  useEffect(() => {
    setResignAgree(false);
    setOpinionWarning(false);
    setReasonWarning(false);
    setAgreeWarning(false);
  }, [reasonCodeToResign]);

  useEffect(() => {
    setAgreeWarning(resignAgree ? false : true);
  }, [resignAgree]);

  useEffect(() => {
    description && title && setShowAlertLayer(true);
  }, [title, description]);

  const ReasonRadioBtn = ({
    id,
    name = "resignRadio",
    value,
    content,
    param,
  }) => {
    return (
      <div className="resign-radio">
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={reasonCodeToResign === value}
          onChange={() => {
            setReasonCodeToResign(value);
            setReasonValueToResign(content);
            setEtcOpinionField(param);
            setEtcOpinion(param === "hidden" ? "" : etcOpinion);
          }}
        />
        <label htmlFor={id}>{content}</label>
        <br />
      </div>
    );
  };

  const validationCheck = () => {
    let checkFlag = true;
    if (reasonCodeToResign === "") {
      setReasonWarning(true);
      checkFlag = false;
    } else {
      if (reasonCodeToResign === "06") {
        if (etcOpinion === "") {
          setOpinionWarning(true);
          checkFlag = false;
        }
      }
    }

    return checkFlag;
  };

  /*
   * ConfirmLayer Callback Function
   * 탈퇴회원 resignYn = Y 로 Update
   */
  const resign = async () => {
    const { status } = await jeboApi.resign(
      myInfo.memberUid,
      reasonCodeToResign,
      reasonCodeToResign === "06"
        ? reasonValueToResign + " : " + etcOpinion
        : reasonValueToResign,
      reasonCodeToResign ? "00" : "N" // 00: 일반탈퇴, 01:영구정지, N: 일단 flag
    );

    if (status === 200) {
      setTitle("탈퇴처리가 완료 되었습니다.");
      setDescription("이용해주셔서 감사합니다.");
    } else {
      setTitle("진행 중 오류가 발생했습니다.");
      setDescription("관리자에게 문의해주세요.");
    }
  };

  return (
    <>
      <div className="modal-wrap open">
        <div className="modal modal-resign open" id="modal-resign">
          <div className="modal-header">
            <button
              type="button"
              className="page-back mobile"
              onClick={(e) => {
                e.preventDefault();
                // history.replace({ pathname: "/setting" });
                history.goBack();
              }}
            ></button>
            <strong className="resign-title">회원탈퇴</strong>
            <button
              className="modal-close pc"
              onClick={(e) => {
                e.preventDefault();
                history.replace({ pathname: "/setting" });
              }}
            >
              <i className="icon-cross1"></i>
            </button>
          </div>
          <div className="resign-header">
            <div className="logo">{/* <img src={logo} alt="제보" /> */}</div>
            <strong className="title">제보를 탈퇴하시겠습니까?</strong>
            <br />
            <div className="resign-notice">
              탈퇴를 하시면 고객정보와 모든 게시물은 삭제되며,
              <br /> 복구가 불가능합니다.
            </div>
          </div>
          <div className="resign-body">
            <div
              className="select-resign"
              style={
                reasonWarning
                  ? { color: "#cf514a", fontWeight: "bold" }
                  : undefined
              }
            >
              탈퇴 이유를 선택해주세요.
            </div>
            <br />
            <ReasonRadioBtn
              id="resingReason_01"
              name="contact"
              value="01"
              content="이용이 불편하고 장애가 많아서"
              param="hidden"
            />
            <ReasonRadioBtn
              id="resingReason_02"
              name="contact"
              value="02"
              content="원하는 서비스가 아니어서"
              param="hidden"
            />
            <ReasonRadioBtn
              id="resingReason_03"
              name="contact"
              value="03"
              content="사용빈도가 낮아서"
              param="hidden"
            />
            <ReasonRadioBtn
              id="resingReason_04"
              name="contact"
              value="04"
              content="기록 삭제 목적"
              param="hidden"
            />
            <ReasonRadioBtn
              id="resingReason_05"
              name="contact"
              value="05"
              content="콘텐츠에 대한 불만"
              param="hidden"
            />
            <ReasonRadioBtn
              id="resingReason_06"
              name="contact"
              value="06"
              content="기타 (직접입력)"
              param="text"
            />
            <input
              type={etcOpinionField}
              id="etcOpinion"
              className={`input-group ${opinionWarning && "etc-opinion"}`}
              value={etcOpinion}
              placeholder="기타 사유를 입력해주세요."
              onChange={(e) => setEtcOpinion(e.target.value)}
            />
            <br />
            <div className="resign-check">
              <input
                type="checkbox"
                id="checkAgree"
                value="agree"
                checked={resignAgree}
                onChange={(e) =>
                  validationCheck()
                    ? setResignAgree(!resignAgree)
                    : setResignAgree(false)
                }
              />
              <label
                htmlFor="checkAgree"
                style={
                  agreeWarning
                    ? { color: "#cf514a", fontWeight: "bold" }
                    : undefined
                }
              >
                {" "}
                안내사항을 모두 확인했으며, 이에 동의합니다.{" "}
              </label>
            </div>
          </div>
          <div className="resign-footer">
            <button
              className="input-btn"
              onClick={(e) => {
                e.preventDefault();
                !validationCheck()
                  ? setResignAgree(false)
                  : resignAgree
                  ? setShowConfirmLayer(true)
                  : setAgreeWarning(true);
              }}
            >
              회원탈퇴
            </button>
          </div>

          {showConfirmLayer && (
            <ConfirmLayer
              setShowConfirmLayer={setShowConfirmLayer}
              callback={resign}
              title="회원탈퇴를 계속 진행하시겠습니까?"
              description="탈퇴 시 복구할 수 없습니다."
              btnText="회원탈퇴"
              isConfirm={true}
            />
          )}
          {showAlertLayer && (
            <AlertLayer
              setShowAlertLayer={setShowAlertLayer}
              title={title}
              description={description}
              callback={() => {
                handleSignOutApp();
                history.replace({ pathname: "/" });
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
