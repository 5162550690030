import React, { useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import LocationPolicy from "../../../layers/policy/LocationPolicy";
import MarketingPolicy from "../../../layers/policy/MarketingPolicy";
import PersonalPolicy from "../../../layers/policy/PersonalPolicy";
import TermOfUsePolicy from "../../../layers/policy/TermOfUsePolicy";

export default function MobileAboutServiceContainer() {
  const [personalData, setPersonalData] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [locationInfo, setLocationInfo] = useState(false);
  const [marketingInfo, setMarketingInfo] = useState(false);

  const policyRef = useRef();
  const history = useHistory();

  return createPortal(
    <Wrap>
      <Modal>
        <ModalHeader>
          <Button
            onClick={(e) => {
              if (termsOfUse || personalData || locationInfo || marketingInfo) {
                setTermsOfUse(false);
                setPersonalData(false);
                setLocationInfo(false);
                setMarketingInfo(false);
              } else {
                history.goBack();
              }
            }}
          ></Button>
          <Title>약관 및 정책</Title>
        </ModalHeader>
        <ModalBody>
          <AboutServiceContainer>
            <AboutServiceList>
              <AboutServiceItem>
                {/* <input type="checkbox" id="service01" checked disabled></input> */}
                <AboutServiceLabel htmlFor="service01">
                  서비스 이용약관 <Highlight>[필수]</Highlight>
                </AboutServiceLabel>
                <ViewBtn
                  onClick={(e) => {
                    setTermsOfUse((prev) => !prev);
                  }}
                >
                  [전문 보기]
                </ViewBtn>
              </AboutServiceItem>
              <AboutServiceItem>
                {/* <input type="checkbox" id="service02" checked disabled></input> */}
                <AboutServiceLabel htmlFor="service02">
                  개인정보 수집 및 이용 동의 <Highlight>[필수]</Highlight>
                </AboutServiceLabel>
                <ViewBtn
                  className="service-desc"
                  onClick={(e) => {
                    setPersonalData((prev) => !prev);
                  }}
                >
                  [전문 보기]
                </ViewBtn>
              </AboutServiceItem>
              <AboutServiceItem>
                <AboutServiceLabel htmlFor="service03">
                  위치기반 서비스 이용약관 <Highlight>[필수]</Highlight>
                </AboutServiceLabel>
                <ViewBtn
                  className="service-desc"
                  onClick={(e) => {
                    setLocationInfo((prev) => !prev);
                  }}
                >
                  [전문 보기]
                </ViewBtn>
              </AboutServiceItem>
              <AboutServiceItem>
                <AboutServiceLabel htmlFor="service04">
                  마케팅 정보 수집 및 이용 동의 <>[선택]</>
                </AboutServiceLabel>
                <ViewBtn
                  className="service-desc"
                  onClick={(e) => {
                    setMarketingInfo((prev) => !prev);
                  }}
                >
                  [전문 보기]
                </ViewBtn>
              </AboutServiceItem>
            </AboutServiceList>
            {(termsOfUse || personalData || locationInfo || marketingInfo) && (
              <div className="policy-container settings" ref={policyRef}>
                {termsOfUse && (
                  <TermOfUsePolicy setTermsOfUse={setTermsOfUse} />
                )}
                {personalData && (
                  <PersonalPolicy setPersonalData={setPersonalData} />
                )}
                {locationInfo && (
                  <LocationPolicy setLocationInfo={setLocationInfo} />
                )}
                {marketingInfo && (
                  <MarketingPolicy
                    setMarketingInfo={setMarketingInfo}
                    policyRef={policyRef}
                  />
                )}
              </div>
            )}
          </AboutServiceContainer>
        </ModalBody>
      </Modal>
    </Wrap>,
    document.getElementById("modal")
  );
}

/* 기존 애니메이션 */
const openModal = keyframes`
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
`;
const Wrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
`;

const Modal = styled.div`
  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;

  width: 100%;
  max-width: 100%;
  height: 100% !important;
  max-height: 100vh !important;

  position: relative;
  background: #fff;
  transform: translateY(0);
  opacity: 0;
`;

const ModalHeader = styled.div`
  display: flex !important;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 7px;
  padding-right: 16px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;
`;

const Title = styled.strong`
  display: inline-block;
  width: auto;
  font-size: 1rem;
  color: var(--text-defaultBlackColor);
  font-weight: 500;
  /* line-height: 50px; */
  line-height: normal !important;
  width: 70%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-self: flex-end;
`;

const Button = styled.button`
  display: block;
  width: 27px;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background: url(/static/media/img_pre.2990dcb3.svg) no-repeat;
  background-color: #fff;
  background-position: left center;
`;

const ModalBody = styled.div`
  height: calc(100% - 50px);
  max-height: none !important;
  overflow-y: auto;
`;

const AboutServiceContainer = styled.div`
  padding: 10px 16px;
`;

const AboutServiceList = styled.div`
  font-size: 100%;
`;

const AboutServiceItem = styled.div`
  margin: 1rem 0;
  display: flex;
`;

const AboutServiceLabel = styled.label`
  font-size: 0.8rem;
  line-height: 1.3rem;
  margin-left: 0.3rem;
`;

const Highlight = styled.span`
  color: #cf514a; ;
`;

const ViewBtn = styled.button`
  margin-left: auto;
  cursor: pointer;
  background-color: transparent;
  color: #666;
  font-weight: bold;
  font-size: 13px;
`;

// over_age terms_of_use personal_data location_info marketing_info
