import React, { Fragment } from "react";

export default function CategoryContainer({
  categories,
  setCategories,
  setTargetCategory,
  tabState,
}) {
  const handleChangeCategory = (e) => {
    setTargetCategory({
      categoryName: e.target.value,
      checked: e.target.checked,
    });

    setCategories((prev) =>
      prev.map((category) => {
        if (category.categoryName === e.target.value)
          return Object.assign({}, category, { checked: e.target.checked });

        return category;
      })
    );
  };

  return (
    <div className="write-item">
      <div className="input-title">
        {tabState ? (
          <strong className="ttl">어느 분야를 제보하시나요?</strong>
        ) : (
          <strong className="ttl">제보요청을 남겨보세요.</strong>
        )}
        <span className="desc">{tabState}*복수 선택 가능</span>
      </div>

      <div className="input-group type tab-btns">
        {categories.map((category) => {
          return (
            <Fragment key={category.categoryCode}>
              <input
                type="checkbox"
                className={`type-check type${category.categoryCode}`}
                name="report_type"
                id={`check${category.categoryCode}`}
                onChange={handleChangeCategory}
                value={category.categoryName}
                checked={category.checked}
              />
              <label htmlFor={`check${category.categoryCode}`}>
                {category.categoryName}
              </label>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}
