import heart from "assets/imgs/icons/icon_favRed.svg";
import emptyHeart from "assets/imgs/icons/icon_favRedBorder.svg";
import iconMember from "assets/imgs/icons/icon_joinUser.svg";
import iconMemberActive from "assets/imgs/icons/icon_joinUserActive.svg";

import iconPostCount from "assets/imgs/icons/icon_feedcount.svg";
import iconPostCountActive from "assets/imgs/icons/icon_feedcountActive.svg";

import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import styled, { css } from "styled-components";

export default function SocialItemList2({
  item,
  category,
  toggleLike,
  dispatchContent,
  filter,
  sortType,
}) {
  const history = useHistory();
  const location = useLocation();
  const { isLoggedIn } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);

  return (
    <>
      <ContentSortContainer>
        {isLoggedIn && (
          <>
            <ContentSortInput
              id="joinedChk"
              type="checkbox"
              onChange={(e) => {
                console.log(e.target.checked);

                dispatchContent({
                  type: "CHANGE_FILTER",
                  filter: { joined: e.target.checked },
                });
              }}
              checked={filter && filter.joined}
            ></ContentSortInput>
            <ContentSortlabel htmlFor="joinedChk">
              {category.title === "테마" || category.title === "캠페인"
                ? `참여한 ${category.title}`
                : category.title === "채널"
                ? `구독한 ${category.title}`
                : `가입한 ${category.title}`}
            </ContentSortlabel>
          </>
        )}
        <SortBtn
          className={sortType === 0 ? "first active" : "first"}
          onClick={() => {
            dispatchContent({
              type: "CHANGE_SORT",
              sortType: 0,
            });
          }}
        >
          인기순
        </SortBtn>
        <SortBtn
          className={sortType === 1 ? "active" : ""}
          onClick={() => {
            dispatchContent({
              type: "CHANGE_SORT",
              sortType: 1,
            });
          }}
        >
          최신순
        </SortBtn>
      </ContentSortContainer>

      <PullToRefresh
        onRefresh={async (e) => {
          dispatchContent({ type: "REFRESH_DATA" });
        }}
        pullingContent=""
        refreshingContent={
          <div className="feed-spinner">
            <span></span>
            <span></span>
            <span></span>
          </div>
        }
      >
        {item.length < 1 ? (
          <EmptyDataCt>
            {category.title === "채널"
              ? `구독한 채널이 없습니다.`
              : category.title === "커뮤니티"
              ? `가입한 커뮤니티가 없습니다.`
              : category.title === "테마"
              ? `참여한 테마가 없습니다.`
              : `참여한 캠페인이 없습니다.`}
          </EmptyDataCt>
        ) : (
          item.map((data, index) => (
            <ItemWrapper
              index={index}
              onClick={(e) => {
                history.push({
                  ...location,
                  pathname: `/social/${category.name}/${data.scId}`,
                });
              }}
              category={category}
            >
              <ContentWrapper>
                <InfoWrap>
                  <HashtagWrapper>
                    {data.scTag &&
                      data.scTag
                        .split(" ")
                        .filter((_, index) => index < 3)
                        .map((tag, index) => (
                          <Hashtag key={index}>{tag}</Hashtag>
                        ))}
                  </HashtagWrapper>
                  <Title>{data.scName}</Title>
                  <JoinInfoWrap>
                    <HeartBox>
                      <a
                        href="#!"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          isLoggedIn
                            ? toggleLike(data)
                            : setSignLayer("signIn");
                        }}
                      >
                        <img src={data.like ? heart : emptyHeart} alt="" />
                      </a>
                      <h3>{data.likeCount}</h3>
                    </HeartBox>

                    <MemberCountBox>
                      {/* <AiOutlineUser className="AiOutlineUser" /> */}
                      <img
                        src={
                          data.roleName !== "ANONYMOUS"
                            ? iconMemberActive
                            : iconMember
                        }
                        alt=""
                      />
                      <h3>{data.memberCount}</h3>
                    </MemberCountBox>

                    <PostCountBox>
                      <img
                        src={
                          data.postCnt > 0 ? iconPostCountActive : iconPostCount
                        }
                        alt=""
                      />
                      <h3>{data.postCnt}</h3>
                    </PostCountBox>

                    <Time>{data.createAt}</Time>
                  </JoinInfoWrap>
                </InfoWrap>
              </ContentWrapper>
            </ItemWrapper>
          ))
        )}
      </PullToRefresh>
    </>
  );
}
const ItemWrapper = styled.div`
  overflow: hidden;
  ${({ index }) => {
    if (index === 4) {
      return css`
        border-bottom: none;
      `;
    }
  }};

  &:hover {
    cursor: pointer;
  }

  width: 100%;
  padding: 13px 0 14px;
  border-top: 1px solid #dcdcdc;
`;

const InfoWrap = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
`;

const JoinInfoWrap = styled.div`
  display: flex;
  align-items: center;
`;

const MemberCountBox = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 14px;
  margin-right: 11px;
  & > :first-child {
    margin-right: 5px;
  }
  & img {
    margin-top: 0.5px;
    width: 13px;
    height: 12.5px;
  }
`;

const PostCountBox = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  margin-right: 11px;
  & > :first-child {
    margin-right: 5px;
  }
  img {
    width: 14px;
    height: 12.84px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HashtagWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  margin-bottom: 9px;
`;

const Hashtag = styled.p`
  display: block;

  max-width: 85px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-weight: 600;
  font-size: 10px;
  line-height: 14px;

  color: #3773ff;

  margin-right: 10px;
`;

const Title = styled.p`
  overflow: hidden;
  white-space: normal;

  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000000;

  width: 100%;

  margin-bottom: 11px;

  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const HeartBox = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  margin-right: 11px;

  & > :first-child {
    margin-right: 2px;
  }
  img {
    margin-right: 5px;
    margin-top: 3px;
    width: 14px;
    height: 14px;
  }
`;

const Time = styled.p`
  display: inline-block;
  margin-left: auto;

  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #b3afac;
`;

const ContentSortContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  margin-bottom: 15px;
`;

const ContentSortInput = styled.input`
  /* margin-left: auto; */
`;
const ContentSortlabel = styled.label`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #2d2d2d;
  margin-right: auto;

  @media screen and (min-width: 481px) {
    font-size: 14px;
    margin-left: 5px;
  }
`;

const SortBtn = styled.button`
  background-color: transparent;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #c0b9b9;
  font-weight: 500;
  &.active {
    color: #2f9bfa;
    font-weight: 600;
  }
  &.first {
    margin-left: auto;
  }

  @media screen and (min-width: 481px) {
    font-size: 13px;
  }
`;
const EmptyDataCt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 55vh;

  font-size: 12px;
  color: #a3a3a3;
  text-align: center;

  background: #fff;

  @media screen and (min-width: 481px) {
    font-size: 15px;
  }
`;
