import React, { useRef } from "react";
import "react-image-lightbox/style.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player/lazy";

export default function GridMediaBox({ mediaType, mediaUrl }) {
  const refImage = useRef();
  const refVideo = useRef();

  return (
    <>
      {mediaType === "image" ? (
        <LazyLoadImage
          itemRef={refImage}
          alt=""
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover", //"contain",
            margin: "auto",
            backgroundColor: "#f8f8f8",
          }}
          src={mediaUrl}
        />
      ) : (
        <ReactPlayer
          ref={refVideo}
          className="react-player"
          controls={false}
          controlsList="nodownload"
          loop={false}
          muted={true}
          autoPlay={false}
          playsInline={true}
          type="video/*"
          url={`${mediaUrl}#t=0.5`}
          width="100%"
          height="100%"
          style={{
            objectFit: "cover", //"contain",
            margin: "auto",
            backgroundColor: "#f8f8f8",
          }}
        />
      )}
    </>
  );
}
