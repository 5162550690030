import React, { createContext } from "react";

export const socialContentContext = createContext({
  socials: null,
  setSocials: () => {},
});

export default function SocialContentContextProvider({
  socials,
  children: Components,
}) {
  return (
    <socialContentContext.Provider value={socials}>
      {Components}
    </socialContentContext.Provider>
  );
}
