import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import FeedMainContent from "./FeedMainContent";
import FeedMainTag from "./FeedMainTag";
import FeedMainText from "./FeedMainText";
import styled from "styled-components";

import Lcampaign from "assets/imgs/icons/icon_socialCampaign.svg";
import Lchannel from "assets/imgs/icons/icon_socialChannel.svg";
import Lcommunity from "assets/imgs/icons/icon_socialCommu.svg";
import Ltheme from "assets/imgs/icons/icon_socialTheme_full.svg";

export default function FeedMain({ feed, page }) {
  const { jeboMediaList: jeboMedia, social } = feed;

  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const gotoSocial = (scType) => {
    if (social.scType === 1) {
      history.push({
        pathname: "/social/theme",
      });
      // 테마
    } else if (social.scType === 2) {
      // 캠페인
      history.push({
        pathname: "/social/campaign",
      });
    } else if (social.scType === 3) {
      // 채널
      history.push({
        pathname: "/social/channel",
      });
    } else if (social.scType === 4) {
      // 커뮤니티
      history.push({
        pathname: "/social/community",
      });
    }
  };

  return (
    <div className="item-body-wrap">
      <div className="item-body">
        {social && (
          <SocialDiv
            scType={social.scType}
            onClick={(e) => {
              gotoSocial(social.scType);
            }}
          >
            <p>
              {social.scType === 1
                ? "테마"
                : social.scType === 2
                ? "캠페인"
                : social.scType === 3
                ? "채널"
                : social.scType === 4
                ? "커뮤니티"
                : "제보큐브"}
              {`: ${social.scName}`}
            </p>
          </SocialDiv>
        )}
        {feed.feedType === 0 || feed.feedType === 2 ? (
          <>
            <FeedMainContent
              feed={feed}
              page={page}
              scType={social && social.scType}
            />
            {feed.mediaCnt > 0 && (
              <div
                className="item-info"
                // style={
                //   page === "member" || page === "mypage"
                //     ? { height: "auto" }
                //     : null
                // }
                // 해당 코드를 넣었을 때 피드 아이템의 높이가 설정값 이상으로 늘어나서 타 ui 영역을 침범하여 주석처리
                onClick={(e) => {
                  e.stopPropagation();

                  history.push({
                    pathname: `${match.url}/detail/${feed.jeboId}`,
                    search: location.search,
                  });
                }}
              >
                {feed && feed.hashTag && <FeedMainTag feed={feed} />}
                <FeedMainText feed={feed} page={page} grid={false} />
              </div>
            )}
          </>
        ) : (
          <>
            <div className="item-photo share">
              <div
                className="share__textContainer"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push({
                    pathname: `${match.url}/detail/${feed.jeboId}`,
                    search: location.search,
                  });
                }}
              >
                <span className="share__title">#제보요청</span>
                <div className="share__textBox">
                  <div className="share__desc">
                    {/* {feed && feed.hashTag && <FeedMainTag feed={feed} />} */}
                    <FeedMainText feed={feed} page={page} grid={false} />
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const SocialDiv = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 1;
  /* width: 100%; */
  height: 40px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  //
  opacity: 0.9;
  width: 95%;
  border-radius: 0 0 20px 0;
  //

  background-size: contain;
  background-color: ${(props) =>
    props.scType === 1
      ? "#8E57A0"
      : props.scType === 2
      ? "#3498DB"
      : props.scType === 3
      ? "#E9971E"
      : props.scType === 4
      ? "#2FA55D"
      : "#fff"};

  & p {
    display: inline-block;
    width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    font-weight: 400;
    color: #fff;
    font-size: 13px;
  }
`;
