import jeboApi from "api/api";
import Layers from "components/layers/Layers";
import Header from "components/pages/app/layouts/Header";
import FeedSection from "components/pages/around/FeedSection";
import Sections from "components/pages/around/layouts/Sections";
import MapSection from "components/pages/around/MapSection";
import AroundContextProvider from "components/pages/around/provider/AroundContextProvider";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { activeLocationContext } from "components/providers/LocationContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import qs from "query-string";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

export default function Around() {
  const { activeLocation } = useContext(activeLocationContext);
  const { isMobile } = useContext(isMobileContext);
  const { handleSignOutApp } = useContext(signAppContext);

  const [feeds, setFeeds] = useState([]);
  const [markerPositions, setMarkerPositions] = useState([]);
  const [layer, setLayer] = useState(null);

  const [mapRect, setMapRect] = useState(null);
  const [mapRegion, setMapRegion] = useState("");

  const [callFromClusterer, setCallFromClusterer] = useState(false);

  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const { latitude, longitude, eventId } = qs.parse(location.search);

  const [searchPosition, setSearchPosition] = useState({
    latitude: latitude
      ? latitude
      : activeLocation
      ? activeLocation.latitude
      : 37.5546788388674,
    longitude: longitude
      ? longitude
      : activeLocation
      ? activeLocation.longitude
      : 126.970606917394,
  });

  const [eventInfo, setEventInfo] = useState(null);

  const getEventName = useCallback(async () => {
    if (!eventId) {
      setEventInfo(null);
      return;
    }

    try {
      const {
        data: { event },
        status,
      } = await jeboApi.getEventName(eventId);

      if (status === 200) {
        const eventInfo = JSON.parse(event);
        setEventInfo(eventInfo);
      } else {
        setEventInfo(null);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  }, [eventId]);

  useEffect(() => {
    getEventName();
  }, [getEventName]);

  const onExpiredSession = () => {
    alert("로그인 세션이 만료됐습니다. 다시 로그인해주세요.");
    handleSignOutApp();
    history.replace({ pathname: `${match.url}/sign` });
  };

  return (
    <>
      <AroundContextProvider
        feeds={{ feeds, setFeeds }}
        layer={{ layer, setLayer }}
        session={{ onExpiredSession }}
      >
        {!isMobile && <Header />}
        <Sections>
          <MapSection
            markerPositions={markerPositions}
            setMapRect={setMapRect}
            setMapRegion={setMapRegion}
            searchPosition={searchPosition}
            setSearchPosition={setSearchPosition}
            setCallFromClusterer={setCallFromClusterer}
          />
          <FeedSection
            mapRegion={mapRegion}
            mapRect={mapRect}
            setMarkerPositions={setMarkerPositions}
            setSearchPosition={setSearchPosition}
            callFromClusterer={callFromClusterer}
            setCallFromClusterer={setCallFromClusterer}
            eventInfo={eventInfo}
          />
        </Sections>
        <Layers />
      </AroundContextProvider>
    </>
  );
}
