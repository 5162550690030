import SockJS from "sockjs-client";
import Stomp from "stompjs";

const DirectMessageService = {
  init: (setSocket, setClient, onOpen, onClose) => {
    const socket = new SockJS(
      `${process.env.REACT_APP_API_HOST}/socket/directMessage`
    );
    socket.onclose = onClose;
    socket.onopen = onOpen;
    setSocket(socket);

    const client = Stomp.over(socket);
    //client.heartbeat.incoming = 20000;
    //client.heartbeat.outgoing = 20000;
    client.reconnect_delay = 1000;
    setClient(client);
  },
  disconnect: (socket, client) => {
    client && client.connected && client.disconnect(() => {});
    socket && socket.close();
  },
  connect: (client, setIsConnectedSocket) => {
    client &&
      client.connect(
        { login: "", passcode: "" },
        (frame) => {
          setIsConnectedSocket(true);
        },
        (error) => {
          setIsConnectedSocket(false);
          console.log("connect msg :::: ", error);
        }
      );
  },
  subscribe: (client, url, callback) => {
    if (client) {
      return client.subscribe(url, callback);
    }
    return null;
  },
  unsubscribe: (subscription) => {
    subscription && subscription.unsubscribe();
  },
  sendMessage: (
    client,
    toMemberUid,
    message,
    communicationId,
    jeboId,
    fromNickName
  ) => {
    const fromMemberUid = sessionStorage.getItem("memberUid");

    if (fromMemberUid === "undefined") {
      alert("계정 만료. 다시 로그인해주세요.");
      return;
    }

    let directMessage = {
      fromMemberUid,
      toMemberUid,
      message,
      communicationId,
      jeboId,
      fromNickName,
    };

    if (client && client.connected) {
      client.send("/app/sendDirectMessage", {}, JSON.stringify(directMessage));
    }
  },
  sendAlarm: (client) => {
    const toMemberUid = sessionStorage.getItem("memberUid");
    let directMessage = {
      toMemberUid,
    };
    if (client && client.connected) {
      client.send("/app/sendAlarm", {}, JSON.stringify(directMessage));
    }
  },
  sendReadYn: (client, toMemberUid) => {
    let directMessage = {
      toMemberUid,
    };

    if (client && client.connected) {
      client.send("/app/sendAlarm", {}, JSON.stringify(directMessage));
    }
  },
  sendNotification: (client, toMemberUid) => {
    if (client && client.connected) {
      client.send("/app/sendNotification", {}, JSON.stringify({ toMemberUid }));
    }
  },
};

export default DirectMessageService;
