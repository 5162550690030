import Layers from "components/layers/Layers";
import LocationSearchLayer from "components/layers/locationSearch/LocationSearchLayer";
import Header from "components/pages/app/layouts/Header";
import BannerSection from "components/pages/home/BannerSection";
import FeedSection from "components/pages/home/FeedSection";
import LandingMessageSection from "components/pages/home/LandingMessageSection";
import Sections from "components/pages/home/layouts/Sections";
import MobileLocationSection from "components/pages/home/MobileLocationSection";
import HomeContextProvider from "components/pages/home/provider/HomeContextProvider";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import QuickJeobPreview from "components/templates/QuickJeboPreview";
import SideNavButton from "components/templates/SideNavButton";
import { useContext, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

// const feedReducer = (state, action) => {
//   switch (action.type) {
//     case "FETCH_FEEDS":
//       return {
//         ...state,
//         feeds:
//           state.cursor.start >= 0
//             ? [...state.feeds, ...action.feeds]
//             : action.feeds,
//       };

//     case "CHANGE_CURSOR":
//       return {
//         ...state,
//         cursor: { ...state.cursor, start: action.start },
//       };

//     case "CHANGE_SORT":

//     case "CHANGE_CATEGORY":

//     case "UPDATE_FEED":

//     case "REMOVE_FEED":

//     case "HIDDEN_FEED":

//     case "KEEP_FEED":

//     case "BLOCK_FEED":
//     default:
//       return state;
//   }
// };

export default function Home() {
  const { isMobile } = useContext(isMobileContext);
  const { handleSignOutApp } = useContext(signAppContext);

  const [feeds, setFeeds] = useState([]);
  const [layer, setLayer] = useState(null);

  const [previewImg, setPreviewImg] = useState(null);
  const [showLocationSearchLayer, setShowLocationSearchLayer] = useState(false);

  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    window.scrollTo(
      0,
      window.scrollY && window.scrollY > 0 ? window.scrollY - 1 : -1
    );
  }, []);

  const onExpiredSession = () => {
    alert("로그인 세션이 만료됐습니다. 다시 로그인해주세요.");
    handleSignOutApp();
    history.replace({ pathname: `${match.url}/sign` });
  };

  return (
    <>
      <HomeContextProvider
        feeds={{ feeds, setFeeds }}
        layer={{ layer, setLayer }}
        session={{ onExpiredSession }}
      >
        <Header />
        <Sections>
          {isMobile && (
            <MobileLocationSection
              setShowLocationSearchLayer={setShowLocationSearchLayer}
            />
          )}
          {!isMobile && <LandingMessageSection />}
          {!isMobile && <BannerSection />}
          <FeedSection onExpiredSession={onExpiredSession} />
        </Sections>
        {previewImg && (
          <QuickJeobPreview
            previewImg={previewImg}
            setPreviewImg={setPreviewImg}
          />
        )}
        <SideNavButton setPreviewImg={setPreviewImg} previewImg={previewImg} />
        {showLocationSearchLayer && (
          <LocationSearchLayer
            setShowLocationSearchLayer={setShowLocationSearchLayer}
          />
        )}

        <Layers />
      </HomeContextProvider>
    </>
  );
}
