import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

const regDateCalculate = (date) => {
  const year = date.split(" ")[0].split("-")[0];
  const month = date.split(" ")[0].split("-")[1] - 1;
  const day = date.split(" ")[0].split("-")[2];
  const hours = date.split(" ")[1].split(":")[0];
  const seconds = date.split(" ")[1].split(":")[1];

  const now = new Date();
  const timeValue = new Date(year, month, day, hours, seconds);

  let betweenTime = Math.floor(
    (now.getTime() - timeValue.getTime()) / 1000 / 60
  );
  let betweenTimeHour = Math.floor(betweenTime / 60);
  let betweenTimeDay = Math.floor(betweenTime / 60 / 24);

  if (betweenTime < 1) {
    return "방금전";
  } else if (betweenTime < 60) {
    return `${betweenTime}분전`;
  } else if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간전`;
  } else {
    return `${betweenTimeDay}일전`;
  }
};

export default function MobileMyHistoryList({ actInfo, majorType, subType }) {
  const history = useHistory();
  const match = useRouteMatch();

  const attendList = ["CP", "TM", "CH", "CM"];

  return majorType === "COMMENT" ? (
    <CommentContent actInfo={actInfo} />
  ) : majorType === "UP" ? (
    <UpContent actInfo={actInfo} subType={subType} />
  ) : majorType === "ZZIM" ? (
    <ZzimContent actInfo={actInfo} />
  ) : attendList.includes(majorType) ? (
    <SocialContent actInfo={actInfo} />
  ) : null;
}

export function CommentContent({ actInfo }) {
  const history = useHistory();
  const match = useRouteMatch();
  return (
    <Container
      onClick={(e) => {
        e.preventDefault();
        history.push({
          pathname: `${match.url}/detail/${actInfo.jeboId}`,
          search: `?focusType=comment&focusId=${actInfo.commentId}`,
        });
      }}
    >
      <Contents>
        <Desc>{actInfo.contents}</Desc>
      </Contents>
      <AttendCreateAt>
        <AttendTime>{regDateCalculate(actInfo.createAt)}</AttendTime>
      </AttendCreateAt>
    </Container>
  );
}

export function UpContent({ actInfo, subType }) {
  const history = useHistory();
  const match = useRouteMatch();
  return (
    <Container
      onClick={(e) => {
        e.preventDefault();
        history.push({
          pathname: `${match.url}/detail/${actInfo.jeboId}`,
          search: `?focusType=comment&focusId=${actInfo.commentId}`,
        });
      }}
    >
      <ActImage>
        <Image
          round={true}
          src={
            actInfo.memberImageUrl
              ? process.env.REACT_APP_RESOURCE_HOST + actInfo.memberImageUrl
              : "/static/media/user_icon.fcd9ee8a.svg"
          }
          alt="프로필 사진"
        />
      </ActImage>
      <Contents>
        <Title>{actInfo.activityName}</Title>
        <Desc>
          {subType === "COMMENT_UP"
            ? `[${actInfo.toNickName}] 님의 ${
                actInfo.hasParents && actInfo.hasParents !== "0"
                  ? "답글"
                  : "댓글"
              } 에 공감합니다.`
            : subType === "FEED_UP"
            ? `[${actInfo.toNickName}] 님의 게시물에 공감합니다.`
            : `${actInfo.contents}`}
        </Desc>
      </Contents>
      <AttendCreateAt>
        <AttendTime>{regDateCalculate(actInfo.createAt)}</AttendTime>
      </AttendCreateAt>
    </Container>
  );
}

export function ZzimContent({ actInfo }) {
  const history = useHistory();

  const mediaPath = "/media/social/";
  const middlePath =
    actInfo.activityType === "1"
      ? "theme"
      : actInfo.activityType === "2"
      ? "campaign"
      : actInfo.activityType === "3"
      ? "channel"
      : actInfo.activityType === "4"
      ? "community"
      : "theme";
  return (
    <Container
      onClick={(e) => {
        actInfo.scStatus !== 10
          ? history.push({
              pathname: `/social/${middlePath}/${actInfo.activityId}`,
              state: { from: "myact" },
            })
          : alert("폐쇄된 목록 입니다.");
      }}
    >
      <ActImage>
        <Image
          src={
            process.env.REACT_APP_RESOURCE_HOST +
            mediaPath +
            actInfo.activityImage
          }
          alt="피드 사진"
        />
      </ActImage>
      <Contents>
        <Title>{actInfo.activityName}</Title>
        <Desc>{actInfo.contents}</Desc>
      </Contents>
    </Container>
  );
}

export function SocialContent({ actInfo }) {
  const history = useHistory();
  const match = useRouteMatch();
  const mediaPath = "/media/social/";

  return (
    <Container
      onClick={(e) => {
        e.preventDefault();
        history.push({
          pathname: `${match.url}/detail/${actInfo.jeboId}`,
          search: `?focusType=comment&focusId=${actInfo.commentId}`,
        });
      }}
    >
      <ActImage>
        <Image
          src={
            process.env.REACT_APP_RESOURCE_HOST +
            mediaPath +
            actInfo.activityImage
          }
          alt="피드 사진"
        />
      </ActImage>

      <Contents>
        <Title>{actInfo.activityName}</Title>
        <Desc>`${actInfo.contents}`</Desc>
      </Contents>

      <AttendCreateAt>
        <AttendTime>{regDateCalculate(actInfo.createAt)}</AttendTime>
      </AttendCreateAt>
    </Container>
  );
}

//styled-component 정의
const Container = styled.div`
  background-color: #fff;
  padding-left: 12px;
  padding-right: 12px;
  height: 60px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #4e4e4e5e;
  cursor: pointer;
`;

const ActImage = styled.div`
  width: auto;
  margin-right: 10px;
  flex: 0 0 auto;
  margin-left: auto;
  display: flex;
`;

const Image = styled.img`
  width: 42px;
  height: 42px;
  margin-right: 1px;
  border-radius: ${(props) => (props.round ? "50%" : "0%")};
`;

const Contents = styled.p`
  width: 78%;
  margin-right: 16px;
  flex: 1 1 auto;
  word-break: break-all;
  font-size: 16px;
  line-height: 22px;
`;

const Title = styled.span`
  max-height: 30px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  white-space: pre-line;
  word-wrap: break-word;

  font-size: 93%;
  font-weight: 500;
  /* margin: 0 13px 5px 3px; */
`;

const Desc = styled.span`
  max-height: 30px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  white-space: pre-line;
  word-wrap: break-word;

  font-size: 85%;
  line-height: 15px;
  margin-left: 3px;
  margin-right: 13px;
  color: #6c6c6c;
`;

const AttendCreateAt = styled.p`
  width: 7%;
  text-align: center;
  margin-right: 16px;
  flex: 1 1 auto;
  word-break: break-all;
  font-size: 16px;
  line-height: 22px;
  color: #4e4e4e;
`;

const AttendTime = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: block;
  word-break: keep-all;
  color: #595959;
`;
