import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { convenienceDataContext } from "components/providers/ConvenienceDataContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { Base64 } from "js-base64";
import { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Fragment } from "react";
import { feedsContext } from "components/providers/FeedContextProvider";

export default function FeedDetailText({ CurFeed }) {
  const history = useHistory();
  const { feeds, setFeeds } = useContext(feedsContext);
  const { setSignLayer } = useContext(signLayerContext);
  const { isLoggedIn, myInfo } = useContext(signAppContext);
  const { contents, jeboMediaList: jeboMedia, locationHashtag } = CurFeed;
  const { mobileType } = useContext(isMobileContext);
  const { hashKeywords } = useContext(convenienceDataContext);
  const isCustomWord = useCallback(function checkWord(word) {
    return "#눌러서바로참여" === word;
  }, []);
  const isConMap = useCallback(
    function checkWord(word) {
      return hashKeywords ? [...hashKeywords].includes(word) : false;
    },
    [hashKeywords]
  );

  return (
    <div className={jeboMedia.length >= 1 ? `text` : "text noMedia"}>
      <p className="noMedia__text" style={{ whiteSpace: "pre-line" }}>
        {contents &&
          (contents + " " + (locationHashtag ? locationHashtag : ""))
            .split(/([#@http(s)][^\s\n]+)/g)
            .map((word, idx) => {
              if (word.match(/(^#[^\s\n]+)/g)) {
                return (
                  <a
                    key={idx}
                    href="#!"
                    //진희님보세요
                    className={
                      isConMap(word)
                        ? "text-highlight"
                        : isCustomWord(word)
                        ? "text-highlight"
                        : "text-normal"
                    }
                    style={{
                      color: isConMap(word)
                        ? "tomato"
                        : isCustomWord(word)
                        ? "#10B755"
                        : "#5293c5",
                      position: "relative",
                      width: "min-content",
                    }}
                    //style={{ color: "#5293c5" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      // 23.05.12 codelua 하드코딩 #눌러서바로참여 해시태그는 로그인이 안되어있을 때 회원가입창으로 유도.
                      if (word === "#눌러서바로참여" && !isLoggedIn) {
                        setSignLayer("signUp");
                        return;
                      } else {
                        console.log(
                          ">>>> DetailText sessionStorage Set Item..!!"
                        );
                        //23.05.16. codelua 태그를 눌러서 이동하고 home으로 돌아오는 경우에 한해 피드의 상태를 돌려주기 위함.
                        sessionStorage.setItem(
                          "homeFeedData",
                          JSON.stringify(feeds)
                        );
                        sessionStorage.setItem(
                          "homeFeedScrollY",
                          window.scrollY
                        );
                        history.push({
                          pathname: `/search/${encodeURIComponent(word)}`,
                        });
                      }
                    }}
                  >
                    {`${word} `}
                  </a>
                );
              } else if (
                word.match(
                  /((^http(s)?:\/\/)([\w]+[\.:]*)+[\w]{2})([\/\w-%#?&=])+(\.[\w]{2,4})*/g
                )
              ) {
                var urlStr = [];
                urlStr.push(
                  word.match(
                    // /((^http(s)?:\/\/)([\w]+[\.:]*)+[\w]{2})([\/\w-%#?&=])+(\.[\w]{2,4})*/g
                    /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}([\/a-z0-9-%#?&=\w])+(\.[a-z0-9]{2,4}(\?[\/a-z0-9-%#?&=\w]+)*)*/g
                  )
                );
                urlStr.push(word.replace(urlStr[0], ""));
                return urlStr.map((splWord, idx) => {
                  if (idx === 0) {
                    return (
                      <a
                        key={idx}
                        rel="noreferrer"
                        href="_EXTERNALURL"
                        style={{
                          color: "#191CEE",
                          wordWrap: "break-word",
                          wordBreak: "keep-all",
                          position: "relative",
                          width: "min-content",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          const url = splWord;
                          if (mobileType === "ios") {
                            const encodedUrl = Base64.encode(url);
                            window.webkit.messageHandlers.openOuterLink.postMessage(
                              encodedUrl
                            );
                          } else {
                            window.open(url);
                          }
                        }}
                      >
                        {splWord}
                      </a>
                    );
                  } else {
                    return <Fragment key={idx}>{`${splWord}`}</Fragment>;
                  }
                });
              }
              return word;
            })}
      </p>
    </div>
  );
}

// {hashTag &&
//   hashTag
//     .split(",")
//     .some((hashTagName) =>
//       feedCategories.some(
//         (feedCategory) =>
//           feedCategory.categoryName === hashTagName.trim()
//       )
//     ) ? (
//     feedCategories.map(
//       (feedCategory, index) =>
//         hashTag
//           .split(",")
//           .find((hashTagName) =>
//             feedCategories.some(
//               (feedCategory) =>
//                 feedCategory.categoryName === hashTagName.trim()
//             )
//           )
//           .trim() === feedCategory.categoryName && (
//           <div className="item-photo share">
//             <div
//               className={`share__textContainer default-img type${feedCategory.categoryCode}`}
//             >
//               <div className="share__textBox">
//                 <div className="share__desc"></div>
//               </div>
//           </div>
//         </div>
//       )
//   )
// ) : (
//   <div className="item-photo share">
//     <div className={`share__textContainer default-img type00`}>
//       <div className="share__textBox">
//         <div className="share__desc"></div>
//       </div>
//     </div>
//   </div>
// )}
