import { Fragment } from "react";

export default function ConfirmLayer({
  setShowConfirmLayer,
  callback,
  title,
  description,
  btnText,
  isConfirm,
  dispatch,
}) {
  return (
    <div className="jeboRegiModal">
      <div className="pop-menu item-pop delete active" id="delete_1">
        <div className="pop-header">
          <strong>
            {title.split("\\n").map((txt, index) => (
              <Fragment key={index}>
                {txt}
                <br />
              </Fragment>
            ))}
          </strong>
        </div>
        <div className="pop-body">
          {description && <span className="txt">{description}</span>}
          <div className="btns">
            <button
              type="button"
              className="cancel"
              onClick={(e) => setShowConfirmLayer(false)}
            >
              취소
            </button>
            <button
              type="button"
              className={isConfirm ? "confirm" : "delete"}
              onClick={(e) => {
                setShowConfirmLayer(false);
                callback && callback();
              }}
            >
              {btnText ? btnText : "확인"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
