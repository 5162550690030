import iconComment from "assets/imgs/icons/icon_comment.svg";
import iconThumUpActive from "assets/imgs/icons/icon_thumUp.svg";
import iconThumUp from "assets/imgs/icons/icon_thumUpDefault.svg";

import { useHistory, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

export default function SocialItemBox({ item, category, toggleLike }) {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <HotItemContents>
        <HotItemTitle>
          실시간 <span>인기글</span>
        </HotItemTitle>
        {item &&
          item.map((data, index) => (
            <ItemWrapper
              index={index}
              onClick={(e) => {
                history.push({
                  ...location,
                  pathname: `/social/${category.name}/detail/${data.jeboId}`,
                });
              }}
            >
              <ContentWrapper>
                <>
                  <InfoWrap>
                    <TextWrapper>
                      <Title>{data.contents}</Title>
                    </TextWrapper>
                    <UserInfoCt>
                      <HashtagWrapper>
                        {data.hashTag &&
                          data.hashTag
                            .split(" ")
                            .filter((_, index) => index < 3)
                            .map((tag, index) => (
                              <Hashtag key={index}>{tag}</Hashtag>
                            ))}
                      </HashtagWrapper>

                      <HeartBox>
                        <div
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   e.stopPropagation();
                        // toggleLike(data);
                        // }}
                        >
                          <img
                            src={data.like ? iconThumUpActive : iconThumUp}
                            alt=""
                          />
                        </div>
                        <h3>{data.likeCount}</h3>
                      </HeartBox>
                      <MemberCountBox>
                        <img src={iconComment} alt="" />
                        <h3>{data.commentCount}</h3>
                      </MemberCountBox>
                    </UserInfoCt>
                  </InfoWrap>
                </>
              </ContentWrapper>
            </ItemWrapper>
          ))}
      </HotItemContents>
    </>
  );
}

const HotItemContents = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 220px; */
  overflow: hidden;
`;

const HotItemTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 18px;
  & span {
    color: #2f9bfa;
  }
`;
const ItemWrapper = styled.div`
  overflow: hidden;
  ${({ index }) => {
    if (index === 4) {
      return css`
        border-bottom: none;
      `;
    }
  }};

  &:hover {
    cursor: pointer;
  }

  flex: 0 0 auto;
  width: 100%;
  height: 65px;
  padding: 14px 9px 9px 13px;
  background-color: #f4f9fe;
  border-radius: 4px;
  margin: 4px 0;
`;

const InfoWrap = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
`;

const MemberCountBox = styled.div`
  display: flex;
  font-size: 10px;
  line-height: 14px;
  & > :first-child {
    margin-right: 5px;
  }

  font-weight: 400;

  @media screen and (min-width: 481px) {
    font-size: 12px;
    img {
      width: 15.5px;
      height: 15.5px;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HashtagWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;

  height: 20px;
`;

const Hashtag = styled.p`
  display: block;

  max-width: 85px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 10px;
  line-height: 14px;

  color: #b3afac;

  margin-right: 10px;

  font-weight: 400;

  @media screen and (min-width: 481px) {
    font-size: 12px;
  }
`;

const TextWrapper = styled.div`
  height: 100%;
  width: 100%;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #292929;
  margin-bottom: 8px;
`;
const Title = styled.p`
  overflow: hidden;
  white-space: normal;

  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #292929;
  width: 100%;

  white-space: nowrap;
  text-overflow: ellipsis;
`;

const UserInfoCt = styled.div`
  display: flex;
  align-items: center;
`;

const HeartBox = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;

  margin-left: auto;
  margin-right: 11px;

  & > :first-child {
    margin-right: 2px;
  }
  img {
    margin-right: 3px;
    margin-top: 3px;
    width: 14px;
    height: 14px;
  }

  @media screen and (min-width: 481px) {
    font-size: 12px;

    img {
      margin-right: 3px;
      margin-top: 3px;
      width: 16px;
      height: 16px;
    }
  }
`;
