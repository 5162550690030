import BrowserEnvContextProvider from "components/providers/BrowserEnvContextProvider";
import LocationContextProvider from "components/providers/LocationContextProvider";
import SignContextProvider from "components/providers/SignContextProvider";
import SignLayerContextProvider from "components/providers/SignLayerContextProvider";
import ConvenienceDataContextProvider from "components/providers/ConvenienceDataContextProvider";
import AdsMapDataContextProvider from "components/providers/AdsMapContextProvider";
import SubscribeMessageContextProvider from "components/providers/SubscribeMessageContextProvider";

export default function AppContextProvider({
  isMobile,
  signApp,
  signLayer,
  subscribeMessage,
  currentLocation,
  activeLocation,
  convenienceData,
  adsmapData,
  children: AppComponent,
}) {
  return (
    <SubscribeMessageContextProvider subscribeMessage={subscribeMessage}>
      <BrowserEnvContextProvider isMobile={isMobile}>
        <SignContextProvider signApp={signApp}>
          <SignLayerContextProvider signLayer={signLayer}>
            <ConvenienceDataContextProvider convenienceData={convenienceData}>
              <AdsMapDataContextProvider adsmapData={adsmapData}>
                <LocationContextProvider
                  currentLocation={currentLocation}
                  activeLocation={activeLocation}
                >
                  {AppComponent}
                </LocationContextProvider>
              </AdsMapDataContextProvider>
            </ConvenienceDataContextProvider>
          </SignLayerContextProvider>
        </SignContextProvider>
      </BrowserEnvContextProvider>
    </SubscribeMessageContextProvider>
  );
}
