import React from "react";

export default function AgreeToTermsOfUse({checkTermsOfUse, setCheckTermsOfUse, setTermsOfUse}){

    return (
        <div className="serviceAgreeItem">
            <input
                onChange={(e) =>{
                setCheckTermsOfUse((current) => !current);
                }}
                id="serviceChk"
                type="checkbox"
                checked={checkTermsOfUse ? "checked" : ""}
            ></input>
            <label htmlFor="serviceChk">
                서비스 이용약관<span>(필수)</span>
            </label>
            <button 
                className="serviceBtn"
                onClick={(e) => {
                    e.preventDefault();
                    setTermsOfUse((prev) => !prev);
                }}
                >전문 보기</button>
        </div>
    )
}