import Layers from "components/layers/Layers";
import Header from "components/pages/app/layouts/Header";
import FeedSection from "components/pages/campaign/FeedSection";
import Sections from "components/pages/campaign/layouts/Sections";
import CampaignContextProvider from "components/pages/campaign/provider/CampaignContextProvider";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { useContext, useState } from "react";

export default function Campaign() {
  const { isMobile } = useContext(isMobileContext);
  const [feeds, setFeeds] = useState([]);
  const [layer, setLayer] = useState(null);

  return (
    <>
      {!isMobile && <Header />}
      <CampaignContextProvider
        feeds={{ feeds, setFeeds }}
        layer={{ layer, setLayer }}
      >
        <Sections>
          <FeedSection />
        </Sections>
      </CampaignContextProvider>

      <Layers />
    </>
  );
}
