import jeboApi from "api/api";
import { useContext, useEffect, useRef, useState } from "react";
import { currentLocationContext } from "components/providers/LocationContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { useRouteMatch, useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player";
import redMarkerImg from "../../../assets/imgs/icons/redmarker.svg";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";

const debouncer = (() => {
  let debounceCheck;

  return {
    debounce(callback, milliseconds) {
      clearTimeout(debounceCheck);
      debounceCheck = setTimeout(() => {
        callback(...arguments);
      }, milliseconds);
    },
  };
})();

const kakao = window.kakao;
const roundControll = 0.0000000000001;
const END_LIMIT = 15;
export default function MapContent({
  socialId,
  category,
  feeds,
  setFeeds,
  mapTabPosition,
}) {
  const match = useRouteMatch();
  const history = useHistory();
  const { isMobile } = useContext(isMobileContext);
  const { currentLocation } = useContext(currentLocationContext);
  const { handleSignOutApp } = useContext(signAppContext);
  const [loading, setLoading] = useState(false);

  // MAP용
  const kakaoMap = useRef();
  const kakaoMapContainer = useRef();
  const customOverlay = useRef();
  const [mapPositionRect, setMapPositionRect] = useState(null);
  const [markerPositions, setMarkerPositions] = useState([]);
  const [jeboMarkers, setJeboMarkers] = useState([]);
  // GRID FEED용
  const [mapFeedRect, setMapFeedRect] = useState(null);
  const [cursor] = useState(0);
  //const [feeds, setFeeds] = useState([]);
  const [notLoadedData, setNotLoadedData] = useState(false);

  window.clusterClickEventFunc = (index) => {
    const mapFeedRect = {
      northEastLatitude: markerPositions[index].maxLatitude,
      northEastLongitude: markerPositions[index].maxLongitude,
      southWestLatitude: markerPositions[index].minLatitude,
      southWestLongitude: markerPositions[index].minLongitude,
      scale: kakaoMap.current.getLevel(),
    };
    setMapFeedRect(mapFeedRect);
  };

  const funcSettingMapPositionRect = () => {
    setMapPositionRect({
      northEastLatitude: kakaoMap.current.getBounds().getNorthEast().getLat(),
      northEastLongitude: kakaoMap.current.getBounds().getNorthEast().getLng(),
      southWestLatitude: kakaoMap.current.getBounds().getSouthWest().getLat(),
      southWestLongitude: kakaoMap.current.getBounds().getSouthWest().getLng(),
      scale: kakaoMap.current.getLevel(),
    });
  };

  const getScale = (scale) => {
    return scale == null
      ? "3"
      : scale < 3
      ? "15"
      : scale < 6
      ? "3"
      : scale < 8
      ? "2"
      : scale < 11
      ? "1"
      : scale < 20
      ? "0"
      : "0";
  };

  useEffect(() => {
    window.scrollTo(0, mapTabPosition + 198);

    // 최초 위치 추가하여 KAKAOMAP 활성화.
    const latitude = currentLocation
      ? currentLocation.latitude
      : 37.5546788388674;
    const longitude = currentLocation
      ? currentLocation.longitude
      : 126.970606917394;

    kakaoMap.current = new kakao.maps.Map(kakaoMapContainer.current, {
      center: new kakao.maps.LatLng(latitude, longitude),
      level: 4,
    });

    // 확대/축소 컨트롤 추가.
    const zoomControl = new kakao.maps.ZoomControl();
    kakaoMap.current.addControl(
      zoomControl,
      kakao.maps.ControlPosition.BOTTOMRIGHT
    );

    // 맵이동 이벤트
    kakao.maps.event.addListener(
      kakaoMap.current,
      "center_changed",
      function () {
        debouncer.debounce(() => {
          funcSettingMapPositionRect();
        }, 300);
      }
    );

    // 확대/축소 시 이벤트.
    kakao.maps.event.addListener(kakaoMap.current, "zoom_changed", function () {
      debouncer.debounce(() => {
        funcSettingMapPositionRect();
      }, 300);
    });

    // 마커 클러스터 추가.
    customOverlay.current = new kakao.maps.MarkerClusterer({
      map: kakaoMap.current, // 마커들을 클러스터로 관리하고 표시할 지도 객체
      averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정
      minLevel: 5, // 클러스터 할 최소 지도 레벨
      minClusterSize: 100000,
      disableClickZoom: true,
      styles: [{}],
    });

    // 최초의 1번은 rect를 세팅해준다. 로딩 시 바로 값을 가져오도록
    funcSettingMapPositionRect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // step1. 지도 위치 기반 피드들의 클러스터를 받아온다.
  useEffect(() => {
    async function loadSocialMapPositionList() {
      // 최초 랜더링 시 에러방지를 위한 예외 값 처리.
      if (!mapPositionRect) return;
      setLoading(true);
      try {
        const neLat = mapPositionRect.northEastLatitude;
        const neLng = mapPositionRect.northEastLongitude;
        const swLat = mapPositionRect.southWestLatitude;
        const swLng = mapPositionRect.southWestLongitude;
        const aroundScale = getScale(mapPositionRect.scale);

        const { status, data } = await jeboApi.socialMapPositionList(
          neLat,
          neLng,
          swLat,
          swLng,
          socialId,
          aroundScale
        );

        if (status === 200) {
          const jeboPostResult = JSON.parse(data.socialPositionList);
          setMarkerPositions(jeboPostResult);
        } else {
          console.log("no data loadSocialMapPositionList..");
          setMarkerPositions([]);
        }
      } catch (error) {
        setMarkerPositions([]);
        console.log(error.response);
        if (error.response?.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
        }
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    loadSocialMapPositionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapPositionRect, feeds]); //게시물 삭제 시 마커 반영을 위해 feeds 추가

  // 마커 세팅. 마커를 세팅하고 feed를 불러오도록 처리한다.
  useEffect(() => {
    const imageSrc = redMarkerImg;
    const imageSize = new kakao.maps.Size(45, 45);
    const imageOption = { offset: new kakao.maps.Point(27, 69) };
    const jeboMarkerImage = new kakao.maps.MarkerImage(
      imageSrc,
      imageSize,
      imageOption
    );

    jeboMarkers.forEach((marker) => {
      marker.setMap(null);
    });
    // 3레벨 미만은 단일 마커로 표기한다.
    if (kakaoMap.current.getLevel() < 3) {
      setJeboMarkers(
        markerPositions.map((feed, index) => {
          const marker = new kakao.maps.Marker({
            map: kakaoMap.current, // 맵을 속성으로 부여 하면 바로 지도에 표기 된다.
            position: new kakao.maps.LatLng(feed.latitude, feed.longitude),
            image: jeboMarkerImage,
          });
          kakao.maps.event.addListener(marker, "click", function () {
            const mapFeedRect = {
              northEastLatitude: marker.getPosition().getLat() + roundControll,
              northEastLongitude: marker.getPosition().getLng() + roundControll,
              southWestLatitude: marker.getPosition().getLat() - roundControll,
              southWestLongitude: marker.getPosition().getLng() - roundControll,
            };
            setMapFeedRect(mapFeedRect);
          });
          return marker;
        })
      );
    } else {
      // 3레벨 이상은 클러스터링으로 표현
      setJeboMarkers(
        markerPositions.map((feed, index) => {
          var content =
            '<div class="map-info" onclick="clusterClickEventFunc(' +
            index +
            ')" style="top:-75px; left: 5px;"><span class="count"><b>' +
            feed.jeboId +
            "</b>건</span><div>";
          var marker = new kakao.maps.CustomOverlay({
            map: kakaoMap.current,
            position: new kakao.maps.LatLng(feed.latitude, feed.longitude),
            content: content,
          });

          return marker;
        })
      );
    }
    // 가져온 포지션들의 값이 실제 존재한다면 추가적으로 FEED를 호출 할 수 있도록 FEED용 Rect state를 조정한다.
    if (markerPositions && markerPositions.length > 0) {
      setMapFeedRect(mapPositionRect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerPositions]);

  // 포지션들을 가져왔다면 실제 지도에 마커를 그리는 것과 동시에 피드를 호출 하러 API서버에 요청 함.
  useEffect(() => {
    async function loadSocialMapPostList() {
      if (!mapFeedRect) return;
      setLoading(true);
      try {
        const neLat = mapFeedRect.northEastLatitude;
        const neLng = mapFeedRect.northEastLongitude;
        const swLat = mapFeedRect.southWestLatitude;
        const swLng = mapFeedRect.southWestLongitude;
        const aroundScale = getScale(mapFeedRect.scale);
        const { status, data } = await jeboApi.socialMapPostList(
          neLat,
          neLng,
          swLat,
          swLng,
          socialId,
          0,
          END_LIMIT,
          cursor,
          aroundScale
        );
        if (status === 200) {
          const jeboPostArr = JSON.parse(data.socialPostList);
          setNotLoadedData(jeboPostArr.length < END_LIMIT ? true : false);
          if (cursor === 0) {
            setFeeds(jeboPostArr);
          } else setFeeds((prev) => prev.concat(jeboPostArr));
        } else if (status === 204) {
          console.log("no data to be loaded..");
          setNotLoadedData(true);
        }
      } catch (error) {
        console.log(error.response);
        if (error.response?.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
        }
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    loadSocialMapPostList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapFeedRect]);

  return (
    <>
      <MapContainer ref={kakaoMapContainer}></MapContainer>
      <FixContainer>
        {(!feeds || feeds.length === 0) &&
          (category.name === "channel" ? (
            <EmptyContents>아직 게시글이 없어요.</EmptyContents>
          ) : (
            <EmptyContents>
              아직 참여한 게시물이 없어요. <br />
              {category.title}의 첫 게시글을 남겨 보세요.
            </EmptyContents>
          ))}
        <GridContainer>
          {feeds &&
            feeds.length > 0 &&
            feeds.map((content) => (
              <GridContent
                key={content.jeboId}
                content={content}
                onClick={(e) => {
                  e.stopPropagation();
                  isMobile
                    ? history.push({
                        pathname: `${match.url}/list`,
                        state: {
                          jeboId: content.jeboId,
                        },
                      })
                    : history.push({
                        pathname: `${match.url}/detail/${content.jeboId}`,
                      });
                }}
              >
                {content.jeboMediaList && content.jeboMediaList.length > 0 ? (
                  content.jeboMediaList[0].mediaType === "image" ? (
                    <LazyLoadImage
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", //"contain",
                        margin: "auto",
                        backgroundColor: "#f8f8f8",
                      }}
                      src={
                        process.env.REACT_APP_RESOURCE_HOST +
                        content.jeboMediaList[0].mediaUrl
                      }
                      alt=""
                    />
                  ) : (
                    <ReactPlayer
                      className="react-player"
                      controls={false}
                      controlsList="nodownload"
                      loop={false}
                      muted={true}
                      autoPlay={false}
                      playsInline={true}
                      type="video/*"
                      url={`${
                        process.env.REACT_APP_RESOURCE_HOST +
                        content.jeboMediaList[0].mediaUrl
                      }#t=0.5`}
                      width="100%"
                      height="100%"
                      style={{
                        objectFit: "cover", //"contain",
                        margin: "auto",
                        backgroundColor: "#f8f8f8",
                      }}
                    />
                  )
                ) : (
                  <p>{content.contents}</p>
                )}
              </GridContent>
            ))}
        </GridContainer>
      </FixContainer>
    </>
  );
}

const EmptyContents = styled.div`
  text-align: center;
  margin-top: 100px;
  line-height: 20px;
`;
const MapContainer = styled.div`
  width: 100%;
  height: 45vh;

  /* border: 1px solid red; */
`;
const FixContainer = styled.div`
  height: 100%;
  min-height: 45vh;
`;

// Grid container
const GridContainer = styled.div`
  height: 100%;
  display: grid;

  gap: 5px;
  /* grid-template-columns: repeat(1, 3fr); */
  /* grid-template-columns: repeat(3, minmax(20%, auto)); */
  /* grid-template-columns: repeat(auto-fill, minmax(32%, 105px)); */
  /* grid-template-columns: 1fr 1fr 1fr; */
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  @media screen and (min-width: 481px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (min-width: 770px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

// Grid Item
const GridContent = styled.div`
  background-color: #83a7fb;
  height: 110px;
  word-break: break-all;

  padding: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  line-height: 1.4em;
  text-align: center;
  color: #fff;
  /* color: #222; */
  font-weight: 500;
  font-size: 14px;

  ${({ content }) =>
    content.jeboMediaList && content.jeboMediaList.length > 0
      ? css`
          padding: 0;
        `
      : ""}

  @media screen and (min-width: 481px) {
    min-height: 215px;
  }
`;
