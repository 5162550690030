import jeboApi from "api/api";
import user_icon from "assets/imgs/icons/user_icon.svg";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { createPortal } from "react-dom";

export default function SubscribePop({
  viewFollowerBox,
  viewFollowingBox,
  memberInfo,
  setFollowValue,
  setViewFollowerBox,
  setViewFollowingBox,
}) {
  const { isLoggedIn, myInfo, handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);

  const history = useHistory();
  const [title, setTitle] = useState(null);
  const [followList, setFollowList] = useState(null);
  const [changeFollow, setChangeFollow] = useState(false);

  const regFollow = async (memberUid) => {
    try {
      await jeboApi.regFollow(memberUid);
      setChangeFollow(true);

      //상위컴포넌트 전달
      if (myInfo.memberUid === memberInfo.memberUid) {
        setFollowValue(false);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
        }
      }
    }
  };

  const removeFollow = async (followId) => {
    try {
      await jeboApi.removeFollow(followId);
      setChangeFollow(true);

      //상위컴포넌트 전달
      if (myInfo.memberUid === memberInfo.memberUid) {
        setFollowValue(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFollower = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.getFollower(
        memberInfo.memberUid,
        myInfo.memberUid
      );

      if (status === 200) {
        const followerListResult = JSON.parse(data.followerList);
        setFollowList(followerListResult);
        setChangeFollow(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [myInfo, memberInfo]);

  const getFollowing = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.getFollowing(
        memberInfo.memberUid,
        myInfo.memberUid
      );

      if (status === 200) {
        const followingListResult = JSON.parse(data.followingList);
        setFollowList(followingListResult);
        setChangeFollow(false);

        //상위컴포넌트 전달
        if (followingListResult.length === 0) {
          setViewFollowingBox(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [myInfo]);

  useEffect(() => {
    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    return () => {
      //부모창 스크롤 방지 해제
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  useEffect(() => {
    if (viewFollowerBox) {
      setTitle("구독자");
      getFollower();
    } else {
      setTitle("관심 구독");
      getFollowing();
    }
  }, [changeFollow, getFollower, getFollowing, viewFollowerBox]);

  return createPortal(
    <Modal>
      <Container>
        <HeaderSection>
          <Title>{title}</Title>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              setViewFollowingBox(false);
              setViewFollowerBox(false);
            }}
          ></CloseButton>
        </HeaderSection>
        <BodySection>
          <Items>
            {followList &&
              followList.map((follow, index) => (
                <Item key={index}>
                  <ImageSection
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/member/${encodeURIComponent(
                          follow.nickName
                        )}`,
                      });
                    }}
                  >
                    <Image
                      src={
                        follow.imageUrl
                          ? process.env.REACT_APP_RESOURCE_HOST +
                            follow.imageUrl
                          : user_icon
                      }
                      alt={follow.nickName}
                    />
                  </ImageSection>
                  <NicknameSection>{follow.nickName}</NicknameSection>
                  {myInfo.memberUid === follow.memberUid ? (
                    ""
                  ) : follow.isFollow ? (
                    <Button
                      onClick={(e) => {
                        removeFollow(follow.isFollow);
                      }}
                    >
                      구독 해제
                    </Button>
                  ) : (
                    <Button
                      onClick={(e) => {
                        isLoggedIn
                          ? regFollow(follow.memberUid)
                          : setSignLayer("signIn");
                      }}
                    >
                      구독 하기
                    </Button>
                  )}
                </Item>
              ))}
          </Items>
        </BodySection>
      </Container>
    </Modal>,
    document.getElementById("modal")
  );
}

const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 500px;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
`;

const Container = styled.div`
  width: 600px;
  height: 500px;
  margin-left: 15%;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  border-radius: 30px;
`;

const HeaderSection = styled.div`
  display: flex;
  color: #000;
  font-size: 25px;
  font-weight: bold;
  width: 100%;
  padding: 19px 20px 13px 20px;
`;

const Title = styled.h2`
  width: 100%;
  text-align: center;
`;

const CloseButton = styled.button`
  margin-left: auto;
  border: none;
  width: 15px;
  height: 15px;
  cursor: pointer;
  background: url(/static/media/exit.9fc98549.svg) no-repeat center;
`;

const BodySection = styled.div`
  padding: 20px;
  max-height: 420px;
`;

const Items = styled.ul`
  list-style: none;
  max-height: 400px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ImageSection = styled.span`
  margin-right: 8px;
  display: inline-block;
`;

const Image = styled.img`
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
`;

const NicknameSection = styled.span`
  flex: 1 1 60%;
  color: black;
  white-space: nowrap;
  overflow: hidden;
`;

const Button = styled.button`
  width: 60px;
  height: 30px;
  font-size: 0.7rem;
  margin-left: auto;

  background-color: #7cc5d8;
  color: #fff;
  border: none;
`;
