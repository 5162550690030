import jeboApi from "api/api";
import AuthService from "api/AuthService";
import user_icon from "assets/imgs/icons/user_icon.svg";
import AlertLayer from "components/layers/popMenu/AlertLayer";
import ConfirmLayer from "components/layers/popMenu/ConfirmLayer";
import ProfilePopLayer from "components/layers/popMenu/ProfilePopLayer";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import {
  activeLocationContext,
  currentLocationContext,
} from "components/providers/LocationContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import LoadingComponent from "components/templates/LoadingComponent";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

export default function ProfileContainer({ setActiveMenu }) {
  const { myInfo, setMyInfo, handleSignOutApp } = useContext(signAppContext);

  const { isMobile } = useContext(isMobileContext);
  const { setSignLayer } = useContext(signLayerContext);
  const { setActiveLocation } = useContext(activeLocationContext);
  const { getCurrentPosition } = useContext(currentLocationContext);
  const [current, setCurrent] = useState(false);

  const [showProfilePopLayer, setShowProfilePopLayer] = useState(false);
  const [nickName, setNickName] = useState(myInfo.nickName);

  const [description, setDescription] = useState(
    myInfo && myInfo.description ? myInfo.description : ""
  );
  const [interTag, setInterTag] = useState(
    myInfo && myInfo.interTag ? myInfo.interTag : ""
  );
  const [interLocations, setInterLocations] = useState(
    myInfo && myInfo.interLocations ? myInfo.interLocations : []
  );
  const [keyword, setKeyword] = useState("");
  const [resultList, setResultList] = useState([]);

  const [showConfirmLayer, setShowConfirmLayer] = useState(false);
  const [showAlertLayer, setShowAlertLayer] = useState(false);

  const kakao = window.kakao;
  const refDescriptionElement = useRef();

  const history = useHistory();

  const [removeToastText, setRemoveToastText] = useState(false);
  const [loading, setLoading] = useState(false);

  const activeRef = useRef(null);

  const ToastTextReturn = (bool) => {
    if (bool) {
      setTimeout((e) => {
        setRemoveToastText(false);
      }, 1500);
    }
  };
  // error 중복 닉네임 확인
  const [errorDesc, setErrorDesc] = useState();

  useEffect(() => {
    if (myInfo) {
      setNickName(myInfo.nickName);
      setDescription(myInfo.description);
      setInterTag(myInfo.interTag);
      setInterLocations(myInfo.interLocations);
    }
  }, [myInfo]);

  const getMyInfo = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.getMyInfo();

      if (status === 200) {
        const resultData = JSON.parse(data.myInfo);
        const {
          memberUid,
          nickName,
          imageUrl,
          interLocations,
          interTag,
          description,
        } = resultData;
        resultData.imageUrl =
          process.env.REACT_APP_RESOURCE_HOST + resultData.imageUrl;
        AuthService.setNickName(nickName);
        // 23.02.14 codelua setMyInfo는 실제 값이 변경 되었다고 판단 될 때만 걸어준다.
        setMyInfo((prev) =>
          JSON.stringify(prev) !== JSON.stringify(resultData)
            ? resultData
            : prev
        );
        // 23.02.13 codelua 관심지역 설정한게 없으면 현위치 세팅.
        const isCurrentLocation =
          interLocations && interLocations.length > 0
            ? interLocations.find((location) => location.selectYn === "Y")
            : null;
        if (!isCurrentLocation) setCurrent(true);
      }
    } catch (error) {
      console.log(error.message);
      if (error.response) {
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  }, []);

  useEffect(() => {
    getMyInfo();
  }, [getMyInfo]);

  const modifyMyInfo = async () => {
    let saveInterTag = "";
    try {
      //콤마를 공백으로 치환 후 반복되는 공백을 하나의 공백으로 치환

      const replaceInterTag = interTag
        ? interTag.replace(/,/g, " ").replace(/ +/g, " ").trim().split(" ")
        : null;

      replaceInterTag &&
        replaceInterTag.forEach((tagValue) => {
          let sTag = tagValue.charAt(0) !== "#" ? "#" + tagValue : tagValue;
          saveInterTag += saveInterTag !== "" ? " " + sTag : sTag;
        });

      const response = await jeboApi.myInfoModify(
        nickName,
        saveInterTag,
        description ? description.trim() : "",
        interLocations
      );
      if (response.status === 200) {
        const parsedMyInfo = JSON.parse(response.data.myInfo);
        setInterTag(saveInterTag);
        setMyInfo(
          Object.assign({}, myInfo, {
            nickName,
            interTag: saveInterTag,
            description: parsedMyInfo.description,
            interLocations: parsedMyInfo.interLocations,
          })
        );

        const selectedInterLocation =
          parsedMyInfo &&
          parsedMyInfo.interLocations &&
          parsedMyInfo.interLocations.length > 0
            ? parsedMyInfo.interLocations.find(
                (location) => location.selectYn === "Y"
              )
            : null;

        if (selectedInterLocation) {
          setActiveLocation({
            latitude: selectedInterLocation.latitude,
            longitude: selectedInterLocation.longitude,
            addressName: selectedInterLocation.addressName,
            placeName: selectedInterLocation.placeName,
          });
        } else {
          getCurrentPosition(false); // 23.02.13 codelua. 원래 true
        }

        setShowAlertLayer(true);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // error 내용 500 === 중복 이름 체크
        if (error.response.status === 500) {
          setErrorDesc("수정 중 에러. 관리자에게 문의해주세요.");
        } else if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  };

  const searchAddrListFromRegionAddr = useCallback(
    (resultData) => {
      const addressSearchObj = new kakao.maps.services.Geocoder();

      addressSearchObj.addressSearch(
        keyword,
        (result, status, pagination) => {
          if (status === kakao.maps.services.Status.OK) {
            const regionList =
              result && result.length > 0
                ? result.map((data) => {
                    return {
                      address_name: data.address_name ? data.address_name : "",
                      road_address_name: data.road_address
                        ? data.road_address.address_name
                        : "",
                      place_name: "",
                      x: data.x,
                      y: data.y,
                    };
                  })
                : null;
            setResultList(
              resultData && resultData.length > 0
                ? resultData.concat(regionList)
                : regionList
            );
          } else {
            if (resultData && resultData.length > 0) {
              setResultList(resultData);
            } else {
              setResultList([]);
            }
          }
        },
        { size: 10 }
      );
    },
    [kakao, keyword]
  );

  const searchAddrListFromKeyword = useCallback(() => {
    const placeSearchObj = new kakao.maps.services.Places();
    placeSearchObj.keywordSearch(
      keyword,
      (result, status, pagination) => {
        //console.log("placeresult : ", result, keyword);
        if (status === kakao.maps.services.Status.OK) {
          const placeList =
            result && result.length > 0
              ? result.map((data) => {
                  return {
                    address_name: data.address_name ? data.address_name : "",
                    road_address_name: data.road_address_name
                      ? data.road_address_name
                      : "",
                    place_name: data.place_name,
                    x: data.x,
                    y: data.y,
                  };
                })
              : null;
          searchAddrListFromRegionAddr(placeList);
        } else {
          status === kakao.maps.services.Status.ZERO_RESULT &&
            searchAddrListFromRegionAddr(null);
        }
      },
      { size: 10, page: 1 }
    );
  }, [kakao, keyword, searchAddrListFromRegionAddr]);

  useEffect(() => {
    keyword ? searchAddrListFromKeyword() : setResultList([]);
  }, [keyword, searchAddrListFromKeyword]);

  useEffect(() => {
    nickName.length === 0 && setErrorDesc("");
  }, [nickName]);

  const handleAddLocation = async (location) => {
    //이미 관심지역리스트에 등록된 위치일 경우 거르는 로직 추가
    let locations = interLocations;
    if (
      locations.some(
        (item) =>
          item.latitude === Number.parseFloat(location.y) &&
          item.longitude === Number.parseFloat(location.x)
      )
    ) {
      locations = locations.map((item) => {
        if (
          item.latitude === Number.parseFloat(location.y) &&
          item.longitude === Number.parseFloat(location.x)
        )
          return Object.assign({}, item, { selectYn: "Y" });

        return Object.assign({}, item, { selectYn: "N" });
      });
      setInterLocations(locations);
    } else {
      locations = locations.map((item) =>
        Object.assign({}, item, { selectYn: "N" })
      );

      let locationInfo = {};
      const { address_name, road_address_name, place_name, x, y } = location;
      locationInfo = {
        addressName: address_name,
        roadAddressName: road_address_name,
        placeName: place_name,
        latitude: y,
        longitude: x,
        selectYn: "Y",
      };

      locations.length >= 20 && locations.shift();
      locations.push(locationInfo);
      setInterLocations(locations);
    }
    setKeyword("");
  };

  const handleRemoveLocation = (seq) => {
    let removeInterLocations = [];
    interLocations.forEach((interLocation) => {
      if (interLocation.locationSeq !== seq)
        removeInterLocations.push(interLocation);
    });
    setInterLocations(removeInterLocations);
  };

  const handleSelectLocation = (seq) => {
    let selectLocations = [];
    // 현위치를 누른 경우 나머지는 무조건 해제 시켜버린다.
    if (seq === 0) {
      setCurrent(true);
    } else {
      setCurrent(false);
    }
    interLocations.forEach((item) => {
      if (item.locationSeq === seq) item.selectYn = "Y";
      else item.selectYn = "N";
      selectLocations.push(item);
    });

    setInterLocations(selectLocations);
  };

  const modifyMyProfile = async (imageFile) => {
    try {
      setShowProfilePopLayer(false);
      setLoading(true);
      const response = await jeboApi.myProfileModify(imageFile);
      if (response.status === 200) {
        const { imageUrl } = JSON.parse(response.data.myInfo);
        setMyInfo(
          Object.assign({}, myInfo, {
            imageUrl: imageUrl
              ? process.env.REACT_APP_RESOURCE_HOST + imageUrl
              : user_icon,
          })
        );
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    } finally {
      // setShowProfilePopLayer(false);
      setLoading(false);
    }
  };

  const checkByte = (value) => {
    //const maxByte = 100; //최대 100바이트
    const text_val = value; //입력한 문자
    const text_len = text_val ? text_val.length : 0; //입력한 문자수

    let totalByte = 0;
    for (let i = 0; i < text_len; i++) {
      const each_char = text_val.charAt(i);
      const uni_char = escape(each_char); //유니코드 형식으로 변환

      //한글 :2byte, 영문,숫자,특수문자 : 1byte
      totalByte = uni_char.length > 4 ? totalByte + 2 : totalByte + 1;
    }

    return totalByte;
  };

  const validateLength = () => {
    if (!nickName || nickName.length > 10) return false;
    if (checkByte(description) > 500) return false;
    // if (  nickName.match(/[`$~@#%^&|\\=?;:",<>\\{\\}\\[\]\\\\/ ]/gim) || nickName.match(/\s/g) ) 기존 validate
    if (/[^ㄱ-힣-\w().!*'\p{Emoji_Presentation}]/gimu.test(nickName)) {
      return false;
    }

    setShowConfirmLayer(true);
  };

  return (
    <>
      <div className="s-body active" id="s-profile">
        <div className="s-title">
          <button
            type="button"
            className="list-back mobile"
            onClick={(e) => setActiveMenu("")}
          ></button>
          <strong>프로필</strong>
          {/* <button
          className="s-btn mobile"
          onClick={(e) => {
            nickName.match(
              /[\\{\\}\\[\]\\/?.,;:|\\)*~`!^\-_+<>@\\#$%&\\=\\(\\'\\"]/g
            ) ||
              nickName.match(/\s/g) ||
              !nickName ||
              (validateNickname && setShowConfirmLayer(true));
            // validateNickname && setShowConfirmLayer(true);
          }}
        >
          수정
        </button> */}
        </div>
        <div className="s-inner">
          <div className="photo">
            <div className="profile-thumb">
              <span className="thumb">
                <img src={myInfo.imageUrl} alt="" />
              </span>
              {showProfilePopLayer && (
                <ProfilePopLayer
                  setShowProfilePopLayer={setShowProfilePopLayer}
                  setRemoveToastText={setRemoveToastText}
                  ToastTextReturn={ToastTextReturn}
                  modifyMyProfile={modifyMyProfile}
                />
              )}
              <button
                className="thumb-edit"
                onClick={(e) => setShowProfilePopLayer(!showProfilePopLayer)}
              ></button>
            </div>

            <strong>{myInfo.nickName}</strong>
          </div>
          <div className="info-wrap">
            <div className="write-item">
              <div className="input-title">
                <strong className="ttl">닉네임</strong>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  className="input-control"
                  value={nickName}
                  onChange={(e) => {
                    setNickName(e.target.value);
                  }}
                />

                {((!nickName || nickName.length > 10) && (
                  <span
                    style={{
                      display: "block",
                      color: "#cf514a",
                      fontSize: "12px",
                      marginTop: "8px",
                    }}
                  >
                    한글, 영문, 숫자를 포함하여 10자 이내로 작성하세요.
                  </span>
                )) ||
                  (/[^ㄱ-힣-\w().!*'\p{Emoji_Presentation}]/gimu.test(
                    nickName
                  ) && (
                    <span
                      style={{
                        display: "block",
                        color: "#cf514a",
                        fontSize: "12px",
                        marginTop: "8px",
                      }}
                    >
                      특수문자 또는 공백은 사용하실 수 없습니다.
                    </span>
                  ))}
                {errorDesc && (
                  <span
                    style={{
                      display: "block",
                      color: "#cf514a",
                      fontSize: "12px",
                      marginTop: "8px",
                    }}
                  >
                    {errorDesc}
                  </span>
                )}
              </div>
            </div>
            <div className="write-item">
              <div className="input-title">
                <strong className="ttl">
                  자기소개(
                  <span
                    style={{
                      color: checkByte(description) <= 500 ? "green" : "red",
                    }}
                  >
                    {checkByte(description)}
                  </span>
                  /500자)
                </strong>
              </div>
              <div className="input-group">
                <textarea
                  ref={refDescriptionElement}
                  className="input-control"
                  placeholder="자기소개를 입력하세요."
                  style={{ height: "100px" }}
                  onChange={(e) =>
                    checkByte(e.target.value) <= 500 &&
                    setDescription(e.target.value)
                  }
                  value={description}
                ></textarea>
                {checkByte(description) > 500 && (
                  <span
                    style={{
                      display: "block",
                      color: "#cf514a",
                      fontSize: "12px",
                      marginTop: "8px",
                    }}
                  >
                    자기소개를 500자 이내로 입력하세요.
                  </span>
                )}
              </div>
            </div>
            <div className="write-item">
              <div className="input-title">
                <strong className="ttl">관심 지역</strong>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  className="input-control"
                  value={keyword}
                  placeholder="관심 지역은 최대 20개까지 등록할 수 있습니다."
                  onChange={(e) => setKeyword(e.target.value)}
                />
                {keyword && (
                  <button
                    className="clear-btn"
                    onClick={() => {
                      setKeyword("");
                    }}
                  ></button>
                )}
                {resultList && resultList.length > 0 && (
                  <div className="search-dropdown open">
                    <ul>
                      {resultList.map((location, index) => {
                        return (
                          <li key={index}>
                            <a
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleAddLocation(location);
                              }}
                            >
                              {location.place_name
                                ? `${location.place_name} (${location.address_name})`
                                : location.address_name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="write-item loc">
              <div
                className={`loc-item ${current && "active"}`}
                ref={activeRef}
              >
                <span onClick={(e) => handleSelectLocation(0)}>
                  현재 위치로 설정
                </span>
              </div>
              <br />
              <i></i>
              {interLocations &&
                interLocations.length > 0 &&
                interLocations.map((location, index) => {
                  return (
                    <div
                      className={`loc-item ${
                        location.selectYn === "Y" && "active"
                      }`}
                      key={index}
                      ref={activeRef}
                    >
                      <span
                        onClick={(e) =>
                          handleSelectLocation(location.locationSeq)
                        }
                      >
                        {location.placeName
                          ? `${location.placeName}(${location.addressName})`
                          : `${location.addressName}`}
                      </span>
                      <a
                        href="#!"
                        className="del"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleRemoveLocation(location.locationSeq);
                        }}
                      >
                        <i></i>
                      </a>
                    </div>
                  );
                })}
            </div>
            <div className="write-item">
              <div className="input-title">
                <strong className="ttl">관심 태그</strong>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  className="input-control"
                  placeholder="관심 키워드는 쉼표로 구분해주세요. ex) 함께하는_일상, 동네_맛집"
                  value={interTag}
                  onChange={(e) => {
                    checkByte(e.target.value) <= 100 &&
                      setInterTag(e.target.value);
                  }}
                />
                {checkByte(interTag) > 100 && (
                  <span
                    style={{
                      display: "block",
                      color: "#cf514a",
                      fontSize: "12px",
                      marginTop: "8px",
                    }}
                  >
                    관심 키워드를 100자 이내로 입력하세요.
                  </span>
                )}
              </div>
            </div>
            <div className="write-btn setting-btn-container">
              <button
                className="input-btn"
                onClick={(e) => {
                  validateLength();
                  // validateNickname && setShowConfirmLayer(true);
                }}
              >
                수정
              </button>
            </div>
            {showConfirmLayer && (
              <ConfirmLayer
                setShowConfirmLayer={setShowConfirmLayer}
                callback={modifyMyInfo}
                title="회원정보를 수정 하시겠습니까?"
                description=""
                btnText="수정하기"
                isConfirm={true}
              />
            )}
            {showAlertLayer && (
              <AlertLayer
                setShowAlertLayer={setShowAlertLayer}
                title="회원정보가 수정되었습니다."
                description=""
                callback={() => {
                  isMobile && setActiveMenu("");
                }}
              />
            )}
            {
              <div
                className={
                  removeToastText
                    ? "removeProfilePic-notice active"
                    : "removeProfilePic-notice"
                }
              >
                <p className="removeProfilePic-text">
                  프로필 사진이 삭제되었습니다.
                </p>
              </div>
            }
          </div>
        </div>
      </div>
      {loading && (
        <div className="modal-wrap open">
          <LoadingComponent />
        </div>
      )}
    </>
  );
}
