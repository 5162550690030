import styled from "styled-components";

import themeWriteImg from "assets/imgs/icons/icon_themeWrite.svg";

import useRecentKeywords from "components/hooks/useRecentKeywords";
import useScrollPosition from "components/hooks/useScrollPosition";
import qs from "query-string";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
// import Search from "./Search";
import jeboApi from "api/api";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import BannercamMid from "../../../assets/imgs/banners/BannercamMid.svg";
import BannerSection from "./BannerSection";
import SearchList from "./SearchList";
import Search from "./SearchNew";

import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import SocialItemList from "./SocialItemList";
import SocialItemSlide from "./SocialItemSlide";

import iconXGray from "assets/imgs/icons/clear.svg";
import BannerSectionMid from "./BannerSectionMid";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import CreatePost from "./CreatePost";
import SignLayer from "components/layers/sign/SignLayer";

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function ContentMainCampaign({
  category,
  content,
  dispatchContent,
  removeSocial,
  updateSocial,
}) {
  const {
    datas,
    popDatas,
    totalCount,
    sortType,
    loading,
    scrollPosition,
    cursor,
    filter,
    searchKeyword,
    isLastData,
  } = content;

  const [searchState, setSearchState] = useState(true);

  const { handleSignOutApp, isLoggedIn } = useContext(signAppContext);
  const { signLayer, setSignLayer } = useContext(signLayerContext);

  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const { q } = qs.parse(location.search);
  const queryKeyword = useMemo(() => (q ? q : ""), [q]);

  const [lastContentRef, inView] = useInView({
    skip: datas.length < cursor.end ? true : false,
  });

  const [isSearcing, setIsSearcing] = useState(false);

  const { recentKeywords, removeKeyword } = useRecentKeywords(
    searchKeyword,
    `${category.name}_recent_keywords`
  );

  const searchRef = useRef();
  const searchListRef = useRef();

  useEffect(() => {
    const onClick = (e) => {
      if (
        searchRef.current &&
        searchListRef.current &&
        !searchRef.current.contains(e.target) &&
        !searchListRef.current.contains(e.target)
      ) {
        setIsSearcing(false);
      }
    };

    window.addEventListener("click", onClick);

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, []);

  useEffect(() => {
    setIsSearcing(false);
  }, [location]);

  const currentScrollPosition = useScrollPosition(scrollPosition);

  useEffect(() => {
    window.scrollTo(0, scrollPosition);
  }, [category]);

  useEffect(() => {
    return () => {
      dispatchContent({
        type: "STORE_SCROLL_POSITION",
        scrollPosition: currentScrollPosition,
      });
    };
  }, [dispatchContent, currentScrollPosition]);

  useEffect(() => {
    //console.log(inView, "inView");
    !loading &&
      !isLastData &&
      inView &&
      dispatchContent({ type: "CHANGE_CURSOR" });
  }, [loading, inView, isLastData, dispatchContent, searchState]);

  const handleSearchKeyword = (keyword) => {
    dispatchContent({ type: "SEARCH_KEYWORD", keyword });
    history.replace({
      pathname: match.url,
      search: `?q=${keyword}`,
    });
  };

  const toggleLike = async (item) => {
    if (!isLoggedIn) {
      setSignLayer("signIn");
      return;
    }

    try {
      const { status } = await jeboApi.toggleSocialLike(item.scId, !item.like);

      if (status === 200) {
        dispatchContent({
          type: "UPDATE_DATA",
          data: {
            ...item,
            like: !item.like,
            likeCount: !item.like
              ? item.likeCount + 1
              : item.likeCount > 0
              ? item.likeCount - 1
              : item.likeCount,
          },
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
        }
      }
    }
  };

  const handleJoin = async (item) => {
    try {
      const { status } = await jeboApi.joinSocial(item.scId);

      if (status === 200) {
        updateSocial({
          scId: item.scId,
          memberCount: item.memberCount + 1,
          roleName: "USER",
        });
        // setSocial((prev) => ({ ...prev, roleName: "USER" }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleQuit = async (item) => {
    try {
      const { status } = await jeboApi.quitSocial(item.scId);
      if (status === 200) {
        item.roleName === "USER" &&
          updateSocial({
            scId: item.scId,
            memberCount: item.memberCount - 1,
            roleName: "ANONYMOUS",
          });
        filter.joined === true && removeSocial(item.scId);
        // setSocial((prev) => ({ ...prev, roleName: "ANONYMOUS" }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    //테마,캠페인,채널,커뮤니티 탭
    <>
      <ContentContainer>
        {/* 상단 배너 */}
        <BannerSection category={category} content={popDatas} />

        <Search
          searchRef={searchRef}
          category={category}
          setIsSearcing={setIsSearcing}
          searchKeyword={queryKeyword}
          handleSearchKeyword={handleSearchKeyword}
          dispatchContent={dispatchContent}
        />
        {isSearcing ? (
          <SearchList
            searchListRef={searchListRef}
            recentKeywords={recentKeywords}
            removeKeyword={removeKeyword}
            handleSearchKeyword={handleSearchKeyword}
            setIsSearcing={setIsSearcing}
          />
        ) : (
          <MainContent>
            {datas.length >= 0 && !searchKeyword ? (
              <>
                <HotItemWrapper
                  type={
                    category.name === "coummunity" ? "community" : "channel"
                  }
                >
                  <SocialItemSlide
                    // key={item}
                    item={popDatas}
                    category={category}
                    handleJoin={handleJoin}
                    handleQuit={handleQuit}
                    toggleLike={toggleLike}
                    itemType={"top"}
                  />
                </HotItemWrapper>

                {/* 광고영역 */}
                <BannerSectionMid
                  type={"mid"}
                  category={category}
                ></BannerSectionMid>

                {/* 콘텐츠 영역 */}
                <ContentList>
                  <SocialItemList
                    item={datas}
                    category={category}
                    filter={filter}
                    toggleLike={toggleLike}
                    dispatchContent={dispatchContent}
                    handleJoin={handleJoin}
                    handleQuit={handleQuit}
                    itemType={"mid"}
                    sortType={sortType}
                  />
                </ContentList>
                {/* } */}
                {!loading && datas.length > 0 && (
                  <div ref={lastContentRef}></div>
                )}
              </>
            ) : (
              <>
                {datas.length > 0 ? (
                  <ContentList>
                    <SocialItemList
                      item={datas}
                      category={category}
                      filter={filter}
                      toggleLike={toggleLike}
                      dispatchContent={dispatchContent}
                      handleJoin={handleJoin}
                      handleQuit={handleQuit}
                      itemType={"mid"}
                      sortType={sortType}
                    />
                  </ContentList>
                ) : (
                  <EmptyDataCt>
                    <p>검색결과가 없습니다.</p>
                    <ClearBtn
                      icon={iconXGray}
                      aria-label="취소 버튼"
                      text="검색"
                      type="list"
                      onClick={() => {
                        handleSearchKeyword("");
                        history.replace({
                          pathname: `/social/${category.name}`,
                        });
                      }}
                    >
                      목록으로 돌아가기
                    </ClearBtn>
                  </EmptyDataCt>
                )}
                {!loading && datas.length > 0 && (
                  <div ref={lastContentRef}></div>
                )}
              </>
            )}
            <MakeContentBtn
              onClick={(e) => {
                e.preventDefault();

                isLoggedIn
                  ? history.push({
                      pathname: `${match.url}${
                        category.creatable ? "/create" : "/propose"
                      }`,
                    })
                  : setSignLayer("signIn");
              }}
              themeWriteImg={themeWriteImg}
            ></MakeContentBtn>
          </MainContent>
        )}
      </ContentContainer>
    </>
  );
}

const MainContent = styled.div`
  padding: 0px 14px 0 14px;
`;

const ContentContainer = styled.div`
  min-height: 100vh;
  height: 100%;
  padding-top: 50px;

  @media screen and (min-width: 481px) {
    padding-top: 10px;
  }

  border-bottom: 1px solid transparent;
`;

const MakeContentBtn = styled.button`
  position: fixed;
  z-index: 2;
  width: 44px;
  height: 40px;
  right: 13px;
  bottom: 80px;
  background: transparent;
  background-size: contain;

  @media screen and (min-width: 481px) {
    right: 7vw;
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    background: url(${({ themeWriteImg }) => themeWriteImg}) no-repeat;
    background-size: contain;

    width: 46px;
    height: 46px;
    content: "";
  }
`;

const ContentList = styled.div`
  height: 100%;
  padding-top: 16px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (min-width: 481px) {
    padding-top: 22px;
  }
`;

const EmptyDataCt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40vh;

  font-size: 12px;
  color: #a3a3a3;
  text-align: center;

  background: #fff;

  flex-direction: column;
  /* position: relative; */
`;

const MidBannerSection = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 10px;

  background: url(${BannercamMid}) no-repeat;
  background-size: cover;
  background-position: center;

  margin-bottom: 34px;
`;

const HotItemWrapper = styled.div`
  margin: 3px 0 50px;

  height: 190px;
`;

const ClearBtn = styled.button`
  padding: 2.5px 12.5px;
  margin-top: 10px;
  color: #a3a3a3;
  border: 1px solid #a3a3a3;
  border-radius: 3px;
  background-color: transparent;
  /* top: 0;
  right: 0;
  position: absolute;
  border-radius: 50%;
  color: transparent;
  background-color: transparent;
  width: 20px;
  height: 20px;
  background: url(${({ icon }) => icon}) no-repeat;
  background-size: cover
  background-position: center; */
`;
