import jeboApi from "api/api";
import img_add_wh from "assets/imgs/icons/add_wh.png";
import img_clear from "assets/imgs/icons/clear.svg";
import img_gps from "assets/imgs/icons/gps.svg";
import img_pre from "assets/imgs/icons/img_pre.svg";
import ImageEditorLayer from "components/layers/write/ImageEditorLayer";
import { currentLocationContext } from "components/providers/LocationContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import LoadingComponent from "components/templates/LoadingComponent";
import ToastMessageBox from "components/templates/ToastMessageBox";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import ReactPlayer from "react-player/lazy";
import { useHistory, useLocation } from "react-router";
import uuid from "react-uuid";
import styled from "styled-components";
import SwiperCore, {
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SocialCompletePop from "./SocialCompletePop";

const debouncer = (() => {
  let debounceCheck;

  return {
    debounce(callback, milliseconds) {
      clearTimeout(debounceCheck);
      debounceCheck = setTimeout(() => {
        callback(...arguments);
      }, milliseconds);
    },
  };
})();

//미디어 width,height 전역변수
const SIZE = "72px";

export default function CreatePost({ category, updateSocial }) {
  const { isMobile } = useContext(isMobileContext);
  SwiperCore.use([Pagination, Navigation, Keyboard, Mousewheel]);
  const kakao = window.kakao;

  const history = useHistory();
  const location = useLocation();
  const {
    state: { social },
  } = useLocation();
  const { socialId, title } = social;
  const { handleSignOutApp } = useContext(signAppContext);
  const { currentLocation, getCurrentPosition } = useContext(
    currentLocationContext
  );

  const [locationType, setLocationType] = useState("ADDRESS");
  const [locationInfo, setLocationInfo] = useState(null);
  const [locationKeyword, setLocationKeyword] = useState("");
  const [resultList, setResultList] = useState([]);
  const [locationInputPlaceholder, setLocationInputPlaceholder] = useState("");

  const [media, setMedia] = useState([]);
  const [contents, setContents] = useState("");

  // image editor
  const [isEdit, setIsEdit] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");

  const [toastMessage, setToastMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const [inputStateMessage, setInputStateMessage] = useState("");

  const [confirmCompleteLayer, setConfirmCompleteLayer] = useState(false);
  const [confirmType, setConfirmType] = useState("0");
  const confirmMessage = {
    0: [category.title] + social.title + "에 가입 후 참여해주세요",
    1: "최대 20개까지 첨부할 수 있습니다.",
  };

  useEffect(() => {
    if (
      category &&
      social &&
      category.joinable &&
      social.roleName === "ANONYMOUS"
    ) {
      setConfirmType(0);
      setConfirmCompleteLayer(true);
      // alert([category.title] + social.title + "에 가입 후 참여해주세요");
      history.replace({
        pathname: `/social/${category.name}/${social.socialId}`,
      });
    }
  }, [history, category, social]);

  const onChangeRadioBtn = (e) => {
    const tempMedia = media.slice();
    tempMedia.map((medium, index) => {
      if (String(index) === String(e.target.value)) {
        medium.changeThumbnail = true;
        return (medium.thumbnail = "Y");
      } else return (medium.thumbnail = "N");
    });
    setMedia(tempMedia);
  };

  const handleFileValidate = (file) => {
    if (!file.type.includes("image") && !file.type.includes("video")) {
      setToastMessage("이미지 또는 동영상 파일만 업로드 가능합니다.");
      return false;
    }

    if (file.size > 1024 * 1024 * 50) {
      setToastMessage("50MB 이하의 파일만 업로드 가능합니다.");
      return false;
    }

    if (media && media.length > 20) {
      setToastMessage("최대 20개까지 첨부할 수 있습니다.");
      return false;
    }

    return true;
  };

  const requiredInputCheck = () => {
    if (contents.trim() === "") {
      setInputStateMessage("내용을 입력해주세요.");
      return false;
    }

    // if (title.trim() === "") {
    //   setInputStateMessage("제목을 입력해주세요.");
    //   return false;
    // }
    // if (description.trim() === "") {
    //   setInputStateMessage("설명을 입력해주세요.");
    //   return false;
    // }
    // if (tags.length <= 0) {
    //   setInputStateMessage("태그를 입력해주세요");
    //   return false;
    // }
    // if (!image) {
    //   setInputStateMessage("대표 사진을 첨부해주세요.");
    //   return false;
    // }
    return true;
  };

  const handleFileUpload = (e) => {
    let files = Array.from(e.target.files);

    if (files.length > 20) {
      files = files.filter((_, index) => index < 20);
      // alert("최대 20개까지 첨부할 수 있습니다.");
      setConfirmType(1);
      setConfirmCompleteLayer(true);
    }

    const addMedia = files
      .filter((file) => handleFileValidate(file))
      .map((file, index) => {
        return {
          id: uuid(),
          file: file,
          url: URL.createObjectURL(file),
          type: file.type.split("/")[0],
          upload: true,
          thumbnail: media.length === 0 && index === 0 ? "Y" : "N",
          changeThumbnail: false,
        };
      });

    addMedia && addMedia.length > 0 && setMedia(media.concat(addMedia));
  };
  const removeMedium = (removeMedium) => {
    URL.revokeObjectURL(removeMedium.url);
    setMedia(media.filter((medium) => medium.id !== removeMedium.id));
  };
  const revokeMediaUrl = () => {
    media.forEach((medium) => URL.revokeObjectURL(medium.url));
  };

  useEffect(() => {
    let existImage = media.some((medium) => {
      return medium.type === "image";
    });
    existImage ? setIsImage(true) : setIsImage(false);
  }, [media]);

  //키워드의 주소 검색
  const searchAddressForRegion = useCallback(
    (resultData) => {
      if (!locationKeyword) return;

      const addressSearchObj = new kakao.maps.services.Geocoder();
      addressSearchObj.addressSearch(
        locationKeyword,
        (result, status, pagination) => {
          if (status === kakao.maps.services.Status.OK) {
            console.log(result, "regionList");
            const regionList =
              result && result.length > 0
                ? result.map((data) => {
                    return {
                      type: "REGION",
                      address_name: data.address_name ? data.address_name : "",
                      road_address_name: data.road_address
                        ? data.road_address.address_name
                        : "",
                      place_name: "",
                      x: data.x,
                      y: data.y,
                    };
                  })
                : null;
            setResultList(
              resultData && resultData.length > 0
                ? resultData.concat(regionList)
                : regionList
            );
          } else {
            if (resultData && resultData.length > 0) {
              setResultList(resultData);
            }
          }
        },
        { size: 10, analyze_type: "EXACT" }
      );
    },
    [kakao, locationKeyword]
  );

  //키워드의 랜드마크(place) 검색
  const searchAddressForPlace = useCallback(() => {
    const placeSearchObj = new kakao.maps.services.Places();
    placeSearchObj.keywordSearch(
      locationKeyword,
      (result, status, pagination) => {
        if (status === kakao.maps.services.Status.OK) {
          console.log(result, "placeList");

          const placeList =
            result && result.length > 0
              ? result.map((data) => {
                  return {
                    type: "PLACE",
                    address_name: data.address_name ? data.address_name : "",
                    road_address_name: data.road_address_name
                      ? data.road_address_name
                      : "",
                    place_name: data.place_name,
                    x: data.x,
                    y: data.y,
                  };
                })
              : null;
          searchAddressForRegion(placeList);
        } else {
          console.log(status);
          status === kakao.maps.services.Status.ZERO_RESULT &&
            searchAddressForRegion(null);
        }
      },
      { size: 10, page: 1 }
    );
  }, [kakao, locationKeyword, searchAddressForRegion]);

  //위치 키워드 입력 시, 해당 키워드로 POI/주소 검색 함수 호출
  useEffect(() => {
    debouncer.debounce(() => {
      !locationInfo && locationKeyword && searchAddressForPlace();
    }, 200);
  }, [locationInfo, locationKeyword, searchAddressForPlace]);

  //현 위치가 변경됐을 경우, location_info state에 값이 없을 때
  // location_info state에 세팅하는 함수 호출
  useEffect(() => {
    if (currentLocation) {
      const { address, roadAddress, placeName, latitude, longitude } =
        currentLocation;

      const coords = new kakao.maps.LatLng(latitude, longitude);

      let tags = [];
      if (placeName) {
        tags.push(`#${placeName.replaceAll(" ", "_")}`);
      } else {
        tags = makeLocationHashtag(address);
      }

      setLocationInfo({
        address,
        roadAddress,
        placeName,
        latitude: coords.getLat(),
        longitude: coords.getLng(),
        hashtags: tags,
      });
    }
  }, [kakao, currentLocation]);

  useEffect(() => {
    if (locationInfo) {
      const { placeName, address } = locationInfo;
      if (locationType === "ADDRESS")
        setLocationKeyword(placeName ? `${placeName}(${address})` : address);
      else setLocationKeyword(placeName);
      // locationType === "ADDRESS" &&
      //   setLocationKeyword(placeName ? `${placeName}(${address})` : address);

      setResultList([]);
    } else {
      setLocationKeyword("");
    }
  }, [locationInfo]);

  /*
  useEffect(() => {
    if (locationInfo) {
      const { placeName, address } = locationInfo;
      setLocationKeyword(placeName ? `${placeName}(${address})` : address);
      setResultList([]);

      const hashtag = placeName
        ? `${placeName.replace(/\s/gi, "_")}`
        : `${address.replace(/\s/gi, "_")}`;
      setContents((prev) => prev + "#" + hashtag.replace("#", ""));
    } else {
      setLocationKeyword("");
    }

    return () => {
      if (locationInfo) {
        const { placeName, address } = locationInfo;
        const hashtag = placeName
          ? `${placeName.replace(/\s/gi, "_")}`
          : `${address.replace(/\s/gi, "_")}`;
        setContents((prev) =>
          prev ? prev.replaceAll("#" + hashtag, "") : prev
        );
      }
    };
  }, [locationInfo]);
  */

  useEffect(() => {
    setLocationInfo(null);
    if (locationType === "ADDRESS") {
      getCurrentPosition(false);
    } else {
      const holderId = localStorage.getItem("hashTagPlaceHolder");
      if (!holderId || holderId === "0") {
        localStorage.setItem("hashTagPlaceHolder", "1");
        setLocationInputPlaceholder("#우리동네");
      } else if (holderId === "1") {
        localStorage.setItem("hashTagPlaceHolder", "0");
        setLocationInputPlaceholder("#방콕중");
      }
    }
  }, [locationType, getCurrentPosition]);

  useEffect(() => {
    let hashs = "";
    social &&
      social.hashtags.map((tag) => {
        if (tag.includes("#")) {
          hashs += tag + " ";
        } else {
          hashs += "#" + tag + " ";
        }
        return hashs;
      });
    setContents(hashs);
  }, []);

  const registSocialPost = async () => {
    setLoading(true);
    try {
      const hashtags = [
        ...contents.matchAll(/#[\w|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|0-9]+/gi),
      ].map((tag) => tag[0]);

      const { data, status } = await jeboApi.createSocialPost(
        media,
        hashtags,
        contents,
        locationInfo && locationInfo.address ? locationInfo.address : "",
        locationInfo && locationInfo.roadAddress
          ? locationInfo.roadAddress
          : "",
        locationInfo && locationInfo.placeName ? locationInfo.placeName : "",
        locationInfo && locationInfo.latitude ? locationInfo.latitude : NaN,
        locationInfo && locationInfo.longitude ? locationInfo.longitude : NaN,
        locationInfo && locationInfo.hashtags ? locationInfo.hashtags : "",
        socialId
      );

      if (status === 200) {
        console.log("저장 성공");
        if (!social.participated) {
          updateSocial({
            scId: socialId,
            memberCount: social.memberCount + 1,
            participated: true,
          });
        }
        revokeMediaUrl();
        history.replace({
          ...location,
          pathname: `/social/${category.name}/${socialId}`,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const makeLocationHashtag = (addressName) => {
    const addressNames = addressName.split(" ");
    let tags = [];
    if (addressNames && addressNames.length > 0) {
      const exceptAddressNumber = parseInt(
        addressNames[addressNames.length - 1].substring(0, 1)
      );
      !isNaN(exceptAddressNumber) && addressNames.pop();

      addressNames.reduce((acc, curr) => {
        tags.push(`#${curr}`);
        acc && tags.push(`#${acc}_${curr}`);
        return curr;
      }, "");
    }

    return tags;
  };

  return (
    <Container>
      <Header>
        <BackButton onClick={history.goBack} />
        <HeaderTitle>
          [{category.title}]{title} 참여
        </HeaderTitle>
      </Header>
      <Main>
        <InputWrapper>
          <LocationTitle>
            <strong>위치</strong>

            <input
              type="checkbox"
              id="search_location"
              onChange={(e) => {
                setLocationType((prev) =>
                  prev === "ADDRESS" ? "HASHTAG" : "ADDRESS"
                );
              }}
              checked={locationType === "ADDRESS" ? true : false}
            />
            <label htmlFor="search_location"></label>
            {locationType === "ADDRESS" && (
              <button
                onClick={() => {
                  getCurrentPosition(false);
                }}
              >
                현 위치
              </button>
            )}
          </LocationTitle>

          {locationType === "ADDRESS" ? (
            <LocationInput>
              <input
                type="text"
                name="location"
                value={locationKeyword}
                placeholder="위치를 입력하세요"
                onChange={(e) => {
                  setLocationInfo(null);
                  setLocationKeyword(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  setLocationInfo(null);
                  setLocationKeyword("");
                }}
              ></button>
              {resultList && resultList.length > 0 && (
                <SearchDropDownContainer>
                  <ul>
                    {resultList.map((location, index) => {
                      return (
                        <li key={index}>
                          <button
                            onClick={() => {
                              let tags = [];
                              const placeName = location.place_name;
                              const addressName = location.address_name;

                              if (placeName) {
                                tags.push(`#${placeName.replaceAll(" ", "_")}`);
                              } else {
                                tags = makeLocationHashtag(addressName);
                              }

                              setLocationInfo({
                                address: addressName,
                                roadAddress: location.road_address_name,
                                placeName: location.place_name,
                                latitude: location.y,
                                longitude: location.x,
                                hashtags: tags,
                              });
                            }}
                          >
                            {location.place_name
                              ? `${location.place_name} (${location.address_name})`
                              : location.road_address_name
                              ? location.road_address_name
                              : location.address_name}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </SearchDropDownContainer>
              )}
            </LocationInput>
          ) : (
            <LocationHashtagInput>
              {/* {!locationKeyword && (
                <label htmlFor="locationTag">
                  주소대신 <b>{locationInputPlaceholder}</b> (으)로 해시태그를
                  이용해보세요.
                </label>
              )} */}
              <input
                type="text"
                name="location"
                autoCapitalize="none"
                aria-autocomplete="none"
                autoCorrect="off"
                autoComplete="off"
                spellCheck="false"
                placeholder={
                  !locationKeyword
                    ? `주소대신 ${locationInputPlaceholder}(으)로 해시태그를 이용해보세요.`
                    : ""
                }
                value={locationKeyword}
                onChange={(e) => {
                  setLocationKeyword(e.target.value);
                }}
                onBlur={(e) => {
                  const placeName = e.target.value.trim();
                  setLocationInfo({
                    placeName,
                    address: "",
                    roadAddress: "",
                    latitude: NaN,
                    longitude: NaN,
                    hashtags: `#${placeName.replaceAll(" ", "_")}`,
                  });
                }}
              />
            </LocationHashtagInput>
          )}
        </InputWrapper>

        <InputWrapper>
          <MediaTitle>
            <strong>미디어</strong>
            <span>*동영상은 50MB 이하 파일만 업로드 가능</span>
            {isMobile && isImage && media && media.length > 0 && (
              <button
                onClick={(e) => {
                  setIsEdit((prev) => !prev);
                }}
              >
                {isEdit ? "편집 완료" : "이미지편집"}
              </button>
            )}
          </MediaTitle>
          <MediaInput>
            <Swiper
              pagination={{ el: ".swiper-pagination", clickable: true }}
              freeMode={true}
              spaceBetween={10}
              slidesPerView="auto"
            >
              <SwiperSlide className="input-file-slide">
                <input
                  type="file"
                  id="fileupload"
                  multiple
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onInput={handleFileUpload}
                  accept="image/* video/*;capture=camera"
                />
                <label htmlFor="fileupload"></label>
              </SwiperSlide>

              {media.map((medium, index) => (
                <SwiperSlide key={medium.id} className="media-slide">
                  {!isEdit && (
                    <button
                      className="del_btn"
                      type="button"
                      onClick={(e) => {
                        removeMedium(medium);
                      }}
                    ></button>
                  )}

                  {medium.type === "image" ? (
                    isMobile && isEdit ? (
                      <>
                        <MediaItem src={medium.url} alt="" />
                        <button
                          className="edit_btn"
                          onClick={(e) => {
                            setMediaUrl(medium.url);
                            setShowEditor(true);
                          }}
                        >
                          선택
                        </button>
                      </>
                    ) : (
                      <Zoom>
                        <img
                          src={medium.url}
                          alt=""
                          width={SIZE}
                          height={SIZE}
                          style={
                            medium.thumbnail === "Y"
                              ? {
                                  border: "3px solid #3773ff",
                                  borderRadius: "3px",
                                }
                              : null
                          }
                        />
                      </Zoom>
                    )
                  ) : (
                    medium.type === "video" && (
                      <ReactPlayer
                        preload="metadata"
                        controlsList="nodownload"
                        controls={true}
                        loop={true}
                        muted={true}
                        autoPlay={true}
                        playsInline={false}
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen={true}
                        type="video/*"
                        width={SIZE}
                        height={SIZE}
                        url={medium.url}
                      />
                    )
                  )}
                  {!isEdit && (
                    <PickDiv className="img-item remove-radio">
                      <input
                        type="radio"
                        id={medium.url}
                        value={index}
                        onChange={onChangeRadioBtn}
                        checked={medium.thumbnail === "Y" ? true : false}
                      />
                      <label className="pickLabel" htmlFor={medium.url}></label>
                    </PickDiv>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </MediaInput>
        </InputWrapper>
        <InputWrapper>
          <ContentTitle>
            <strong>내용</strong>
          </ContentTitle>
          <ContentInput>
            <textarea
              placeholder="내용입력"
              autoCapitalize="none"
              aria-autocomplete="none"
              autoCorrect="off"
              autoComplete="off"
              spellCheck="false"
              onChange={(e) => {
                if (e.target.value.length > 10000) return;
                setContents(e.target.value);
              }}
              value={contents}
            ></textarea>
          </ContentInput>
        </InputWrapper>
        {inputStateMessage && (
          <InvalidMessage>{inputStateMessage}</InvalidMessage>
        )}
        <ButtonContainer>
          <button
            className="cancelBtn"
            onClick={() => {
              revokeMediaUrl();
              history.goBack();
            }}
          >
            취소
          </button>
          <button
            className="joinBtn"
            onClick={(e) => {
              e.preventDefault();
              if (!requiredInputCheck()) return false;
              setInputStateMessage("");
              registSocialPost();
            }}
          >
            {category.name === "channel" ? "게시" : "참여"}
          </button>
        </ButtonContainer>
      </Main>
      {showEditor &&
        createPortal(
          <ImageEditorLayer
            mediaUrl={mediaUrl}
            media={media}
            setMedia={setMedia}
            setShowEditor={setShowEditor}
            setIsEdit={setIsEdit}
          />,
          document.getElementById("modal")
        )}
      {toastMessage && (
        <ToastMessageBox
          toastMessage={toastMessage}
          setToastMessage={setToastMessage}
        />
      )}
      {loading && <LoadingComponent />}

      {confirmCompleteLayer && (
        <SocialCompletePop
          // title={social.title}
          category={category}
          message={confirmMessage[confirmType]}
          onClick={setConfirmCompleteLayer(false)}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  padding-bottom: 50px;
  background: #ffffff;

  @media screen and (min-width: 481px) {
    background-color: rgba(0, 0, 0, 0.5);
    padding-bottom: 0;
  }
`;

const Main = styled.main`
  width: 100%;
  height: 100%;

  /* padding: 5px; */
  padding-top: 50px;

  @media screen and (min-width: 481px) {
    /* border: 3px dashed blue; */
    background-color: #fff;
    width: 700px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
  }
`;

const Header = styled.div`
  height: 48px;
  position: fixed;
  left: 0;
  right: 0;

  padding: 0 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-bottom: solid 1px #dcdcdc;

  @media screen and (min-width: 481px) {
    display: none;
  }
`;

const BackButton = styled.button`
  background: url(${img_pre}) no-repeat;
  border: none;

  width: 10%;
  height: 70%;

  @media screen and (min-width: 481px) {
    width: 25px;
    height: 25px;
  }
`;

const HeaderTitle = styled.h1`
  width: 80%;
  height: 70%;
  text-align: center;
  line-height: 30px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const InputWrapper = styled.div`
  width: 100%;
  /* height: 120px; */
  margin-top: 10px;

  padding: 0.5rem 1rem;
`;

const LocationTitle = styled.div`
  margin-bottom: 3.5%;

  strong {
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
  }

  input[type="checkbox"] {
    display: none;
  }

  label {
    display: inline-block;
    width: 50px;
    height: 28px;
    border-radius: 14px;
    background: #dcdcdc;
    position: relative;
    cursor: pointer;

    transform: translateY(8px);

    margin-right: 5px;

    ::after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;

      border-radius: 50%;
      background: #fff;
      position: absolute;
      top: 4px;
      left: 4px;
      transition: 0.15s ease-in-out;
    }
  }

  input[type="checkbox"]:checked + label {
    background: var(--text-linkColor);
    ::after {
      left: 24px;
    }
  }

  & > button {
    border: none;
    background: none;
    color: var(--text-linkColor);

    ::before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 16px;
      background: url(${img_gps}) no-repeat;
      margin-right: 5px;
      position: relative;
      top: 4px;
    }
  }
`;

const LocationInput = styled.div`
  height: 50px;
  position: relative;

  input[type="text"] {
    border: solid 1px #dddddd;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    padding-right: 45px;
    padding-left: 20px;
  }

  & > button {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 5px;
    top: 3px;
    border: none;
    margin: 0;
    padding: 0;
    background: url(${img_clear}) no-repeat;
    background-size: 15px;
    background-color: #fff;
    background-position: center center;
  }
`;

const LocationHashtagInput = styled.div`
  height: 50px;
  position: relative;

  & > input[type="text"] {
    border: solid 1px #dddddd;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    padding-right: 45px;
    padding-left: 20px;
  }

  & > label {
    position: absolute;
    top: 35%;
    color: #8e8e8e;
    margin-left: 20px;
    font-size: 12px;
    font-weight: 400;
  }
`;

const MediaTitle = styled.div`
  margin-bottom: 3.5%;
  strong {
    font-size: 14px;
    font-weight: 600;
  }

  & > button {
    float: right;
    border-radius: 3px;
    border: none;
    background: var(--default-deepBlueColor);
    color: #ffffff;

    width: auto;
    height: 20px;
    font-size: 3vw;

    display: inline-block;
    clear: both;
  }

  span {
    font-size: 10px;
    color: #666;
    display: none;
  }
`;

const MediaInput = styled.div`
  /* height: 120px; */

  .swiper-container {
    /* height: 100px; */
    overflow: hidden;
    .swiper-wrapper {
      .swiper-slide {
        width: 80px;
        height: 80px;
        position: relative;

        margin-right: 10px;
      }

      .input-file-slide {
        input[type="file"] {
          display: none;
        }

        & > label {
          border-radius: 10px;
          display: block;
          width: 90%;
          height: 90%;
          background: #eeeeee;
          position: relative;
          cursor: pointer;

          ::before {
            content: url(${img_add_wh});
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }
        }
      }

      .media-slide {
        .del_btn {
          position: absolute;
          right: 12px;
          top: 5px;
          width: 17px;
          height: 17px;
          line-height: 15px;
          border-radius: 50%;
          z-index: 2;
          padding: 0;
          margin: 0;
          border: none;
          background: url(${img_clear}) no-repeat;
          color: #fff;
        }

        .edit_btn {
          background: var(--default-deepBlueColor);
          color: #ffffff;
          position: absolute;
          top: 32.5%;
          left: 15%;
          width: 70%;
          height: 35%;
          z-index: 9;
        }
      }
    }
  }
`;

const ContentTitle = styled.div`
  margin-bottom: 3.5%;

  strong {
    font-size: 14px;
    font-weight: 600;
    margin-right: 30px;
  }
`;

const ContentInput = styled.div`
  textarea {
    border: solid 1px #dddddd;
    border-radius: 3px;
    width: 100%;
    /* height: 100px; */
    resize: none;

    font-size: 13px;
    line-height: 20px;
    height: 100%;
    padding: 10px 15px;
    min-height: 100px;
  }
`;

const SearchDropDownContainer = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  border: solid 1px #e2e2e2;
  border-radius: 3px;
  width: 100%;
  overflow-y: auto;

  z-index: 10;
  border-top: none;
  background: #fff;
  padding: 6px 2px;
  display: block;

  & > ul {
    width: 100%;

    & > li {
      width: 100%;
      padding-bottom: 1px;
      border-bottom: 1px solid #e2e2e2;

      & > button {
        width: 100%;
        min-height: 26px;
        margin: 0;
        padding: 0;
        background: #fff;
        font-size: 0.813rem;
        color: #4e4e4e;
        text-align: left;
        padding: 0 6px;
        transition: 0.2s;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button {
    max-height: 55px;
    color: #fff;
    border-radius: 4px;
    padding: 13.5px 44px;
    margin: 0 10px;

    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
  }
  .cancelBtn {
    background-color: #ccc;
  }

  .joinBtn {
    background-color: #014681;
  }
`;

const MediaItem = styled.img`
  width: ${SIZE};
  height: ${SIZE};
`;

const InvalidMessage = styled.p`
  color: #cf514a;
  font-size: 12px;
  margin: 8px 10px 15px 10px;
`;

const PickDiv = styled.div`
  position: absolute;
  z-index: 8;
  width: 100%;
  bottom: -1em;
  height: 3em;
  left: 10px;

  .pickLabel {
    z-index: 9;
    width: 100% !important;
    height: 45% !important;
    bottom: 4px !important;
    background-color: transparent;
    &::before {
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      /* border: solid 1px #c4c4c4; */
      border: solid 1px #c4c4c4;
      border-radius: 50%;
      margin-right: 2em;
      vertical-align: middle;
      position: relative;
      left: 31px;
      top: 15px;
    }

    &::after {
      content: "";
      top: 9px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: #3773ff;
      left: 45px;
    }
  }
`;
