import jeboApi from "api/api";
import user_icon from "assets/imgs/icons/user_icon.svg";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function SubscribeBox(param) {
  const { isLoggedIn, myInfo, handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);

  const history = useHistory();
  const [title, setTitle] = useState(null);
  const [followList, setFollowList] = useState(null);
  const [changeFollow, setChangeFollow] = useState(false);

  const regFollow = async (memberUid) => {
    try {
      await jeboApi.regFollow(memberUid);
      setChangeFollow(true);

      //상위컴포넌트 전달
      if (myInfo.memberUid === param.memberInfo.memberUid) {
        param.setFollowValue(false);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
        }
      }
    }
  };

  const removeFollow = async (followId) => {
    try {
      await jeboApi.removeFollow(followId);
      setChangeFollow(true);

      //상위컴포넌트 전달
      if (myInfo.memberUid === param.memberInfo.memberUid) {
        param.setFollowValue(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFollower = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.getFollower(
        param.memberInfo.memberUid,
        myInfo.memberUid
      );

      if (status === 200) {
        const followerListResult = JSON.parse(data.followerList);
        setFollowList(followerListResult);
        setChangeFollow(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [myInfo, param.memberInfo]);

  const getFollowing = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.getFollowing(
        param.memberInfo.memberUid,
        myInfo.memberUid
      );

      if (status === 200) {
        const followingListResult = JSON.parse(data.followingList);
        setFollowList(followingListResult);
        setChangeFollow(false);

        //상위컴포넌트 전달
        if (followingListResult.length === 0) {
          param.setViewFollowingBox(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [myInfo, param]);

  useEffect(() => {
    if (param.viewFollowerBox) {
      setTitle("구독자");
      getFollower();
    } else {
      setTitle("관심 구독");
      getFollowing();
    }
  }, [changeFollow, getFollower, getFollowing, param.viewFollowerBox]);

  return (
    <>
      {/* 구독자 목록 팝업 */}
      <div className="subs-info-ct">
        <div className="subs-info-top">
          <h2 className="subs-info-top__title">{title}</h2>
          <button
            className="subs-info-top__closeBtn"
            onClick={(e) => {
              e.preventDefault();
              param.setViewFollowingBox(false);
              param.setViewFollowerBox(false);
            }}
          ></button>
        </div>
        <div className="subs-list-ct">
          <ul className="subs-list">
            {followList &&
              followList.map((follow, index) => (
                <li className="subs-list-item" key={index}>
                  <span
                    className="thumb"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/member/${encodeURIComponent(
                          follow.nickName
                        )}`,
                      });
                    }}
                  >
                    <img
                      src={
                        follow.imageUrl
                          ? process.env.REACT_APP_RESOURCE_HOST +
                            follow.imageUrl
                          : user_icon
                      }
                      alt={follow.nickName}
                    />
                  </span>
                  <span className="name">{follow.nickName}</span>
                  {myInfo.memberUid === follow.memberUid ? (
                    ""
                  ) : follow.isFollow ? (
                    <button
                      className="btn-edit subscribing-btn"
                      onClick={(e) => {
                        removeFollow(follow.isFollow);
                      }}
                    >
                      구독 해제
                    </button>
                  ) : (
                    <button
                      className="btn-edit subscribe-btn"
                      onClick={(e) => {
                        isLoggedIn
                          ? regFollow(follow.memberUid)
                          : setSignLayer("signIn");
                      }}
                    >
                      구독 하기
                    </button>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
      {/* 구독자 목록 팝업 */}
    </>
  );
}
