import jeboApi from "api/api";
import AuthService from "api/AuthService";
import SignUpLayerForm from "components/layers/sign/SignUpLayerForm";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { Base64 } from "js-base64";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
//import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import SignUpComplete from "components/layers/sign/SignUpComplete";
import GoogleLogin from "react-google-login";
import SignUpAgreeLayer from "./SignUpAgreeLayer";

export default function SignUpMain() {
  const { handleSignInApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const { mobileType } = useContext(isMobileContext);
  const [openTermsOfJebo, setOpenTermsOfJebo] = useState(false);
  const [infoStruct, setInfoStruct] = useState(null);
  const [isEmailSignUp, setIsEmailSignUp] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);

  const [memberYn, setMemberYn] = useState("");

  const naver = window.naver;
  const kakao = window.Kakao;

  const signInRef = useRef();

  // useEffect(() => {
  //   document.getElementsByTagName("html")[0].classList.add("hidden");
  //   window.document.body.classList.add("hidden");
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("hidden");
  //     window.document.body.classList.remove("hidden");
  //   };
  // }, []);

  useEffect(() => {
    setOpenTermsOfJebo(infoStruct ? true : false);
  }, [infoStruct]);

  const socialSignIn = useCallback(
    async (id, email, name, socialType, deviceId, fcmToken, agreeItems) => {
      try {
        const { headers } = await jeboApi.socialSignIn(
          id,
          email,
          name,
          socialType,
          deviceId,
          fcmToken,
          mobileType,
          agreeItems
        );

        if (mobileType === "ios" || mobileType === "android") {
          headers.refresh_token
            ? AuthService.setAutoLoginToken(headers.refresh_token)
            : AuthService.removeAutoLoginToken();
        }

        handleSignInApp(
          headers.authorization,
          headers.refresh_token,
          headers.member_email,
          Base64.decode(headers.member_nickname),
          headers.grant_type,
          headers.member_uid,
          headers.social_yn,
          socialType
        );
      } catch (error) {
        console.error("error : ", error);
      } finally {
        memberYn === "N" ? setOpenComplete(true) : setSignLayer("");
      }
    },
    [mobileType, memberYn, handleSignInApp, setSignLayer]
  );

  const signInAppIOS = useCallback(
    async (encodedUserInfo) => {
      try {
        let jsonUserInfo = Base64.decode(encodedUserInfo);
        let userInfo = JSON.parse(jsonUserInfo);

        window.webkit.messageHandlers.print.postMessage(
          JSON.stringify(userInfo)
        );
        if (userInfo.loginType === "email") {
          signInRef.current?.signIn(userInfo.deviceId, userInfo.fcmToken);
        } else {
          const { data } = await jeboApi.existMemberYn(
            userInfo.id,
            userInfo.loginType
          );

          setMemberYn(data.existMemberYn);
          if (data.existMemberYn === "N") {
            setInfoStruct({
              id: userInfo.id,
              email: userInfo.email,
              name: userInfo.nickName.replace(
                /[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]/g,
                ""
              ),
              socialType: userInfo.loginType,
              deviceType: userInfo.deviceType,
              fcmToken: userInfo.fcmToken,
            });
          } else if (data.existMemberYn === "G") {
            alert("재가입은 탈퇴일로 부터 7일 후에 가능합니다");
          } else if (data.existMemberYn === "F") {
            alert("영구정지 상태로 재가입이 불가합니다.");
          } else {
            socialSignIn(
              userInfo.id,
              userInfo.email,
              userInfo.nickName,
              userInfo.loginType,
              userInfo.deviceId,
              userInfo.fcmToken
            );
          }
        }
      } catch (error) {
        console.log(error);
        window.webkit.messageHandlers.print.postMessage(error);
      }
    },
    [socialSignIn]
  );

  useEffect(() => {
    window.signInAppIOS = signInAppIOS;
    return () => {
      window.signInAppIOS = () => {};
    };
  }, [signInAppIOS]);

  const loginCallbackAndroid = useCallback(
    async (account) => {
      let userInfo = JSON.parse(account);
      let returnString = window.androidApp.getDeviceTokenInfo();

      let deviceId = returnString.split("@@")[0];
      let fcmToken = returnString.split("@@")[1];

      socialSignIn(
        userInfo.id,
        userInfo.email,
        userInfo.nickName,
        userInfo.socialType,
        deviceId,
        fcmToken
      );
    },
    [socialSignIn]
  );

  useEffect(() => {
    window.loginCallbackAndroid = loginCallbackAndroid;
    return () => {
      window.loginCallbackAndroid = () => {};
    };
  }, [loginCallbackAndroid]);

  const naverInit = useCallback(() => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: process.env.REACT_APP_NAVER_CLIENT_KEY,
      callbackUrl: `${process.env.REACT_APP_HOST}/naverLoginSuccess`,
      isPopup: false,
      loginButton: { color: "green", type: 1, height: 30 },
    });

    naverLogin.init();
    naver.successCallback = socialSignIn;
  }, [naver, socialSignIn]);

  const kakaoInit = useCallback(() => {
    !kakao.isInitialized() && kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
  }, [kakao]);

  useEffect(() => {
    naverInit();
    kakaoInit();
  }, [naverInit, kakaoInit]);

  const handleNaverLogin = () => {
    const naverLoginButton = document.querySelector("#naverIdLogin a");
    naverLoginButton.click();
  };

  const getKakaoUserInfo = (userInfo) => {
    const { id, kakao_account } = userInfo;

    socialSignIn(
      id,
      kakao_account.email,
      "profile" in kakao_account ? kakao_account.profile.nickname : "",
      "kakao",
      "",
      ""
    );
  };
  const handleKakaoLogin = () => {
    kakao.Auth.login({
      success: (authObj) => {
        kakao.API.request({
          url: "/v2/user/me",
          success: getKakaoUserInfo,
          fail: (error) => {
            console.log("kakao get userInfo error : ", error);
          },
        });
      },
      fail: (error) => {
        console.log("kakao login error : ", error);
      },
    });
  };

  /*
  const getFacebookUserInfo = async (userInfo) => {
    if (userInfo.id && userInfo.name) {
      const { data } = await jeboApi.existMemberYn(userInfo.id, "facebook");
      setMemberYn(data.existMemberYn);
      if (data.existMemberYn === "N") {
        setInfoStruct({
          id: userInfo.id,
          email: "email" in userInfo ? userInfo.email : null,
          name: userInfo.name.replace(/[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]/g, ""),
          socialType: "facebook",
          deviceId: "",
          fcmToken: "",
        });
      } else if (data.existMemberYn === "G") {
        alert("재가입은 탈퇴일로 부터 7일 후에 가능합니다");
      } else if (data.existMemberYn === "F") {
        alert("영구정지 상태로 재가입이 불가합니다.");
      } else {
        socialSignIn(
          userInfo.id,
          "email" in userInfo ? userInfo.email : null,
          userInfo.name,
          "facebook",
          "",
          ""
        );
      }
    }
  };
  */

  const getGoogleUserInfo = async (userInfo) => {
    const id = userInfo.getId();
    const email = userInfo.getBasicProfile().getEmail();
    const name = userInfo.getBasicProfile().getName();

    const { data } = await jeboApi.existMemberYn(id, "google");
    setMemberYn(data.existMemberYn);
    if (data.existMemberYn === "N") {
      setInfoStruct({
        id: id,
        email: email,
        name: name.replace(/[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]/g, ""),
        socialType: "google",
        deviceId: "",
        fcmToken: "",
      });
    } else if (data.existMemberYn === "G") {
      alert("재가입은 탈퇴일로 부터 7일 후에 가능합니다");
    } else if (data.existMemberYn === "F") {
      alert("영구정지 상태로 재가입이 불가합니다.");
    } else {
      socialSignIn(id, email, name, "google", "", "", "");
    }
  };

  return (
    <div className="panel panel-signup" style={{ display: "block" }}>
      <div className="modal-body">
        {isEmailSignUp ? (
          <SignUpLayerForm ref={signInRef} />
        ) : (
          <>
            <div className="signup-sns open">
              <strong>SNS로 3초만에 가입하기</strong>

              <div className="input-group">
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  render={(props) => (
                    <button
                      className="modal-btn ggl"
                      onClick={(e) => {
                        if (mobileType === "ios") {
                          let params = JSON.stringify({
                            loginType: "google",
                          });
                          window.webkit.messageHandlers.login.postMessage(
                            Base64.encode(params)
                          );
                        } else if (mobileType === "android") {
                          window.androidApp.googleSignIn();
                        } else {
                          props.onClick(e);
                        }
                      }}
                    >
                      구글 간편 가입하기
                    </button>
                  )}
                  onSuccess={(result) => getGoogleUserInfo(result)}
                  onFailure={(result) => console.log(result)}
                  cookiePolicy="single_host_origin"
                />
              </div>

              <div className="input-group">
                <button
                  className="modal-btn kko"
                  onClick={(e) => {
                    e.preventDefault();
                    if (mobileType === "ios") {
                      let params = JSON.stringify({
                        loginType: "kakao",
                      });
                      window.webkit.messageHandlers.login.postMessage(
                        Base64.encode(params)
                      );
                    } else if (mobileType === "android") {
                      handleKakaoLogin();
                    } else {
                      kakao.Auth.authorize({
                        redirectUri: `${process.env.REACT_APP_HOST}/kakaoLoginSuccess`,
                        throughTalk: true,
                      });
                    }
                  }}
                >
                  카카오톡 간편 가입하기
                </button>
              </div>
              <div className="input-group">
                <button
                  className="modal-btn nv"
                  onClick={(e) => {
                    e.preventDefault();
                    if (mobileType === "ios") {
                      let params = JSON.stringify({
                        loginType: "naver",
                      });
                      window.webkit.messageHandlers.login.postMessage(
                        Base64.encode(params)
                      );
                    } else {
                      handleNaverLogin();
                    }
                  }}
                >
                  네이버 간편 가입하기
                </button>
              </div>

              <div className="input-group">
                {/* <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  autoLoad={false}
                  fields="id,name,email"
                  callback={getFacebookUserInfo}
                  render={(renderProps) => (
                    <button
                      className="modal-btn fb"
                      onClick={(e) => {
                        if (mobileType === "ios") {
                          let params = JSON.stringify({
                            loginType: "facebook",
                          });
                          window.webkit.messageHandlers.login.postMessage(
                            Base64.encode(params)
                          );
                        } else {
                          renderProps.onClick(e);
                        }
                      }}
                    >
                      페이스북 간편 가입하기
                    </button>
                  )}
                /> */}
              </div>

              {/* apple 추가 */}
              {mobileType === "ios" && (
                <div className="input-group">
                  <button
                    className="modal-btn apple"
                    onClick={(e) => {
                      if (mobileType === "ios") {
                        let params = JSON.stringify({
                          loginType: "apple",
                        });
                        window.webkit.messageHandlers.login.postMessage(
                          Base64.encode(params)
                        );
                      }
                    }}
                  >
                    Sign up with Apple
                  </button>
                </div>
              )}
              {/* apple로 가입하기 */}
              <div className="input-group email">
                <button
                  className="modal-btn email"
                  onClick={(e) => setIsEmailSignUp(true)}
                >
                  이메일로 가입하기
                </button>
              </div>
            </div>
            <div className="input-group center">
              <span className="join-txt">
                이미 계정이 있으신가요?
                <button
                  type="button"
                  onClick={(e) => setSignLayer("signIn")}
                  className="join underline"
                >
                  로그인
                </button>
              </span>
            </div>

            <div className="input-group center">
              <span className="join-txt">
                제보 블로그를 방문해 보세요.
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    const url = "https://guide.jebo.io/";
                    if (mobileType === "ios") {
                      const encodedUrl = Base64.encode(url);
                      window.webkit.messageHandlers.openOuterLink.postMessage(
                        encodedUrl
                      );
                    } else {
                      window.open(url);
                    }
                  }}
                  className="join underline"
                >
                  제보 블로그 바로가기
                </button>
              </span>
            </div>
          </>
        )}
      </div>
      {openTermsOfJebo ? (
        <SignUpAgreeLayer
          socialSignIn={socialSignIn}
          infoStruct={infoStruct}
          setInfoStruct={setInfoStruct}
          setOpenTermsOfJebo={setOpenTermsOfJebo}
        />
      ) : openComplete ? (
        <SignUpComplete
          setSignLayer={setSignLayer}
          setOpenComplete={setOpenComplete}
        />
      ) : null}
    </div>
  );
}
