import user_icon from "assets/imgs/icons/user_icon.svg";
import React, { createContext } from "react";

export const signAppContext = createContext({
  isLoggedIn: false,
  myInfo: {
    memberUid: "",
    nickName: "",
    interLocations: [],
    interTag: "",
    imageUrl: user_icon,
    error: "",
  },
  handleSignInApp: () => {},
  handleSignOutApp: () => {},
  setMyInfo: () => {},
});

export default function SignContextProvider({ signApp, children: Components }) {
  return (
    <signAppContext.Provider value={signApp}>
      {Components}
    </signAppContext.Provider>
  );
}
