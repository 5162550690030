import { signAppContext } from "components/providers/SignContextProvider";
import { subscribeMessageContext } from "components/providers/SubscribeMessageContextProvider";
import React, { useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";

export default function MobileNavigator() {
  const { isNewMessage } = useContext(subscribeMessageContext);
  const { isLoggedIn } = useContext(signAppContext);

  const history = useHistory();

  return (
    <div className="section nav-bar mobile">
      <nav>
        <NavLink
          className="menu-home"
          activeClassName="menu-home active"
          exact={true}
          to={{ pathname: "/home" }}
          onClick={(e) => {
            //23.05.18 codelua 세션 저장값 삭제. history back에서만 동작하고 홈버튼 클릭시는 안하기 위해.
            sessionStorage.removeItem("homeFeedData");
            sessionStorage.removeItem("homeFeedScrollY");
            sessionStorage.removeItem("homeFeedCursor");
            sessionStorage.removeItem("homeFeedSort");
            sessionStorage.removeItem("homeFeedCategory");
            // document.scrollingElement.scrollIntoView({
            //   behavior: "smooth",
            //   block: "start",
            // });
            e.preventDefault();
            history.replace({ pathname: "/" });
          }}
        >
          <span>홈</span>
        </NavLink>
        <NavLink
          className="menu-pin"
          activeClassName="menu-pin active"
          to={{ pathname: "/around" }}
        >
          <span>주변제보</span>
        </NavLink>
        <NavLink
          className="menu-add"
          activeClassName="menu-add active"
          to={{ pathname: "/home/add" }}
        ></NavLink>
        <NavLink
          className={`menu-dm ${isLoggedIn && isNewMessage && " dot"}`}
          activeClassName={`menu-dm active ${
            isLoggedIn && isNewMessage && " dot"
          }`}
          to={{
            pathname: "/directMessage",
          }}
        >
          <span>1:1대화</span>
        </NavLink>
        {/* <NavLink className="menu-more" to={{ pathname: "/campaign" }}>
          <span>캠페인&테마</span>
        </NavLink> */}
        <NavLink className="menu-more" to={{ pathname: "/social" }}>
          <span>제보큐브</span>
        </NavLink>
      </nav>
    </div>
  );
}
