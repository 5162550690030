import styled from "styled-components";

import jeboApi from "api/api";
import heart from "assets/imgs/icons/icon_favRed.svg";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ContentItemDefault from "./ContentItemDefault";
import MyContentItem from "./MyContentItem";

import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { useState } from "react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import BannerSectionMid from "./BannerSectionMid";
import "./summaryStyle.css";

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function SummaryNew({
  summaryState, //summaryState.summary
  category,
  dispatchSummary,
  dispatchTheme,
  dispatchCampaign,
  dispatchChannel,
  dispatchCommunity,
  setActiveCategory,
}) {
  const { joinedData, rankedData, sortType } = summaryState;
  const history = useHistory();
  const { handleSignOutApp, isLoggedIn } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);

  const { isMobile } = useContext(isMobileContext);

  useEffect(() => {
    setActiveCategory(category.summary);
  }, [setActiveCategory, category.summary]);

  // 참여목록
  const loadSummaryJoined = useCallback(async () => {
    window.scrollTo(0, 0);
    try {
      const {
        data: { resultData },
        status,
      } = await jeboApi.getSocialJoinedList(category.summary.type);

      if (status === 200) {
        const joinedDatas = JSON.parse(resultData);

        dispatchSummary({
          type: "FETCH_SUMMARY",
          name: "joinedData",
          data: joinedDatas,
        });
      }
    } catch (error) {
      const { status } = error;
      console.log(error, status);
    }
  }, [category, dispatchSummary]);

  useEffect(() => {
    loadSummaryJoined();
  }, [loadSummaryJoined, dispatchSummary, isLoggedIn]);

  const loadSummaryRanked = useCallback(async () => {
    window.scrollTo(0, 0);
    try {
      const {
        data: { resultData },
        status,
      } = await jeboApi.summaryRankedList(sortType);

      if (status === 200) {
        const rankedDatas = JSON.parse(resultData);

        dispatchSummary({
          type: "FETCH_SUMMARY",
          name: "rankedData",
          data: rankedDatas,
        });
      }
    } catch (error) {
      const { status } = error;
      console.log(error, status);
    }
  }, [category, dispatchSummary, sortType]);

  useEffect(() => {
    loadSummaryRanked();
  }, [loadSummaryRanked, dispatchSummary]);

  const toggleLike = async (socialId, like, likeCount) => {
    if (!isLoggedIn) {
      setSignLayer("signIn");
      return;
    }
    try {
      const { status } = await jeboApi.toggleSocialLike(socialId, like);

      if (status === 200) {
        dispatchTheme({
          type: "UPDATE_DATA",
          data: {
            scId: socialId,
            like,
            likeCount: like
              ? likeCount + 1
              : likeCount > 0
              ? likeCount - 1
              : likeCount,
          },
        });
        dispatchCampaign({
          type: "UPDATE_DATA",
          data: {
            scId: socialId,
            like,
            likeCount: like
              ? likeCount + 1
              : likeCount > 0
              ? likeCount - 1
              : likeCount,
          },
        });
        dispatchChannel({
          type: "UPDATE_DATA",
          data: {
            scId: socialId,
            like,
            likeCount: like
              ? likeCount + 1
              : likeCount > 0
              ? likeCount - 1
              : likeCount,
          },
        });
        dispatchCommunity({
          type: "UPDATE_DATA",
          data: {
            scId: socialId,
            like,
            likeCount: like
              ? likeCount + 1
              : likeCount > 0
              ? likeCount - 1
              : likeCount,
          },
        });

        dispatchSummary({
          type: "UPDATE_RANKED",
          data: {
            scId: socialId,
            like,
            likeCount: like
              ? likeCount + 1
              : likeCount > 0
              ? likeCount - 1
              : likeCount,
          },
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
        }
      }
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const swiperResponse = {
    spaceBetween: 0,
    slidesPerView: 2.5,
  };

  useEffect(() => {
    // 창 크기 변경 이벤트 리스너 등록
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // 컴포넌트 언마운트 시 이벤트 리스너 해제
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // setState로 값을 변경해주려고 하면 오류가 발생함
  // 너무 많은 re render를 했다
  if (windowWidth > 481 && windowWidth < 630) {
    // 481 spaceBetween slidesPerView
    swiperResponse["spaceBetween"] = 10;
    swiperResponse["slidesPerView"] = 3;
  } else if (windowWidth > 630 && windowWidth < 770) {
    // 630 spaceBetween slidesPerView
    // console.log("630");
    swiperResponse["spaceBetween"] = 15;
    swiperResponse["slidesPerView"] = 4;
  } else if (windowWidth > 770 && windowWidth < 940) {
    // 770 spaceBetween slidesPerView
    // console.log("770");
    swiperResponse["spaceBetween"] = 20;
    swiperResponse["slidesPerView"] = 5;
  } else if (windowWidth > 940) {
    // 940 spaceBetween slidesPerView
    // console.log("940");
    swiperResponse["spaceBetween"] = 25;
    swiperResponse["slidesPerView"] = 6;
  }

  return (
    <SummaryContainer>
      {isLoggedIn && joinedData ? (
        <SummarySection>
          <SummaryHeader>
            <SummaryTitle heart={heart}>내가 참여한 목록</SummaryTitle>
          </SummaryHeader>
          {/* 20230504 (진희) 현재 2개만 나오고 있지만 여러개 콘텐츠가 나올 수 있도록 수정해야함 api 수정 필요 */}
          <SummaryMyContent>
            <Swiper
              autoplay={false}
              className="swiper-container live-swiper summary"
              spaceBetween={isMobile ? 0 : swiperResponse["spaceBetween"]}
              slidesPerView={isMobile ? 2.5 : swiperResponse["slidesPerView"]}
              loop={false}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              pagination={{ el: ".swiper-pagination", clickable: true }}
              onSwiper={(swiper) => {}}
              onSlideChange={() => {}}
            >
              {joinedData.map((content, index) => (
                <SwiperSlide
                  key={index}
                  style={{
                    width: "140px !important",
                  }}
                  className="swiper-slide live-item"
                >
                  <MyContentItem
                    key={content.scId}
                    item={content}
                    category={
                      content.scType === 1
                        ? category.theme
                        : content.scType === 2
                        ? category.campaign
                        : content.scType === 3
                        ? category.channel
                        : content.scType === 4
                        ? category.community
                        : category.summary
                    }
                    toggleLike={() => {
                      toggleLike(
                        content.scId,
                        !content.like,
                        content.likeCount
                      );
                    }}
                  />
                </SwiperSlide>
              ))}
              <SwiperPaginationBtn className="swiper-pagination"></SwiperPaginationBtn>
            </Swiper>
          </SummaryMyContent>
        </SummarySection>
      ) : isLoggedIn ? (
        <EmptyMyContents>
          아직 참여한 콘텐츠가 없습니다.
          <br />
          목록에 참여해보세요
        </EmptyMyContents>
      ) : (
        <EmptyMyContents>
          제보 회원가입 후 활동을 시작해 보세요!
          <br />
          다양한 콘텐츠가 회원님을 기다리고 있어요
        </EmptyMyContents>
      )}

      {/* 광고영역 */}
      <BannerSectionMid category={"summary"}></BannerSectionMid>
      {/* 광고영역 */}

      <SortContainer>
        <SortBtn
          className={sortType === 0 ? "active" : ""}
          onClick={(e) => {
            dispatchSummary({
              type: "CHANGE_SORT_SUMMARY",
              sortType: 0,
            });
          }}
        >
          인기순
        </SortBtn>
        <SortBtn
          className={sortType === 1 ? "active" : ""}
          onClick={(e) => {
            dispatchSummary({
              type: "CHANGE_SORT_SUMMARY",
              sortType: 1,
            });
          }}
        >
          최신순
        </SortBtn>
      </SortContainer>

      {/* 인기 아이템 차트 */}
      {rankedData &&
        rankedData.map((data, index) => (
          <SummarySection>
            <ContentItemDefault
              item={data}
              category={
                data.scType === 1
                  ? category.theme
                  : data.scType === 2
                  ? category.campaign
                  : data.scType === 3
                  ? category.channel
                  : data.scType === 4
                  ? category.community
                  : category.summary
              }
              toggleLike={() => {
                toggleLike(data.scId, !data.like, data.likeCount);
              }}
              index={index}
            />
          </SummarySection>
        ))}
    </SummaryContainer>
  );
}

const SummaryContainer = styled.div`
  min-height: 100vh;
  height: 100%;

  padding: 61px 14px 0 14px;

  @media screen and (min-width: 481px) {
    /* overflow: unset; */
    padding-top: 21px;
    width: 100%;
  }
  @media (min-width: 481px) and (max-width: 1200px) {
  }

  border: 1px solid transparent;
`;

const SummarySection = styled.div`
  margin-bottom: 10px;

  border: 1px solid transparent;
`;

const SummaryHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;

  @media screen and (min-width: 481px) {
    margin-bottom: 12px;
  }
`;

const SummaryTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  position: relative;

  @media screen and (min-width: 481px) {
    font-size: 16px;
  }

  &:after {
    margin-left: 4px;
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    background: url(${({ heart }) => heart}) no-repeat;
    background-size: contain;
    background-position: center;
  }
`;

const SummaryMyContent = styled.div`
  display: flex;
  height: 185px;
`;

const SortContainer = styled.div`
  margin-bottom: 8px;
`;

const SortBtn = styled.button`
  width: 60px;
  height: 22px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  border: 1px solid #c5c5c5;
  color: #4a4a4a;
  background-color: transparent;
  border-radius: 100px;

  margin-right: 8px;

  @media screen and (min-width: 481px) {
    font-size: 13px;
    width: 66px;
    height: 25px;
  }
  &.active {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    color: #8121e0;

    border: none;
    padding: 2px 10px;
    background: rgba(204, 152, 255, 0.63);
    @media screen and (min-width: 481px) {
      font-size: 13px;
      width: 66px;
      height: 25px;
    }
  }
`;
const SwiperPaginationBtn = styled.div`
  &.swiper-pagination {
    text-align: center !important;
    & .swiper-pagination-bullet-active {
      color: #3289fc;
    }
    & .swiper-pagination-bullet {
      background: rgba(50, 137, 252, 0.4);
      width: 5px;
      height: 5px;
      margin: 0 3px;
      bottom: 10px;
    }
  }
`;

const EmptyMyContents = styled.div`
  width: 100%;
  height: 130px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  line-height: 17px;
  font-weight: bold;
  margin-bottom: 10px;
`;
