import jeboApi from "api/api";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

export default function DeleteConfirmLayer({
  communicationId,
  setShowDeleteConfirmLayer,
  setTitles,
}) {
  const { handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);

  const history = useHistory();

  //const [error, setError] = useState("");

  const deleteDirectMessage = async () => {
    if (!communicationId) return;

    try {
      const { status } = await jeboApi.deleteDirectMessage(communicationId);
      if (status === 200) {
        setTitles((prev) =>
          prev.filter((title) => communicationId !== title.communicationId)
        );
        history.replace({ pathname: "/directMessage" });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          console.log(error.response.status);
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  };

  return (
    <div className="pop-menu item-pop delete active">
      <div className="pop-header">
        <strong>대화 내용을 삭제하시겠습니까?</strong>
      </div>
      <div className="pop-body">
        <span className="txt">
          대화 내용이 삭제되고 채팅 목록에서도 사라집니다.
        </span>
        <div className="btns">
          <button
            type="button"
            className="cancel"
            onClick={(e) => {
              setShowDeleteConfirmLayer(false);
            }}
          >
            취소
          </button>
          <button
            type="button"
            className="delete"
            onClick={(e) => {
              deleteDirectMessage();
            }}
          >
            삭제
          </button>
        </div>
      </div>
    </div>
  );
}
