const AuthService = {
  setAuth: (
    authorization,
    refreshToken,
    email,
    nickName,
    grantType,
    memberUid,
    socialYn,
    loginType
  ) => {
    sessionStorage.setItem("authorization", authorization);
    sessionStorage.setItem("refresh_token", refreshToken);
    sessionStorage.setItem("email", email);
    sessionStorage.setItem("nickName", nickName);
    sessionStorage.setItem("grantType", grantType);
    sessionStorage.setItem("memberUid", memberUid);
    sessionStorage.setItem("socialYn", socialYn);
    sessionStorage.setItem("loginType", loginType);
  },
  setAutoLoginToken: (autoLoginToken) => {
    localStorage.setItem("autoLoginToken", autoLoginToken);
  },
  setKeepId: (memberEmail) => {
    localStorage.setItem("jeboKeepId", memberEmail);
  },
  setMyInfo: (myInfo) => {
    sessionStorage.setItem("myInfo", myInfo);
    AuthService.getAutoLoginToken() && localStorage.setItem("myInfo", myInfo);
  },
  setActiveLocation: (activeLocation) => {
    sessionStorage.setItem("activeLocation", activeLocation);
  },
  removeMyInfo: () => {
    sessionStorage.removeItem("myInfo");
    localStorage.removeItem("myInfo");
  },
  removeAuth: () => {
    sessionStorage.removeItem("authorization");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("nickName");
    sessionStorage.removeItem("grantType");
    sessionStorage.removeItem("memberUid");
    localStorage.removeItem("autoLoginToken");
  },
  removeAutoLoginToken: () => {
    localStorage.removeItem("jeboRefreshToken");
  },
  removeKeepId: () => {
    localStorage.removeItem("jeboKeepId");
  },
  setNickName: (nickName) => {
    sessionStorage.setItem("nickName", nickName);
  },
  getAuthorization: () => {
    return sessionStorage.getItem("authorization");
  },
  getRefreshToken: () => {
    return sessionStorage.getItem("refresh_token");
  },
  getSocialYn: () => {
    return sessionStorage.getItem("socialYn");
  },
  getLoginType: () => {
    return sessionStorage.getItem("loginType");
  },
  getAutoLoginToken: () => {
    return localStorage.getItem("autoLoginToken");
  },
  getKeepId: () => {
    return localStorage.getItem("jeboKeepId");
  },
  getMyInfo: () => {
    return localStorage.getItem("myInfo")
      ? localStorage.getItem("myInfo")
      : sessionStorage.getItem("myInfo")
      ? sessionStorage.getItem("myInfo")
      : null;
  },
  getActiveLocation: () => {
    return sessionStorage.getItem("activeLocation");
  },
  getHistorySearchLocations: () => {
    return localStorage.getItem("historySearchLocations");
  },
  setHistorySearchLocation: (location) => {
    let locations = [location];
    let historySearchLocations = localStorage.getItem("historySearchLocations");

    if (historySearchLocations) {
      historySearchLocations = JSON.parse(historySearchLocations);

      //중복된 결과가 있다면 삭제 후 마지막 값으로 insert
      if (
        historySearchLocations.some(
          (searchHistory) =>
            Number.parseFloat(searchHistory.latitude) ===
              Number.parseFloat(location.latitude) &&
            Number.parseFloat(searchHistory.longitude) ===
              Number.parseFloat(location.longitude)
        )
      ) {
        const locationId = historySearchLocations.find(
          (searchHistory) =>
            Number.parseFloat(searchHistory.latitude) ===
              Number.parseFloat(location.latitude) &&
            Number.parseFloat(searchHistory.longitude) ===
              Number.parseFloat(location.longitude)
        ).id;
        historySearchLocations = historySearchLocations.filter(
          (arr) => arr.id !== locationId
        );
      }

      //5개 이상일 때 shift로 첫번째 값 삭제
      if (historySearchLocations.length >= 30) historySearchLocations.pop();
      locations = [location, ...historySearchLocations];
    }
    localStorage.setItem("historySearchLocations", JSON.stringify(locations));
  },

  removeHistorySearchLocation: (id) => {
    let locations = localStorage.getItem("historySearchLocations");
    if (locations) {
      locations = JSON.parse(locations);
      locations = locations.filter((location) => location.id !== id);
      localStorage.setItem("historySearchLocations", JSON.stringify(locations));
    }
  },
  clearHistorySearchLocations: () => {
    localStorage.removeItem("historySearchLocations");
  },
};

export default AuthService;
