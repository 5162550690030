import jeboApi from "api/api";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { AdminNoticeList } from "components/templates/AdminNoticeList";
import { useCallback, useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useHistory } from "react-router";
import styled from "styled-components";

export default function NoticeListContainer({ setActiveMenu, noticeId }) {
  const END_LIMIT = 9;

  const { handleSignOutApp, setSignLayer } = useContext(signAppContext);

  const [noticeList, setNoticeList] = useState([]);

  const [lastFeedRef, inView] = useInView({ threshold: 0 });
  const [error, setError] = useState("");
  const [notLoadedData, setNotLoadedData] = useState(false);

  const [cursor, setCursor] = useState(0);

  const { isMobile, mobileType } = useContext(isMobileContext);

  const history = useHistory();

  const getNoticeList = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.noticeList(END_LIMIT, cursor);

      if (status === 200) {
        const notice = JSON.parse(data.noticeList);
        setNotLoadedData(notice.length < END_LIMIT ? true : false);

        if (cursor === 0) setNoticeList(notice);
        else setNoticeList((prev) => prev.concat(notice));
      } else if (status === 204) {
        setNotLoadedData(true);
      }
    } catch (error) {
      setError(error);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인 해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  }, [cursor, history, handleSignOutApp, setSignLayer]);

  useEffect(() => {
    getNoticeList();
  }, [getNoticeList]);

  useEffect(() => {
    if (inView && !error && !notLoadedData)
      setCursor((prev) => prev + END_LIMIT);
  }, [inView, error, notLoadedData]);

  // const handleRefresh = useCallback(async () => {
  //   cursor !== 0 ? setCursor(0) : getNoticeList();
  // }, []);

  return (
    <Container>
      <Section>
        <TitleContainer>
          <Title>공지사항</Title>
        </TitleContainer>
        <Notice>
          {noticeList && noticeList.length > 0 ? (
            noticeList.map((notice, index) => (
              <AdminNoticeList
                key={index}
                notice={notice}
                mobileType={mobileType}
                open={noticeId && noticeId === notice.noticeId ? true : false}
                focus={noticeId ? true : false}
              />
            ))
          ) : (
            <>
              <NoData> 공지사항이 없습니다. </NoData>
            </>
          )}
          {noticeList.length > 0 && <div ref={lastFeedRef}></div>}
        </Notice>
      </Section>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 100%;
  min-width: 160px;
`;

const Section = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  padding: 40px;
  overflow-y: auto;
`;

const TitleContainer = styled.div`
  margin-bottom: 40px;
`;

const Title = styled.strong`
  color: var(--text-defaultBlackColor);
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.04em;
`;

const Notice = styled.div`
  display: flex;
  align-items: center;
  padding-top: 60px;
  flex-direction: column;
  justify-content: flex-start;
`;

const NoData = styled.div`
  margin: 100% 0%;
`;
