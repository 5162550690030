//import { useHistory } from "react-router-dom";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import imagesLoaded from "imagesloaded";
import { Base64 } from "js-base64";
import { useContext, useEffect, useRef, useState } from "react";

export default function DirectMessageFeedCard({ feed }) {
  const refImage = useRef();
  //const history = useHistory();
  const [mediaClassName, setMediaClassName] = useState("");
  const { isMobile, mobileType } = useContext(isMobileContext);

  const addressParsing = (address, placeName) => {
    let resultStr = "";

    if (address.length > 0) {
      resultStr =
        address.split(" ")[0] +
        " " +
        address.split(" ")[1] +
        " " +
        address.split(" ")[2];
    } else {
      resultStr = placeName;
    }

    return resultStr;
  };

  const locationResult = addressParsing(feed.address, feed.placeName);

  useEffect(() => {
    imagesLoaded(refImage.current, (instance) => {
      const { img } = instance.images[0];
      img.width > img.height
        ? setMediaClassName("hor")
        : setMediaClassName("ver");
    });
  }, [feed]);

  return (
    <div className="line">
      <a
        href="#!"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (mobileType === "ios") {
            const encodedUrl = Base64.encode(
              process.env.REACT_APP_HOST + "/detail/" + feed.jeboId
            );
            window.webkit.messageHandlers.openOuterLink.postMessage(encodedUrl);
          } else if (mobileType === "android") {
            window.location.href =
              process.env.REACT_APP_HOST + "/detail/" + feed.jeboId;
          } else if (isMobile) {
            window.location.href =
              process.env.REACT_APP_HOST + "/detail/" + feed.jeboId;
          } else {
            window.open(
              process.env.REACT_APP_HOST + "/detail/" + feed.jeboId,
              "_blank"
            );
          }
        }}
      >
        <div className="feed-item">
          <div className="item-photo">
            <img
              ref={refImage}
              style={{ top: "auto" }}
              className={mediaClassName}
              src={process.env.REACT_APP_RESOURCE_HOST + feed.mediaUrl}
              alt="제보"
            />
          </div>
          <div className="info">
            <span className="user-name">{feed.nickName}</span>
            <span className="location">{locationResult}</span>
            <div className="text">
              <p>
                {feed.hashTag &&
                  feed.hashTag
                    .split(",")
                    .map((hashTagSplit, index) => (
                      <span
                        key={index}
                        className="hashtag"
                      >{`${hashTagSplit} `}</span>
                    ))}
                {feed.contents}
              </p>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
