import jeboApi from "api/api";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingComponent from "components/templates/LoadingComponent";

export default function PushAlarmContainer({ setActiveMenu }) {
  const { handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const [loading, setLoading] = useState(true);

  // {
  //   pushLike: false,
  //   pushComment: false,
  //   pushReply: false,
  //   pushComLike: false,
  //   pushMention: false,
  //   pushFollow: false,
  //   pushFollowFeed: false,
  //   pushDm: false,
  // }
  const [pushSettings, setPushSettings] = useState(null);

  const history = useHistory();

  const updatePushSettings = (settings) => {
    console.log("updatePushSettings");

    const {
      pushLike,
      pushComment,
      pushReply,
      pushComLike,
      pushMention,
      pushFollow,
      pushFollowFeed,
      pushDm,
      pushMarketing,
    } = settings;

    jeboApi
      .updatePushSettings({
        pushLike: pushLike ? "1" : "0",
        pushComment: pushComment ? "1" : "0",
        pushReply: pushReply ? "1" : "0",
        pushComLike: pushComLike ? "1" : "0",
        pushMention: pushMention ? "1" : "0",
        pushFollow: pushFollow ? "1" : "0",
        pushFollowFeed: pushFollowFeed ? "1" : "0",
        pushDm: pushDm ? "1" : "0",
        pushMarketing: pushMarketing ? "1" : "0",
      })
      .then(({ status }) => {
        console.log("push setting : ", status);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            console.log(error.response.status);
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            handleSignOutApp();
            setSignLayer("signIn");
            history.replace({ pathname: "/" });
          }
        }
      });
  };

  const getPushSetting = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.getPushSettings();

      if (status === 200) {
        const {
          pushComment,
          pushLike,
          pushReply,
          pushComLike,
          pushMention,
          pushFollow,
          pushFollowFeed,
          pushDm,
          pushMarketing,
        } = JSON.parse(data.pushSettings);

        setPushSettings({
          pushLike: pushLike === "1" ? true : false,
          pushComment: pushComment === "1" ? true : false,
          pushReply: pushReply === "1" ? true : false,
          pushComLike: pushComLike === "1" ? true : false,
          pushMention: pushMention === "1" ? true : false,
          pushFollow: pushFollow === "1" ? true : false,
          pushFollowFeed: pushFollowFeed === "1" ? true : false,
          pushDm: pushDm === "1" ? true : false,
          pushMarketing: pushMarketing === "1" ? true : false,
        });
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    } finally {
      setLoading(false);
    }
  }, [history, handleSignOutApp, setSignLayer]);

  useEffect(() => {
    getPushSetting();
  }, [getPushSetting]);

  return loading ? (
    <LoadingComponent />
  ) : (
    <div className="s-body active">
      <div className="s-title">
        <button
          type="button"
          className="list-back mobile"
          onClick={(e) => setActiveMenu("")}
        ></button>
        <strong>푸시 알림</strong>
      </div>
      <div className="s-inner">
        <div className="info-wrap">
          <div className="push-list">
            <div className="push-group">
              <div className="push-title">
                <span>모든 알림</span>
                <input
                  type="checkbox"
                  className="toggle-check"
                  id="pushAll"
                  onChange={(e) => {
                    const settings = {
                      pushLike: e.target.checked,
                      pushComment: e.target.checked,
                      pushReply: e.target.checked,
                      pushComLike: e.target.checked,
                      pushMention: e.target.checked,
                      pushFollow: e.target.checked,
                      pushFollowFeed: e.target.checked,
                      pushDm: e.target.checked,
                      pushMarketing: e.target.checked,
                    };

                    updatePushSettings(settings);
                    setPushSettings(settings);
                  }}
                  checked={Object.values(pushSettings).every((value) => value)}
                />
                <label htmlFor="pushAll"></label>
              </div>
            </div>

            <div className="push-group">
              <div className="push-title">
                <span>게시물 알림</span>
              </div>
              <div className="push-item">
                <span>받은 댓글</span>
                <input
                  type="checkbox"
                  className="toggle-check"
                  id="pushComment"
                  onChange={(e) => {
                    updatePushSettings(
                      Object.assign({}, pushSettings, {
                        pushComment: e.target.checked,
                      })
                    );
                    setPushSettings((prev) =>
                      Object.assign({}, prev, { pushComment: e.target.checked })
                    );
                  }}
                  checked={pushSettings.pushComment}
                />
                <label htmlFor="pushComment"></label>
              </div>
              <div className="push-item">
                <span>받은 공감</span>
                <input
                  type="checkbox"
                  className="toggle-check"
                  id="pushLike"
                  onChange={(e) => {
                    updatePushSettings(
                      Object.assign({}, pushSettings, {
                        pushLike: e.target.checked,
                      })
                    );
                    setPushSettings((prev) =>
                      Object.assign({}, prev, { pushLike: e.target.checked })
                    );
                  }}
                  checked={pushSettings.pushLike}
                />
                <label htmlFor="pushLike"></label>
              </div>
            </div>
            <div className="push-group">
              <div className="push-title">
                <span>댓글 알림</span>
              </div>
              <div className="push-item">
                <span>받은 답글</span>
                <input
                  type="checkbox"
                  className="toggle-check"
                  id="pushReply"
                  onChange={(e) => {
                    updatePushSettings(
                      Object.assign({}, pushSettings, {
                        pushReply: e.target.checked,
                      })
                    );
                    setPushSettings((prev) =>
                      Object.assign({}, prev, { pushReply: e.target.checked })
                    );
                  }}
                  checked={pushSettings.pushReply}
                />
                <label htmlFor="pushReply"></label>
              </div>
              <div className="push-item">
                <span>받은 공감</span>
                <input
                  type="checkbox"
                  className="toggle-check"
                  id="pushComLike"
                  onChange={(e) => {
                    updatePushSettings(
                      Object.assign({}, pushSettings, {
                        pushComLike: e.target.checked,
                      })
                    );
                    setPushSettings((prev) =>
                      Object.assign({}, prev, { pushComLike: e.target.checked })
                    );
                  }}
                  checked={pushSettings.pushComLike}
                />
                <label htmlFor="pushComLike"></label>
              </div>
              <div className="push-item">
                <span>받은 언급</span>
                <input
                  type="checkbox"
                  className="toggle-check"
                  id="pushMention"
                  onChange={(e) => {
                    updatePushSettings(
                      Object.assign({}, pushSettings, {
                        pushMention: e.target.checked,
                      })
                    );
                    setPushSettings((prev) =>
                      Object.assign({}, prev, { pushMention: e.target.checked })
                    );
                  }}
                  checked={pushSettings.pushMention}
                />
                <label htmlFor="pushMention"></label>
              </div>
            </div>

            <div className="push-group">
              <div className="push-title">
                <span>구독 알림</span>
              </div>

              <div className="push-item">
                <span>나를 구독</span>
                <input
                  type="checkbox"
                  className="toggle-check"
                  id="pushFollow"
                  onChange={(e) => {
                    updatePushSettings(
                      Object.assign({}, pushSettings, {
                        pushFollow: e.target.checked,
                      })
                    );
                    setPushSettings((prev) =>
                      Object.assign({}, prev, { pushFollow: e.target.checked })
                    );
                  }}
                  checked={pushSettings.pushFollow}
                />
                <label htmlFor="pushFollow"></label>
              </div>
              <div className="push-item">
                <span>관심 구독 회원의 새글</span>
                <input
                  type="checkbox"
                  className="toggle-check"
                  id="pushFollowFeed"
                  onChange={(e) => {
                    updatePushSettings(
                      Object.assign({}, pushSettings, {
                        pushFollowFeed: e.target.checked,
                      })
                    );
                    setPushSettings((prev) =>
                      Object.assign({}, prev, {
                        pushFollowFeed: e.target.checked,
                      })
                    );
                  }}
                  checked={pushSettings.pushFollowFeed}
                />
                <label htmlFor="pushFollowFeed"></label>
              </div>
            </div>
            <div className="push-group">
              <div className="push-title">
                <span>1:1 대화 알림</span>
              </div>
              <div className="push-item">
                <span>메시지</span>
                <input
                  type="checkbox"
                  className="toggle-check"
                  id="pushDm"
                  onChange={(e) => {
                    updatePushSettings(
                      Object.assign({}, pushSettings, {
                        pushDm: e.target.checked,
                      })
                    );
                    setPushSettings((prev) =>
                      Object.assign({}, prev, { pushDm: e.target.checked })
                    );
                  }}
                  checked={pushSettings.pushDm}
                />
                <label htmlFor="pushDm"></label>
              </div>
            </div>
            <div className="push-group">
              <div className="push-title">
                <span>이벤트 혜택 알림</span>
              </div>
              <div className="push-item">
                <span>푸시알림</span>
                <input
                  type="checkbox"
                  className="toggle-check"
                  id="pushMarketing"
                  onChange={(e) => {
                    updatePushSettings(
                      Object.assign({}, pushSettings, {
                        pushMarketing: e.target.checked,
                      })
                    );
                    setPushSettings((prev) =>
                      Object.assign({}, prev, {
                        pushMarketing: e.target.checked,
                      })
                    );
                  }}
                  checked={pushSettings.pushMarketing}
                />
                <label htmlFor="pushMarketing"></label>
              </div>
            </div>
            {/* <div className="push-item">
              <span>보안</span>
              <input type="checkbox" className="toggle-check" id="a7" onChange={(e) => {onChangeCheckbox(e)}} checked={pushSettings && pushSettings.pushSecurity === '1' ? true : false} />
              <label htmlFor="a7"></label>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
