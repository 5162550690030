import jeboApi from "api/api";
import { feedsContext } from "components/providers/FeedContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { useContext, useState } from "react";
import TagManager from "react-gtm-module";
import cameraIcon from "assets/imgs/icon_camera.svg";
import clearIcon from "assets/imgs/icons/exit_blue.svg";
import styled from "styled-components";
import LoadingComponent from "./LoadingComponent";

export default function CommentWriteForm({
  feed,
  setFeed,
  // setReCommentActiveRef,
}) {
  const { isLoggedIn, handleSignOutApp, myInfo } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const { setFeeds } = useContext(feedsContext);
  const [commentText, setCommentText] = useState("");
  const [loading, setLoading] = useState(false);

  const [mediaFile, setMediaFile] = useState({
    file: null,
    url: "",
  });
  const [isMedia, setIsMedia] = useState(false);
  const [commentInputHeight, setCommentInputHeight] = useState("25px");

  const resizeTextarea = (e) => {
    setCommentInputHeight(`${e.target.scrollHeight}px`);
  };

  const handleFileUpload = (e) => {
    var reader = new FileReader();
    let file = e.target.files[0];

    reader.onload = (e) => {
      setMediaFile({
        file: file,
        url: URL.createObjectURL(file),
      });
    };

    reader.readAsDataURL(file);
    setIsMedia(true);
  };

  const removeMedia = (removeMedia) => {
    URL.revokeObjectURL(removeMedia.url);
    setMediaFile({
      file: null,
      url: "",
    });
    setIsMedia(false);
  };

  const registComment = async () => {
    if (!isLoggedIn) {
      setSignLayer("signIn");
      return;
    }
    if (!commentText && !mediaFile.file) return;

    setLoading(true);
    try {
      let mentions = commentText.trim().match(/@[^@]\S+/gi);
      mentions = mentions
        ? [...mentions]
            .map((text) => text.substring(1))
            .reduce((acc, obj) => (acc.includes(obj) ? acc : [...acc, obj]), [])
        : [];

      const {
        status,
        data: { comments, commentsCnt },
      } = await jeboApi.commentReg(
        mediaFile,
        commentText,
        feed.jeboId,
        mentions,
        null,
        null
      );
      if (status === 200) {
        // error로 빠지지 않았다면 등록 성공으로 볼것이고
        // const tagManagerArgs = {
        //   dataLayer: {
        //     event: "commentReg",
        //   },
        // };
        // TagManager.dataLayer(tagManagerArgs);
        const updateComments = JSON.parse(comments);
        setFeeds((feeds) =>
          feeds.map((item) => {
            if (feed.jeboId === item.jeboId) {
              return Object.assign({}, item, { commentCnt: commentsCnt });
            }
            return item;
          })
        );

        setFeed((prev) =>
          Object.assign({}, prev, { jeboCommentList: updateComments })
        );
      }
    } catch (error) {
      console.log("error : ", error);

      if (error.response) {
        console.log("error : ", error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
        }
      }
    } finally {
      setCommentText("");
      setCommentInputHeight("25px");
      setLoading(false);
      setMediaFile({
        file: null,
        url: "",
      });
      setIsMedia(false);
    }
  };

  return (
    <>
      {mediaFile && isMedia && (
        <CommentMediaContainer>
          <CommentMediaCloseBtn
            type="button"
            onClick={(e) => removeMedia(mediaFile)}
          >
            <i className="icon-cross3"></i>
          </CommentMediaCloseBtn>
          <CommentMediaImgContainer>
            <CommentMediaImg src={mediaFile.url} alt="commentMedia" />
          </CommentMediaImgContainer>
        </CommentMediaContainer>
      )}
      <div className="comment-write">
        <div className="thumb">
          <img src={myInfo.imageUrl} alt="사용자1" />
        </div>
        <div className="comment-input">
          <textarea
            placeholder="댓글을 입력하세요."
            onKeyUp={resizeTextarea}
            onKeyDown={resizeTextarea}
            onChange={(e) => {
              if (e.target.value.length > 200) return;
              setCommentText(e.target.value);
            }}
            onFocus={(e) => {
              // setReCommentActiveRef(null);
              !isLoggedIn && setSignLayer("signIn");
            }}
            value={commentText}
            style={{ height: commentInputHeight }}
          ></textarea>
          <input
            type="file"
            className="file-upload"
            id="commentPic"
            onClick={(e) => {
              e.stopPropagation();
              if (!isLoggedIn) {
                e.preventDefault();
                setSignLayer("signIn");
              }
              e.target.value = null;
            }}
            onInput={handleFileUpload}
            accept="image/*"
          />
          <label htmlFor="commentPic">
            <img src={cameraIcon} alt="이미지추가" />
          </label>
          <button
            onClick={() => {
              if (!commentText && !mediaFile.file) {
                alert("댓글이나 이미지를 입력해주세요!");
                return;
              } else {
                !loading && registComment();
              }
            }}
          >
            게시
          </button>
        </div>
      </div>
      {loading && <LoadingComponent />}
    </>
  );
}

const CommentMediaContainer = styled.div`
  width: 100%;

  text-align: center;
  left: 0;
  background-color: rgb(0 0 0 / 20%);
  z-index: 2;
  margin-bottom: 4px;

  @media screen and (min-width: 481px) {
    position: absolute;
    bottom: 20px;
    max-height: 200px;
  }

  @media screen and (max-width: 480px) {
    position: fixed;
    bottom: 115px;
    max-height: 140px;
  }
`;

const CommentMediaImgContainer = styled.div`
  margin: 5px 0;
  height: 100%;
`;

const CommentMediaImg = styled.img`
  width: 25%;
  @media screen and (min-width: 481px) {
    width: 22%;
  }
`;

const CommentMediaCloseBtn = styled.button`
  position: absolute;
  right: 10px;
  top: 9px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  padding: 0;
  border: 1px solid #014681;
  border-radius: 50%;
  background: url(${clearIcon}) no-repeat;
  background-size: 10px;
  background-position: center center;
`;
