import jeboApi from "api/api";
import { convenienceDataContext } from "components/providers/ConvenienceDataContextProvider";
import {
  feedCategoriesContext,
  feedsContext,
} from "components/providers/FeedContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import LoadingComponent from "components/templates/LoadingComponent";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import ConfirmLayer from "../popMenu/ConfirmLayer";
import CategoryContainer from "./CategoryContainer";
import EditorContainer from "./EditorContainer";
import ImageEditorLayer from "./ImageEditorLayer";
import LocationSearchContainer from "./LocationSearchContainer";
import MediaContainer from "./MediaContainer";
import ShareTabLayer from "./ShareTabLayer";

export default function FeedEditLayer({ setDetailFeed }) {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const { convenienceData } = useContext(convenienceDataContext);
  const { handleSignOutApp } = useContext(signAppContext);

  const { feedCategories } = useContext(feedCategoriesContext);
  const { feeds, setFeeds } = useContext(feedsContext);

  const feed =
    location && location.state && location.state.feed
      ? location.state.feed
      : null;

  const [feedType, setFeedType] = useState(feed.feedType);
  const [media, setMedia] = useState(
    feed
      ? feed.jeboMediaList.map((medium) => {
          return {
            id: medium.mediaId,
            file: null,
            url: process.env.REACT_APP_RESOURCE_HOST + medium.mediaUrl,
            type: medium.mediaType,
            upload: false,
            thumbnail: medium.thumbnail,
            changeThumbnail: false,
          };
        })
      : []
  );
  const [locationInfo, setLocationInfo] = useState(
    feed
      ? {
          address: feed.address,
          roadAddress: feed.roadAddress,
          placeName: feed.placeName,
          latitude: feed.latitude,
          longitude: feed.longitude,
          hashtags: feed.locationHashtag ? feed.locationHashtag.split(" ") : [],
        }
      : null
  );

  const [contents, setContents] = useState(feed && feed.contents);
  const [conName, setConName] = useState(null);

  const [hashTags, setHashTags] = useState(
    feed && feed.hashTag
      ? feed.hashTag.split(",").map((item) => item.trim())
      : []
  );
  const [categories, setCategories] = useState(
    feedCategories.map((category) =>
      Object.assign({}, category, {
        checked: hashTags.includes(category.categoryName),
      })
    )
  );
  const [targetCategory, setTargetCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inputStateMessage, setInputStateMessage] = useState("");
  const [locationInputStateMessage, setLocationInputStateMessage] =
    useState("");

  const [showConfirmLayer, setShowConfirmLayer] = useState(false);
  const [eventInfo, setEventInfo] = useState(null);

  const [tabState, setTabState] = useState(
    feed && (feed.feedType === 0 || feed.feedType === 2) ? true : false
  );

  const [openLocation, setOpenLocation] = useState(
    locationInfo && locationInfo.latitude && locationInfo.longitude
      ? true
      : false
  );
  const [openMedia, setOpenMedia] = useState(
    !tabState && media && media.length > 0 ? true : false
  );

  const editorRef = useRef();
  //ImageEditor
  const [showEditor, setShowEditor] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");

  useEffect(() => {
    feed.hashCode &&
      convenienceData &&
      convenienceData.forEach((item) => {
        //이벤트 진행 중 && 이벤트 시작일 이후에 작성된거면 해당 해시태그는 수정 불가함
        if (
          item.isEvent &&
          item.hashCode === feed.hashCode &&
          feed.createAt >= item.eventStart
        ) {
          /* 내용 옆에 넣어주고, 내용에서는 태그를 삭제하여 
             이벤트 관련된 태그에 대해서 삭제 불가하게 조치 */
          setConName(item.codeName);
          // setContents(contents.replace(item.codeName, ""));
        }
      });
  }, [convenienceData]);

  // useEffect(() => {
  //   if (eventInfo) {
  //     const eventShortsDesc = eventInfo.eventShortsDesc;
  //     setContents((prev) => prev.replace(eventShortsDesc, ""));
  //   }
  // }, [eventInfo]);

  useEffect(() => {
    tabState
      ? locationInfo && locationInfo.latitude && locationInfo.longitude
        ? setFeedType(0)
        : setFeedType(2)
      : setFeedType(1);
  }, [locationInfo]);

  // const getEventName = useCallback(async () => {
  //   if (!feed.eventId) return;

  //   try {
  //     const {
  //       data: { event },
  //       status,
  //     } = await jeboApi.getEventName(feed.eventId);

  //     if (status === 200) {
  //       const eventInfo = JSON.parse(event);
  //       setEventInfo(eventInfo);
  //     } else {
  //       setEventInfo(null);
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       console.log(error.response);
  //       if (error.response.status === 403) {
  //         alert("세션이 만료되었습니다. 다시 로그인해주세요.");
  //         handleSignOutApp();
  //       }
  //     }
  //   }
  // }, [feed.eventId, handleSignOutApp]);

  // useEffect(() => {
  //   getEventName();
  // }, [getEventName]);

  const revokeMediaUrl = () => {
    media.forEach((medium) => URL.revokeObjectURL(medium.url));
  };

  const requiredInputCheck = () => {
    if (!locationInfo && feedType === 0) {
      setLocationInputStateMessage("위치를 입력하세요.");
      return false;
    }

    if (contents.length > 10000) {
      setInputStateMessage("내용입력은 10,000자 까지만 가능합니다.");
      return false;
    }

    if (!contents || !media || !media.length === 0) {
      setInputStateMessage("제보 미디어를 첨부하거나 내용을 입력하세요.");
      return false;
    }

    return true;
  };

  const modifyFeed = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let regContents = contents;
      let regHashtags = hashTags;
      // if (eventInfo && eventInfo.eventShortsDesc) {
      //   regContents = ` ${eventInfo.eventShortsDesc} ${regContents}`;

      //   let eventShortsDesc = eventInfo.eventShortsDesc;
      //   if (eventShortsDesc.trim()) {
      //     let eventShortsDescs = eventShortsDesc.trim().split(" ");
      //     eventShortsDescs.forEach((name) => {
      //       if (name.indexOf("#") === 0) regHashtags = [name, ...regHashtags];
      //     });
      //   }
      // }

      /*편의지도 태그가 query-string으로 전달되고, 
      editor에 편의지도 태그가 없다면 regHashtags에 편의지도 태그 push 해줌 */
      if (conName) {
        if (!regHashtags.includes(conName)) {
          regHashtags.push(conName);
          regContents += " " + conName;
        }
      }

      const {
        data: { jeboPost },
      } = await jeboApi.feedModify(
        media,
        regHashtags,
        regContents,
        locationInfo && locationInfo.address ? locationInfo.address : "",
        locationInfo && locationInfo.roadAddress
          ? locationInfo.roadAddress
          : "",
        locationInfo && locationInfo.placeName ? locationInfo.placeName : "",
        locationInfo && locationInfo.latitude ? locationInfo.latitude : null,
        locationInfo && locationInfo.longitude ? locationInfo.longitude : null,
        locationInfo && locationInfo.hashtags ? locationInfo.hashtags : [],
        feed.jeboId,
        feedType
      );
      const jeboPostObj = JSON.parse(jeboPost);
      if (!match.url.includes("/social")) {
        jeboPostObj &&
          setFeeds(
            feeds.map((feed) => {
              if (feed.jeboId === jeboPostObj.jeboId) return jeboPostObj;
              return feed;
            })
          );
      }

      jeboPostObj && setDetailFeed && setDetailFeed(jeboPostObj);
      revokeMediaUrl();
      const url = match.url?.slice(0, match.url?.lastIndexOf("/edit"));
      history.replace({
        pathname: url ? url : "/",
        search: location.search ? location.search : null,
      });
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const modifyShare = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const {
        data: { jeboPost },
      } = await jeboApi.shareFeedModify(
        media,
        hashTags,
        contents,
        locationInfo && locationInfo.address ? locationInfo.address : "",
        locationInfo && locationInfo.roadAddress
          ? locationInfo.roadAddress
          : "",
        locationInfo && locationInfo.placeName ? locationInfo.placeName : "",
        locationInfo && locationInfo.latitude ? locationInfo.latitude : null,
        locationInfo && locationInfo.longitude ? locationInfo.longitude : null,
        locationInfo && locationInfo.hashtags ? locationInfo.hashtags : [],
        feed.jeboId,
        feedType
      );

      const jeboPostObj = JSON.parse(jeboPost);
      jeboPostObj &&
        setFeeds(
          feeds.map((feed) => {
            if (feed.jeboId === jeboPostObj.jeboId) return jeboPostObj;
            return feed;
          })
        );

      jeboPostObj && setDetailFeed && setDetailFeed(jeboPostObj);

      revokeMediaUrl();
      const url = match.url?.slice(0, match.url?.lastIndexOf("/edit"));
      history.replace({
        pathname: url ? url : "/",
        search: location.search ? location.search : null,
      });
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요");
          handleSignOutApp();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return createPortal(
    <>
      <div className="modal-wrap open">
        <div className="modal modal-write open" id="modal-write">
          <div className="modal-header">
            <button
              type="button"
              className="page-back mobile"
              onClick={(e) => {
                e.preventDefault();
                revokeMediaUrl();
                const url = match.url?.slice(
                  0,
                  match.url?.lastIndexOf("/edit")
                );
                history.replace({
                  pathname: url ? url : "/",
                  search: location.search ? location.search : null,
                });
              }}
            ></button>
            <strong>{tabState ? "제보하기" : "제보요청"}</strong>
            <button
              type="button"
              className="modal-close pc"
              onClick={(e) => {
                e.preventDefault();
                revokeMediaUrl();
                const url = match.url?.slice(
                  0,
                  match.url?.lastIndexOf("/edit")
                );
                history.replace({
                  pathname: url ? url : "/",
                  search: location.search ? location.search : null,
                });
              }}
            >
              <i className="icon-cross2"></i>
            </button>
          </div>
          <div className="modal-body">
            {showEditor && (
              <ImageEditorLayer
                mediaUrl={mediaUrl}
                media={media}
                setMedia={setMedia}
                setShowEditor={setShowEditor}
                setIsEdit={setIsEdit}
              />
            )}
            {tabState ? (
              <>
                <CategoryContainer
                  categories={categories}
                  setCategories={setCategories}
                  setTargetCategory={setTargetCategory}
                  tabState={tabState}
                />

                <LocationSearchContainer
                  locationInfo={locationInfo}
                  setLocationInfo={setLocationInfo}
                  locationInputStateMessage={locationInputStateMessage}
                  setLocationInputStateMessage={setLocationInputStateMessage}
                  openLocation={true}
                  setOpenLocation={setOpenLocation}
                  feedType={feedType}
                />

                <MediaContainer
                  media={media}
                  setMedia={setMedia}
                  openMedia={true}
                  setShowEditor={setShowEditor}
                  setMediaUrl={setMediaUrl}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  tabState={tabState}
                />
                <EditorContainer
                  setHashTags={setHashTags}
                  targetCategory={targetCategory}
                  categories={categories}
                  setCategories={setCategories}
                  contents={contents}
                  setContents={setContents}
                  locationInfo={locationInfo}
                  eventInfo={eventInfo}
                  editorRef={editorRef}
                  feedType={feedType}
                  conName={conName}
                />
              </>
            ) : (
              <>
                <CategoryContainer
                  setCategories={setCategories}
                  categories={categories}
                  setTargetCategory={setTargetCategory}
                  tabState={tabState}
                />
                <div className={`shareTab ${openLocation ? "active" : ""}`}>
                  <button
                    className="shareTabBtn"
                    onClick={() => {
                      setOpenLocation((prev) => !prev);
                    }}
                  ></button>
                  <LocationSearchContainer
                    locationInfo={locationInfo}
                    setLocationInfo={setLocationInfo}
                    locationInputStateMessage={locationInputStateMessage}
                    setLocationInputStateMessage={setLocationInputStateMessage}
                    openLocation={openLocation}
                    setOpenLocation={setOpenLocation}
                    feedType={feedType}
                  />
                </div>
                <div className={`shareTab ${openMedia ? "active" : ""}`}>
                  <button
                    className="shareTabBtn"
                    onClick={() => {
                      setOpenMedia((prev) => !prev);
                    }}
                  ></button>
                  <MediaContainer
                    media={media}
                    setMedia={setMedia}
                    openMedia={openMedia}
                    setShowEditor={setShowEditor}
                    setMediaUrl={setMediaUrl}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    tabState={tabState}
                  />
                </div>

                <ShareTabLayer
                  setHashTags={setHashTags}
                  targetCategory={targetCategory}
                  categories={categories}
                  setCategories={setCategories}
                  contents={contents}
                  setContents={setContents}
                  eventInfo={eventInfo}
                  editorRef={editorRef}
                />
              </>
            )}

            <div className="write-btn btmBtnContainer">
              {inputStateMessage && (
                <span className="invalid">{inputStateMessage}</span>
              )}
              <button
                className="modal-btn cancle-btn"
                onClick={(e) => {
                  e.preventDefault();
                  revokeMediaUrl();
                  const url = match.url?.slice(
                    0,
                    match.url?.lastIndexOf("/edit")
                  );
                  history.replace({
                    pathname: url ? url : "/",
                    search: location.search ? location.search : null,
                  });
                }}
              >
                취소하기
              </button>
              <button
                className="modal-btn"
                onClick={(e) => {
                  e.preventDefault();
                  if (tabState && !requiredInputCheck()) return false;
                  else if (!contents) {
                    alert("제보요청 내용을 입력해주세요");
                    return false;
                  }
                  setLocationInputStateMessage("");
                  setInputStateMessage("");
                  setShowConfirmLayer(true);
                }}
              >
                수정하기
              </button>
            </div>
          </div>

          {showConfirmLayer && (
            <ConfirmLayer
              setShowConfirmLayer={setShowConfirmLayer}
              callback={tabState ? modifyFeed : modifyShare}
              title="수정 하시겠습니까?"
              description=""
              btnText="수정하기"
              isConfirm={true}
            />
          )}
        </div>
      </div>
      {loading && <LoadingComponent />}
    </>,
    document.getElementById("modal")
  );
}
