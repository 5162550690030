import jeboApi from "api/api";
import MobileFeedListLayer from "components/layers/detail/MobileFeedListLayer";
import CategoryTabSection from "components/pages/around/CategoryTabSection";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { feedsContext } from "components/providers/FeedContextProvider";
import { currentLocationContext } from "components/providers/LocationContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import FeedContainer from "components/templates/FeedContainer";
import GridFeed from "components/templates/GridFeed";
import qs from "query-string";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import EllipsisText from "react-ellipsis-text/lib/components/EllipsisText";
import { useInView } from "react-intersection-observer";
import {
  Link,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

const debouncer = (() => {
  let debounceCheck;

  return {
    debounce(callback, milliseconds) {
      clearTimeout(debounceCheck);
      debounceCheck = setTimeout(() => {
        callback(...arguments);
      }, milliseconds);
    },
  };
})();

const popMenuReducer = (state, action) => {
  switch (action.type) {
    case "DISPLAY_MENU":
      return {
        ...state,
        id: action.id,
        name: action.name,
      };
    case "CLEAR":
      return initialPopMenuState;

    default:
      return state;
  }
};

const initialPopMenuState = {
  id: "",
  name: "",
};

export default function FeedSection({
  mapRegion,
  mapRect,
  setMarkerPositions,
  setSearchPosition,
  callFromClusterer,
  setCallFromClusterer,
  eventInfo,
}) {
  const END_LIMIT = 15;
  const kakao = window.kakao;

  const { isMobile } = useContext(isMobileContext);
  const { feeds, setFeeds } = useContext(feedsContext);
  const { handleSignOutApp } = useContext(signAppContext);
  const { currentLocation, getCurrentPosition } = useContext(
    currentLocationContext
  );

  const [searchKeyword, setSearchKeyword] = useState("");
  const [resultList, setResultList] = useState([]);
  const [showDropBox, setShowDropBox] = useState(false);
  const [showFeedListContainer, setShowFeedListContainer] = useState(true);
  const [notLoadedData, setNotLoadedData] = useState(false);

  const [current, setCurrent] = useState(false);

  const [campaign, setCampaign] = useState("");
  const [category, setCategory] = useState("");
  const [cursor, setCursor] = useState(0);
  const [lastFeedRef, inView] = useInView();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [popMenu, dispatchPopMenu] = useReducer(
    popMenuReducer,
    initialPopMenuState
  );

  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const { eventId, latitude, longitude } = qs.parse(location.search);

  const feedSectionRef = useRef();

  useEffect(() => {
    latitude && longitude ? setCampaign(true) : setCampaign(false);
  }, [latitude, longitude]);

  useEffect(() => {
    current &&
      currentLocation &&
      setSearchPosition({
        latitude: currentLocation.latitude,
        longitude: currentLocation.longitude,
      });
  }, [current, currentLocation, setSearchPosition]);

  const searchCoordsFromAddr = (address, roadAddress, place, xAxis, yAxis) => {
    const coords = new kakao.maps.LatLng(yAxis, xAxis);
    setCurrent(false);
    setSearchPosition({
      latitude: coords.getLat(),
      longitude: coords.getLng(),
    });
    setSearchKeyword(place ? `${place} (${address})` : address);
    setShowDropBox(false);
  };

  const searchAddrListFromRegionAddr = useCallback(
    (resultData) => {
      const addressSearchObj = new kakao.maps.services.Geocoder();

      addressSearchObj.addressSearch(
        searchKeyword,
        (result, status, pagination) => {
          if (status === kakao.maps.services.Status.OK) {
            const regionList =
              result && result.length > 0
                ? result.map((data) => {
                    return {
                      address_name: data.address_name ? data.address_name : "",
                      road_address_name: data.road_address
                        ? data.road_address.address_name
                        : "",
                      place_name: "",
                      x: data.x,
                      y: data.y,
                    };
                  })
                : null;
            setResultList(
              resultData && resultData.length > 0
                ? resultData.concat(regionList)
                : regionList
            );
            setShowDropBox(true);
          } else {
            console.log("status : ", status);
            if (status === kakao.maps.services.Status.ZERO_RESULT) {
              if (resultData && resultData.length > 0) {
                setResultList(resultData);
                setShowDropBox(true);
              } else {
                setShowDropBox(false);
              }
            }
          }
        },
        { size: 30 }
      );
    },
    [kakao, searchKeyword]
  );

  const searchAddrListFromKeyword = useCallback(() => {
    const placeSearchObj = new kakao.maps.services.Places();

    placeSearchObj.keywordSearch(
      searchKeyword,
      (result, status, pagination) => {
        if (status === kakao.maps.services.Status.OK) {
          //setResultList(data);
          //setShowDropBox(true);

          const placeList =
            result && result.length > 0
              ? result.map((data) => {
                  return {
                    address_name: data.address_name ? data.address_name : "",
                    road_address_name: data.road_address_name
                      ? data.road_address_name
                      : "",
                    place_name: data.place_name,
                    x: data.x,
                    y: data.y,
                  };
                })
              : null;
          searchAddrListFromRegionAddr(placeList);
        } else {
          status === kakao.maps.services.Status.ZERO_RESULT &&
            searchAddrListFromRegionAddr(null);
        }
      },
      { size: 15, page: 1 }
    );
  }, [kakao, searchKeyword, searchAddrListFromRegionAddr]);

  useEffect(() => {
    debouncer.debounce(() => {
      searchKeyword && searchAddrListFromKeyword();
    }, 500);
    !searchKeyword && setShowDropBox(false);
  }, [searchKeyword, searchAddrListFromKeyword]);

  useEffect(() => {
    setFeeds([]);
    setCursor(0);
    !callFromClusterer && setMarkerPositions([]);
  }, [mapRect, category, eventId, setMarkerPositions, setFeeds]);

  const loadFeeds = useCallback(async () => {
    if (!mapRect) return;
    try {
      setLoading(true);

      const neLat = mapRect.northEastLatitude;
      const neLng = mapRect.northEastLongitude;
      const swLat = mapRect.southWestLatitude;
      const swLng = mapRect.southWestLongitude;

      //CustormOverlay가 뭉쳐보이기 때문에 좌표 소수점 수정
      const aroundScale =
        mapRect.scale === undefined
          ? "2"
          : mapRect.scale < 3
          ? "17"
          : mapRect.scale < 5
          ? "3"
          : mapRect.scale < 7
          ? "2"
          : mapRect.scale < 9
          ? "1"
          : mapRect.scale < 11
          ? "0"
          : 0;

      const { status, data } = await jeboApi.aroundMapFeedList(
        neLat,
        neLng,
        swLat,
        swLng,
        eventId ? "" : category,
        eventId,
        0,
        END_LIMIT,
        cursor,
        aroundScale
      );

      if (status === 200) {
        const jeboPostResult = JSON.parse(data.jeboPost);
        const jeboPostArr = jeboPostResult && jeboPostResult.jeboPostList;
        const jeboPositionArr =
          jeboPostResult && jeboPostResult.jeboPositionList;

        setNotLoadedData(jeboPostArr.length < END_LIMIT ? true : false);
        if (cursor === 0) {
          setFeeds(jeboPostArr);
          !callFromClusterer && setMarkerPositions(jeboPositionArr);
        } else setFeeds((prev) => prev.concat(jeboPostArr));
        setCallFromClusterer(false);
      } else if (status === 204) {
        console.log("no data to be loaded..");
        setNotLoadedData(true);
      }
    } catch (error) {
      console.log(error.response);
      if (error.response?.status === 403) {
        alert("세션이 만료되었습니다. 다시 로그인해주세요.");
        handleSignOutApp();
      }
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [cursor, category, eventId, mapRect, setFeeds, setMarkerPositions]);

  useEffect(() => {
    loadFeeds();
  }, [loadFeeds]);

  useEffect(() => {
    if (inView && !error && !notLoadedData)
      setCursor((prev) => prev + END_LIMIT);
  }, [inView, error, notLoadedData]);

  const onUpdateFeed = (updatedFeed) => {
    setFeeds((prev) =>
      prev.map((item) => {
        if (item.jeboId === updatedFeed.jeboId) {
          return Object.assign({}, item, updatedFeed);
        }
        return item;
      })
    );
  };

  const onBlockFeed = (memberUid) => {
    setFeeds((prev) => prev.filter((item) => item.memberUid !== memberUid));
  };

  const onDeleteFeed = (feed) => {
    setFeeds((prev) => prev.filter((item) => item.jeboId !== feed.jeboId));
  };

  return (
    <>
      <Route
        path={`${match.path}/list`}
        render={(props) => (
          <MobileFeedListLayer
            title={eventInfo ? `${eventInfo.eventName} 주변 제보` : "주변 제보"}
          />
        )}
      />
      <div className={showFeedListContainer ? "right open" : "right"}>
        <div className="list-container">
          <button
            className="btn-fold pc"
            onClick={(e) => {
              setShowFeedListContainer(!showFeedListContainer);
            }}
          ></button>
          <div className="list-header">
            <div className="top pc">
              <p className="title">
                {eventInfo ? (
                  <Link to={"/"} className="logo">
                    <EllipsisText
                      text={eventInfo.eventName.replaceAll("#", "")}
                      length={12}
                    />{" "}
                    주변 제보
                  </Link>
                ) : (
                  <Link to={"/"} className="logo">
                    주변 제보
                  </Link>
                )}
              </p>

              <button
                className="current-location"
                onClick={(e) => {
                  getCurrentPosition(false);
                  setCurrent(true);
                }}
              >
                현 위치
              </button>
            </div>
            <div className="top mobile">
              <div className="map-header">
                <button
                  type="button"
                  className="page-back mobile"
                  onClick={() => {
                    isMobile && history.goBack();
                  }}
                ></button>

                {eventInfo ? (
                  <strong>
                    <EllipsisText
                      text={eventInfo.eventName.replaceAll("#", "")}
                      length={12}
                    />{" "}
                    주변 제보
                  </strong>
                ) : (
                  <strong>주변 제보</strong>
                )}

                {/* 주변 제보 */}
                <button
                  className="current-location"
                  onClick={(e) => {
                    getCurrentPosition(false);
                    setCurrent(true);
                  }}
                >
                  현 위치
                </button>
              </div>
            </div>
            <div className="search-ct">
              <strong className="map-location">{mapRegion}</strong>
              <div className="search-group">
                <input
                  type="text"
                  placeholder="위치를 검색하세요."
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  onKeyPress={(e) => {
                    e.key === "Enter" &&
                      resultList &&
                      resultList.length > 0 &&
                      searchCoordsFromAddr(
                        resultList[0].address_name,
                        resultList[0].road_address_name,
                        resultList[0].place_name,
                        resultList[0].x,
                        resultList[0].y
                      );
                  }}
                />
                <button
                  className="search-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    resultList &&
                      resultList.length > 0 &&
                      searchCoordsFromAddr(
                        resultList[0].address_name,
                        resultList[0].road_address_name,
                        resultList[0].place_name,
                        resultList[0].x,
                        resultList[0].y
                      );
                  }}
                ></button>
                {searchKeyword && (
                  <button
                    className="clear-btn"
                    onClick={() => {
                      setSearchKeyword("");
                    }}
                  ></button>
                )}
                {showDropBox && resultList && resultList.length > 0 && (
                  <div className="search-dropdown open">
                    <ul>
                      {resultList.map((location, index) => {
                        return (
                          <li key={index}>
                            <button
                              onClick={() => {
                                searchCoordsFromAddr(
                                  location.address_name,
                                  location.road_address_name,
                                  location.place_name,
                                  location.x,
                                  location.y
                                );
                              }}
                            >
                              <EllipsisText
                                text={
                                  location.place_name
                                    ? `${location.place_name} (${location.address_name})`
                                    : location.address_name
                                }
                                length={30}
                              />
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {!campaign ? (
              <CategoryTabSection
                category={category}
                setCategory={setCategory}
              />
            ) : null}
          </div>
          <section className="section-feed" ref={feedSectionRef}>
            <div className="container">
              {isMobile ? (
                <section className="section-grid mobile">
                  <div className="container">
                    <div className="grid">
                      <ul>
                        {feeds &&
                          feeds.map((feed, index, array) => (
                            <GridFeed
                              key={index}
                              feed={feed}
                              lastFeedRef={
                                array.length - 1 === index ? lastFeedRef : null
                              }
                              page="around"
                            />
                          ))}
                      </ul>
                    </div>
                  </div>
                </section>
              ) : (
                <>
                  <div className="cont-feed pc">
                    <div className="list">
                      {feeds &&
                        feeds.map((feed, index, array) => (
                          <FeedContainer
                            key={index}
                            feed={feed}
                            popMenu={popMenu}
                            dispatchPopMenu={dispatchPopMenu}
                            onUpdateFeed={onUpdateFeed}
                            onDeleteFeed={onDeleteFeed}
                            onBlockFeed={onBlockFeed}
                            page="around"
                          />
                        ))}
                      {feeds.length > 0 && <div ref={lastFeedRef}></div>}
                      {!error && loading && (
                        <div className="feed-spinner">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
