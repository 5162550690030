import jeboApi from "api/api";
import user_icon from "assets/imgs/icons/user_icon.svg";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function MobileSubscribeBox({ memberInfo, type }) {
  const { isLoggedIn, myInfo, handleSignOutApp } = useContext(signAppContext);
  const history = useHistory();

  const [followList, setFollowList] = useState(null);
  const [activeTab, setActiveTab] = useState(
    type === "FOLLOWER" ? "FOLLOWER" : "FOLLOWING"
  );
  const { setSignLayer } = useContext(signLayerContext);
  const [changeFollow, setChangeFollow] = useState(false);

  // useEffect(() => {
  //   document.getElementsByTagName("html")[0].classList.add("hidden");
  //   window.document.body.classList.add("hidden");
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("hidden");
  //     window.document.body.classList.remove("hidden");
  //   };
  // }, []);

  const regFollow = async (memberUid, response) => {
    try {
      await jeboApi.regFollow(memberUid);
      setChangeFollow(true);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  };

  const removeFollow = async (followId) => {
    try {
      await jeboApi.removeFollow(followId);
      setChangeFollow(true);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  };

  const getFollower = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.getFollower(
        memberInfo.memberUid,
        myInfo.memberUid
      );

      if (status === 200) {
        const followerListResult = JSON.parse(data.followerList);
        setFollowList(followerListResult);
        setChangeFollow(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [myInfo, memberInfo]);

  const getFollowing = useCallback(
    async (response) => {
      try {
        const { status, data } = await jeboApi.getFollowing(
          memberInfo.memberUid,
          myInfo.memberUid
        );

        if (status === 200) {
          const followingListResult = JSON.parse(data.followingList);
          setFollowList(followingListResult);
          setChangeFollow(false);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [myInfo, memberInfo]
  );

  useEffect(() => {
    activeTab === "FOLLOWER" ? getFollower() : getFollowing();
  }, [activeTab, changeFollow, getFollower, getFollowing]);

  return (
    <section className="section-profile">
      <div className="container">
        <div className="mobile-header p-header">
          <strong>{memberInfo.nickName}</strong>
          <button
            type="button"
            className="page-back mobile"
            onClick={() => {
              history.goBack();
              // myInfo.memberUid === memberInfo.memberUid
              //   ? history.replace({ pathname: "/mypage" })
              //   : history.replace({
              //       pathname: `/member/${memberInfo.nickName}`,
              //     });
            }}
          ></button>
        </div>

        {/* 구독자 목록 mobile popup */}
        <div className="subscribe-mobile-popup active">
          <div className="subscribe-mobile-popup-title-box">
            <h2
              className={activeTab === "FOLLOWER" ? "active" : ""}
              onClick={(e) => {
                setActiveTab("FOLLOWER");
              }}
            >
              구독자
            </h2>
            <h2
              className={activeTab === "FOLLOWING" ? "active" : ""}
              onClick={(e) => {
                setActiveTab("FOLLOWING");
              }}
            >
              관심 구독
            </h2>
          </div>
          {/* 구독자 tab */}
          <div
            className={
              activeTab === "FOLLOWER"
                ? "tab-subs-ct tab-subscribed active"
                : "tab-subs-ct tab-subscribed"
            }
          >
            <ul className="tab-subs-list">
              {followList &&
                followList.map((follow, index) => (
                  <li className="tab-subs-list-item" key={index}>
                    <span
                      className="tab-subs-list-item__pic"
                      onClick={(e) => {
                        e.preventDefault();

                        if (myInfo && myInfo.memberUid === follow.memberUid) {
                          history.push({ pathname: "/mypage" });
                        } else {
                          history.push({
                            pathname: `/member/${encodeURIComponent(
                              follow.nickName
                            )}`,
                          });
                        }
                      }}
                    >
                      <img
                        src={
                          follow.imageUrl
                            ? process.env.REACT_APP_RESOURCE_HOST +
                              follow.imageUrl
                            : user_icon
                        }
                        alt={follow.nickName}
                      />
                    </span>
                    <span className="tab-subs-list-item__name">
                      {follow.nickName}
                    </span>
                    {myInfo.memberUid === follow.memberUid ? (
                      ""
                    ) : follow.isFollow ? (
                      <button
                        className="btn-edit subscribing-btn"
                        onClick={(e) => {
                          removeFollow(follow.isFollow);
                        }}
                      >
                        구독 해제
                      </button>
                    ) : (
                      <button
                        className="btn-edit subscribe-btn"
                        onClick={(e) => {
                          isLoggedIn
                            ? regFollow(follow.memberUid)
                            : setSignLayer("signIn");
                        }}
                      >
                        구독 하기
                      </button>
                    )}
                  </li>
                ))}
            </ul>
          </div>

          {/* 관심 구독 tab */}
          <div
            className={
              activeTab === "FOLLOWING"
                ? "tab-subs-ct tab-subscribed active"
                : "tab-subs-ct tab-subscribed"
            }
          >
            <ul className="tab-subs-list">
              {followList &&
                followList.map((follow, index) => (
                  <li className="tab-subs-list-item" key={index}> 
                    <span
                      className="tab-subs-list-item__pic"
                      onClick={(e) => {
                        e.preventDefault();

                        if (myInfo && myInfo.memberUid === follow.memberUid) {
                          history.push({ pathname: "/mypage" });
                        } else {
                          history.push({
                            pathname: `/member/${encodeURIComponent(
                              follow.nickName
                            )}`,
                          });
                        }
                      }}
                    >
                      <img
                        src={
                          follow.imageUrl
                            ? process.env.REACT_APP_RESOURCE_HOST +
                              follow.imageUrl
                            : user_icon
                        }
                        alt={follow.nickName}
                      />
                    </span>
                    <span className="tab-subs-list-item__name">
                      {follow.nickName}
                    </span>
                    {myInfo.memberUid === follow.memberUid ? (
                      ""
                    ) : follow.isFollow ? (
                      <button
                        className="btn-edit subscribing-btn"
                        onClick={(e) => {
                          removeFollow(follow.isFollow);
                        }}
                      >
                        구독 해제
                      </button>
                    ) : (
                      <button
                        className="btn-edit subscribe-btn"
                        onClick={(e) => {
                          isLoggedIn
                            ? regFollow(follow.memberUid)
                            : setSignLayer("signIn");
                        }}
                      >
                        구독 하기
                      </button>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
        {/* 구독자 목록 mobile popup */}
      </div>
    </section>
  );
}
