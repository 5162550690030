import jeboApi from "api/api";
import AuthService from "api/AuthService";
import LocationPolicy from "components/layers/policy/LocationPolicy";
import MarketingPolicy from "components/layers/policy/MarketingPolicy";
import PersonalPolicy from "components/layers/policy/PersonalPolicy";
import TermOfUsePolicy from "components/layers/policy/TermOfUsePolicy";
import SignUpComplete from "components/layers/sign/SignUpComplete";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import LoadingComponent from "components/templates/LoadingComponent";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import ConfirmLayer from "../popMenu/ConfirmLayer";
import AgreeToLocationInfo from "./AgreeToLocationInfo";
import AgreeToMarketingInfo from "./AgreeToMarketingInfo";
import AgreeToOverAge from "./AgreeToOverAge";
import AgreeToPersonalData from "./AgreeToPersonalData";
import AgreeToTermsOfUse from "./AgreeToTermsOfUse";

import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import ToastMessageBox from "components/templates/ToastMessageBox";
import { Base64 } from "js-base64";

const debouncer = (() => {
  let debounceCheck;

  return {
    debounce(callback, milliseconds) {
      clearTimeout(debounceCheck);
      debounceCheck = setTimeout(() => {
        callback(...arguments);
      }, milliseconds);
    },
  };
})();
// export default function SignUpSnsContainer() {
const SignUpSnsContainer = forwardRef((props, ref) => {
  const { setSignLayer } = useContext(signLayerContext);
  const { handleSignInApp } = useContext(signAppContext);
  const { isMobile, mobileType } = useContext(isMobileContext);

  //닉네임 중복되는지 -> 가입 시 체크
  const [isDuplicateNickName, setIsDuplicateNickName] = useState(false);
  //닉네임 길이 체크
  const [checkNickNameLength, setCheckNickNameLength] = useState(false);
  //닉네임 특수문자 체크
  const [checkNickNameWord, setCheckNickNameWord] = useState(false);

  //인증메일 보냈는지 -> 인증번호 확인 영역 나타남.
  const [sendCertificationEmail, setSendCertificationEmail] = useState(false);
  //전달받은 인증 키
  const [resCertificationKey, setResCertificationKey] = useState("");
  const [waitStatus, setWaitStatus] = useState(false);
  //이메일 중복 체크
  //const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);
  // 이메일 유효성 체크
  // const [checkEmailValid, setCheckEmailValid] = useState(true);
  //이메일이 인증되었는지 -> 가입 시 체크
  const [isCertificatedEmail, setIsCertificatedEmail] = useState(false);
  //패스워드 유효성 체크
  const [checkPasswordValid, setCheckPasswordValid] = useState(false);
  //패스워드와 패스워드 확인이 일치하는지 -> 가입 시 체크
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);

  const [nickName, setNickName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [nickNameInputStateMessage, setNickNameInputStateMessage] =
    useState("");
  const [passwordInputStateMessage, setPasswordInputStateMessage] =
    useState("");
  const [emailInputStateMessage, setEmailInputStateMessage] = useState("");
  const [
    confirmPasswordInputStateMessage,
    setConfirmPasswordInputStateMessage,
  ] = useState("");

  const [requiredAgreeMessage, setRequiredAgreeMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [showConfirmLayer, setShowConfirmLayer] = useState(false);
  //const [error, setError] = useState("");

  const [checkOverAge, setCheckOverAge] = useState(false);
  const [checkTermsOfUse, setCheckTermsOfUse] = useState(false);
  const [checkPersonalData, setCheckPersonalData] = useState(false);
  const [checkLocationInfo, setCheckLocationInfo] = useState(false);
  const [checkMarketingInfo, setCheckMarketingInfo] = useState(false);

  const [termsOfUse, setTermsOfUse] = useState(false);
  const [personalData, setPersonalData] = useState(false);
  const [locationInfo, setLocationInfo] = useState(false);
  const [marketingInfo, setMarketingInfo] = useState(false);

  const [allCheck, setAllCheck] = useState(false);

  const [openComplete, setOpenComplete] = useState(false);

  const [toastMessage, setToastMessage] = useState("");

  const cssStyle = isMobile ? { bottom: "32rem" } : null;

  const emailRegExp = useMemo(
    () =>
      /^(([^<>()\\[\].,;:\s@"]+(\.[^<>()\\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    []
  );

  // useEffect(() => {
  //   document.getElementsByTagName("html")[0].classList.add("hidden");
  //   window.document.body.classList.add("hidden");
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("hidden");
  //     window.document.body.classList.remove("hidden");
  //   };
  // }, []);

  useEffect(() => {
    setCheckOverAge(allCheck);
    setCheckTermsOfUse(allCheck);
    setCheckPersonalData(allCheck);
    setCheckLocationInfo(allCheck);
    setCheckMarketingInfo(allCheck);
  }, [allCheck]);

  useEffect(() => {
    setSendCertificationEmail(false);

    //let emailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if (email && !emailRegExp.test(email)) {
      setEmailInputStateMessage("이메일 형식이 잘못되었습니다.");
    } else {
      setEmailInputStateMessage("");
    }
  }, [email, emailRegExp]);

  useEffect(() => {
    if (confirmPassword && password) {
      if (password === confirmPassword) {
        setConfirmPasswordInputStateMessage("");
        setIsConfirmPassword(true);
      } else {
        setConfirmPasswordInputStateMessage("비밀번호가 일치하지 않습니다.");
        setIsConfirmPassword(false);
      }
    }
  }, [confirmPassword, password]);

  // useEffect(() => {
  //   setNickName((prev) => prev.replace(/[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]/g, ""));
  // }, [nickName]);

  useEffect(() => {
    if (
      checkOverAge &&
      checkTermsOfUse &&
      checkPersonalData &&
      checkLocationInfo
    ) {
      setRequiredAgreeMessage("");
    }
  }, [checkLocationInfo, checkOverAge, checkPersonalData, checkTermsOfUse]);

  const handleValidNickName = useCallback(async () => {
    var nickNameRegExp = /[`$~@#%^&|\\=?;:",<>\\{\\}\\[\]\\\\/ ]/gim;

    if (nickName && nickName.length > 10) {
      setNickNameInputStateMessage(
        "한글, 영문, 숫자를 포함하여 10자 이내로 작성하세요."
      ); //특수문자와 공백을 제외하여 10자 이내로 작성하세요.
      setCheckNickNameLength(false);
      return;
    } else if (nickName && nickNameRegExp.test(nickName)) {
      setNickNameInputStateMessage(
        "일부 특수문자 또는 공백은 사용하실 수 없습니다."
      );
      setCheckNickNameWord(false);
      return;
    } else {
      setNickNameInputStateMessage("");
      setCheckNickNameLength(true);
      setCheckNickNameWord(true);
    }

    try {
      const response = await jeboApi.checkDuplicationNickName(nickName);
      if (response.status === 200) {
        setNickNameInputStateMessage("");
        setIsDuplicateNickName(false);
      }
    } catch (error) {
      if (error.response) {
        const {
          response: { data, status },
        } = error;
        if (status === 409) {
          setNickNameInputStateMessage("이미 사용 중인 닉네임입니다.");
          setIsDuplicateNickName(true);
        } else {
          setNickNameInputStateMessage(
            "시스템 오류 발생. 관리자에게 문의해주세요."
          );
          console.log(data.errorDescription);
        }
      }
    }
  }, [nickName]);

  useEffect(() => {
    // nickName && debouncer.debounce(handleValidNickName, 200);
    debouncer.debounce(handleValidNickName, 200);
  }, [nickName, handleValidNickName]);

  const handleValidPassword = useCallback(
    (e) => {
      if (password.length < 6 || password.length > 20) {
        setPasswordInputStateMessage(
          "영문, 숫자, 특수 문자를 조합하여 6 ~ 20자 이내로 작성하세요."
        );
        setCheckPasswordValid(false);
      } else if (
        !/[a-zA-Z]/g.test(password) ||
        !/[0-9]/g.test(password) ||
        !/[\\{\\}\\[\]\\/?.,;:|\\)*~`!^\-_+<>@\\#$%&\\=\\(\\'\\"]/g.test(
          password
        ) ||
        /[\s]/g.test(password)
      ) {
        setPasswordInputStateMessage(
          "영문, 숫자, 특수 문자를 조합하여 6 ~ 20자 이내로 작성하세요."
        );
        setCheckPasswordValid(false);
      } else {
        setPasswordInputStateMessage("");
        setCheckPasswordValid(true);
      }
    },
    [password]
  );

  useEffect(() => {
    password && handleValidPassword();
    !password && setPasswordInputStateMessage("");
  }, [password, handleValidPassword]);

  const checkEmailCertificationListener = () => {
    if (!email) {
      setEmailInputStateMessage("이메일을 입력하세요.");
      return;
    } else if (!emailRegExp.test(email)) {
      setEmailInputStateMessage("이메일 형식이 잘못되었습니다.");
      return;
    } else if (!sendCertificationEmail) {
      setEmailInputStateMessage("인증 메일을 전송하여 인증하세요.");
    }

    const response = jeboApi.checkCertificationKey(resCertificationKey, email);

    response
      .then(({ data, status, data: { certificationYn } }) => {
        if (certificationYn === "Y") {
          setEmailInputStateMessage("");
          setIsCertificatedEmail(true);
          setSendCertificationEmail(false);
          if (!nickName) {
            setNickNameInputStateMessage("닉네임을 입력하세요.");
            return;
          } else if (nickNameInputStateMessage) {
            return;
          }

          if (!password) {
            setPasswordInputStateMessage("비밀번호를 입력하세요.");
            return;
          }

          if (!confirmPassword) {
            setConfirmPasswordInputStateMessage(
              "동일한 비밀번호를 입력하세요."
            );
            return;
          }

          if (isDuplicateNickName || !checkNickNameLength) return;
          if (!checkPasswordValid) return;
          if (!isConfirmPassword) return;

          if (
            !checkOverAge ||
            !checkTermsOfUse ||
            !checkPersonalData ||
            !checkLocationInfo
          ) {
            setRequiredAgreeMessage("필수 동의 항목을 체크해주세요.");
            return;
          }

          setShowConfirmLayer(true);
        } else {
          setEmailInputStateMessage("전송된 메일에서 인증을 완료 해주세요.");
          return;
        }
      })
      .catch((error) => {
        !waitStatus && setSendCertificationEmail(false);
        //setError(error);
        console.log(error);
        // if (error.response) {
        //   const {
        //     response: { data, status },
        //   } = error;
        // }
      });
  };
  //인증메일보내기
  const handleSendCertificationEmail = (e) => {
    setWaitStatus(true);
    if (isCertificatedEmail) return;

    if (!email) {
      setEmailInputStateMessage("이메일을 입력하세요.");
      return;
    } else if (!emailRegExp.test(email)) {
      setEmailInputStateMessage("이메일 형식이 잘못되었습니다.");
      return;
    } else {
      setEmailInputStateMessage("");
    }

    const response = jeboApi.sendCertificationEmail(email);
    setToastMessage("전송된 메일에서 인증을 완료 해주세요.");
    setSendCertificationEmail(true);

    response
      .then(({ data: { certificationKey }, status }) => {
        if (status === 200) {
          setResCertificationKey(certificationKey);
          setEmailInputStateMessage("");
        }
      })
      .catch((error) => {
        setSendCertificationEmail(false);

        if (error.response) {
          const {
            response: { data, status },
          } = error;
          if (status === 409) {
            //setEmailInputStateMessage("이미 가입한 이메일 주소 입니다.");
            setEmailInputStateMessage(data.errorDescription);
            //setIsDuplicateEmail(true);
          } else {
            console.log(data.errorDescription);
            setEmailInputStateMessage(
              "이메일 발송 중 오류 발생. 관리자에게 문의 해주세요."
            );
          }
        }
      })
      .finally(() => {
        setWaitStatus(false);
      });
  };

  const signUp = async () => {
    try {
      const agreeItems = {
        overAge: checkOverAge,
        termsOfUse: checkTermsOfUse,
        personalData: checkPersonalData,
        locationInfo: checkLocationInfo,
        marketingInfo: checkMarketingInfo,
      };

      const response = await jeboApi.signUp(
        email,
        nickName,
        password,
        agreeItems
      );

      setLoading(true);
      if (response.status !== 200)
        alert("알 수 없는 에러 발생 다시 시도해 주세요.");
      else {
        //회원가입 중 에러가 발생하지 않았다면, 로그인 로직 시작
        if (mobileType === "ios") {
          let params = JSON.stringify({
            loginType: "email",
          });
          window.webkit.messageHandlers.login.postMessage(
            Base64.encode(params)
          );
        } else if (mobileType === "android") {
          signInAppAndroid();
        } else {
          ref.current?.signIn();
        }
      }
    } catch (error) {
      if (error.response) {
        const {
          response: { data, status },
        } = error;
        if (status === 409) alert(data.errorDescription);
        else if (status === 401) alert(data.errorDescription);
        else if (status === 500) alert(data.errorDescription);
        else console.log("알 수 없는 에러 발생. 관리자에게 문의 해주세요.");
      }
    } finally {
      setLoading(false);
    }
  };

  const signInAppAndroid = () => {
    let returnString = window.androidApp.getDeviceTokenInfo();

    let deviceId = returnString.split("@@")[0];
    let fcmToken = returnString.split("@@")[1];

    ref.current?.signIn(deviceId, fcmToken);
  };

  useImperativeHandle(ref, () => ({
    signIn: async (deviceId, fcmToken) => {
      try {
        const { headers } = await jeboApi.signIn(
          email,
          password,
          deviceId,
          fcmToken,
          mobileType
        );

        if (mobileType === "ios" || mobileType === "android") {
          headers.refresh_token &&
            AuthService.setAutoLoginToken(headers.refresh_token);
          headers.member_email && AuthService.setKeepId(headers.member_email);
        } else {
          AuthService.removeAutoLoginToken();
          AuthService.removeKeepId();
        }

        handleSignInApp(
          headers.authorization,
          headers.refresh_token,
          headers.member_email,
          Base64.decode(headers.member_nickname),
          headers.grant_type,
          headers.member_uid,
          headers.social_yn,
          "email"
        );

        setOpenComplete(true);
      } catch (error) {
        if (error.response) {
          const {
            response: { data, status },
          } = error;
          if (status === 401) {
            data.errorMessage === "NotFoundMember"
              ? alert(data.errorDescription)
              : alert(data.errorDescription);
          } else alert("알 수 없는 에러 발생. 관리자에게 문의 해주세요.");
        }
      }
    },
  }));

  return (
    <>
      {toastMessage && (
        <ToastMessageBox
          toastMessage={toastMessage}
          setToastMessage={setToastMessage}
          cssStyle={cssStyle}
        />
      )}
      {showConfirmLayer && (
        <ConfirmLayer
          setShowConfirmLayer={setShowConfirmLayer}
          callback={signUp}
          title="회원가입을 하시겠습니까?"
          description=""
          btnText="가입하기"
          isConfirm={true}
        />
      )}
      {openComplete && (
        <SignUpComplete
          setSignLayer={setSignLayer}
          setOpenComplete={setOpenComplete}
          nickname={nickName}
        />
      )}
      {(termsOfUse || personalData || locationInfo || marketingInfo) && (
        <>
          <div className="modal-wrap agree open">
            <div className="modal modal-agree email open">
              <div className="modal-header">
                {isMobile && (
                  <button
                    type="button"
                    className="page-back mobile"
                    onClick={() => {
                      setTermsOfUse(false);
                      setPersonalData(false);
                      setLocationInfo(false);
                      setMarketingInfo(false);
                    }}
                  ></button>
                )}
                <strong>이용약관</strong>

                {!isMobile && (
                  <button
                    className="modal-close pc"
                    onClick={(e) => {
                      setTermsOfUse(false);
                      setPersonalData(false);
                      setLocationInfo(false);
                      setMarketingInfo(false);
                    }}
                  >
                    <i className="icon-cross"></i>
                  </button>
                )}
              </div>
              <div className="serviceArgreeContainer full-content">
                <div className="policy-container sign email-sign">
                  {termsOfUse && (
                    <TermOfUsePolicy
                      setTermsOfUse={setTermsOfUse}
                      callFrom={"signUp"}
                    />
                  )}
                  {personalData && (
                    <PersonalPolicy
                      setPersonalData={setPersonalData}
                      callFrom={"signUp"}
                    />
                  )}
                  {locationInfo && (
                    <LocationPolicy
                      setLocationInfo={setLocationInfo}
                      callFrom={"signUp"}
                    />
                  )}
                  {marketingInfo && (
                    <MarketingPolicy
                      setMarketingInfo={setMarketingInfo}
                      callFrom={"signUp"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!termsOfUse && !personalData && !locationInfo && !marketingInfo && (
        <div className="signup-email open">
          <div className="input-group">
            <input
              type="text"
              name="email"
              className="input-control"
              placeholder="ex) jebo@naver.com"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              disabled={isCertificatedEmail ? true : false}
            />
            <button
              type="button"
              className="input-group-btn"
              onClick={handleSendCertificationEmail}
            >
              {sendCertificationEmail ? "전송완료" : "인증메일 전송"}
            </button>
            {emailInputStateMessage && (
              <span className="invalid">{emailInputStateMessage}</span>
            )}
          </div>
          <div className="input-group">
            <input
              type="text"
              name="nickName"
              className="input-control"
              placeholder="닉네임(한글, 영문, 숫자 포함 10자 이내 / 일부 특수 문자 불가)"
              onChange={(e) => {
                setNickName(e.target.value);
              }}
            />
            <button type="button" className="input-group-btn">
              {nickName &&
                !isDuplicateNickName &&
                checkNickNameLength &&
                !isDuplicateNickName &&
                checkNickNameWord &&
                "✔"}
            </button>
            {nickNameInputStateMessage && (
              <span className="invalid">{nickNameInputStateMessage}</span>
            )}
          </div>
          <div className="input-group">
            <input
              type="password"
              name="password"
              className="input-control"
              placeholder="비밀번호(영문, 숫자, 특수문자 조합 6~20자 이내)"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {passwordInputStateMessage && (
              <span className="invalid">{passwordInputStateMessage}</span>
            )}
          </div>
          <div className="input-group">
            <input
              type="password"
              name="passwordConfirm"
              className="input-control"
              placeholder="비밀번호 확인"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            {confirmPasswordInputStateMessage && (
              <span className="invalid">
                {confirmPasswordInputStateMessage}
              </span>
            )}
          </div>
          <div className="input-group">
            <span className="modal-txt">
              {/* 영문, 숫자, 특수 문자를 조합하여 6 ~ 20자 이내로 작성하세요. */}
            </span>
          </div>
          <div className="serviceArgreeContainer">
            <div className="serviceArgreeAll">
              <label htmlFor="serviceAllChk">모든 약관 동의</label>
              <input
                onChange={(e) => setAllCheck((current) => !current)}
                id="serviceAllChk"
                type="checkbox"
                checked={allCheck}
              ></input>
            </div>
            <div className="serviceAgreeContainer__wapper">
              <AgreeToOverAge
                checkOverAge={checkOverAge}
                setCheckOverAge={setCheckOverAge}
              />
              <AgreeToTermsOfUse
                checkTermsOfUse={checkTermsOfUse}
                setCheckTermsOfUse={setCheckTermsOfUse}
                setTermsOfUse={setTermsOfUse}
              />
              <AgreeToPersonalData
                checkPersonalData={checkPersonalData}
                setCheckPersonalData={setCheckPersonalData}
                setPersonalData={setPersonalData}
              />
              <AgreeToLocationInfo
                checkLocationInfo={checkLocationInfo}
                setCheckLocationInfo={setCheckLocationInfo}
                setLocationInfo={setLocationInfo}
              />
              <AgreeToMarketingInfo
                checkMarketingInfo={checkMarketingInfo}
                setCheckMarketingInfo={setCheckMarketingInfo}
                setMarketingInfo={setMarketingInfo}
              />
            </div>
          </div>
          <div className="input-group">
            {requiredAgreeMessage && (
              <span className="invalid">{requiredAgreeMessage}</span>
            )}
            <button
              className="modal-btn"
              onClick={(e) => {
                checkEmailCertificationListener();
              }}
            >
              회원가입
            </button>
          </div>
          <div className="input-group center">
            <span className="modal-txt-s">
              회원가입 버튼을 클릭하면 제보의{" "}
              <a
                href="#!"
                className="link"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                서비스 약관
              </a>{" "}
              및{" "}
              <a
                href="#!"
                className="link"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                개인정보 수집 및 이용 동의
              </a>
              에 동의하게 됩니다.
            </span>
          </div>
          <div className="input-group center">
            <span className="join-txt">
              이미 계정이 있으신가요?
              <button
                type="button"
                className="join underline"
                onClick={(e) => {
                  e.preventDefault();
                  setSignLayer("signIn");
                }}
              >
                로그인
              </button>
            </span>
          </div>
        </div>
      )}
      {loading && (
        <div className="modal-wrap open">
          <LoadingComponent />
        </div>
      )}
    </>
  );
});

export default SignUpSnsContainer;
