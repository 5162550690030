import React, { createContext } from "react";

export const convenienceDataContext = createContext({
  convenienceData: {},
  setConvenienceData: () => {},
  hashKeywords: {},
});

export default function ConvenienceDataContextProvider({
  convenienceData,
  children: Components,
}) {
  return (
    <convenienceDataContext.Provider value={convenienceData}>
      {Components}
    </convenienceDataContext.Provider>
  );
}
