import React, { createContext } from "react";

export const activeLocationContext = createContext({
  activeLocation: null,
  setActiveLocation: () => {},
});

export const currentLocationContext = createContext({
  currentLocation: null,
  getCurrentPosition: () => {},
});

export default function LocationContextProvider({
  activeLocation,
  currentLocation,
  children: Components,
}) {
  return (
    <currentLocationContext.Provider value={currentLocation}>
      <activeLocationContext.Provider value={activeLocation}>
        {Components}
      </activeLocationContext.Provider>
    </currentLocationContext.Provider>
  );
}
