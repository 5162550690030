import React, { createContext } from "react";

export const subscribeMessageContext = createContext({
  subscribeMessage: {},
  setSubscribeMessage: () => {},
  subscribeNotification: {},
  setSubscribeNotification: () => {},
  isNewMessage: false,
  isConnectedSocket: false,
  setIsConnectedSocket: () => {},
  stompClient: null,
});

export default function SubscribeMessageContextProvider({
  subscribeMessage,
  children: Components,
}) {
  return (
    <subscribeMessageContext.Provider value={subscribeMessage}>
      {Components}
    </subscribeMessageContext.Provider>
  );
}
