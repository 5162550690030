import jeboApi from "api/api";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import "assets/styles/mapInfoStyle.css";
import redMarkerImg from "../../../assets/imgs/icons/redmarker.svg";
import styled, { css } from "styled-components";
import { signAppContext } from "components/providers/SignContextProvider";
import FeedContainer from "components/templates/FeedContainer";
import { currentLocationContext } from "components/providers/LocationContextProvider";
import { useHistory } from "react-router-dom";
import { convenienceDataContext } from "components/providers/ConvenienceDataContextProvider";

import redMarker from "../../../assets/imgs/icons/icon_mapMarker_red.svg";
import blueMarker from "../../../assets/imgs/icons/icon_mapMarker_blue.svg";
// import gpsMarker from "../../../assets/imgs/icons/iconGps.gray.svg";
import gpsMarker from "../../../assets/imgs/icons/iconGps.blue.svg";
import moreBtn from "../../../assets/imgs/icons/more.svg";
import closeBtn from "../../../assets/imgs/icons/exit.svg";

import SearchNavButton from "./SearchNavButton";
import SearchNavEtcButton from "./SearchNavEtcButton";

// border가 있는 아이콘
import markerPet from "../../../assets/imgs/icons/mapMarker_pet.svg";
import markerMedi from "../../../assets/imgs/icons/mapMarker_medi.svg";
import markerCloth from "../../../assets/imgs/icons/mapMarker_cloth.svg";
import markerRedPet from "../../../assets/imgs/icons/mapRedMarker_pet.svg";
import markerRedMedi from "../../../assets/imgs/icons/mapRedMarker_medi.svg";
import markerRedCloth from "../../../assets/imgs/icons/mapRedMarker_cloth.svg";

// border가 없는 아이콘
import markerPet_Bn from "../../../assets/imgs/icons/marker_pet_blue.svg";
import markerPet_RBn from "../../../assets/imgs/icons/marker_pet_red.svg";
import markerMedi_Bn from "../../../assets/imgs/icons/marker_medi_blue.svg";
import markerMedi_RBn from "../../../assets/imgs/icons/marker_medi_red.svg";
import markerCloth_Bn from "../../../assets/imgs/icons/marker_cloth_blue.svg";
import markerCloth_RBn from "../../../assets/imgs/icons/marker_cloth_red.svg";
import { useInView } from "react-intersection-observer";

const debouncer = (() => {
  let debounceCheck;

  return {
    debounce(callback, milliseconds) {
      clearTimeout(debounceCheck);
      debounceCheck = setTimeout(() => {
        callback(...arguments);
      }, milliseconds);
    },
  };
})();

const popMenuReducer = (state, action) => {
  switch (action.type) {
    case "DISPLAY_MENU":
      return {
        ...state,
        id: action.id,
        name: action.name,
      };
    case "CLEAR":
      return initialPopMenuState;

    default:
      return state;
  }
};

const initialPopMenuState = {
  id: "",
  name: "",
};

const kakao = window.kakao;
const roundControll = 0.0000000000001;
const END_LIMIT = 15;
export default function ConDataSection({
  searchPosition,
  keyword,
  conMapData,
  loadingAdsMapData,
  isAd,
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { convenienceData } = useContext(convenienceDataContext);
  const { handleSignOutApp } = useContext(signAppContext);
  const { currentLocation, getCurrentPosition } = useContext(
    currentLocationContext
  );
  //MAP
  const kakaoMap = useRef();
  const kakaoMapContainer = useRef();
  const infowindow = useRef();
  const [mapPositionRect, setMapPositionRect] = useState(null);
  const [mapRegion, setMapRegion] = useState("");

  //jebo
  const [jeboMarkerPositions, setJeboMarkerPositions] = useState([]);
  const [jeboMarkers, setJeboMarkers] = useState([]);
  //public
  const [pubMarkerPositions, setPubMarkerPositions] = useState([]);
  const [pubMarkers, setPubMarkers] = useState([]);

  //FEED
  const [mapFeedRect, setMapFeedRect] = useState(null);
  const [cursor, setCursor] = useState(0);
  const [notLoadedData, setNotLoadedData] = useState(false);
  const [feeds, setFeeds] = useState([]);
  const [lastFeedRef, inView] = useInView();

  //편의지도 수정
  const [showMoreItemBox, setShowMoreItemBox] = useState(false);

  useEffect(() => {
    const openExternalLink = (url) => {
      window.open(url);
    };
    window.openExternalLink = openExternalLink;
  }, []);

  const [popMenu, dispatchPopMenu] = useReducer(
    popMenuReducer,
    initialPopMenuState
  );

  const fnSettingMapPositionRect = () => {
    setMapPositionRect({
      northEastLatitude: kakaoMap.current.getBounds().getNorthEast().getLat(),
      northEastLongitude: kakaoMap.current.getBounds().getNorthEast().getLng(),
      southWestLatitude: kakaoMap.current.getBounds().getSouthWest().getLat(),
      southWestLongitude: kakaoMap.current.getBounds().getSouthWest().getLng(),
      scale: kakaoMap.current.getLevel(),
    });
  };

  useEffect(() => {
    if (!kakaoMap.current) return;
    kakaoMap.current.setMaxLevel(conMapData.level ? conMapData.level : 7);
  }, [conMapData]);

  useEffect(() => {
    const { latitude, longitude } = searchPosition;

    kakaoMap.current = new kakao.maps.Map(kakaoMapContainer.current, {
      center: new kakao.maps.LatLng(latitude, longitude),
      level: 6,
    });

    //zoom컨트롤 추가
    const zoomControl = new kakao.maps.ZoomControl();
    kakaoMap.current.addControl(
      zoomControl,
      kakao.maps.ControlPosition.BOTTOMRIGHT
    );

    //맵 이동 이벤트
    kakao.maps.event.addListener(
      kakaoMap.current,
      "center_changed",
      function () {
        debouncer.debounce(() => {
          fnSettingMapPositionRect();
        }, 300);
      }
    );

    //줌 이벤트
    kakao.maps.event.addListener(kakaoMap.current, "zoom_changed", function () {
      debouncer.debounce(() => {
        fnSettingMapPositionRect();
      }, 300);
    });

    //infowindw
    infowindow.current = new kakao.maps.InfoWindow({ zIndex: 1 });

    //최초 1번은 rect를 세팅해준다. 로딩 시 바로 값을 가져오도록.
    fnSettingMapPositionRect();
  }, []);

  // 센터 주소 처리.
  useEffect(() => {
    /*
      광고(isAd)인 경우 현 위치 설정 안해줌
    */
    if (!currentLocation || isAd) return;

    kakaoMap.current.setLevel(6); // 시작 레벨.
    kakaoMap.current.setCenter(
      new kakao.maps.LatLng(currentLocation.latitude, currentLocation.longitude)
    );
  }, [currentLocation]);

  //loadingAdsMapData가 false인 경우 searchPosition에 저장된 위/경도로 카카오맵 세팅
  useEffect(() => {
    if (!isAd || loadingAdsMapData) return;

    if (isAd) {
      kakaoMap.current.setLevel(6); // 시작 레벨.
      kakaoMap.current.setCenter(
        new kakao.maps.LatLng(searchPosition.latitude, searchPosition.longitude)
      );
    }
  }, [loadingAdsMapData, isAd]);

  useEffect(() => {
    if (!mapPositionRect) return;
    const latlng = kakaoMap.current.getCenter();
    const geocoder = new kakao.maps.services.Geocoder();
    geocoder.coord2RegionCode(
      latlng.getLng(),
      latlng.getLat(),
      (result, status) => {
        if (status === kakao.maps.services.Status.OK) {
          setMapRegion(
            result[0].region_4depth_name
              ? `${result[0].region_3depth_name} ${result[0].region_4depth_name}`
              : `${result[1].region_2depth_name} ${result[1].region_3depth_name}`
          );
        }
      }
    );
  }, [mapPositionRect]);

  //제보마커포지션
  useEffect(() => {
    if (!mapPositionRect) return;
    const neLat = mapPositionRect.northEastLatitude;
    const neLng = mapPositionRect.northEastLongitude;
    const swLat = mapPositionRect.southWestLatitude;
    const swLng = mapPositionRect.southWestLongitude;
    async function loadJeboPositionList() {
      try {
        const { status, data } = await jeboApi.jeboMapPositionList(
          neLat,
          neLng,
          swLat,
          swLng,
          keyword
        );

        if (status === 200) {
          const result = JSON.parse(data.jeboMapPositionList);
          setJeboMarkerPositions(result);
        } else {
          console.log("no data loadJeboMapPositionLst..");
          setJeboMarkerPositions([]);
        }
      } catch (error) {
        setJeboMarkerPositions([]);
        console.log(error.response);
        if (error.response?.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
        }
      } finally {
      }
    }
    async function loadPublicMapPositionList() {
      try {
        const { status, data } = await jeboApi.getJeboPublicMapPositionList(
          neLat,
          neLng,
          swLat,
          swLng,
          keyword
        );
        if (status === 200) {
          const resultData = JSON.parse(data.jeboPublicPostionListData);
          setPubMarkerPositions(
            resultData && resultData.length > 0 ? resultData : []
          );
        } else {
          console.log("getJeboPublicMapPositionList");
          setPubMarkerPositions([]);
        }
      } catch (error) {
        setPubMarkerPositions([]);
        console.log(error.response);
        if (error.response?.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
        }
        console.log(error);
      } finally {
      }
    }
    loadJeboPositionList();
    loadPublicMapPositionList();
  }, [handleSignOutApp, keyword, mapPositionRect]);

  // keyword가 변경될 때마다 정보 제공 마커 변경하기
  let iconInfoImgJebo = redMarker;
  let iconInfoImgPublic = blueMarker;
  if (keyword === "#주말약국") {
    iconInfoImgJebo = markerMedi_RBn;
    iconInfoImgPublic = markerMedi_Bn;
  } else if (keyword === "#의류수거함") {
    iconInfoImgJebo = markerCloth_RBn;
    iconInfoImgPublic = markerCloth_Bn;
  } else if (keyword === "#반려동물") {
    iconInfoImgJebo = markerPet_RBn;
    iconInfoImgPublic = markerPet_Bn;
  }

  //제보 마커 세팅
  useEffect(() => {
    let imageSrc;
    let imageSize = new kakao.maps.Size(30, 30);
    let imageOption = { offset: new kakao.maps.Point(15, 45) };

    if (keyword === "#주말약국") {
      imageSrc = markerRedMedi;
    } else if (keyword === "#의류수거함") {
      imageSrc = markerRedCloth;
    } else if (keyword === "#반려동물") {
      imageSrc = markerRedPet;
    } else {
      imageSrc = redMarkerImg;
      imageSize = new kakao.maps.Size(45, 45);
      imageOption = { offset: new kakao.maps.Point(27, 69) };
    }

    const jeboMarkerImage = new kakao.maps.MarkerImage(
      imageSrc,
      imageSize,
      imageOption
    );

    jeboMarkers.forEach((marker) => {
      marker.setMap(null);
    });
    setJeboMarkers(
      jeboMarkerPositions.map((feed, index) => {
        const marker = new kakao.maps.Marker({
          map: kakaoMap.current,
          position: new kakao.maps.LatLng(feed.latitude, feed.longitude),
          image: jeboMarkerImage,
        });
        kakao.maps.event.addListener(marker, "click", function () {
          const mapRect = {
            northEastLatitude: marker.getPosition().getLat() + roundControll,
            northEastLongitude: marker.getPosition().getLng() + roundControll,
            southWestLatitude: marker.getPosition().getLat() - roundControll,
            southWestLongitude: marker.getPosition().getLng() - roundControll,
          };
          setMapFeedRect(mapRect);
        });
        return marker;
      })
    );
    if (jeboMarkerPositions && jeboMarkerPositions.length > 0) {
      setMapFeedRect(mapPositionRect);
    } else {
      setFeeds([]);
    }
  }, [jeboMarkerPositions]);

  //공공정보 마커 세팅
  useEffect(() => {
    infowindow && infowindow.current.close();
    pubMarkers.forEach((marker) => {
      marker.setMap(null);
    });

    let imageSrc;
    let imageSize = new kakao.maps.Size(30, 30);
    let imageOption = { offset: new kakao.maps.Point(15, 45) };

    if (keyword === "#주말약국") {
      imageSrc = markerMedi;
    } else if (keyword === "#의류수거함") {
      imageSrc = markerCloth;
    } else if (keyword === "#반려동물") {
      imageSrc = markerPet;
    } else {
      imageSrc = blueMarker;
      imageSize = new kakao.maps.Size(45, 45);
    }
    const publicMarkerImage = new kakao.maps.MarkerImage(
      imageSrc,
      imageSize,
      imageOption
    );

    setPubMarkers(
      pubMarkerPositions.map((info, index) => {
        var marker = new kakao.maps.Marker({
          map: kakaoMap.current,
          position: new kakao.maps.LatLng(info.lat, info.lng),
          image: publicMarkerImage,
        });
        marker.id = info.mapId;

        kakao.maps.event.addListener(marker, "click", async function () {
          const mapId = this.id;
          var contents;
          try {
            const { status, data } = await jeboApi.getJeboPublicMapInfoData(
              mapId
            );
            if (status === 200) {
              const infoResult = JSON.parse(data.infoData);
              if (!String(infoResult.content).includes("</div>")) {
                contents =
                  `<div class="info-search-map"><p>` +
                  infoResult.content +
                  `</p></div>`;
              } else {
                contents =
                  `<div class="info-search-map">` +
                  infoResult.content +
                  `</div>`;
              }
            } else {
              contents =
                `<div class="info-search-map"><p>` +
                data.reulstMessage +
                `</p></div>`;
            }
          } catch (error) {
            contents = `<div class="info-search-map">일시적인 오류입니다.</div>`;
          } finally {
          }
          infowindow.current.setContent(contents);
          infowindow.current.open(kakaoMap.current, marker);
        });
        return marker;
      })
    );
  }, [pubMarkerPositions]);

  //포지션 가져왔다면 실제 지도에 마커를 그리는 것과 동시에 피드를 호출하러 API서버에 요청
  useEffect(() => {
    async function loadComMapPostList() {
      if (!mapFeedRect) return;
      setLoading(true);
      try {
        const neLat = mapFeedRect.northEastLatitude;
        const neLng = mapFeedRect.northEastLongitude;
        const swLat = mapFeedRect.southWestLatitude;
        const swLng = mapFeedRect.southWestLongitude;
        const aroundScale = kakaoMap.current.getLevel();

        const { status, data } = await jeboApi.jeboMapPostList(
          neLat,
          neLng,
          swLat,
          swLng,
          keyword,
          0,
          END_LIMIT,
          cursor
        );
        if (status === 200) {
          const jeboPostArr = JSON.parse(data.jeboMapPostList);
          setNotLoadedData(jeboPostArr.length < END_LIMIT ? true : false);
          if (cursor === 0) {
            setFeeds(jeboPostArr);
          } else {
            setFeeds((prev) => prev.concat(jeboPostArr));
          }
        } else if (status === 204) {
          console.log("no data to be loaded..!");
          setNotLoadedData(true);
        }
      } catch (error) {
        console.log(error.response);
        if (error.response?.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
        }
      } finally {
        setLoading(false);
      }
    }
    loadComMapPostList();
  }, [cursor, mapFeedRect, keyword, handleSignOutApp, conMapData]);

  useEffect(() => {
    if (inView && !notLoadedData) setCursor((prev) => prev + END_LIMIT);
  }, [inView, notLoadedData]);

  const onUpdateFeed = (updatedFeed) => {
    setFeeds((prev) =>
      prev.map((item) => {
        if (item.jeboId === updatedFeed.jeboId) {
          return Object.assign({}, item, updatedFeed);
        }
        return item;
      })
    );
  };

  const onBlockFeed = (memberUid) => {
    setFeeds((prev) => prev.filter((item) => item.memberUid !== memberUid));
  };

  const onDeleteFeed = (feed) => {
    setFeeds((prev) => prev.filter((item) => item.jeboId !== feed.jeboId));
  };

  return (
    <>
      <SectionMap>
        <MapContainer ref={kakaoMapContainer}></MapContainer>

        <MapInfoCt>
          <MapInfoHeader>
            <MapList>
              <MapLocation
                gpsMarker={gpsMarker}
                className="curLocation"
                onClick={(e) => {
                  getCurrentPosition(false);
                }}
              >
                현위치
              </MapLocation>
              <MapListContent>
                {conMapData &&
                  convenienceData &&
                  convenienceData.map((item, index) => {
                    if (index <= 2)
                      // 대표 아이템은 4개를 초과하지 않도록 처리.
                      return (
                        <SearchNavButton
                          item={item}
                          key={index}
                          keyword={keyword}
                          onClick={(e) => {
                            const url =
                              "/search/" + item.codeName.replace("#", "%23");
                            history.replace({
                              pathname: url,
                            });
                          }}
                        >
                          {item.codeName}
                        </SearchNavButton>
                      );
                  })}
              </MapListContent>

              <MapMoreBtn
                moreBtn={moreBtn}
                className="moreBtn"
                onClick={(e) => {
                  setShowMoreItemBox((prev) => !prev);
                }}
              ></MapMoreBtn>
            </MapList>

            {/* 편의지도 리스트 */}
            <MoreItemBoxWrapper showMoreBox={showMoreItemBox}>
              {convenienceData.map((item, index) => {
                if (index > 2)
                  return (
                    <>
                      <SearchNavEtcButton
                        keyword={keyword}
                        key={index}
                        item={item}
                        className="itemListP"
                      ></SearchNavEtcButton>
                    </>
                  );
              })}
            </MoreItemBoxWrapper>
          </MapInfoHeader>

          <MapIconWrapper>
            <PublicInfo>
              <PublicInfoIcon
                redMarker={iconInfoImgJebo}
                className={keyword ? " roundIcon jeboInfoCt" : "jeboInfoCt"}
              ></PublicInfoIcon>{" "}
              제보
            </PublicInfo>
            <PublicInfo>
              <PublicInfoIcon
                blueMarker={iconInfoImgPublic}
                className={keyword ? `roundIcon publicInfoCt` : `publicInfoCt`}
              ></PublicInfoIcon>{" "}
              공공
            </PublicInfo>
          </MapIconWrapper>
        </MapInfoCt>

        <ContentFeedCt>
          {feeds && feeds.length > 0 && (
            <ContentFeedList>
              {feeds.map((feed, index, arr) => (
                <FeedContainerTest key={index}>
                  <FeedContainer
                    key={index}
                    feed={feed}
                    popMenu={popMenu}
                    dispatchPopMenu={dispatchPopMenu}
                    onUpdateFeed={onUpdateFeed}
                    onDeleteFeed={onDeleteFeed}
                    onBlockFeed={onBlockFeed}
                    page="search"
                  />
                </FeedContainerTest>
              ))}
              <div ref={lastFeedRef}></div>
            </ContentFeedList>
          )}
        </ContentFeedCt>
      </SectionMap>
    </>
  );
}

const SectionMap = styled.div`
  width: 100%;
  height: 100%;

  overflow: hidden;
  position: relative;
`;

// Map container
const MapContainer = styled.div`
  width: 100%;
  /* height: 100%; */
  height: 500px;
  overflow: hidden;
  z-index: 0;
`;

const MapInfoCt = styled.div``;

const MapInfoHeader = styled.div`
  width: 435px;

  position: absolute;
  padding: 0 10px;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 15px;
`;

const PublicInfo = styled.div`
  margin-left: auto;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  border: 1px solid #dcdcdc;
  padding: 8px;
  height: 29px;

  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #232323;
`;

const PublicInfoIcon = styled.span`
  width: 25px;
  height: 25px;
  margin-right: 3px;
  &.jeboInfoCt {
    background: url(${({ redMarker }) => redMarker}) no-repeat;
    &.roundIcon {
      background-size: contain;
      background-position: center;
    }
  }
  &.publicInfoCt {
    background: url(${({ blueMarker }) => blueMarker}) no-repeat;
    &.roundIcon {
      background-size: contain;
      background-position: center;
    }
  }
`;

// ContentSection
const ContentFeedCt = styled.div`
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

const ContentFeedList = styled.div`
  width: 100%;
  transition: 0.2s;
  text-align: left;

  height: 100%;

  margin-top: 5px;

  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 1230px) {
    max-width: 914px;
  }
  @media screen and (max-width: 940px) {
    max-width: 608px;
  }
  @media screen and (max-width: 629px) {
    max-width: 100%;
  }
`;

const FeedContainerTest = styled.div`
  margin: 0 8.5px 1px;
  flex: 0 0 23.6%;
  min-width: 286px;
  transition: 0.2s;

  position: relative;

  @media screen and (max-width: 629px) {
    flex: 0 1 45%;
    min-width: 45%;
  }
`;

// 편의지도 수정
const MapIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 10px;
  top: calc(500px - 90px);
`;
const MapList = styled.div`
  width: 100%;
  height: 45px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  background-color: #fff;
  border: 1px solid #e2e5e7;
  border-radius: 30px;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
`;

const MapLocation = styled.button`
  margin-left: 15px;
  padding: 0;
  font-size: 0;

  background-color: transparent;
  &.curLocation::before {
    content: "";
    display: block;
    width: 22px;
    height: 22px;
    background: url(${({ gpsMarker }) => gpsMarker}) no-repeat;
    background-position: center;
    background-size: 22px;
  }
`;

const MapListContent = styled.ul`
  display: flex;
  margin-left: 5px;
  justify-content: space-between;

  width: 74%;
`;

const MapMoreBtn = styled.button`
  position: absolute;
  right: -2px;
  top: 0;
  height: 43px;
  background-color: #e2e5e7;
  border: 1px solid #e2e5e7;
  border-radius: 0 20px 20px 0;
  padding: 12px;
  &.moreBtn::before {
    content: "";
    display: block;
    width: 16px;
    height: 12px;
    background: url(${({ moreBtn }) => moreBtn}) no-repeat;
    background-position: center;
  }
`;

const MoreItemBoxWrapper = styled.div`
  margin-top: 5px;
  position: relative;
  z-index: 1;
  width: 100%;
  display: ${({ showMoreBox }) => (showMoreBox ? "flex" : "none")};
  flex-wrap: wrap;

  background-color: #fff;
  border: 1px solid #e2e5e7;
  padding: 10px;
  border-radius: 10px;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
  & > p {
    display: inline-block;
    font-size: 14px;
    margin: 6px;
  }
`;
