import React, { Fragment } from "react";

export default function AlertLayer({
  setShowAlertLayer,
  title,
  description,
  callback,
}) {
  return (
    <div className="pop-menu item-pop delete active">
      <div className="pop-header">
        <strong>
          {title.split("\\n").map((txt, index) => (
            <Fragment key={index}>
              {txt}
              <br />
            </Fragment>
          ))}
        </strong>
      </div>
      <div className="pop-body">
        {description && <span className="txt">{description}</span>}
        <div className="btns">
          <button
            type="button"
            className="alert-ok"
            onClick={(e) => {
              e.preventDefault();
              callback && callback();
              setShowAlertLayer(false);
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
}
