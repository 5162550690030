import jeboApi from "api/api";
import { SessionContext } from "components/providers/SessionContextProvider";
import { useContext } from "react";

export default function FeedDeleteConfirmLayer({ feed, onClose, onDelete }) {
  const { onExpiredSession } = useContext(SessionContext);

  const deleteFeed = async (feed) => {
    try {
      const { status } = await jeboApi.deleteFeed(feed.jeboId, feed.createAt);

      if (status === 200) onDelete(feed);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) onExpiredSession();
      }
    } finally {
      onClose();
    }
  };

  return (
    <div className="pop-menu item-pop delete active">
      <div className="pop-header">
        <strong>해당 제보를 삭제하시겠습니까?</strong>
      </div>
      <div className="pop-body">
        <span className="txt">삭제 시 복구할 수 없습니다.</span>
        <div className="btns">
          <button type="button" className="cancel" onClick={(e) => onClose()}>
            취소
          </button>
          <button
            type="button"
            className="delete"
            onClick={() => deleteFeed(feed)}
          >
            삭제
          </button>
        </div>
      </div>
    </div>
  );
}
