import jeboApi from "api/api";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import cameraIcon from "assets/imgs/icon_camera.svg";
import clearIcon from "assets/imgs/icons/clear.svg";
import LoadingComponent from "./LoadingComponent";

export default function CommentUpdateForm({
  comment,
  jeboId,
  setFeed,
  dispatch,
}) {
  const { isLoggedIn, handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);

  const [loading, setLoading] = useState(false);

  const [mediaFile, setMediaFile] = useState({
    file: null,
    url: comment.commentMediaUrl
      ? process.env.REACT_APP_RESOURCE_HOST + comment.commentMediaUrl
      : "",
  });
  const [isMedia, setIsMedia] = useState(mediaFile.url ? true : false);

  const [commentText, setCommentText] = useState(comment.contents);
  const resizeTextarea = (e) => {
    e.target.style.height = e.target.scrollHeight + "px";
  };

  const areaRef = useRef();

  useEffect(() => {
    function handleOutside(e) {
      if (areaRef.current && !areaRef.current.contains(e.target)) {
        dispatch({ type: "INIT" });
      }
    }
    document.addEventListener("click", handleOutside);
    return () => {
      document.removeEventListener("click", handleOutside);
    };
  }, [areaRef]);

  const onInput = (e) => {
    let file = e.target.files[0];
    if (file) {
      setMediaFile({
        file: file,
        url: URL.createObjectURL(file),
      });
      setIsMedia(true);
    } else {
      if (comment.commentMeidaUrl) {
        setMediaFile({
          file: null,
          url: comment.commentMediaUrl,
        });
      }
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      if (reader.readyState === 2) {
        console.log();
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const removeMedia = (removeMedia) => {
    URL.revokeObjectURL(removeMedia.url);
    setMediaFile({
      file: null,
      url: "",
    });
    setIsMedia(false);
  };

  const modifyComment = async (e) => {
    if (!isLoggedIn) {
      setSignLayer("signIn");
      return;
    }
    setLoading(true);

    if (!commentText && mediaFile.file) return;

    try {
      const {
        status,
        data: { comments },
      } = await jeboApi.commentModify(
        jeboId,
        comment.commentId,
        commentText,
        mediaFile,
        isMedia
      );
      if (status === 200) {
        const updatedComments = JSON.parse(comments);

        setFeed((prev) => {
          return Object.assign({}, prev, { jeboCommentList: updatedComments });
        });
      }
    } catch (error) {
      console.log("error : ", error);

      if (error.response) {
        console.log("error : ", error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
        }
      }
    } finally {
      dispatch({ type: "INIT" });
    }
  };

  return (
    <>
      <div className="comment-box cmt-active" ref={areaRef}>
        {isMedia && (
          <CommentMedia>
            <div>
              <button
                type="button"
                className="clearMediaViewer"
                onClick={(e) => {
                  e.stopPropagation();
                  removeMedia(mediaFile);
                }}
              >
                <i className="icon-cross3"></i>
              </button>
              <img src={isMedia && mediaFile.url} alt="commentMedia" />
            </div>
          </CommentMedia>
        )}
        <div className="comment-input">
          <textarea
            autoFocus
            className="recomment-write"
            placeholder="수정할 내용을 입력하세요."
            onKeyUp={resizeTextarea}
            onKeyDown={resizeTextarea}
            onChange={(e) => setCommentText(e.target.value)}
            onFocus={(e) => {
              e.target.scrollIntoView({ behavior: "smooth", block: "nearest" });
              !isLoggedIn && setSignLayer("signIn");
            }}
            value={commentText}
          ></textarea>

          <input
            type="file"
            className="file-upload"
            id="commentPic"
            style={{ display: "none" }}
            onClick={(e) => {
              e.target.value = null;
            }}
            onInput={onInput}
            accept="image/*"
          />
          <label htmlFor="commentPic" style={{ width: "20px" }}>
            <img src={cameraIcon} alt="" />
          </label>
          <button
            onClick={(e) => {
              if (!commentText && !mediaFile.file) {
                alert("댓글이나 이미지를 입력해주세요!");
                return;
              } else {
                !loading && modifyComment();
              }
            }}
          >
            수정
          </button>
        </div>
      </div>
      {loading && <LoadingComponent />}
    </>
  );
}

const CommentMedia = styled.div`
  width: 100%;
  max-height: 140px;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 50px;
  background-color: rgb(0 0 0 / 50%);
  z-index: 2;
  img {
    max-width: 25%;
  }
  button {
    position: absolute;
    right: 10px;
    top: 9px;
    width: 17px;
    height: 17px;
    line-height: 15px;
    z-index: 2;
    padding: 0;
    margin: 0;
    border: none;
    background: url(${clearIcon}) no-repeat;
  }
`;
