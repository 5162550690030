import user_icon from "assets/imgs/icons/user_icon.svg";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { useContext, useState, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";

export default function FeedHeader({ feed, onOpenPopMenu }) {
  const {
    address,
    placeName,
    nickName,
    createAt,
    memberImageUrl,
    memberUid,
    distance,
    feedType,
  } = feed;

  const regDateCalculate = (date) => {
    if (isRealDate) {
      return date;
    } else {
      const year = "20" + date.split(" ")[0].split(".")[0];
      const month = date.split(" ")[0].split(".")[1] - 1;
      const day = date.split(" ")[0].split(".")[2];
      const hours = date.split(" ")[1].split(":")[0];
      const seconds = date.split(" ")[1].split(":")[1];

      const now = new Date();
      const timeValue = new Date(year, month, day, hours, seconds);

      let betweenTime = Math.floor(
        (now.getTime() - timeValue.getTime()) / 1000 / 60
      );
      let betweenTimeHour = Math.floor(betweenTime / 60);
      let betweenTimeDay = Math.floor(betweenTime / 60 / 24);

      if (betweenTime < 1) {
        return "방금전";
      } else if (betweenTime < 60) {
        return `${betweenTime}분전`;
      } else if (betweenTimeHour < 24) {
        return `${betweenTimeHour}시간전`;
      } else {
        return `${betweenTimeDay}일전`;
      }
    }
  };

  const distanceParsing = (distance) => {
    let resultStr = "";
    if (distance != null && distance < 1000) {
      if (distance < 1) {
        resultStr = String(Number(distance) * 1000).split(".")[0] + "m";
      } else {
        resultStr = Number(distance.split(".")[0]) + 1 + "km내";
      }
    }

    return resultStr;
  };

  const addressParsing = (address, placeName) => {
    let resultStr = "";

    if (placeName && placeName.length > 0) {
      resultStr = placeName;
    } else if (address && address.length > 0) {
      let addressArr = address.split(" ").filter((_, index) => index < 3);
      addressArr.forEach((element) => {
        resultStr += element + " ";
      });
    }
    return resultStr.trim();
  };

  const history = useHistory();

  const { myInfo } = useContext(signAppContext);

  const [isRealDate, setIsRealDate] = useState(false);

  const calculTime = useMemo(() => regDateCalculate(createAt), [isRealDate]);
  const distanceResult = distanceParsing(distance);
  const locationResult = addressParsing(address, placeName);

  const feedHeaderRef = useRef();

  const { isMobile } = useContext(isMobileContext);

  return (
    <>
      <div className="item-header-wrap" ref={feedHeaderRef}>
        <div className="item-header">
          <div className="thumb">
            <img
              src={
                memberImageUrl
                  ? process.env.REACT_APP_RESOURCE_HOST + memberImageUrl
                  : user_icon
              }
              alt={nickName}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (myInfo && myInfo.memberUid === memberUid) {
                  history.push({ pathname: "/mypage" });
                } else {
                  history.push({
                    pathname: `/member/${encodeURIComponent(nickName)}`,
                  });
                }
              }}
            />
          </div>
          <div className="info-ct">
            <div className="info">
              <span className="user-name">
                <a
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (myInfo && myInfo.memberUid === memberUid) {
                      history.push({ pathname: "/mypage" });
                    } else {
                      history.push({
                        pathname: `/member/${encodeURIComponent(nickName)}`,
                      });
                    }
                  }}
                >
                  {nickName}
                </a>
              </span>

              <span className="location">
                {locationResult && (
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      history.push({
                        pathname: `/search/${encodeURIComponent(
                          encodeURIComponent(locationResult)
                        )}`,
                        search:
                          feedType === 2
                            ? ``
                            : `?latitude=${feed.latitude}&longitude=${feed.longitude}`,
                      });
                    }}
                  >
                    {locationResult}
                  </a>
                )}
              </span>
            </div>
            <button
              className="menu-open"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (isMobile) {
                  e.target.scrollIntoView(true);

                  window.scrollY &&
                    document.scrollingElement.scroll(0, window.scrollY - 150);
                }

                onOpenPopMenu(feed.jeboId, "FEAT");
              }}
            ></button>
          </div>
          <span className="distance">{distanceResult}</span>
          <span
            className="time"
            onClick={(e) => {
              e.stopPropagation();
              setIsRealDate((prev) => !prev);
            }}
          >
            {calculTime}
          </span>
        </div>
      </div>
    </>
  );
}
