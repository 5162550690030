import jeboApi from "api/api";
import question_icon from "assets/imgs/icons/question.png"; //활동포인트물음표
import PointPolicy from "components/layers/policy/PointPolicy";
import { signAppContext } from "components/providers/SignContextProvider";
import LoadingComponent from "components/templates/LoadingComponent";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useInView } from "react-intersection-observer";
import { useHistory, useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";

//활동포인트
export default function MobileMyActivityPoint() {
  const END_LIMIT = 15;
  const { handleSignOutApp, setSignLayer } = useContext(signAppContext);

  const [myPointList, setMyPointList] = useState([]);
  const [viewPointPolicy, setViewPointPolicy] = useState(false);

  const [lastFeedRef, inView] = useInView({ threshold: 0 });
  const [cursor, setCursor] = useState(0);
  const [loading, setLoading] = useState(true);
  const [notLoadedData, setNotLoadedData] = useState(false);
  const [error, setError] = useState(null);

  const history = useHistory();

  const now = new Date();

  const nowYear = now.getFullYear();
  const nowMonth = ("0" + (1 + now.getMonth())).slice(-2);

  //당월 첫번째 new Date(Y, m , 1)
  const startOfMonth = new Date(nowYear, now.getMonth(), 1);
  //당월 마지막 new Date(Y, m+1, 0)
  const endOfMonth = new Date(nowYear, now.getMonth() + 1, 0);

  const location = useLocation();
  const total = location.state && location.state.total;

  const loadMyPoint = useCallback(async () => {
    try {
      setLoading(true);
      const { status, data } = await jeboApi.loadMyActivityPoint(
        nowYear,
        nowMonth,
        END_LIMIT,
        cursor
      );
      if (status === 200) {
        const actPoint = JSON.parse(data.actPoint);
        setNotLoadedData(actPoint.length < END_LIMIT ? true : false);

        if (cursor === 0) setMyPointList(actPoint);
        else setMyPointList((prev) => prev.concat(actPoint));
      } else if (status === 204) {
        setNotLoadedData(true);
        console.log("no data to be loaded..");
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    } finally {
      setLoading(false);
    }
  }, [cursor, history, handleSignOutApp, setSignLayer]);

  useEffect(() => {
    loadMyPoint();
  }, [loadMyPoint]);

  useEffect(() => {
    if (inView && !error && !notLoadedData)
      setCursor((prev) => prev + END_LIMIT);
  }, [inView, error, notLoadedData]);

  return createPortal(
    <Wrap>
      <Modal>
        <ModalHeader>
          <Button
            onClick={() => {
              viewPointPolicy ? setViewPointPolicy(false) : history.goBack();
            }}
          ></Button>
          <Title>활동 포인트</Title>
          <PointQuestion
            src={question_icon}
            onClick={(e) => {
              e.preventDefault();
              setViewPointPolicy((prev) => !prev);
            }}
          />
        </ModalHeader>
        <ModalBody>
          <Container>
            <SubjectCt>
              <Subject>현재 포인트</Subject>
              {/* <Term>
                    {startOfMonth.getFullYear()}.{("0" + (1 + startOfMonth.getMonth())).slice(-2)}.{("0" + startOfMonth.getDate()).slice(-2)}
                  ~ {endOfMonth.getFullYear()}.{("0" + (1 + endOfMonth.getMonth())).slice(-2)}.{("0" + endOfMonth.getDate()).slice(-2)}
                </Term> */}
              <SubjectPoint>{total ? total : 0} Point</SubjectPoint>
            </SubjectCt>
            <PointCt>
              {myPointList.length > 0 ? (
                myPointList.map((point, index) => (
                  <PointList key={index}>
                    <DescSection>
                      <PointDate>{point.createAt}</PointDate>
                      <PointType>
                        {point.pointType === "CH"
                          ? "출석"
                          : point.pointType === "CF"
                          ? "게시물 작성"
                          : point.pointType === "DF"
                          ? "게시물 삭제"
                          : point.pointType === "CC"
                          ? "댓글 작성"
                          : point.pointType === "DC"
                          ? "댓글 삭제"
                          : point.pointType === "US"
                          ? "포인트 사용"
                          : ""}
                      </PointType>
                    </DescSection>
                    <PointSection>
                      <Point operation={point.operation}>
                        {point.operation === "P" ? "+" : "-"}
                        {point.point} point
                      </Point>
                    </PointSection>
                  </PointList>
                ))
              ) : (
                <NoData>이번 달 적립된 포인트가 없습니다.</NoData>
              )}
              {myPointList.length > 0 && <div ref={lastFeedRef}></div>}
            </PointCt>
            {viewPointPolicy && (
              <PointPolicy
                viewPointPolicy={viewPointPolicy}
                setViewPointPolicy={setViewPointPolicy}
              />
            )}
          </Container>
        </ModalBody>
      </Modal>
      {loading && (
        <div className="modal-wrap open">
          <LoadingComponent />
        </div>
      )}
    </Wrap>,
    document.getElementById("modal")
  );
}

const openModal = keyframes`
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
`;

const Modal = styled.div`
  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;

  width: 100%;
  max-width: 100%;
  height: 100% !important;
  max-height: 100vh !important;

  position: relative;
  background: #fff;
  transform: translateY(0);
  opacity: 0;
`;

const ModalHeader = styled.div`
  display: flex !important;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 7px;
  padding-right: 16px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;
`;

const Title = styled.strong`
  display: inline-block;
  width: auto;
  font-size: 1rem;
  color: var(--text-defaultBlackColor);
  font-weight: 500;
  /* line-height: 50px; */
  line-height: normal !important;
  width: 70%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-self: flex-end;
`;

const Button = styled.button`
  display: block;
  width: 27px;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background: url(/static/media/img_pre.2990dcb3.svg) no-repeat;
  background-color: #fff;
  background-position: left center;
`;

const ModalBody = styled.div`
  height: calc(100% - 50px);
  max-height: none !important;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  max-width: 100%;
  margin: 0;
`;
const SubjectCt = styled.div`
  position: absolute;
  background: #fff;
  width: 100%;
  top: 48px; /*heade높이*/
`;
const Subject = styled.span`
  width: 100%;
  display: inline-block;
  text-align: center;
  padding: 20px 20px 5px 20px;
  font-size: 18px;
`;

const SubjectPoint = styled.span`
  width: 100%;
  display: inline-block;
  text-align: center;
  padding-bottom: 20px;
  font-size: 17px;
`;

const PointCt = styled.div`
  margin-top: 100px;
  margin-bottom: 50px;
`;

const PointList = styled.div`
  margin-top: 17px;
  padding: 0 12px;
  height: 51px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #4e4e4e5e;
`;

const DescSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

const PointDate = styled.span`
  width: 100%;
  font-size: 12px;
  color: #4e4e4e9c;
`;

const PointType = styled.span`
  width: 100%;
  font-size: 20px;
  margin: 3px 0;
`;

const PointSection = styled.div`
  height: 30px;
  width: 23%;
  text-align: center;
  margin: 15px;
  background-color: lightgray;
  border-radius: 20px;
  margin: auto;
`;

const Point = styled.span`
  font-size: 15px;
  line-height: 30px; // 상위 Div Height과 동일하게
  color: ${(props) => (props.operation === "P" ? "black" : "red")};
`;

const NoData = styled.div`
  width: 100vw;
  height: 100vh;
  text-align: center;
  padding-top: 50%;
`;

const PointQuestion = styled.img`
  width: 20px;
  height: 20px;
`;
