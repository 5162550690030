import jeboApi from "api/api";
import Layers from "components/layers/Layers";
import Header from "components/pages/app/layouts/Header";
import Sections from "components/pages/DirectMessage/layouts/Sections";
import MemberSection from "components/pages/DirectMessage/MemberSection";
import MessageSection from "components/pages/DirectMessage/MessageSection";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { subscribeMessageContext } from "components/providers/SubscribeMessageContextProvider";
import qs from "query-string";
import { useCallback, useContext, useEffect, useState } from "react";
import { Route, useHistory, useLocation, useParams } from "react-router-dom";
import LayerContextProvider from "components/providers/LayerContextProvider";
// const debouncer = (() => {
//   let debounceCheck;

//   return {
//     debounce(callback, milliseconds) {
//       clearTimeout(debounceCheck);
//       debounceCheck = setTimeout(() => {
//         callback(...arguments);
//       }, milliseconds);
//     },
//   };
// })();
export default function DirectMessage() {
  const { isMobile } = useContext(isMobileContext);
  const { isLoggedIn, handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const { subscribeMessage } = useContext(subscribeMessageContext);

  const [titles, setTitles] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isFirstDm, setIsFirstDm] = useState(false);
  const [layer, setLayer] = useState(null);

  const history = useHistory();
  const { communicationId } = useParams();
  const location = useLocation();
  const { memberUid } = qs.parse(location.search);

  const getDirectMessages = useCallback(async () => {
    try {
      const response = await jeboApi.getDirectMessages(
        communicationId,
        isLoggedIn
      );
      const status = response.status;
      const data = response.data;
      if (status === 200) {
        const directMessageHeader = JSON.parse(data.directMessageHeader);
        const directMessageList = JSON.parse(data.directMessageList);

        directMessageHeader && directMessageHeader.length > 0
          ? setIsFirstDm(false)
          : setIsFirstDm(true);

        setTitles(
          directMessageHeader && directMessageHeader.length > 0
            ? directMessageHeader
            : []
        );
        setMessages(
          directMessageList && directMessageList.length > 0
            ? directMessageList
            : []
        );

        if (!communicationId && memberUid) {
          const header = directMessageHeader.find(
            (item) => item.toMemberUid === memberUid
          );
          if (header.communicationId) {
            history.replace({
              pathname: `/directMessage/${header.communicationId}`,
            });
          }
        }
      } else if (status === 204) {
        setTitles([]);
        setMessages([]);
        console.log("no data to be loaded..");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          console.log(error.response.status);
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  }, [
    history,
    handleSignOutApp,
    setSignLayer,
    communicationId,
    memberUid,
    isLoggedIn,
  ]);

  useEffect(() => {
    getDirectMessages();
  }, [subscribeMessage, getDirectMessages]);

  return (
    <LayerContextProvider layer={{ layer, setLayer }}>
      {!isMobile && <Header />}
      <Sections>
        {isMobile ? (
          !communicationId && memberUid ? (
            <MessageSection
              setTitles={setTitles}
              titles={titles}
              messages={messages}
              setMessages={setMessages}
              getDirectMessages={getDirectMessages}
            />
          ) : (
            <>
              <Route
                exact
                path="/directMessage"
                render={() => (
                  <MemberSection
                    titles={titles}
                    setTitles={setTitles}
                    isFirstDm={isFirstDm}
                  />
                )}
              />
              <Route
                exact
                path="/directMessage/:communicationId"
                render={() => (
                  <MessageSection
                    setTitles={setTitles}
                    titles={titles}
                    messages={messages}
                    setMessages={setMessages}
                    getDirectMessages={getDirectMessages}
                  />
                )}
              />
            </>
          )
        ) : (
          <>
            <MemberSection
              titles={titles}
              setTitles={setTitles}
              isFirstDm={isFirstDm}
            />
            {communicationId || memberUid ? (
              <MessageSection
                setTitles={setTitles}
                titles={titles}
                messages={messages}
                setMessages={setMessages}
                getDirectMessages={getDirectMessages}
              />
            ) : (
              <div className="dm-viewer-ct open">
                <div className="dm-viewer">
                  <div className="dm-header"></div>
                  <div className="dm-body"></div>
                </div>
              </div>
            )}
          </>
        )}
      </Sections>
      <Layers />
    </LayerContextProvider>
  );
}
