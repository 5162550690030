import AuthService from "api/AuthService";
import PasswordChangeLayer from "components/layers/passwordChange/PasswordChangeLayer";
import ConfirmLayer from "components/layers/popMenu/ConfirmLayer";
import ResignLayer from "components/layers/resign/ResignLayer";
import { signAppContext } from "components/providers/SignContextProvider";
import { useContext, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import MobileBlockMemberContainer from "./MobileBlockMemberContainer";

export default function MobileAccountManageContainer() {
  const { handleSignOutApp } = useContext(signAppContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [showConfirmLayer, setShowConfirmLayer] = useState(false);

  return createPortal(
    <div className="modal-wrap open">
      <div className="modal modal-detail open">
        <div className="modal-header mobile">
          <button
            type="button"
            className="list-back mobile"
            onClick={(e) => history.goBack()}
          ></button>
          <strong>계정 관리</strong>
        </div>
        <AccountManageContainer>
          {AuthService.getSocialYn() === "N" && (
            <AccountManageItem
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `${match.url}/passwdchange`,
                });
              }}
            >
              <span>비밀번호 변경</span>
            </AccountManageItem>
          )}
          <AccountManageItem
            onClick={(e) => {
              e.preventDefault();
              setShowConfirmLayer(true);
            }}
          >
            <span>로그아웃</span>
          </AccountManageItem>
          <AccountManageItem
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: `${match.url}/blockmember`,
              });
            }}
          >
            <span>차단목록</span>
          </AccountManageItem>
          <AccountManageItem
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: `${match.url}/resign`,
              });
            }}
          >
            <span>회원탈퇴</span>
          </AccountManageItem>
        </AccountManageContainer>
      </div>
      {showConfirmLayer && (
        <ConfirmLayer
          setShowConfirmLayer={setShowConfirmLayer}
          title="로그아웃 하시겠습니까?"
          description=""
          callback={() => {
            handleSignOutApp();
            history.replace({ pathname: "/" });
          }}
        />
      )}
      <Route
        path={`${match.path}/blockmember`}
        component={MobileBlockMemberContainer}
      />
      <Route path={`${match.path}/resign`} component={ResignLayer} />
      <Route
        path={`${match.path}/passwdchange`}
        component={PasswordChangeLayer}
      />
    </div>,
    document.getElementById("modal")
  );
}

const AccountManageContainer = styled.div`
  padding: 10px 0;
  display: block;
`;

const AccountManageItem = styled.button`
  border-top: none;
  background: #fff;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
`;
