import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { activeLocationContext } from "components/providers/LocationContextProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import redMarkerImg from "../../assets/imgs/icons/redmarker.svg";

export default function TrafficInfoBox({ trafficInfoList }) {
  const kakao = window.kakao;
  const { activeLocation } = useContext(activeLocationContext);
  const history = useHistory();
  const { isMobile } = useContext(isMobileContext);
  const [map, setMap] = useState(null);
  const mapRef = useRef();
  const textRef = useRef();
  const itemInfo = useRef();

  useEffect(() => {
    if (activeLocation) {
      if (map) {
        map.setCenter(
          new kakao.maps.LatLng(
            activeLocation.latitude,
            activeLocation.longitude
          )
        );
        // 지도에 교통정보를 표시하도록 지도타입을 추가합니다
        map.addOverlayMapTypeId(kakao.maps.MapTypeId.TRAFFIC);

        const zcontrol = new kakao.maps.ZoomControl();
        map.addControl(zcontrol, kakao.maps.ControlPosition.BOTTOMRIGHT);
        // 현 위치
        const markerPositions = new kakao.maps.LatLng(
          activeLocation.latitude,
          activeLocation.longitude
        );

        // 마커 이미지 변경
        const imageSrc = redMarkerImg,
          // 마커이미지의 주소입니다
          imageSize = new kakao.maps.Size(45, 45),
          // 마커이미지의 크기입니다
          imageOption = { offset: new kakao.maps.Point(27, 69) };
        // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

        // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
        const markerImage = new kakao.maps.MarkerImage(
          imageSrc,
          imageSize,
          imageOption
        );

        const marker = new kakao.maps.Marker({
          position: markerPositions,
          image: markerImage,
        });
        marker.setMap(map);
      } else {
        kakao.maps.load(() => {
          setMap(
            new kakao.maps.Map(mapRef.current, {
              center: new kakao.maps.LatLng(
                activeLocation.latitude,
                activeLocation.longitude
              ), // 지도의 중심좌표
              draggable: false,
              scrollwheel: false,
              disableDoubleClick: true,
              disableDoubleClickZoom: true,
              level: 4, // 지도의 확대 레벨
            })
          ); // 지도를 생성합니다
        });
      }
    }
  }, [kakao, map, activeLocation]);

  return (
    <div className="item traffic">
      <div className="item-header-wrap">
        <div className="item-header">
          <div className="thumb thumb-traffic"></div>
          <div className="info-ct">
            <div className="info">
              <span className="user-name">실시간 교통 정보</span>
              <span className="location">{activeLocation.addressName}</span>
            </div>
          </div>

          {/* 0110 크게보기 버튼 */}
          {!isMobile ? (
            <button
              className="traffic-map-btn"
              onClick={(e) => {
                history.push("/trafficMap");
              }}
            >
              크게보기
            </button>
          ) : (
            <></>
          )}
          {/* 0110 크게보기 버튼 */}
        </div>
      </div>
      <div className="item-body-wrap">
        <div className="item-body">
          <div className="item-photo" style={{ position: "relative" }}>
            <div style={{ height: "100%", zIndex: 1 }} ref={mapRef}></div>
            <div
              id="corver"
              style={{
                width: "90%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
                zIndex: 2,
              }}
            ></div>
          </div>
          <div ref={itemInfo} className="item-info">
            <div className="tags more-info-ct">
              <button
                onClick={(e) => {
                  e.target.classList.toggle("active");
                  textRef.current.classList.toggle("active");
                  itemInfo.current.classList.toggle("active");
                }}
                className="more-infoBtn"
              ></button>
            </div>

            <div className="text">
              <p className="traffic-desc" ref={textRef}>
                {trafficInfoList && trafficInfoList.length > 0
                  ? trafficInfoList[0].type +
                    " - " +
                    trafficInfoList[0].eventType +
                    "_(" +
                    trafficInfoList[0].roadName +
                    "-" +
                    trafficInfoList[0].roadDrcType +
                    ") " +
                    trafficInfoList[0].message
                  : trafficInfoList.length < 1 && "[사고, 공사] 정보 없음"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="item-bar-wrap">
        <div className="item-bar traffic">
          <b>제공</b>
          <span>국토교통정보센터</span>
        </div>
      </div>
    </div>
  );
}
