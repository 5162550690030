import { signAppContext } from "components/providers/SignContextProvider";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

export default function PopMenu({
  setShowPopMenu,
  setShowDeleteConfirmLayer,
  setShowBlockLayer,
  setShowReportLayer,
  targetMemberUid,
  targetMemberNickName,
}) {
  const { myInfo } = useContext(signAppContext);

  const history = useHistory();

  return (
    <div className="pop-menu item-menu message active">
      <ul>
        <li>
          <a
            className="menu dm-link"
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if (myInfo && myInfo.memberUid === targetMemberUid) {
                history.push({ pathname: "/mypage" });
              } else {
                history.push({
                  pathname: `/member/${encodeURIComponent(
                    targetMemberNickName
                  )}`,
                });
              }
            }}
          >
            대화상대 프로필보기
          </a>
        </li>
        <li>
          <button
            className="menu"
            onClick={(e) => {
              setShowDeleteConfirmLayer(true);
              setShowPopMenu(false);
            }}
          >
            채팅삭제
          </button>
        </li>
        <li>
          <button
            className="menu"
            onClick={(e) => {
              setShowBlockLayer(true);
              setShowPopMenu(false);
            }}
          >
            차단
          </button>
        </li>
        <li>
          <button
            className="menu"
            onClick={(e) => {
              setShowReportLayer(true);
              setShowPopMenu(false);
            }}
          >
            신고
          </button>
        </li>
        <li>
          <button
            className="menu"
            onClick={(e) => {
              setShowPopMenu(false);
            }}
          >
            취소
          </button>
        </li>
      </ul>
    </div>
  );
}
