import { layerContext } from "components/providers/LayerContextProvider";
import { Fragment, useContext } from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import FeedMainText from "./FeedMainText";
import GridMediaBox from "./GridMediaBox";

import Lcampaign from "assets/imgs/icons/icon_socialCampaign.svg";
import Lchannel from "assets/imgs/icons/icon_socialChannel.svg";
import Lcommunity from "assets/imgs/icons/icon_socialCommu.svg";
import Ltheme from "assets/imgs/icons/icon_socialTheme.svg";
import styled from "styled-components";

export default function GridFeed({ feed, lastFeedRef, page }) {
  const { setLayer } = useContext(layerContext);
  const {
    address,
    placeName,
    contents,
    jeboMediaList: jeboMedia,
    social,
  } = feed;

  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <li
      ref={lastFeedRef}
      className={feed.mediaCnt > 1 ? "pl" : ""}
      onClick={(e) => {
        e.stopPropagation();
        history.push({
          pathname: `${match.url}/list`,
          state: { jeboId: feed.jeboId, page },
          search: location.search ? location.search : null,
        });
      }}
    >
      {social && (
        <SocialLabel scType={social.scType}>
          {social.scType === 1
            ? "테마"
            : social.scType === 2
            ? "캠페인"
            : social.scType === 3
            ? "채널"
            : "커뮤 니티"}
        </SocialLabel>
      )}

      {feed.feedType === 0 || feed.feedType === 2 ? (
        jeboMedia && jeboMedia.length > 0 ? (
          <Fragment key={jeboMedia[0].mediaId}>
            <GridMediaBox
              mediaType={jeboMedia[0].mediaType}
              mediaUrl={
                process.env.REACT_APP_RESOURCE_HOST + jeboMedia[0].mediaUrl
              }
            />
          </Fragment>
        ) : (
          <div className="default-img type01">
            <span>
              {contents
                ? contents.length > 35
                  ? contents.substr(0, 35) + "..."
                  : contents
                : placeName
                ? placeName
                : address}
            </span>
          </div>
        )
      ) : (
        <div className="default-img-share">
          <span className="share__title__grid">#제보요청</span>
          <div className="share__textBox">
            <div className="share__desc__grid">
              <FeedMainText feed={feed} grid={true} />
            </div>
          </div>
        </div>
      )}
    </li>
  );
}

const SocialLabel = styled.div`
  position: absolute;
  top: -5px;
  right: 0px;
  z-index: 1;
  width: 40px;
  height: 35px;

  scale: 0.75;

  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  padding: 0 10px 7px 3px;
  line-height: 13px;
  letter-spacing: -0.5px;

  background: url(${({ scType }) => {
      if (scType === 1) {
        return Ltheme;
      } else if (scType === 2) {
        return Lcampaign;
      } else if (scType === 3) {
        return Lchannel;
      } else if (scType === 4) {
        return Lcommunity;
      } else {
        return "";
      }
    }})
    no-repeat;
  background-size: contain;
`;
