export default function FeedSortTab({ sort, setSort }) {
  return (
    <ul className="list tab-btns">
      <li className={sort === "" ? `btn active` : `btn`}>
        <button
          onClick={(e) => {
            setSort("");
          }}
        >
          추천순
        </button>
      </li>
      <li className={sort === "0" ? `btn active` : `btn`}>
        <button
          onClick={(e) => {
            setSort("0");
          }}
        >
          최신순
        </button>
      </li>
      <li className={sort === "1" ? `btn active` : `btn`}>
        <button
          onClick={(e) => {
            setSort("1");
          }}
        >
          거리순
        </button>
      </li>
      <li
        className={
          sort === "00" || sort === "01" || sort === "02" || sort === "03"
            ? `btn active`
            : `btn`
        }
      >
        <button
          onClick={(e) => {
            sort === "00" || sort === "01" || sort === "02" || sort === "03"
              ? setSort(sort)
              : setSort(document.querySelector("#sort_sel").value);
          }}
        >
          인기순
        </button>

        <div className="sort-sel">
          <select
            id="sort_sel"
            onChange={(e) => {
              setSort(e.target.value);
            }}
            defaultValue={
              sort === "00" || sort === "01" || sort === "02" || sort === "03"
                ? sort
                : "01"
            }
          >
            <option value="00">오늘</option>
            <option value="01">1주 내</option>
            <option value="02">1개월 내</option>
            <option value="03">1년 내</option>
          </select>
        </div>
      </li>
    </ul>
  );
}
