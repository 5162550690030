import jeboApi from "api/api";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import {
  feedCategoriesContext,
  feedsContext,
} from "components/providers/FeedContextProvider";
import { currentLocationContext } from "components/providers/LocationContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import LoadingComponent from "components/templates/LoadingComponent";
import qs from "query-string";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory, useLocation } from "react-router-dom";
import ConfirmLayer from "../popMenu/ConfirmLayer";
import CategoryContainer from "./CategoryContainer";
import EditorContainer from "./EditorContainer";
import LocationSearchContainer from "./LocationSearchContainer";
import MediaContainer from "./MediaContainer";

import ImageEditorLayer from "./ImageEditorLayer";
import ShareTabLayer from "./ShareTabLayer";
import IntroduceEventPopLayer from "../popMenu/IntroduceEventPopLayer";

export default function FeedAddLayer() {
  const { handleSignOutApp } = useContext(signAppContext);
  const { getCurrentPosition } = useContext(currentLocationContext);
  const { isMobile } = useContext(isMobileContext);
  const { setFeeds, feeds } = useContext(feedsContext);
  const { feedCategories } = useContext(feedCategoriesContext);

  const location = useLocation();

  const [media, setMedia] = useState([]);
  const [locationInfo, setLocationInfo] = useState(null);
  const [categories, setCategories] = useState(
    feedCategories.map((category) =>
      Object.assign({}, category, {
        checked: false,
      })
    )
  );

  const [targetCategory, setTargetCategory] = useState(null);
  const [contents, setContents] = useState("");
  const [hashTags, setHashTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputStateMessage, setInputStateMessage] = useState("");
  const [locationInputStateMessage, setLocationInputStateMessage] =
    useState("");
  const [showConfirmLayer, setShowConfirmLayer] = useState(false);
  const [eventInfo, setEventInfo] = useState(null);

  const [showRegistTab, setShowRegistTab] = useState(false);
  const [showRequestTab, setShowRequestTab] = useState(false);

  const [tabState, setTabState] = useState(true);
  const [feedType, setFeedType] = useState(0);

  //ImageEditor
  const [showEditor, setShowEditor] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");

  const editorRef = useRef();
  const modalRef = useRef();

  const [openLocation, setOpenLocation] = useState(false);
  const [openMedia, setOpenMedia] = useState(false);

  const history = useHistory();

  //SearchMap에서 제보하기 URL 접근 시 conName 사용
  const POP_KEY = "introduce_event";
  const IS_POP_OPEN = localStorage.getItem(POP_KEY);
  const { eventId, conName, isEvent } = qs.parse(location.search);

  const [introduceEvent, setIntroduceEvent] = useState(
    isEvent
      ? JSON.parse(isEvent)
        ? IS_POP_OPEN !== null
          ? JSON.parse(IS_POP_OPEN)
          : true
        : false
      : false
  );

  useEffect(() => {
    setLocationInfo(null);
    getCurrentPosition(false);
  }, [tabState, getCurrentPosition]);

  useEffect(() => {
    // 0323 여기를 수정해야함
    tabState
      ? locationInfo && locationInfo.latitude && locationInfo.longitude
        ? setFeedType(0)
        : setFeedType(2)
      : setFeedType(1);
  }, [locationInfo]);

  // const getEventName = useCallback(async () => {
  //   if (!eventId) return;

  //   try {
  //     const {
  //       data: { event },
  //       status,
  //     } = await jeboApi.getEventName(eventId);

  //     if (status === 200) {
  //       const eventInfo = JSON.parse(event);
  //       setEventInfo(eventInfo);
  //     } else {
  //       setEventInfo(null);
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       console.log(error.response);
  //       if (error.response.status === 403) {
  //         alert("세션이 만료되었습니다. 다시 로그인해주세요.");
  //         handleSignOutApp();
  //       }
  //     }
  //   }
  // }, [eventId, handleSignOutApp]);

  // useEffect(() => {
  //   getEventName();
  // }, [getEventName]);

  const revokeMediaUrl = () => {
    media.forEach((medium) => URL.revokeObjectURL(medium.url));
  };

  const requiredInputCheck = () => {
    if (!locationInfo && feedType === 0) {
      setLocationInputStateMessage("위치를 입력하세요.");
      return false;
    }

    if (contents.length > 10000) {
      setInputStateMessage("내용입력은 10,000자 까지만 가능합니다.");
      return false;
    }

    if (!contents || !media || !media.length === 0) {
      setInputStateMessage("제보 미디어를 첨부하거나 내용을 입력하세요.");
      return false;
    }

    return true;
  };

  const registFeed = async () => {
    if (loading) return;

    try {
      setLoading(true);

      let regContents = contents;
      let regHashtags = hashTags;
      // if (eventInfo && eventInfo.eventShortsDesc) {
      //   regContents = ` ${eventInfo.eventShortsDesc} ${regContents}`;

      //   let eventShortsDesc = eventInfo.eventShortsDesc;
      //   if (eventShortsDesc.trim()) {
      //     let eventShortsDescs = eventShortsDesc.trim().split(" ");
      //     eventShortsDescs.forEach((name) => {
      //       if (name.indexOf("#") === 0) regHashtags = [name, ...regHashtags];
      //     });
      //   }
      // }
      /*편의지도 태그가 query-string으로 전달되고, 
      editor에 편의지도 태그가 없다면 regHashtags에 편의지도 태그 push 해줌 */
      if (conName) {
        if (!regHashtags.includes(conName)) {
          regHashtags.push(conName);
          regContents += " " + conName;
        }
      }

      /* 위치없는 경우 location이 null이기 때문에 오류 location 값 체크 필요 */
      const {
        data: { jeboPost },
      } = await jeboApi.feedRegist(
        media,
        regHashtags,
        regContents,
        locationInfo && locationInfo.address,
        locationInfo && locationInfo.roadAddress,
        locationInfo && locationInfo.placeName,
        locationInfo && locationInfo.latitude,
        locationInfo && locationInfo.longitude,
        locationInfo && locationInfo.hashtags,
        eventId,
        feedType
      );
      const jeboPostObj = JSON.parse(jeboPost);

      revokeMediaUrl();

      jeboPostObj && setFeeds([jeboPostObj, ...feeds]);
      document.scrollingElement.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
      history.replace({ pathname: "/" });
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요");
          handleSignOutApp();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const registShare = async () => {
    if (loading) return;

    try {
      setLoading(true);
      /* 위치없는 경우 location이 null이기 때문에 오류 location 값 체크 필요 */
      const {
        data: { jeboPost },
      } = await jeboApi.shareFeedRegist(
        media,
        hashTags,
        contents,
        locationInfo && locationInfo.address,
        locationInfo && locationInfo.roadAddress,
        locationInfo && locationInfo.placeName,
        locationInfo && locationInfo.latitude,
        locationInfo && locationInfo.longitude,
        locationInfo && locationInfo.hashtags,
        eventId,
        feedType
      );
      const jeboPostObj = JSON.parse(jeboPost);

      revokeMediaUrl();

      jeboPostObj && setFeeds([jeboPostObj, ...feeds]);
      document.scrollingElement.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
      history.replace({ pathname: "/" });
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요");
          handleSignOutApp();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return createPortal(
    <>
      <div
        className="modal-wrap open"
        ref={modalRef}
        onMouseDown={(e) => {
          !isMobile &&
            e.target === modalRef.current &&
            history.replace({ pathname: "/" });
        }}
      >
        <div className="modal modal-write open" id="modal-write">
          <div className="modal-header">
            <button
              type="button"
              className="page-back mobile"
              onClick={(e) => {
                e.preventDefault();
                revokeMediaUrl();
                history.goBack();
              }}
            ></button>
            <div className="modal-tabCt">
              <button
                onClick={(e) => {
                  setMedia([]);
                  setContents("");
                  setTargetCategory(null);
                  setCategories((prev) =>
                    prev.map((category) =>
                      Object.assign({}, category, {
                        checked: false,
                      })
                    )
                  );
                  setOpenLocation(false);
                  setOpenMedia(false);
                  setTabState((prev) => !prev);
                  setFeedType(0);
                }}
                className={`modal-tab ${tabState && "active"}`}
              >
                제보하기
              </button>
              <button
                onClick={(e) => {
                  setMedia([]);
                  setContents("");
                  setTargetCategory(null);
                  setCategories((prev) =>
                    prev.map((category) =>
                      Object.assign({}, category, {
                        checked: false,
                      })
                    )
                  );
                  setOpenLocation(false);
                  setOpenMedia(false);
                  setTabState((prev) => !prev);
                  setFeedType(1);
                }}
                className={`modal-tab ${!tabState && "active"}`}
              >
                제보요청
              </button>
            </div>
            <button
              type="button"
              className="modal-close pc"
              onClick={(e) => {
                e.preventDefault();
                revokeMediaUrl();
                !isMobile && history.replace({ pathname: "/" });
              }}
            >
              <i className="icon-cross2"></i>
            </button>
          </div>
          <div className="modal-body">
            {showEditor && (
              <ImageEditorLayer
                mediaUrl={mediaUrl}
                media={media}
                setMedia={setMedia}
                setShowEditor={setShowEditor}
                setIsEdit={setIsEdit}
              />
            )}
            {tabState ? (
              <>
                <CategoryContainer
                  setCategories={setCategories}
                  categories={categories}
                  setTargetCategory={setTargetCategory}
                  tabState={tabState}
                />
                <LocationSearchContainer
                  locationInfo={locationInfo}
                  setLocationInfo={setLocationInfo}
                  locationInputStateMessage={locationInputStateMessage}
                  setLocationInputStateMessage={setLocationInputStateMessage}
                  openLocation={true}
                  setOpenLocation={setOpenLocation}
                  feedType={feedType}
                />
                <MediaContainer
                  media={media}
                  setMedia={setMedia}
                  openMedia={true}
                  setShowEditor={setShowEditor}
                  setMediaUrl={setMediaUrl}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  tabState={tabState}
                />
                <EditorContainer
                  setHashTags={setHashTags}
                  targetCategory={targetCategory}
                  categories={categories}
                  setCategories={setCategories}
                  contents={contents}
                  setContents={setContents}
                  eventInfo={eventInfo}
                  editorRef={editorRef}
                  conName={conName}
                />
              </>
            ) : (
              <>
                <CategoryContainer
                  setCategories={setCategories}
                  categories={categories}
                  setTargetCategory={setTargetCategory}
                  tabState={tabState}
                />
                <div className={`shareTab ${openLocation ? "active" : ""}`}>
                  <button
                    className="shareTabBtn"
                    onClick={() => {
                      setOpenLocation((prev) => !prev);
                    }}
                  ></button>
                  <LocationSearchContainer
                    locationInfo={locationInfo}
                    setLocationInfo={setLocationInfo}
                    locationInputStateMessage={locationInputStateMessage}
                    setLocationInputStateMessage={setLocationInputStateMessage}
                    openLocation={openLocation}
                    setOpenLocation={setOpenLocation}
                    feedType={feedType}
                  />
                </div>
                <div className={`shareTab ${openMedia ? "active" : ""}`}>
                  <button
                    className="shareTabBtn"
                    onClick={() => {
                      setOpenMedia((prev) => !prev);
                    }}
                  ></button>
                  <MediaContainer
                    media={media}
                    setMedia={setMedia}
                    openMedia={openMedia}
                    setShowEditor={setShowEditor}
                    setMediaUrl={setMediaUrl}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    tabState={tabState}
                  />
                </div>

                <ShareTabLayer
                  setHashTags={setHashTags}
                  targetCategory={targetCategory}
                  categories={categories}
                  setCategories={setCategories}
                  contents={contents}
                  setContents={setContents}
                  eventInfo={eventInfo}
                  editorRef={editorRef}
                />
              </>
            )}

            <div className="write-btn btmBtnContainer">
              {inputStateMessage && (
                <span className="invalid">{inputStateMessage}</span>
              )}
              <button
                className="modal-btn cancle-btn"
                onClick={(e) => {
                  e.preventDefault();
                  revokeMediaUrl();
                  //모바일 && PC 모두 goBack 처리 문제 있나??
                  // isMobile
                  //   ? history.goBack()
                  //   : history.replace({ pathname: "/" });
                  history.goBack();
                }}
              >
                취소하기
              </button>
              <button
                className="modal-btn"
                onClick={(e) => {
                  e.preventDefault();
                  if (tabState && !requiredInputCheck()) return false;
                  else if (!contents) {
                    alert("제보요청 내용을 입력해주세요");
                    return false;
                  }
                  setLocationInputStateMessage("");
                  setInputStateMessage("");
                  setShowConfirmLayer(true);
                }}
              >
                {tabState ? "제보하기" : "제보요청"}
              </button>
            </div>
          </div>
          {showConfirmLayer && (
            <ConfirmLayer
              setShowConfirmLayer={setShowConfirmLayer}
              callback={tabState ? registFeed : registShare}
              title={tabState ? "제보 하시겠습니까?" : "제보요청 하시겠습니까?"}
              description=""
              btnText={tabState ? "제보하기" : "제보요청"}
              isConfirm={true}
            />
          )}
          {showRegistTab && (
            <ConfirmLayer
              setShowConfirmLayer={setShowRegistTab}
              callback={() => {
                setMedia([]);
                setContents("");
                setTargetCategory(null);
                setCategories((prev) =>
                  prev.map((category) =>
                    Object.assign({}, category, {
                      checked: false,
                    })
                  )
                );
                setOpenLocation(false);
                setOpenMedia(false);
                setTabState((prev) => !prev);
              }}
              title={
                "작성 중인 내용이 있습니다. \\n 제보하기 이동 시 \\n 내용이 초기화됩니다. \\n 이동하시겠습니까?"
              }
              description=""
              btnText={"이동하기"}
              isConfirm={true}
            />
          )}
          {showRequestTab && (
            <ConfirmLayer
              setShowConfirmLayer={setShowRequestTab}
              callback={() => {
                setMedia([]);
                setContents("");
                setTargetCategory(null);
                setCategories((prev) =>
                  prev.map((category) =>
                    Object.assign({}, category, {
                      checked: false,
                    })
                  )
                );
                setOpenLocation(false);
                setOpenMedia(false);
                setTabState((prev) => !prev);
              }}
              title={
                "작성 중인 내용이 있습니다. \\n 제보요청 이동 시 \\n 내용이 초기화됩니다. \\n 이동하시겠습니까?"
              }
              description=""
              btnText={"이동하기"}
              isConfirm={true}
            />
          )}
          {introduceEvent && (
            <IntroduceEventPopLayer
              setOpenPop={setIntroduceEvent}
              expiredKey={POP_KEY}
            />
          )}
        </div>
      </div>

      {loading && (
        <div className="modal-wrap open">
          <LoadingComponent />
        </div>
      )}
    </>,
    document.getElementById("modal")
  );
}
