import jeboApi from "api/api";
import user_icon from "assets/imgs/icons/user_icon.svg";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { useCallback, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

export default function ProfilePopLayer({
  setShowProfilePopLayer,
  setRemoveToastText,
  ToastTextReturn,
  modifyMyProfile,
}) {
  const { myInfo, setMyInfo, handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const { isMobile } = useContext(isMobileContext);
  const refFile = useRef();
  const refPopMenu = useRef();

  const history = useHistory();

  // 0712
  const fileRef = useRef();

  const handleClosePopMenu = useCallback(
    (e) => {
      isMobile
        ? e.target === refPopMenu.current && setShowProfilePopLayer(false)
        : !e.target.closest(".pop-menu") && setShowProfilePopLayer(false);
    },
    [isMobile, setShowProfilePopLayer]
  );

  useEffect(() => {
    window.addEventListener("click", handleClosePopMenu);
    return () => {
      window.removeEventListener("click", handleClosePopMenu);
    };
  }, [handleClosePopMenu]);

  const removeMyProfile = async () => {
    try {
      const response = await jeboApi.myProfileRemove();
      if (response.status === 200) {
        setRemoveToastText(true);
        setMyInfo(
          Object.assign({}, myInfo, {
            imageUrl: user_icon,
          })
        );
        ToastTextReturn(true);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          console.log(error.response.status);
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    } finally {
      setShowProfilePopLayer(false);
    }
  };

  return (
    <div
      className="profile pop-menu item-menu scroll-lock active"
      ref={refPopMenu}
    >
      <ul>
        {isMobile && (
          <li>
            <input
              className="menu-directCamera"
              id="directCamera"
              type="file"
              ref={fileRef}
              onClick={(e) => (e.target.value = null)}
              onChange={(e) => modifyMyProfile(Array.from(e.target.files)[0])}
              accept="image/*"
              capture={true}
            />
            <label htmlFor="directCamera" className="directCamera_text">
              사진 찍기
            </label>
          </li>
        )}
        <li>
          <button
            className="menu"
            onClick={(e) => {
              refFile.current.click();
            }}
          >
            사진 선택
          </button>
          <input
            type="file"
            className="file-upload"
            id="fileupload"
            accept="image/*"
            ref={refFile}
            onChange={(e) => {
              modifyMyProfile(Array.from(e.target.files)[0]);
            }}
            style={{ display: "none" }}
          />
        </li>

        <li>
          <button className="menu" onClick={removeMyProfile}>
            사진 삭제
          </button>
        </li>
        {/* {isMobile && (
          <li className="mobile">
            <button
              className="menu"
              onClick={() => setShowProfilePopLayer(false)}
            >
              취소
            </button>
          </li>
        )} */}
      </ul>
    </div>
  );
}
