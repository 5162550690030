import heart from "assets/imgs/icons/icon_favRed.svg";
import emptyHeart from "assets/imgs/icons/icon_favRedBorder.svg";
import { useHistory, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { useContext, useEffect, useState } from "react";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";

import iconMember from "assets/imgs/icons/icon_joinUser.svg";
import iconMemberActive from "assets/imgs/icons/icon_joinUserActive.svg";

import iconPostCount from "assets/imgs/icons/icon_feedcount.svg";
import iconPostCountActive from "assets/imgs/icons/icon_feedcountActive.svg";

export default function ContentItemDefault({
  item,
  category,
  toggleLike,
  index,
  section,
}) {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useContext(isMobileContext);
  const [like, setLike] = useState(item.like);
  const [likeCount, setLikeCount] = useState(item.likeCount);
  const [postCnt, setPostCnt] = useState(item.postCnt);

  useEffect(() => {
    setLike(item.like);
    setLikeCount(item.likeCount);
    setPostCnt(item.postCnt);
  }, [item]);

  const {
    scId: socialId,
    scType: socialType,
    scTag: hashtags,
    scName: title,
    memberCount,
    imageName,
    participated,
  } = item;

  // useEffect(() => {
  //   if (like) {
  //     setLikeCount((prev) => prev + 1);
  //   } else {
  //     setLikeCount((prev) => prev - 1);
  //   }
  // }, [like]);

  return (
    <ItemWrapper
      className={section}
      index={index}
      isMobile={isMobile.isMobile}
      onClick={(e) => {
        history.push({
          ...location,
          pathname: `/social/${category.name}/${socialId}`,
        });
      }}
    >
      <ContentWrapper>
        <InfoWrap>
          <TextWrapper>
            <HashtagWrapper>
              {hashtags &&
                hashtags
                  .split(" ")
                  .filter((_, index) => index < 3)
                  .map((hashtag, index) => (
                    <Hashtag key={index}>{hashtag}</Hashtag>
                  ))}
            </HashtagWrapper>

            <SocialTypeText
              type={
                socialType === 1
                  ? "테마"
                  : socialType === 2
                  ? "캠페인"
                  : socialType === 3
                  ? "채널"
                  : socialType === 4
                  ? `커뮤 니티`
                  : ""
              }
            >
              {socialType === 1
                ? "테마"
                : socialType === 2
                ? "캠페인"
                : socialType === 3
                ? "채널"
                : socialType === 4
                ? `커뮤니티`
                : ""}
            </SocialTypeText>
            {title}
          </TextWrapper>

          <ImageWrapper className={section}>
            <Image
              imageUrl={
                socialId &&
                process.env.REACT_APP_RESOURCE_HOST +
                  "/media/social/" +
                  imageName
              }
            />
          </ImageWrapper>
        </InfoWrap>

        <JoinInfoWrap className={section}>
          <HeartBox>
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // 상태관리 자세히 해봐야겟음 24.06.27.codelua
                // setLike((like) => !like);
                // toggleLike(item);
              }}
            >
              <img src={like ? heart : emptyHeart} alt="" />
            </a>
            <h3>{likeCount}</h3>
          </HeartBox>
          <MemberCountBox>
            <img src={participated ? iconMemberActive : iconMember} alt="" />
            <h3>{memberCount}</h3>
          </MemberCountBox>
          {/* 피드의 개수 표기  */}
          <PostCountBox>
            <img
              src={postCnt > 0 ? iconPostCountActive : iconPostCount}
              alt=""
            />
            <h3>{postCnt}</h3>
          </PostCountBox>
        </JoinInfoWrap>
      </ContentWrapper>
    </ItemWrapper>
  );
}

const ItemWrapper = styled.div`
  display: flex;
  padding: 18px 0;
  border-bottom: 1px solid #dcdcdc;
  cursor: pointer;

  ${({ index }) => {
    if (index === 0) {
      return css`
        margin-top: 6px;
      `;
    }
    if (index === 3) {
      return css`
        border-bottom: none;
      `;
    }
  }};

  ${({ isMobile }) => {
    if (!isMobile) {
      return css`
        padding: 5.5px 0 15px;
      `;
    }
  }}
`;

const InfoWrap = styled.div`
  display: flex;
  margin-bottom: 9px;
  @media screen and (min-width: 481px) {
    margin-bottom: 0px;
  }
`;

const JoinInfoWrap = styled.div`
  display: flex;

  &.home {
    margin-top: 3px;
  }
`;

const PostCountBox = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;

  margin-right: 11px;
  & > :first-child {
    margin-right: 5px;
  }
  img {
    width: 14px;
    height: 12.84px;
  }
  @media screen and (min-width: 481px) {
    font-size: 12px;
  }
`;

const HeartBox = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;

  margin-right: 11px;
  & > :first-child {
    margin-right: 5px;
  }
  img {
    width: 14px;
    height: 12.84px;
  }
  /* a {
    cursor: default;
    text-decoration: none;
    color: inherit;
  } */
  @media screen and (min-width: 481px) {
    font-size: 12px;
    /* border: 1px dashed blue; */
  }
`;

const MemberCountBox = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  margin-right: 11px;
  & > :first-child {
    margin-right: 5px;
  }
  img {
    width: 14px;
    height: 12.84px;
  }
  @media screen and (min-width: 481px) {
    font-size: 12px;
    /* border: 1px dashed blue; */
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
`;

const HashtagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  flex: 1 0 inherit;
  width: 100%;
  max-height: 30px;

  overflow: scroll;
  @media screen and (min-width: 481px) {
    overflow: unset;
  }

  margin-bottom: 4px;
`;

const Hashtag = styled.p`
  display: block;
  height: 16px;
  max-width: 99px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-weight: 600;
  font-size: 10px;
  line-height: 14px;

  color: #3773ff;

  margin-right: 10px;

  @media screen and (min-width: 481px) {
    font-size: 13px;
    /* border: 1px dashed red; */
  }
`;

const TextWrapper = styled.div`
  height: 100%;

  overflow: hidden;
  word-break: break-all;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #292929;
  margin-right: 23px;

  @media screen and (min-width: 481px) {
    font-size: 16px;
  }
`;

const SocialTypeText = styled.p`
  display: inline-block;
  margin-right: 5px;
  font-size: 12px;
  font-weight: 700;
  @media screen and (min-width: 481px) {
    font-size: 15px;
    /* border: 1px dashed red; */
  }
  ${({ type }) => {
    if (type === "테마") {
      return css`
        color: #14b15c !important;
      `;
    } else if (type === "캠페인") {
      return css`
        color: #cc440a !important;
      `;
    } else if (type === "커뮤 니티") {
      return css`
        color: #bf1cf9 !important;
      `;
    } else if (type === "채널") {
      return css`
        color: #f08420 !important;
      `;
    }
  }};
`;

const ImageWrapper = styled.div`
  width: 88px;
  height: 58px;
  margin-left: auto;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid #dcdcdc;

  overflow: hidden;
  position: relative;

  @media screen and (min-width: 481px) {
    width: 140px;
    height: 80px;
    &.home {
      width: 88px;
      height: 58px;
    }
  }
`;
const Image = styled.div`
  background: url(${({ imageUrl }) => (imageUrl ? imageUrl : "")}) no-repeat;
  background-size: 100%;
  background-position: center;

  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
`;
