import jeboApi from "api/api";
import AuthService from "api/AuthService";
import SignUpAgreeLayer from "components/layers/sign/SignUpAgreeLayer";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import LoadingComponent from "components/templates/LoadingComponent";
import { Base64 } from "js-base64";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import SignUpComplete from "./../../components/layers/sign/SignUpComplete";

export default function NaverLoginSuccess() {
  const naver = window.naver;

  const history = useHistory();
  const { handleSignInApp } = useContext(signAppContext);
  const { mobileType } = useContext(isMobileContext);

  const [openTermsOfJebo, setOpenTermsOfJebo] = useState(false);
  const [infoStruct, setInfoStruct] = useState(null);

  const [openComplete, setOpenComplete] = useState(false);
  const [memberYn, setMemberYn] = useState("");

  useEffect(() => {
    setOpenTermsOfJebo(infoStruct ? true : false);
  }, [infoStruct]);

  const naverLogin = useMemo(
    () =>
      new naver.LoginWithNaverId({
        clientId: process.env.REACT_APP_NAVER_CLIENT_KEY,
        callbackUrl: `${process.env.REACT_APP_HOST}/naverLoginSuccess`,
        callbackHandle: true,
        isPopup: false,
      }),
    [naver]
  );

  const socialSignIn = useCallback(
    async (id, email, name, socialType, deviceId, fcmToken, agreeItems) => {
      try {
        const { headers } = await jeboApi.socialSignIn(
          id,
          email,
          name,
          socialType,
          deviceId,
          fcmToken,
          mobileType,
          agreeItems
        );

        if (mobileType === "android")
          AuthService.setAutoLoginToken(headers.refresh_token);

        handleSignInApp(
          headers.authorization,
          headers.refresh_token,
          headers.member_email,
          Base64.decode(headers.member_nickname).replace(
            /[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]/g,
            ""
          ),
          headers.grant_type,
          headers.member_uid,
          headers.social_yn,
          "naver"
        );
      } catch (error) {
        console.error("error : ", error);
      } finally {
        memberYn === "N"
          ? setOpenComplete(true)
          : history.replace({ pathname: "/" });
      }
    },
    [mobileType, history, memberYn, handleSignInApp]
  );

  const success = useCallback(() => {

    naverLogin.getLoginStatus(async (status) => {
      if (status) {
        const email = naverLogin.user.getEmail();
        const name = naverLogin.user.getNickName();
        const id = naverLogin.user.getId();

        let deviceId;
        let fcmToken;

        if (mobileType === "android") {
          let returnString = window.androidApp.getDeviceTokenInfo();

          deviceId = returnString.split("@@")[0];
          fcmToken = returnString.split("@@")[1];
        }

        if (!email) {
          alert("이메일은 필수 정보 입니다. 정보제공을 동의해주세요.");
          naverLogin.reprompt();
          return;
        }
        // 회원여부 질의
        const { data } = await jeboApi.existMemberYn(id, "naver");
        setMemberYn(data.existMemberYn);
        if (data.existMemberYn === "N") {
          setInfoStruct({
            id: id,
            email: email,
            name: name ? name : "",
            socialType: "naver",
            deviceId: deviceId,
            fcmToken: fcmToken,
          });
        } else if (data.existMemberYn === "G") {
          alert("재가입은 탈퇴일로 부터 7일 후에 가능합니다");
        } else if (data.existMemberYn === "F") {
          alert("영구정지 상태로 재가입이 불가합니다.");
        } else {
          socialSignIn(
            id,
            email,
            name ? name : "",
            "naver",
            deviceId,
            fcmToken
          );
        }
      } else {
        console.log("callback 처리 실패. error code : ", status);
      }
    });
  }, [mobileType, naverLogin, socialSignIn]);

  useEffect(() => {
    naverLogin.init();
    success();
  }, [naverLogin, success]);

  return openTermsOfJebo ? (
    <SignUpAgreeLayer
      socialSignIn={socialSignIn}
      infoStruct={infoStruct}
      setInfoStruct={setInfoStruct}
      setOpenTermsOfJebo={setOpenTermsOfJebo}
    />
  ) : openComplete ? (
    <SignUpComplete setOpenComplete={setOpenComplete} />
  ) : (
    <LoadingComponent />
  );
}
