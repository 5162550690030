import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { useContext } from "react";
import ZoomSlider from "react-instagram-zoom-slider";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player/lazy";
import SwiperCore, {
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import MediaBox from "./MediaBox";
import FeedDetailText from "./FeedDetailText";

SwiperCore.use([Pagination, Navigation, Keyboard, Mousewheel]);
export default function FeedDetailContent({ feed }) {
  const { isMobile } = useContext(isMobileContext);

  const { jeboMediaList: jeboMedia, social } = feed;

  const slides = jeboMedia.map((medium) => {
    if (medium.mediaType === "image") {
      return (
        <LazyLoadImage
          alt=""
          style={{
            width: "100%",
            height: "380px",
            objectFit: "contain",
            margin: "auto",
          }}
          src={process.env.REACT_APP_RESOURCE_HOST + medium.mediaUrl}
        />
      );
    } else {
      return (
        <ReactPlayer
          controls={true}
          controlsList="nodownload"
          loop={false}
          muted={true}
          autoPlay={false}
          playsInline={true}
          type="video/*"
          url={`${process.env.REACT_APP_RESOURCE_HOST + medium.mediaUrl}#t=0.5`}
          width="100%"
          height="380px"
          style={{
            width: "100%",
            height: "380px",
            objectFit: "contain",
            margin: "auto",
          }}
        />
      );
    }
  });

  return (
    <div className={isMobile ? "feed-photo" : "feed-photo pc"}>
      {/* {social && (
        <SocialLabel scType={social.scType && social.scType}>
          {social.scType === 1
            ? "테마"
            : social.scType === 2
            ? "캠페인"
            : social.scType === 3
            ? "채널"
            : "커뮤 니티"}
        </SocialLabel>
      )} */}
      <div
        className={
          jeboMedia && jeboMedia.length > 1 ? "photo multiple" : "photo"
        }
      >
        {jeboMedia.length >= 1 ? (
          <>
            {isMobile ? (
              <ZoomSlider slides={slides} />
            ) : (
              <Swiper
                className={"modal-pht-slider swiper-container active"}
                pagination={{ el: ".swiper-pagination" }}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                style={isMobile ? { overflow: "revert" } : null}
              >
                {jeboMedia.map((medium) => (
                  <SwiperSlide key={medium.mediaId}>
                    <MediaBox
                      mediaType={medium.mediaType}
                      mediaUrl={
                        process.env.REACT_APP_RESOURCE_HOST + medium.mediaUrl
                      }
                      enabledZoom={true}
                      mediaFit={false}
                    />
                  </SwiperSlide>
                ))}
                {jeboMedia.length > 1 && (
                  <div className="swiper-pagination"></div>
                )}
                {!isMobile && jeboMedia.length > 1 && (
                  <div className="swiper-button-next"></div>
                )}
                {!isMobile && jeboMedia.length > 1 && (
                  <div className="swiper-button-prev"></div>
                )}
              </Swiper>
            )}
          </>
        ) : (
          <FeedDetailText CurFeed={feed} />
        )}
      </div>
    </div>
  );
}

// const SocialLabel = styled.div`
//   position: absolute;
//   top: -1px;
//   right: 15px;
//   z-index: 1;
//   width: 45px;
//   height: 45px;

//   font-size: 12px;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   text-align: center;
//   padding: 0 6px 10px;
//   line-height: 13px;
//   font-weight: 500;
//   letter-spacing: -0.5px;

//   background: url(${({ scType }) => {
//       if (scType === 1) {
//         return Ltheme;
//       } else if (scType === 2) {
//         return Lcampaign;
//       } else if (scType === 3) {
//         return Lchannel;
//       } else if (scType === 4) {
//         return Lcommunity;
//       } else {
//         return "";
//       }
//     }})
//     no-repeat;
//   background-size: contain;
// `;
