import { activeLocationContext } from "components/providers/LocationContextProvider";
import { useContext, useRef } from "react";
import { useHistory } from "react-router-dom";

export default function MobileLocationSection({ setShowLocationSearchLayer }) {
  const { activeLocation } = useContext(activeLocationContext);

  const refSectionTab = useRef();

  return (
    <section className="section-location mobile" ref={refSectionTab}>
      <button
        type="button"
        className="btn-loc"
        onClick={(e) => {
          e.preventDefault();
          setShowLocationSearchLayer(true);
        }}
      >
        {activeLocation
          ? activeLocation.placeName
            ? activeLocation.placeName
            : activeLocation.addressName
            ? activeLocation.addressName
            : "주소 정보를 불러오는 중입니다.."
          : "위치 정보를 불러오는 중입니다.."}
      </button>
    </section>
  );
}
