import AttendancePopLayer from "components/layers/popMenu/AttendancePopLayer";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { Base64 } from "js-base64";
import React, { useContext } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

export function LegacySettingMenuSection({ activeMenu, setActiveMenu }) {
  const { myInfo } = useContext(signAppContext);
  const { mobileType, isMobile } = useContext(isMobileContext);

  const history = useHistory();
  return (
    <div className="s-list-ct">
      <div className="s-header">
        <button
          type="button"
          onClick={() => {
            isMobile && history.goBack();
          }}
          className="page-back mobile"
        ></button>
        <strong>설정</strong>
      </div>

      <div className="s-body">
        <div className="mobile s-profile">
          <div className="photo">
            <div className="profile-thumb">
              <span className="thumb">
                <img src={myInfo.imageUrl} alt="" />
              </span>
            </div>

            <strong>{`${myInfo.nickName} 님`}</strong>
          </div>
        </div>
        <div className="list">
          <button
            className={
              activeMenu === "profile" ? "setting-item active" : "setting-item"
            }
            onClick={(e) => {
              e.preventDefault();
              setActiveMenu("profile");
            }}
          >
            <span>프로필</span>
            <i className="icon-arrow-right32"></i>
          </button>
          <button
            className={
              activeMenu === "collectBadge"
                ? "setting-item active"
                : "setting-item"
            }
            onClick={(e) => setActiveMenu("collectBadge")}
          >
            <span>획득 배지</span>
            <i className="icon-arrow-right32"></i>
          </button>

          <button
            className={
              activeMenu === "pushAlarm"
                ? "setting-item active"
                : "setting-item"
            }
            onClick={(e) => setActiveMenu("pushAlarm")}
          >
            <span>푸시 알림</span>
            <i className="icon-arrow-right32"></i>
          </button>
          <button
            className={
              activeMenu === "blockMember"
                ? "setting-item active"
                : "setting-item"
            }
            onClick={(e) => setActiveMenu("blockMember")}
          >
            <span>차단목록</span>
            <i className="icon-arrow-right32"></i>
          </button>
          <button
            rel="noreferrer"
            className={
              activeMenu === "help" ? "setting-item active" : "setting-item"
            }
            onClick={(e) => {
              e.preventDefault();
              const url = "https://guide.jebo.io/faq/";
              if (mobileType === "ios") {
                const encodedUrl = Base64.encode(url);
                window.webkit.messageHandlers.openOuterLink.postMessage(
                  encodedUrl
                );
              } else {
                window.open(url);
              }
            }}
          >
            <span>자주 묻는 질문</span>
            <i className="icon-arrow-right32"></i>
          </button>
          <button
            rel="noreferrer"
            className={
              activeMenu === "policy" ? "setting-item active" : "setting-item"
            }
            onClick={(e) => setActiveMenu("aboutService")}
          >
            <span>약관 및 정책</span>
            <i className="icon-arrow-right32"></i>
          </button>

          <button
            rel="noreferrer"
            className={
              activeMenu === "blog" ? "setting-item active" : "setting-item"
            }
            onClick={(e) => {
              e.preventDefault();
              const url = "https://guide.jebo.io";
              if (mobileType === "ios") {
                const encodedUrl = Base64.encode(url);
                window.webkit.messageHandlers.openOuterLink.postMessage(
                  encodedUrl
                );
              } else {
                window.open(url);
              }
            }}
          >
            <span>블로그 방문하기</span>
            <i className="icon-arrow-right32"></i>
          </button>
          <button
            className={
              activeMenu === "accountManage"
                ? "setting-item active"
                : "setting-item"
            }
            onClick={(e) => setActiveMenu("accountManage")}
          >
            <span>계정관리</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default function SettingMenuSection({ activeMenu, setActiveMenu }) {
  const { myInfo } = useContext(signAppContext);
  const { mobileType, isMobile } = useContext(isMobileContext);

  const [viewAttendance, setViewAttendance] = useState(false);

  const history = useHistory();

  return (
    <div className="s-list-ct">
      <div className="s-header">
        <button
          type="button"
          onClick={() => {
            isMobile && history.goBack();
          }}
          className="page-back mobile"
        ></button>
        <strong>마이페이지</strong>
      </div>

      <div className="s-body">
        <ProfileContainer>
          <ImageSection onClick={(e) => setActiveMenu("myInfo")}>
            <Image src={myInfo.imageUrl} alt="" />
          </ImageSection>
          <NickNameSection onClick={(e) => setActiveMenu("myInfo")}>
            <NickName>{myInfo.nickName}&nbsp;</NickName>
            님. <br />
            오늘도 반가워요😀
          </NickNameSection>
          <AttendanceSection>
            <AttendanceSpan
              onClick={(e) => {
                e.preventDefault();
                setViewAttendance(true);
              }}
            >
              출석체크 하러 가기
            </AttendanceSpan>
            <NextArrow type="arrowType" style={{ marginTop: "2.5px" }} />
          </AttendanceSection>
        </ProfileContainer>
        <div className="list">
          <button
            className={
              activeMenu === "profile" ? "setting-item active" : "setting-item"
            }
            onClick={(e) => {
              e.preventDefault();
              setActiveMenu("profile");
            }}
          >
            <span>프로필</span>
            <i className="icon-arrow-right32"></i>
          </button>
          <button
            className={
              activeMenu === "myact" ? "setting-item active" : "setting-item"
            }
            onClick={(e) => setActiveMenu("myact")}
          >
            <span>나의 활동</span>
            <i className="icon-arrow-right32"></i>
          </button>
          <button
            className={
              activeMenu === "myattend" ? "setting-item active" : "setting-item"
            }
            onClick={(e) => setActiveMenu("myattend")}
          >
            <span>제보큐브 활동</span>
            <i className="icon-arrow-right32"></i>
          </button>
          <button
            className={
              activeMenu === "pushAlarm"
                ? "setting-item active"
                : "setting-item"
            }
            onClick={(e) => setActiveMenu("pushAlarm")}
          >
            <span>푸시 알림</span>
            <i className="icon-arrow-right32"></i>
          </button>
          <button
            className={
              activeMenu === "noticeList"
                ? "setting-item active"
                : "setting-item"
            }
            onClick={(e) => setActiveMenu("noticeList")}
          >
            <span>공지사항</span>
            <i className="icon-arrow-right32"></i>
          </button>
          {/* <button
            className={
              activeMenu === "blockMember"
                ? "setting-item active"
                : "setting-item"
            }
            onClick={(e) => setActiveMenu("blockMember")}
          >
            <span>차단목록</span>
            <i className="icon-arrow-right32"></i>
          </button> */}
          <button
            rel="noreferrer"
            className={
              activeMenu === "help" ? "setting-item active" : "setting-item"
            }
            onClick={(e) => {
              e.preventDefault();
              const url = "https://guide.jebo.io/faq/";
              if (mobileType === "ios") {
                const encodedUrl = Base64.encode(url);
                window.webkit.messageHandlers.openOuterLink.postMessage(
                  encodedUrl
                );
              } else {
                window.open(url);
              }
            }}
          >
            <span>자주 묻는 질문</span>
            <i className="icon-arrow-right32"></i>
          </button>
          <button
            rel="noreferrer"
            className={
              activeMenu === "policy" ? "setting-item active" : "setting-item"
            }
            onClick={(e) => setActiveMenu("aboutService")}
          >
            <span>약관 및 정책</span>
            <i className="icon-arrow-right32"></i>
          </button>

          <button
            rel="noreferrer"
            className={
              activeMenu === "blog" ? "setting-item active" : "setting-item"
            }
            onClick={(e) => {
              e.preventDefault();
              const url = "https://guide.jebo.io";
              if (mobileType === "ios") {
                const encodedUrl = Base64.encode(url);
                window.webkit.messageHandlers.openOuterLink.postMessage(
                  encodedUrl
                );
              } else {
                window.open(url);
              }
            }}
          >
            <span>블로그 방문하기</span>
            <i className="icon-arrow-right32"></i>
          </button>

          <button
            className={
              activeMenu === "accountManage"
                ? "setting-item active"
                : "setting-item"
            }
            onClick={(e) => setActiveMenu("accountManage")}
          >
            <span>계정관리</span>
          </button>
        </div>
      </div>
      {viewAttendance && (
        <AttendancePopLayer setViewAttendance={setViewAttendance} />
      )}
    </div>
  );
}

/* styled component 정의 */

const ProfileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 6%;
  height: 200px;
  border-bottom: solid 1px #dcdcdc;
`;

const ImageSection = styled.div`
  cursor: pointer;
  width: 40%;
`;

const Image = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 50%;
`;

const NickNameSection = styled.div`
  cursor: pointer;
  margin-top: 30px;
  line-height: 20px;
  width: 60%;
`;
const NickName = styled.strong`
  margin: auto;
  //line-height: 100px; //ProfileSection의 height의 1/2값
`;

const AttendanceSection = styled.div`
  cursor: pointer;
  height: 35px;
  background-color: #f5f5f5;
  border-radius: 30px;
  width: 90%;
  margin: auto;

  text-align: right;

  display: flex;
  padding-top: 10px;
`;

const AttendanceSpan = styled.span`
  display: table-cell;
  vertical-align: middle;
  margin-right: 10px;

  width: 90%;
`;

const NextArrow = styled.span`
  position: inherit;
  margin-right: 15px;
  width: 10px;
  height: 10px;
  border-top: 2px solid #999;
  border-right: 2px solid #999;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
`;
