import jeboApi from "api/api";
import SearchMapSection from "components/pages/search/SearchMapSection";
import { convenienceDataContext } from "components/providers/ConvenienceDataContextProvider";
import { AdsMapDataContext } from "components/providers/AdsMapContextProvider";
import { feedsContext } from "components/providers/FeedContextProvider";
import {
  activeLocationContext,
  currentLocationContext,
} from "components/providers/LocationContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import FeedContainer from "components/templates/FeedContainer";
import FeedSortTab from "components/templates/FeedSortTab";
import qs from "query-string";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import LinesEllipsis from "react-lines-ellipsis";
import { Link, useLocation, useParams } from "react-router-dom";

import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import InduceAppPopLayer from "components/layers/popMenu/InduceAppPopLayer";
import ConDataSection from "./ConDataSection";

const popMenuReducer = (state, action) => {
  switch (action.type) {
    case "DISPLAY_MENU":
      return {
        ...state,
        id: action.id,
        name: action.name,
      };
    case "CLEAR":
      return initialPopMenuState;

    default:
      return state;
  }
};

const initialPopMenuState = {
  id: "",
  name: "",
};

export default function FeedSection({ eventInfo }) {
  const END_LIMIT = 36;
  const { feeds, setFeeds } = useContext(feedsContext);
  const { myInfo } = useContext(signAppContext);
  const { activeLocation } = useContext(activeLocationContext);
  const { convenienceData } = useContext(convenienceDataContext);

  const [cursor, setCursor] = useState(0);
  const [lastFeedRef, inView] = useInView();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const params = useParams();
  const { keyword } = params;
  const location = useLocation();
  const { latitude, longitude, eventId, gubun, classNum } = qs.parse(
    location.search
  );

  const [recommendKeywords, setRecommendKeywords] = useState(null);
  const [notLoadedData, setNotLoadedData] = useState(false);
  const [sort, setSort] = useState("");

  const [popMenu, dispatchPopMenu] = useReducer(
    popMenuReducer,
    initialPopMenuState
  );

  /* codelua 검색에 지도를 달기 위해 추가 시작  */
  const decKeyword = decodeURIComponent(keyword);

  // 23.02.07 codelua 완전 자동화를 위해서는 아래것을 사용하는게 맞긴 하나. 최초 로딩시의 타이밍 문제가있어 일단 하드코딩 상태를 사용중
  // 반드시 최초 로딩에 대한 타이밍을 해결하여 아래 자동화 방식을 사용토록 해야 함.
  const conMapData = useMemo(() => {
    let searchMapData = null;
    convenienceData &&
      convenienceData.forEach((info) => {
        const arrDetail = info.codeDetail.split(",");
        if (arrDetail.includes(decKeyword)) {
          searchMapData = {};
          searchMapData.hashCode = info.hashCode;
          searchMapData.level = info.viewLevel;
          setSort("1");
        }
      });
    return searchMapData;
  }, [convenienceData, decKeyword]);

  const { isMobile, mobileType } = useContext(isMobileContext);
  const [searchPosition, setSearchPosition] = useState({
    latitude: latitude
      ? latitude
      : activeLocation
      ? activeLocation.latitude
      : 37.5546788388674,
    longitude: longitude
      ? longitude
      : activeLocation
      ? activeLocation.longitude
      : 126.970606917394,
  });
  /* 검색에 지도 달기 사전 처리 끝 이 주석 사이를 리팩토링 하면 됨 */
  const { adsMapData, loadingAdsMapData } = useContext(AdsMapDataContext);
  const [targetUrl, setTargetUrl] = useState(null);
  const [isAd, setIsAd] = useState(false);
  useEffect(() => {
    adsMapData &&
      adsMapData.forEach((info) => {
        if (info.gubun === gubun && info.classNum === classNum) {
          // info.actionCode로 어찌 할지는 이후 진행방향을 확인 후 !!
          setIsAd(true);
          setTargetUrl(info.adLink);

          /*
            1004,1005번 광고의 경우 광고를 통해 웹 진입 시 
            현 위치에 상관없이 강서구청을 중심좌표로 설정
          */
          ["1004", "1005"].includes(classNum) &&
            setSearchPosition({
              latitude: 37.55096731841639,
              longitude: 126.84953375407952,
            });
        }
      });
  }, [gubun, classNum, adsMapData]);

  //앱 유도 팝업 6시간에 한번 씩
  const EXPIRED_KEY = "induce_app_expired";
  const EXPIRED_TIME = localStorage.getItem(EXPIRED_KEY);

  const [openInduceApp, setOpenInduceApp] = useState(
    EXPIRED_TIME && EXPIRED_TIME > new Date() ? false : true
  );

  const kakaoMapContainer = useRef();
  const kakao = window.kakao;
  const [map, setMap] = useState(null);
  //feed의 위치 클릭 시 해당위치의 feed를 보여주지 않는 현상 수정 - 203
  //23.01.11 codelua 편의지도를 위해 체크 한줄 더 추가
  const searchtype =
    latitude && longitude && !conMapData
      ? "place"
      : eventId
      ? "테마&캠페인"
      : "";

  useEffect(() => {
    getRecommendList();
  }, []);

  useEffect(() => {
    setNotLoadedData(false);
  }, [location]);

  const getRecommendList = async () => {
    try {
      const {
        data: { searchHistoryList },
      } = await jeboApi.getRecommendSearchKeyword();

      setRecommendKeywords(JSON.parse(searchHistoryList));
    } catch (error) {
      if (error.response) {
        const {
          response: { data, status },
        } = error;
        console.log(status, data);
      }
    }
  };

  useEffect(() => {
    setFeeds([]);
    setCursor(0);
  }, [keyword, activeLocation, myInfo.interTag, sort, eventId, setFeeds]);

  const loadFeeds = useCallback(async () => {
    setLoading(true);
    try {
      // 23.02.23 codelua 검색창에 무리한 확장으로 집어넣은 기능이기에
      // 타이밍이 안맞으면 필요없는 데이터를 호출 할 수 있어서 리턴 시켜버림
      if (isMobile && conMapData && sort === "1") {
        setLoading(false);
        return;
      }
      const { status, data } = await jeboApi.searchFeedList(
        keyword ? decodeURIComponent(keyword) : "",
        myInfo.interTag,
        activeLocation.latitude,
        activeLocation.longitude,
        END_LIMIT,
        cursor,
        sort,
        searchtype, //feed의 위치 클릭 시 해당위치의 feed를 보여주지 않는 현상 수정 - 203
        eventId
      );

      if (status === 200) {
        const jeboPostArr = JSON.parse(data.jeboPost);
        setNotLoadedData(jeboPostArr.length < END_LIMIT ? true : false);

        if (cursor === 0) setFeeds(jeboPostArr);
        else setFeeds((prev) => prev.concat(jeboPostArr));
      } else if (status === 204) {
        console.log("no data to be loaded..");
        setNotLoadedData(true);
      }
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [
    isMobile,
    conMapData,
    cursor,
    activeLocation,
    keyword,
    eventId,
    myInfo.interTag,
    sort,
    searchtype,
    setFeeds,
  ]);

  useEffect(() => {
    activeLocation && loadFeeds();
  }, [activeLocation, loadFeeds]);

  useEffect(() => {
    if (inView && !error && !notLoadedData)
      setCursor((prev) => prev + END_LIMIT);
  }, [inView, error, notLoadedData]);

  const initKakaoMap = useCallback(() => {
    const coords = new kakao.maps.LatLng(latitude, longitude);
    const mapObj = new kakao.maps.Map(kakaoMapContainer.current, {
      center: coords,
      level: 4,
      draggable: false,
      zoomable: true,
      disableDoubleClick: true,
    });

    setMap(mapObj);

    // 마커가 표시될 위치입니다
    var markerPosition = new kakao.maps.LatLng(latitude, longitude);
    // 마커를 생성합니다
    var marker = new kakao.maps.Marker({
      position: markerPosition,
    });
    // 마커가 지도 위에 표시되도록 설정합니다
    marker.setMap(mapObj);
  }, [kakao, latitude, longitude]);

  useEffect(() => {
    if (latitude && longitude && !conMapData) {
      if (map) {
        map.setCenter(new kakao.maps.LatLng(latitude, longitude));
        var markerPosition = new kakao.maps.LatLng(latitude, longitude);
        // 마커를 생성합니다
        var marker = new kakao.maps.Marker({
          position: markerPosition,
        });
        marker.setMap(map);
        map.relayout();
      } else {
        kakao.maps.load(() => {
          initKakaoMap();
        });
      }
    } else {
      setMap(null);
    }
  }, [kakao, map, latitude, longitude, conMapData, initKakaoMap]);

  const onUpdateFeed = (updatedFeed) => {
    setFeeds((prev) =>
      prev.map((item) => {
        if (item.jeboId === updatedFeed.jeboId) {
          return Object.assign({}, item, updatedFeed);
        }
        return item;
      })
    );
  };

  const onBlockFeed = (memberUid) => {
    setFeeds((prev) => prev.filter((item) => item.memberUid !== memberUid));
  };

  const onDeleteFeed = (feed) => {
    setFeeds((prev) => prev.filter((item) => item.jeboId !== feed.jeboId));
  };

  return (
    <>
      {/* 2023.02.22 codelua 검색의 초기에 편의지도에 속하는지 판단 기준이 없다면 로딩을 띄우고 대기타봅시다.  */}
      {!convenienceData && !conMapData && (
        <div className="feed-spinner">
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
      {/* 피드에서 #편의검색어가 눌릴 시 lat,lng 를 qs로 던지기때문에 conMapData를 체크해줘야 Adress용  지도가 안나온다.  */}
      {!conMapData && latitude && longitude && (
        <section className="searchMap-ct">
          <div className="searchSection-map">
            <div className="map-container" ref={kakaoMapContainer}></div>
          </div>
        </section>
      )}
      <section className="section-feed">
        <div className="container search">
          <div
            className={
              eventId ? `campaign cont-sort search` : `cont-sort search`
            }
          >
            <FeedSortTab sort={sort} setSort={setSort} />
          </div>
          {isMobile &&
            mobileType === "browser" &&
            openInduceApp &&
            !loadingAdsMapData && (
              <InduceAppPopLayer
                setOpenInduceApp={setOpenInduceApp}
                expiredKey={EXPIRED_KEY}
                targetUrl={targetUrl}
              />
            )}
          {/* 23.02.23 codelua 편의지도 처리를 간결하게 1개 Section으로 변경처리함.  */}
          {conMapData && sort === "1" ? (
            isMobile ? (
              <SearchMapSection
                keyword={decKeyword}
                searchPosition={searchPosition}
                conMapData={conMapData}
                loadingAdsMapData={loadingAdsMapData}
                isAd={isAd}
              />
            ) : (
              <ConDataSection
                keyword={decKeyword}
                searchPosition={searchPosition}
                conMapData={conMapData}
                loadingAdsMapData={loadingAdsMapData}
                isAd={isAd}
              />
            )
          ) : (
            <div className="cont-feed">
              {feeds && feeds.length > 0 && (
                <div className="list">
                  {feeds.map((feed, index, arr) => (
                    <FeedContainer
                      key={index}
                      feed={feed}
                      popMenu={popMenu}
                      dispatchPopMenu={dispatchPopMenu}
                      onUpdateFeed={onUpdateFeed}
                      onDeleteFeed={onDeleteFeed}
                      onBlockFeed={onBlockFeed}
                      page="search"
                    />
                  ))}
                  <div ref={lastFeedRef}></div>
                </div>
              )}
            </div>
          )}

          {loading && !error && (
            <div className="feed-spinner">
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
        </div>

        {/* {!loading && feeds.length === 0 && ( */}
        {!loading && !conMapData && feeds.length === 0 && (
          <>
            <div className="emptySearch_container">
              <figure className="emptySearch_imgCt">
                <div className="emptySearch_img"></div>
              </figure>
              <h2 className="title">
                <span className="keyword">
                  <LinesEllipsis
                    maxLine={2}
                    ellipsis="..."
                    text={
                      keyword
                        ? decodeURIComponent(keyword)
                        : eventInfo
                        ? `[${eventInfo.eventName}]`
                        : ""
                    }
                  />
                </span>
                검색 결과가 없습니다.
                <p className="desc">
                  검색어를 바르게 입력하셨는지 확인하시거나 다른 검색어로 검색해
                  보세요.
                </p>
              </h2>
            </div>

            <div className="emptySearch_container search_popularKeyword">
              <h1 className="search_popularKeyword__title">인기 검색어</h1>
              <div className="search_popularKeyword__tagContainer">
                {recommendKeywords &&
                  recommendKeywords.length > 0 &&
                  recommendKeywords.map((item, idx) => {
                    return (
                      <Link
                        className="search_popularKeyword__tag"
                        to={`/search/${encodeURIComponent(
                          encodeURIComponent(item.keyword)
                        )}`}
                        replace={true}
                        key={idx}
                      >
                        <LinesEllipsis
                          maxLine={1}
                          ellipsis="..."
                          text={item.keyword}
                        />
                      </Link>
                    );
                  })}
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
}
