import { currentLocationContext } from "components/providers/LocationContextProvider";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

const debouncer = (() => {
  let debounceCheck;

  return {
    debounce(callback, milliseconds) {
      clearTimeout(debounceCheck);
      debounceCheck = setTimeout(() => {
        callback(...arguments);
      }, milliseconds);
    },
  };
})();

export default function LocationSearchContainer({
  locationInfo,
  setLocationInfo,
  locationInputStateMessage,
  setLocationInputStateMessage,
  openLocation,
  setOpenLocation,
  feedType,
}) {
  const kakao = window.kakao;

  const { getCurrentPosition, currentLocation } = useContext(
    currentLocationContext
  );

  const [input, setInput] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const [resultList, setResultList] = useState([]);
  const [keyword, setKeyword] = useState(
    locationInfo
      ? locationInfo.placeName
        ? `${locationInfo.placeName} (${locationInfo.address})`
        : locationInfo.address
      : ""
  );

  const [hashKeyword, setHashKeyword] = useState(
    locationInfo && locationInfo.placeName ? locationInfo.placeName : ""
  );
  const [activeLocation, setActiveLocation] = useState(
    feedType === 2 ? false : true
  );
  const [hashTagPlaceHolder, setHashTagPlaceHolder] = useState("");
  const [viewPlaceHolder, setViewPlaceHolder] = useState(
    locationInfo && locationInfo.placeName ? "hidden" : ""
  );

  const locationTagRef = useRef();
  const dropdownRef = useRef();

  useEffect(() => {
    (!resultList || resultList.length <= 0) && setCursor(-1);
  }, [resultList]);

  const searchAddrListFromRegionAddr = useCallback(
    (resultData) => {
      if (!keyword) return;

      const addressSearchObj = new kakao.maps.services.Geocoder();
      addressSearchObj.addressSearch(
        keyword,
        (result, status, pagination) => {
          if (status === kakao.maps.services.Status.OK) {
            const regionList =
              result && result.length > 0
                ? result.map((data) => {
                    return {
                      address_name: data.address_name ? data.address_name : "",
                      road_address_name: data.road_address
                        ? data.road_address.address_name
                        : "",
                      place_name: "",
                      x: data.x,
                      y: data.y,
                    };
                  })
                : null;
            setResultList(
              resultData && resultData.length > 0
                ? resultData.concat(regionList)
                : regionList
            );
          } else {
            if (resultData && resultData.length > 0) {
              setResultList(resultData);
            }
          }
        },
        { size: 10 }
      );
    },
    [kakao, keyword]
  );

  const searchAddrListFromKeyword = useCallback(() => {
    const placeSearchObj = new kakao.maps.services.Places();
    placeSearchObj.keywordSearch(
      keyword,
      (result, status, pagination) => {
        if (status === kakao.maps.services.Status.OK) {
          const placeList =
            result && result.length > 0
              ? result.map((data) => {
                  return {
                    address_name: data.address_name ? data.address_name : "",
                    road_address_name: data.road_address_name
                      ? data.road_address_name
                      : "",
                    place_name: data.place_name,
                    x: data.x,
                    y: data.y,
                  };
                })
              : null;
          searchAddrListFromRegionAddr(placeList);
        } else {
          console.log(status);
          status === kakao.maps.services.Status.ZERO_RESULT &&
            searchAddrListFromRegionAddr(null);
        }
      },
      { size: 10, page: 1 }
    );
  }, [kakao, keyword, searchAddrListFromRegionAddr]);

  useEffect(() => {
    keyword && setLocationInputStateMessage("");
    debouncer.debounce(() => {
      input && keyword && searchAddrListFromKeyword();
    }, 200);
  }, [keyword, input, setLocationInputStateMessage, searchAddrListFromKeyword]);

  const searchCoordsFromAddr = useCallback(
    (address, roadAddress, placeName, xAxis, yAxis, tags) => {
      const coords = new kakao.maps.LatLng(yAxis, xAxis);

      setInput(false);
      setResultList([]);
      setKeyword(placeName ? `${placeName} (${address})` : address);
      setLocationInfo({
        address,
        roadAddress,
        placeName,
        latitude: coords.getLat(),
        longitude: coords.getLng(),
        hashtags: tags,
      });
    },
    [kakao, setLocationInfo]
  );

  useEffect(() => {
    if (openLocation) {
      if (activeLocation) {
        // setLocationInfo(null);
        getCurrentPosition(false);
        setHashKeyword("");
        setViewPlaceHolder("");
      } else {
        const holderId = localStorage.getItem("hashTagPlaceHolder");
        if (!holderId || holderId === "0") {
          localStorage.setItem("hashTagPlaceHolder", "1");
          setHashTagPlaceHolder("#우리동네");
        } else if (holderId === "1") {
          localStorage.setItem("hashTagPlaceHolder", "0");
          setHashTagPlaceHolder("#방콕중");
        }
        setLocationInfo(null);
        // setHashKeyword("");
        // setInput(false);
        // setResultList([]);
        // setKeyword("");
      }
    } else {
      setLocationInfo(null);
      setInput(false);
      // setResultList([]);
      // setKeyword("");
      setActiveLocation(true);
    }
  }, [openLocation, activeLocation]);

  useEffect(() => {
    if (openLocation && currentLocation && !locationInfo) {
      const { address, roadAddress, placeName, latitude, longitude } =
        currentLocation;

      let tags = [];
      tags = makeLocationHashtag(address);
      if (placeName) {
        tags.unshift(`#${placeName.replaceAll(" ", "_")}`);
      }

      searchCoordsFromAddr(
        address,
        roadAddress,
        placeName,
        longitude,
        latitude,
        tags
      );
    }
  }, [openLocation, currentLocation, searchCoordsFromAddr]);

  // 0323 키보드네비게이션 이벤트 김진희 작성
  const keyboardNavigation = useCallback(
    (e) => {
      const maxCursor =
        resultList && resultList.length > 0 ? resultList.length - 1 : 0;

      switch (e.key) {
        case "ArrowDown":
          setCursor((prev) => (prev >= maxCursor ? 0 : prev + 1));
          break;
        case "ArrowUp":
          setCursor((prev) => (0 >= prev ? maxCursor : prev - 1));
          break;
        case "Escape":
          // setInput(false);
          // setResultList([]);
          // setLocationInfo(null);
          // setKeyword("");
          break;
        case "Enter":
          if (0 <= cursor && cursor <= maxCursor) {
            let tags = [];
            tags = makeLocationHashtag(resultList[cursor].address_name);
            if (resultList[cursor].place_name) {
              tags.unshift(
                `#${resultList[cursor].place_name.replaceAll(" ", "_")}`
              );
            }

            searchCoordsFromAddr(
              resultList[cursor].address_name,
              resultList[cursor].road_address_name,
              resultList[cursor].place_name,
              resultList[cursor].x,
              resultList[cursor].y,
              tags
            );
          }
          break;
        default:
          break;
      }
    },
    [cursor, resultList, searchCoordsFromAddr, setLocationInfo]
  );

  const makeLocationHashtag = (addressName) => {
    const addressNames = addressName.split(" ");
    let tags = [];
    if (addressNames && addressNames.length > 0) {
      const exceptAddressNumber = parseInt(
        addressNames[addressNames.length - 1].substring(0, 1)
      );
      if (!isNaN(exceptAddressNumber)) {
        addressNames.pop();
      }

      addressNames.reduce((acc, curr) => {
        tags.push(`#${curr}`);
        acc && tags.push(`#${acc}_${curr}`);
        return curr;
      }, "");
    }

    return tags;
  };

  return (
    <div
      className=" write-item"
      style={{ paddingBottom: locationInputStateMessage && "0px" }}
    >
      <div className="input-title">
        <strong className="ttl">위치</strong>
        {feedType !== 1 && openLocation && (
          <>
            <input
              type="checkbox"
              className="toggle-check"
              id="locationSearch"
              onChange={(e) => {
                setActiveLocation((prev) => !prev);
                setResultList([]);
                setKeyword("");
              }}
              checked={activeLocation}
            ></input>

            <label
              htmlFor="locationSearch"
              style={{ transform: "translateY(8px)", margin: "0px 10px" }}
            ></label>
          </>
        )}
        <button
          className="current-location"
          style={{ visibility: `${!activeLocation ? "hidden" : ""}` }}
          onClick={(e) => {
            setLocationInfo(null);
            getCurrentPosition(false);

            setOpenLocation(true);
          }}
        >
          현 위치
        </button>
      </div>
      <div className="input-group flex search">
        {activeLocation ? (
          <input
            type="text"
            name="location"
            autoCapitalize="none"
            aria-autocomplete="none"
            autoCorrect="off"
            autoComplete="off"
            spellCheck="false"
            className="input-control"
            placeholder="위치를 검색하세요."
            value={keyword}
            onChange={(e) => {
              setInput(true);
              setResultList([]);
              // setLocationInfo(null);
              setKeyword(e.target.value);
            }}
            onKeyUp={(e) => {
              resultList && resultList.length > 0 && keyboardNavigation(e);
            }}
          />
        ) : (
          <>
            <label
              htmlFor="locationTag"
              className={`location-tag ${viewPlaceHolder}`}
            >
              주소대신 <b>{hashTagPlaceHolder}</b> (으)로 해시태그를
              이용해보세요.
            </label>
            <input
              type="text"
              id="locationTag"
              ref={locationTagRef}
              onChange={(e) => {
                if (e.target.value.length > 15) return;
                const keyword = e.target.value.trim();
                setHashKeyword(keyword);
              }}
              onFocus={(e) => {
                setViewPlaceHolder("hidden");
              }}
              onBlur={(e) => {
                if (hashKeyword === "#" || !hashKeyword) {
                  setHashKeyword("");
                  setViewPlaceHolder("");
                } else if (!hashKeyword.includes("#")) {
                  //23.02.20 2myung3 - 피드 주소정보란에 '#'이 안붙는 현상 수정
                  setLocationInfo({
                    address: "",
                    roadAddress: "",
                    placeName: `#${hashKeyword}`,
                    latitude: NaN,
                    longitude: NaN,
                    hashtags: [`#${hashKeyword.replace(" ", "_")}`],
                  });
                  setHashKeyword(`#${hashKeyword}`);
                } else {
                  setLocationInfo({
                    address: "",
                    roadAddress: "",
                    placeName: hashKeyword,
                    latitude: NaN,
                    longitude: NaN,
                    hashtags: [hashKeyword.replace(" ", "_")],
                  });
                }
              }}
              value={hashKeyword}
            />
          </>
        )}
        {keyword && (
          <button
            className="clear-btn"
            onClick={(e) => {
              setInput(true);
              setResultList([]);
              setLocationInfo(null);
              setKeyword("");
            }}
          ></button>
        )}
        {resultList && resultList.length > 0 && (
          <div className="search-dropdown open">
            <ul className="search-dropdown-list" ref={dropdownRef}>
              {resultList.map((location, index) => {
                return (
                  <li
                    onMouseOver={() => setCursor(index)}
                    className={cursor === index ? "selected" : undefined}
                    key={index}
                  >
                    <button
                      onClick={() => {
                        let tags = [];
                        tags = makeLocationHashtag(location.address_name);
                        if (location.place_name) {
                          tags.unshift(
                            `#${location.place_name.replaceAll(" ", "_")}`
                          );
                        }

                        searchCoordsFromAddr(
                          location.address_name,
                          location.road_address_name,
                          location.place_name,
                          location.x,
                          location.y,
                          tags
                        );
                      }}
                    >
                      {location.place_name
                        ? `${location.place_name} (${location.address_name})`
                        : location.road_address_name
                        ? location.road_address_name
                        : location.address_name}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      {locationInputStateMessage && (
        <span className="invalid">{locationInputStateMessage}</span>
      )}
    </div>
  );
}
