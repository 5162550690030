export default function Sections({ children }) {
  return (
    <div className="contents-wrap dm">
      <div className="section-dm">
        <div className="container">
          <div className="dm-wrap">{children}</div>
        </div>
      </div>
    </div>
  );
}
