import jeboApi from "api/api";
import Layers from "components/layers/Layers";
import { signAppContext } from "components/providers/SignContextProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import MobileMyHistoryList from "./mobile/MobileMyHistoryList";

export default function MyActivityContainer() {
  const END_LIMIT = 9;

  const { handleSignOutApp, setSignLayer } = useContext(signAppContext);

  const [lastFeedRef, inView] = useInView({ threshold: 0 });
  const [cursor, setCursor] = useState(0);
  const [notLoadedData, setNotLoadedData] = useState(false);

  const [myActivities, setMyActivities] = useState([]);
  const [tabType, setTabType] = useState("COMMENT"); //COMMENT - 댓글, UP - 공감, ZZIM - 찜

  const [reloadSwitch, setReloadSwitch] = useState(true);
  const [error, setError] = useState(null);

  const history = useHistory();

  const loadMyActivity = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.loadMyActivity(
        tabType,
        END_LIMIT,
        cursor
      );
      if (status === 200) {
        const activities = JSON.parse(data.activities);

        setNotLoadedData(activities.length < END_LIMIT ? true : false);
        if (cursor === 0) setMyActivities(activities);
        else setMyActivities((prev) => prev.concat(activities));
      } else if (status === 204) {
        setNotLoadedData(true);
        console.log("no data to be loaded..");
      }
    } catch (error) {
      setError(error);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    }
  }, [cursor, reloadSwitch, history, handleSignOutApp, setSignLayer]);

  useEffect(() => {
    loadMyActivity();
  }, [loadMyActivity]);

  useEffect(() => {
    if (inView && !error && !notLoadedData) {
      setCursor((prev) => prev + END_LIMIT);
    }
  }, [inView]);

  useEffect(() => {
    setMyActivities([]);
    setReloadSwitch((prev) => !prev);
    setCursor(0);
  }, [tabType]);

  return (
    <Container>
      <Header>
        <Title>나의 활동</Title>
      </Header>
      <Body>
        <BodyContainer>
          <MenuSection>
            <TabName>
              <TabBtn
                onClick={(e) => {
                  e.preventDefault();
                  setTabType("COMMENT");
                }}
                active={tabType === "COMMENT" ? true : false}
                right={true}
              >
                댓글
              </TabBtn>
            </TabName>
            <TabName>
              <TabBtn
                onClick={(e) => {
                  e.preventDefault();
                  setTabType("UP");
                }}
                active={tabType === "UP" ? true : false}
                // right={true}
              >
                공감
              </TabBtn>
            </TabName>
            {/* <TabName>
                    <TabBtn
                      onClick={(e) => {
                        e.preventDefault();
                        setTabType("Z");
                      }}
                      active={tabType === "Z" ? true : false}
                    >
                      찜
                    </TabBtn>
                  </TabName> */}
          </MenuSection>
          <ContentsSection>
            {myActivities && myActivities.length > 0 ? (
              myActivities.map((activity, index) => {
                return (
                  <MobileMyHistoryList
                    key={index}
                    actInfo={activity}
                    majorType={tabType}
                    subType={activity.activityType}
                  />
                );
              })
            ) : (
              <NoData>
                {tabType
                  ? (tabType === "COMMENT"
                      ? "댓글/답글"
                      : tabType === "UP"
                      ? "공감"
                      : tabType === "ZZIM"
                      ? "찜"
                      : "") + " 활동 이력이 없습니다."
                  : "잘못된 접근 입니다."}
              </NoData>
            )}
            {/* {myActivities.length > 0 && <div ref={lastFeedRef}></div>} */}
          </ContentsSection>
        </BodyContainer>
        <Layers />
      </Body>
    </Container>
  );
}

//styled-component 정의
const Container = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  padding: 40px;
  overflow-y: auto;

  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`;

const Header = styled.div`
  margin-bottom: 40px;
`;

const Title = styled.strong`
  color: #000;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.04em;
`;

const Body = styled.div`
  height: calc(100% - 50px);
`;

const BodyContainer = styled.div`
  padding-bottom: 50px;
  width: 100%;
  height: 100%;
`;

const MenuSection = styled.div`
  width: auto;
  display: flex;
  margin-bottom: 15px;
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  height: 35px;
  /* border-bottom: 1px solid #dcdcdc; */
`;

const ContentsSection = styled.div`
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`;

const TabName = styled.div`
  display: grid;
  align-items: center;
  background-color: #fff;
  padding-left: 12px;
  padding-right: 12px;
  height: 100%;
  width: 50%;
  margin-bottom: 0;
  text-align: center;
  /* border: 1px solid #dcdcdc; */
  font-weight: ${(props) => (props.active ? "bold" : "")};
  border-right: ${(props) => (props.right ? "1px solid #dcdcdc" : "")};
`;

const TabBtn = styled.button`
  font-size: 15px;
  background: ${(props) =>
    props.active
      ? `linear-gradient(
    316.2deg,
    #3773ff -3.34%,
    #22e6f3 131.85%
  )`
      : "#fff"};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  border-radius: 13px;
  border: 1px solid #dcdcdc;
  width: 100%;
  height: 80%;
  margin: auto;

  font-weight: ${(props) => (props.actve ? "700 !important" : "400")};
`;

const NoData = styled.div`
  width: 100vw;
  height: 100vh;
  text-align: center;
  padding-top: 50%;
`;
