import React, { createContext } from "react";

export const SessionContext = createContext({
  onExpiredSession: () => {},
});

export default function SessionContextProvider({
  session,
  children: Components,
}) {
  return (
    <SessionContext.Provider value={session}>
      {Components}
    </SessionContext.Provider>
  );
}
