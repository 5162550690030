export default function CollectBadgeDetailMobile({
  handleBadgeClickMobile,
  setModalIsOpen,
  memberReward,
}) {
  const handleBackgroundClick = (e) => {
    e.target.classList.contains("badgeDetail_background") &&
      handleBadgeClickMobile(true);
  };

  return (
    <>
      <div
        className="badgeDetail_background mobile"
        onClick={handleBackgroundClick}
      >
        <div
          className={`badgeDetail_modal mobile ${setModalIsOpen && "active"}`}
        >
          <p className="showPercentText">
            {memberReward.rewardName !== "스타터" && (
              <span className="percent">
                상위 {memberReward.rewardPercent} % 획득
              </span>
            )}
          </p>
          <div className="badgeDetail">
            <img
              src={
                process.env.REACT_APP_RESOURCE_HOST +
                memberReward.rewardImagePath
              }
              alt="배지"
            ></img>
            <div className="badgeDetailTextBox">
              <p className="badgeDetail__title">{memberReward.rewardName}</p>
              <p className="badgeDetail__desc">{memberReward.rewardDesc}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
