import { feedCategoriesContext } from "components/providers/FeedContextProvider";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

export default function FeedMainTag({ feed }) {
  const history = useHistory();
  const { hashTag } = feed;
  const { feedCategories } = useContext(feedCategoriesContext);

  return (
    <div className="tags">
      {hashTag.split(",").map((hashTagSplit, index) => {
        return feedCategories.map((feedCategory) => {
          if (feedCategory.categoryName === hashTagSplit.trim()) {
            return (
              <button
                key={index}
                className={`tag type${feedCategory.categoryCode}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.push({
                    pathname: `/search/${encodeURIComponent(hashTagSplit)}`,
                  });
                }}
              >
                {hashTagSplit}
              </button>
            );
          }
          return null;
        });
      })}
    </div>
  );
}
