import React, { useState } from "react";

export default function HistorySearchKeywordBox({ handleSearchKeyword }) {
  const [historyKeywords, setHistoryKeywords] = useState(
    localStorage.getItem("searchHistory")
      ? JSON.parse(localStorage.getItem("searchHistory"))
      : []
  );

  const removeSearchHistory = (keyword) => {
    let storeKeywords = historyKeywords.filter((item) => keyword !== item);
    setHistoryKeywords(storeKeywords);
    localStorage.setItem("searchHistory", JSON.stringify(storeKeywords));
  };

  return (
    <div className="k-panel recent">
      <strong>최근 검색어</strong>
      <button
        className="delete-all"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setHistoryKeywords(null);
          localStorage.removeItem("searchHistory");
        }}
      >
        전체 삭제
      </button>
      <ul className="k-list">
        {historyKeywords &&
          historyKeywords.length > 0 &&
          historyKeywords.map((keyword, index) => (
            <li key={index}>
              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSearchKeyword(keyword);
                }}
              >
                {keyword}
              </a>
              <button
                className="delete"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeSearchHistory(keyword);
                }}
              >
                &times;
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
}
