import { createPortal } from "react-dom";
import styled, { css } from "styled-components";

export function ChannelPolicy() {
  return (
    <>
      <ol>
        <li>
          ∙ 제보큐브 ‘채널’은 특정 목적을 위한 게시물이 업로드 되는 공간입니다.
        </li>
        <li>
          ∙ 채널 개설은 누구나 요청할 수 있으며, 개설 즉시 운영 가능합니다.
        </li>
        <li>
          ∙ 개설된 채널 내에서 개설자는 게시물을 자유롭게 게시할 수 있습니다.
        </li>
        <li>
          ∙ 개설된 채널의 게시물은 모든 사용자의 홈 화면에도 동시 게시됩니다.
        </li>
        <li>
          ∙ 나만의 꿀팁, 강좌 같은 내가 자신 있는 분야의 유익한 정보를 나눌 수
          있습니다.
        </li>
        <li>
          ∙ 제보는 우수 채널을 발굴하여 보상을 지급하는 방법에 대해 고민하고,
          채널 활성화를 위해 노력하겠습니다.
        </li>
      </ol>
    </>
  );
}

export function CommunityPolicy() {
  return (
    <>
      <ol>
        <li>
          ∙ 제보큐브 ‘커뮤니티’는 특정 주제로 사용자들이 모이는 공간입니다.
        </li>
        <li>
          ∙ 커뮤니티 개설은 누구나 요청할 수 있으며, 개설 즉시 운영 가능합니다.
        </li>
        <li>
          ∙ 개설된 커뮤니티 내에서 사용자는 관심있는 커뮤니티에 가입하여
          활동합니다.
        </li>
        <li>
          ∙ 개설된 커뮤니티의 게시물은 모든 사용자의 홈 화면에도 동시
          게시됩니다.
        </li>
        <li>
          ∙ 우리지역 맛집, 레저 및 스포츠, 취미 등의 공동체 활동을 통해 유익한
          정보를 나눌 수 있습니다.
        </li>
      </ol>
    </>
  );
}

export function ThemePolicy() {
  return (
    <>
      <ol>
        <li>
          ∙ 제보큐브 ‘테마’는 특정한 시기나 이슈에 대해 사용자의 제보가 필요할
          때 활용되는 공간입니다.
        </li>
        <li>
          ∙ 테마 개설은 누구나 요청할 수 있으나, 제보 관리자의 승인 하에
          개설됩니다.
        </li>
        <li>∙ 테마 개설 승인 여부는 개설자에게 1:1 대화로 안내 드립니다.</li>
        <li>
          ∙ 개설된 테마 내에서 사용자는 특정 가입 절차 없이 게시물을 자유롭게
          게시할 수 있습니다.
        </li>
        <li>
          ∙ 개설된 테마의 게시물은 모든 사용자의 홈 화면에도 동시 게시됩니다.
        </li>
        <li>
          ∙ 특정 지역의 산불, 화재, 태풍, 폭우와 같은 재난 상황에 주로
          활용됩니다.
        </li>
      </ol>
    </>
  );
}

export function CampaignPolicy() {
  return (
    <>
      <ol>
        <li>
          ∙ 제보큐브 ‘캠페인’은 우리사회가 필요로 하는 다양한 목적을 위해
          활용되는 공간입니다.
        </li>
        <li>
          ∙ 캠페인 개설은 누구나 요청할 수 있으나, 제보 관리자의 승인 하에
          개설됩니다.
        </li>
        <li>∙ 캠페인 개설 승인 여부는 개설자에게 1:1 대화로 안내 드립니다.</li>
        <li>
          ∙ 개설된 캠페인 내에서 사용자는 특정 가입 절차 없이 게시물을 자유롭게
          게시할 수 있습니다.
        </li>
        <li>
          ∙ 개설된 캠페인의 게시물은 모든 사용자의 홈 화면에도 동시 게시됩니다.
        </li>
        <li>
          ∙ 탄소중립 실현, 이동 약자의 이동권 보장, 저출산 고령화 문제 등과 같은
          특한 목적을 위한 활동에 참여할 수 있습니다.
        </li>
      </ol>
    </>
  );
}

export default function SocialPolicy({ setCheckState, category }) {
  const { name, title } = category;
  return (
    ["channel", "community", "theme", "campaign"].includes(name) && (
      <SocialNotice>
        <Container>
          <DetailContainer>
            <HeaderSection>
              <Title>{title} 개설 유의사항</Title>
            </HeaderSection>
            <BodySection>
              {name === "channel" && <ChannelPolicy />}
              {name === "community" && <CommunityPolicy />}
              {name === "theme" && <ThemePolicy />}
              {name === "campaign" && <CampaignPolicy />}
              <Highlight>
                제보 게시물의 운영 정책 및 서비스 약관에 따라 유해한 게시물은
                관리자에 의해 제한될 수 있습니다.
              </Highlight>
              <InputContainer category={category.name}>
                <input
                  onClick={(e) => {
                    setCheckState((prev) => !prev);
                  }}
                  id="proposeSocialChk"
                  type="checkbox"
                />
                <Label htmlFor="proposeSocialChk">유의사항에 동의합니다.</Label>
              </InputContainer>
            </BodySection>
          </DetailContainer>
        </Container>
      </SocialNotice>
    )
  );
}
const SocialNotice = styled.div`
  width: inherit;
  height: 90%;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 100;
`;
const Container = styled.div`
  position: fixed;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 30px;
  height: 22rem;
  width: 90%;

  top: 20%;
  left: 5%;

  z-index: 3;

  @media screen and (min-width: 481px) {
    height: auto;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  }
`;

const DetailContainer = styled.div`
  //ScrollBar 숨기기
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`;

const HeaderSection = styled.p`
  position: absolute;
  width: 100%;
  height: 70px;
  font-size: 25px;
  font-weight: bold;
  background-color: #f5f5f5;
  border-radius: 30px 30px 0 0;
  text-align: center;
  padding: 25px;
`;

const Title = styled.strong`
  width: 100%;
`;

const BodySection = styled.div`
  padding: 5%;
  line-height: 1.2;
  margin-top: 70px;
  color: var(--text-defaultGrayColor);
  font-size: 12px;

  word-break: break-all;
  overflow-y: scroll;

  ol {
    list-style-position: inside;
    text-indent: -7px;
    padding-left: 7px;
  }
`;

const Highlight = styled.p`
  font-weight: bold;
  margin: 15px 0px;
`;

const InputContainer = styled.div`
  text-align: center;
  margin: 15px 0px;
  ${({ category }) => {
    if (category === "community") {
      return css`
        margin: 25px 0px;
      `;
    }
  }}
`;

const Label = styled.label`
  font-weight: bold;
`;
