import React from "react";


export default function AgreeToMarketingInfo({checkMarketingInfo, setCheckMarketingInfo, setMarketingInfo}){
    return(
        <div className="serviceAgreeItem">
            <input
                onChange={(e) => {
                setCheckMarketingInfo((current) => !current);
                }}
                id="serviceMarketingChk"
                type="checkbox"
                checked={checkMarketingInfo ? "checked" : ""}
            ></input>
            <label htmlFor="serviceMarketingChk">
                마케팅 정보 수집 및 이용동의<span>(선택)</span>
            </label>
            <button 
                className="serviceBtn"
                onClick={(e) => {
                    e.preventDefault();
                    setMarketingInfo((prev) => !prev);
                }}
                >전문 보기</button>
        </div>
    )
}