import jeboApi from "api/api";
import add_wh from "assets/imgs/icons/add_wh.png";
import img_pre from "assets/imgs/icons/img_pre.svg";
import { createPortal } from "react-dom";
import SocialPolicy from "components/layers/policy/SocialPolicy";
import { signAppContext } from "components/providers/SignContextProvider";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import LoadingComponent from "components/templates/LoadingComponent";
import Zoom from "react-medium-image-zoom";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import SocialConfirmPop from "./SocialConfirmPop";
import SocialCompletePop from "./SocialCompletePop";
//
import uuid from "react-uuid";
import SwiperCore, {
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
SwiperCore.use([Pagination, Navigation, Keyboard, Mousewheel]);
export default function CreateSocial({ category, addSocial }) {
  //
  const inputReducer = (state, action) => {
    switch (action.type) {
      case "INPUT_CHANGE":
        return { ...state, [action.name]: action.value };
      case "ADD_TAG":
        let splitTag = [];
        let replaceTag = action.tag.trim().replace(/,/g, " ");

        if (replaceTag.indexOf(" ")) {
          let spaceCheckTag = replaceTag.split(" ");

          splitTag = splitTag.concat(
            spaceCheckTag.filter((item, idx) => {
              return (
                spaceCheckTag.indexOf(item) === idx &&
                !state.tags.includes(item) &&
                item !== ""
              );
            })
          );
        }
        return {
          ...state,
          tags: state.tags.concat(splitTag),
          tag: "",
        };
      case "DELETE_TAG":
        return {
          ...state,
          tags: state.tags.filter((tag) => {
            return tag !== action.target;
          }),
        };
      case "CHANGE_THUMBNAIL":
        return {
          ...state,
          images: action.images,
        };
      case "ADD_IMAGES":
        return {
          ...state,
          images: state.images.concat(action.images),
        };
      case "DEL_IMAGE":
        const isThumbnail = action.target.thumbnail === "Y";

        const newImages = state.images.filter((image) => {
          URL.revokeObjectURL(action.target.url);
          return image !== action.target;
        });

        if (isThumbnail && newImages.length > 0) {
          newImages[0] = { ...newImages[0], thumbnail: "Y" };
        }

        return {
          ...state,
          images: newImages,
        };
      default:
        break;
    }
  };

  const intialInputState = {
    title: "",
    description: "",
    tag: "",
    tags: [],
    images: [],
  };

  const debouncer = (() => {
    let debounceCheck;

    return {
      debounce(callback, milliseconds) {
        clearTimeout(debounceCheck);
        debounceCheck = setTimeout(() => {
          callback(...arguments);
        }, milliseconds);
      },
    };
  })();

  const MAX_LENGTH = 400;
  const MAX_ROWS = 10;
  //
  const { isMobile } = useContext(isMobileContext);
  const { handleSignOutApp } = useContext(signAppContext);
  const history = useHistory();

  const [confirmLayer, setConfirmLayer] = useState(false);

  const [inputStateMessage, setInputStateMessage] = useState("");
  const [titleInputStateMessage, setTitleInputStateMessage] = useState("");
  const [descInputMessage, setDescInputMessage] = useState("");

  const [inputState, dispatch] = useReducer(inputReducer, intialInputState);
  const { title, description, tag, tags, images } = inputState;

  const [loading, setLoading] = useState(false);
  const [checkState, setCheckState] = useState(true);
  const onChange = (e) => {
    const { name, value } = e.target;
    // 한글자 남았을 때 지워지지 않는 문제 23.04.11 ms
    // value.trim() !== "" && dispatch({ type: "INPUT_CHANGE", name, value });
    dispatch({ type: "INPUT_CHANGE", name, value });
  };

  const [confirmCompleteLayer, setConfirmCompleteLayer] = useState(false);
  const [confirmType, setConfirmType] = useState("0");
  const confirmMessage = {
    0: "개설되었습니다",
  };

  const addTag = () => {
    tag.trim() !== "" && dispatch({ type: "ADD_TAG", tag });
  };

  const deleteTag = (tag) => {
    dispatch({ type: "DELETE_TAG", target: tag });
  };

  const requiredInputCheck = () => {
    if (title.trim() === "") {
      setInputStateMessage("제목을 입력해주세요.");
      return false;
    }
    if (description.trim() === "") {
      setInputStateMessage("설명을 입력해주세요.");
      return false;
    }
    if (tags.length <= 0) {
      setInputStateMessage("태그를 입력해주세요");
      return false;
    }
    if (images.length <= 0) {
      setInputStateMessage("대표 사진을 첨부해주세요.");
      return false;
    }
    return true;
  };

  const handleValidTitle = useCallback(async () => {
    try {
      const response = await jeboApi.checkDuplicationSocialTitle(
        title,
        category.type
      );
      if (response.status === 200) {
        setTitleInputStateMessage("");
      }
    } catch (error) {
      if (error.response) {
        const {
          response: { data, status },
        } = error;
        if (status === 409) {
          setTitleInputStateMessage("이미 사용 중인 제목입니다.");
        } else {
          console.log(data.errorDescription);
        }
      }
    }
  }, [title]);

  useEffect(() => {
    debouncer.debounce(handleValidTitle, 1000);
  }, [title, handleValidTitle]);

  useEffect(() => {
    const length = description.replace(/\n/g, "").length;
    const rows = description.split(/\n/g).length;

    if (length > MAX_LENGTH || rows > MAX_ROWS) {
      length > MAX_LENGTH
        ? setDescInputMessage("상세내용은 400자 이내로 입력해주세요.")
        : setDescInputMessage("개행은 10줄까지 가능합니다.");
    } else {
      setDescInputMessage("");
    }
  }, [description]);

  const onChangeRadioBtn = (e) => {
    const tempMedia = images.slice();
    tempMedia.map((medium, index) => {
      if (String(index) === String(e.target.value)) {
        medium.changeThumbnail = true;
        return (medium.thumbnail = "Y");
      } else return (medium.thumbnail = "N");
    });
    dispatch({
      type: "CHANGE_THUMBNAIL",
      images: tempMedia,
    });
  };

  const handleFileValidate = (file) => {
    if (!file.type.includes("image")) {
      alert("이미지 파일만 업로드 가능합니다.");
      return false;
    }
    if (file.size > 1024 * 1024 * 50) {
      alert("50MB 이하의 파일만 업로드 가능합니다.");
      return false;
    }

    if (images && images.length >= 4) {
      alert("최대 4개까지 첨부할 수 있습니다.");
      return false;
    }

    return true;
  };

  const handleFileUpload = (e) => {
    let files = Array.from(e.target.files);

    if (files.length > 4) {
      files = files.filter((_, index) => index < 4);
      alert("선택은 최대 4개까지 가능합니다.");
      return;
    }

    const addImage = files
      .filter((file) => handleFileValidate(file))
      .map((file, index) => {
        return {
          id: uuid(),
          file: file,
          url: URL.createObjectURL(file),
          type: file.type.split("/")[0],
          upload: true,
          thumbnail: images.length === 0 && index === 0 ? "Y" : "N",
          changeThumbnail: false,
        };
      });

    if (addImage && addImage.length > 0) {
      dispatch({
        type: "ADD_IMAGES",
        images: addImage,
      });
    }
  };

  const removeMedium = (removeMedia) => {
    dispatch({
      type: "DEL_IMAGE",
      target: removeMedia,
    });
  };

  const handleCreateSocial = async () => {
    if (loading) return;
    try {
      setLoading(true);

      if (title.trim() === "") {
        // setRetryState("empty");
        // setConfirmLayer(true);
        return false;
      }

      const {
        status,
        data: { socialData },
      } = await jeboApi.createSocial(
        images,
        category.type,
        title,
        description,
        tags ? tags.join(" ") : ""
      );

      if (status === 200) {
        setConfirmType(0);
        setConfirmCompleteLayer(true);
        // alert("개설되었습니다.");
        const data = JSON.parse(socialData);
        if (data) {
          //console.log(data);
          addSocial(data);
          history.replace({
            pathname: `/social/${category.name}/${data.scId}`,
          });
        } else history.goBack();
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          //setSignLayer("signIn");
          //history.replace({ pathname: "/" });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return createPortal(
    <>
      <Container>
        <Header>
          <BackButton onClick={history.goBack} />
          <HeaderTitle>{category.title} 개설하기</HeaderTitle>
        </Header>
        <Main>
          <HeaderWeb>
            <HeaderWebTitle>{category.title} 개설하기</HeaderWebTitle>
          </HeaderWeb>
          {checkState && (
            <SocialPolicy setCheckState={setCheckState} category={category} />
          )}
          <AddSocialInputContainer>
            <AddSocialInput
              type="text"
              onChange={onChange}
              name="title"
              value={title}
              maxLength={100}
              placeholder={`${category.title} 제목을 입력해주세요. (공백 포함 100자)`}
            />
            {titleInputStateMessage && (
              <InvalidMessage style={{ marginLeft: "0" }}>
                {titleInputStateMessage}
              </InvalidMessage>
            )}
            <textarea
              placeholder={`${category.title}의 상세내용을 자세히 작성할 수록 사용자의 참여도가 높아져요`}
              name="description"
              onChange={onChange}
              value={description}
            ></textarea>
            {/* <div className="textBox" contentEditable={true}>
            테마의 상세내용을 자세히 작성할 수록 사용자의 참여도가 높아져요
          </div> */}
            {descInputMessage !== "" && (
              <InvalidMessage style={{ marginLeft: "0" }}>
                {descInputMessage}
              </InvalidMessage>
            )}
          </AddSocialInputContainer>

          <InfoInputContainer>
            <AddInputTitle>
              <strong>제보태그</strong>
            </AddInputTitle>
            <div className="inputBox tags">
              <AddSocialInput
                type="text"
                placeholder="해당되는 태그를 작성해주세요.(필수)"
                name="tag"
                onChange={onChange}
                maxLength={10}
                value={tag}
              />
              <AddSocialButton
                onClick={() => {
                  tag.length <= 30 && addTag();
                }}
              >
                추가
              </AddSocialButton>
            </div>
            {tag.length > 30 && (
              <InvalidMessage style={{ marginLeft: "0" }}>
                한 번에 입력 가능한 글자 수는 30글자 입니다.
              </InvalidMessage>
            )}
            <div className="inputTagBox">
              {tags.map((tag, index) => (
                <p key={index}>
                  <DeleteButton
                    left={tag.length}
                    onClick={(e) => {
                      e.preventDefault();
                      deleteTag(tag);
                    }}
                  ></DeleteButton>
                  {tag}
                </p>
              ))}
            </div>
          </InfoInputContainer>
          <InfoInputContainer>
            <AddInputTitle>
              이미지 등록 <span>*이미지는 최대 4장 까지 등록 됩니다.</span>
            </AddInputTitle>
            <StyledSwiper>
              <Swiper
                className="input-group image file-swiper swiper-container active"
                pagination={{ el: ".swiper-pagination", clickable: true }}
                freeMode={true}
                spaceBetween={10}
                slidesPerView="auto"
              >
                <SwiperSlide className="img-item">
                  <input
                    type="file"
                    className="file-upload"
                    id="fileupload"
                    multiple
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    onInput={handleFileUpload}
                    accept="image/*;capture=camera"
                  />
                  <label htmlFor="fileupload"></label>
                </SwiperSlide>
                {images.map((medium, index) => (
                  <SwiperSlide
                    className="img-item remove-radio"
                    key={medium.id}
                  >
                    <button
                      className="img-del"
                      type="button"
                      onClick={(e) => removeMedium(medium)}
                    >
                      <i className="icon-cross3"></i>
                    </button>
                    {!isMobile ? (
                      <>
                        <Zoom>
                          <img
                            src={medium.url}
                            alt=""
                            width={"72px"}
                            height={"72px"}
                            style={
                              medium.thumbnail === "Y"
                                ? {
                                    border: "3px solid #3773ff",
                                    borderRadius: "3px",
                                  }
                                : null
                            }
                          />
                        </Zoom>
                        <PickDiv>
                          <input
                            className="pickInput"
                            type="radio"
                            name="fisrtImage"
                            id={medium.url}
                            value={index}
                            onChange={onChangeRadioBtn}
                            checked={medium.thumbnail === "Y" ? true : false}
                          />
                          <label
                            className="pickLabel"
                            htmlFor={medium.url}
                          ></label>
                        </PickDiv>
                      </>
                    ) : (
                      <>
                        <img
                          src={medium.url}
                          alt=""
                          width={"72px"}
                          height={"72px"}
                          style={
                            medium.thumbnail === "Y"
                              ? {
                                  // border: "3px outset red",
                                  border: "3px solid #3773ff",
                                  borderRadius: "3px",
                                }
                              : null
                          }
                        />
                        <PickDiv>
                          <input
                            className="pickInput"
                            type="radio"
                            name="fisrtImage"
                            id={medium.url}
                            value={index}
                            onChange={onChangeRadioBtn}
                            checked={medium.thumbnail === "Y" ? true : false}
                          />
                          <label
                            className="pickLabel"
                            htmlFor={medium.url}
                          ></label>
                        </PickDiv>
                      </>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            </StyledSwiper>
          </InfoInputContainer>
          {inputStateMessage && (
            <InvalidMessage>{inputStateMessage}</InvalidMessage>
          )}

          <BtnContainer>
            <CancleSocialButton
              className="cancleBtn"
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              취소하기
            </CancleSocialButton>
            <CancleSocialButton
              onClick={(e) => {
                e.preventDefault();
                if (
                  !requiredInputCheck() ||
                  titleInputStateMessage ||
                  descInputMessage
                )
                  return false;

                setInputStateMessage("");
                setConfirmLayer(true);
              }}
              className="addSocialBtn makeBtn"
            >
              개설하기
            </CancleSocialButton>
          </BtnContainer>

          {confirmLayer && (
            <SocialConfirmPop
              title={`[${category.title}]${title}`}
              message="개설하시겠습니까?"
              onClick={() => {
                setConfirmLayer(false);
                handleCreateSocial();
              }}
              onCancel={() => {
                setConfirmLayer(false);
              }}
            />
          )}
        </Main>
        {confirmCompleteLayer && (
          <SocialCompletePop
            // title={social.title}
            category={category}
            message={confirmMessage[confirmType]}
            onClick={setConfirmCompleteLayer(false)}
          />
        )}
      </Container>
      {loading && (
        <div className="modal-wrap open">
          <LoadingComponent />
        </div>
      )}
    </>,
    document.getElementById("modal")
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  padding-bottom: 50px;
  background: #ffffff;

  @media screen and (min-width: 481px) {
    background-color: rgba(0, 0, 0, 0.5);
    padding-bottom: 0;
  }
`;

const Main = styled.main`
  width: 100%;
  height: 100%;

  padding-top: 50px;

  /* 스크롤 넣었음 문제가 발생할까요? */
  /* overflow-y: scroll; */

  @media screen and (min-width: 481px) {
    /* border: 3px dashed blue; */
    background-color: #fff;
    width: 700px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
  }
`;

const Header = styled.div`
  height: 48px;
  position: fixed;
  left: 0;
  right: 0;

  padding: 0 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-bottom: solid 1px #dcdcdc;
  background-color: #fff;

  @media screen and (min-width: 481px) {
    display: none;
  }
`;

const BackButton = styled.button`
  background: url(${img_pre}) no-repeat;
  border: none;

  width: 10%;
  height: 70%;

  @media screen and (min-width: 481px) {
    width: 25px;
    height: 25px;
  }
`;

const HeaderTitle = styled.h1`
  width: 80%;
  height: 70%;
  text-align: center;
  line-height: 30px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const HeaderWeb = styled.div`
  padding: 0 10px;

  display: flex;
  /* justify-content: center; */
  align-items: center;

  border-bottom: solid 1px #dcdcdc;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const HeaderWebTitle = styled.h1`
  height: 30px;
  line-height: 25px;
  justify-self: center;
  margin: 0 auto;
`;

const AddSocialInputContainer = styled.div`
  padding: 0 7px;
  margin-top: 30px;
  margin-bottom: 20px;
  input[type="text"],
  textarea,
  .textBox {
    border: 1px solid #9d9d9d;
    border-radius: 4px;
    width: 100%;
    padding: 10px 7px;

    &::placeholder {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #9d9d9d;
    }
  }
  input[type="text"] {
    height: 36px;
    margin-bottom: 11px;
  }
  textarea,
  .textBox {
    height: 200px;

    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #000;
  }
`;

const AddSocialInput = styled.input`
  border: 1px solid #9d9d9d;
  border-radius: 4px;
  width: 100%;
  padding: 10px 7px;
  margin-right: 15px;
  height: 36px;

  &::placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #9d9d9d;
  }

  margin-bottom: 11px;
`;

const InfoInputContainer = styled.div`
  width: 100%;
  padding: 0 7px;
  margin-bottom: 14px;
  .inputBox {
    display: flex;
    &.tags {
      padding-bottom: 5px;
    }
  }
  .inputTagBox {
    display: flex;
    flex-wrap: wrap;
    p {
      padding: 6.5px 11px;
      margin-right: 4px;
      margin-bottom: 6px;
      flex-grow: 0 0 auto;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      color: #5293c5;
      border: 1px solid #5293c5;
      border-radius: 100px;

      position: relative;
    }
  }
`;

const AddSocialButton = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 7px;

  height: 36px;

  background: #9d9d9d;
  border: 1px solid #9d9d9d;
  border-radius: 4px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #ffffff;
  &.makeBtn {
    margin: 0 24px 15px 24px;
    /* width: 313px; */
    height: 50px;
    left: 24px;
    top: 609px;

    background: #014681;
    border-radius: 4px;
  }
`;
const CancleSocialButton = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 7px;

  height: 36px;

  background: #9d9d9d;
  border: 1px solid #9d9d9d;
  border-radius: 4px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #ffffff;

  cursor: pointer;
  &.makeBtn {
    margin: 0 24px 15px 24px;
    width: 50%;
    flex: 0 0 40%;
    height: 50px;

    background: #014681;
    border-radius: 4px;
  }
  &.cancleBtn {
    width: 50%;
    flex: 0 0 40%;
    height: 50px;
  }
`;
const BtnContainer = styled.div`
  margin: 0 10px;
  display: flex;
  justify-content: center;
`;

const DeleteButton = styled.button`
  position: absolute;

  /* margin-left: ${(props) =>
    `${props.left ? `${props.left * 7 - 4}px` : `auto`}`};
  margin-top: -10px; */

  top: -6px;
  right: -6px;

  width: 17px;
  height: 17px;
  line-height: 15px;

  border-radius: 50%;

  z-index: 2;
  border: none;
  background: url(/static/media/clear.53950f3c.svg) no-repeat;
  color: #fff;
`;

const AddInputTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;

  & span {
    font-weight: 400;
    font-size: 11px;
  }
`;

const InvalidMessage = styled.p`
  color: #cf514a;
  font-size: 12px;
  margin: 8px 10px 15px 10px;
`;

const StyledSwiper = styled.div`
  .input-group.image {
    height: 100px;
    overflow: hidden;
  }

  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }

  .img-del {
    position: absolute;
    right: 12px;
    top: 5px;
    width: 17px;
    height: 17px;
    line-height: 15px;
    border-radius: 50%;
    z-index: 2;
    padding: 0;
    margin: 0;
    border: none;
    background: url(/static/media/clear.53950f3c.svg) no-repeat;
    color: #fff;
  }

  .img-item {
    width: 80px;
    height: 80px;

    margin-right: 10px;
    position: relative;
  }

  & input {
    display: none;
  }

  & input + label {
    border-radius: 10px;
    display: block;
    width: 90%;
    height: 90%;
    background: #eeeeee;
    position: relative;
    cursor: pointer;

    &::before {
      content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAOElEQVQ4jWP8//8/AxEApoiRkFImYkwjBYwaOGogGYAFKdESAwiqpYkLCWan0aw3auCQMpCBgQEAEaYHKMxOq4sAAAAASUVORK5CYII=);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const PickDiv = styled.div`
  position: absolute;
  z-index: 8;
  width: 100%;
  bottom: -1em;
  height: 3em;
  left: 10px;

  .pickLabel {
    z-index: 9;
    width: 100% !important;
    height: 45% !important;
    bottom: 4px !important;
    background-color: transparent;
    &::before {
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      /* border: solid 1px #c4c4c4; */
      border: solid 1px #c4c4c4;
      border-radius: 50%;
      margin-right: 2em;
      vertical-align: middle;
      position: relative;
      left: 31px !important;
      top: 15px !important;
    }

    &::after {
      content: "";
      top: 9px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: #3773ff;
      left: 45px !important;
    }
  }
`;
