import { useEffect, useState } from "react";

const useScrollPosition = (initPosition) => {
  const [scrollPosition, setScrollPosition] = useState(
    initPosition ? initPosition : 0
  );

  useEffect(() => {
    const updatePosition = () => {
      //console.log("listen", window.scrollY);
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", updatePosition);
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return scrollPosition;
};

export default useScrollPosition;
