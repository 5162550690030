import jeboApi from "api/api";
import AlertLayer from "components/layers/popMenu/AlertLayer";
import ConfirmLayer from "components/layers/popMenu/ConfirmLayer";
import { signAppContext } from "components/providers/SignContextProvider";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function PasswordChangeContainer({ setActiveMenu }) {
  const { handleSignOutApp } = useContext(signAppContext);
  const history = useHistory();

  //패스워드와 패스워드 확인이 일치하는지 -> 가입 시 체크
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);
  const [prePassword, setPrePassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  const [showConfirmLayer, setShowConfirmLayer] = useState(false);
  const [showAlertLayer, setShowAlertLayer] = useState(false);

  const [prePasswordInputStateMessage, setPrePasswordInputStateMessage] =
    useState("");
  const [passwordInputStateMessage, setPasswordInputStateMessage] =
    useState("");
  const [
    confirmPasswordInputStateMessage,
    setConfirmPasswordInputStateMessage,
  ] = useState("");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  // useEffect(() => {
  //   document.getElementsByTagName("html")[0].classList.add("hidden");
  //   window.document.body.classList.add("hidden");
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("hidden");
  //     window.document.body.classList.remove("hidden");
  //   };
  // }, []);

  useEffect(() => {
    if (prePassword) setPrePasswordInputStateMessage("");
  }, [prePassword]);

  useEffect(() => {
    description && title && setShowAlertLayer(true);
  }, [title, description]);

  useEffect(() => {
    if (password) {
      if (password.length < 6 || password.length > 20) {
        setPasswordInputStateMessage("패스워드는 6 ~ 20자 이내로 입력하세요.");
        setValidPassword(false);
      } else if (
        !/[a-zA-Z]/g.test(password) ||
        !/[0-9]/g.test(password) ||
        !/[\\{\\}\\[\]\\/?.,;:|\\)*~`!^\-_+<>@\\#$%&\\=\\(\\'\\"]/g.test(
          password
        ) ||
        /[\s]/g.test(password)
      ) {
        setPasswordInputStateMessage(
          "영문, 숫자, 특수 문자를 조합하여 6 ~ 20자 이내로 작성하세요."
        );
        setValidPassword(false);
      } else {
        setPasswordInputStateMessage("");
        setValidPassword(true);
      }
    }
  }, [password]);

  useEffect(() => {
    if (password && confirmPassword) {
      if (password === confirmPassword) {
        setConfirmPasswordInputStateMessage("");
        setIsConfirmPassword(true);
      } else {
        setConfirmPasswordInputStateMessage(
          "패스워드가 일치하지 않습니다. 다시 한 번 확인해주세요."
        );
        setIsConfirmPassword(false);
      }
    }
  }, [confirmPassword, password]);

  const handleUpdatePassword = async () => {
    try {
      const { status } = await jeboApi.updateMemberPassword(
        prePassword,
        password
      );
      if (status === 200) {
        setTitle("비밀번호 변경");
        setDescription("완료되었습니다.");
      } else {
        setTitle("진행 중 오류가 발생했습니다.");
        setDescription("관리자에게 문의해주세요.");
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 400)
          setPrePasswordInputStateMessage(
            error.response.data?.errorDescription
          );
        else if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          history.replace({ pathname: "/" });
        } else
          setPrePasswordInputStateMessage(
            "서버에러. 관리자에게 문의 해주세요."
          );
      }
    } finally {
      setPrePassword("");
      setConfirmPassword("");
      setPassword("");
    }
  };

  return (
    <>
      <div className="modal-wrap open">
        <div className="modal modal-resign open" id="modal-resign">
          <div className="modal-header">
            <button
              type="button"
              className="page-back mobile"
              onClick={(e) => {
                e.preventDefault();
                // history.replace({ pathname: "/setting" });
                history.goBack();
              }}
            ></button>
            <strong className="resign-title">비밀번호 변경</strong>
            <button
              className="modal-close pc"
              onClick={(e) => {
                e.preventDefault();
                history.replace({ pathname: "/setting" });
              }}
            >
              <i className="icon-cross1"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="info-wrap">
              {showConfirmLayer && (
                <ConfirmLayer
                  setShowConfirmLayer={setShowConfirmLayer}
                  callback={handleUpdatePassword}
                  title="비밀번호를 수정 하시겠습니까?"
                  description=""
                  btnText="수정하기"
                  isConfirm={true}
                />
              )}
              {showAlertLayer && (
                <AlertLayer
                  setShowAlertLayer={setShowAlertLayer}
                  title={title}
                  description={description}
                  callback={() => {
                    history.replace({ pathname: "/setting" });
                  }}
                />
              )}
              <div className="write-item org">
                <div className="input-title">
                  <strong className="ttl">현재 비밀번호</strong>
                </div>
                <div className="input-group">
                  <input
                    type="password"
                    className="input-control"
                    value={prePassword}
                    onChange={(e) => setPrePassword(e.target.value)}
                  />
                  {prePasswordInputStateMessage && (
                    <span className="invalid">
                      {prePasswordInputStateMessage}
                    </span>
                  )}
                </div>
              </div>
              <div className="write-item">
                <div className="input-title">
                  <strong className="ttl">새 비밀번호</strong>
                </div>
                <div className="input-group">
                  <input
                    type="password"
                    className="input-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passwordInputStateMessage && (
                    <span className="invalid">{passwordInputStateMessage}</span>
                  )}
                </div>
              </div>
              <div className="write-item">
                <div className="input-title">
                  <strong className="ttl">새 비밀번호 확인</strong>
                </div>
                <div className="input-group">
                  <input
                    type="password"
                    className="input-control"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                  {confirmPasswordInputStateMessage && (
                    <span className="invalid">
                      {confirmPasswordInputStateMessage}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="resign-footer">
              <button
                className="input-btn"
                onClick={(e) => {
                  e.preventDefault();

                  if (!prePassword) {
                    setPrePasswordInputStateMessage(
                      "현재 비밀번호를 입력하세요."
                    );
                    return;
                  }

                  if (!password) {
                    setPasswordInputStateMessage("비밀번호를 입력하세요.");
                    return;
                  }

                  if (!confirmPassword) {
                    setConfirmPasswordInputStateMessage(
                      "동일한 비밀번호를 입력하세요."
                    );
                    return;
                  }

                  if (!validPassword) return;
                  if (!isConfirmPassword) return;

                  setShowConfirmLayer(true);
                }}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
