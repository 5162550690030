/* eslint-disable react-hooks/exhaustive-deps */
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import LoadingComponent from "components/templates/LoadingComponent";
import { useContext, useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
/* 
  1. canvas 1 - drag영역, 2 - 원본이미지 and 수정내용, 3 - 블러이미지 생성
      L 블러이미지의 경우 context 의 filter로 간단히 적용이 가능하지만 ios에 적용되지 않아
        pixel로 변화주는 방식으로 적용
  
*/
let reImgW, originW, ratioW;
let reImgH, originH, ratioH;
export default function ImageEditorLayer({
  mediaUrl,
  media,
  setMedia,
  setShowEditor,
  setIsEdit,
}) {
  const originBLayer = useRef();
  const originLayer = useRef();
  const editLayer = useRef();
  const blurLayer = useRef();
  const dragLayer = useRef();

  const [loading, setLoading] = useState(true);
  const [originCtx, setOriginCtx] = useState();
  const [originBlurCtx, setOriginBlurCtx] = useState();

  const [editCanvas, setEditCanvas] = useState();
  const [editCtx, setEditCtx] = useState();
  const [blurCanvas, setBlurCanvas] = useState();
  const [blurCtx, setBlurCtx] = useState();
  const [dragCanvas, setDragCanvas] = useState();
  const [dragCtx, setDragCtx] = useState();

  const [dragArea, setDragArea] = useState({ x: 0, y: 0, width: 0, height: 0 });

  const [editPrev, setEditPrev] = useState([]);
  const [editNext, setEditNext] = useState([]);

  const [prevImage, setPrevImage] = useState();

  const [originPrev, setOriginPrev] = useState();
  const [originBlurImage, setOriginBlurImage] = useState([]);

  //Blur = B , MarkUp = M...
  const [editMode, setEditMode] = useState("");
  const [restore, setRestore] = useState(true);

  const { isMobile } = useContext(isMobileContext);

  const [blurBtn, setBlurBtn] = useState("블러 효과");
  const [markUpBtn, setmarkUpBtn] = useState("그리기 효과");

  const [ratio, setRatio] = useState({ width: 0, height: 0 });

  const image = new Image();

  // useEffect(() => {
  //   document.getElementsByTagName("html")[0].classList.add("hidden");
  //   window.document.body.classList.add("hidden");
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("hidden");
  //     window.document.body.classList.remove("hidden");
  //   };
  // }, []);

  useEffect(() => {
    if (!restore) return;
    //뒷면 레이어가 움직이면 드래그 좌표 오류가 나기 때문에 고정
    isMobile && mainLayerHold(true);

    const oCanvas = originLayer.current;
    const oContext = oCanvas.getContext("2d");
    const obCanvas = originBLayer.current;
    const obContext = obCanvas.getContext("2d");

    const eCanvas = editLayer.current;
    const eContext = eCanvas.getContext("2d");
    const bCanvas = blurLayer.current;
    const bContext = bCanvas.getContext("2d");

    const dCanvas = dragLayer.current;
    const dContext = dCanvas.getContext("2d");

    setOriginCtx(oContext);
    setOriginBlurCtx(obContext);

    setEditCanvas(eCanvas);
    setEditCtx(eContext);
    setBlurCanvas(bCanvas);
    setBlurCtx(bContext);

    setDragCanvas(dCanvas);
    setDragCtx(dContext);

    image.src = mediaUrl;
    image.crossOrigin = "anonymous";

    image.onload = () => {
      //원본 비율로 Canvas에 그려주기 위한 로직

      //파일의 크기를 알아냄
      originW = image.width;
      originH = image.height;

      //캔버스 크기고정
      let canW = window.innerWidth;
      let canH = Math.floor(window.innerHeight * 0.65);
      //이미지 크기 조정

      ratioW = (canW / originW).toFixed(2);
      ratioH = (canH / originH).toFixed(2);
      // console.log("비율", ratioW, ratioH);

      //캔버스 넓이 > 이미지 원본 넓이
      if (canW >= originW) {
        //캔버스 높이 > 이미지 원본 높이
        if (canH >= originH) {
          reImgW = originW;
          reImgH = originH;
        } else {
          //"캔버스 높이 < 이미지 원본 높이
          reImgW = originW * ratioH;
          reImgH = canH;
        }
      } else {
        //캔버스 넓이 < 이미지 원본 넓이
        if (canH >= originH) {
          //캔버스 높이 > 이미지 원본 높이
          reImgW = canW;
          reImgH = originH * ratioW;
        } else {
          //캔버스 높이 < 이미지 원본 높이
          if (ratioW > ratioH) {
            reImgW = originW * ratioH;
            reImgH = originH * ratioH;
          } else {
            reImgW = originW * ratioW;
            reImgH = originH * ratioW;
          }
        }
      }
      originW = originW > 2500 ? (originW * 0.7).toFixed(0) : originW;
      originH = originH > 2500 ? (originH * 0.7).toFixed(0) : originH;

      oCanvas.width = obCanvas.width = originW;
      oCanvas.height = obCanvas.height = originH;

      setRatio({
        width: (reImgW / originW).toFixed(2),
        height: (reImgH / originH).toFixed(2),
      });

      eCanvas.width = bCanvas.width = dCanvas.width = reImgW;
      eCanvas.height = bCanvas.height = dCanvas.height = reImgH;

      bContext.drawImage(image, 0, 0, reImgW, reImgH);
      makeBlurEffect(bContext, 5);

      obContext.drawImage(image, 0, 0, originW, originH);
      makeBlurEffect(obContext, (10 / ratioW).toFixed(0));

      eContext.drawImage(image, 0, 0, reImgW, reImgH);
      oContext.drawImage(image, 0, 0, originW, originH);

      setTimeout(() => {
        setLoading(false);
      }, 100);
    };
    setRestore(false);
  }, [restore]);

  const mainLayerHold = (isHold) => {
    const mainLayer = document.querySelector("#root");
    isHold
      ? mainLayer.setAttribute("style", "height: 2px; overflow-y:hidden;")
      : mainLayer.removeAttribute("style") &&
        setEditPrev([]) &&
        setEditNext([]);
  };

  //드래그 영역 시작
  const handleDown = (e) => {
    if (editPrev.length === 0) {
      setEditPrev([]);
      setEditNext([]);
    }

    //편집 시작 전 이미지 정보 저장
    setPrevImage(
      editCtx.getImageData(0, 0, editCanvas.width, editCanvas.height)
    );
    setOriginPrev(
      originCtx.getImageData(
        0,
        0,
        originCtx.canvas.width,
        originCtx.canvas.height
      )
    );

    let offset = getPosition(e);
    setDragArea((prev) => ({
      x: offset.X,
      y: offset.Y,
    }));
    if (editMode === "M") {
      originCtx.beginPath();

      editCtx.beginPath(); //새로운 경로 지정
      editCtx.moveTo(offset.X, offset.Y);
    }
  };

  //드래그 중
  const handleMove = (e) => {
    let now = getPosition(e);

    if (editMode === "B") {
      let startX = dragArea.x;
      let startY = dragArea.y;

      dragCtx.lineWidth = 3;
      dragCtx.strokeStyle = "red";
      //마우스 움직이는 중 드래그 박스 지우고 새로 그림
      dragCtx.clearRect(0, 0, dragCanvas.width, dragCanvas.height);
      dragCtx.strokeRect(startX, startY, now.X - startX, now.Y - startY);
    } else {
      editCtx.lineWidth = 10;
      editCtx.lineCap = "round";
      editCtx.lineJoin = "round";

      originCtx.lineWidth = 10 / ratio.width;
      originCtx.lineCap = "round";
      originCtx.lineJoin = "round";

      editCtx.lineTo(now.X, now.Y);
      originCtx.lineTo(now.X / ratio.width, now.Y / ratio.height);

      originCtx.stroke();
      editCtx.stroke();
    }
  };

  //드래그 영역 끝
  const handleUp = (e) => {
    if (editMode === "B") {
      //Drag 영역 표시 라인 제거
      dragCtx.clearRect(0, 0, dragCanvas.width, dragCanvas.height);

      let startX = dragArea.x;
      let startY = dragArea.y;

      let end = getPosition(e);

      let dragW = end.X - startX;
      let dragH = end.Y - startY;

      if (dragW !== 0 && dragH !== 0) {
        setDragArea((prev) => ({
          ...prev,
          width: dragW,
          height: dragH,
        }));

        saveEditArea(blurCtx, startX, startY, dragW, dragH);
      }
    } else {
      editCtx.beginPath();
      saveEditArea(editCtx, 0, 0, editCanvas.width, editCanvas.height);
    }
  };

  const saveEditArea = (ctx, x, y, width, height) => {
    if (editMode === "B") {
      const left = width > 0 ? x : x + width;
      const top = height > 0 ? y : y + height;

      //블러이미지 Get and Put
      editCtx.putImageData(ctx.getImageData(x, y, width, height), left, top);
      originCtx.putImageData(
        originBlurCtx.getImageData(
          x / ratio.width,
          y / ratio.height,
          width / ratio.width,
          height / ratio.height
        ),
        left / ratio.width,
        top / ratio.height
      );

      setEditPrev((prev) => [
        {
          prevImg: prevImage,
          nextImg: editCtx.getImageData(
            0,
            0,
            editCanvas.width,
            editCanvas.height
          ),
          prevOrigin: originPrev,
          nextOrigin: originBlurCtx.getImageData(
            x / ratio.width,
            y / ratio.height,
            width / ratio.width,
            height / ratio.height
          ),
          left: 0,
          top: 0,
        },
        ...prev,
      ]);
    } else {
      setEditPrev((prev) => [
        {
          prevImg: prevImage,
          nextImg: ctx.getImageData(x, y, width, height),
          prevOrigin: originPrev,
          nextOrigin: originCtx.getImageData(
            x,
            y,
            originCtx.canvas.width,
            originCtx.canvas.height
          ),
          left: x,
          top: y,
        },
        ...prev,
      ]);
    }
  };

  //ios context.filter 먹히지 않으므로 이미지Data로 blur처리
  const makeBlurEffect = (context, rd) => {
    const radius = rd > 70 ? 70 : rd;
    const amount = parseFloat(radius);
    if (amount <= 0) {
      return context;
    }

    const { height, width } = context.canvas;
    const imageData = context.getImageData(0, 0, width, height);
    const { data } = imageData;

    // http://www.quasimondo.com/BoxBlurForCanvas/FastBlur.js
    const wm = width - 1;
    const hm = height - 1;
    const rad1 = amount + 1;
    var mul_table = [
      1, 57, 41, 21, 203, 34, 97, 73, 227, 91, 149, 62, 105, 45, 39, 137, 241,
      107, 3, 173, 39, 71, 65, 238, 219, 101, 187, 87, 81, 151, 141, 133, 249,
      117, 221, 209, 197, 187, 177, 169, 5, 153, 73, 139, 133, 127, 243, 233,
      223, 107, 103, 99, 191, 23, 177, 171, 165, 159, 77, 149, 9, 139, 135, 131,
      253, 245, 119, 231, 224, 109, 211,
    ];

    var shg_table = [
      0, 9, 10, 10, 14, 12, 14, 14, 16, 15, 16, 15, 16, 15, 15, 17, 18, 17, 12,
      18, 16, 17, 17, 19, 19, 18, 19, 18, 18, 19, 19, 19, 20, 19, 20, 20, 20,
      20, 20, 20, 15, 20, 19, 20, 20, 20, 21, 21, 21, 20, 20, 20, 21, 18, 21,
      21, 21, 21, 20, 21, 17, 21, 21, 21, 22, 22, 21, 22, 22, 21, 22,
    ];

    const mulSum = mul_table[radius];
    const shgSum = shg_table[radius];

    const r = [];
    const g = [];
    const b = [];
    const a = [];

    const vmin = [];
    const vmax = [];

    let iterations = 1; // 1 - 3
    let p, p1, p2, pa;

    while (iterations-- > 0) {
      let yw = 0;
      let yi = 0;

      for (let y = 0; y < height; y++) {
        let rsum = data[yw] * rad1;
        let gsum = data[yw + 1] * rad1;
        let bsum = data[yw + 2] * rad1;
        let asum = data[yw + 3] * rad1;

        for (let i = 1; i <= amount; i++) {
          p = yw + ((i > wm ? wm : i) << 2);
          rsum += data[p++];
          gsum += data[p++];
          bsum += data[p++];
          asum += data[p];
        }

        for (let x = 0; x < width; x++) {
          r[yi] = rsum;
          g[yi] = gsum;
          b[yi] = bsum;
          a[yi] = asum;

          if (y === 0) {
            vmin[x] = ((p = x + rad1) < wm ? p : wm) << 2;
            vmax[x] = (p = x - amount) > 0 ? p << 2 : 0;
          }

          p1 = yw + vmin[x];
          p2 = yw + vmax[x];

          rsum += data[p1++] - data[p2++];
          gsum += data[p1++] - data[p2++];
          bsum += data[p1++] - data[p2++];
          asum += data[p1] - data[p2];

          yi++;
        }
        yw += width << 2;
      }

      for (let x = 0; x < width; x++) {
        let yp = x;
        let rsum = r[yp] * rad1;
        let gsum = g[yp] * rad1;
        let bsum = b[yp] * rad1;
        let asum = a[yp] * rad1;

        for (let i = 1; i <= amount; i++) {
          yp += i > hm ? 0 : width;
          rsum += r[yp];
          gsum += g[yp];
          bsum += b[yp];
          asum += a[yp];
        }
        yi = x << 2;

        for (let y = 0; y < height; y++) {
          data[yi + 3] = pa = (asum * mulSum) >>> shgSum;

          if (pa > 0) {
            pa = 255 / pa;
            data[yi] = ((rsum * mulSum) >>> shgSum) * pa;
            data[yi + 1] = ((gsum * mulSum) >>> shgSum) * pa;
            data[yi + 2] = ((bsum * mulSum) >>> shgSum) * pa;
          } else {
            data[yi] = data[yi + 1] = data[yi + 2] = 0;
          }

          if (x === 0) {
            vmin[y] = ((p = y + rad1) < hm ? p : hm) * width;
            vmax[y] = (p = y - amount) > 0 ? p * width : 0;
          }

          p1 = x + vmin[y];
          p2 = x + vmax[y];

          rsum += r[p1] - r[p2];
          gsum += g[p1] - g[p2];
          bsum += b[p1] - b[p2];
          asum += a[p1] - a[p2];

          yi += width << 2;
        }
      }
    }

    context.putImageData(imageData, 0, 0);

    return context;
  };

  //수정된 blob 부모component Return
  const blobCallback = () => {
    return (b) => {
      const editUrl = window.URL.createObjectURL(b);
      console.log("> editBlob ", editUrl);
      const copyMedia = media.slice();
      const form = document.querySelector("#fileupload");
      copyMedia.map((image) => {
        if (image.url === mediaUrl) {
          if (!image.file) image.mediaId = image.id; //수정된 image insert 시 기존 sort를 위함

          const array = [];
          const fileExt = image.url.split(".").reverse()[0];
          const fileType = image.file
            ? image.file.type
            : fileExt === "jpg"
            ? "image/jpeg"
            : `image/${fileExt}`;

          const fileName = image.file
            ? image.file.name
            : uuid() + "." + fileExt;
          const imageDataUrl = originLayer.current.toDataURL(fileType);
          const binaryData = atob(imageDataUrl.split(",")[1]);

          for (let i = 0; i < binaryData.length; i++)
            array.push(binaryData.charCodeAt(i));

          const editFile = new File([new Uint8Array(array)], fileName, {
            type: fileType,
          });

          form.append(editFile);

          image.file = editFile;
          image.url = editUrl;
          image.upload = true;
        }
        return image;
      });

      setMedia(copyMedia);
      setIsEdit(false);
      setShowEditor(false);
    };
  };

  //get마우스 좌표
  const getPosition = (e) => {
    let resultX = "",
      resultY = "";

    resultX =
      "changedTouches" in e
        ? e.changedTouches[0].clientX -
          window.pageXOffset -
          e.target.getBoundingClientRect().left
        : "nativeEvent" in e
        ? e.nativeEvent.offsetX
        : 0;

    resultY =
      "changedTouches" in e
        ? e.changedTouches[0].clientY -
          window.pageYOffset -
          e.target.getBoundingClientRect().top
        : "nativeEvent" in e
        ? e.nativeEvent.offsetY
        : 0;

    return { X: resultX, Y: resultY };
  };

  return (
    <div className="modal-wrap open">
      <div
        className="modal modal-write open img-editor-modal"
        style={{ overflow: "hidden" }}
        id="modal-write"
      >
        <div className="modal-header">
          <button
            type="button"
            className="page-back mobile"
            onClick={(e) => {
              isMobile && mainLayerHold(false);
              setIsEdit(false);
              setShowEditor(false);
            }}
          ></button>
          <strong>이미지 편집</strong>
          <button
            type="button"
            className="edit-commit"
            onClick={(e) => {
              isMobile && mainLayerHold(false);
              originLayer.current.toBlob(blobCallback(), "image");
            }}
          >
            완료
          </button>
        </div>
        <div className="modal-body  height100">
          <div className="edit-header">
            <button
              className="edit-btn undo-btn "
              onClick={(e) => {
                if (editPrev.length > 0) {
                  editNext.unshift(editPrev[0]);
                  editCtx.putImageData(
                    editPrev[0].prevImg,
                    editPrev[0].left,
                    editPrev[0].top
                  );

                  originCtx.putImageData(
                    editPrev[0].prevOrigin,
                    editPrev[0].left,
                    editPrev[0].top
                  );
                  editPrev.shift(); //0번째 제거
                }
              }}
            ></button>
            <button
              className="edit-btn redo-btn"
              onClick={(e) => {
                if (editNext.length > 0) {
                  editPrev.unshift(editNext[0]);
                  editCtx.putImageData(
                    editNext[0].nextImg,
                    editNext[0].left,
                    editNext[0].top
                  );

                  originCtx.putImageData(
                    editPrev[0].nextOrigin,
                    editPrev[0].left,
                    editPrev[0].top
                  );
                  editNext.shift(); //0번째 제거
                }
              }}
            ></button>
            <button
              className="edit-btn reset-btn"
              onClick={(e) => {
                if (editPrev.length > 0) {
                  // setLoading(true);
                  setEditPrev([]);
                  setEditNext([]);
                  setRestore(true);
                }
              }}
            ></button>
          </div>
          <div className="canvas-div">
            <canvas className="origin-layer margin-center" ref={originBLayer} />
            <canvas className="origin-layer margin-center" ref={originLayer} />
            <canvas className="blur-layer margin-center" ref={blurLayer} />
            <canvas className="img-layer margin-center" ref={editLayer} />
            <canvas
              className="drag-layer margin-center"
              ref={dragLayer}
              onTouchStart={(e) => {
                editMode && handleDown(e);
              }}
              onMouseDown={(e) => {
                editMode && handleDown(e);
              }}
              onTouchMove={(e) => {
                editMode && handleMove(e);
              }}
              onMouseMove={(e) => {
                editMode && handleMove(e);
              }}
              onTouchEnd={(e) => {
                editMode && handleUp(e);
              }}
              onMouseUp={(e) => {
                editMode && handleUp(e);
              }}
            />
          </div>
          <div
            className="write-btn  height80 "
            style={{ marginBottom: "2vh", position: "fixed", bottom: "3%" }}
          >
            <button
              className={`edit-btn blur-btn ${blurBtn}`}
              onClick={(e) => {
                e.preventDefault();

                setEditMode("B");
                setmarkUpBtn("");
                setBlurBtn("active");
                // }
              }}
            ></button>
            <button
              className={`edit-btn draw-btn ${markUpBtn}`}
              onClick={(e) => {
                e.preventDefault();

                setEditMode("M");
                setBlurBtn("");
                setmarkUpBtn("active");
              }}
            ></button>
          </div>
        </div>
      </div>
      {loading && (
        <div className="modal-wrap open">
          <LoadingComponent />
        </div>
      )}
    </div>
  );
}
