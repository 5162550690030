import styled from "styled-components";

import InputBackImg from "assets/imgs/icons/icon_SocialSearch.svg";
import { useEffect, useRef, useState } from "react";

export default function Search({
  searchRef,
  category,
  setIsSearcing,
  searchKeyword,
  handleSearchKeyword,
}) {
  //  const inputRef = useRef();

  const [keyword, setKeyword] = useState(searchKeyword ? searchKeyword : "");

  const onChange = (e) => {
    setKeyword(e.target.value);
  };
  const onFocus = (e) => {
    setIsSearcing(true);
  };

  const onKeyUp = (e) => {
    if (e.key === "Enter") {
      handleSearchKeyword(e.target.value);
    }
  };

  useEffect(() => {
    setKeyword(searchKeyword);
  }, [searchKeyword]);

  // useEffect(() => {
  //   setIsSearcing(false);
  //   inputRef.current.blur();
  // }, [setIsSearcing]);

  return (
    <SearchContainer>
      <SearchHeader>
        <ContentSearchBtn InputBackImg={InputBackImg}></ContentSearchBtn>
        <ContentSearchInput
          ref={searchRef}
          onKeyUp={onKeyUp}
          placeholder={`관심있는 ${category.title}를 검색해 보세요`}
          onChange={onChange}
          onFocus={onFocus}
          value={keyword}
        />
      </SearchHeader>
    </SearchContainer>
  );
}

const SearchContainer = styled.div`
  width: 100%;
  height: 100%;

  border: 1px solid transparent;
  background-color: #fff;
`;

const SearchHeader = styled.div`
  margin-top: 20px;
  margin-bottom: 33px;
  background-color: #fff;

  border: 2px solid transparent;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(345deg, #3773ff 0%, #22e6f3 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;

  border-radius: 25px;
  /* width: 66%; */

  /* width: 80%; */
  width: 245px;
  height: 35px;

  @media screen and (min-width: 481px) {
    width: 80%;
  }

  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

const ContentSearchInput = styled.input`
  border: none;
  width: 83%;
  border-radius: 0 10px 10px 0;
  height: 28px;

  &::placeholder {
    padding-left: 5px;
    font-size: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(48, 152, 251, 0.6);

    @media screen and (min-width: 481px) {
      font-size: 13px;
    }
  }
`;

const ContentSearchBtn = styled.button`
  width: 14px;
  height: 14px;
  background: url(${({ InputBackImg }) => InputBackImg}) no-repeat;
  background-size: contain;
  margin-left: 8px;
  margin-right: 17px;
`;
