import styled, { css } from "styled-components";

import { useEffect, useState } from "react";
import BannerCam from "assets/imgs/banners/Add_Banner_Campaign.jpg";
import BannerTheme from "assets/imgs/banners/Add_Banner_Theme.jpg";
import BannerChannel from "assets/imgs/banners/Add_Banner_Channel.jpg";
import BannerCommunity from "assets/imgs/banners/Add_Banner_Community.jpg";

import { useHistory } from "react-router-dom";

export default function BannerSection({ category, content }) {
  // theme 별로 배너의 내용이 다르게 설정

  // const {
  //   datas,
  //   totalCount,
  //   sortType,
  //   loading,
  //   scrollPosition,
  //   cursor,
  //   filter,
  //   searchKeyword,
  //   isLastData,
  // } = content;
  const random = Math.floor(Math.random() * (4 - 0 + 1) + 0);

  const history = useHistory();

  // const currnetContent = content[random];

  return (
    <BannerWrapper category={category}>
      {/* {content[random]} */}
      {/* <BannerTextWrapper>
        <HashTagCt
          className={category.name === "community" ? "community" : "channel"}
        >
          {<HashTag>{currnetContent && currnetContent.scTag}</HashTag>}
        </HashTagCt>
        <BannerTitle category={category}>
          <strong>{currnetContent && currnetContent.scName}</strong>
          {category.name === "channel" ? (
            <BannerDesc category={category}>
              지금 바로 채널 구독하세요!
            </BannerDesc>
          ) : (
            ""
          )}
        </BannerTitle>
      </BannerTextWrapper> */}

      {/* <UserPicCt>
        <UserPic category={category}></UserPic>
      </UserPicCt> */}
    </BannerWrapper>
  );
}

const BannerWrapper = styled.div`
  width: 100%;
  height: 46vw;

  border: 1px solid transparent;
  margin-top: -10px;
  margin-bottom: 7px;

  position: relative;

  ${({ category }) => {
    if (category.name === "theme") {
      return css`
        background: url(${BannerTheme}) no-repeat;
      `;
    } else if (category.name === "campaign") {
      return css`
        background: url(${BannerCam}) no-repeat;
      `;
    } else if (category.name === "channel") {
      return css`
        background: url(${BannerChannel}) no-repeat;
      `;
    } else if (category.name === "community") {
      return css`
        background: url(${BannerCommunity}) no-repeat;
      `;
    }
  }}

  background-size: 100%;
  background-position: top left;
  @media screen and (min-width: 481px) {
    height: 26vw;
    background-size: 100% 100%;
    background-position: top left;
  }
`;

const BannerTextWrapper = styled.div`
  color: #2b2828;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 22px 0 0 20px;
  text-align: left;
`;
const BannerTitle = styled.p`
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  display: inline-block;
  text-align: center;
  color: #515352;

  ${({ category }) => {
    if (category.name === "community") {
      return css`
        color: #fff;
      `;
    }
  }}
`;

const BannerDesc = styled.p`
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #a47ccd;
  margin-top: 5px;

  ${({ category }) => {
    if (category.name === "community") {
      return css`
        color: #ffefc5;
      `;
    }
  }}
`;

const HashTagCt = styled.div`
  margin-bottom: 5px;

  &.community span {
    background-color: #cc73ec;
  }
`;

const HashTag = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  padding: 3px 8px;
  margin-right: 3px;
  color: #fff;
  background-color: #cd61e8;
  border-radius: 100px;
`;

const UserPicCt = styled.div`
  top: 20%;
  right: 10%;
  position: absolute;
`;
const UserPic = styled.img`
  border-radius: 50%;
  width: 85px;
  height: 85px;
  background-color: #a47ccd;

  ${({ category }) => {
    if (category.name === "community") {
      return css`
        background-color: #ffefc5;
      `;
    }
  }}
`;
