import FeedDeleteConfirmLayer from "components/layers/confirm/FeedDeleteConfirmLayer";
import MemberBlockLayer from "components/layers/memberBlock/MemberBlockLayer";
import FeedAddFeatLayer from "components/layers/popMenu/FeedAddFeatLayer";
import FeedReportLayer from "components/layers/report/FeedReportLayer";
import FeedShareLayer from "components/layers/share/FeedShareLayer";
import CommentContainer from "components/templates/CommentContainer";
import { useEffect, useState, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import FeedDetailContent from "./FeedDetailContent";
import FeedDetailFooter from "./FeedDetailFooter";
import FeedDetailHeader from "./FeedDetailHeader";
import FeedDetailTag from "./FeedDetailTag";
import FeedDetailText from "./FeedDetailText";
import ToastMessageBox from "./ToastMessageBox";
import styled from "styled-components";
import { Base64 } from "js-base64";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import jeboApi from "api/api";

export default function FeedDetailContainer({
  feed,
  setFeed,
  popMenu,
  dispatchPopMenu,
  onUpdateFeed,
  onDeleteFeed,
  onBlockFeed,
}) {
  const [toastMessage, setToastMessage] = useState("");

  const history = useHistory();
  const match = useRouteMatch();

  const onClosePopMenu = () => {
    dispatchPopMenu({ type: "CLEAR" });
  };

  const onOpenPopMenu = (id, name) => {
    dispatchPopMenu({ type: "DISPLAY_MENU", id, name });
  };

  const { mobileType } = useContext(isMobileContext);
  const { contents, locationHashtag } = feed;
  var urlStr = [];

  contents &&
    (contents + " " + (locationHashtag ? locationHashtag : ""))
      .split(/([#@http(s)][^\s\n]+)/g)
      .map((word) => {
        if (
          word.match(
            /((^http(s)?:\/\/)([\w]+[\.:]*)+[\w]{2})([\/\w-%#?&=])+(\.[\w]{2,4})*/g
          )
        ) {
          urlStr.push(
            word.match(
              // /((^http(s)?:\/\/)([\w]+[\.:]*)+[\w]{2})([\/\w-%#?&=])+(\.[\w]{2,4})*/g
              /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}([\/a-z0-9-%#?&=\w])+(\.[a-z0-9]{2,4}(\?[\/a-z0-9-%#?&=\w]+)*)*/g
            )
          );
          return urlStr[0];
        }
      });

  const [isUrlEmpty, setIsUrlEmpty] = useState(false);
  const [urlData, setUrlData] = useState(null);

  const info = async () => {
    try {
      if (!urlStr[0]) return;
      const { data, status } = await jeboApi.getUrlMetaIfno(String(urlStr[0]));
      if (status === 200) {
        console.log(status);
        setUrlData(JSON.parse(data.metaInfo));
        setIsUrlEmpty(true);
      }
    } catch (error) {
      console.log(error);
      setIsUrlEmpty(false);
    }
  };

  // test
  // useEffect(() => {
  //   console.log(urlData);
  // }, [urlData]);

  useEffect(() => {
    info();
  }, []);

  return (
    <>
      {popMenu.id === feed.jeboId && popMenu.name === "FEAT" && (
        <FeedAddFeatLayer
          feed={feed}
          onClose={onClosePopMenu}
          onOpen={onOpenPopMenu}
          onUpdateFeed={onUpdateFeed}
          onDeleteFeed={onDeleteFeed}
          setToastMessage={setToastMessage}
        />
      )}
      {popMenu.id === feed.jeboId && popMenu.name === "MEMBER_BLOCK" && (
        <MemberBlockLayer
          member={{ memberUid: feed.memberUid, nickName: feed.nickName }}
          onClose={onClosePopMenu}
          onBlock={onBlockFeed}
        />
      )}
      {popMenu.id === feed.jeboId && popMenu.name === "REPORT" && (
        <FeedReportLayer feed={feed} onClose={onClosePopMenu} />
      )}
      {popMenu.id === feed.jeboId && popMenu.name === "SHARE" && (
        <FeedShareLayer feed={feed} onClose={onClosePopMenu} />
      )}
      {popMenu.id === feed.jeboId && popMenu.name === "DELETE" && (
        <FeedDeleteConfirmLayer
          feed={feed}
          onClose={onClosePopMenu}
          onDelete={onDeleteFeed}
        />
      )}
      {toastMessage && (
        <ToastMessageBox
          toastMessage={toastMessage}
          setToastMessage={setToastMessage}
        />
      )}

      <div className="feed-wrap">
        <FeedDetailHeader feed={feed} onOpenPopMenu={onOpenPopMenu} />
        {/* {jeboMedia && jeboMedia.length > 0 && <FeedDetailContent feed={feed} />} */}
        <FeedDetailContent feed={feed} />
        <div className="feed-detail">
          <div className="item-body-wrap">
            {feed && feed.hashTag && <FeedDetailTag feed={feed} />}
            <FeedDetailText CurFeed={feed} />

            {isUrlEmpty && (
              <UrlContainer>
                <UrlLink
                  rel="noreferrer"
                  href="_EXTERNALURL"
                  style={{
                    color: "#191CEE",
                    wordWrap: "break-word",
                    wordBreak: "keep-all",
                    position: "relative",
                    width: "min-content",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const url = String(urlStr[0]);
                    if (mobileType === "ios") {
                      const encodedUrl = Base64.encode(url);
                      window.webkit.messageHandlers.openOuterLink.postMessage(
                        encodedUrl
                      );
                    } else {
                      window.open(url);
                    }
                  }}
                >
                  <UrlImg
                    src={urlData.imageUrl}
                    alt=""
                    referrerPolicy="no-referrer"
                  />
                  <UrlTitle>{urlData.title}</UrlTitle>
                  <UrlDesc>{urlData.description}</UrlDesc>
                  <UrlName>{urlData.siteName}</UrlName>

                  {/* {String(urlStr[0])} */}
                </UrlLink>
              </UrlContainer>
            )}
            <LikeHistoryContainer>
              {feed.upCnt > 0 && (
                <LikeHistoryDesc
                  onClick={(e) => {
                    history.push({
                      pathname: `${match.url}/likeHistory`,
                      state: { jeboId: feed.jeboId },
                    });
                  }}
                  className="likeHistory__title"
                >
                  {feed.upCnt > 1 ? (
                    <>
                      {feed.likeNickName}님 외 <span>{feed.upCnt - 1}</span>명이
                      공감합니다
                    </>
                  ) : (
                    <>{feed.likeNickName}님이 공감합니다</>
                  )}
                </LikeHistoryDesc>
              )}
            </LikeHistoryContainer>
            <FeedDetailFooter
              feed={feed}
              setFeed={setFeed}
              showCommentBtn={false}
              onOpenPopMenu={onOpenPopMenu}
              onUpdateFeed={onUpdateFeed}
              setToastMessage={setToastMessage}
            />
            <CommentContainer feed={feed} setFeed={setFeed} />
            {/* delete confirm */}
          </div>
        </div>
      </div>
    </>
  );
}

const UrlContainer = styled.div`
  margin: 10px 14px;
  padding: 10px 3vh;
  background-color: #ebf5f1;
`;

const UrlLink = styled.a`
  font-size: 11px;
  display: block;
  width: 100% !important;
`;

const UrlImg = styled.img`
  /* background: url(${({ imageUrl }) => imageUrl}) no-repeat;
  background-color: #ebf5f1;
  background-position: center;
  background-size: contain; */
  object-fit: contain;
  width: 100%;
  height: 180px;
`;

const UrlTitle = styled.p`
  font-size: 15px;
  line-height: 20px;
  margin: 10px 0;
  color: #000;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
`;

const UrlName = styled.p`
  font-size: 12px;
  color: #999;
  margin-top: 10px;
`;

const UrlDesc = styled.p`
  font-size: 13px;
  color: #2c2c2c;

  line-height: 14px;
  /* height: 72px; */
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
`;

const LikeHistoryContainer = styled.div`
  background-color: #fff;
  height: 26px;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;

  @media screen and (min-width: 481px) {
    border: none;
  }

  @media screen and (max-width: 480px) {
    border: none;
  }
`;

const LikeHistoryDesc = styled.button`
  background-color: transparent;
  padding: 0px 0px 7px 11px;
  font-size: 12px;
  background-color: transparent;
  color: #4e4e4e;
  font-weight: bold;
  display: inline-block;
  text-align: left;
`;

// const likeHistory__title
