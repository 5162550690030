import jeboApi from "api/api";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";

export default function MobilePushAlarmContainer({ setActiveMenu }) {
  const { handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const [loading, setLoading] = useState(true);
  const modalRef = useRef();
  const { isMobile } = useContext(isMobileContext);
  const [pushSettings, setPushSettings] = useState({
    pushLike: false,
    pushComment: false,
    pushReply: false,
    pushComLike: false,
    pushMention: false,
    pushFollow: false,
    pushFollowFeed: false,
    pushDm: false,
    pushMarketing: false,
  });

  const history = useHistory();

  const updatePushSettings = (settings) => {
    console.log("updatePushSettings");

    const {
      pushLike,
      pushComment,
      pushReply,
      pushComLike,
      pushMention,
      pushFollow,
      pushFollowFeed,
      pushDm,
      pushMarketing,
    } = settings;

    jeboApi
      .updatePushSettings({
        pushLike: pushLike ? "1" : "0",
        pushComment: pushComment ? "1" : "0",
        pushReply: pushReply ? "1" : "0",
        pushComLike: pushComLike ? "1" : "0",
        pushMention: pushMention ? "1" : "0",
        pushFollow: pushFollow ? "1" : "0",
        pushFollowFeed: pushFollowFeed ? "1" : "0",
        pushDm: pushDm ? "1" : "0",
        pushMarketing: pushMarketing ? "1" : "0",
      })
      .then(({ status }) => {
        console.log("push setting : ", status);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            console.log(error.response.status);
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            handleSignOutApp();
            setSignLayer("signIn");
            history.replace({ pathname: "/" });
          }
        }
      });
  };

  const getPushSetting = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.getPushSettings();

      if (status === 200) {
        const {
          pushComment,
          pushLike,
          pushReply,
          pushComLike,
          pushMention,
          pushFollow,
          pushFollowFeed,
          pushDm,
          pushMarketing,
        } = JSON.parse(data.pushSettings);

        setPushSettings({
          pushLike: pushLike === "1" ? true : false,
          pushComment: pushComment === "1" ? true : false,
          pushReply: pushReply === "1" ? true : false,
          pushComLike: pushComLike === "1" ? true : false,
          pushMention: pushMention === "1" ? true : false,
          pushFollow: pushFollow === "1" ? true : false,
          pushFollowFeed: pushFollowFeed === "1" ? true : false,
          pushDm: pushDm === "1" ? true : false,
          pushMarketing: pushMarketing === "1" ? true : false,
        });
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    } finally {
      setLoading(false);
    }
  }, [history, handleSignOutApp, setSignLayer]);

  useEffect(() => {
    getPushSetting();
  }, [getPushSetting]);

  return createPortal(
    <Wrap
      ref={modalRef}
      onMouseDown={(e) => {
        !isMobile &&
          e.target === modalRef.current &&
          history.replace({ pathname: "/" });
      }}
    >
      <Modal>
        <ModalHeader>
          <Button
            onClick={() => {
              history.goBack();
            }}
          ></Button>
          <Title>푸시 알림</Title>
        </ModalHeader>
        <ModalBody>
          <Container>
            <PushList>
              <PushGroup>
                <PushTitle>
                  <span>모든 알림</span>
                  <input
                    type="checkbox"
                    className="toggle-check"
                    id="pushAll"
                    onChange={(e) => {
                      const settings = {
                        pushLike: e.target.checked,
                        pushComment: e.target.checked,
                        pushReply: e.target.checked,
                        pushComLike: e.target.checked,
                        pushMention: e.target.checked,
                        pushFollow: e.target.checked,
                        pushFollowFeed: e.target.checked,
                        pushDm: e.target.checked,
                        pushMarketing: e.target.checked,
                      };

                      updatePushSettings(settings);
                      setPushSettings(settings);
                    }}
                    checked={Object.values(pushSettings).every(
                      (value) => value
                    )}
                  />
                  <label htmlFor="pushAll"></label>
                </PushTitle>
              </PushGroup>

              <PushGroup>
                <PushTitle>
                  <span>게시물 알림</span>
                </PushTitle>
                <PushItem>
                  <span>받은 댓글</span>
                  <input
                    type="checkbox"
                    className="toggle-check"
                    id="pushComment"
                    onChange={(e) => {
                      updatePushSettings(
                        Object.assign({}, pushSettings, {
                          pushComment: e.target.checked,
                        })
                      );
                      setPushSettings((prev) =>
                        Object.assign({}, prev, {
                          pushComment: e.target.checked,
                        })
                      );
                    }}
                    checked={pushSettings.pushComment}
                  />
                  <label htmlFor="pushComment"></label>
                </PushItem>
                <PushItem>
                  <span>받은 공감</span>
                  <input
                    type="checkbox"
                    className="toggle-check"
                    id="pushLike"
                    onChange={(e) => {
                      updatePushSettings(
                        Object.assign({}, pushSettings, {
                          pushLike: e.target.checked,
                        })
                      );
                      setPushSettings((prev) =>
                        Object.assign({}, prev, {
                          pushLike: e.target.checked,
                        })
                      );
                    }}
                    checked={pushSettings.pushLike}
                  />
                  <label htmlFor="pushLike"></label>
                </PushItem>
              </PushGroup>
              <PushGroup>
                <PushTitle>
                  <span>댓글 알림</span>
                </PushTitle>
                <PushItem>
                  <span>받은 답글</span>
                  <input
                    type="checkbox"
                    className="toggle-check"
                    id="pushReply"
                    onChange={(e) => {
                      updatePushSettings(
                        Object.assign({}, pushSettings, {
                          pushReply: e.target.checked,
                        })
                      );
                      setPushSettings((prev) =>
                        Object.assign({}, prev, {
                          pushReply: e.target.checked,
                        })
                      );
                    }}
                    checked={pushSettings.pushReply}
                  />
                  <label htmlFor="pushReply"></label>
                </PushItem>
                <PushItem>
                  <span>받은 공감</span>
                  <input
                    type="checkbox"
                    className="toggle-check"
                    id="pushComLike"
                    onChange={(e) => {
                      updatePushSettings(
                        Object.assign({}, pushSettings, {
                          pushComLike: e.target.checked,
                        })
                      );
                      setPushSettings((prev) =>
                        Object.assign({}, prev, {
                          pushComLike: e.target.checked,
                        })
                      );
                    }}
                    checked={pushSettings.pushComLike}
                  />
                  <label htmlFor="pushComLike"></label>
                </PushItem>
                <PushItem>
                  <span>받은 언급</span>
                  <input
                    type="checkbox"
                    className="toggle-check"
                    id="pushMention"
                    onChange={(e) => {
                      updatePushSettings(
                        Object.assign({}, pushSettings, {
                          pushMention: e.target.checked,
                        })
                      );
                      setPushSettings((prev) =>
                        Object.assign({}, prev, {
                          pushMention: e.target.checked,
                        })
                      );
                    }}
                    checked={pushSettings.pushMention}
                  />
                  <label htmlFor="pushMention"></label>
                </PushItem>
              </PushGroup>

              <PushGroup>
                <PushTitle>
                  <span>구독 알림</span>
                </PushTitle>

                <PushItem>
                  <span>나를 구독</span>
                  <input
                    type="checkbox"
                    className="toggle-check"
                    id="pushFollow"
                    onChange={(e) => {
                      updatePushSettings(
                        Object.assign({}, pushSettings, {
                          pushFollow: e.target.checked,
                        })
                      );
                      setPushSettings((prev) =>
                        Object.assign({}, prev, {
                          pushFollow: e.target.checked,
                        })
                      );
                    }}
                    checked={pushSettings.pushFollow}
                  />
                  <label htmlFor="pushFollow"></label>
                </PushItem>
                <PushItem>
                  <span>관심 구독 회원의 새글</span>
                  <input
                    type="checkbox"
                    className="toggle-check"
                    id="pushFollowFeed"
                    onChange={(e) => {
                      updatePushSettings(
                        Object.assign({}, pushSettings, {
                          pushFollowFeed: e.target.checked,
                        })
                      );
                      setPushSettings((prev) =>
                        Object.assign({}, prev, {
                          pushFollowFeed: e.target.checked,
                        })
                      );
                    }}
                    checked={pushSettings.pushFollowFeed}
                  />
                  <label htmlFor="pushFollowFeed"></label>
                </PushItem>
              </PushGroup>
              <PushGroup>
                <PushTitle>
                  <span>1:1 대화 알림</span>
                </PushTitle>
                <PushItem>
                  <span>메시지</span>
                  <input
                    type="checkbox"
                    className="toggle-check"
                    id="pushDm"
                    onChange={(e) => {
                      updatePushSettings(
                        Object.assign({}, pushSettings, {
                          pushDm: e.target.checked,
                        })
                      );
                      setPushSettings((prev) =>
                        Object.assign({}, prev, { pushDm: e.target.checked })
                      );
                    }}
                    checked={pushSettings.pushDm}
                  />
                  <label htmlFor="pushDm"></label>
                </PushItem>
              </PushGroup>
              <PushGroup>
                <PushTitle>
                  <span>이벤트 혜택 알림</span>
                </PushTitle>
                <PushItem>
                  <span>푸시알림</span>
                  <input
                    type="checkbox"
                    className="toggle-check"
                    id="pushMarketing"
                    onChange={(e) => {
                      updatePushSettings(
                        Object.assign({}, pushSettings, {
                          pushMarketing: e.target.checked,
                        })
                      );
                      setPushSettings((prev) =>
                        Object.assign({}, prev, {
                          pushMarketing: e.target.checked,
                        })
                      );
                    }}
                    checked={pushSettings.pushMarketing}
                  />
                  <label htmlFor="pushMarketing"></label>
                </PushItem>
              </PushGroup>
            </PushList>
          </Container>
        </ModalBody>
      </Modal>
    </Wrap>,
    document.getElementById("modal")
  );
}

const openModal = keyframes`
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
`;

const Modal = styled.div`
  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;

  width: 100%;
  max-width: 100%;
  height: 100% !important;
  max-height: 100vh !important;

  position: relative;
  background: #fff;
  transform: translateY(0);
  opacity: 0;
`;

const ModalHeader = styled.div`
  display: flex !important;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 7px;
  padding-right: 16px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;
`;

const Title = styled.strong`
  display: inline-block;
  width: auto;
  font-size: 1rem;
  color: var(--text-defaultBlackColor);
  font-weight: 500;
  /* line-height: 50px; */
  line-height: normal !important;
  width: 70%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-self: flex-end;
`;

const Button = styled.button`
  display: block;
  width: 27px;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background: url(/static/media/img_pre.2990dcb3.svg) no-repeat;
  background-color: #fff;
  background-position: left center;
`;

const ModalBody = styled.div`
  height: calc(100% - 50px);
  max-height: none !important;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  max-width: 100%;
  margin: 0;
`;

const PushList = styled.div`
  max-width: 100%;
  width: 100%;
  padding: 40px 16px;
  > div {
    &:first-child {
      margin-bottom: 40px;
    }
  }
`;

const PushGroup = styled.div`
  font-size: 100%;
  box-sizing: border-box;
`;

const PushTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const PushItem = styled.div`
  padding-left: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
