import jeboApi from "api/api";
import Layers from "components/layers/Layers";
import Header from "components/pages/app/layouts/Header";
import FeedSection from "components/pages/search/FeedSection";
import Sections from "components/pages/search/layouts/Sections";
import MobileSearchSection from "components/pages/search/MobileSearchSection";
import SearchContextProvider from "components/pages/search/provider/SearchContextProvider";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import qs from "query-string";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useParams,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import EllipsisText from "react-ellipsis-text/lib/components/EllipsisText";
import { signAppContext } from "components/providers/SignContextProvider";
import { convenienceDataContext } from "components/providers/ConvenienceDataContextProvider";
import styled from "styled-components";
import { AdsMapDataContext } from "components/providers/AdsMapContextProvider";

export default function Search() {
  const { isMobile } = useContext(isMobileContext);
  const { handleSignOutApp } = useContext(signAppContext);
  const [feeds, setFeeds] = useState([]);
  const [layer, setLayer] = useState(null);

  const [eventInfo, setEventInfo] = useState(null);
  const params = useParams();
  const location = useLocation();
  const { keyword } = params;
  const { eventId } = qs.parse(location.search);

  const { convenienceData } = useContext(convenienceDataContext);
  const [conMapData, setConMapData] = useState(null);
  const history = useHistory();
  const match = useRouteMatch();

  const [showKeywordsBox, setShowKeywordsBox] = useState(
    keyword || eventId ? false : true
  );
  const onExpiredSession = () => {
    alert("로그인 세션이 만료됐습니다. 다시 로그인해주세요.");
    handleSignOutApp();
    history.replace({ pathname: `${match.url}/sign` });
  };

  const getEventName = useCallback(async () => {
    if (!eventId) {
      setEventInfo(null);
      return;
    }

    try {
      const {
        data: { event },
        status,
      } = await jeboApi.getEventName(eventId);

      if (status === 200) {
        const eventInfo = JSON.parse(event);
        setEventInfo(eventInfo);
      } else {
        setEventInfo(null);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  }, [eventId]);

  useEffect(() => {
    getEventName();
  }, [getEventName]);

  const { adsMapData } = useContext(AdsMapDataContext);
  const [adsInfo, setAdsInfo] = useState(null);

  useEffect(() => {
    /* convenienceData - 편의지도코드테이블, adsMapData - 광고정보테이블
      0. convenienceData의 isEvent(이벤트여부) 값과 adsMapData의 description( 이벤트 랜딩메세지 ) 값이 필요함
      1. 편의지도 event 정보는 jebo_public_map_code에 event_start-event_end사이에 현재날짜 포함여부로 판단
      2. 추후 편의지도 광고 필요 시 jebo_public_map_code의 hash_code 컬럼과 ads_manager_info의 action_code 값을 맞춰 준다. 
    */
    if (conMapData && adsMapData)
      for (let j = 0; j < adsMapData.length; j++) {
        if (
          decodeURIComponent(keyword) === conMapData.codeName &&
          conMapData.hashCode === adsMapData[j].actionCode
        ) {
          setAdsInfo(adsMapData[j]);
          break;
        }
      }
  }, [conMapData, adsMapData]);

  useEffect(() => {
    setConMapData(null);

    convenienceData &&
      convenienceData.map((item) => {
        if (decodeURIComponent(keyword) === item.codeName) setConMapData(item);
      });
  }, [keyword, convenienceData]);

  return (
    <>
      <SearchContextProvider
        feeds={{ feeds, setFeeds }}
        layer={{ layer, setLayer }}
        session={{ onExpiredSession }}
      >
        {!isMobile && <Header />}
        {conMapData && (
          <Container>
            <Section>
              <Banner>
                <div>
                  <Strong>
                    {adsInfo &&
                    conMapData.hashCode === adsInfo.actionCode &&
                    conMapData.isEvent
                      ? adsInfo.description
                      : "한눈에 확인하는 편의지도, 제보하기로 함께 만들어요!"}
                  </Strong>
                </div>

                <AddButton
                  className="btn-md btn-border-wh"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: `${match.url}/add`,
                      search: `?conName=${conMapData.codeName.replace(
                        "#",
                        "%23"
                      )}&isEvent=${conMapData.isEvent}`, //--
                    });
                  }}
                >
                  제보하기
                </AddButton>
              </Banner>
            </Section>
          </Container>
        )}
        <Sections eventInfo={eventInfo} conMapData={conMapData}>
          {isMobile &&
            // <MobileSearchSection
            //   setShowKeywordsBox={setShowKeywordsBox}
            //   showKeywordsBox={showKeywordsBox}
            // />

            (eventId ? (
              <div className="list-container campaign">
                <div className="list-header">
                  <div className="top mobile">
                    <div className="mobile-header p-header">
                      <button
                        type="button"
                        className="page-back mobile"
                        onClick={() => {
                          isMobile && history.goBack();
                        }}
                      ></button>

                      {eventInfo ? (
                        <strong>
                          <EllipsisText
                            text={eventInfo.eventName.replaceAll("#", "")}
                            length={12}
                          />{" "}
                          제보 현황
                        </strong>
                      ) : (
                        <strong>제보 현황</strong>
                      )}

                      {/* 주변 제보 */}
                      {/* <button
                    className="current-location"
                    onClick={(e) => {
                      getCurrentPosition(false);
                      setCurrent(true);
                    }}
                  >
                    현 위치
                  </button> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <MobileSearchSection
                setShowKeywordsBox={setShowKeywordsBox}
                showKeywordsBox={showKeywordsBox}
              />
            ))}
          {!showKeywordsBox && <FeedSection eventInfo={eventInfo} />}
        </Sections>

        <Layers />
      </SearchContextProvider>
    </>
  );
}
const Container = styled.div`
  height: 120px;
  background: linear-gradient(90deg, #3484fd 0%, #29bef7 100%);
`;

const Section = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const Banner = styled.div`
  text-align: center;
  flex-direction: column;
  overflow-x: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Strong = styled.div`
  display: inline-block;
  font-size: 1.313rem;
  line-height: 1.3em;
  font-weight: 400;
  word-break: keep-all;
  color: #fff;
  margin-right: 20px;
`;

const AddButton = styled.div`
  margin-top: 13px;

  color: #fff;
  background: transparent;
  border: solid 2px #fff;

  display: inline-block;
  width: auto;
  min-width: 145px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 36px;
  font-size: 0.875rem;
  cursor: pointer;
`;
