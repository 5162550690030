import jeboApi from "api/api";
import Layers from "components/layers/Layers";
import Header from "components/pages/app/layouts/Header";
import Sections from "components/pages/mobileSubscribe/layouts/Sections";
import MobileSubscribeSection from "components/pages/mobileSubscribe/MobileSubscribeSection";
import MobileSubscribeContextProvider from "components/pages/mobileSubscribe/provider/MobileSubscribeContextProvider";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import LoadingComponent from "components/templates/LoadingComponent";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function MobileSubscribe() {
  const { isMobile } = useContext(isMobileContext);
  const [memberInfo, setMemberInfo] = useState(null);
  const { memberUid } = useParams();
  const { type } = useParams();
  const [loading, setLoading] = useState(true);
  const [layer, setLayer] = useState(null);

  const getMemberInfo = useCallback(async () => {
    try {
      const { status, data } = await jeboApi.getMemberInfo(memberUid);
      if (status === 200) setMemberInfo(JSON.parse(data.memberInfo));
    } catch (error) {
      console.log("error : ", error);
    } finally {
      setLoading(false);
    }
  }, [memberUid]);

  useEffect(() => {
    getMemberInfo();
  }, [memberUid, getMemberInfo]);

  return (
    <>
      <MobileSubscribeContextProvider layer={{ layer, setLayer }}>
        {!isMobile && <Header />}
        {loading ? (
          <LoadingComponent />
        ) : (
          <Sections>
            <MobileSubscribeSection memberInfo={memberInfo} type={type} />
          </Sections>
        )}
        <Layers />
      </MobileSubscribeContextProvider>
    </>
  );
}
