import jeboApi from "api/api";
import { feedsContext } from "components/providers/FeedContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import cameraIcon from "assets/imgs/icon_camera.svg";
import clearIcon from "assets/imgs/icons/exit_blue.svg";
import LoadingComponent from "./LoadingComponent";

export default function ReCommentWriteForm({
  comment,
  jeboId,
  setFeed,
  state,
  dispatch,
}) {
  const { isLoggedIn, handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);

  const { setFeeds } = useContext(feedsContext);

  const [mediaFile, setMediaFile] = useState({
    file: null,
    url: "",
  });
  const [isMedia, setIsMedia] = useState(mediaFile.url && true);
  const [loading, setLoading] = useState(false);
  const [reCommentText, setReCommentText] = useState("");
  const resizeTextarea = (e) => {
    e.target.style.height = e.target.scrollHeight + "px";
  };

  const areaRef = useRef();

  useEffect(() => {
    function handleOutside(e) {
      if (areaRef.current && !areaRef.current.contains(e.target)) {
        dispatch({ type: "INIT" });
      }
    }
    document.addEventListener("click", handleOutside);
    return () => {
      document.removeEventListener("click", handleOutside);
    };
  }, [areaRef]);

  const handleFileUpload = (e) => {
    var reader = new FileReader();
    let file = e.target.files[0];

    reader.onload = (e) => {
      setMediaFile({
        file: file,
        url: URL.createObjectURL(file),
      });
    };

    reader.readAsDataURL(file);
    setIsMedia(true);
  };

  const removeMedia = (removeMedia) => {
    URL.revokeObjectURL(removeMedia.url);
    setMediaFile({
      file: null,
      url: "",
    });
    setIsMedia(false);
  };

  const registReComment = async (e) => {
    if (!isLoggedIn) {
      setSignLayer("signIn");
      return;
    }
    setLoading(true);
    try {
      let mentions = reCommentText.trim().match(/@[^@]\S+/gi);
      mentions = mentions
        ? [...mentions]
            .map((text) => text.substring(1))
            .reduce((acc, obj) => (acc.includes(obj) ? acc : [...acc, obj]), [])
        : [];

      const {
        status,
        data: { comments, commentsCnt },
      } = await jeboApi.commentReg(
        mediaFile,
        reCommentText,
        jeboId,
        mentions,
        comment.parentCommentId ? comment.parentCommentId : comment.commentId,
        comment.memberUid
      );
      if (status === 200) {
        const updatedComments = JSON.parse(comments);
        setFeeds((feeds) =>
          feeds.map((item) => {
            if (jeboId === item.jeboId) {
              return Object.assign({}, item, { commentCnt: commentsCnt });
            }
            return item;
          })
        );
        setFeed((prev) => {
          return Object.assign({}, prev, { jeboCommentList: updatedComments });
        });
      }
    } catch (error) {
      console.log("error : ", error);

      if (error.response) {
        console.log("error : ", error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
        }
      }
    } finally {
      setLoading(false);
      dispatch({ type: "INIT" });
    }
  };

  return (
    <>
      <div className="comment-box cmt-active" ref={areaRef}>
        {mediaFile && isMedia && (
          <ReCommentMediaContainer>
            <ReCommentMediaCloseBtn
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                removeMedia(mediaFile);
                console.log(
                  ">>>>>>>>>>removeMedia e.target>>>>",
                  e.target,
                  "ref",
                  areaRef.current
                );
              }}
            >
              <i className="icon-cross3"></i>
            </ReCommentMediaCloseBtn>
            <ReCommentMediaImgContainer>
              <ReCommentMediaImg src={mediaFile.url} alt="commentMedia" />
            </ReCommentMediaImgContainer>
          </ReCommentMediaContainer>
        )}
        <div className="comment-input recomment-write">
          <textarea
            autoFocus
            className=""
            placeholder="답글을 입력하세요."
            onKeyUp={resizeTextarea}
            onKeyDown={resizeTextarea}
            onChange={(e) => setReCommentText(e.target.value)}
            onFocus={(e) => {
              e.target.scrollIntoView({ behavior: "smooth", block: "nearest" });
              !isLoggedIn && setSignLayer("signIn");
            }}
            value={reCommentText}
          ></textarea>

          <input
            type="file"
            className="file-upload"
            id="reCommentPic"
            onClick={(e) => {
              e.stopPropagation();
              if (!isLoggedIn) {
                e.preventDefault();
                setSignLayer("signIn");
              }
              e.target.value = null;
            }}
            onInput={handleFileUpload}
            accept="image/*"
          />
          <label className="reCommentPic" htmlFor="reCommentPic">
            <img src={cameraIcon} alt="이미지추가" />
          </label>
          <button
            onClick={(e) => {
              if (!reCommentText && !mediaFile.file) {
                alert("답글이나 이미지를 입력해주세요!");
                return;
              } else {
                registReComment();
                dispatch({ type: "INIT" });
              }
            }}
          >
            게시
          </button>
        </div>
      </div>
      {loading && <LoadingComponent />}
    </>
  );
}

const ReCommentMediaContainer = styled.div`
  width: 100%;
  max-height: 140px;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 50px;
  background-color: rgb(0 0 0 / 20%);
  z-index: 2;
`;

const ReCommentMediaImgContainer = styled.div`
  margin: 5px 0;
  height: 100%;
`;

const ReCommentMediaImg = styled.img`
  width: 25%;
`;

const ReCommentMediaCloseBtn = styled.button`
  position: absolute;
  right: 10px;
  top: 9px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  padding: 0;
  border: 1px solid #014681;
  border-radius: 50%;
  background: url(${clearIcon}) no-repeat;
  background-size: 10px;
  background-position: center center;
`;
