import { useHistory, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

export default function MyContentItem({ item, category, toggleLike }) {
  const history = useHistory();
  const location = useLocation();

  const {
    scId: socialId,
    scType: socialType,
    scTag: hashtags,
    scName: title,
    like,
    likeCount,
    memberCount,
    imageName,
  } = item;

  return (
    <ItemWrapper
      onClick={(e) => {
        history.push({
          ...location,
          pathname: `/social/${category.name}/${socialId}`,
        });
      }}
    >
      <ImageWrapper
        imageUrl={
          socialId &&
          process.env.REACT_APP_API_HOST + "/media/social/" + imageName
        }
      ></ImageWrapper>
      <ContentWrapper>
        <TextWrapper>
          <SocialTypeText
            type={
              socialType === 1
                ? "테마"
                : socialType === 2
                ? "캠페인"
                : socialType === 3
                ? "채널"
                : socialType === 4
                ? `커뮤 니티`
                : ""
            }
          >
            {socialType === 1
              ? "테마"
              : socialType === 2
              ? "캠페인"
              : socialType === 3
              ? "채널"
              : socialType === 4
              ? `커뮤니티`
              : ""}
          </SocialTypeText>
          {title}
        </TextWrapper>
      </ContentWrapper>
    </ItemWrapper>
  );
}

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 130px;
  height: 150px;

  @media screen and (min-width: 481px) {
    width: 124px;
  }

  margin-right: 14px;
  &:hover {
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  background: rgba(217, 217, 217, 0.57)
    url(${({ imageUrl }) => (imageUrl ? imageUrl : "")}) no-repeat;
  background-size: 100% 100%;

  border-radius: 4px;

  width: 100%;
  height: 104px;

  margin-bottom: 8px;
`;

const ContentWrapper = styled.div`
  background-color: white;
  height: 38px;
  padding: 1px;
`;

const TextWrapper = styled.div`
  overflow: hidden;
  word-break: break-all;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #292929;

  @media screen and (min-width: 481px) {
    font-size: 15px;
    line-height: 18px;
  }
`;

const SocialTypeText = styled.p`
  display: inline-block;
  margin-right: 5px;
  font-size: 12px;
  font-weight: 700;
  @media screen and (min-width: 481px) {
    font-size: 14px;
  }
  ${({ type }) => {
    if (type === "테마") {
      return css`
        color: #14b15c !important;
      `;
    } else if (type === "캠페인") {
      return css`
        color: #cc440a !important;
      `;
    } else if (type === "커뮤 니티") {
      return css`
        color: #bf1cf9 !important;
      `;
    } else if (type === "채널") {
      return css`
        color: #f08420 !important;
      `;
    }
  }};
`;
