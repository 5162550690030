import React from "react";

export default function TabSection({ activeMenu, setActiveMenu }) {
  return (
    <section className="section-mytab">
      <div className="container">
        <ul className="tab-btns">
          <li className={activeMenu === "MY" ? "btn active" : "btn"}>
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setActiveMenu("MY");
              }}
              className="my-tab"
            >
              게시물
            </a>
          </li>
          <li className={activeMenu === "HIDDEN" ? "btn active" : "btn"}>
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setActiveMenu("HIDDEN");
              }}
              className="my-tab"
            >
              숨김 게시물
            </a>
          </li>
          <li className={activeMenu === "CABINET" ? "btn active" : "btn"}>
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setActiveMenu("CABINET");
              }}
              className="my-tab"
            >
              보관 게시물
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
}
