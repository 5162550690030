import jeboApi from "api/api";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { useContext, useState } from "react";
import ConfirmLayer from "../popMenu/ConfirmLayer";

export default function MemberBlockLayer({ member, onBlock, onClose }) {
  const { isLoggedIn, handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const [reason, setReason] = useState("");
  const [showConfirmLayer, setShowConfirmLayer] = useState(false);

  const memberBlock = async () => {
    if (!isLoggedIn) {
      setSignLayer("signIn");
      return;
    }

    try {
      const { status } = await jeboApi.memberBlock(
        member.memberUid,
        member.nickName,
        reason
      );

      if (status === 200) onBlock(member.memberUid);
    } catch (error) {
      if (error.response) {
        console.log("error : ", error.response);
        if (error.response.status === 403) {
          handleSignOutApp();
          setSignLayer("signIn");
        } else if (error.response.status === 409) {
          alert("이미 차단된 사용자입니다.");
        } else {
          alert("시스템 에러 입니다. 잠시후 다시 시도해 주세요.");
        }
      }
    } finally {
      onClose();
    }
  };

  return (
    <div className="pop-menu item-pop report active">
      <div className="pop-header">
        <strong>차단 사유</strong>
        <button className="close" onClick={() => onClose()}>
          &times;
        </button>
      </div>
      <div className="pop-body">
        <form>
          <ul>
            <li>
              <textarea
                id="feed_report06_opinion"
                className="report-etc-opinion"
                value={reason}
                placeholder="필요 시, 차단 사유를 입력해주세요.(50자 이내)"
                onChange={(e) => {
                  if (e.target.value.length > 50) return;
                  setReason(e.target.value);
                }}
              />
            </li>
          </ul>
          <div className="btns">
            <button type="button" className="cancel" onClick={() => onClose()}>
              취소
            </button>
            <button
              type="button"
              className="confirm"
              onClick={(e) => {
                setShowConfirmLayer(true);
              }}
            >
              확인
            </button>
          </div>
        </form>
      </div>
      {showConfirmLayer && (
        <ConfirmLayer
          setShowConfirmLayer={setShowConfirmLayer}
          callback={memberBlock}
          title={`${member.nickName}님을 차단하시겠습니까?`}
          description=""
          btnText="차단하기"
          isConfirm={true}
        />
      )}
    </div>
  );
}
