import jeboApi from "api/api";
import user_icon from "assets/imgs/icons/user_icon.svg";
import MemberBlockLayer from "components/layers/memberBlock/MemberBlockLayer";
import CommentAddFeatLayer from "components/layers/popMenu/CommentAddFeatLayer";
import CommentReportLayer from "components/layers/report/CommentReportLayer";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { feedsContext } from "components/providers/FeedContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { Base64 } from "js-base64";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import ReComment from "./ReComment";
import ReCommentWriteForm from "./ReCommentWriteForm";
import CommentUpdateForm from "./CommentUpdateForm";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { Fragment } from "react";
import qs from "query-string";
import styled from "styled-components";
import MetaInfo from "./MetaInfo";
import Zoom from "react-medium-image-zoom";
import CommentDeleteConfirmLayer from "components/layers/confirm/CommentDeleteConfirmLayer";

const regDateCalculate = (date) => {
  const year = "20" + date.split(" ")[0].split(".")[0];
  const month = date.split(" ")[0].split(".")[1] - 1;
  const day = date.split(" ")[0].split(".")[2];
  const hours = date.split(" ")[1].split(":")[0];
  const seconds = date.split(" ")[1].split(":")[1];

  const now = new Date();
  const timeValue = new Date(year, month, day, hours, seconds);

  let betweenTime = Math.floor(
    (now.getTime() - timeValue.getTime()) / 1000 / 60
  );
  let betweenTimeHour = Math.floor(betweenTime / 60);
  let betweenTimeDay = Math.floor(betweenTime / 60 / 24);

  if (betweenTime < 1) {
    return "방금전";
  } else if (betweenTime < 60) {
    return `${betweenTime}분전`;
  } else if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간전`;
  } else {
    return `${betweenTimeDay}일전`;
  }
};

export default function Comment({
  comment,
  feed,
  setFeed,
  state,
  dispatch,
  focus,
  onClose,
  onBlock,
}) {
  const {
    commentId,
    contents,
    nickName,
    createAt,
    jeboNastedCommentList: reComments,
    memberImageUrl,
    memberUid,
    deleteYn,
    deleteViewYn, //삭제된 comment 중 노출여부 Y/N - (작성자에의해 삭제된 ..)
    commentMediaUrl,
  } = comment;

  const { isLoggedIn, handleSignOutApp, myInfo } = useContext(signAppContext);
  const { mobileType } = useContext(isMobileContext);
  const { setFeeds } = useContext(feedsContext);
  const { setSignLayer } = useContext(signLayerContext);

  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const { focusType, focusId } = qs.parse(location.search);

  const caculateDate = regDateCalculate(createAt);

  const [upCommentCnt, setUpCommentCnt] = useState(comment.upCommentCnt);
  const [downCommentCnt, setDownCommentCnt] = useState(comment.downCommentCnt);
  const [likeType, setLikeType] = useState(comment.likeType);

  //신고, 차단 시 해당 comment 및 recomment 정보 변수
  const [targetComment, setTargetComment] = useState(comment);

  const addFeatMenuRef = useRef();
  const commentRef = useRef();

  //const [needMetaInfo, setNeedMetaInfo] = useState(null);
  let urlStr = [];

  useEffect(() => {
    focus &&
      commentRef.current &&
      commentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  }, [focus]);

  useEffect(() => {
    if (comment) {
      setLikeType(comment.likeType);
      setUpCommentCnt(comment.upCommentCnt);
      setDownCommentCnt(comment.downCommentCnt);
    }
  }, [comment]);

  const toggleCommentLike = async (jeboId, commentId, like) => {
    if (!isLoggedIn) {
      setSignLayer("signIn");
      return;
    }
    try {
      const {
        status,
        data: { jeboCommentLike },
      } = await jeboApi.toggleCommentLike(jeboId, commentId, like);
      if (status === 200) {
        const updatedJeboCommentLike = JSON.parse(jeboCommentLike);

        setUpCommentCnt(
          updatedJeboCommentLike ? updatedJeboCommentLike.upCnt : 0
        );
        setDownCommentCnt(
          updatedJeboCommentLike ? updatedJeboCommentLike.downCnt : 0
        );
        setLikeType(likeType === like ? "" : like);
      }
    } catch (error) {
      console.log("error : ", error);
      if (error.response) {
        console.log("error.response : ", error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
        }
      }
    }
  };

  return (
    <>
      {state.name === "BLOCK" &&
        (document.querySelector(
          ".modal-detail .feed-detail .item-body-wrap"
        ).scrollTop = 0)}
      {state.name === "BLOCK" && state.id === commentId && (
        <MemberBlockLayer
          member={{
            memberUid: targetComment.memberUid,
            nickName: targetComment.nickName,
          }}
          onBlock={onBlock}
          onClose={onClose}
        />
      )}
      {state.name === "REPORT" && state.id === commentId && (
        <CommentReportLayer comment={targetComment} dispatch={dispatch} />
      )}
      {state.name === "DELETE" && state.id === commentId && (
        <CommentDeleteConfirmLayer
          setFeed={setFeed}
          comment={comment}
          dispatch={dispatch}
        />
      )}
      {deleteViewYn === "Y" && (
        <div
          className={
            deleteYn === "Y" && deleteViewYn === "Y"
              ? "comment-item delete"
              : "comment-item"
          }
          ref={commentRef}
        >
          <div className="thumb">
            <img
              src={
                memberImageUrl
                  ? process.env.REACT_APP_RESOURCE_HOST + memberImageUrl
                  : user_icon
              }
              alt={nickName}
              onClick={(e) => {
                e.preventDefault();
                if (myInfo && myInfo.memberUid === memberUid) {
                  history.push({ pathname: "/mypage" });
                } else {
                  history.push({
                    pathname: `/member/${encodeURIComponent(nickName)}`,
                  });
                }
              }}
            />
          </div>

          <div className="comment-text">
            <div className="top">
              <a
                href="#!"
                className="user-name"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.push({
                    pathname: `/member/${encodeURIComponent(nickName)}`,
                  });
                }}
              >
                {nickName}
              </a>
              <span>
                {(contents || commentMediaUrl) && deleteYn === "N" //삭제 안된 comments
                  ? contents
                      .split(/([#@http(s)][^\s\n]+)/g)
                      .map((word, idx) => {
                        if (word.match(/(^#[^\s\n]+)/g)) {
                          return (
                            <a
                              key={idx}
                              href="#!"
                              style={{ color: "#5293c5" }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push({
                                  pathname: `/search/${encodeURIComponent(
                                    word
                                  )}`,
                                });
                              }}
                            >
                              {`${word}`}
                            </a>
                          );
                        } else if (word.match(/(^@[^\s\n]+)/g)) {
                          return (
                            <a
                              key={idx}
                              href="#!"
                              style={{ color: "#5293c5" }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push({
                                  pathname: `/member/${encodeURIComponent(
                                    word.replace("@", "")
                                  )}`,
                                });
                              }}
                            >
                              {`${word}`}
                            </a>
                          );
                        } else if (
                          word.match(
                            /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}([\/a-z0-9-%#?&=\w])+(\.[a-z0-9]{2,4}(\?[\/a-z0-9-%#?&=\w]+)*)*/g
                          )
                        ) {
                          urlStr.push(
                            word.match(
                              /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}([\/a-z0-9-%#?&=\w])+(\.[a-z0-9]{2,4}(\?[\/a-z0-9-%#?&=\w]+)*)*/g
                            )
                          );
                          urlStr.push(word.replace(urlStr[0], ""));
                          return urlStr.map((splWord, idx) => {
                            if (idx == 0) {
                              return (
                                <a
                                  key={idx}
                                  rel="noreferrer"
                                  href="_EXTERNALURL"
                                  style={{
                                    color: "#191CEE",
                                    wordWrap: "break-word",
                                    // wordBreak: "keep-all",
                                    position: "relative",
                                    width: "min-content",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const url = splWord;
                                    if (mobileType === "ios") {
                                      const encodedUrl = Base64.encode(url);
                                      window.webkit.messageHandlers.openOuterLink.postMessage(
                                        encodedUrl
                                      );
                                    } else {
                                      window.open(url);
                                    }
                                  }}
                                >
                                  {splWord[0]}
                                </a>
                              );
                            } else {
                              return (
                                <Fragment key={idx}>{`${splWord}`}</Fragment>
                              );
                            }
                          });
                        }
                        return word;
                      })
                  : deleteViewYn === "Y" //삭제된 것 중 노출필요한 comments
                  ? "작성자에 의해 삭제된 댓글입니다."
                  : null}

                {/* {contents} */}
              </span>
            </div>
            {urlStr && urlStr.length > 0 && (
              <MetaInfo urlMessage={urlStr[0]} viewMode={"H"} />
            )}
            {deleteYn === "N" && commentMediaUrl && (
              <CommentMediaContainer>
                {/* <CommentImage
                  src={process.env.REACT_APP_RESOURCE_HOST + commentMediaUrl}
                /> */}
                <Zoom>
                  <CommentImage
                    src={process.env.REACT_APP_RESOURCE_HOST + commentMediaUrl}
                    alt=""
                  />
                </Zoom>
              </CommentMediaContainer>
            )}
            {deleteYn === "N" && (
              <div className="bottom">
                <div className="count">
                  <button
                    className={likeType === "UP" ? "btn up active" : "btn up"}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleCommentLike(
                        feed.jeboId,
                        commentId,
                        likeType === "UP" ? "" : "UP"
                      );
                    }}
                  ></button>
                  <span className={likeType === "UP" ? "text active" : "text"}>
                    {/* {likeCnt < 0 ? 0 : likeCnt} */}
                    {/* 22.08.01 이명삼 - 우선 기능 반영, 디자인 나오면 재반영 필요 */}
                    {upCommentCnt ? upCommentCnt : 0}
                  </span>
                  <button
                    className={
                      likeType === "DOWN" ? "btn down active" : "btn down"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      toggleCommentLike(
                        feed.jeboId,
                        commentId,
                        likeType === "DOWN" ? "" : "DOWN"
                      );
                    }}
                  ></button>
                  <span
                    className={likeType === "DOWN" ? "text active" : "text"}
                  >
                    {/* 22.08.01 이명삼 - 우선 기능 반영, 디자인 나오면 재반영 필요 */}
                    {downCommentCnt ? downCommentCnt : 0}
                  </span>
                </div>
                <button
                  className="comment-add"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch({
                      type: "MODE",
                      id: commentId,
                      name: "RECOMMENT",
                    });
                    e.target.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }}
                >
                  답글 달기
                </button>
                <span>{caculateDate}</span>
              </div>
            )}
            {state.name === "RECOMMENT" && state.id === commentId && (
              <ReCommentWriteForm
                feed={feed}
                comment={comment}
                jeboId={feed.jeboId}
                setFeed={setFeed}
                state={state}
                dispatch={dispatch}
              />
            )}
            {state.name === "UPDATE" && state.id === commentId && (
              <CommentUpdateForm
                comment={comment}
                jeboId={feed.jeboId}
                setFeed={setFeed}
                state={state}
                dispatch={dispatch}
              />
            )}
          </div>

          {deleteYn === "N" && (
            <button
              className="menu-open"
              ref={addFeatMenuRef}
              onClick={(e) =>
                dispatch({ type: "MENU_OPEN", id: commentId, name: "MENU" })
              }
            ></button>
          )}
          {state.name === "MENU" && state.id === commentId && (
            <CommentAddFeatLayer
              setFeed={setFeed}
              comment={comment}
              dispatch={dispatch}
            />
          )}
        </div>
      )}
      {reComments && reComments.length > 0 && (
        <>
          <div className="re-comments">
            <div className="comment-list">
              {reComments.map((reComment, index) => (
                <ReComment
                  key={index}
                  commentId={reComment.commentId}
                  setFeed={setFeed}
                  feed={feed}
                  comment={reComment}
                  state={state}
                  dispatch={dispatch}
                  setTargetComment={setTargetComment}
                  onClose={onClose}
                  onBlock={onBlock}
                  focus={
                    focusType &&
                    focusType === "comment" &&
                    focusId &&
                    focusId === reComment.commentId
                      ? true
                      : false
                  }
                />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}
const CommentMediaContainer = styled.div`
  margin: 10px 14px;
  padding: 5px 3vh;
  text-align: center;
  background-color: #ebeef5;
`;
const CommentImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 150px;
`;
