import React from "react";
import { render } from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import firebase from "./Firebase";

console.log(firebase);
window.history.scrollRestoration = "manual";
if (process.env.REACT_APP_MODE === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

console.log("REACT_APP_MODE : ", process.env.REACT_APP_MODE);

const rootElement = document.getElementById("root");
render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
