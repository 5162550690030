import jeboApi from "api/api";
import { SessionContext } from "components/providers/SessionContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

export default function FeedFooter({
  feed,
  showCommentBtn,
  onOpenPopMenu,
  onUpdateFeed,
  setToastMessage,
}) {
  const { jeboId, like, upCnt, downCnt } = feed;
  const { isLoggedIn, myInfo } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);
  const { onExpiredSession } = useContext(SessionContext);

  const history = useHistory();
  const match = useRouteMatch();

  const toggleLike = async (jeboId, like) => {
    if (!isLoggedIn) {
      setSignLayer("signIn");
      return;
    }

    try {
      const {
        status,
        data: { jeboPost },
      } = await jeboApi.toggleLike(jeboId, like);
      if (status === 200) {
        const updatedFeed = JSON.parse(jeboPost);
        console.log(updatedFeed);
        onUpdateFeed({
          jeboId,
          like,
          likeNickName:
            updatedFeed && updatedFeed.likeNickName
              ? updatedFeed.likeNickName
              : "",
          upCnt: updatedFeed && updatedFeed.upCnt ? updatedFeed.upCnt : 0,
          downCnt: updatedFeed && updatedFeed.downCnt ? updatedFeed.downCnt : 0,
        });
      }
    } catch (error) {
      console.log("error : ", error);
      if (error.response) {
        console.log("error.response : ", error.response);
        if (error.response.status === 403) {
          onExpiredSession();
        }
      }
    }
  };

  const handleAddFeedCabinet = async () => {
    if (feed.memberUid === myInfo.memberUid) {
      setToastMessage("제보한 게시물은 내 계정에서 확인해 주세요.");
      return;
    }

    if (!isLoggedIn) {
      history.replace({ pathname: `${match.url}/sign` });
      return;
    }

    try {
      const { status } = await jeboApi.addKeepFeed(feed.jeboId);

      if (status === 200) {
        onUpdateFeed({ ...feed, keeping: true });
        setToastMessage("보관 게시물에 추가되었습니다.");
      }
    } catch (error) {
      console.log("error : ", error);
      if (error.response) {
        console.log("error.response : ", error.response);
        if (error.response.status === 403) {
          onExpiredSession();
        }
      }
    }
  };

  const handleRemoveFeedCabinet = async () => {
    if (feed.memberUid === myInfo.memberUid) return;

    if (!isLoggedIn) {
      history.replace({ pathname: `${match.url}/sign` });
      return;
    }

    try {
      const { status } = await jeboApi.removeKeepFeed(feed.jeboId);

      if (status === 200) {
        onUpdateFeed({ ...feed, keeping: false });
        setToastMessage("보관이 취소되었습니다.");
      }
    } catch (error) {
      console.log("error : ", error);
      if (error.response) {
        console.log("error.response : ", error.response);
        if (error.response.status === 403) {
          onExpiredSession();
        }
      }
    }
  };

  return (
    <>
      <div className="item-bar-wrap">
        <div className="item-bar">
          <div className="count">
            <button
              className={like === "UP" ? "btn up active" : "btn up"}
              onClick={(e) => {
                e.preventDefault();
                toggleLike(jeboId, like === "UP" ? "" : "UP");
              }}
            ></button>
            <span className={like === "UP" ? "text active" : "text"}>
              {upCnt ? upCnt : 0}
            </span>
            <button
              className={like === "DOWN" ? "btn down active" : "btn down"}
              onClick={(e) => {
                e.preventDefault();
                toggleLike(jeboId, like === "DOWN" ? "" : "DOWN");
              }}
            ></button>
            <span className={like === "DOWN" ? "text active" : "text"}>
              {downCnt ? downCnt : 0}
            </span>
          </div>

          <div className="tools">
            <ul>
              <li>
                <button
                  className="btn share"
                  onClick={(e) => onOpenPopMenu(jeboId, "SHARE")}
                ></button>
              </li>
              <li>
                {feed.keeping ? (
                  <button
                    className="btn bookmark active"
                    id="bookmark_1"
                    onClick={(e) => {
                      handleRemoveFeedCabinet();
                    }}
                  ></button>
                ) : (
                  <button
                    className="btn bookmark"
                    id="bookmark_1"
                    onClick={(e) => {
                      handleAddFeedCabinet();
                    }}
                  ></button>
                )}
              </li>
              {showCommentBtn && (
                <li>
                  <button
                    className="btn comments"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `${match.url}/detail/${feed.jeboId}`,
                      });
                    }}
                  ></button>
                  {feed && feed.commentCnt > 0 && (
                    <span className="c-count">{feed.commentCnt}</span>
                  )}
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
