import React, { useEffect, useState } from "react";

export default function WeatherInfoBox({ weatherInfo }) {
  const [skyStatusClassName, setSkyStatusClassName] = useState(null);
  const [skyStatus, setSkyStatus] = useState(null);
  const [pm10Status, setPm10Status] = useState(null);
  const [pm10StatusClassName, setPm10StatusClassName] = useState(null);
  const [pm25Status, setPm25Status] = useState(null);
  const [pm25StatusClassName, setPm25StatusClassName] = useState(null);

  useEffect(() => {
    let precipitationType = weatherInfo.precipitationType;
    let skyType = weatherInfo.skyStatus;
    let pm10Grade = weatherInfo.pm10Status;
    let pm25Grade = weatherInfo.pm25Status;

    if (precipitationType > 0) {
      if (
        precipitationType === "2" ||
        precipitationType === "3" ||
        precipitationType === "6" ||
        precipitationType === "7"
      ) {
        setSkyStatus("눈");
        setSkyStatusClassName("wheather-type snowy");
      } else {
        setSkyStatus("비");
        setSkyStatusClassName("wheather-type rainy");
      }
    } else {
      if (skyType === "1") {
        setSkyStatus("맑음");
        setSkyStatusClassName("wheather-type sunny");
      }
      if (skyType === "3") {
        setSkyStatus("구름많음");
        setSkyStatusClassName("wheather-type cloudy");
      }
      if (skyType === "4") {
        setSkyStatus("흐림");
        setSkyStatusClassName("wheather-type gray");
      }
    }

    if (pm10Grade === "1") setPm10Status("좋음");
    else if (pm10Grade === "2") setPm10Status("보통");
    else if (pm10Grade === "3") setPm10Status("나쁨");
    else if (pm10Grade === "4") setPm10Status("매우나쁨");
    else setPm10Status("None");
    setPm10StatusClassName("dust-type type0" + pm10Grade);

    if (pm25Grade === "1") setPm25Status("좋음");
    else if (pm25Grade === "2") setPm25Status("보통");
    else if (pm25Grade === "3") setPm25Status("나쁨");
    else if (pm25Grade === "4") setPm25Status("매우나쁨");
    else setPm25Status("None");

    setPm25StatusClassName("dust-type type0" + pm25Grade);
  }, [weatherInfo]);

  return (
    <div className="item item-wheather">
      <div className="item-header-wrap">
        <div className="item-header">
          <div className="thumb thumb-weather"></div>
          <div className="info-ct">
            <div className="info">
              <span className="user-name">지금 날씨 어때?</span>
              <span className="location">{weatherInfo.addr}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="item-body-wrap">
        <div className="item-body">
          <div className="item-photo">
            <div className={skyStatusClassName}>
              <span className="w-current">
                {weatherInfo.fcstTime}시 현재, {weatherInfo.addr}
              </span>
              <div className="w-info">
                <span className="temp">
                  <strong>{weatherInfo.temperatures}˚</strong>
                  {skyStatus}
                </span>
                <div className="dust">
                  <div>
                    <span>미세먼지 </span>
                    <span className={pm10StatusClassName}>{pm10Status}</span>
                  </div>
                  <div>
                    <span>초미세먼지 </span>
                    <span className={pm25StatusClassName}>{pm25Status}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-info">
            {/* <div className="tags">
              <button className="tag type01">#날씨</button>
            </div> */}

            <div className="text">
              <div className="w-list">
                <span>
                  습도 <b>{weatherInfo.humidity}</b>%
                </span>
                <span className="dot"></span>
                <span>
                  강수 <b>{weatherInfo.precipitation}</b>
                </span>
                <span className="dot"></span>
                <span>
                  풍속 <b>{weatherInfo.wind}</b>m/s
                </span>
                <span className="dot"></span>
                <span>
                  풍향 <b>{weatherInfo.windDirection}</b>˚
                </span>
              </div>
            </div>
            <div className="tags weather-tags">
              <p className="weather-desc">
                <span className="weather-desc__notice">안내</span> : 데이터는
                실시간 관측된 자료이며 측정소 현지 사정이나 데이터의 수신상태에
                따라 미수신 될 수 있습니다.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="item-bar-wrap">
        <div className="item-bar wheather">
          <b>제공</b>
          <span>기상청, 한국환경공단</span>
        </div>
      </div>
    </div>
  );
}
