import { useEffect } from "react";

const useOuterClick = (ref, callback) => {
  useEffect(() => {
    const onClick = (e) => {
      if (callback && ref.current && !ref.current.contains(e.target))
        callback();
    };
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [ref, callback]);
};

export default useOuterClick;
