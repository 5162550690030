import React, { useContext, useState } from "react";
import AuthService from "api/AuthService";
import { signAppContext } from "components/providers/SignContextProvider";
import ConfirmLayer from "components/layers/popMenu/ConfirmLayer";
import { useHistory, useRouteMatch } from "react-router-dom";

export default function AccountManageContainer({ setActiveMenu }) {
  const { handleSignOutApp } = useContext(signAppContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [showConfirmLayer, setShowConfirmLayer] = useState(false);

  return (
    <div className="s-list-ct account-manage">
      <div className="s-body active">
        <div className="s-title">
          <button
            type="button"
            className="list-back mobile"
            onClick={(e) => setActiveMenu("")}
          ></button>
          <strong>계정 관리</strong>
        </div>
        <div className="s-inner account-manage">
          {AuthService.getSocialYn() !== "Y" && (
            <button
              className="setting-item under-line"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `${match.url}/passwdchange`,
                });
              }}
            >
              <span>비밀번호 변경</span>
            </button>
          )}
          <button
            className="setting-item under-line"
            onClick={(e) => {
              e.preventDefault();
              setShowConfirmLayer(true);
            }}
          >
            <span>로그아웃</span>
          </button>
          <button
            className="setting-item under-line"
            onClick={(e) => setActiveMenu("blockMember")}
          >
            <span>차단목록</span>
          </button>
          <button
            className="setting-item under-line"
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: `${match.url}/resign`,
              });
            }}
          >
            <span>회원탈퇴</span>
          </button>
        </div>
      </div>
      {showConfirmLayer && (
        <ConfirmLayer
          setShowConfirmLayer={setShowConfirmLayer}
          title="로그아웃 하시겠습니까?"
          description=""
          callback={() => {
            handleSignOutApp();
            setActiveMenu("");
            history.replace({ pathname: "/" });
          }}
        />
      )}
    </div>
  );
}
