import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useContext } from "react";
import { createPortal } from "react-dom";
import styled, { css, keyframes } from "styled-components";

export function PointPolicyContents({ isMobile, setViewPointPolicy }) {
  return (
    <DetailContainer mobile={isMobile}>
      <HeaderSection>
        <Title>포인트 적립 안내</Title>
        <CloseSection onClick={(e) => setViewPointPolicy(false)}>
          {isMobile ? <DownArrow /> : <CloseButton />}
        </CloseSection>
      </HeaderSection>
      <BodySection>
        - 활동 포인트는 제보 서비스 내에서 전월 1일부터 마지막날을 기준으로
        발생됩니다.
        <br />
        <br />
        - 보유한 포인트로 프로필 배지를 획득할 수 있습니다. 이 외에도 서비스
        내에서 다양한 혜택을 받으실 수 있도록 준비중입니다.
        <br />
        <br />
        - 포인트는 매월 마지막 날을 기점으로 갱신됩니다.
        <br />
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        <br />
        - 작성된 게시물, 댓글을 삭제할 경우 획득된 포인트가 차감 됩니다.
        <br />
        <br />
        - 회원 탈퇴 시, 그동안 획득한 모든 포인트는 소멸됩니다.
        <br />
        <br />- 포인트 발생 기준은 변경될 수 있습니다.
        {/** */}
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />
        - 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로 지급되는
        포인트는 제외됩니다.
        <br />- 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로
        지급되는 포인트는 제외됩니다. br - 포인트는 하루 최대 50P까지만
        지급됩니다. (단, 이벤트 보상으로 지급되는 포인트는 제외됩니다.
        <br />- 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로
        지급되는 포인트는 제외됩니다.br - 포인트는 하루 최대 50P까지만
        지급됩니다. (단, 이벤트 보상으로 지급되는 포인트는 제외됩니다.
        <br />- 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로
        지급되는 포인트는 제외됩니다.br - 포인트는 하루 최대 50P까지만
        지급됩니다. (단, 이벤트 보상으로 지급되는 포인트는 제외됩니다.
        <br />- 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로
        지급되는 포인트는 제외됩니다.br - 포인트는 하루 최대 50P까지만
        지급됩니다. (단, 이벤트 보상으로 지급되는 포인트는 제외됩니다.
        <br />- 포인트는 하루 최대 50P까지만 지급됩니다. (단, 이벤트 보상으로
        지급되는 포인트는 제외됩니다.
      </BodySection>
    </DetailContainer>
  );
}

export default function PointPolicy({ viewPointPolicy, setViewPointPolicy }) {
  const { isMobile } = useContext(isMobileContext);

  useEffect(() => {
    if (isMobile) return;
    //부모창 스크롤 방지
    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    return () => {
      //부모창 스크롤 방지 해제
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return isMobile ? (
    <MobileContainer viewPolicy={viewPointPolicy}>
      <PointPolicyContents
        isMobile={isMobile}
        setViewPointPolicy={setViewPointPolicy}
      />
    </MobileContainer>
  ) : (
    createPortal(
      <Modal>
        <WebContainer>
          <PointPolicyContents
            isMobile={isMobile}
            setViewPointPolicy={setViewPointPolicy}
          />
        </WebContainer>
      </Modal>,
      document.getElementById("modal")
    )
  );
}

/* 포인트 안내 팝업 */
const open = keyframes`
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0%;
  }
`;
const close = keyframes`
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0%;
  }
`;

/* Mobile CSS Start */
const MobileContainer = styled.div`
  position: fixed;
  background-color: #f5f5f5;
  height: calc(100% - 48px);
  width: auto;
  bottom: 0%;

  animation: ${open} 0.2s ease-in-out;
`;
/* Mobile CSS End */

/* Web Modal CSS Start */
const Modal = styled.div`
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 700px;

  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
`;
const WebContainer = styled.div`
  position: fixed;
  max-width: 700px;
  height: 520px;
  top: 30%;
  margin-left: 15%;
  border-radius: 30px;
  background-color: #f5f5f5;
`;
/* Web Modal CSS End */

const DetailContainer = styled.div`
  //ScrollBar 숨기기
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }

  //Mobile || Web Height
  ${(props) =>
    props.mobile
      ? css`
          height: 90%;
        `
      : css`
          height: 95%;
        `}
`;

const HeaderSection = styled.p`
  display: flex;
  position: absolute;
  width: 100%;
  height: 70px;
  font-size: 25px;
  font-weight: bold;
  background-color: #f5f5f5;
  border-radius: 30px 30px 0 0;
  text-align: center;
  padding: 25px;
`;

const Title = styled.strong`
  width: 90%;
  margin-left: 10%;
`;

const CloseSection = styled.div`
  width: 10%;
  text-align: right;
`;

const CloseButton = styled.button`
  padding-top: 20px;
  height: 15px;
  width: 15px;
  cursor: pointer;
  background: url(/static/media/exit.9fc98549.svg) no-repeat;
`;

const BodySection = styled.p`
  padding: 5%;
  line-height: 1.2;
  margin-top: 70px;
  color: var(--text-defaultGrayColor);
  font-size: 12px;

  word-break: break-all;
`;

const DownArrow = styled.span`
  position: absolute;
  left: 93%;
  width: 10px;
  height: 10px;
  border-top: 2px solid #999;
  border-right: 2px solid #999;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
`;
