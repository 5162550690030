import jeboApi from "api/api";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";

/* 아이콘 이미지 추후 변경 필요 */
import question_icon from "assets/imgs/icons/question.png"; //활동포인트물음표
import follow_icon from "assets/imgs/icons/m_my.svg"; //구독/관심구독
import feeds_icon from "assets/imgs/icons/speechbubble.svg"; //피드목록
import ProgressBar from "@ramonak/react-progress-bar";
import CollectBadgeDetailWeb from "components/templates/CollectBadgeDetailWeb";
import SubscribeBox from "components/templates/SubscribeBox";
import { useInView } from "react-intersection-observer";
import PointPolicy from "components/layers/policy/PointPolicy";
import SubscribePop from "components/layers/popMenu/SubscribePop";

export default function MyInfoContainer() {
  const END_LIMIT = 10;
  const history = useHistory();
  const { handleSignOutApp, setSignLayer } = useContext(signLayerContext);

  const { myInfo, isLoggedIn } = useContext(signAppContext);

  const [followerCnt, setFollowerCnt] = useState(null);
  const [followingCnt, setFollowingCnt] = useState(null);
  const [totFeedCnt, setTotFeedCnt] = useState(null);
  const [showReward, setShowReward] = useState(null);
  const [totalActPoint, setTotalActPoint] = useState(0);

  const [viewFollowerBox, setViewFollowerBox] = useState(false);
  const [viewFollowingBox, setViewFollowingBox] = useState(false);

  const now = new Date();
  const nowYear = now.getFullYear();
  const nowMonth = ("0" + (1 + now.getMonth())).slice(-2);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [memberReward, setMemberReward] = useState(null);

  const [myPointList, setMyPointList] = useState([]);
  const [isOpen, setIsOpen] = useState(null);
  const [lastFeedRef, inView] = useInView({ threshold: 0 });
  const [cursor, setCursor] = useState(0);
  const [loading, setLoading] = useState(true);
  const [notLoadedData, setNotLoadedData] = useState(false);
  const [error, setError] = useState(null);

  const [viewPointPolicy, setViewPointPolicy] = useState(false);

  const setFollowValue = (isUnfollow) => {
    isUnfollow
      ? setFollowingCnt((prev) => prev - 1)
      : setFollowingCnt((prev) => parseInt(prev) + 1);
  };

  const handleBadgeClick = (memberReward) => {
    setMemberReward(memberReward);
    setModalIsOpen(!modalIsOpen);
  };

  const handleBadgeClickMobile = (isclickBackground) => {
    isclickBackground && setModalIsOpen(!modalIsOpen);
  };

  const getFollowCnt = useCallback(
    async (response) => {
      try {
        const { status, data } = await jeboApi.getMyProfileInfo(
          myInfo.memberUid,
          nowYear,
          nowMonth
        );

        if (status === 200) {
          const followCnt = data.followCnt;
          const rewards = data.rewardList;
          const point = data.activitiesPoint;

          setFollowerCnt(followCnt.followerCnt);
          setFollowingCnt(followCnt.followingCnt);
          setTotFeedCnt(followCnt.totFeedCnt);
          setShowReward(rewards);
          setTotalActPoint(point);
        }
      } catch (error) {
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    },
    [myInfo]
  );

  const loadMyPoint = useCallback(async () => {
    console.log(">> LoadMyPoint");
    try {
      const { status, data } = await jeboApi.loadMyActivityPoint(
        nowYear,
        nowMonth,
        END_LIMIT,
        cursor
      );
      if (status === 200) {
        const actPoint = JSON.parse(data.actPoint);
        setNotLoadedData(actPoint.length < END_LIMIT ? true : false);

        if (cursor === 0) setMyPointList(actPoint);
        else setMyPointList((prev) => prev.concat(actPoint));
      } else if (status === 204) {
        setNotLoadedData(true);
        console.log("no data to be loaded..");
      }
    } catch (error) {
      if (error.response) {
        console.log("Error : ", error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요");
          handleSignOutApp();
          setSignLayer("signIn");
          history.replace({ pathname: "/" });
        }
      }
    } finally {
      setLoading(false);
    }
  }, [cursor, isOpen, handleSignOutApp, setSignLayer]);

  useEffect(() => {
    isOpen && loadMyPoint();
  }, [isOpen, cursor]);

  useEffect(() => {
    if (inView && !error && !notLoadedData) {
      setCursor((prev) => prev + END_LIMIT);
    }
  }, [inView, error, notLoadedData]);

  useEffect(() => {
    getFollowCnt();
  }, [myInfo]);

  return (
    <>
      <Container>
        <Section height={100} last={false}>
          <TitleStrong>나의정보</TitleStrong>
          <Contents width={24}>
            <ClickContent
              onClick={(e) => {
                e.preventDefault();
                if (isLoggedIn) {
                  history.push({
                    pathname: `/mypage`,
                  });
                } else {
                  setSignLayer("signIn");
                }
              }}
            >
              <Icon src={feeds_icon} alt="게시물 수" />
              <Value>{totFeedCnt}</Value>
            </ClickContent>
          </Contents>
          <Contents width={24}>
            <ClickContent
              onClick={(e) => {
                e.preventDefault();
                if (isLoggedIn) {
                  setViewFollowerBox((prev) => !prev);
                } else {
                  setSignLayer("signIn");
                }
              }}
            >
              <Icon src={follow_icon} alt="구독자" />
              <Value>{followerCnt}</Value>
            </ClickContent>
          </Contents>
          <Contents width={24}>
            <ClickContent
              onClick={(e) => {
                e.preventDefault();
                if (isLoggedIn) {
                  setViewFollowingBox((prev) => !prev);
                } else {
                  setSignLayer("signIn");
                }
              }}
            >
              <Icon src={follow_icon} alt="관심구독" />
              <Value>{followingCnt}</Value>
            </ClickContent>
          </Contents>
          {(viewFollowerBox || viewFollowingBox) && (
            <SubscribePop
              viewFollowerBox={viewFollowerBox}
              viewFollowingBox={viewFollowingBox}
              memberInfo={myInfo}
              setFollowValue={setFollowValue}
              setViewFollowerBox={setViewFollowerBox}
              setViewFollowingBox={setViewFollowingBox}
            ></SubscribePop>
          )}
        </Section>
        <Section last={false}>
          <TitleStrong>
            활동포인트
            <PointQuestion
              src={question_icon}
              onClick={(e) => {
                e.preventDefault();
                setViewPointPolicy((prev) => !prev);
              }}
            />
          </TitleStrong>
          <Contents width={60}>
            <ProgressBar
              className="my_progress_bar"
              labelAlignment="outside"
              labelColor="black"
              customLabelStyles={progressLayer}
              completed={Number((totalActPoint / 15).toFixed(1))}
              maxCompleted={100}
              bgColor="linear-gradient(316.2deg,#3773ff -3.34%,#22e6f3 131.85%)"
              height="30px"
            />
          </Contents>
          <ArrowBox onClick={(e) => setIsOpen((prev) => !prev)}>
            <NextArrow open={isOpen} />
          </ArrowBox>
          {viewPointPolicy && (
            <PointPolicy
              viewPointPolicy={viewPointPolicy}
              setViewPointPolicy={setViewPointPolicy}
            />
          )}
          <PointWrap>
            <PointContainer open={isOpen}>
              <PointItems>
                {myPointList.length > 0
                  ? myPointList.map((point, index) => (
                      <PointItem key={index}>
                        <DescSection>
                          <PointDate>{point.createAt}</PointDate>
                          <PointType>
                            {point.pointType === "CH"
                              ? "출석"
                              : point.pointType === "CF"
                              ? "게시물 작성"
                              : point.pointType === "DF"
                              ? "게시물 삭제"
                              : point.pointType === "CC"
                              ? "댓글 작성"
                              : point.pointType === "DC"
                              ? "댓글 삭제"
                              : point.pointType === "US"
                              ? "포인트 사용"
                              : ""}
                          </PointType>
                        </DescSection>
                        <PointSection>
                          <Point operation={point.operation}>
                            {point.operation === "P" ? "+" : "-"}
                            {point.point} point
                          </Point>
                        </PointSection>
                      </PointItem>
                    ))
                  : notLoadedData && (
                      <NoData>이번 달 적립된 포인트가 없습니다.</NoData>
                    )}
                {myPointList.length > 0 && <div ref={lastFeedRef}></div>}
              </PointItems>
            </PointContainer>
          </PointWrap>
        </Section>
        <Section height={100} last={true}>
          <TitleStrong width={100} style={{ margin: "20px" }}>
            획득 배지
          </TitleStrong>
          {showReward &&
            showReward.map((reward, index) => {
              return (
                <BadgeItem
                  key={index}
                  onClick={(e) => {
                    handleBadgeClick(reward);
                  }}
                >
                  <BadgeIcon
                    active={reward.memberUid ? true : false}
                    alt="배지"
                    className="icon active"
                    src={
                      process.env.REACT_APP_RESOURCE_HOST +
                      reward.rewardImagePath
                    }
                  />
                  <BadgeName>{reward.rewardName}</BadgeName>
                </BadgeItem>
              );
            })}
          {modalIsOpen && (
            <CollectBadgeDetailWeb
              handleBadgeClickMobile={handleBadgeClickMobile}
              memberReward={memberReward}
            />
          )}
        </Section>
      </Container>
    </>
  );
}

//Progressbar style
const progressLayer = {
  position: "absolute",
  marginLeft: "53%",
  color: "black",
  fontWeight: "bold",
  fontSize: "15px",
};
//styled-component 정의

const openIcon = keyframes`
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(135deg);
    
  }
`;

const closeIcon = keyframes`
  0% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(45deg);
    
  }
`;
const openMenu = keyframes`
  0% {
    transform: translateY(-100%);
    height: 0px;
  }
  100% {
    transform: rotate(0%);
    height: 500px;
    
  }
`;

const closeMenu = keyframes`
  0% {
    transform: rotate(0%);
    height: 500px;
    
  }
  100% {
    transform: translateY(-100%);
    height: 0px;
    
  }
`;

const Container = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  //padding: 40px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: ${(props) => (props.last ? "" : "10px solid #ececec")};
  height: ${(props) => `${props.height}px`};
`;

const TitleStrong = styled.div`
  color: #000;
  font-size: 1.5rem;
  font-weight: 600;
  margin: auto 20px;
  width: ${(props) => (props.width ? `${props.width}%` : "20%")};
`;

const Contents = styled.div`
  display: flex;
  //height: 100%;
  font-weight: bold;
  font-size: 25px;
  width: ${(props) => `${props.width}%`};
`;

const ClickContent = styled.div`
  width: 40%;
  display: flex;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin: auto 0;
`;
const Value = styled.div`
  margin: auto 20px;
`;

const ArrowBox = styled.div`
  /* width: 15%; */
  padding: 4%;
  cursor: pointer;
  position: relative;
`;
const NextArrow = styled.span`
  position: absolute;
  /* left: ${(props) => (props.type === "arrowType" ? "90%" : "87%")};
  top: 15.5%; */
  top: 30px;
  right: 0;
  content: "";
  width: 10px;
  height: 10px;
  border-top: 3px solid #999;
  border-right: 3px solid #999;
  transform: rotate(45deg);
  ${(props) =>
    props.open
      ? css`
          animation: ${openIcon} 0.2s ease-in-out;
          animation-fill-mode: both;
        `
      : css`
          animation: ${closeIcon} 0.2s ease-in-out;
          animation-fill-mode: both;
        `}
`;
const BadgeItem = styled.div`
  width: 14%;
  height: 100%;
  text-align: center;
  cursor: pointer;
`;

const BadgeIcon = styled.img`
  width: ${(props) => (props.active ? "80%" : "60%")};
  height: 110px;
  object-fit: ${(props) => (props.active ? "cover" : "contain")};
`;
const BadgeName = styled.div`
  font-size: 14px;
`;

////////////////
const PointWrap = styled.div`
  width: 100%;
  overflow: hidden;
`;

const PointContainer = styled.div`
  width: 100%;
  //애니메이션 open === true ? open : (open !== null && close)
  ${(props) =>
    props.open
      ? css`
          padding: 15px;
          margin-bottom: 30px;
          overflow-y: auto;
          animation: ${openMenu} 0.2s ease-in-out;
          animation-fill-mode: both;
          &::-webkit-scrollbar {
            width: 0 !important;
            display: none;
          }
        `
      : css`
          ${(props) =>
            props.open !== null &&
            css`
              animation: ${closeMenu} 0.2s ease-in-out;
              animation-fill-mode: both;
            `}
        `}
`;
const PointItems = styled.div`
  width: 90%;
  background-color: aliceblue;
  margin: auto;
`;

const PointItem = styled.div`
  padding: 0 12px;
  padding-top: 15px;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #4e4e4e5e;
`;

const DescSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

const PointDate = styled.span`
  width: 100%;
  font-size: 12px;
  color: #4e4e4e9c;
`;

const PointType = styled.span`
  width: 100%;
  font-size: 20px;
  margin: 3px 0;
`;

const PointSection = styled.div`
  height: 30px;
  width: 23%;
  text-align: center;
  margin: 15px;
  background-color: lightgray;
  border-radius: 20px;
  margin: auto;
`;

const Point = styled.span`
  font-size: 15px;
  line-height: 30px; // 상위 Div Height과 동일하게
  color: ${(props) => (props.operation === "P" ? "black" : "red")};
`;

const NoData = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
`;

const PointQuestion = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
