import React, { createContext } from "react";

export const layerContext = createContext({
  layer: null,
  setLayer: () => {},
});

export default function LayerContextProvider({ layer, children: Components }) {
  return (
    <layerContext.Provider value={layer}>{Components}</layerContext.Provider>
  );
}
