import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import HistorySearchKeywordBox from "./HistorySearchKeywordBox";
import RecommendSearchKeywordBox from "./RecommendSearchKeywordBox";

export default function HeaderSearchContainer() {
  const history = useHistory();
  const params = useParams();

  const [searchKeyword, setSearchKeyword] = useState(
    params && params.keyword ? decodeURIComponent(params.keyword) : ""
  );
  const [showKeywordsBox, setShowKeywordsBox] = useState(false);

  const searchBoxRef = useRef();

  useEffect(() => {
    // 검색영역 밖 클릭시 닫기
    window.addEventListener("click", handleClickCloseMenuBox);
    window.addEventListener("keydown", handleKeypressCloseMenuBox);
    return () => {
      window.removeEventListener("click", handleClickCloseMenuBox);
      window.removeEventListener("keydown", handleKeypressCloseMenuBox);
    };
  }, []);

  useEffect(() => {
    setSearchKeyword(
      params && params.keyword ? decodeURIComponent(params.keyword) : ""
    );
  }, [params]);

  const handleKeypressCloseMenuBox = (e) => {
    e.key === "Escape" && setShowKeywordsBox(false);
  };

  const handleClickCloseMenuBox = (e) => {
    searchBoxRef.current &&
      !searchBoxRef.current.contains(e.target) &&
      setShowKeywordsBox(false);
  };

  const handleSearchKeyword = (keyword) => {
    if (!keyword) return;

    let historyKeywords =
      localStorage.getItem("searchHistory") &&
      JSON.parse(localStorage.getItem("searchHistory"));

    let storeKeywords = [keyword];
    if (historyKeywords && historyKeywords.length > 0) {
      storeKeywords = historyKeywords.filter((item) => keyword !== item);
      storeKeywords.length >= 5 && storeKeywords.pop();
      storeKeywords = [keyword, ...storeKeywords];
    }

    keyword &&
      localStorage.setItem("searchHistory", JSON.stringify(storeKeywords));

    setShowKeywordsBox(false);
    history.push({
      pathname: `/search/${encodeURIComponent(encodeURIComponent(keyword))}`,
    });
  };

  return (
    <div className="search-ct pc" ref={searchBoxRef}>
      <div className="top-search">
        <input
          type="text"
          className="top-search"
          placeholder="검색어를 입력해주세요."
          onChange={(e) => {
            setShowKeywordsBox(true);
            setSearchKeyword(e.target.value);
          }}
          onKeyPress={(e) => {
            e.key === "Enter" && handleSearchKeyword(searchKeyword);
          }}
          onFocus={(e) => {
            setShowKeywordsBox(true);
          }}
          autoComplete="off"
          value={searchKeyword}
        />
        <button
          className="search-btn"
          onClick={(e) => {
            e.stopPropagation();
            handleSearchKeyword(searchKeyword);
          }}
        ></button>
      </div>
      {showKeywordsBox && (
        <div className="keyword-wrap on">
          <div className="keywords-ct">
            <RecommendSearchKeywordBox
              handleSearchKeyword={handleSearchKeyword}
            />
            <HistorySearchKeywordBox
              handleSearchKeyword={handleSearchKeyword}
            />
          </div>
        </div>
      )}
    </div>
  );
}
