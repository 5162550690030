import jeboApi from "api/api";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { activeLocationContext } from "components/providers/LocationContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import redMarkerImg from "../../../assets/imgs/icons/redmarker.svg";

const debouncer = (() => {
  let debounceCheck;

  return {
    debounce(callback, milliseconds) {
      clearTimeout(debounceCheck);
      debounceCheck = setTimeout(() => {
        callback(...arguments);
      }, milliseconds);
    },
  };
})();

export default function BannerMapContainer() {
  const kakaoMapContainer = useRef();
  const kakao = window.kakao;

  const { isMobile } = useContext(isMobileContext);
  const { activeLocation } = useContext(activeLocationContext);
  const { handleSignOutApp } = useContext(signAppContext);

  const [map, setMap] = useState(null);
  // const [clusterer, setClusterer] = useState(null);
  const [customOverlay, setCustomOverlay] = useState(null);

  const [originMarkers, setOriginMarkers] = useState([]);
  const [callFromClusterer, setCallFromClusterer] = useState(false);
  const [markerPositions, setMarkerPositions] = useState([]);
  const [mapRect, setMapRect] = useState(null);

  //const [error, setError] = useState("");
  const history = useHistory();

  const createCustomOverlayContent = useCallback(
    (count) => {
      const texts = isMobile
        ? `<div class="map-info" style="
    top:-75px;
    left: 5px;"><span class="count"><b>${count}</b>건</span><div>`
        : `<div class="map-info" style="
    top:-90px;
    left: 12px;"><span class="count"><b>${count}</b>건</span><div>`;

      return texts;
    },
    [isMobile]
  );

  const createCustomOveray = useCallback(
    (mapObj) => {
      // 마커 클러스터러를 생성합니다
      const clustererObj = new kakao.maps.MarkerClusterer({
        map: mapObj, // 마커들을 클러스터로 관리하고 표시할 지도 객체
        averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정
        minLevel: 5, // 클러스터 할 최소 지도 레벨
        minClusterSize: 100000,
        disableClickZoom: true,
        styles: [{}],
        texts: createCustomOverlayContent,
      });

      setCustomOverlay(clustererObj);
    },
    [kakao, createCustomOverlayContent]
  );

  window.eventFunction = (index) => {
    if (map.getLevel() > 1) {
      map.setLevel(map.getLevel() - 2);
      map.setCenter(
        new kakao.maps.LatLng(
          markerPositions[index].latitude,
          markerPositions[index].longitude
        )
      );
    }
  };

  const customMarker = useCallback(() => {
    customOverlay.clear();
    const markers = markerPositions.map((feed, index) => {
      var content = isMobile
        ? '<div class="map-info" onclick="eventFunction(' +
          index +
          ')" style="top:-75px; left: 5px;"><span class="count"><b>' +
          feed.jeboId +
          "</b>건</span><div>"
        : '<div class="map-info" onclick="eventFunction(' +
          index +
          ')" style="top:-75px; left: 5px;"><span class="count"><b>' +
          feed.jeboId +
          "</b>건</span><div>";

      var marker = new kakao.maps.CustomOverlay({
        position: new kakao.maps.LatLng(feed.latitude, feed.longitude),
        content: content,
      });

      return marker;
    });

    // 클러스터러에 마커들을 추가합니다
    customOverlay.addMarkers(markers, false);

    customOverlay.setTexts(createCustomOverlayContent);
  }, [kakao, customOverlay, markerPositions, createCustomOverlayContent]);

  useEffect(() => {
    markerPositions && customOverlay && customMarker();
  }, [markerPositions, customOverlay, customMarker]);

  const handleCenterChange = useCallback(() => {
    debouncer.debounce(() => {
      setMapRect({
        northEastLatitude: map.getBounds().getNorthEast().getLat(),
        northEastLongitude: map.getBounds().getNorthEast().getLng(),
        southWestLatitude: map.getBounds().getSouthWest().getLat(),
        southWestLongitude: map.getBounds().getSouthWest().getLng(),
        scale: map.getLevel(),
      });
    }, 500);
  }, [map, setMapRect]);

  useEffect(() => {
    map &&
      kakao.maps.event.addListener(map, "center_changed", handleCenterChange);
    return () => {
      map &&
        kakao.maps.event.removeListener(
          map,
          "center_changed",
          handleCenterChange
        );
    };
  }, [kakao, map, handleCenterChange]);

  const initKakaoMap = useCallback(() => {
    const { latitude, longitude } = activeLocation;
    const coords = new kakao.maps.LatLng(latitude, longitude);

    //해당 좌표에 대한 맵 로드
    const mapObj = new kakao.maps.Map(kakaoMapContainer.current, {
      center: coords,
      level: 4,
      draggable: true,
      disableDoubleClick: true,
    });

    /* 확대 축소 컨트롤 */
    const zoomControl = new kakao.maps.ZoomControl();
    mapObj.addControl(zoomControl, kakao.maps.ControlPosition.BOTTOMRIGHT);

    setMap(mapObj);
    createCustomOveray(mapObj);

    debouncer.debounce(() => {
      setMapRect({
        northEastLatitude: mapObj.getBounds().getNorthEast().getLat(),
        northEastLongitude: mapObj.getBounds().getNorthEast().getLng(),
        southWestLatitude: mapObj.getBounds().getSouthWest().getLat(),
        southWestLongitude: mapObj.getBounds().getSouthWest().getLng(),
      });
    }, 300);
  }, [kakao, createCustomOveray, activeLocation]);

  useEffect(() => {
    !map &&
      activeLocation &&
      kakao.maps.load(() => {
        initKakaoMap();
      });
  }, [kakao, map, activeLocation, initKakaoMap]);

  // 마커 이미지 변경 0804

  useEffect(() => {
    if (map && activeLocation) {
      // map.setLevel(5);
      map.setCenter(
        new kakao.maps.LatLng(activeLocation.latitude, activeLocation.longitude)
      );
      /* 현재위치마커 (Init이후 다시 그려져서인지 마커가 사라지니 이쪽에서 처리) */
      const markerPositions = new kakao.maps.LatLng(
        activeLocation.latitude,
        activeLocation.longitude
      );

      // 마커 이미지 변경
      const imageSrc = redMarkerImg,
        // "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png"
        // 마커이미지의 주소입니다
        imageSize = new kakao.maps.Size(45, 45),
        // 마커이미지의 크기입니다
        imageOption = { offset: new kakao.maps.Point(27, 69) };
      // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

      // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
      const markerImage = new kakao.maps.MarkerImage(
        imageSrc,
        imageSize,
        imageOption
      );

      const marker = new kakao.maps.Marker({
        position: markerPositions,
        image: markerImage,
        zIndex: 10,
      });
      marker.setMap(map);
      //
    }
  }, [kakao, map, activeLocation]);

  useEffect(() => {
    setMarkerPositions([]);
  }, [mapRect]);

  const loadFeeds = useCallback(async () => {
    try {
      const neLat = mapRect.northEastLatitude;
      const neLng = mapRect.northEastLongitude;
      const swLat = mapRect.southWestLatitude;
      const swLng = mapRect.southWestLongitude;
      const aroundScale =
        mapRect.scale == null
          ? "3"
          : mapRect.scale < 3
          ? "4"
          : mapRect.scale < 6
          ? "3"
          : mapRect.scale < 8
          ? "2"
          : mapRect.scale < 11
          ? "1"
          : mapRect.scale < 20
          ? "0"
          : 0;

      const { status, data } = await jeboApi.aroundMapFeedList(
        neLat,
        neLng,
        swLat,
        swLng,
        "",
        "",
        0,
        15,
        0,
        aroundScale
      );

      if (status === 200) {
        const jeboPostResult = JSON.parse(data.jeboPost);
        const jeboPositionArr =
          jeboPostResult && jeboPostResult.jeboPositionList;
        setMarkerPositions(jeboPositionArr);
      } else if (status === 204) {
        console.log("no data to be loaded..");
        //setError("nodata");
      }
    } catch (error) {
      console.log(error.response);
      if (error.response?.status === 403) {
        alert("세션이 만료되었습니다. 다시 로그인해주세요.");
        handleSignOutApp();
      }
      //setError(error);
    }
  }, [mapRect, handleSignOutApp]);

  useEffect(() => {
    mapRect && loadFeeds();
  }, [mapRect, loadFeeds]);

  return (
    <>
      {true ? (
        <div className="section-map">
          {/* 현재위치 */}
          <button
            className="traffic-map-btn live-around"
            onClick={(e) => {
              if (map && activeLocation) {
                map.setCenter(
                  new kakao.maps.LatLng(
                    activeLocation.latitude,
                    activeLocation.longitude
                  )
                );
              }
            }}
          >
            현재위치
          </button>

          {/* 0110 크게보기 버튼 */}
          <button
            className="traffic-map-btn live-traffic"
            onClick={(e) => {
              history.push({ pathname: "/around" });
            }}
          >
            크게보기
          </button>
          {/* 0110 크게보기 버튼 */}
          <div className="map-container" ref={kakaoMapContainer}></div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
