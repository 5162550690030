import styled, { css } from "styled-components";
import iconHeart from "assets/imgs/icons/icon_heart.svg";
import iconBack from "assets/imgs/icons/prev.svg";
import { AiOutlineBorder } from "react-icons/ai";

export default function DetailNavButton({ text, onClick, type }) {
  return (
    <Button prev={iconBack} iconHeart={iconHeart} type={type} onClick={onClick}>
      {type !== "list" ? text : ""}
    </Button>
  );
}

const Button = styled.button`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  background-color: white;

  /* border: 1px solid #292929; */
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  margin: 0 3px;

  color: #292929;

  padding: 9px 14px;
  &:hover {
    background-color: #3773ff;
    color: white;
  }

  ${({ type, text }) => {
    if (type === "join" || type === "post") {
      return css`
        background: linear-gradient(
          134.74deg,
          #22e6f3 -66.53%,
          #29bdf7 -23.83%,
          #2f9dfa 16.93%,
          #3385fd 57.69%,
          #3677fe 94.56%,
          #3773ff 127.56%
        );
        color: #fff;
        border: none;
        padding: 9px 30px;
      `;
    } else if (type === "like") {
      return css`
        background: url(${({ iconHeart }) => iconHeart}) no-repeat;
        background-size: 16.77px 15.29px;
        background-position: center;
        width: 38px;
        height: 34px;
        padding: 9px 10px;
        margin-right: auto;
      `;
    } else if (type === "close" || type === "quit") {
      return css`
        /* background: #dadada;
        color: 000;
        border: none;
        padding: 9px 20.5px; */
      `;
    } else if (type === "list") {
      return css`
        position: absolute;
        top: 10px;
        left: 5px;
        width: 32px;
        height: 32px;
        border: 1px solid #dcdcdc;
        border-radius: 50%;
        background: #ffffff url(${({ prev }) => prev}) no-repeat;
        background-size: contain;
        opacity: 0.6;
      `;
    }
  }};
`;
