import jeboApi from "api/api";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import { Base64 } from "js-base64";
import { useContext } from "react";
import jeboRoundLogo from "../../assets/imgs/jebo_admin_profile.png";
import { useHistory, useRouteMatch } from "react-router-dom";

// import Tesetimg from "../../assets/imgs/Slogan-Test.png";

export default function FeedInsertedCampaign({ feed }) {
  const { mobileType, isMobile } = useContext(isMobileContext);
  const { isLoggedIn } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);

  const history = useHistory();
  const match = useRouteMatch();
  const eventId = feed.eventId;

  const campaignContent = {
    CD00: {
      key: 0,
      title: "슬로건",
      content: `당신의 일상을 제보하세요,<br/> 더 나은 세상이 될 거에요.`,
      imgSrc: `${process.env.REACT_APP_RESOURCE_HOST}/images/event/${feed.feedImageName}`,
      desc: "",
    },
    CD01: {
      key: 1,
      title: "캠페인",
      content: `당신의 일상을 제보하세요,<br/> 더 나은 세상이 될 거에요.`,
      imgSrc: `${process.env.REACT_APP_RESOURCE_HOST}/images/event/${feed.feedImageName}`,
      desc: "제보에서 진행하는 캠페인 입니다.",
    },
    CD02: {
      key: 2,
      title: "테마",
      content: `당신의 일상을 제보하세요,<br/> 더 나은 세상이 될 거에요.`,
      imgSrc: `${process.env.REACT_APP_RESOURCE_HOST}/images/event/${feed.feedImageName}`,
      desc: "제보에서 진행하는 테마 입니다.",
    },
  };

  const contentMarkp = () => {
    return { __html: campaignContent[feed.eventTypeCode].content };
  };

  const slogStyle =
    !isMobile && campaignContent[feed.eventTypeCode].key === 0
      ? {
          borderRadius: "0 0 10px 10px",
          height: "424px",
        }
      : null;
  const slogImgStyle =
    !isMobile && campaignContent[feed.eventTypeCode].key === 0
      ? {
          borderRadius: "0 0 10px 10px",
          height: "423px",
        }
      : null;

  return (
    <>
      <div className="item campaign">
        <div className="item-header-wrap">
          <div className="item-header">
            <div className="thumb">
              <img src={jeboRoundLogo} alt="jebo_logo" />
            </div>
            <div className="info-ct">
              <div className="info">
                <span className="user-name">
                  <a href="#!" onClick={(e) => e.preventDefault()}>
                    제 보
                  </a>
                </span>
                <span className="location">
                  <a
                    className="campaignTitle"
                    href="#!"
                    onClick={(e) => e.preventDefault()}
                  >
                    {campaignContent[feed.eventTypeCode].title}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="item-body-wrap" style={slogStyle}>
          <div className="item-body">
            <div className="pht-slider ad">
              <div className="default-img type00" style={slogStyle}>
                <img
                  className="campaignImg"
                  style={slogImgStyle}
                  src={campaignContent[feed.eventTypeCode].imgSrc}
                  // 1118
                  // src={Testimg}
                  alt={campaignContent[feed.eventTypeCode].title}
                  onClick={(e) => {
                    e.preventDefault();
                    if (campaignContent[feed.eventTypeCode].key === 0) {
                      const url = "https://guide.jebo.io";
                      if (mobileType === "ios") {
                        const encodedUrl = Base64.encode(url);
                        window.webkit.messageHandlers.openOuterLink.postMessage(
                          encodedUrl
                        );
                      } else {
                        window.open(url);
                      }
                    }
                  }}
                ></img>
                {/* <p className="event_text">
                    클릭 시 캠페인 화면으로 이동합니다.
                  </p> */}
              </div>
              {campaignContent[feed.eventTypeCode].key > 0 && (
                <div className="item-bar-wrap campaign-btn-container feedinserted">
                  <div className="item-bar" style={{ justifyContent: "left" }}>
                    <button
                      style={{
                        padding: "5px",
                        borderRadius: "3px",
                        margin: "4px",
                        backgroundColor: "#014681",
                        color: "#fff",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (isLoggedIn) {
                          history.push({
                            pathname: `${match.url}/add`,
                            search: `?tag=${encodeURIComponent(
                              campaignContent[feed.eventTypeCode].desc.replace(
                                " ",
                                "_"
                              )
                            )}&eventId=${eventId}`,
                          });
                        } else {
                          setSignLayer("signIn");
                        }
                      }}
                    >
                      제보하기
                    </button>
                    {/* {feed.eventViewCode === "CD03" || feed.eventViewCode === "CD05" ? ( */}
                    <button
                      style={{
                        padding: "5px",
                        borderRadius: "3px",
                        margin: "4px",
                        backgroundColor: "#014681",
                        color: "#fff",
                      }}
                      onClick={async (e) => {
                        const { status, data } =
                          await jeboApi.aroundMapFeedList(
                            0,
                            0,
                            0,
                            0,
                            "",
                            `${eventId}`,
                            "0",
                            "5",
                            "0"
                          );
                        if (status === 200) {
                          const jeboPostArr = JSON.parse(
                            data.jeboPost
                          ).jeboPostList;
                          const latitude =
                            jeboPostArr.length > 0
                              ? jeboPostArr[0].latitude
                              : "";
                          const longitude =
                            jeboPostArr.length > 0
                              ? jeboPostArr[0].longitude
                              : "";

                          history.push({
                            pathname: `/around`,
                            search: `?eventId=${eventId}&latitude=${latitude}&longitude=${longitude}`,
                          });
                        } else {
                          history.push({
                            pathname: `/around`,
                            search: `?eventId=${eventId}`,
                          });
                        }
                      }}
                    >
                      지도보기
                    </button>
                    {/* ) : ( "" )} */}
                    {/* {feed.eventViewCode === "CD04" || feed.eventViewCode === "CD05" ? ( */}
                    <button
                      style={{
                        padding: "5px",
                        borderRadius: "3px",
                        margin: "4px",
                        backgroundColor: "#014681",
                        color: "#fff",
                      }}
                      onClick={async (e) => {
                        history.push({
                          pathname: "/search",
                          search: `?eventId=${eventId}`,
                        });
                      }}
                    >
                      제보현황
                    </button>
                    {/* ) : ( "" )} */}
                  </div>
                </div>
              )}
            </div>
            {!isMobile && campaignContent[feed.eventTypeCode].key > 0 && (
              <div className="item-info">
                <div className="tags"></div>
                <div className="text">
                  <p
                    className="campaignText"
                    dangerouslySetInnerHTML={contentMarkp()}
                  ></p>
                </div>
              </div>
            )}
          </div>
        </div>
        {!isMobile && campaignContent[feed.eventTypeCode].key > 0 && (
          <div className="item-bar-wrap"></div>
        )}
      </div>
    </>
  );
}
