import jeboApi from "api/api";
import SignLayer from "components/layers/sign/SignLayer";
import FeedEditLayer from "components/layers/write/FeedEditLayer";
import Header from "components/pages/app/layouts/Header";
import DetailContextProvider from "components/pages/detail/provider/DetailContextProvider";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { activeLocationContext } from "components/providers/LocationContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import FeedDetailContainer from "components/templates/FeedDetailContainer";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { Route, useHistory, useParams, useRouteMatch } from "react-router-dom";

const popMenuReducer = (state, action) => {
  switch (action.type) {
    case "DISPLAY_MENU":
      return {
        ...state,
        id: action.id,
        name: action.name,
      };
    case "CLEAR":
      return initialPopMenuState;

    default:
      return state;
  }
};

const initialPopMenuState = {
  id: "",
  name: "",
};

export default function Detail() {
  const [feeds, setFeeds] = useState([]);
  const { isMobile } = useContext(isMobileContext);
  const { myInfo, isLoggedIn, handleSignOutApp } = useContext(signAppContext);
  const [feed, setFeed] = useState(null);
  const [loading, setLoading] = useState(true);
  //const [error, setError] = useState(null);

  const history = useHistory();
  const match = useRouteMatch();

  const { jeboId } = useParams();

  const modalRef = useRef();

  const { activeLocation } = useContext(activeLocationContext);

  const [popMenu, dispatchPopMenu] = useReducer(
    popMenuReducer,
    initialPopMenuState
  );

  useEffect(() => {
    feed && setLoading(false);
  }, [feed]);

  const getFeedDetail = useCallback(async () => {
    try {
      setLoading(true);

      const { status, data } = await jeboApi.feedDetail(
        jeboId,
        myInfo.memberUid,
        activeLocation ? activeLocation.latitude : null,
        activeLocation ? activeLocation.longitude : null
      );

      if (status === 200) {
        const jeboPost = JSON.parse(data.jeboPost);
        setFeed(jeboPost);
      } else if (status === 204) {
        alert("존재하지 않는 게시물 입니다.");
        const url = match.url?.slice(0, match.url?.lastIndexOf("/detail"));
        history.replace({
          pathname: url ? url : "/",
        });
        console.log("no data to be loaded..");
      }
    } catch (error) {
      console.log(error);
      alert("존재하지 않는 게시물 입니다.");
      const url = match.url?.slice(0, match.url?.lastIndexOf("/detail"));
      history.replace({
        pathname: url ? url : "/",
      });
      //setError(error);
    }
  }, [jeboId, myInfo, history, match, activeLocation]);

  useEffect(() => {
    getFeedDetail();
  }, [getFeedDetail]);

  const onExpiredSession = () => {
    alert("로그인 세션이 만료됐습니다. 다시 로그인해주세요.");
    handleSignOutApp();
    history.replace({ pathname: `${match.url}/sign` });
  };

  const onUpdateFeed = (updatedFeed) => {
    setFeed((prev) => Object.assign({}, prev, updatedFeed));
  };

  const onBlockFeed = (memberUid) => {
    history.replace({ pathname: "/" });
  };

  const onDeleteFeed = (feed) => {
    setFeeds((prev) => prev.filter((item) => item.jeboId !== feed.jeboId));
    history.replace({ pathname: "/" });
  };

  return (
    <>
      <DetailContextProvider
        feeds={{ feeds, setFeeds }}
        session={{ onExpiredSession }}
      >
        {feed && (
          <Helmet>
            {feed.nickName && <title>{`${feed.nickName}의 제보`}</title>}
            {feed.nickName && (
              <meta name="title" content={`${feed.nickName}의 제보`} />
            )}
            {feed.contents && (
              <meta name="description" content={feed.contents} />
            )}
            {feed.hashtag && <meta name="keywords" content={feed.hashtag} />}

            {/*  Facebook Meta Tags / 페이스북 오픈 그래프 */}
            <meta
              property="og:url"
              content={`${process.env.REACT_APP_HOST}${match.url}`}
            />
            <meta property="og:locale" content="ko_KR" />
            <meta property="og:type" content="website" />
            {feed.nickName && (
              <meta property="og:title" content={`${feed.nickName}의 제보`} />
            )}
            {feed.contents && (
              <meta property="og:description" content={feed.contents} />
            )}

            {feed.jeboMediaList &&
              feed.jeboMediaList.length > 0 &&
              feed.jeboMediaList[0].mediaType === "image" && (
                <meta
                  property="og:image"
                  content={feed.jeboMediaList[0].mediaUrl}
                />
              )}

            {/*  Twitter Meta Tags / 트위터  */}
            <meta name="twitter:card" content="summary_large_image" />
            {feed.nickName && (
              <meta name="twitter:title" content={`${feed.nickName}의 제보`} />
            )}
            {feed.contents && (
              <meta name="twitter:description" content={feed.contents} />
            )}
            {feed.jeboMediaList &&
              feed.jeboMediaList.length > 0 &&
              feed.jeboMediaList[0].mediaType === "image" && (
                <meta
                  property="twitter:image"
                  content={feed.jeboMediaList[0].mediaUrl}
                />
              )}

            {/*  Google / Search Engine Tags / 구글 검색 엔진 */}
            {feed.nickName && (
              <meta itemprop="name" content={`${feed.nickName}의 제보`} />
            )}
            {feed.contents && (
              <meta itemprop="description" content={feed.contents} />
            )}
            {feed.jeboMediaList &&
              feed.jeboMediaList.length > 0 &&
              feed.jeboMediaList[0].mediaType === "image" && (
                <meta
                  itemprop="image"
                  content={feed.jeboMediaList[0].mediaUrl}
                />
              )}
          </Helmet>
        )}
        {!isMobile && <Header />}
        {!loading && feed && (
          <div
            className="modal-wrap open"
            ref={modalRef}
            onMouseDown={(e) => {
              !isMobile &&
                e.target === modalRef.current &&
                history.replace({ pathname: "/" });
            }}
          >
            <div className="modal modal-detail open">
              <button
                className="close pc"
                onClick={() => {
                  !isMobile && history.replace({ pathname: "/" });
                }}
              ></button>
              <div className="modal-header mobile">
                <button
                  type="button"
                  className="page-back mobile"
                  onClick={() => {
                    isMobile && history.replace({ pathname: "/" });
                  }}
                ></button>
                <strong>{`${feed.nickName}님의 제보`}</strong>
              </div>
              <div className="modal-body">
                <FeedDetailContainer
                  feed={feed}
                  setFeed={setFeed}
                  popMenu={popMenu}
                  dispatchPopMenu={dispatchPopMenu}
                  onUpdateFeed={onUpdateFeed}
                  onDeleteFeed={onDeleteFeed}
                  onBlockFeed={onBlockFeed}
                />
              </div>
            </div>
          </div>
        )}
        <Route
          path={`${match.path}/edit`}
          render={(props) => (isLoggedIn ? <FeedEditLayer /> : <SignLayer />)}
        />
      </DetailContextProvider>
    </>
  );
}
