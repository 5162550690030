import FeedDeleteConfirmLayer from "components/layers/confirm/FeedDeleteConfirmLayer";
import MemberBlockLayer from "components/layers/memberBlock/MemberBlockLayer";
import FeedAddFeatLayer from "components/layers/popMenu/FeedAddFeatLayer";
import FeedReportLayer from "components/layers/report/FeedReportLayer";
import FeedShareLayer from "components/layers/share/FeedShareLayer";
import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import FeedFooter from "./FeedFooter";
import FeedHeader from "./FeedHeader";
import FeedMain from "./FeedMain";
import ToastMessageBox from "./ToastMessageBox";

import styled from "styled-components";

export default function FeedContainer({
  feed,
  popMenu,
  dispatchPopMenu,
  onUpdateFeed,
  onDeleteFeed,
  onBlockFeed,
  page,
}) {
  const [toastMessage, setToastMessage] = useState("");

  const history = useHistory();
  const match = useRouteMatch();

  const onClosePopMenu = () => {
    dispatchPopMenu({ type: "CLEAR" });
  };

  const onOpenPopMenu = (id, name) => {
    dispatchPopMenu({ type: "DISPLAY_MENU", id, name });
  };

  const {
    address,
    placeName,
    contents,
    jeboMediaList: jeboMedia,
    social,
  } = feed;

  return (
    <>
      <div
        className={`item ${
          (feed.feedType === 1 || feed.mediaCnt <= 0) && "share"
        }
        `}
      >
        {popMenu.id === feed.jeboId && popMenu.name === "FEAT" && (
          <FeedAddFeatLayer
            feed={feed}
            onClose={onClosePopMenu}
            onOpen={onOpenPopMenu}
            onUpdateFeed={onUpdateFeed}
            onDeleteFeed={onDeleteFeed}
            setToastMessage={setToastMessage}
          />
        )}
        {popMenu.id === feed.jeboId && popMenu.name === "MEMBER_BLOCK" && (
          <MemberBlockLayer
            member={{ memberUid: feed.memberUid, nickName: feed.nickName }}
            onClose={onClosePopMenu}
            onBlock={onBlockFeed}
          />
        )}
        {popMenu.id === feed.jeboId && popMenu.name === "REPORT" && (
          <FeedReportLayer feed={feed} onClose={onClosePopMenu} />
        )}
        {popMenu.id === feed.jeboId && popMenu.name === "SHARE" && (
          <FeedShareLayer feed={feed} onClose={onClosePopMenu} />
        )}
        {popMenu.id === feed.jeboId && popMenu.name === "DELETE" && (
          <FeedDeleteConfirmLayer
            feed={feed}
            onClose={onClosePopMenu}
            onDelete={onDeleteFeed}
          />
        )}
        {toastMessage && (
          <ToastMessageBox
            toastMessage={toastMessage}
            setToastMessage={setToastMessage}
          />
        )}

        <FeedHeader feed={feed} onOpenPopMenu={onOpenPopMenu} />
        <FeedMain feed={feed} page={page} />
        <div className="likeHistoryTextBox">
          {feed.upCnt > 0 && (
            <button
              onClick={(e) => {
                history.push({
                  pathname: `${match.url}/likeHistory`,
                  state: { jeboId: feed.jeboId },
                });
              }}
              className="likeHistory__title"
            >
              {feed.upCnt > 1 ? (
                <>
                  {feed.likeNickName}님 외 <span>{feed.upCnt - 1}</span>명이
                  공감합니다
                </>
              ) : (
                <>{feed.likeNickName}님이 공감합니다</>
              )}
            </button>
          )}
        </div>
        <FeedFooter
          feed={feed}
          showCommentBtn={true}
          onOpenPopMenu={onOpenPopMenu}
          onUpdateFeed={onUpdateFeed}
          setToastMessage={setToastMessage}
        />
      </div>
    </>
  );
}
