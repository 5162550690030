import FeedContextProvider from "components/providers/FeedContextProvider";
import SessionContextProvider from "components/providers/SessionContextProvider";

export default function DetailContextProvider({
  feeds,
  session,
  children: pageComponent,
}) {
  return (
    <FeedContextProvider feeds={feeds}>
      <SessionContextProvider session={session}>
        {pageComponent}
      </SessionContextProvider>
    </FeedContextProvider>
  );
}
