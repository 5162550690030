import React from "react";

export default function AgreeToPersonalData({checkPersonalData, setCheckPersonalData, setPersonalData}){
    return(
        <div className="serviceAgreeItem">
            <input
                onChange={(e)=>{
                setCheckPersonalData((current) => !current);
                }}
                id="servicePersonChk"
                type="checkbox"
                checked={checkPersonalData ? "checked" : ""}
            ></input>
            <label htmlFor="servicePersonChk">
            개인정보 수집 및 이용 동의 처리방침<span>(필수)</span>
            </label>
            <button 
                className="serviceBtn"
                onClick={(e) => {
                    e.preventDefault();
                    setPersonalData((prev) => !prev);
                }}
            >전문 보기</button>
        </div>
    )
}