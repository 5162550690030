import FeedContextProvider from "components/providers/FeedContextProvider";
import LayerContextProvider from "components/providers/LayerContextProvider";
import React from "react";
export default function CampaignContextProvider({
  feeds,
  layer,
  children: pageComponent,
}) {
  return (
    <FeedContextProvider feeds={feeds}>
      <LayerContextProvider layer={layer}>{pageComponent}</LayerContextProvider>
    </FeedContextProvider>
  );
}
