import { useRef, useState } from "react";

const QuickJeobPreviewFillText = ({
  imgURL,
  setIsFillTextClicked,
  registFeed,
  loading,
}) => {
  const [contents, setContents] = useState("");
  const [editorHeight, setEditorHeight] = useState("60px");

  const editorRef = useRef();

  const resizeTextarea = (e) => {
    setEditorHeight(`${e.target.scrollHeight}px`);
  };

  /*
  const [pastedData, setPastedData] = useState("");
  const [selection, setSelection] = useState(null);
  useEffect(() => {
    if (pastedData && editorRef.current && selection) {
      editorRef.current.setSelectionRange(selection.start, selection.start);
      setPastedData("");
      setSelection(null);
    }
  }, [contents, pastedData, editorRef, selection]);
  */

  return (
    <>
      {
        <div className="previewFillContainer">
          {/* header 영역 */}
          <div className="previewHeader prevCt">
            <button
              className="previewCloseBtn"
              onClick={() => {
                setIsFillTextClicked(false);
              }}
            >
              취소
            </button>
            <button
              className="previewRegistBtn"
              onClick={(e) => {
                //!loading && registFeed(editorRef.current.innerText);
                !loading && registFeed(contents);
              }}
            >
              등록
            </button>
          </div>

          {/* 이미지 영역 */}
          <div className="previewImgContainer-Fill prevCt">
            {/* file 객체를 img 테그로 적용하는 */}
            <img id="previewImg" className="preImgTag" src={imgURL} alt="" />

            {/* text 영역 */}
            <div className="previewTextArea-Fill">
              <textarea
                className="previewText"
                ref={editorRef}
                autoCapitalize="none"
                aria-autocomplete="none"
                autoCorrect="off"
                autoComplete="off"
                spellCheck="false"
                onKeyPress={resizeTextarea}
                onChange={(e) => {
                  setContents(e.target.value);
                }}
                onPaste={(e) => {
                  //setContents(e.target.value);
                  /*
                  //e.preventDefault();
                  const pastedData = e.clipboardData || window.clipboardData;
                  const textData = pastedData.getData("Text");
    
                  setPastedData(textData);
    
                  let cursorStart = e.target.selectionStart;
                  let cursorEnd = e.target.selectionEnd;
    
                  setContents((prev) => {
                    return (
                      prev.substring(0, cursorStart) +
                      textData +
                      prev.substring(cursorEnd)
                    );
                  });
                  setSelection({
                    start: cursorStart + textData.length,
                    end: cursorStart + textData.length,
                  });
                  */
                }}
                style={{ height: editorHeight }}
                type="text"
                value={contents}
                placeholder="내용을 입력해 주세요"
                autofocus
              ></textarea>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default QuickJeobPreviewFillText;
