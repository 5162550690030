import jeboApi from "api/api";
import user_icon from "assets/imgs/icons/user_icon.svg";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import SubscribeBox from "components/templates/SubscribeBox";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import CollectBadgeContainer from "../setting/CollectBadgeContainer";

export default function ProfileSection({ memberInfo }) {
  const { setSignLayer } = useContext(signLayerContext);
  const { isLoggedIn, myInfo, handleSignOutApp } = useContext(signAppContext);
  const { isMobile } = useContext(isMobileContext);

  const history = useHistory();

  const [followerCnt, setFollowerCnt] = useState(null);
  const [followingCnt, setFollowingCnt] = useState(null);
  const [isFollow, setIsFollow] = useState(null);
  const [followId, setFollowId] = useState(null);
  const [totFeedCnt, setTotFeedCnt] = useState(null);

  const [viewFollowerBox, setViewFollowerBox] = useState(false);
  const [viewFollowingBox, setViewFollowingBox] = useState(false);

  const refDescription = useRef();
  const [descriptionHeight, setDescriptionHeight] = useState("0px");
  const [showBadgeBox, setShowBadgeBox] = useState(false);

  const setFollowValue = () => {
    setFollowingCnt(Number(followingCnt) - 1);
  };

  const getFollowCnt = useCallback(
    async (response) => {
      try {
        const { status, data } = await jeboApi.getFollowCnt(
          myInfo.memberUid,
          memberInfo.memberUid
        );

        if (status === 200) {
          const followCnt = JSON.parse(data.followCnt);

          setFollowerCnt(followCnt.followerCnt);
          setFollowingCnt(followCnt.followingCnt);
          setIsFollow(followCnt.followId ? true : false);
          setFollowId(followCnt.followId);
          setTotFeedCnt(followCnt.totFeedCnt);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [memberInfo, myInfo]
  );

  useEffect(() => {
    setDescriptionHeight(refDescription.current.scrollHeight);

    //팔로워, 팔로잉 카운트
    getFollowCnt();
    setViewFollowerBox(false);
    setViewFollowingBox(false);
    window.addEventListener("click", closePopMenu);

    return () => {
      window.removeEventListener("click", closePopMenu);
    };
  }, [isFollow, memberInfo, getFollowCnt]);

  const closePopMenu = (e) => {
    if (!e.target.closest(".user-info-box")) {
      setViewFollowerBox(false);
      setViewFollowingBox(false);
    }
  };

  const regFollow = async (response) => {
    try {
      await jeboApi.regFollow(memberInfo.memberUid);
      setIsFollow(true);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
        }
      }
    }
  };

  const removeFollow = async (response) => {
    try {
      await jeboApi.removeFollow(followId);
      setIsFollow(false);
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
          setSignLayer("signIn");
        }
      }
    }
  };

  return (
    <section className="section-profile">
      <div className="container">
        <div className="mobile-header p-header">
          {<strong>{memberInfo.nickName}의 프로필</strong>}
          <button
            type="button"
            className="page-back mobile"
            onClick={() => {
              if (history.length <= 1) {
                history.replace({
                  pathname: "/",
                });
              } else {
                history.goBack();
              }
            }}
          ></button>
        </div>
        <div className="profile-box">
          <div className="user-profil">
            <div className="profile-thumb">
              <span className="thumb">
                <img
                  src={
                    memberInfo.imageUrl
                      ? process.env.REACT_APP_RESOURCE_HOST +
                        memberInfo.imageUrl
                      : user_icon
                  }
                  alt="사용자"
                />
              </span>
            </div>

            <div className="user-info">
              <span className="name">{memberInfo.nickName}</span>
              <div className="user-info-box">
                <div className="user-info-box-item">
                  <h2 className="user-info-box-item_count">{totFeedCnt}</h2>
                  <span className="user-info-box-item_txt">게시물</span>
                </div>
                <div
                  className="user-info-box-item"
                  onClick={(e) => {
                    if (Number(followerCnt) > 0 && !viewFollowerBox) {
                      setViewFollowerBox(true);
                      setViewFollowingBox(false);
                    } else {
                      setViewFollowerBox(false);
                      setViewFollowingBox(false);
                    }
                  }}
                >
                  <h2 className="user-info-box-item_count">{followerCnt}</h2>
                  <span className="user-info-box-item_txt">구독자</span>
                </div>
                <div
                  className="user-info-box-item"
                  onClick={(e) => {
                    if (Number(followingCnt) > 0 && !viewFollowingBox) {
                      setViewFollowingBox(true);
                      setViewFollowerBox(false);
                    } else {
                      setViewFollowingBox(false);
                      setViewFollowerBox(false);
                    }
                  }}
                >
                  <h2 className="user-info-box-item_count">{followingCnt}</h2>
                  <span className="user-info-box-item_txt">관심 구독</span>
                </div>
              </div>
              <div className="badge-box menu-open">
                {!isFollow ? (
                  <button
                    className="btn-edit subscribe-btn"
                    onClick={(e) =>
                      isLoggedIn ? regFollow() : setSignLayer("signIn")
                    }
                  >
                    구독 하기
                  </button>
                ) : (
                  <button
                    className="btn-edit subscribing-btn"
                    onClick={(e) => removeFollow()}
                  >
                    구독 해제
                  </button>
                )}
                <button
                  className="btn-edit"
                  onClick={(e) => setShowBadgeBox(!showBadgeBox)}
                >
                  획득 배지
                </button>
                {showBadgeBox && (
                  <div className="pop-menu badge-ct active">
                    <div className="mobile-header badge-header">
                      <button
                        type="button"
                        className="page-back mobile"
                        onClick={(e) => setShowBadgeBox(false)}
                      ></button>
                      <strong>획득 배지</strong>
                    </div>

                    <CollectBadgeContainer memberUid={memberInfo.memberUid} />
                  </div>
                )}
              </div>
              {memberInfo.memberUid === myInfo.memberUid ? (
                ""
              ) : (
                <button
                  className="btn-edit"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!isLoggedIn) {
                      setSignLayer("signIn");
                      return;
                    }
                    sessionStorage.setItem(
                      "dmTargetMemberUid",
                      memberInfo.memberUid
                    );
                    sessionStorage.setItem(
                      "dmTargetNickName",
                      memberInfo.nickName
                    );

                    isMobile
                      ? history.push({
                          pathname: "/directMessage",
                          search: `?memberUid=${memberInfo.memberUid}&nickName=${memberInfo.nickName}`,
                        })
                      : window.open(
                          `/directMessage?memberUid=${memberInfo.memberUid}&nickName=${memberInfo.nickName}`,
                          "_blank"
                        );
                  }}
                >
                  1:1대화
                </button>
              )}
            </div>
            {/* 유저소개 */}
            <div className="user-introduce">
              {/* 자기소개 위치 이동 0111 */}
              <div className="user-text">
                <textarea
                  className="text"
                  style={{ height: descriptionHeight }}
                  ref={refDescription}
                  readOnly={true}
                  value={
                    memberInfo.description ? memberInfo.description.trim() : ""
                  }
                ></textarea>
              </div>
              {/* 자기소개 위치 이동 0111 */}
            </div>
            {/* 유저소개 */}

            {/* 구독 팝업 PC */}
            {!isMobile ? (
              viewFollowerBox || viewFollowingBox ? (
                <SubscribeBox
                  viewFollowerBox={viewFollowerBox}
                  viewFollowingBox={viewFollowingBox}
                  memberInfo={memberInfo}
                  setFollowValue={setFollowValue}
                  setViewFollowingBox={setViewFollowingBox}
                  viewFolloerBox={viewFollowerBox}
                  setViewFollowerBox={setViewFollowerBox}
                ></SubscribeBox>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {/* 구독 팝업 모바일 */}
            {isMobile
              ? viewFollowerBox || viewFollowingBox
                ? history.push(
                    `/mobileSubscribe/${memberInfo.memberUid}/${
                      viewFollowerBox ? "FOLLOWER" : "FOLLOWING"
                    }`
                  )
                : ""
              : ""}
          </div>
        </div>
      </div>
    </section>
  );
}
