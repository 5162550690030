import jeboApi from "api/api";
import user_icon from "assets/imgs/icons/user_icon.svg";
import jebocube_icon from "assets/imgs/icons/banner_section/icon_jebocube.svg";
import around_icon from "assets/imgs/icons/banner_section/icon_around.svg";
import top5_icon from "assets/imgs/icons/banner_section/icon_top5.svg";

import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import BannerMapContainer from "./BannerMapContainer";
import styled from "styled-components";
import HomeContentItemDefault from "components/pages/social/HomeContentItemDefault";

SwiperCore.use([Navigation, Pagination, Autoplay]);
export default function BannerSection() {
  const [rankers, setRankers] = useState([]);
  const [jeboEventList, setJeboEventList] = useState([]);

  const [activeTab, setActiveTab] = useState(0); // 탭클릭 시 마다 active 상태<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  const tabTypes = [
    { id: 0, title: "전체" },
    { id: 1, title: "테마" },
    { id: 2, title: "캠페인" },
    { id: 3, title: "채널" },
    { id: 4, title: "커뮤니티" },
  ];
  const [tabItem, setTabItem] = useState();

  const { myInfo, isLoggedIn, handleSignOutApp } = useContext(signAppContext);
  const { setSignLayer } = useContext(signLayerContext);

  const history = useHistory();

  useEffect(() => {
    getTopJeboMemberList();

    //캠페인 불러오기
    getJeboEventList();
  }, []);

  const toggleLike = async (item) => {
    if (!isLoggedIn) {
      setSignLayer("signIn");
      return;
    }

    try {
      const { status } = await jeboApi.toggleSocialLike(item.scId, !item.like);

      if (status === 200) {
        // console.log(">>>>>>>>>> HAHAHAH");
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
        }
      }
    }
  };

  const getJeboEventList = async () => {
    try {
      const {
        status,
        data: { resultData },
      } = await jeboApi.summaryRankedList(0);

      if (status === 200) {
        const jeboEventList = JSON.parse(resultData);
        console.log(jeboEventList, "<<<<<<<<<<<<<<<<<<<<이거jeboEventList");

        setJeboEventList(jeboEventList);

        setTabItem(null); // 탭 클릭 시 다른 데이터를 보여주지 않도록 설정
      } else if (status === 204) {
        setJeboEventList(null);
        console.log("no data to be loaded..");
      }
    } catch (error) {
      setJeboEventList(null);
      console.log(error);
    } finally {
    }
  };

  const getTopJeboMemberList = async () => {
    try {
      const { status, data } = await jeboApi.topJeboMemberList();

      if (status === 200) {
        const rankerArray = JSON.parse(data.rankers);

        setRankers(rankerArray);
      } else if (status === 204) {
        setRankers(null);
        console.log("no data to be loaded..");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // getSocialList api 호출
  const ApiTest = async (category) => {
    try {
      const {
        data: { socialData: resultData },
        status,
      } = await jeboApi.getSocialList(
        category,
        0,
        { start: 0, end: 4 },
        "",
        false
      );

      if (status === 200) {
        const socialData = JSON.parse(resultData);
        console.log("socialData 목록 여기****", socialData);

        setTabItem(socialData);
        setJeboEventList(null); //전체 탭 안보이게
      }
    } catch (error) {
      const { status } = error;
      console.log(error, status);
    }
  };

  //카테고리 탭 클릭 시
  function clickTab(category) {
    setActiveTab(category);

    if (category === 0) {
      getJeboEventList();
    } else {
      ApiTest(category);
    }
  }

  return (
    <section className="section-contents pc">
      <div className="container">
        <div className="content-wrap">
          <div className="content-container cont-01 default">
            <TitleBox>
              <div className="icon">
                <img src={jebocube_icon} alt="제보큐브" />
              </div>
              <div className="title">
                <strong>제보큐브</strong>
              </div>
            </TitleBox>
            <MoreButtonViewWrap>
              <ContentTitleDesc>
                다양한 제보 콘텐츠, 여기에 더 있어요.
              </ContentTitleDesc>
              <MoreButton
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: `/social`,
                  });
                }}
              >
                더보기
              </MoreButton>
            </MoreButtonViewWrap>

            {/* 제보 큐브 카테고리~각 콘텐츠 Wrapper */}
            <JeboCubeWrapper
              className="box"
              style={{ backgroundColor: "#f8f8f8", border: "0" }}
            >
              {/* 제보 큐브 카테고리 탭 */}
              <CategoryTab>
                {tabTypes.map((tabtype) => (
                  <CategoryButton
                    key={tabtype.id}
                    className={activeTab === tabtype.id ? "active" : ""}
                    onClick={() => clickTab(tabtype.id)}
                    tabId={tabtype.id}
                  >
                    {tabtype.title}
                  </CategoryButton>
                ))}
              </CategoryTab>
              <SocialSectionWrapper>
                {jeboEventList &&
                  jeboEventList.length > 0 &&
                  jeboEventList.map((data, idx) => (
                    <SocialSection key={idx}>
                      <HomeContentItemDefault
                        item={data}
                        category={
                          data.scType === 1
                            ? {
                                name: "theme",
                                title: "테마",
                                type: 1,
                                label: null,
                                creatable: false,
                                joinable: false,
                                postable: true,
                              }
                            : data.scType === 2
                            ? {
                                name: "campaign",
                                title: "캠페인",
                                type: 2,
                                label: null,
                                creatable: false,
                                joinable: false,
                                postable: true,
                              }
                            : data.scType === 3
                            ? {
                                name: "channel",
                                title: "채널",
                                type: 3,
                                label: null,
                                creatable: true,
                                joinable: true,
                                postable: false,
                              }
                            : {
                                name: "community",
                                title: "커뮤니티",
                                type: 4,
                                label: null,
                                creatable: true,
                                joinable: true,
                                postable: true,
                              }
                        }
                        toggleLike={toggleLike}
                        index={idx}
                        section={"home"}
                      />
                    </SocialSection>
                  ))}
                {tabItem &&
                  tabItem.length > 0 &&
                  tabItem.map((data, idx) => (
                    <SocialSection key={idx}>
                      <HomeContentItemDefault
                        item={data}
                        category={
                          data.scType === 1
                            ? {
                                name: "theme",
                                title: "테마",
                                type: 1,
                                label: null,
                                creatable: false,
                                joinable: false,
                                postable: true,
                              }
                            : data.scType === 2
                            ? {
                                name: "campaign",
                                title: "캠페인",
                                type: 2,
                                label: null,
                                creatable: false,
                                joinable: false,
                                postable: true,
                              }
                            : data.scType === 3
                            ? {
                                name: "channel",
                                title: "채널",
                                type: 3,
                                label: null,
                                creatable: true,
                                joinable: true,
                                postable: false,
                              }
                            : {
                                name: "community",
                                title: "커뮤니티",
                                type: 4,
                                label: null,
                                creatable: true,
                                joinable: true,
                                postable: true,
                              }
                        }
                        toggleLike={toggleLike}
                        index={idx}
                        section={"home"}
                      />
                    </SocialSection>
                  ))}
              </SocialSectionWrapper>
            </JeboCubeWrapper>
          </div>

          <div className="content-container cont-02 pc">
            <TitleBox>
              <div className="title">
                <strong className="pc">주변 제보</strong>
              </div>
              <div className="icon">
                <img src={around_icon} alt="주변제보" />
              </div>
            </TitleBox>
            <ContentTitleDesc>
              내 주변에 무슨 일이 있는지 궁금한 사람
            </ContentTitleDesc>
            <div className="cont02-slide on">
              <div className="box">
                <BannerMapContainer />
              </div>
            </div>
          </div>
          <div
            style={{ display: "none" }}
            className="content-container cont-03 pc"
          >
            <TitleBox>
              <div className="title">
                <strong>제보 TOP5 멤버</strong>
              </div>
              <div className="icon">
                <img src={top5_icon} alt="TOP5" />
              </div>
            </TitleBox>
            <ContentTitleDesc>
              제보에서 가장 활발한 활동을 보이고 있는 멤버!
            </ContentTitleDesc>
            <div className="box jeboRank_box">
              <div className="user-rank">
                <div className="bottom">
                  <ul>
                    {rankers.map((ranker, key) => (
                      <li key={key}>
                        <div className="user-item flex">
                          <div className="thumb">
                            <img
                              src={
                                ranker.imageUrl
                                  ? process.env.REACT_APP_RESOURCE_HOST +
                                    ranker.imageUrl
                                  : user_icon
                              }
                              alt="사용자"
                            />
                          </div>
                          <div className="info">
                            <span className="user-name">
                              <Link
                                to={
                                  myInfo &&
                                  myInfo.memberUid === ranker.memberUid
                                    ? { pathname: "/mypage" }
                                    : {
                                        pathname: `/member/${encodeURIComponent(
                                          ranker.nickName
                                        )}`,
                                      }
                                }
                              >
                                {ranker.nickName}
                              </Link>
                            </span>
                          </div>
                          <div className="rankerFeed_count_">
                            <h2 className="rankerFeed_countText">
                              {/* +{ranker.overAge} */}
                            </h2>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="jeboRank_bottom__desc">
                <span>※</span>
                <p>
                  제보 TOP5 멤버는 최근 7일간의 글쓰기 내역을 합산해 실시간으로
                  업데이트 됩니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const TitleBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  .title {
    margin-right: 10px;
  }

  .icon {
    margin-right: 10px;
    margin-bottom: 10px;
    height: 20px;
  }

  .button {
    margin-bottom: 10px;
    border-radius: 20px;
    border: 1.5px solid;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    color: var(--text-black);

    position: absolute;
    right: 5px;
  }
`;

const ContentTitleDesc = styled.p`
  font-size: 12px;
  font-weight: 300;
  line-height: 1.4;
`;

const MoreButtonViewWrap = styled.div`
  display: flex;
  align-items: center;
`;

const MoreButton = styled.button`
  cursor: pointer;
  font-size: 10px;
  line-height: 15px;
  border: 0.7px solid #a9a9a9;
  background: #fff;
  border-radius: 10px;
  padding: 0 9px;

  margin-left: 12px;
`;

const JeboCubeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid #dddddd;
  border-radius: 10px;

  padding: 24px 16px;
`;

const CategoryTab = styled.div`
  display: flex;
  gap: 30px;
  padding: 0 15px;
  margin-bottom: 20px;

  /* 반응형 */
  @media screen and (max-width: 1230px) {
    gap: 30px;
  }
`;

const CategoryButton = styled.button`
  font-size: 17px;
  font-weight: 400;
  color: #666;

  background-color: transparent;

  &.active {
    font-weight: 600;
    color: #000;
    /* background-color: #fff; */
    /* color: ${({ tabId }) =>
      tabId === 1
        ? "#fff"
        : tabId === 2
        ? "#fff"
        : tabId === 3
        ? "#fff"
        : tabId === 4
        ? "#fff"
        : "#000"}; */
    /* background-color: ${({ tabId }) =>
      tabId === 1
        ? "#8e57a0"
        : tabId === 2
        ? "#3498db"
        : tabId === 3
        ? "#2fa55d"
        : tabId === 4
        ? "#e9971e"
        : "#fff"}; */
  }

  /* 반응형 */
  @media screen and (max-width: 1230px) {
    font-size: 15px;
  }
`;

const SocialSectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
`;

const SocialSection = styled.div`
  width: 49%;
  display: flex;

  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: 10px;
  border-radius: 5px;

  /* 반응형 */
  @media screen and (max-width: 1230px) {
    width: 49%;
  }
  /* @media screen and (max-width: 930px) {
    width: 49%;
  } */
`;
