import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { Base64 } from "js-base64";
import logo from "assets/imgs/logo_new.svg";
import starter from "assets/imgs/badges/badge_01.svg";
import { signAppContext } from "components/providers/SignContextProvider";

export default function SignUpComplete({ setSignLayer, setOpenComplete }) {
  const history = useHistory();
  const { mobileType } = useContext(isMobileContext);
  const { myInfo } = useContext(signAppContext);

  // useEffect(() => {
  //   document.getElementsByTagName("html")[0].classList.add("hidden");
  //   window.document.body.classList.add("hidden");
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("hidden");
  //     window.document.body.classList.remove("hidden");
  //   };
  // }, []);

  return (
    <div className="modal-wrap open">
      <div className="modal modal-sign open complete">
        <div className="modal-header">
          <div className="logo-signup-complete">
            <img src={logo} alt="스타터" />
          </div>
        </div>
        <div className="modal-body-signup-complete">
          <div className="starter-img-div">
            <img className="starter-img" src={starter} alt="스타터" />
          </div>
          <strong className="signup-title">
            {myInfo.nickName} 님,
            <br /> 반갑습니다.
          </strong>
          <div className="signup-subtitle">
            제보(JEBO) 회원가입이 완료되었습니다.
            <br /> 다양한 정보를 제보에서 나눠보세요!
          </div>
        </div>
        <div className="modal-footer-signup-complete">
          <div>
            <button
              className="modal-btn signup-complete"
              onClick={(e) => {
                e.preventDefault();
                if (setSignLayer) {
                  setSignLayer("");
                } else {
                  setOpenComplete(false);
                  history.replace({ pathname: "/" });
                }
              }}
            >
              메인으로
            </button>
          </div>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              const url = "https://guide.jebo.io/";
              if (mobileType === "ios") {
                const encodedUrl = Base64.encode(url);
                window.webkit.messageHandlers.openOuterLink.postMessage(
                  encodedUrl
                );
              } else {
                window.open(url);
              }
            }}
            className="complete underline"
          >
            제보 블로그 바로가기
          </button>
        </div>
      </div>
    </div>
  );
}
