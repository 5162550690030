import Layers from "components/layers/Layers";
import Header from "components/pages/app/layouts/Header";
import Content from "components/pages/social/Content";
import SocialContextProvider from "components/pages/social/provider/SocialContextProvider";
// import SocialNavButton from "components/pages/social/SocialNavButton";
import SocialNavButton from "components/pages/social/SocialNavButtonNew";
// import Summary from "components/pages/social/Summary";
import SummaryNew from "components/pages/social/SummaryNew";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import campaignLabel from "assets/imgs/icons/icon_socialCampaign.svg";
import channelLabel from "assets/imgs/icons/icon_socialChannel.svg";
import communityLabel from "assets/imgs/icons/icon_socialCommu.svg";
import themeLabel from "assets/imgs/icons/icon_socialTheme.svg";

import jeboApi from "api/api";
import ContentMainCampaign from "components/pages/social/ContentMainCampaign";
import ContentMainChannel from "components/pages/social/ContentMainChannel";
import ContentMainCommunity from "components/pages/social/ContentMainCommunity";
import ContentMainTheme from "components/pages/social/ContentMainTheme";
import { signAppContext } from "components/providers/SignContextProvider";
import styled from "styled-components";

const CATEGORY = {
  summary: {
    name: "summary",
    title: "전체",
    type: 0,
    label: null,
    creatable: false,
    joinable: false,
    postable: false,
  },
  theme: {
    name: "theme",
    title: "테마",
    type: 1,
    label: themeLabel,
    creatable: false,
    joinable: false,
    postable: true,
  },
  campaign: {
    name: "campaign",
    title: "캠페인",
    type: 2,
    label: campaignLabel,
    creatable: false,
    joinable: false,
    postable: true,
  },
  channel: {
    name: "channel",
    title: "채널",
    type: 3,
    label: channelLabel,
    creatable: true,
    joinable: true,
    postable: false,
  },
  community: {
    name: "community",
    title: "커뮤니티",
    type: 4,
    label: communityLabel,
    creatable: true,
    joinable: true,
    postable: true,
  },
};
const END_LIMIT = 5;
const contentReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUMMARY":
      console.log("FETCH_SUMMARY");
      return {
        ...state,
        [action.name]: action.data ? action.data : [],
        loading: false,
      };
    case "UPDATE_RANKED":
      console.log("UPDATE_RANKED");
      return {
        ...state,
        rankedData: state.rankedData.map((item) =>
          item.scId === action.data.scId ? { ...item, ...action.data } : item
        ),
      };
    case "CHANGE_SORT_SUMMARY":
      console.log("CHANGE_SORT_SUMMARY");
      return {
        ...state,
        sortType: action.sortType,
        loading: true,
      };
    case "INIT_SUMMARY":
      console.log("INIT_SUMMARY");
      return {
        ...state,
        joinedData: [],
        rankedData: [],
      };
    case "FETCH_DATA":
      console.log("FETCH_DATA");
      return {
        ...state,
        datas:
          state.cursor && state.cursor.start === 0
            ? action.datas
            : [...state.datas, ...action.datas],
        popDatas: action.popDatas ? action.popDatas : [],
        loading: false,
      };
    case "INIT_DATA":
      return initialContent;
    case "REFRESH_DATA":
      console.log("REFRESH_DATA");
      return {
        ...state,
        datas: [],
        cursor: { start: 0, end: END_LIMIT },
        scrollPosition: 0,
        loading: true,
        isLastData: false,
      };
    case "ADD_DATA":
      console.log("ADD_DATA");
      return {
        ...state,
        datas: [action.data, ...state.datas],
      };
    case "UPDATE_DATA":
      console.log("UPDATE_DATA");
      const datas = state.datas.map((data) => {
        if (data.scId === action.data.scId) return { ...data, ...action.data };
        else return data;
      });
      // 커뮤니티 popDatas는 피드여서 popDatas 업데이트 X
      if (action.data.scType !== 4) {
        const popDatas = state.popDatas.map((data) => {
          if (data.scId === action.data.scId)
            return { ...data, ...action.data };
          else return data;
        });
        return {
          ...state,
          datas,
          popDatas,
        };
      } else {
        return {
          ...state,
          datas,
        };
      }
    case "REMOVE_DATA":
      console.log("REMOVE_DATA");
      return {
        ...state,
        datas: state.datas.filter((data) => data.scId !== action.socialId),
      };
    case "CHANGE_CURSOR":
      console.log("CHANGE_CURSOR");
      return {
        ...state,
        cursor: { start: state.cursor.start + END_LIMIT, end: END_LIMIT },
      };
    case "LAST_DATA":
      return {
        ...state,
        isLastData: true,
      };
    case "SEARCH_KEYWORD":
      console.log("SEARCH_KEYWORD");
      return {
        ...state,
        datas: [],
        loading: true,
        isLastData: false,
        cursor: { start: 0, end: END_LIMIT },
        searchKeyword: action.keyword,
      };
    case "CHANGE_SORT":
      console.log("CHANGE_SORT");
      return {
        ...state,
        datas: [],
        loading: true,
        isLastData: false,
        cursor: { start: 0, end: END_LIMIT },
        sortType: action.sortType,
      };
    case "CHANGE_FILTER":
      console.log("CHANGE_FILTER");
      return {
        ...state,
        datas: [],
        loading: true,
        isLastData: false,
        cursor: { start: 0, end: END_LIMIT },
        filter: action.filter,
      };
    case "TOTAL_COUNT":
      console.log("TOTAL_COUNT");
      return {
        ...state,
        totalCount: action.totalCount,
      };

    case "STORE_SCROLL_POSITION":
      //console.log("STORE_SCROLL_POSITION");
      return {
        ...state,
        scrollPosition: action.scrollPosition,
      };
    default:
      return state;
  }
};

const initialContent = {
  datas: [],
  popDatas: [],
  loading: true,
  isLastData: false,
  cursor: { start: 0, end: END_LIMIT },
  searchKeyword: "",
  sortType: 0,
  totalCount: 0,
  scrollPosition: 0,
  filter: { joined: false },
};
export default function Social() {
  const history = useHistory();

  const { isLoggedIn } = useContext(signAppContext);

  const [layer, setLayer] = useState(null);
  const [activeCategory, setActiveCategory] = useState(CATEGORY.summary);
  const [summaryState, dispatchSummary] = useReducer(contentReducer, {
    joinedData: null,
    rankedData: null,
    sortType: 0,
    loading: false,
  });

  const [theme, dispatchTheme] = useReducer(contentReducer, initialContent);
  const [campaign, dispatchCampaign] = useReducer(
    contentReducer,
    initialContent
  );
  const [channel, dispatchChannel] = useReducer(contentReducer, initialContent);
  const [community, dispatchCommunity] = useReducer(
    contentReducer,
    initialContent
  );

  /* channel load*/
  const loadChannel = useCallback(async () => {
    try {
      const {
        data: { socialData: resultData, popularData: resultPopData },
        status,
      } = await jeboApi.getSocialList(
        CATEGORY.channel.type,
        channel.sortType,
        channel.cursor,
        channel.searchKeyword,
        channel.filter && channel.filter.joined ? channel.filter.joined : false
      );

      if (status === 200) {
        const socialData = JSON.parse(resultData);
        const popularData = JSON.parse(resultPopData);
        dispatchChannel({
          type: "FETCH_DATA",
          datas: socialData,
          popDatas: popularData,
        });
      } else if (status === 204) {
        dispatchChannel({ type: "LAST_DATA" });
      }
    } catch (error) {
      const { status } = error;
      console.log(error, status);
    }
  }, [channel.cursor, channel.sortType, channel.searchKeyword, channel.filter]);
  useEffect(() => {
    console.log("loadChannel....");
    loadChannel();
  }, [loadChannel]);

  /* community load*/
  const loadCommunity = useCallback(async () => {
    try {
      const {
        data: { socialData: resultData, popularData: resultPopData },
        status,
      } = await jeboApi.getSocialList(
        CATEGORY.community.type,
        community.sortType,
        community.cursor,
        community.searchKeyword,
        community.filter && community.filter.joined
          ? community.filter.joined
          : false
      );

      if (status === 200) {
        const socialData = JSON.parse(resultData);
        const popularData = JSON.parse(resultPopData);
        dispatchCommunity({
          type: "FETCH_DATA",
          datas: socialData,
          popDatas: popularData,
        });
      } else if (status === 204) {
        dispatchCommunity({ type: "LAST_DATA" });
      }
    } catch (error) {
      const { status } = error;
      console.log(error, status);
    }
  }, [
    community.cursor,
    community.sortType,
    community.searchKeyword,
    community.filter,
  ]);
  useEffect(() => {
    console.log("loadCommunity....");
    loadCommunity();
  }, [loadCommunity]);

  /* theme load*/
  const loadTheme = useCallback(async () => {
    try {
      const {
        data: { socialData: resultData, popularData: resultPopData },
        status,
      } = await jeboApi.getSocialList(
        CATEGORY.theme.type,
        theme.sortType,
        theme.cursor,
        theme.searchKeyword,
        theme.filter && theme.filter.joined ? theme.filter.joined : false
      );

      if (status === 200) {
        const socialData = JSON.parse(resultData);
        const popularData = JSON.parse(resultPopData);
        dispatchTheme({
          type: "FETCH_DATA",
          datas: socialData,
          popDatas: popularData,
        });
      } else if (status === 204) {
        dispatchTheme({ type: "LAST_DATA" });
      }
    } catch (error) {
      const { status } = error;
      console.log(error, status);
    }
  }, [theme.cursor, theme.sortType, theme.searchKeyword, theme.filter]);
  useEffect(() => {
    console.log("loadTheme....");
    loadTheme();
  }, [loadTheme]);

  /* campaign load*/
  const loadCampaign = useCallback(async () => {
    try {
      const {
        data: { socialData: resultData, popularData: resultPopData },
        status,
      } = await jeboApi.getSocialList(
        CATEGORY.campaign.type,
        campaign.sortType,
        campaign.cursor,
        campaign.searchKeyword,
        campaign.filter && campaign.filter.joined
          ? campaign.filter.joined
          : false
      );

      if (status === 200) {
        const socialData = JSON.parse(resultData);
        const popularData = JSON.parse(resultPopData);
        dispatchCampaign({
          type: "FETCH_DATA",
          datas: socialData,
          popDatas: popularData,
        });
      } else if (status === 204) {
        dispatchCampaign({ type: "LAST_DATA" });
      }
    } catch (error) {
      const { status } = error;
      console.log(error, status);
    }
  }, [
    campaign.cursor,
    campaign.sortType,
    campaign.searchKeyword,
    campaign.filter,
  ]);
  useEffect(() => {
    console.log("loadCampaign....");
    loadCampaign();
  }, [loadCampaign]);

  return (
    <>
      <Header />
      <SocialContextProvider layer={{ layer, setLayer }}>
        <SocialContainer>
          <SocialNavContainer>
            <SocialNavButton
              text={CATEGORY.summary.title}
              isActive={CATEGORY.summary.type === activeCategory.type}
              onClick={(e) => {
                setActiveCategory(CATEGORY.summary);
                history.replace({ pathname: "/social" });
              }}
            />
            <SocialNavButton
              text={CATEGORY.channel.title}
              isActive={CATEGORY.channel.type === activeCategory.type}
              onClick={() => {
                setActiveCategory(CATEGORY.channel);
                loadChannel();
                history.replace({
                  pathname: "/social/channel",
                  search: `?q=${channel.searchKeyword}`,
                });
              }}
            />

            <SocialNavButton
              text={CATEGORY.community.title}
              isActive={CATEGORY.community.type === activeCategory.type}
              onClick={() => {
                setActiveCategory(CATEGORY.community);
                loadCommunity();
                history.replace({
                  pathname: "/social/community",
                  search: `?q=${community.searchKeyword}`,
                });
              }}
            />
            <SocialNavButton
              text={CATEGORY.theme.title}
              isActive={CATEGORY.theme.type === activeCategory.type}
              onClick={(e) => {
                setActiveCategory(CATEGORY.theme);
                loadTheme();
                history.replace({
                  pathname: "/social/theme",
                  search: `?q=${theme.searchKeyword}`,
                });
              }}
            />
            <SocialNavButton
              text={CATEGORY.campaign.title}
              isActive={CATEGORY.campaign.type === activeCategory.type}
              onClick={() => {
                setActiveCategory(CATEGORY.campaign);
                loadCampaign();
                history.replace({
                  pathname: "/social/campaign",
                  search: `?q=${campaign.searchKeyword}`,
                });
              }}
            />
          </SocialNavContainer>
          <SocialMaincontainer>
            <Switch>
              <Route
                path="/social"
                exact
                render={(props) => (
                  <SummaryNew
                    summaryState={summaryState}
                    category={CATEGORY}
                    dispatchSummary={dispatchSummary}
                    dispatchTheme={dispatchTheme}
                    dispatchCampaign={dispatchCampaign}
                    dispatchChannel={dispatchChannel}
                    dispatchCommunity={dispatchCommunity}
                    setActiveCategory={setActiveCategory}
                  />
                )}
              />
              <Route
                path="/social/theme"
                render={(props) => (
                  <Content
                    category={CATEGORY.theme}
                    content={theme}
                    dispatchContent={dispatchTheme}
                    loadContent={loadTheme}
                    setActiveCategory={setActiveCategory}
                  >
                    <ContentMainTheme />
                  </Content>
                )}
              />
              <Route
                path="/social/campaign"
                render={(props) => (
                  <Content
                    category={CATEGORY.campaign}
                    content={campaign}
                    dispatchContent={dispatchCampaign}
                    loadContent={loadCampaign}
                    setActiveCategory={setActiveCategory}
                  >
                    <ContentMainCampaign />
                  </Content>
                )}
              />
              <Route
                path="/social/channel"
                render={(props) => (
                  <Content
                    category={CATEGORY.channel}
                    content={channel}
                    dispatchContent={dispatchChannel}
                    loadContent={loadChannel}
                    setActiveCategory={setActiveCategory}
                  >
                    <ContentMainChannel />
                  </Content>
                )}
              />
              <Route
                path="/social/community"
                render={(props) => (
                  <Content
                    category={CATEGORY.community}
                    content={community}
                    dispatchContent={dispatchCommunity}
                    loadContent={loadCommunity}
                    setActiveCategory={setActiveCategory}
                  >
                    <ContentMainCommunity />
                  </Content>
                )}
              />
            </Switch>
          </SocialMaincontainer>
        </SocialContainer>
      </SocialContextProvider>
      <Layers />
    </>
  );
}

const SocialContainer = styled.div`
  padding-top: 45px;
  padding-bottom: 50px;

  @media screen and (min-width: 481px) {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const SocialMaincontainer = styled.div`
  background-color: #fff;

  @media screen and (min-width: 481px) {
    /* width: 1200px; */
    /* max-width: 1200px; */
    max-width: 900px;
    width: 100%;
  }
`;

const SocialNavContainer = styled.div`
  position: fixed;

  z-index: 2;
  width: 100%;
  height: 40px;
  padding: 7px 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: linear-gradient(
    128.36deg,
    #22e6f3 -9.75%,
    #29bdf7 16.61%,
    #2f9dfa 41.76%,
    #3385fd 66.92%,
    #3677fe 89.68%,
    #3773ff 110.05%
  );
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23);

  @media screen and (min-width: 481px) {
    position: static;
    padding: 0 15vw;
  }
`;
