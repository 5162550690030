import { feedsContext } from "components/providers/FeedContextProvider";
import { signAppContext } from "components/providers/SignContextProvider";
import FeedContainer from "components/templates/FeedContainer";
import { Fragment, useContext, useEffect, useReducer, useRef } from "react";
import { createPortal } from "react-dom";
import {
  Route,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import SignLayer from "../sign/SignLayer";
import FeedEditLayer from "../write/FeedEditLayer";
import FeedDetailLayer from "./FeedDetailLayer";

const popMenuReducer = (state, action) => {
  switch (action.type) {
    case "DISPLAY_MENU":
      return {
        ...state,
        id: action.id,
        name: action.name,
      };
    case "CLEAR":
      return initialPopMenuState;

    default:
      return state;
  }
};

const initialPopMenuState = {
  id: "",
  name: "",
};

export default function MobileFeedListLayer({ title }) {
  const { feeds, setFeeds } = useContext(feedsContext);
  const { isLoggedIn } = useContext(signAppContext);
  const { nickName } = useParams();

  const focusRef = useRef();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const [popMenu, dispatchPopMenu] = useReducer(
    popMenuReducer,
    initialPopMenuState
  );

  useEffect(() => {
    focusRef && focusRef.current && focusRef.current.scrollIntoView();

    //부모창 스크롤 이벤트 방지 추가
    //Component Mount 시 동작
    document.scrollingElement.style.overflow = "hidden";
    //Component UnMount 시 동작
    return () => {
      document.scrollingElement.style.overflow = "scroll";
    };
  }, []);

  const onUpdateFeed = (updatedFeed) => {
    setFeeds((prev) =>
      prev.map((item) => {
        if (item.jeboId === updatedFeed.jeboId) {
          return Object.assign({}, item, updatedFeed);
        }
        return item;
      })
    );
  };

  const onBlockFeed = (memberUid) => {
    setFeeds((prev) => prev.filter((item) => item.memberUid !== memberUid));
  };

  const onDeleteFeed = (feed) => {
    setFeeds((prev) => prev.filter((item) => item.jeboId !== feed.jeboId));
  };

  const movePrevPage = () => {
    if (history.length <= 1) {
      const url = match.url.slice(0, match.url?.lastIndexOf("/list"));
      history.replace({
        pathname: url ? url : "/",
      });
    } else {
      history.goBack();
    }
  };
  //삭제로 인해 목록 내에 Feed 가 없을 때 FeedListLayer 종료를 위해 추가
  useEffect(() => {
    (!feeds || feeds.length === 0) && movePrevPage();
  }, [feeds]);

  return (
    <>
      {createPortal(
        <>
          <div className="modal-wrap open">
            <div className="modal modal-list open">
              <div className="modal-header mobile">
                <button
                  type="button"
                  className="page-back mobile"
                  onClick={() => {
                    // setLayer(false);
                    movePrevPage();
                  }}
                ></button>
                <strong>{title}</strong>
              </div>
              <div className="modal-body">
                <div className="member-feed_list">
                  {feeds &&
                    feeds.map((feed, index) => (
                      <Fragment key={index}>
                        <div
                          ref={
                            location.state &&
                            feed.jeboId === location.state.jeboId
                              ? focusRef
                              : null
                          }
                        ></div>
                        <FeedContainer
                          feed={feed}
                          popMenu={popMenu}
                          dispatchPopMenu={dispatchPopMenu}
                          onUpdateFeed={onUpdateFeed}
                          onDeleteFeed={onDeleteFeed}
                          onBlockFeed={onBlockFeed}
                          page={nickName ? "member" : "mypage"}
                        />
                      </Fragment>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <Route
            path={`${match.path}/detail/:jeboId`}
            render={(props) => <FeedDetailLayer />}
          />
          <Route
            path={`${match.path}/edit`}
            render={(props) => (isLoggedIn ? <FeedEditLayer /> : <SignLayer />)}
          />
        </>,
        document.getElementById("modal")
      )}
    </>
  );
}
