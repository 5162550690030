import jeboApi from "api/api";
import { isMobileContext } from "components/providers/BrowserEnvContextProvider";
import { Base64 } from "js-base64/base64";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import styled from "styled-components";

//viewMode [V (vertical): 수직, H (horizontal): 수평]
export default function MetaInfo({ urlMessage, viewMode, callBack }) {
  const { mobileType } = useContext(isMobileContext);

  const [isUrlEmpty, setIsUrlEmpty] = useState(true);
  const [urlData, setUrlData] = useState({});

  const getMetaInfo = async () => {
    try {
      const { data, status } = await jeboApi.getUrlMetaIfno(String(urlMessage));
      if (status === 200) {
        console.log(status);

        const urlData = JSON.parse(data.metaInfo);
        urlData && setIsUrlEmpty(false);

        setUrlData(urlData);
        callBack && callBack();
      }
    } catch (error) {
      console.log(error);
      setIsUrlEmpty(true);
    } finally {
    }
  };

  useEffect(() => {
    urlMessage && getMetaInfo();
  }, [urlMessage]);

  return (
    !isUrlEmpty &&
    (viewMode === "V" ? (
      <UrlContainerV
        onLoad={(e) =>
          window.scrollBy({
            top: e.target.height,
            behavior: "smooth",
          })
        }
      >
        <UrlLinkV
          rel="noreferrer"
          href="_EXTERNALURL"
          style={{
            color: "#191CEE",
            wordWrap: "break-word",
            wordBreak: "keep-all",
            position: "relative",
            width: "min-content",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const url = String(urlMessage);
            if (mobileType === "ios") {
              const encodedUrl = Base64.encode(url);
              window.webkit.messageHandlers.openOuterLink.postMessage(
                encodedUrl
              );
            } else {
              window.open(url);
            }
          }}
        >
          {urlData.imageUrl && (
            <UrlImgV
              src={urlData.imageUrl}
              alt=""
              referrerPolicy="no-referrer"
            />
          )}
          <UrlTitleV>{urlData.title}</UrlTitleV>
          <UrlDescV>{urlData.description}</UrlDescV>
          <UrlNameV>{urlData.siteName}</UrlNameV>
        </UrlLinkV>
      </UrlContainerV>
    ) : (
      <UrlContainerH>
        <UrlLinkH
          rel="noreferrer"
          href="_EXTERNALURL"
          style={{
            color: "#191CEE",
            wordWrap: "break-word",
            wordBreak: "keep-all",
            position: "relative",
            width: "min-content",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const url = String(urlMessage);
            if (mobileType === "ios") {
              const encodedUrl = Base64.encode(url);
              window.webkit.messageHandlers.openOuterLink.postMessage(
                encodedUrl
              );
            } else {
              window.open(url);
            }
          }}
        >
          <InfoContainerH>
            {urlData.imageUrl && (
              <UrlImgH
                src={urlData.imageUrl}
                alt=""
                referrerPolicy="no-referrer"
              />
            )}
            <InfoSectionH>
              <UrlTitleH>{urlData.title}</UrlTitleH>
              <UrlDescH>{urlData.description}</UrlDescH>
            </InfoSectionH>
          </InfoContainerH>
          <UrlNameH>{urlData.siteName}</UrlNameH>
        </UrlLinkH>
      </UrlContainerH>
    ))
  );
}
/////// 수직 View /////////////
const UrlContainerV = styled.div`
  margin: 10px 14px;
  padding: 10px 3vh;
  background-color: #ebf5f1;
`;

const UrlLinkV = styled.a`
  font-size: 11px;
  display: block;
  width: 100% !important;
`;

const UrlImgV = styled.img`
  object-fit: contain;
  width: 100%;
  height: 180px;
`;

const UrlTitleV = styled.p`
  font-size: 15px;
  line-height: 20px;
  margin: 10px 0;
  color: #000;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
`;

const UrlNameV = styled.p`
  font-size: 12px;
  color: #999;
  margin-top: 10px;
`;

const UrlDescV = styled.p`
  font-size: 13px;
  color: #2c2c2c;

  line-height: 14px;
  /* height: 72px; */
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-y: hidden;

  word-break: break-all;
`;

/////// 수평 View /////////////
const UrlContainerH = styled.div`
  margin: 10px 0px;
  padding: 10px;
  background-color: #ebf5f1;
`;

const UrlLinkH = styled.a`
  font-size: 11px;
  display: block;
  width: 100% !important;
`;

const InfoContainerH = styled.div`
  display: flex;
`;
const InfoSectionH = styled.div``;

const UrlImgH = styled.img`
  /* background: url(${({ imageUrl }) => imageUrl}) no-repeat;
  background-color: #ebf5f1;
  background-position: center;
  background-size: contain; */
  object-fit: revert;
  min-width: 110px;
  max-width: 110px;
  min-height: 80px;
  max-height: 80px;
  padding-right: 10px;
`;

const UrlTitleH = styled.p`
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 10px;
  color: #000;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
`;

const UrlNameH = styled.p`
  font-size: 11px;
  color: #999;
  margin-top: 10px;
`;

const UrlDescH = styled.p`
  font-size: 11px;
  color: #2c2c2c;

  line-height: 14px;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-y: hidden;

  word-break: break-all;
`;
